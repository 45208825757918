import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";

import PersonalSlots from "./personalSlots";
import {
    CoachChangePersonalSlotRequest,
    CoachGetRequests,
    CoachPersonalSlotsAddTimeToDay,
    CoachPersonalSlotsGetMonth,
    CoachPersonalSlotsGetMonthDay, CoachPersonalSlotsRemoveTimeFromDay, GetSubTypesList
} from "../../../../redux/reducers/trainingReducer";

class PersonalSlotsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Coach: Слоты для индивидуальных тренировок");

        const date = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();

        this.props.CoachGetRequests();
        this.props.CoachPersonalSlotsGetMonth(+month + 1, year);
        this.props.GetSubTypesList()
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <PersonalSlots {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    TrainingInfo: state.trainingReducer
})


export default compose(
    connect(mapStateToProps, {
        CoachPersonalSlotsGetMonth, CoachPersonalSlotsGetMonthDay, CoachPersonalSlotsAddTimeToDay,
        CoachPersonalSlotsRemoveTimeFromDay, CoachGetRequests, CoachChangePersonalSlotRequest,
        GetSubTypesList
    }),
    withRouter
)(PersonalSlotsContainer)