import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import DiskFiles from "./diskFiles";
import {
    CreateDir,
    DeleteFile, DownloadDir,
    DownloadFile,
    GetFiles,
    PreviewImage,
    PushToStack, sortDiskTable,
    UploadFile
} from "../../../redux/reducers/diskReducer";

class DiskFilesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        if (!this.props.type) this.props.changeCurrentPage("")
        if (this.props.match.params.supportTask) {
            this.props.GetFiles(null, this.props.match.params.supportTask);
        } else {
            this.props.GetFiles();
        }
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <DiskFiles {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    DiskInfo: state.diskReducer
})

export default compose(
    connect(mapStateToProps, {
        GetFiles, PushToStack, CreateDir, UploadFile, DownloadFile, DeleteFile, PreviewImage, sortDiskTable, DownloadDir
    }),
    withRouter
)(DiskFilesContainer)