import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import RolesList from "./rolesList";
import rolesReducer, {
    AddRolePageAccess,
    CreateRole, DeleteRole, DeleteRolePageAccess, EditRole,
    GetRolesList,
    SetRolesAccessesList
} from "../../../../redux/reducers/rolesReducer";
import {GetPagesList} from "../../../../redux/reducers/pagesReducer";

class RolesListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Работа с ролями")
        this.props.GetRolesList(this.props.AuthInfo.id, this.props.AuthInfo.email, this.props.AuthInfo.accessToken)
        this.props.GetPagesList(this.props.AuthInfo.id, this.props.AuthInfo.email, this.props.AuthInfo.accessToken)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.PagesInfo.pagesList[0] && nextProps.RolesInfo.rolesList[0]
            && !nextProps.RolesInfo.rolesAccessesList[0]) {
            let roles = [];
            nextProps.RolesInfo.rolesList.forEach(role => {
                let roleBody = {
                    info: role,
                    pages: []
                }
                nextProps.PagesInfo.pagesList.forEach(page => {
                    let pageBody = {
                        page,
                        isAccessed: false
                    };
                    role.pageAccesses.forEach(access => {
                        if (access.name == page.name) pageBody.isAccessed = true
                    })
                    roleBody.pages.push(pageBody)
                })
                roles.push(roleBody)
            })
            this.props.SetRolesAccessesList(roles)
            return;
        }

        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.props.RolesInfo.rolesList.length != prevProps.RolesInfo.rolesList.length) {
        //     this.forceUpdate()
        // }
    }

    render() {
        return (
            <RolesList {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer,
    RolesInfo: state.rolesReducer,
    PagesInfo: state.pagesReducer
})

export default compose(
    connect(mapStateToProps, {
        GetRolesList, CreateRole, GetPagesList, SetRolesAccessesList,
        AddRolePageAccess, DeleteRolePageAccess, DeleteRole, EditRole
    }),
    withRouter
)(RolesListContainer)