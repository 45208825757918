
import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {ReactComponent as Arrow} from "../../assests/arrow_custom_select.svg";


const CustomSelect = ({width, data, value, title, setValue, z, id, isShowMenu, selectedData, setSelectedData}) => {

    useEffect(() => {
        window.addEventListener('click', (e) => {
            if (e.target.id !== 'popup') {
                setSelectedData(selectedData?.map((el, i) => ({...el, isShowMenu: false})))
            }
        })

        return () => {
            window.removeEventListener('click', (e) => {
                if (e.target.id !== 'popup') {
                    setSelectedData(selectedData?.map((el, i) => ({...el, isShowMenu: false})))
                }
            })
        }
    }, []);

    return (
        <div className={s.select_box}
             style={{zIndex: isShowMenu ? 100 : 1}}
        >
            <div className={s.select} id={'popup'}
                 style={{
                     borderRadius: isShowMenu && '10px 10px 0 0',
                     background: isShowMenu && '#252736',
                     border: isShowMenu && '1px solid #2F80ED',
                 }}
                 onClick={(e) => {
                     e.stopPropagation()
                     setSelectedData(selectedData?.map((el, i) => el.id === id ? {
                         ...el,
                         isShowMenu: !isShowMenu
                     } : {...el, isShowMenu: false}))
                 }}>
                <span
                    className={s.p}  style={{color: isShowMenu && '#fff'}}> {value && value != "all" ? value : title}</span>
                <Arrow className={classNames(s.arrow, isShowMenu && s.arrow_rotate)}/>
            </div>

            <div className={s.select_menu}
                 style={{
                     height: isShowMenu ? data?.length * 32 : 0,
                     bottom: isShowMenu ? (data?.length > 8 ? -(9 * 32) : -(data?.length * 32)) : 0,
                     border: isShowMenu && '1px solid #2F80ED',
                     overflow: data?.length < 8 && 'hidden',
                 }}>
                {data?.map((el) => {
                    return <div key={el.id}
                                className={classNames(s.select_item, el.title === value && s.selected)}
                                onClick={() => setValue(el.title)}>
                        <span className={s.span}>{el.title && el.title !== "all" ? el.title : el.title === "all" ? "Все" : ""}</span>
                    </div>
                })}
            </div>
        </div>
    );
};

export default CustomSelect;