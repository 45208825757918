import React from 'react';
import DialogContent from "@mui/material/DialogContent";
import {Button, DialogActions} from "@mui/material";
import Dialog from "@mui/material/Dialog";

const PopoverRemove = ({remove, setRemove, removeChooseDate, setId,id}) => {
    return (
        <Dialog
            open={remove}
            onClose={() => setRemove(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <h4 style={{
                    margin: 0
                }}>Удалить?</h4>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    removeChooseDate(id)
                    setRemove(false)
                    setTimeout(() => setId(null), 0)
                }}>Да</Button>
                <Button onClick={() => setRemove(false)} autoFocus>
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopoverRemove;
