import React from 'react';
import NotepadMain from "../../../../common/notepad_main";

const TextMobileMenu = (props) => {
    return (
        <div>
          <NotepadMain {...props} height={500}/>
        </div>
    );
};

export default TextMobileMenu;