import React from 'react';
import s from './styles.module.css'

import profitImg from '../../../../assests/day1.png'
import handsImg from '../../../../assests/day2.png'
import rakeImg from '../../../../assests/day3.png'
import tbetImg from '../../../../assests/day4.png'
import cbetImg from '../../../../assests/day5.png'

const Item = (props) => {
    return (
        <div className={s.container}>
            <div className={s.item} title={"Профит в пересчете на USD за выбранный период на основе внесенных данных"}>
                <img src={profitImg} alt=""/>
                <div className={s.text_box}>
                    <h4>${props.selected_profit ? props.selected_profit.toFixed(2) : 0}</h4>
                    <p>Профит</p>
                </div>
            </div>
            <div className={s.item} title={"Cумма Рейкбека за выбранный период на основе внесенных данных"}>
                <img src={tbetImg} alt=""/>
                <div className={s.text_box}>
                    <h4>{props.selected_rakeback ? props.selected_rakeback.toFixed(2) : 0}</h4>
                    <p>Рейкбек</p>
                </div>
            </div>
            <div className={s.item} title={"Выплаченная сумма"}>
                <img src={rakeImg} alt=""/>
                <div className={s.text_box}>
                    <h4>{props.selected_calculated ? props.selected_calculated.toFixed(2) : 0}</h4>
                    <p>Выплачено</p>
                </div>
            </div>
            <div className={s.item} title={"Не выплаченная сумма"}>
                <img src={rakeImg} alt=""/>
                <div className={s.text_box}>
                    <h4>{props.selected_not_calculated ? props.selected_not_calculated.toFixed(2) : 0}</h4>
                    <p>Осталось</p>
                </div>
            </div>
        </div>
    );
};

export default Item;