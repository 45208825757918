import React, {useState, useRef} from "react";
import WikiCreatorContainer from "./wikiCreatorContainer";
import WikiViewerContainer from "./wikiViewerContainer";
import s from "./styles.module.css";

export default function WikiPage(props) {
    return (
        <div className={s.wikiWrapper}>
            <div className={s.wikiPage}>
                {
                    props.WikiInfo.currentPage ?
                        props.AuthInfo.isWikiCreator ?
                            <WikiCreatorContainer type={1} name={props.WikiInfo.currentPage.name}
                                                  tags={props.WikiInfo.currentPage.tags}
                                                  category={props.WikiInfo.currentPage.category}
                                                  categories={props.WikiInfo.categoriesList}
                                                  id={props.WikiInfo.currentPage.id}
                                                  content={props.WikiInfo.currentPage.content}
                                                  rolesAccesses={props.WikiInfo.currentPage.rolesAccesses}
                            />
                            : <WikiViewerContainer {...props}/>
                        : null

                }
            </div>
        </div>
    );
}
