import React, {useState} from 'react';
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const CoachNotifications = (props) => {
    const [selectedGroup, setSelectedGroup] = useState("j");

    const handleDateChange = (date) => {
        props.J_NotificationsEditInfo(selectedGroup, "from_timestamp", +date)
    };

    const handleChangeChannels = (e) => {
        props.J_NotificationsEditInfo(selectedGroup, "channels", e.target.value)
    }

    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            },

        },
    });

    return (
        <div>
            <div style={{color: "white"}} key={selectedGroup}>
                <div>
                    <div style={{marginBottom: "30px", fontWeight: "bolder", display: "grid", gridTemplateColumns: "1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr"}}>
                        <div className={`button ${selectedGroup == "j" ? "green-back" : "default-back"}`} onClick={() => setSelectedGroup("j")}>Junior</div>
                        <div></div>
                        <div className={`button ${selectedGroup == "a" ? "green-back" : "default-back"}`} onClick={() => setSelectedGroup("a")}>Amateur</div>
                        <div></div>
                        <div className={`button ${selectedGroup == "sp" ? "green-back" : "default-back"}`} onClick={() => setSelectedGroup("sp")}>Semi-Pro</div>
                        <div></div>
                        <div className={`button ${selectedGroup == "p" ? "green-back" : "default-back"}`} onClick={() => setSelectedGroup("p")}>Pro</div>
                        <div></div>
                        <div className={`button ${selectedGroup == "il" ? "green-back" : "default-back"}`} onClick={() => setSelectedGroup("il")}>International Legion</div>
                    </div>

                    <div style={{fontSize: "13px", color: "#bc5959"}}>
                        При изменении даты сбросятся все текущие уведомления на "Не отправлено" и прошлая дата забудется! Даже не кликайте на дату, если не собираетесь её менять
                    </div>
                    <div style={{fontSize: "13px", marginBottom: "-5px", color: "#bc7759"}}>
                        Указывайте своё локальное время
                    </div>
                    <div style={{filter: "brightness(0) invert(1)"}}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    format="dd.MM.yyyy HH:mm"
                                    margin="normal"
                                    label="Начало собрания"
                                    value={props.TrainingInfo[`${selectedGroup}_from_timestamp`]}
                                    onChange={handleDateChange}
                                />
                            </MuiPickersUtilsProvider>

                        </ThemeProvider>
                    </div>
                    <div style={{marginTop: "30px"}}>
                        <div style={{fontSize: "13px", marginBottom: "5px"}}>
                            ID каналов для уведомлений (каждый новый канал - на новой строке!)
                        </div>
                        <textarea defaultValue={props.TrainingInfo[`${selectedGroup}_channels`]} onBlur={handleChangeChannels} style={{width: "200px", padding: "5px"}} rows="10"></textarea>
                    </div>

                    {/*<div style={{marginTop: "30px"}}>*/}
                    {/*    <div style={{fontSize: "13px"}}>*/}
                    {/*        Текущие уведомления:*/}
                    {/*    </div>*/}
                    {/*    <div style={{fontSize: "12px"}}>*/}
                    {/*        <div>*/}
                    {/*            За день: {props.j_notification_1day ? <span style={{color: "#66ba63"}}>Отправлено</span> : <span style={{color: "#bc5959"}}>Не отправлено</span>}*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            В день: {props.j_notification_current_day ? <span style={{color: "#66ba63"}}>Отправлено</span> : <span style={{color: "#bc5959"}}>Не отправлено</span>}*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            За 30 минут: {props.j_notification_30min ? <span style={{color: "#66ba63"}}>Отправлено</span> : <span style={{color: "#bc5959"}}>Не отправлено</span>}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default CoachNotifications;