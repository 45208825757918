import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import hidden from "../left_side_bar/icons/Group 3191.png";
import hiddenBack from "../../assests/hiddenBack.png";
import {useWindowSize} from "../../hooks/useWindowSize";
import UserMain from "../../common/user_main";
import SpedometrMain from "../../common/spedometr_main";
import PlayersMain from "../../common/players_main";
import NotepadMain from "../../common/notepad_main";
import {whatShowRightPanel} from "../../helpers/whatShowRightPanel";
import {useLocation} from "react-router-dom";
import Cookies from "js-cookie";

const RightSideBar = (props) => {
    const {width, height} = useWindowSize()
    const location = useLocation()

    return (
        <div className={classNames(s.rightSideBar, props.hiddenRight && s.hiddenBar)}>

            <UserMain hiddenRight={props.hiddenRight} email={Cookies.get("email")}
            username={Cookies.get("username")} avatar={props.MainInfo.mainPageData.avatar} AuthInfo={props.AuthInfo}/>


            <div className={classNames(s.box, props.hiddenRight && s.hiddenBar)}>

                {whatShowRightPanel(location.pathname, props.hiddenRight, {...props})}

                <NotepadMain hiddenRight={props.hiddenRight} {...props} height={145}/>

            </div>
            {width > 1200 && <div className={classNames(s.hidden, props.hiddenRight && s.hiddenHidden)}
                                  onClick={() => {
                                      if (width <= 1600) {
                                          if (props.hiddenRight) {
                                              props.setHiddenLeft(true)
                                              props.setHiddenRight(!props.hiddenRight)
                                          } else {
                                              props.setHiddenRight(!props.hiddenRight)
                                          }
                                      } else {
                                          props.setHiddenRight(!props.hiddenRight)
                                      }
                                  }}>
                <img src={props.hiddenRight ? hidden : hiddenBack} alt="hidden"/>
                {!props.hiddenRight && <p>Скрыть меню</p>}
            </div>}
        </div>
    );
};

export default RightSideBar;