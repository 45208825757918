import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {SupportGetTask, SupportSaveTask} from "../../../../redux/reducers/auditorReducer";
import AuditPlayer from "./AuditPlayer";

class AuditPlayerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        if (this.props.match.params.username && this.props.match.params.period && this.props.match.params.setc) {
            this.props.changeCurrentPage(`Аудит игрока ${this.props.match.params.username} (период: ${this.props.match.params.period} | сет: ${this.props.match.params.setc})`)
            this.props.SupportGetTask(this.props.match.params.period, this.props.match.params.username, this.props.match.params.setc)
        } else {
            this.props.changeCurrentPage(`Страница не найдена`)
        }
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        return (
            <AuditPlayer {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    AuditorInfo: state.auditorReducer
})


export default compose(
    connect(mapStateToProps, {
        SupportGetTask, SupportSaveTask
    }),
    withRouter
)(AuditPlayerContainer)