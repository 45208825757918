import {ReactComponent as Main} from "./icons/1.svg";
import {ReactComponent as Convert} from "./icons/2.svg";
import {ReactComponent as StudentOffice} from "./icons/3.svg";
import {ReactComponent as TeacherOffice} from "./icons/4.svg";
import {ReactComponent as Cloud} from "./icons/5.svg";
import {ReactComponent as SettingsUser} from "./icons/6.svg";
import {ReactComponent as SettingsOffice} from "./icons/7.svg";
import {ReactComponent as OfficeOwner1} from "./icons/8.svg";
import {ReactComponent as OfficeOwner2} from "./icons/9.svg";
import {ReactComponent as Pool} from "./icons/10.svg";
import {ReactComponent as Helper} from "./icons/11.svg";
import {ReactComponent as Finance} from "./icons/12.svg";
import {ReactComponent as Buisnes} from "./icons/13.svg";

export const accordionItems = () => [
    {title: 'Главная', isAccordion: false, svgComponent: <Main/>, path: "/"},
    {title: 'Фин. результаты', isAccordion: false, svgComponent: <Buisnes/>, path: "/fin_results"},
    {title: 'Трансферы', isAccordion: false, svgComponent: <Finance/>, path: "/transfers"},
    {title: 'Обменник', isAccordion: false, svgComponent: <Convert/>, path: "/exchanger"},
    {title: 'Тренировки', isAccordion: false, svgComponent: <StudentOffice/>, path: "/trainings"},
    {title: 'Leakfinder', isAccordion: false, svgComponent: <StudentOffice/>, path: "/leakfinder/mtt"},
    {title: 'Leakfinder ', isAccordion: false, svgComponent: <StudentOffice/>, path: "/leakfinder/spin"},
    {title: 'Зарплата сотрудника', isAccordion: false, svgComponent: <Buisnes/>, path: "/salary"},
    {title: 'Реферальная программа', isAccordion: false, svgComponent: <Buisnes/>, path: "/ref-program"},
    {title: 'FAQ', isAccordion: false, svgComponent: <Main/>, path: "/faq/main"},
    {title: 'Вики', isAccordion: false, svgComponent: <Main/>, path: "/wiki/list"},
    // {title: 'Обменник', isAccordion: false, svgComponent: <Convert/>, path: '/1'},
    // {
    //     title: 'Кабинет ученика',
    //     isAccordion: true,
    //     svgComponent: <StudentOffice/>,
    //     selectFirst1: 1,
    //     data: [
    //         {
    //             title: 'Стастистика',
    //             isAccordion: false,
    //             svgComponent: <Finance/>,
    //             path: '/f1',
    //             position: '/2',
    //             selectFirstParent: 1
    //         },
    //         {
    //             title: 'Финансы',
    //             isAccordion: true,
    //             svgComponent: <Buisnes/>,
    //             selectFirstParent: 1,
    //             selectSecond2: 1,
    //             data: [
    //                 {
    //                     title: 'Главная',
    //                     isAccordion: false,
    //                     svgComponent: <Main/>,
    //                     path: "/t1",
    //                     selectSecondParent: 1,
    //                 },
    //                 {
    //                     title: 'Обменник',
    //                     isAccordion: false,
    //                     svgComponent: <Convert/>,
    //                     path: '/t2',
    //                     selectSecondParent: 1,
    //                 },
    //                 {
    //                     title: 'Главная',
    //                     isAccordion: false,
    //                     svgComponent: <Main/>,
    //                     path: "/t3",
    //                     selectSecondParent: 1,
    //                 },
    //                 {
    //                     title: 'Обменник',
    //                     isAccordion: false,
    //                     svgComponent: <Convert/>,
    //                     path: '/t4',
    //                     selectSecondParent: 1,
    //                 },
    //             ]
    //         },
    //     ]
    // },
    // {title: 'Кабинет учителя', isAccordion: false, svgComponent: <TeacherOffice/>, path: '/3'},
    // {title: 'Облачный диск', isAccordion: false, svgComponent: <Cloud/>, path: '/4'},
    // {title: 'Настройки пользователя', isAccordion: false, svgComponent: <SettingsUser/>, path: '/5'},
    // {
    //     title: 'Настройки кабинета',
    //     isAccordion: true,
    //     svgComponent: <SettingsOffice/>,
    //     selectFirst1: 2,
    //     data: [
    //         {
    //             title: 'Стастистика',
    //             isAccordion: true,
    //             svgComponent: <Finance/>,
    //             selectFirstParent: 2,
    //             selectSecond2: 2,
    //             data: [
    //                 {
    //                     title: 'Кабинет учителя',
    //                     isAccordion: false,
    //                     svgComponent: <TeacherOffice/>,
    //                     selectSecondParent: 2,
    //                     path: '/t5'
    //                 },
    //                 {
    //                     title: 'Облачный диск',
    //                     isAccordion: false,
    //                     svgComponent: <Cloud/>,
    //                     path: '/t6',
    //                     selectSecondParent: 2
    //                 },
    //                 {
    //                     title: 'Кабинет учителя',
    //                     isAccordion: false,
    //                     svgComponent: <TeacherOffice/>,
    //                     path: '/t7',
    //                     selectSecondParent: 2,
    //                 },
    //                 {
    //                     title: 'Облачный диск',
    //                     isAccordion: false,
    //                     svgComponent: <Cloud/>,
    //                     path: '/t8',
    //                     selectSecondParent: 2
    //                 },
    //                 {
    //                     title: 'Кабинет учителя',
    //                     isAccordion: false,
    //                     svgComponent: <TeacherOffice/>,
    //                     path: '/t9',
    //                     selectSecondParent: 2,
    //                 },
    //                 {
    //                     title: 'Облачный диск',
    //                     isAccordion: false,
    //                     svgComponent: <Cloud/>,
    //                     path: '/t10',
    //                     selectSecondParent: 2,
    //                 },
    //             ]
    //         },
    //         {
    //             title: 'Финансы',
    //             isAccordion: false,
    //             svgComponent: <Buisnes/>,
    //             path: '/f4',
    //             position: '/6',
    //             selectFirstParent: 2,
    //         },
    //     ]
    // },
    // {title: 'Кабинет директора', isAccordion: false, svgComponent: <OfficeOwner1/>, path: '/7'},
    // {title: 'Кабинет директора', isAccordion: false, svgComponent: <OfficeOwner2/>, path: '/8',},
    // {
    //     title: 'POOL', isAccordion: true, svgComponent: <Pool/>, selectFirst1: 3, data: [
    //         {
    //             title: 'Главная',
    //             isAccordion: false,
    //             svgComponent: <Main/>,
    //             path: "/f5",
    //             position: '/9',
    //             selectFirstParent: 3,
    //         },
    //         {
    //             title: 'Обменник',
    //             isAccordion: false,
    //             svgComponent: <Convert/>,
    //             path: '/f6',
    //             position: '/9',
    //             selectFirstParent: 3,
    //         },
    //         {
    //             title: 'Стастистика',
    //             isAccordion: false,
    //             svgComponent: <Finance/>,
    //             path: '/f7',
    //             position: '/9',
    //             selectFirstParent: 3,
    //         },
    //         {
    //             title: 'Финансы',
    //             isAccordion: false,
    //             svgComponent: <Buisnes/>,
    //             path: '/f8',
    //             position: '/9',
    //             selectFirstParent: 3,
    //         },
    //     ]
    // },
    // {
    //     title: 'Служба поддержки', isAccordion: true, svgComponent: <Helper/>, selectFirst1: 4, data: [
    //         {
    //             title: 'Настройки пользователя',
    //             isAccordion: false,
    //             svgComponent: <SettingsUser/>,
    //             path: '/5',
    //             position: '/10',
    //             selectFirstParent: 4,
    //         },
    //         {
    //             title: 'Кабинет директора',
    //             isAccordion: false,
    //             svgComponent: <OfficeOwner1/>,
    //             path: '/f9',
    //             position: '/10',
    //             selectFirstParent: 4,
    //         },
    //         {
    //             title: 'Кабинет директора',
    //             isAccordion: false,
    //             svgComponent: <OfficeOwner2/>,
    //             path: '/f10',
    //             position: '/10',
    //             selectFirstParent: 4,
    //         },
    //     ]
    // },
    // {title: 'Стастистика', isAccordion: false, svgComponent: <Finance/>, path: '/11'},
    // {title: 'Финансы', isAccordion: false, svgComponent: <Buisnes/>, path: '/12'},

]