import {ADMIN_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";
import Cookies from "js-cookie";

const SET_ROLES_LIST = 'SET_ROLES_LIST';
const SET_ROLES_ACCESSES_LIST = 'SET_ROLES_ACCESSES_LIST';
const CHANGE_ROLE_PAGE_ACCESS = 'CHANGE_ROLE_PAGE_ACCESS';
const ADD_NEW_ROLE = 'ADD_NEW_ROLE';
const DELETE_ROLE_FROM_LIST = 'DELETE_ROLE_FROM_LIST';

let initialState = {
    rolesList: [],
    rolesAccessesList: []
};

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_ROLE:
            return {
                ...state,
                rolesList: [{name: action.roleName, pageAccesses: []}, ...state.rolesList],
                rolesAccessesList: [{
                    info: {name: action.roleName, pageAccesses: [], initiator: action.initiator}, pages:
                        action.pages.map(page => {
                            return {page, isAccessed: false}
                        })
                }, ...state.rolesAccessesList]
            }
        case DELETE_ROLE_FROM_LIST:
            let newRolesList = [...state.rolesList]
            let newRolesAccessesList = [...state.rolesAccessesList]

            let isIndexFound = false;
            let roleIndex = 0;
            state.rolesList.forEach((role, i) => {
                if (role.name == action.roleName) {
                    isIndexFound = true;
                    roleIndex = i;
                }
            })
            let isIndexFoundAccesses = false;
            let roleIndexAccesses = 0;
            state.rolesAccessesList.forEach((role, i) => {
                if (role.info.name == action.roleName) {
                    isIndexFoundAccesses = true;
                    roleIndexAccesses = i;
                }
            })

            if (isIndexFound && isIndexFoundAccesses) {
                newRolesList.splice(roleIndex, 1);
                newRolesAccessesList.splice(roleIndexAccesses, 1);

                return {
                    ...state,
                    rolesList: [...newRolesList],
                    rolesAccessesList: [...newRolesAccessesList]
                }
            } else {
                return state;
            }
        case SET_ROLES_LIST:
            return {
                ...state,
                rolesList: action.roles
            };
        case SET_ROLES_ACCESSES_LIST:
            return {
                ...state,
                rolesAccessesList: [...action.roles]
            };
        case CHANGE_ROLE_PAGE_ACCESS: {
            let updatedRolesAccessesList = [];
            state.rolesAccessesList.forEach(role => {
                if (role.info.name == action.roleName) {
                    role.pages.forEach(page => {
                        if (page.page.name == action.pageName) page.isAccessed = !page.isAccessed;
                    })
                }
                updatedRolesAccessesList.push(role)
            })
            return {
                ...state,
                rolesAccessesList: [...updatedRolesAccessesList]
            }
        }
        default:
            return state;
    }
};

export const SetRolesList = (roles) => ({type: SET_ROLES_LIST, roles});
export const SetRolesAccessesList = (roles) => ({type: SET_ROLES_ACCESSES_LIST, roles});
export const ChangeRolePageAccess = (roleName, pageName) => ({type: CHANGE_ROLE_PAGE_ACCESS, roleName, pageName})
const AddNewRole = (roleName, pages, initiator) => ({type: ADD_NEW_ROLE, roleName, pages, initiator})
const DeleteRoleFromList = (roleName) => ({type: DELETE_ROLE_FROM_LIST, roleName})

export const EditRole = (role_name, type, newValue) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get('token'), role_name, type, newValue
        }
        ADMIN_API.editRole(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение роли", data.message))
                    return
                }
            })
    }
}
export const DeleteRole = (id, email, username, access_token, roleName) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id, email, username
            },
            access_token,
            role_info: {
                name: roleName
            }
        }
        ADMIN_API.adminDeleteRole(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление роли", data.message))
                    return
                }
                dispatch(DeleteRoleFromList(roleName))
            })
    }
}

export const AddRolePageAccess = (id, email, username, access_token, roleName, pageName) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id, email, username
            },
            access_token,
            role_info: {
                name: roleName
            },
            page_info: {
                name: pageName
            }
        }
        ADMIN_API.addRolePageAccess(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Выдача доступа к странице", data.message))
                    return
                }
                dispatch(ChangeRolePageAccess(data.roleName, data.pageName))
            })
    }
}

export const DeleteRolePageAccess = (id, email, username, access_token, roleName, pageName) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id, email, username
            },
            access_token,
            role_info: {
                name: roleName
            },
            page_info: {
                name: pageName
            }
        }
        ADMIN_API.deleteRolePageAccess(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление доступа к странице", data.message))
                    return
                }
                dispatch(ChangeRolePageAccess(data.roleName, data.pageName))
            })
    }
}
export const GetRolesList = (id, email, access_token) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        ADMIN_API.getRolesList(id, email, access_token)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение списка ролей", data.message))
                    return
                }
                dispatch(SetRolesList(data))
            })
    }
}
export const CreateRole = (id, email, username, access_token, role_name, pages) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        if (role_name.length > 32 || role_name.length < 2) {
            dispatch(ChangeMainLoading(false))
            dispatch(AddNotification("error", "Создание роли", `В названии должно быть минимум 2 символа и максимум 24. Вы ввели: ${role_name.length}`))
            return
        }
        let body = {
            info: {
                id, email, username
            },
            access_token,
            role_info: {
                name: role_name, initiator: username
            }
        }
        ADMIN_API.createRole(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание роли", data.message))
                    return
                }
                dispatch(AddNewRole(role_name, pages, username))
            })
    }
}

export default rolesReducer;

