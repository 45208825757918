import React from 'react';
import errorImg from '../../../images/404.png'

const Error_404 = () => {
    return (
        <div>
            <img className={"errorImg"} src={errorImg} alt="404"/>
        </div>
    )
}

export default Error_404;