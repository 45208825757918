import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import s from './styles.module.css';
import closeImg from "../../assests/x-small.png";
import Textarea from "../textarea";

const SendQuestionToSupport = ({popupClassName, svg, title, UserSendQuestion}) => {

    const [question, setQuestion] = useState(null)
    const [keyId, setKeyId] = useState(1)

    const handleSendQuestion = () => {
        setKeyId(keyId + 1);
        UserSendQuestion(question);
        setQuestion(null)
    }
    return (
        <Popup key={`${keyId}`}
               trigger={
                   <div className={popupClassName}>
                       {svg}
                       <p>{title}</p>
                   </div>
               }
               modal
               nested
               closeOnDocumentClick={false}
        >
            {(close) => (
                <div className={s.popup_content} style={{width: "650px", height: "450px"}}>
                    <img className={s.close} src={closeImg} alt="close" onClick={close}/>
                    <div className={s.form}>
                            <Textarea value={question}
                                      placeholder={'Введите ваш вопрос'}
                                      title={<div className={s.input_right}>
                                      </div>} onChange={(e) => setQuestion(e.target.value)}/>

                        <div className={s.sendQuestionBtn} onClick={handleSendQuestion}>
                            Отправить вопрос
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    )
}

export default SendQuestionToSupport;