import React, {useState} from 'react';
import s from './styles.module.css'
import HeaderProgram from "./1-content";
import MainTable from "./2-content";
import LastTables from "./3-content";
import PopUpContainer from "../../common/popup_container";
import PopupGetMoney from "./popup_get_money";
import PopupSuccessAndError from "./popup_success_and_error";
import PopupRefProgram from "./popup_ref_program";

const GET_MONEY_POPUP = 'get_money'
const SUCCESS_POPUP = 'success'
const ERROR_POPUP = 'error'
const REF_PROGRAM_POPUP = 'ref_program'

const RefProgram = (props) => {
    const [showGetMoney, setShowGetMoney] = useState(null)

    const headerData = [
        {
            title: isNaN(props.ReferralInfo.full_info.available_sum) ? 0 : props.ReferralInfo.full_info.available_sum.toFixed(2),
            titleColor: '#2F80ED',
            subTitle: 'Сумма доступная к выводу',
            background: '#223053'
        },
        {cursor: true, title: '', titleColor: '', subTitle: 'Вывести деньги', background: '#37b214'},
        {
            title: props.ReferralInfo.full_info.all_time_sum,
            titleColor: '#717590',
            subTitle: 'Начислено за всё время',
            background: 'rgba(37,39,54,0.8)'
        },
        {
            title: props.ReferralInfo.full_info.out_sum,
            titleColor: '#717590',
            subTitle: 'Выплачено за всё время',
            background: 'rgba(37,39,54,0.8)'
        },
    ]

    return (
        <div>
            <PopUpContainer open={showGetMoney !== null} onClose={() => setShowGetMoney(null)}>
                {showGetMoney === GET_MONEY_POPUP &&
                    <PopupGetMoney RefSendRequest={props.RefSendRequest}
                                   showErrorOrSuccess={setShowGetMoney}
                                   onClose={() => setShowGetMoney(null)}
                                   sum={headerData.find((f => f.subTitle === 'Сумма доступная к выводу')).title} {...props}/>}

                {showGetMoney === SUCCESS_POPUP &&
                    <PopupSuccessAndError type={'success'} onClose={() => setShowGetMoney(null)}/>}

                {showGetMoney === ERROR_POPUP &&
                    <PopupSuccessAndError type={'error'} onClose={() => setShowGetMoney(GET_MONEY_POPUP)}/>}
            </PopUpContainer>

            <PopUpContainer height={775} width={1065} open={showGetMoney === REF_PROGRAM_POPUP}
                            onClose={() => setShowGetMoney(null)}>
                {showGetMoney === REF_PROGRAM_POPUP && <PopupRefProgram onClose={() => setShowGetMoney(null)}/>}
            </PopUpContainer>

            <div className={s.logo}>
                <h3>Личный кабинет</h3>
            </div>

            <div className={s.content} style={{paddingBottom: '50px'}}>
                <HeaderProgram GET_MONEY_POPUP={GET_MONEY_POPUP} REF_PROGRAM_POPUP={REF_PROGRAM_POPUP}
                               openPopUp={setShowGetMoney} headerData={headerData}
                               code={props.ReferralInfo.full_info.code}/>
                <MainTable {...props}/>
                <LastTables {...props}/>
            </div>
        </div>
    );
};

export default RefProgram;
