import reportWebVitals from './v_1/reportWebVitals';

import React from 'react';
import ReactDOM from 'react-dom';
import App_1 from './v_1/App';
import {BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import {Provider} from "react-redux";
import * as serviceWorker from "./v_1/serviceWorker";
import {subscribeUser} from './v_1/subscription';
import Cookies from "js-cookie";

import './v_2/index.css';
import App_2 from './v_2/AppContainer.js';

const WEB_SITE_USER_VERSION = Cookies.get("WEB_SITE_USER_VERSION")

if (!WEB_SITE_USER_VERSION || WEB_SITE_USER_VERSION === "1") {
    ReactDOM.render(
        <BrowserRouter>
            <React.StrictMode>
                <Provider store={store}>
                    <App_1/>
                </Provider>
            </React.StrictMode>
        </BrowserRouter>, document.getElementById('root')
    );
} else {
    ReactDOM.render(
        <BrowserRouter>
            <React.StrictMode>
                <Provider store={store}>
                    <App_2/>
                </Provider>
            </React.StrictMode>
        </BrowserRouter>, document.getElementById('root')
    );
}

serviceWorker.register()
subscribeUser()
reportWebVitals();
