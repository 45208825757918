import React from 'react';
import s from "../../pages/Exchanger/2-content/3-block_last/styles.module.css";
import Popup from "reactjs-popup";

const RequisitesSelector = (props) => {
    const handleSelect = (description, close) => {
        if (props.type == "changer") {
            props.onChangeCard({currentTarget: {value: description}}, "card");
            close()
        } else {
            props.setCardInfo(description);
            close();
        }
    }
    const handleRemove = (id) => {
        props.RemoveRequisites(id)
    }

    return (
        <Popup key={"checkbox_rules_fs_changer"}
               trigger={
                   <div style={{color: "white", backgroundColor: "#283d63", textAlign: "center", padding: "5px", borderRadius: "10px", marginTop: "5px", cursor: "pointer"}}>
                       Открыть сохраненные
                   </div>}
               modal
               nested
        >
            {(close) => (
                <div>
                    <div id="get-advice" className={s.popup}>
                        <form className="form">
                            <div className={s.formgroup}>
                                <div style={{
                                    width: "95%", marginTop: "-30px",
                                    float: "left",
                                    cursor: "pointer", color: "white"
                                }}>
                                    Нажмите на нужное, чтобы выбрать:
                                </div>
                                <div style={{marginTop: "30px"}}>
                                        {
                                            props.RequisitesInfo.requisites.map(e => {
                                                return <div style={{display: "grid", gridTemplateColumns: "1fr 10px 80px"}}>
                                                    <div style={{color: "white", backgroundColor: "#365895", margin: "13px 0", cursor: "pointer", borderRadius: "10px", padding: "18px 14px"}} onClick={() => handleSelect(e.description, close)}>
                                                        {e.description}
                                                    </div>
                                                    <div></div>
                                                    <div style={{color: "#c53d3d", margin: "13px 0", padding: "18px 14px"}} >
                                                        <span onClick={() => handleRemove(e._id)} style={{cursor: "pointer"}}>Удалить</span>
                                                    </div>
                                                </div>
                                            })
                                        }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default RequisitesSelector;