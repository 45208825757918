import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {NavLink, useLocation} from "react-router-dom";
import {Tooltip} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SecondAccordion from "./second_accordion";
import {accordionItems} from "../../components/left_side_bar/left_side_bat.constants";

export const ShowToolTip = ({children, hiddenLeft, title}) => !hiddenLeft ? <>{children}</> :
    <Tooltip title={title} placement="right" arrow>{children}</Tooltip>

export const NavLinkProvider = ({children, isAccordion, path}) => !isAccordion ?
    <NavLink to={path}>{children}</NavLink> : <>{children}</>

const ItemNavigate = ({
                          svgComponent,
                          title,
                          setSelected,
                          selected,
                          path,
                          hiddenLeft,
                          isAccordion,
                          data,
                          item,
                          setSelectFirst,
                          selectFirst,
                          setSelectSecond,
                          selectSecond,
                          setSelectThird,
                          selectThird,
                          AuthInfo
                      }) => {
    const location = useLocation()
    const [showSubData, setShowSubData] = useState(false)
    const [lenghtTird, setLenghtTherd] = useState(0)


    const handlerAccordion = (e) => {
        // e.stopPropagation()
        setShowSubData(!showSubData)
    }

    useEffect(() => {
        setSelected(location.pathname)
    }, [location.pathname])

    if (title == "Зарплата сотрудника" && (!AuthInfo.isDeveloper && !AuthInfo.isCoach && !AuthInfo.isOwner && !AuthInfo.isAdmin
        && !AuthInfo.isAuditAdmin && !AuthInfo.isFsChangerAdmin && !AuthInfo.isFsChangerSupport && !AuthInfo.isWikiCreator
        && !AuthInfo.isSupport && !AuthInfo.isDisk && !AuthInfo.isFinancesAdmin && !AuthInfo.isSpinSupport
        && !AuthInfo.isCashSupport && !AuthInfo.isMTTSupport && !AuthInfo.isMTSNGSupport && !AuthInfo.isRefSupport
        && !AuthInfo.isAuditor)) {
        return null;
    }

    if (title == "Leakfinder" && (!AuthInfo.isPlayerLeakfinderAccess || AuthInfo.verificator.direction != "MTT")) {
        return null;
    }

    if (title == "Leakfinder " && (!AuthInfo.isPlayerLeakfinderAccess || AuthInfo.verificator.direction != "SPIN")) {
        return null;
    }

    return (
        <NavLinkProvider path={path} isAccordion={isAccordion}>
            <ShowToolTip hiddenLeft={hiddenLeft} title={title}>
                <div
                    className={classNames(
                        s.item,
                        selected === path && s.active,
                        (((selectFirst) && (selectFirst === item?.selectFirst1)) ? s.active : ''),
                        (((selectSecond) && (selectSecond === item?.selectFirst)) ? s.active : ''),
                        (((selectThird) && (selectThird === item?.selectFirst)) ? s.active : ''),
                        hiddenLeft && s.hiddenLeft)}
                    onClick={(e) => {
                        setSelectFirst(item?.selectFirst1 ? item?.selectFirst1 : null)
                        handlerAccordion(e)
                    }}>
                    {svgComponent}

                    {(!hiddenLeft) && <p>{title}</p>}
                    {isAccordion &&
                        <div className={classNames(s.arrow, showSubData && s.rotateArrow, hiddenLeft && s.arrowHidden)}>
                            <ExpandMoreIcon/></div>}
                </div>
            </ShowToolTip>

            <div className={s.subData}
                 style={{height: showSubData && 'fit-content'}}
            >
                {data?.map((item, index) => <SecondAccordion
                    setSelectThird={setSelectThird}
                    setSelectFirst={setSelectFirst}
                    setSelectSecond={setSelectSecond}
                    lenghtTird={lenghtTird} setLenghtTherd={setLenghtTherd}
                    key={index + item.path}
                    hiddenLeft={hiddenLeft} item={item} {...item}
                    setSelected={setSelected} selected={selected}
                    selectSecond={selectSecond}
                    selectThird={selectThird}
                />)}
            </div>
        </NavLinkProvider>
    );
};

export default ItemNavigate;