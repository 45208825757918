import React, { useState, useRef } from "react";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import MainLoading from "../common/mainLoading";

export default function WikiSearch(props) {
    let SearchCategory = (e) => {
        props.SearchCategory(e.target.accessKey)
    }

    let handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            props.SearchText(event.target.value)
        }
    }

    return (
        props.isLoading ? <MainLoading/>
            :
        <div>
            <div className={"wiki-search"}>
                <input onKeyPress={handleKeyPress} placeholder={"Поиск: по названию, тегам, тексту / Для отображения всех статей очистите поиск"}/>
                <Popup key={new Date()}
                       trigger={<button className={"transition blue-back wiki-search-category"}>Поиск категории</button>}
                       modal
                       nested
                >
                    {() => (
                        <div>
                            <div id="get-advice" className="creation-page-category-selector-popup">
                                {
                                    props.WikiInfo.categoriesList.map((category, i) => {
                                        return (
                                            <button key={category + " " + i}
                                                    accessKey={category}
                                                    onClick={SearchCategory}
                                                    className={"send-btn"}>{category}</button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )}
                </Popup>
                {
                    props.AuthInfo.isWikiCreator ?
                        <NavLink to={"/wiki/creator"} className={"transition green-back wiki-search-category"}>
                            Создать новую статью
                        </NavLink>
                        :
                        null
                }
            </div>
        </div>
    );
}
