import React from 'react';
import Container from "../container";
import s from './styles.module.css'
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import ImgField from "../../../common/fields/imgField";
import ColorText from "../../../common/fields/colorText";
import NumberField from "../../../common/fields/numberField";
import NumberCeilField from "../../../common/fields/numberCeilField";
import NumberToFixed1Field from "../../../common/fields/numberToFixed1Field";
import TimestampToDateField from "../../../common/fields/timestampToDateField";
import TextField from "../../../common/fields/textField";
import NumberToFixed2Field from "../../../common/fields/numberToFixed2Field";
import TimestampToPeriodField from "../../../common/fields/timestampToPeriodField";
import AcceptButtonField from "../../../common/fields/acceptButtonField";
import DeclineButtonField from "../../../common/fields/declineButtonField";

const FourthContent = (props) => {
    return (
        <Container top='35px'>
            <div className='ag-theme-alpine-dark' style={{ height: 620, width: '100%', position: 'relative' }}>
                <AgGridReact
                    style={{ height: 620, width: '100%' }}
                    rowData={props.MainInfo.finResultsPageData.rows}
                    enableBrowserTooltips={true}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowHeight={70}
                    defaultColDef={{
                        editable: true,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        filter: false,
                        floatingFilter: false,
                        resizable: true,
                    }}
                    frameworkComponents={{
                        imgField: ImgField,
                        colorText: ColorText,
                        numberField: NumberField,
                        numberCeilField: NumberCeilField,
                        numberToFixed1Field: NumberToFixed1Field,
                        numberToFixed2Field: NumberToFixed2Field,
                        timestampToDateField: TimestampToDateField,
                        timestampToPeriodField: TimestampToPeriodField,
                        textField: TextField,
                        acceptButtonField: AcceptButtonField,
                        declineButtonField: DeclineButtonField
                    }}
                >
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'ID'}
                        field={'id'}
                        editable={false}
                        sortable={false}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Клуб'}
                        field='club_name'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Период'}
                        field='from_timestamp'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='timestampToPeriodField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Союз'}
                        field='union_name'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Профит USD'}
                        field='profit_usd'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberToFixed2Field'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Рейкбек USD'}
                        field='rakeback_usd'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberToFixed2Field'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Tax'}
                        field={"tax"}
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberToFixed2Field'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={''}
                        field={"action"}
                        editable={false}
                        sortable={false}
                        filter={false}
                        cellRenderer='acceptButtonField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={''}
                        field={"blablabla"}
                        editable={false}
                        sortable={false}
                        filter={false}
                        cellRenderer='declineButtonField'
                    />
                </AgGridReact>
            </div>
        </Container>

    );
};

export default FourthContent;