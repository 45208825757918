import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {GetCategoriesList} from "../../../redux/reducers/categoriesReducer";
import GeneratedMenuAccesses from "./generatedMenuAccesses";

class GeneratedMenuAccessesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.GetCategoriesList(this.props.AuthInfo.id, this.props.AuthInfo.email, this.props.AuthInfo.accessToken)
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        return (
            <GeneratedMenuAccesses {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    CategoriesInfo: state.categoriesReducer
})


export default compose(
    connect(mapStateToProps, {
        GetCategoriesList
    }),
    withRouter
)(GeneratedMenuAccessesContainer)