import React from 'react';
import s from './styles.module.css';
import {PlayerChangeStatus, PlayerTransferChangeStatus} from "../../../redux/reducers/mainReducer";
import {useDispatch} from "react-redux";

const AcceptButtonField = ({data}) => {
    const dispatch = useDispatch();

    return data.status == 1 ?
        <>
            {
                data.from_timestamp ?
                    <button className={s.number}
                            onClick={() => dispatch(PlayerChangeStatus(data._id, 2))} style={{
                        cursor: "pointer",
                        backgroundColor: "rgba(255,255,255,0)",
                        border: "none"
                    }}>✅</button>
                    :
                    <button className={s.number}
                            onClick={() => dispatch(PlayerTransferChangeStatus(data._id, 2))} style={{
                        cursor: "pointer",
                        backgroundColor: "rgba(255,255,255,0)",
                        border: "none"
                    }}>✅</button>
            }
        </> : <></>
};

export default AcceptButtonField;