import React from 'react';
import Container from "../container";
import s from './styles.module.css'
import Item from "./item";
import icon1 from '../../../assests/mainIcon1.png'
import icon2 from '../../../assests/mainIcon2.png'
import icon3 from '../../../assests/mainIcon3.png'
import icon4 from '../../../assests/mainIcon4.png'
import icon5 from '../../../assests/mainIcon5.png'
import icon6 from '../../../assests/mainIcon6.png'
import classNames from "classnames";

const FirstContent = (props) => {
    return (

        props.AuthInfo.verificator && props.AuthInfo.verificator.direction && props.AuthInfo.verificator.direction == "CASH" ?
        <Container top='35px' className={s.content}>
                <h4 className={s.title}>Личные данные</h4>
                <div className={s.item_box}>

                    <div className={s.item}>
                        <Item img={icon1} title={'Никнейм'} subtitle={props.MainInfo.mainPageData.username}/>
                        <Item img={icon2} title={'Электронная почта'} subtitle={props.MainInfo.mainPageData.email}/>
                    </div>

                    <div className={s.line}/>

                    <div className={s.item}>
                        <Item img={icon3} title={'Тип контракта'} subtitle={props.MainInfo.mainPageData.contractType}/>
                        <Item img={icon4} title={'Дата подписания'} subtitle={props.MainInfo.mainPageData.contractDate}/>
                    </div>

                    <div className={s.line}/>

                    <div className={classNames(s.item,s.item3)}>
                        <Item img={icon5} title={'Профит за всё время'} subtitle={`$${props.MainInfo.mainPageData.sums.all_time_profit ? +props.MainInfo.mainPageData.sums.all_time_profit.toFixed(2) : 0}`} color={'#FAB800'}/>
                        <Item img={icon6} title={'Рук за всё время'} subtitle={props.MainInfo.mainPageData.sums.all_time_hands}/>
                    </div>

                </div>
            </Container>

            : null
    );
};

export default FirstContent;