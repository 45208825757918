import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    ChangeServersFilter,
    ChangeStatusesFilter, ChangeTargetFilter, CloseTT,
    GetTTList,
    UpdateTTStatus
} from "../../../redux/reducers/supportReducer";
import TasksTickets from "./tasksTickets";

import config from "../../config.json"
import socketIOClient from "socket.io-client";
import {ChangeTTFilters} from "../../../redux/reducers/authReducer";

class BugTicketsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    beforeUnloadFunc = (ev) => {
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    }

    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", this.beforeUnloadFunc);
    };

    componentDidMount() {
        this.props.changeCurrentPage("Баги")
        this.props.GetTTList("set", this.props.AuthInfo.TTServersFilter, this.props.AuthInfo.TTStatusesFilter, this.props.AuthInfo.TTTargetsFilter, this.props.type, 0, 50)

        this.props.AuthInfo.TTStatusesFilter.forEach(status => this.props.ChangeStatusesFilter(status))
        this.props.AuthInfo.TTServersFilter.forEach(status => this.props.ChangeServersFilter(status))

        this.setupBeforeUnloadListener();

        this.socket = socketIOClient(config.socket_io_path + `/tasksTicketsUpdates?access_token=${this.props.AuthInfo.accessToken}`);
        this.socket.on("tasksTicketsUpdated", data => {
            if (data.type == this.props.type && this.props.SupportInfo.servers.includes(data.body?.server?.id)) {
                // if (this.props.SupportInfo.statuses.includes(data.body.status)) {}
                this.props.UpdateTTStatus(data.body)
            }
        });
    }

    componentWillUnmount() {

        this.socket.disconnect()
        this.props.AuthInfo.TTStatusesFilter = [...this.props.SupportInfo.statuses];
        this.props.AuthInfo.TTServersFilter = [...this.props.SupportInfo.servers];
        this.props.SupportInfo.servers = [];
        this.props.SupportInfo.statuses = [];
        window.removeEventListener("beforeunload", this.beforeUnloadFunc);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.type != prevProps.type) {
            this.props.changeCurrentPage("Баги")
            this.props.GetTTList("set", this.props.SupportInfo.servers, this.props.SupportInfo.statuses, this.props.SupportInfo.targets, this.props.type, 0, 50)
        }

        if (this.props.SupportInfo.servers.length != prevProps.SupportInfo.servers.length ||
        this.props.SupportInfo.statuses.length != prevProps.SupportInfo.statuses.length || this.props.SupportInfo.targets.length != prevProps.SupportInfo.targets.length) {
            this.props.GetTTList("set", this.props.SupportInfo.servers, this.props.SupportInfo.statuses, this.props.SupportInfo.targets, this.props.type, 0, 50)
        }
    }

    fetchMoreData = () => {
        this.props.GetTTList("update", this.props.SupportInfo.servers, this.props.SupportInfo.statuses, this.props.SupportInfo.targets, this.props.type, this.props.SupportInfo.TTList.length, 50)
    };

    render() {
        return (
            <TasksTickets fetchMoreData={this.fetchMoreData} {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    SupportInfo: state.supportReducer
})


export default compose(
    connect(mapStateToProps, {
        GetTTList, UpdateTTStatus, ChangeStatusesFilter, ChangeServersFilter, ChangeTargetFilter,
        CloseTT
    }),
    withRouter
)(BugTicketsContainer)