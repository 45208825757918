import {TRAINING_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";
import Cookies from "js-cookie";

const TRAINING_SET_CONNECTORS_LIST = 'TRAINING_SET_CONNECTORS_LIST';
const TRAINING_DELETE_CONNECTOR = 'TRAINING_DELETE_CONNECTOR';
const TRAINING_CREATE_CONNECTOR = 'TRAINING_CREATE_CONNECTOR';
const TRAINING_SET_CREATOR_INFO = 'TRAINING_SET_CREATOR_INFO';
const TRAINING_SET_CREATOR_ERROR = 'TRAINING_SET_CREATOR_ERROR';
const TRAINING_SET_TRAINING_LIST = 'TRAINING_SET_TRAINING_LIST';
const TRAINING_EDIT_TRAINING = 'TRAINING_EDIT_TRAINING';
const TRAINING_COACH_SET_PERSONAL_SLOTS_MONTH = 'TRAINING_COACH_SET_PERSONAL_SLOTS_MONTH';
const TRAINING_COACH_SET_PERSONAL_SLOTS_MONTH_DAY = 'TRAINING_COACH_SET_PERSONAL_SLOTS_MONTH_DAY';
const TRAINING_USER_SET_PERSONAL_REQUEST_INFO = 'TRAINING_USER_SET_PERSONAL_REQUEST_INFO';
const TRAINING_USER_PUSH_NEW_REQUEST = 'TRAINING_USER_PUSH_NEW_REQUEST';
const TRAINING_COACH_SET_REQUESTS = 'TRAINING_COACH_SET_REQUESTS';
const TRAINING_COACH_SET_CHANGER_PERSONAL_SLOT_REQUEST_STEP = 'TRAINING_COACH_SET_CHANGER_PERSONAL_SLOT_REQUEST_STEP'
const TRAINING_COACH_CHANGE_PERSONAL_SLOT_REQUEST = 'TRAINING_COACH_CHANGE_PERSONAL_SLOT_REQUEST';
const TRAINING_DISCORD_SENDER_SET_PLAYERS_LIST = 'TRAINING_DISCORD_SENDER_SET_PLAYERS_LIST';
const TRAINING_DISCORD_SENDER_SET_CURRENT_PLAYERS_LIST = 'TRAINING_DISCORD_SENDER_SET_CURRENT_PLAYERS_LIST';
const TRAINING_USER_SET_STATUS_FILTER = "TRAINING_USER_SET_STATUS_FILTER";
const TRAINING_SET_ALL_FEEDBACKS = "TRAINING_SET_ALL_FEEDBACKS";
const TRAINING_SET_COACHES_LIST = 'TRAINING_SET_COACHES_LIST';
const TRAINING_SET_COACH_STATS = 'TRAINING_SET_COACH_STATS';

const TRAINING_COACH_STATS_SET_FILTER_GROUP = 'TRAINING_COACH_STATS_SET_FILTER_GROUP';
const TRAINING_COACH_STATS_SET_FILTER_TYPE = 'TRAINING_COACH_STATS_SET_FILTER_TYPE';
const TRAINING_COACH_STATS_SET_FILTER_STATUS = 'TRAINING_COACH_STATS_SET_FILTER_STATUS';
const TRAINING_COACH_STATS_SET_FILTER_DIFFICULT = 'TRAINING_COACH_STATS_SET_FILTER_DIFFICULT';

const TRAINING_SET_SUB_TYPES_LIST = 'TRAINING_SET_SUB_TYPES_LIST';
const TRAINING_USER_REMOVE_REQUEST = 'TRAINING_USER_REMOVE_REQUEST';
const TRAINING_SUPPORT_EDIT_VISIT = 'TRAINING_SUPPORT_EDIT_VISIT';
const TRAINING_SUPPORT_EDIT_NOT_VISITED_REASON = 'TRAINING_SUPPORT_EDIT_NOT_VISITED_REASON';

const TRAINING_SET_PLAYERS_LIST = 'TRAINING_SET_PLAYERS_LIST';
const TRAINING_SET_PLAYER_STATS = 'TRAINING_SET_PLAYER_STATS';

const TRAINING_PLAYER_STATS_SET_FILTER_GROUP = 'TRAINING_PLAYER_STATS_SET_FILTER_GROUP';
const TRAINING_PLAYER_STATS_SET_FILTER_TYPE = 'TRAINING_PLAYER_STATS_SET_FILTER_TYPE';
const TRAINING_PLAYER_STATS_SET_FILTER_STATUS = 'TRAINING_PLAYER_STATS_SET_FILTER_STATUS';
const TRAINING_PLAYER_STATS_SET_FILTER_DIFFICULT = 'TRAINING_PLAYER_STATS_SET_FILTER_DIFFICULT';

const TRAINING_J_NOTIFICATIONS_SET_INFO = 'TRAINING_J_NOTIFICATIONS_SET_INFO';

let initialState = {
    connectorsList: [],
    creatorInfo: {},
    creatorCoaches: [],
    creatorError: null,
    trainingsList: [],
    coachPersonalSlotsMonthList: [],
    coachPersonalSlotsMonthDay: null,
    userCoaches: [],
    userRequests: [],
    userRequestsSave: [],
    coachRequests: [],
    coachStep: 1,
    trainingList: [],

    allFeedbacks: null,

    userStatusFilters: ['Тренировка в расписании', 'Тренировка завершена'],

    discord_sender_players_list: [],
    discord_sender_current_players_list: [],
    discord_sender_directions: [],
    discord_sender_groups: [],

    coachesList: [],
    coachStats: [],
    coachStatsSave: [],
    coachStatsGroupFilter: [],
    coachStatsTypeFilter: [],
    coachStatsStatusFilter: [],
    coachStatsDifficultFilter: [],

    coachStatsSelectedGroupFilter: [],
    coachStatsSelectedTypeFilter: [],
    coachStatsSelectedStatusFilter: [],
    coachStatsSelectedDifficultFilter: [],

    subTypesList: [],

    playersList: [],
    playerStats: [],
    playerStatsSave: [],
    playerStatsGroupFilter: [],
    playerStatsTypeFilter: [],
    playerStatsDifficultFilter: [],
    playerStatsStatusFilter: [],

    playerStatsSelectedGroupFilter: [],
    playerStatsSelectedTypeFilter: [],
    playerStatsSelectedStatusFilter: [],
    playerStatsSelectedDifficultFilter: [],

    j_from_timestamp: 0,
    j_notification_30min: false,
    j_notification_current_day: false,
    j_notification_1day: false,
    j_channels: "",

    sp_from_timestamp: 0,
    sp_notification_30min: false,
    sp_notification_current_day: false,
    sp_notification_1day: false,
    sp_channels: "",

    p_from_timestamp: 0,
    p_notification_30min: false,
    p_notification_current_day: false,
    p_notification_1day: false,
    p_channels: "",

    a_from_timestamp: 0,
    a_notification_30min: false,
    a_notification_current_day: false,
    a_notification_1day: false,
    a_channels: "",

    il_from_timestamp: 0,
    il_notification_30min: false,
    il_notification_current_day: false,
    il_notification_1day: false,
    il_channels: ""
};

const trainingReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRAINING_J_NOTIFICATIONS_SET_INFO: {
            return {
                ...state,
                j_from_timestamp: action.data.j_from_timestamp,
                j_notification_30min: action.data.j_notification_30min,
                j_notification_current_day: action.data.j_notification_current_day,
                j_notification_1day: action.data.j_notification_1day,
                j_channels: action.data.j_channels,

                sp_from_timestamp: action.data.sp_from_timestamp,
                sp_notification_30min: action.data.sp_notification_30min,
                sp_notification_current_day: action.data.sp_notification_current_day,
                sp_notification_1day: action.data.sp_notification_1day,
                sp_channels: action.data.sp_channels,

                p_from_timestamp: action.data.p_from_timestamp,
                p_notification_30min: action.data.p_notification_30min,
                p_notification_current_day: action.data.p_notification_current_day,
                p_notification_1day: action.data.p_notification_1day,
                p_channels: action.data.p_channels,

                a_from_timestamp: action.data.a_from_timestamp,
                a_notification_30min: action.data.a_notification_30min,
                a_notification_current_day: action.data.a_notification_current_day,
                a_notification_1day: action.data.a_notification_1day,
                a_channels: action.data.a_channels,

                il_from_timestamp: action.data.il_from_timestamp,
                il_notification_30min: action.data.il_notification_30min,
                il_notification_current_day: action.data.il_notification_current_day,
                il_notification_1day: action.data.il_notification_1day,
                il_channels: action.data.il_channels
            }
        }
        case TRAINING_SET_SUB_TYPES_LIST: {
            return {
                ...state,
                subTypesList: JSON.parse(JSON.stringify(action.data))
            }
        }
        case TRAINING_COACH_STATS_SET_FILTER_GROUP: {
            let data = JSON.parse(JSON.stringify(state.coachStatsSave));

            let currentFilters = action.data;

            let filteredData;

            if (state.coachStatsSave && state.coachStatsSave[0]) {
                filteredData = data.filter(item => {
                    const hasGroupName = currentFilters.length === 0 || currentFilters.some(group => group.group_name === item.group_name);
                    const hasType = state.coachStatsSelectedTypeFilter.length === 0 || state.coachStatsSelectedTypeFilter.some(type => type.type === item.type);
                    const hasDifficult = state.coachStatsSelectedDifficultFilter.length === 0 || state.coachStatsSelectedDifficultFilter.some(difficult => difficult.subType === item.subType);
                    const hasStatus = state.coachStatsSelectedStatusFilter.length === 0 || state.coachStatsSelectedStatusFilter.some(status => status.status === item.status);
                    return hasGroupName && hasType && hasDifficult && hasStatus;
                });
            }

            return {
                ...state,
                coachStats: filteredData ? JSON.parse(JSON.stringify(filteredData)) : JSON.parse(JSON.stringify(state.coachStatsSave)),

                coachStatsSelectedGroupFilter: action.data,
            }
        }
        case TRAINING_COACH_STATS_SET_FILTER_TYPE: {
            let data = JSON.parse(JSON.stringify(state.coachStatsSave));

            let currentFilters = action.data;

            let filteredData;

            if (state.coachStatsSave && state.coachStatsSave[0]) {
                filteredData = data.filter(item => {
                    const hasGroupName = state.coachStatsSelectedGroupFilter.length === 0 || state.coachStatsSelectedGroupFilter.some(group => group.group_name === item.group_name);
                    const hasType = currentFilters.length === 0 || currentFilters.some(type => type.type === item.type);
                    const hasDifficult = state.coachStatsSelectedDifficultFilter.length === 0 || state.coachStatsSelectedDifficultFilter.some(difficult => difficult.subType === item.subType);
                    const hasStatus = state.coachStatsSelectedStatusFilter.length === 0 || state.coachStatsSelectedStatusFilter.some(status => status.status === item.status);
                    return hasGroupName && hasType && hasDifficult && hasStatus;
                });
            }

            return {
                ...state,
                coachStats: filteredData ? JSON.parse(JSON.stringify(filteredData)) : JSON.parse(JSON.stringify(state.coachStatsSave)),

                coachStatsSelectedTypeFilter: action.data,
            }
        }
        case TRAINING_COACH_STATS_SET_FILTER_DIFFICULT: {
            let data = JSON.parse(JSON.stringify(state.coachStatsSave));

            let currentFilters = action.data;

            let filteredData;

            if (state.coachStatsSave && state.coachStatsSave[0]) {
                filteredData = data.filter(item => {
                    const hasGroupName = state.coachStatsSelectedGroupFilter.length === 0 || state.coachStatsSelectedGroupFilter.some(group => group.group_name === item.group_name);
                    const hasType = state.coachStatsSelectedTypeFilter.length === 0 || state.coachStatsSelectedTypeFilter.some(type => type.type === item.type);
                    const hasDifficult = currentFilters.length === 0 || currentFilters.some(difficult => difficult.subType === item.subType);
                    const hasStatus = state.coachStatsSelectedStatusFilter.length === 0 || state.coachStatsSelectedStatusFilter.some(status => status.status === item.status);
                    return hasGroupName && hasType && hasDifficult && hasStatus;
                });
            }

            return {
                ...state,
                coachStats: filteredData ? JSON.parse(JSON.stringify(filteredData)) : JSON.parse(JSON.stringify(state.coachStatsSave)),

                coachStatsSelectedDifficultFilter: action.data,
            }
        }
        case TRAINING_COACH_STATS_SET_FILTER_STATUS: {
            let data = JSON.parse(JSON.stringify(state.coachStatsSave));

            let currentFilters = action.data;

            let filteredData;

            if (state.coachStatsSave && state.coachStatsSave[0]) {
                filteredData = data.filter(item => {
                    const hasGroupName = state.coachStatsSelectedGroupFilter.length === 0 || state.coachStatsSelectedGroupFilter.some(group => group.group_name === item.group_name);
                    const hasType = state.coachStatsSelectedTypeFilter.length === 0 || state.coachStatsSelectedTypeFilter.some(type => type.type === item.type);
                    const hasDifficult = state.coachStatsSelectedDifficultFilter.length === 0 || state.coachStatsSelectedDifficultFilter.some(difficult => difficult.subType === item.subType);
                    const hasStatus = currentFilters.length === 0 || currentFilters.some(status => status.status === item.status);
                    return hasGroupName && hasType && hasDifficult && hasStatus;
                });
            }

            return {
                ...state,
                coachStats: filteredData ? JSON.parse(JSON.stringify(filteredData)) : JSON.parse(JSON.stringify(state.coachStatsSave)),

                coachStatsSelectedStatusFilter: action.data,
            }
        }
        case TRAINING_PLAYER_STATS_SET_FILTER_GROUP: {
            let data = JSON.parse(JSON.stringify(state.playerStatsSave));

            let currentFilters = action.data;

            let filteredData;

            if (state.playerStatsSave && state.playerStatsSave[0]) {
                filteredData = data.filter(item => {
                    const hasGroupName = currentFilters.length === 0 || currentFilters.some(group => group.group_name === item.group_name);
                    const hasType = state.playerStatsSelectedTypeFilter.length === 0 || state.playerStatsSelectedTypeFilter.some(type => type.type === item.type);
                    const hasDifficult = state.playerStatsSelectedDifficultFilter.length === 0 || state.playerStatsSelectedDifficultFilter.some(difficult => difficult.subType === item.subType);
                    const hasStatus = state.playerStatsSelectedStatusFilter.length === 0 || state.playerStatsSelectedStatusFilter.some(status => status.status === item.status);
                    return hasGroupName && hasType && hasDifficult && hasStatus;
                });
            }

            return {
                ...state,
                playerStats: filteredData ? JSON.parse(JSON.stringify(filteredData)) : JSON.parse(JSON.stringify(state.playerStatsSave)),

                playerStatsSelectedGroupFilter: action.data,
            }
        }
        case TRAINING_PLAYER_STATS_SET_FILTER_TYPE: {
            let data = JSON.parse(JSON.stringify(state.playerStatsSave));

            let currentFilters = action.data;

            let filteredData;

            if (state.playerStatsSave && state.playerStatsSave[0]) {
                filteredData = data.filter(item => {
                    const hasGroupName = state.playerStatsSelectedGroupFilter.length === 0 || state.playerStatsSelectedGroupFilter.some(group => group.group_name === item.group_name);
                    const hasType = currentFilters.length === 0 || currentFilters.some(type => type.type === item.type);
                    const hasDifficult = state.playerStatsSelectedDifficultFilter.length === 0 || state.playerStatsSelectedDifficultFilter.some(difficult => difficult.subType === item.subType);
                    const hasStatus = state.playerStatsSelectedStatusFilter.length === 0 || state.playerStatsSelectedStatusFilter.some(status => status.status === item.status);
                    return hasGroupName && hasType && hasDifficult && hasStatus;
                });
            }

            return {
                ...state,
                playerStats: filteredData ? JSON.parse(JSON.stringify(filteredData)) : JSON.parse(JSON.stringify(state.playerStatsSave)),

                playerStatsSelectedTypeFilter: action.data,
            }
        }
        case TRAINING_PLAYER_STATS_SET_FILTER_DIFFICULT: {
            let data = JSON.parse(JSON.stringify(state.playerStatsSave));

            let currentFilters = action.data;

            let filteredData;

            if (state.playerStatsSave && state.playerStatsSave[0]) {
                filteredData = data.filter(item => {
                    const hasGroupName = state.playerStatsSelectedGroupFilter.length === 0 || state.playerStatsSelectedGroupFilter.some(group => group.group_name === item.group_name);
                    const hasType = state.playerStatsSelectedTypeFilter.length === 0 || state.playerStatsSelectedTypeFilter.some(type => type.type === item.type);
                    const hasDifficult = currentFilters.length === 0 || currentFilters.some(difficult => difficult.subType === item.subType);
                    const hasStatus = state.playerStatsSelectedStatusFilter.length === 0 || state.playerStatsSelectedStatusFilter.some(status => status.status === item.status);
                    return hasGroupName && hasType && hasDifficult && hasStatus;
                });
            }

            return {
                ...state,
                playerStats: filteredData ? JSON.parse(JSON.stringify(filteredData)) : JSON.parse(JSON.stringify(state.playerStatsSave)),

                playerStatsSelectedDifficultFilter: action.data,
            }
        }
        case TRAINING_PLAYER_STATS_SET_FILTER_STATUS: {
            let data = JSON.parse(JSON.stringify(state.playerStatsSave));

            let currentFilters = action.data;

            let filteredData;

            if (state.playerStatsSave && state.playerStatsSave[0]) {
                filteredData = data.filter(item => {
                    const hasGroupName = state.playerStatsSelectedGroupFilter.length === 0 || state.playerStatsSelectedGroupFilter.some(group => group.group_name === item.group_name);
                    const hasType = state.playerStatsSelectedTypeFilter.length === 0 || state.playerStatsSelectedTypeFilter.some(type => type.type === item.type);
                    const hasDifficult = state.playerStatsSelectedDifficultFilter.length === 0 || state.playerStatsSelectedDifficultFilter.some(difficult => difficult.subType === item.subType);
                    const hasStatus = currentFilters.length === 0 || currentFilters.some(status => status.status === item.status);
                    return hasGroupName && hasType && hasDifficult && hasStatus;
                });
            }

            return {
                ...state,
                playerStats: filteredData ? JSON.parse(JSON.stringify(filteredData)) : JSON.parse(JSON.stringify(state.playerStatsSave)),

                playerStatsSelectedStatusFilter: action.data,
            }
        }
        case TRAINING_SET_COACH_STATS: {
            let r1 = JSON.parse(JSON.stringify(action.data));
            let r2 = JSON.parse(JSON.stringify(action.data));
            let r3 = JSON.parse(JSON.stringify(action.data));

            let GroupFilter;
            let TypeFilter;
            let DifficultFilter;

            if (r1 && r1[0]) {
                const uniqueData1 = r1.reduce((accumulator, current) => {
                    if (!accumulator[current.group_name]) {
                        accumulator[current.group_name] = current;
                    }
                    return accumulator;
                }, {});
                const uniqueData2 = r2.reduce((accumulator, current) => {
                    if (!accumulator[current.type]) {
                        accumulator[current.type] = current;
                    }
                    return accumulator;
                }, {});
                const uniqueData3 = r3.reduce((accumulator, current) => {
                    if (!accumulator[current.subType]) {
                        accumulator[current.subType] = current;
                    }
                    return accumulator;
                }, {});

                GroupFilter = Object.values(uniqueData1).filter(e => e.group_name?.length >= 2);
                TypeFilter = Object.values(uniqueData2).filter(e => e.type?.length >= 2);
                DifficultFilter = Object.values(uniqueData3).filter(e => e.subType?.length >= 2);
            }

            return {
                ...state,
                coachStats: JSON.parse(JSON.stringify(action.data)),
                coachStatsSave: JSON.parse(JSON.stringify(action.data)),

                coachStatsGroupFilter: GroupFilter ? GroupFilter : [],
                coachStatsTypeFilter: TypeFilter ? TypeFilter : [],
                coachStatsDifficultFilter: DifficultFilter ? DifficultFilter : [],
                coachStatsStatusFilter: [{status: "Завершена"}, {status: "Отменена"}, {status: "Активно"}],


                coachStatsSelectedGroupFilter: [],
                coachStatsSelectedTypeFilter: [],
                coachStatsSelectedStatusFilter: [],
                coachStatsSelectedDifficultFilter: [],
            }
        }
        case TRAINING_SET_COACHES_LIST: {
            return {
                ...state,
                coachesList: JSON.parse(JSON.stringify(action.data))
            }
        }
        case TRAINING_SET_PLAYER_STATS: {
            let r1 = JSON.parse(JSON.stringify(action.data));
            let r2 = JSON.parse(JSON.stringify(action.data));
            let r3 = JSON.parse(JSON.stringify(action.data));

            let GroupFilter;
            let TypeFilter;
            let DifficultFilter;

            if (r1 && r1[0]) {
                const uniqueData1 = r1.reduce((accumulator, current) => {
                    if (!accumulator[current.group_name]) {
                        accumulator[current.group_name] = current;
                    }
                    return accumulator;
                }, {});
                const uniqueData2 = r2.reduce((accumulator, current) => {
                    if (!accumulator[current.type]) {
                        accumulator[current.type] = current;
                    }
                    return accumulator;
                }, {});
                const uniqueData3 = r3.reduce((accumulator, current) => {
                    if (!accumulator[current.subType]) {
                        accumulator[current.subType] = current;
                    }
                    return accumulator;
                }, {});

                GroupFilter = Object.values(uniqueData1).filter(e => e.group_name?.length >= 2);
                TypeFilter = Object.values(uniqueData2).filter(e => e.type?.length >= 2);
                DifficultFilter = Object.values(uniqueData3).filter(e => e.subType?.length >= 2);
            }

            return {
                ...state,
                playerStats: JSON.parse(JSON.stringify(action.data)),
                playerStatsSave: JSON.parse(JSON.stringify(action.data)),

                playerStatsGroupFilter: GroupFilter ? GroupFilter : [],
                playerStatsTypeFilter: TypeFilter ? TypeFilter : [],
                playerStatsDifficultFilter: DifficultFilter ? DifficultFilter : [],
                playerStatsStatusFilter: [{status: "Завершена"}, {status: "Отменена"}, {status: "Активно"}],


                playerStatsSelectedGroupFilter: [],
                playerStatsSelectedTypeFilter: [],
                playerStatsSelectedStatusFilter: [],
                playerStatsSelectedDifficultFilter: [],
            }
        }
        case TRAINING_SET_PLAYERS_LIST: {
            return {
                ...state,
                playersList: JSON.parse(JSON.stringify(action.data))
            }
        }
        case TRAINING_SET_ALL_FEEDBACKS: {
            return {
                ...state,
                allFeedbacks: JSON.parse(JSON.stringify(action.data))
            }
        }
        case TRAINING_USER_SET_STATUS_FILTER: {
            const filters = state.userStatusFilters;

            if (action.status) {
                const filterIndex = filters.findIndex(e=>e == action.status);
                if (filterIndex == -1) {
                    filters.push(action.status);
                } else {
                    filters.splice(filterIndex, 1);
                }
            }

            let requests = JSON.parse(JSON.stringify(state.userRequestsSave.filter(e => filters.includes(e.status))));

            if (action.search) {
                let words = action.search.toLowerCase().split(" ");
                let requests_save = JSON.parse(JSON.stringify(requests));
                requests = [];
                for (let i = 0; i < requests_save.length; i++) {
                    let matches = 0;
                    let coachNames = requests_save[i].coachName ? requests_save[i].coachName.toLowerCase().split(" ") : [];
                    let replays = requests_save[i].replay ? requests_save[i].replay.toLowerCase().split(" ") : [];
                    let user_descriptions = requests_save[i].user_description ? requests_save[i].user_description.toLowerCase().split(" ") : [];
                    let user_topics = requests_save[i].user_topic ? requests_save[i].user_topic.toLowerCase().split(" ") : [];

                    coachNames.forEach(e => {
                        words.forEach(word => {
                            if (e == word) matches++;
                        })
                    })
                    replays.forEach(e => {
                        words.forEach(word => {
                            if (e == word) matches++;
                        })
                    })
                    user_descriptions.forEach(e => {
                        words.forEach(word => {
                            if (e == word) matches++;
                        })
                    })
                    user_topics.forEach(e => {
                        words.forEach(word => {
                            if (e == word) matches++;
                        })
                    })

                    words.forEach(word => {
                        if ((requests_save[i].coachName && requests_save[i].coachName.toLowerCase().includes(word)) ||
                            (requests_save[i].replay && requests_save[i].replay.toLowerCase().includes(word)) ||
                            (requests_save[i].user_description && requests_save[i].user_description.toLowerCase().includes(word)) ||
                            (requests_save[i].user_topic && requests_save[i].user_topic.toLowerCase().includes(word))) matches++;
                    })

                    if (matches != 0) requests.push(requests_save[i])
                }
            }

            if (action.timestamp_from) {
                requests = requests.filter(e => e.timestamp >= action.timestamp_from)
            }
            if (action.timestamp_to) {
                requests = requests.filter(e => e.timestamp <= action.timestamp_to)
            }

            return {
                ...state,
                userStatusFilters: action.status ? JSON.parse(JSON.stringify(filters)) : state.userStatusFilters,
                userRequests: requests
            }
        }
        case TRAINING_DISCORD_SENDER_SET_CURRENT_PLAYERS_LIST: {
            let updatedCurrentPlayersList = [];
            if (action.ttype == "direction") {
                updatedCurrentPlayersList = state.discord_sender_players_list.filter(e => e.direction == action.value)
            }
            if (action.ttype == "group") {
                updatedCurrentPlayersList = state.discord_sender_players_list.filter(e => e.coach_group == action.value)
            }
            if (action.ttype == "default") {
                return {
                    ...state, discord_sender_current_players_list: [...state.discord_sender_players_list]
                }
            }
            return {
                ...state,
                discord_sender_current_players_list: [...updatedCurrentPlayersList]
            }
        }
        case TRAINING_DISCORD_SENDER_SET_PLAYERS_LIST: {
            let discord_sender_directions = [...new Set(action.data.map(item => item.direction))];
            let discord_sender_groups = [...new Set(action.data.map(item => item.coach_group))];
            discord_sender_directions = discord_sender_directions.filter(e => !!e)
            discord_sender_groups = discord_sender_groups.filter(e => !!e)
            discord_sender_directions = discord_sender_directions.map(e => {return {direction: e}})
            discord_sender_groups = discord_sender_groups.map(e => {return {coach_group: e}})
            return {
                ...state,
                discord_sender_players_list: JSON.parse(JSON.stringify(action.data)),
                discord_sender_current_players_list: JSON.parse(JSON.stringify(action.data)),
                discord_sender_directions: [...discord_sender_directions], discord_sender_groups: [...discord_sender_groups]
            }
        }
        case TRAINING_USER_PUSH_NEW_REQUEST: {
            return {
                ...state,
                userRequests: [action.row, ...state.userRequests]
            }
        }
        case TRAINING_COACH_CHANGE_PERSONAL_SLOT_REQUEST: {
            let requestDayIndex = state.coachRequests.findIndex(e => e.timestamp_day == action.timestamp_day && e.timestamp == action.timestamp_time)
            if (requestDayIndex == -1) return state;

            let newCoachRequests = JSON.parse(JSON.stringify(state.coachRequests));
            newCoachRequests.splice(requestDayIndex, 1);

            return {
                ...state,
                coachRequests: JSON.parse(JSON.stringify(newCoachRequests))
            }
        }
        case TRAINING_COACH_SET_CHANGER_PERSONAL_SLOT_REQUEST_STEP: {
            return {
                ...state,
                coachStep: action.step,
                trainingList: JSON.parse(JSON.stringify(action.trainingList)),
            }
        }
        case TRAINING_COACH_SET_REQUESTS: {
            return {
                ...state,
                coachRequests: JSON.parse(JSON.stringify(action.requests))
            }
        }
        case TRAINING_USER_SET_PERSONAL_REQUEST_INFO: {
            let userRequests = JSON.parse(JSON.stringify(action.requests));
            userRequests.sort((a, b) => b.timestamp - a.timestamp);
            return {
                ...state,
                userCoaches: JSON.parse(JSON.stringify(action.coaches)),
                userRequests: JSON.parse(JSON.stringify(userRequests.filter(e => e.status == "Тренировка в расписании" || e.status == "Тренировка завершена"))),
                userRequestsSave: JSON.parse(JSON.stringify(userRequests))
            }
        }
        case TRAINING_COACH_SET_PERSONAL_SLOTS_MONTH_DAY: {
            if (action.day && action.day.times && action.day.times[0]) {
                let newDay = JSON.parse(JSON.stringify(action.day));
                newDay.times.sort((a, b) => a.timestamp - b.timestamp);
                return {
                    ...state,
                    coachPersonalSlotsMonthDay: JSON.parse(JSON.stringify(newDay))
                }
            }
            return {
                ...state,
                coachPersonalSlotsMonthDay: JSON.parse(JSON.stringify(action.day))
            }
        }
        case TRAINING_COACH_SET_PERSONAL_SLOTS_MONTH: {
            return {
                ...state,
                coachPersonalSlotsMonthList: JSON.parse(JSON.stringify(action.list))
            }
        }
        case TRAINING_EDIT_TRAINING: {
            let newTrainingList = JSON.parse(JSON.stringify(state.coachStats))
            let ti = state.coachStats.findIndex(e => e._id == action.training._id);
            if (ti != -1) {
                newTrainingList[ti] = JSON.parse(JSON.stringify(action.training))
                return {
                    ...state,
                    coachStats: [...newTrainingList]
                }
            } else {
                return state;
            }
        }
        case TRAINING_SET_TRAINING_LIST: {
            return {
                ...state,
                trainingsList: [...action.list]
            }
        }
        case TRAINING_SET_CREATOR_ERROR: {
            return {
                ...state,
                creatorError: action.text
            }
        }
        case TRAINING_SET_CREATOR_INFO: {
            return {
                ...state,
                creatorInfo: JSON.parse(JSON.stringify(action.info)),
                creatorCoaches: JSON.parse(JSON.stringify(action.coaches))
            }
        }
        case TRAINING_SET_CONNECTORS_LIST: {
            return {
                ...state,
                connectorsList: [...action.connectorsList]
            }
        }
        case TRAINING_DELETE_CONNECTOR: {
            const connectorIndex = state.connectorsList.findIndex(e => e.direction == action.direction && e.group_name == action.group_name)
            if (connectorIndex != -1) {
                let newConnectorsList = [...state.connectorsList];
                newConnectorsList.splice(connectorIndex, 1);
                return {
                    ...state,
                    connectorsList: [...newConnectorsList]
                }
            } else {
                return state;
            }
        }
        case TRAINING_CREATE_CONNECTOR: {
            return {
                ...state,
                connectorsList: [{
                    direction: action.direction,
                    group_name: action.group_name,
                    channels: action.channels
                }, ...state.connectorsList]
            }
        }
        case TRAINING_USER_REMOVE_REQUEST: {
            let updatedUserRequestsSave = JSON.parse(JSON.stringify(state.userRequestsSave));
            let updatedUserRequests = JSON.parse(JSON.stringify(state.userRequests));
            updatedUserRequests = updatedUserRequests.filter(e => e.owner_id != action.owner_id && e.timestamp_day != action.timestamp_day && e.timestamp_time != action.timestamp_time && e.direction != action.direction)
            updatedUserRequestsSave = updatedUserRequestsSave.filter(e => e.owner_id != action.owner_id && e.timestamp_day != action.timestamp_day && e.timestamp_time != action.timestamp_time && e.direction != action.direction)
            return {
                ...state,
                userRequests: JSON.parse(JSON.stringify(updatedUserRequests)),
                userRequestsSave: JSON.parse(JSON.stringify(updatedUserRequestsSave))
            }
        }
        case TRAINING_SUPPORT_EDIT_NOT_VISITED_REASON: {
            // _id, student_id, newValue
            const coachStats = JSON.parse(JSON.stringify(state.coachStats));
            const coachStatsSave = JSON.parse(JSON.stringify(state.coachStatsSave));

            const indexStat = coachStats.findIndex(e => e._id == action._id);
            const indexStatSave = coachStatsSave.findIndex(e => e._id == action._id);

            if (indexStat != -1) {
                let indexVisit = coachStats[indexStat].students_list.findIndex(e => e.student_id == action.student_id);
                if (indexVisit != -1) {
                    coachStats[indexStat].students_list[indexVisit].not_visited_reason = action.newValue;
                }
            }
            if (indexStatSave != -1) {
                let indexVisit = coachStatsSave[indexStatSave].students_list.findIndex(e => e.student_id == action.student_id);
                if (indexVisit != -1) {
                    coachStatsSave[indexStatSave].students_list[indexVisit].not_visited_reason = action.newValue;
                }
            }
            return {
                ...state,
                coachStats: JSON.parse(JSON.stringify(coachStats)),
                coachStatsSave: JSON.parse(JSON.stringify(coachStatsSave))
            }
        }
        case TRAINING_SUPPORT_EDIT_VISIT: {
            // _id, student_id, newValue
            const coachStats = JSON.parse(JSON.stringify(state.coachStats));
            const coachStatsSave = JSON.parse(JSON.stringify(state.coachStatsSave));

            const indexStat = coachStats.findIndex(e => e._id == action._id);
            const indexStatSave = coachStatsSave.findIndex(e => e._id == action._id);

            if (indexStat != -1) {
                let indexVisit = coachStats[indexStat].students_list.findIndex(e => e.student_id == action.student_id);
                if (indexVisit != -1) {
                    coachStats[indexStat].students_list[indexVisit].visit_percent = action.newValue;
                }
            }
            if (indexStatSave != -1) {
                let indexVisit = coachStatsSave[indexStatSave].students_list.findIndex(e => e.student_id == action.student_id);
                if (indexVisit != -1) {
                    coachStatsSave[indexStatSave].students_list[indexVisit].visit_percent = action.newValue;
                }
            }
            return {
                ...state,
                coachStats: JSON.parse(JSON.stringify(coachStats)),
                coachStatsSave: JSON.parse(JSON.stringify(coachStatsSave))
            }
        }
        default:
            return state;
    }
};


const ConnectorsSetList = (connectorsList) =>
    ({type: TRAINING_SET_CONNECTORS_LIST, connectorsList});

const ConnectorDeleteAC = (direction, group_name) =>
    ({type: TRAINING_DELETE_CONNECTOR, direction, group_name});

const ConnectorCreateAC = (direction, group_name, channels) =>
    ({type: TRAINING_CREATE_CONNECTOR, direction, group_name, channels});

const CreatorSetInfo = (info, coaches) => ({type: TRAINING_SET_CREATOR_INFO, info, coaches});

const SetCreatorError = (text) => ({type: TRAINING_SET_CREATOR_ERROR, text});

const SetTrainingsList = (list) => ({type: TRAINING_SET_TRAINING_LIST, list })

const EditTrainingAC = (training) => ({type: TRAINING_EDIT_TRAINING, training});

const SetAllFeedbacks = (data) => ({type: TRAINING_SET_ALL_FEEDBACKS, data});

const UserSetPersonalRequestInfo = (coaches, requests) => ({type: TRAINING_USER_SET_PERSONAL_REQUEST_INFO, coaches, requests});
export const CoachSetChangerPersonalSlotRequestStep = (step, trainingList = []) => ({type: TRAINING_COACH_SET_CHANGER_PERSONAL_SLOT_REQUEST_STEP, step, trainingList})
const CoachChangePersonalSlotRequestInfo = (timestamp_day, timestamp_time) => ({type: TRAINING_COACH_CHANGE_PERSONAL_SLOT_REQUEST, timestamp_day, timestamp_time})

const SetPlayersList = (data) => ({type: TRAINING_DISCORD_SENDER_SET_PLAYERS_LIST, data});

const SetCoachesListAC = (data) => ({type: TRAINING_SET_COACHES_LIST, data})
const SetCoachStatsAC = (data) => ({type: TRAINING_SET_COACH_STATS, data})

const SetPlayersListAC = (data) => ({type: TRAINING_SET_PLAYERS_LIST, data})
const SetPlayerStatsAC = (data) => ({type: TRAINING_SET_PLAYER_STATS, data})

const CoachStatsSetFilterGroupAC = (data) => ({type: TRAINING_COACH_STATS_SET_FILTER_GROUP, data})
const CoachStatsSetFilterTypeAC = (data) => ({type: TRAINING_COACH_STATS_SET_FILTER_TYPE, data})
const CoachStatsSetFilterStatusAC = (data) => ({type: TRAINING_COACH_STATS_SET_FILTER_STATUS, data})
const CoachStatsSetFilterDifficultAC = (data) => ({type: TRAINING_COACH_STATS_SET_FILTER_DIFFICULT, data})

const PlayerStatsSetFilterGroupAC = (data) => ({type: TRAINING_PLAYER_STATS_SET_FILTER_GROUP, data})
const PlayerStatsSetFilterTypeAC = (data) => ({type: TRAINING_PLAYER_STATS_SET_FILTER_TYPE, data})
const PlayerStatsSetFilterStatusAC = (data) => ({type: TRAINING_PLAYER_STATS_SET_FILTER_STATUS, data})
const PlayerStatsSetFilterDifficultAC = (data) => ({type: TRAINING_PLAYER_STATS_SET_FILTER_DIFFICULT, data})

const SetSubTypesList = (data) => ({type: TRAINING_SET_SUB_TYPES_LIST, data});

const J_NotificationsSetInfo = (data) => ({ type: TRAINING_J_NOTIFICATIONS_SET_INFO, data})
export const J_NotificationsGetInfo = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        TRAINING_API.coachNotificatorGetInfo()
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Получение данных", data.message))
                    return;
                }
                dispatch(J_NotificationsSetInfo(data))
            })
    }
}
export const J_NotificationsEditInfo = (selectedGroup, type, newValue) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        TRAINING_API.coachNotificatorEditInfo({selectedGroup, type, newValue, token: Cookies.get("token")})
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Изменение данных", data.message))
                    return;
                }
                dispatch(J_NotificationsSetInfo(data))
            })
    }
}

const UserRemoveRequestAC = (owner_id, timestamp_day, timestamp_time, direction) => ({type: TRAINING_USER_REMOVE_REQUEST, owner_id, timestamp_day, timestamp_time, direction});
export const UserRemoveRequest = (owner_id, timestamp_day, timestamp_time, direction) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            owner_id, timestamp_day, timestamp_time, direction
        }
        TRAINING_API.userRemoveRequest(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode) {
                    window.alert(data.message)
                    return;
                }
                dispatch(UserRemoveRequestAC(owner_id, timestamp_day, timestamp_time, direction))
            })
    }
}
const UserSetStatusFilterAC = (status, search, timestamp_from, timestamp_to) => ({type: TRAINING_USER_SET_STATUS_FILTER, status, search, timestamp_from, timestamp_to});
export const UserSetStatusFilter = (status, search, timestamp_from, timestamp_to) => {
    return dispatch => {
        dispatch(UserSetStatusFilterAC(status, search, timestamp_from, timestamp_to))
    }
}
const DiscordSenderUpdateCurrentPlayersAC = (ttype, value) => ({type: TRAINING_DISCORD_SENDER_SET_CURRENT_PLAYERS_LIST, ttype, value});
export const DiscordSenderUpdateCurrentPlayers = (ttype, value) => {
    return dispatch => {
        dispatch(DiscordSenderUpdateCurrentPlayersAC(ttype, value))
    }
}
export const CoachStatsSetFilterGroup = (data) => {
    return dispatch => {
        dispatch(CoachStatsSetFilterGroupAC(data))
    }
}
export const CoachStatsSetFilterType = (data) => {
    return dispatch => {
        dispatch(CoachStatsSetFilterTypeAC(data))
    }
}
export const CoachStatsSetFilterStatus = (data) => {
    return dispatch => {
        dispatch(CoachStatsSetFilterStatusAC(data))
    }
}
export const CoachStatsSetFilterDifficult = (data) => {
    return dispatch => {
        dispatch(CoachStatsSetFilterDifficultAC(data))
    }
}


export const PlayerStatsSetFilterGroup = (data) => {
    return dispatch => {
        dispatch(PlayerStatsSetFilterGroupAC(data))
    }
}
export const PlayerStatsSetFilterType = (data) => {
    return dispatch => {
        dispatch(PlayerStatsSetFilterTypeAC(data))
    }
}
export const PlayerStatsSetFilterStatus = (data) => {
    return dispatch => {
        dispatch(PlayerStatsSetFilterStatusAC(data))
    }
}
export const PlayerStatsSetFilterDifficult = (data) => {
    return dispatch => {
        dispatch(PlayerStatsSetFilterDifficultAC(data))
    }
}


export const ExportCoachStatsToGoogleTable = (data) => {
    return dispatch => {
        let body = {
            token: Cookies.get("token"),
            data
        }
        TRAINING_API.exportCoachStatsToGoogleTable(body)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Экспорт данных", data.message))
                    return;
                }
                dispatch(AddNotification("success", "Экспорт данных", "OK"))
            })
    }
}


export const ExportPlayerStatsToGoogleTable = (data) => {
    return dispatch => {
        let body = {
            token: Cookies.get("token"),
            data
        }
        TRAINING_API.exportPlayerStatsToGoogleTable(body)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Экспорт данных", data.message))
                    return;
                }
                dispatch(AddNotification("success", "Экспорт данных", "OK"))
            })
    }
}

export const GetSubTypesList = () => {
    return dispatch => {
        TRAINING_API.getSubTypesList()
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Получение уникального списка сложностей для тренировок", data.message))
                    return;
                }
                dispatch(SetSubTypesList(data))
            })
    }
}
const EditVisitAC = (_id, student_id, newValue) => ({type: TRAINING_SUPPORT_EDIT_VISIT, _id, student_id, newValue});
export const EditVisits = (_id, student_id, newValue) => {
    return dispatch => {
        let body = {
            token: Cookies.get("token"), _id, student_id, newValue
        };
        TRAINING_API.editVisits(body)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Изменение присутствия игрока", data.message))
                    return;
                }
                dispatch(EditVisitAC(_id, student_id, newValue))
            })
    }
}
const EditNotVisitedReasonAC = (_id, student_id, newValue) => ({type: TRAINING_SUPPORT_EDIT_NOT_VISITED_REASON, _id, student_id, newValue});
export const EditNotVisitedReason = (_id, student_id, newValue) => {
    return dispatch => {
        let body = {
            token: Cookies.get("token"), _id, student_id, newValue
        };
        TRAINING_API.editNotVisitedReason(body)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Изменение причины отсутствия игрока", data.message))
                    return;
                }
                dispatch(EditNotVisitedReasonAC(_id, student_id, newValue))
            })
    }
}
export const GetPlayerStats = (from_timestamp, to_timestamp, student_id) => {
    return dispatch => {
        TRAINING_API.getPlayerStats(from_timestamp, to_timestamp, student_id)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Получение статистики по игроку", data.message))
                    return;
                }
                dispatch(SetPlayerStatsAC(data))
            })
    }
}
export const GetPlayersList = () => {
    return dispatch => {
        TRAINING_API.getPlayersList()
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Получение списка игроков", data.message))
                    return;
                }
                dispatch(SetPlayersListAC(data))
            })
    }
}
export const GetCoachStats = (from_timestamp, to_timestamp, coach_id) => {
    return dispatch => {
        TRAINING_API.getCoachStats(from_timestamp, to_timestamp, coach_id)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Получение статистики по тренеру", data.message))
                    return;
                }
                dispatch(SetCoachStatsAC(data))
            })
    }
}
export const SetStudentsList = (training_id) => {
    return dispatch => {
        TRAINING_API.setStudentsList(training_id)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Список учеников", data.message))
                    return;
                }
                dispatch(AddNotification("success", "Список учеников", "Процесс обновления списка запущен. Чтобы увидеть обновленный список придется перезапросить тренировки/обновить страницу через ~1мин."));
            })
    }
}
export const GetCoachesList = () => {
    return dispatch => {
        TRAINING_API.getCoachesList()
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Получение списка тренеров", data.message))
                    return;
                }
                dispatch(SetCoachesListAC(data))
            })
    }
}
export const TrainingsGetAllFeedbacks = (from_timestamp, to_timestamp) => {
    return dispatch => {
        TRAINING_API.getAllFeedbacks(from_timestamp, to_timestamp)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Получение фидбеков", data.message))
                    return;
                }
                dispatch(SetAllFeedbacks(data))
            })
    }
}
export const TrainingsSendDiscordMessage = (players, message, file) => {
    return dispatch => {
        // let body = {
        //     token: Cookies.get("token"),
        //     players, message, file
        // }

        TRAINING_API.sendDiscordMessage(players, message, file)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Отправка сообщения", data.message))
                    return;
                }
                dispatch(AddNotification("success", "Отправка сообщения", "Сообщения отправлены"))
            })
    }
}


export const TrainingsSendPlayersTask = (users, message, description, date, upCount, upDescription) => {
    return dispatch => {
        let body = {
            users, message, description, date, up_count: upCount, up_text: upDescription
        }

        TRAINING_API.sendPlayersTask(body)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Отправка задачи игрокам", data.message))
                    return;
                }
                dispatch(AddNotification("success", "Отправка задачи игрокам", "Сообщения отправлены"))
            })
    }
}

export const TrainingsGetPlayers = () => {
    return dispatch => {
        TRAINING_API.getPlayers()
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Получение списка игроков", data.message))
                    return;
                }
                dispatch(SetPlayersList(data))
            })
    }
}
export const SendManualNotifications = (_id) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"),
            _id
        };
        TRAINING_API.sendManualNotifications(body)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Рассылка напоминания", data.message))
                    return;
                }
                dispatch(AddNotification("success", "Рассылка напоминания", "Рассылка началась"));
            })
    }
}
export const CoachChangePersonalSlotRequest = (type, step, timestamp_day, timestamp_time, decline_reason = null) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            type, step, timestamp_day, timestamp_time, reason: decline_reason
        }
        TRAINING_API.coachChangePersonalSlotRequest(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 10) {
                    dispatch(AddNotification("error", "Изменение статуса запроса", data.message))
                    return;
                }
                if (data && data.resultCode && data.resultCode == 10) {
                    dispatch(CoachSetChangerPersonalSlotRequestStep(2))
                    setTimeout(() => {
                        dispatch(CoachSetChangerPersonalSlotRequestStep(1))
                    }, 5000)
                    return;
                }

                dispatch(CoachChangePersonalSlotRequestInfo(timestamp_day, timestamp_time))
            })
    }
}
const CoachSetRequests = (requests) => ({type: TRAINING_COACH_SET_REQUESTS, requests});
export const CoachGetRequests = () => {
    return (dispatch) => {
        TRAINING_API.coachGetRequests()
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение списка запросов", data.message))
                    return
                }
                dispatch(CoachSetRequests(data))
            })
    }
}
const UserPushNewRequest = (row) => ({type: TRAINING_USER_PUSH_NEW_REQUEST, row});
export const UserSendPersonalRequest = (owner_id, timestamp_day, timestamp_time, topic, description, direction, coachName) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            owner_id, timestamp_day, timestamp_time, topic, description, direction
        }
        TRAINING_API.userSendPersonalRequest(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 10) {
                    window.alert(data.message)
                    return
                }
                if (data && data.resultCode && data.resultCode == 10) {
                    dispatch(CoachSetChangerPersonalSlotRequestStep(2, data.trainingList))
                    return;
                }
                dispatch(UserPushNewRequest({
                    timestamp: timestamp_time,
                    coachName,
                    status: "На рассмотрении",
                    user_topic: topic,
                    status_level: 1,
                    reason: "-"
                }))
            })
    }
}
export const UserGetPersonalRequestInfo = () => {
    return (dispatch) => {
        TRAINING_API.userGetPersonalRequestInfo()
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение информации", data.message))
                    return
                }

                dispatch(UserSetPersonalRequestInfo(data.coaches, data.requests));
            })
    }
}
export const CoachPersonalSlotsRemoveTimeFromDay = (month, year, day, time) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        TRAINING_API.coachPersonalSlotsRemoveTimeFromDay(month, year, day, time)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление времени", data.message))
                    return
                }
                dispatch(CoachPersonalSlotsSetMonthDay(data))
            })
    }
}
export const CoachPersonalSlotsAddTimeToDay = (month, year, day, time, subType) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"),
            month, year, day, time, subType
        }
        TRAINING_API.coachPersonalSlotsAddTimeToDay(body)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Добавление времени", data.message))
                    return
                }
                dispatch(CoachPersonalSlotsSetMonthDay(data))
            })
    }
}
const CoachPersonalSlotsSetMonthDay = (day) => ({type: TRAINING_COACH_SET_PERSONAL_SLOTS_MONTH_DAY, day});
export const CoachPersonalSlotsGetMonthDay = (month, year, day) => {
    return (dispatch) => {
        TRAINING_API.coachPersonalSlotsGetMonthDay(month, year, day)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение информации по индивидуальным тренировкам", data.message))
                    return
                }
                dispatch(CoachPersonalSlotsSetMonthDay(data))
            })
    }
}
const CoachPersonalSlotsSetMonthList = (list) => ({type: TRAINING_COACH_SET_PERSONAL_SLOTS_MONTH, list})
export const CoachPersonalSlotsGetMonth = (month, year) => {
    return (dispatch) => {
        TRAINING_API.coachPersonalSlotsGetMonth(month, year)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение информации по индивидуальным тренировкам", data.message))
                    return
                }
                dispatch(CoachPersonalSlotsSetMonthList(data))
            })
    }
}
export const EditTraining = (_id, type, newValue) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"),
            _id, type, newValue
        }
        TRAINING_API.editTraining(body)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение тренировки", data.message))
                    return
                }
                dispatch(EditTrainingAC(data))
            })
    }
}
export const GetTrainingsList = (type, list_type, hide_cancel = "1") => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        TRAINING_API.getTrainingsList(type, list_type, hide_cancel)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение списка тренировок", data.message))
                    return
                }
                dispatch(SetTrainingsList([]));
                dispatch(SetTrainingsList(data));
            })
    }
}
export const CreatorCreate = (creation_info, err, history) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get('token'),
            creation_info: {...creation_info}
        }
        TRAINING_API.creatorCreate(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 10) {
                    dispatch(AddNotification("error", "Создание тренировки", data.message))
                    return
                }
                if (data && data.resultCode && data.resultCode == 10) {
                    dispatch(SetCreatorError("На это время уже назначена тренировка другим тренером. Если вы уверены, что нужно назначить тренировку - нажмите на кнопку ещё раз"))
                    if (!err) {
                        setTimeout(() => {
                            dispatch(SetCreatorError(null))
                        }, 15000)
                    }
                    return
                }
                dispatch(SetCreatorError(null));
                history.push('/coach/training/list');
            })
    }
}
export const CreatorGetInfo = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        TRAINING_API.creatorGetInfo()
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение информации по созданию тренировки", data.message))
                    return
                }
                dispatch(CreatorSetInfo(data.info, data.coaches));
            })
    }
}
export const ConnectorEdit = (direction, group_name, type, newValue) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get('token'),
            direction, group_name, type, newValue
        }
        TRAINING_API.connectorEdit(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение группы для уведомлений", data.message))
                    return
                }
            })
    }
}
export const ConnectorsListGet = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        TRAINING_API.connectorsGet()
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получения списка групп для уведомлений", data.message))
                    return
                }
                dispatch(ConnectorsSetList(data))
            })
    }
}
export const ConnectorDelete = (direction, group_name) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        TRAINING_API.connectorDelete(direction, group_name)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление группы для уведомлений", data.message))
                    return
                }
                dispatch(ConnectorDeleteAC(direction, group_name))
            })
    }
}
export const ConnectorCreate = (direction, group_name, channels) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get('token'),
            direction, group_name, channels
        }
        TRAINING_API.connectorCreate(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание группы для уведомлений", data.message))
                    return
                }
                dispatch(ConnectorCreateAC(direction, group_name, channels))
            })
    }
}

export default trainingReducer;