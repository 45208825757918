import React from 'react';
import s from './styles.module.css';
const ColorText = ({ value, data, color, isCurrency }) => {
    let customStyles = {fontSize: "12px"};
    if (color) {
        customStyles.color = color;
    }
    if (data.coachName) {
        customStyles.color = "tomato"
        if (data.status_level == 1) customStyles.color = "orange";
        if (data.status_level == 2) customStyles.color = "greenyellow";
        if (data.status_level == 3) customStyles.color = "tomato";
        if (data.status_level == 5) customStyles.color = "yellow";

    }
    return <p className={s.color_text} style={customStyles}>{isCurrency ? "$ ": ""}{value}</p>;
};

export default ColorText;