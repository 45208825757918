import React from 'react';
import classNames from "classnames";
import s from './styles.module.css'
import Speedometr from "../speedometr";
import {ReactComponent as Blue} from "../../assests/blue.svg";
import {ReactComponent as Gold} from "../../assests/gold.svg";

const SpedometrMain = ({hiddenRight, maxS, currentS, titleS, typeS, classNameS, min, max, title, title_help}) => {
    return (
        <div title={title_help} className={classNames(s.box_item, hiddenRight && s.box_item_hidden)}>
            {!hiddenRight && <>
                <div>
                    <p className={s.right_p}>{title}</p>
                    <span className={s.first}>{min} </span><span className={s.second}>/ {max}</span>
                </div>
                <div>
                    <Speedometr max={maxS} current={currentS} title={titleS} type={typeS} className={typeS === 'blue' ? s.blue : s.gold}/>
                </div>
            </>}

            {hiddenRight && <div className={s.hidden_first}>
                {typeS === 'blue' ? <Blue/> : <Gold/>}
                <p className={typeS === 'blue' ? s.blue : s.gold}>{titleS}</p>
                <div>
                    <span className={s.first}>{min} </span><span className={s.second}>/ {max}</span>
                </div>
            </div>}

        </div>
    );
};

export default SpedometrMain;