import React, {useState} from 'react';
import Container from "../container";
import s from './styles.module.css'
import classNames from "classnames";
import CustomSelect from "../../../common/select";
import CustomDatePicker from "../../../common/date_picker";
import {useWindowSize} from "../../../hooks/useWindowSize";
import Items from "./item";

const ThirdContent = (props) => {
    const {width, height} = useWindowSize()

    const isMobile = width <= 768

    const [value, setValue] = useState({
        id: 'all',
        club: 'all',
        union: 'all',
        setCount: 'all',
    })

    const [isNeedUpdateRows, setIsNeedUpdateRows] = useState(false)


    let dateStartDate = new Date();
    let firstDay = +new Date(dateStartDate.getFullYear(), dateStartDate.getMonth(), 1);

    const [date, setDate] = useState([
        {
            startDate: firstDay,
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const filters = {
        id: value.id,
        setCount: value.setCount,
        club_name: value.club,
        union_name: value.union,
        startDate: +date[0].startDate,
        endDate: +date[0].endDate
    }

    const handleSetValue = (value) => {
        setIsNeedUpdateRows(true)
        setValue(value)
    }

    const handleSetDate = (date) => {
        // setIsNeedUpdateRows(true)
        setDate(date)
    }

    if (isNeedUpdateRows) {
        setIsNeedUpdateRows(false);
        props.GetFinResultsRowsByPeriod(filters)
    }

    const [selectedData, setSelectedData] = useState([
        {id: 1, isShowMenu: false},
        {id: 2, isShowMenu: false},
        {id: 3, isShowMenu: false},
        {id: 4, isShowMenu: false},
    ])

    return (
        <Container top='35px' overflow={false}>
            <div className={s.navigate}>
                <div className={classNames(s.box_item, s.first_box_item)}>
                    <p>Период:</p>
                    <div style={{width: '68%'}}>
                        <CustomDatePicker z={isMobile ? 10 : 10} state={date} setState={handleSetDate} filters={filters} GetData={props.GetFinResultsRowsByPeriod} setIsNeedUpdateRows={setIsNeedUpdateRows}/>
                    </div>
                </div>

                <div className={s.box_item}>
                    <p>ID:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Выбрать аккаунт'} data={props.MainInfo.finResultsPageData.uniqueIds}
                                      setValue={(element) => handleSetValue({...value, id: element})} value={value.id}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[0].id}
                                      isShowMenu={selectedData[0].isShowMenu}
                        />
                    </div>
                </div>

                <div className={s.box_item}>
                    <p>Union:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Выбрать союз'} data={props.MainInfo.finResultsPageData.uniqueUnions}
                                      setValue={(element) => handleSetValue({...value, union: element})} value={value.union}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[1].id}
                                      isShowMenu={selectedData[1].isShowMenu}
                        />
                    </div>
                </div>

                <div className={s.box_item}>
                    <p>Club:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Выбрать клуб'} data={props.MainInfo.finResultsPageData.uniqueClubs}
                                      setValue={(element) => handleSetValue({...value, club: element})} value={value.club}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[2].id}
                                      isShowMenu={selectedData[2].isShowMenu}
                        />
                    </div>
                </div>

                <div className={s.box_item}>
                    <p>Set:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Выбрать сет'} data={props.MainInfo.finResultsPageData.uniqueSetCounts}
                                      setValue={(element) => handleSetValue({...value, setCount: element})} value={value.setCount}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[3].id}
                                      isShowMenu={selectedData[3].isShowMenu}
                        />
                    </div>
                </div>

            </div>

            <div className={s.box_items}>
                <Items {...props.MainInfo.finResultsPageData.sums}/>
            </div>
        </Container>
    );
};

export default ThirdContent;