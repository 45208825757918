import React, {useEffect, useState} from 'react';
import Select from "react-dropdown-select";
import {useDispatch} from "react-redux";
import {AddNotification} from "../../../../redux/reducers/notificationsReducer";
import Table2 from "./details/table_4/index"
import CustomDropdownRenderer from "../../Util/Dropdown/CustomDropdownRenderer";

const GroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите группу"}
            labelField={"coach_group"}
            valueField={"coach_group"}
            sortBy={"coach_group"}
            contentRenderer={() => props.selectedGroup && props.selectedGroup[0] ? <div>{props.selectedGroup[0].coach_group}</div> : <div>Группа не выбрана</div>}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedGroup && props.selectedGroup[0] ? props.selectedGroup : []}
            onChange={(value) => {
                props.handleSelectGroup(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const RankSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите ранг"}
            labelField={"rank"}
            valueField={"rank"}
            sortBy={"rank"}
            contentRenderer={() => props.selectedRank && props.selectedRank[0] ? <div>{props.selectedRank[0].rank}</div> : <div>Ранг не выбран</div>}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedRank && props.selectedRank[0] ? props.selectedRank : []}
            onChange={(value) => {
                props.handleSelectRank(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const PlayersSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите игрока"}
            labelField={"nick"}
            valueField={"nick"}
            sortBy={"nick"}
            dropdownRenderer={CustomDropdownRenderer}
            contentRenderer={() => <div>Выбрано игроков: {props.selectedPlayers.length} из {props.options.length}</div>}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedPlayers}
            onChange={(value) => {
                props.handleSelectPlayers(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const PeriodTypeSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите тип периода"}
            labelField={"name"}
            valueField={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedPeriodType}
            onChange={(value) => {
                props.handleSelectPeriodType(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const YearSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите год"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedYear}
            onChange={(value) => {
                props.handleSelectYear(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const SchemaSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите ранг"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectSchema(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}

const LeakfinderDetailsTable_2 = (props) => {
    const dispatch = useDispatch();

    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedRank, setSelectedRank] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedPeriodType, setSelectedPeriodType] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [confirmStep, setConfirmStep] = useState(0);

    let interval = null;

    const handleSetConfirmStep = (num) => {
        interval = setTimeout(() => {
            setConfirmStep(0);
        }, 15000)

        if (num == 3) {
            setConfirmStep(0)
            props.LeakfinderRegenerateInteractiveTable2()
        }
        setConfirmStep(num)
    }
    const handleSelectGroup = (e) => {
        let players = [];
        let ids = [];

        if (!e || !e[0]) {
            setSelectedIds(JSON.parse(JSON.stringify(ids)))
            setSelectedPlayers(players);

            setSelectedGroup([]);
        } else {
            props.LeakfinderInfo.players.forEach(player => {
                e.forEach(se => {
                    if (se.coach_group == player.coach_group) {
                        players.push(player);
                        ids.push(player.id)
                    }
                })
            })
            setSelectedGroup(e)

            setSelectedIds(JSON.parse(JSON.stringify(ids)))
            setSelectedPlayers(players);

            setSelectedRank([]);
        }

    }


    const handleSelectRank = (e) => {
        let players = [];
        let ids = [];

        if (!e || !e[0]) {
            setSelectedIds(JSON.parse(JSON.stringify(ids)))
            setSelectedPlayers(players);
            setSelectedRank([])

        } else {
            props.LeakfinderInfo.players.forEach(player => {
                e.forEach(se => {
                    if (se.rank == player.rank) {
                        players.push(player);
                        ids.push(player.id)
                    }
                })
            })
            setSelectedRank(e)
            setSelectedIds(JSON.parse(JSON.stringify(ids)))
            setSelectedPlayers(players);
            setSelectedGroup([]);
        }

    }

    const handleSelectPlayers = (e) => {
        if (e && e[0]) {
            let ids = [];

            e.forEach(j => {
                ids.push(j.id)
            })

            setSelectedIds(JSON.parse(JSON.stringify(ids)))
            setSelectedPlayers(e)

        } else {
            setSelectedIds(JSON.parse(JSON.stringify([])));
            setSelectedPlayers([])

        }

    }
    const handleSelectPeriodType = (e) => {
        if (e && e[0]) {
            setSelectedPeriodType(JSON.parse(JSON.stringify(e)));
        } else {
            setSelectedPeriodType(JSON.parse(JSON.stringify([])));
        }
    }
    const handleSelectYear = (e) => {
        if (e && e[0]) {
            setSelectedYear(JSON.parse(JSON.stringify(e)));
        } else {
            setSelectedYear(JSON.parse(JSON.stringify([])));
        }
    }
    const handleSelectSchema = (e) => {
        if (e && e[0]) {
            setSelectedSchema(JSON.parse(JSON.stringify(e)));
        } else {
            setSelectedSchema(JSON.parse(JSON.stringify([])));
        }
    }

    const handleGetData = () => {
        if (!selectedSchema[0]) return dispatch(AddNotification("error", "Получение данных по таблице", "Не выбран ранг!"))
        if (!selectedPeriodType[0]) return dispatch(AddNotification("error", "Получение данных по таблице", "Не выбран тип периода!"))
        if (!selectedIds[0]) return dispatch(AddNotification("error", "Получение данных по таблице", "Нет румнеймов у выбранных ников!"))

        props.LeakfinderGetInteractiveTableData_2(selectedIds, selectedPeriodType[0].name, selectedYear[0] && selectedYear[0].name ? selectedYear[0].name : null, selectedSchema[0].name)
    }

    return (
        <div>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 20px 1fr 20px 1fr", color: "white"}}>
                <GroupSelector options={props.LeakfinderInfo.groups}
                               selectedGroup={selectedGroup}
                               handleSelectGroup={handleSelectGroup}/>
                <RankSelector options={props.LeakfinderInfo.ranks}
                              selectedRank={selectedRank}
                              handleSelectRank={handleSelectRank}/>
                <PlayersSelector options={props.LeakfinderInfo.players}
                                 selectedPlayers={selectedPlayers}
                                 handleSelectPlayers={handleSelectPlayers}/>
                <PeriodTypeSelector options={[{name: "Все года"}, {name: "Месяцы"}, {name: "Кварталы"}, {name: "Полугодия"}]}
                                    selectedPeriodType={selectedPeriodType}
                                    handleSelectPeriodType={handleSelectPeriodType}/>
                <YearSelector options={[{name: "2022"}, {name: "2023"}, {name: "2024"}, {name: "2025"}, {name: "2026"}]}
                                    selectedYear={selectedYear}
                                    handleSelectYear={handleSelectYear}/>
                <SchemaSelector options={props.LeakfinderInfo.schemas}
                                selectedSchema={selectedSchema}
                                handleSelectSchema={handleSelectSchema}/>
                <div></div>

                {
                    props.isLoading ? <div></div> : <div className={"button default-button green-back cursor-pointer"} style={{height: "40px"}} onClick={handleGetData}>
                        Обновить
                    </div>
                }
                <div></div>
                {
                    props.isLoading ? <div></div> : <div className={"button default-button red-back cursor-pointer"} style={{height: "40px"}} onClick={() => handleSetConfirmStep(confirmStep + 1)}>
                        {
                            confirmStep == 2 ? "ТОЧНО? Это может занять 2 часа" :
                                confirmStep == 1 ? "Вы уверены в действии?" : "Пересчитать ВСЕ данные"
                        }
                    </div>
                }

            </div>

            {
                props.LeakfinderInfo.interactiveTableData_2 && props.LeakfinderInfo.interactiveTableData_2.name && selectedSchema[0] &&
                <div style={{color: "white"}}>
                    <Table2 usernames={props.LeakfinderInfo.players} data={props.LeakfinderInfo.interactiveTableData_2}
                            period_type={selectedPeriodType[0] && selectedPeriodType[0].name ? selectedPeriodType[0].name : null}
                            year={selectedYear[0] && selectedYear[0].name ? selectedYear[0].name : null}
                    />
                </div>
            }
        </div>
    );
};

export default LeakfinderDetailsTable_2;