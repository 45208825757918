


let initialState = {

};

const sharkReducer = (state = initialState, action) => {
    switch (action.type) {

        default:
            return state;
    }
};


export default sharkReducer;

