import React from 'react';
import s from './styles.module.css'
import HeaderExchanger from "./1-content";
import ExchangerContent from "./2-content";
import ExchangerInfo from "./3-content";
import LastCommentaries from "./4-content";
import AboutExchanger from "./5-content";

const Exchanger = (props) => {
    return (
        <div className={s.box}>
            <div className={s.logo}>
                <h3>Личный кабинет</h3>
            </div>

            <div className={s.content}>
                <HeaderExchanger/>
                <ExchangerContent {...props}/>
                <ExchangerInfo {...props}/>
                <LastCommentaries {...props}/>
                <AboutExchanger/>
            </div>

        </div>
    );
};

export default Exchanger;