import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import CoachTainingsListSidebar from "./coachTainingsListSidebar";
import {GetTrainingsList} from "../../../../redux/reducers/trainingReducer";

class CoachTrainingsListSidebarContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }


    componentDidMount() {

    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <CoachTainingsListSidebar {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    TrainingInfo: state.trainingReducer
})


export default compose(
    connect(mapStateToProps, {
        GetTrainingsList
    }),
    withRouter
)(CoachTrainingsListSidebarContainer)