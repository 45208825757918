import React, {useState} from 'react';
import Container from "../container";
import s from './styles.module.css'
import classNames from "classnames";
import CustomSelect from "../../../common/select";
import CustomDatePicker from "../../../common/date_picker";
import {useWindowSize} from "../../../hooks/useWindowSize";

const ThirdContent = (props) => {
    // const [selected, setSelected] = useState("Текущий месяц")
    // const [optionsState, setOptionsState] = useState("Текущий месяц")
    //
    // const handleSelectPeriod = (day) => {
    //     setSelected(day);
    //     setOptionsState(day);
    //     // props.GetFinResultsRowsByPeriod(day)
    //     props.GetTransfersRowsByPeriod(day)
    // }
    //
    // const handleSelectPeriodSelector = (e) => {
    //     handleSelectPeriod(e.target.value)
    // }

    const {width, height} = useWindowSize()

    const isMobile = width <= 768

    const [value, setValue] = useState({
        id: 'all',
        target: 'all',
        method: 'all',
    })

    const [isNeedUpdateRows, setIsNeedUpdateRows] = useState(false)

    let dateStartDate = new Date();
    let firstDay = +new Date(dateStartDate.getFullYear(), dateStartDate.getMonth(), 1);

    const [date, setDate] = useState([
        {
            startDate: firstDay,
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const filters = {
        id: value.id,
        method: value.method,
        target: value.target,
        startDate: +date[0].startDate,
        endDate: +date[0].endDate
    }

    const handleSetValue = (value) => {
        setIsNeedUpdateRows(true)
        setValue(value)
    }

    const handleSetDate = (date) => {
        setIsNeedUpdateRows(true)
        setDate(date)
    }

    if (isNeedUpdateRows) {
        setIsNeedUpdateRows(false);
        props.GetTransfersRowsByPeriod(filters)
    }

    const [selectedData, setSelectedData] = useState([
        {id: 1, isShowMenu: false},
        {id: 2, isShowMenu: false},
        {id: 3, isShowMenu: false},
    ])

    return (
        <Container top='35px' overflow={false}>
            <div className={s.navigate}>
                <div className={classNames(s.box_item, s.first_box_item)}>
                    <p>Период:</p>
                    <div style={{width: '85%'}}>
                        <CustomDatePicker z={isMobile ? 10 : 10} state={date} setState={handleSetDate} filters={filters} GetData={props.GetTransfersRowsByPeriod} setIsNeedUpdateRows={setIsNeedUpdateRows}/>
                    </div>
                </div>

                <div className={s.box_item}>
                    <p>ID:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Аккаунт'} data={props.MainInfo.transfersPageData.uniqueIds}
                                      setValue={(element) => handleSetValue({...value, id: element})} value={value.id}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[0].id}
                                      isShowMenu={selectedData[0].isShowMenu}
                        />
                    </div>
                </div>
                <div className={s.box_item}>
                    <p>Target:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Назначение'} data={props.MainInfo.transfersPageData.uniqueTargets}
                                      setValue={(element) => handleSetValue({...value, target: element})} value={value.target}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[1].id}
                                      isShowMenu={selectedData[1].isShowMenu}
                        />
                    </div>
                </div>
                <div className={s.box_item}>
                    <p>Method:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Метод/клуб'} data={props.MainInfo.transfersPageData.uniqueMethods}
                                      setValue={(element) => handleSetValue({...value, method: element})} value={value.method}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[2].id}
                                      isShowMenu={selectedData[2].isShowMenu}
                        />
                    </div>
                </div>
            </div>

            {/*<div className={s.box_items}>*/}
            {/*    <Items {...props.MainInfo.finResultsPageData.sums}/>*/}
            {/*</div>*/}
        </Container>
    );
};

export default ThirdContent;