import React, {useState, useRef} from "react";
import {NavLink} from "react-router-dom";
import Popup from "reactjs-popup";
import EditIcon from "../../../images/edit_pencil.png";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import moment from "moment-timezone";
import Select from "react-dropdown-select";

const SubTypeSelector = (props) => {
    return (
        <Select
            placeholder={"Сложность тренировки"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            create={true}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedSubType}
            onChange={(value) => {
                props.handleSelectSubType(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "16px", border: "none", color: "white"}}
        />
    )
}

const CoachSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите тренера"}
            labelField={"coach_name"}
            valueField={"coach_name"}
            sortBy={"coach_name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelectCoach(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "1"}}
        />
    )
}

const GroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать группу"}
            labelField={"group_name"}
            valueField={"group_name"}
            sortBy={"group_name"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelectFilterGroup(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "1"}}
        />
    )
}

const TypeSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать тип"}
            labelField={"type"}
            valueField={"type"}
            sortBy={"type"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectFilterType(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "0"}}
        />
    )
}

const StatusSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать статусы"}
            labelField={"status"}
            valueField={"status"}
            sortBy={"status"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectFilterStatus(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "0"}}
        />
    )
}

const DifficultSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать сложность"}
            labelField={"subType"}
            valueField={"subType"}
            sortBy={"subType"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectFilterDifficult(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "0"}}
        />
    )
}


export default function List(props) {
    const [selectedCoach, setSelectedCoach] = useState([]);
    const [fromTimestamp, setFromTimestamp] = useState(+new Date());
    const [toTimestamp, setToTimestamp] = useState(0);
    const [search, setSearch] = useState("");
    
    
    const handleSelectFilterType = (e) => {
        props.CoachStatsSetFilterType(e)
    }
    const handleSelectFilterGroup = (e) => {
        props.CoachStatsSetFilterGroup(e)
    }
    const handleSelectFilterDifficult = (e) => {
        props.CoachStatsSetFilterDifficult(e)
    }
    const handleSelectFilterStatus = (type, e) => {
        props.CoachStatsSetFilterStatus(type, e)
    }

    const handleSelectCoach = (e) => {
        setSelectedCoach(JSON.parse(JSON.stringify(e)));
        // props.GetCoachStats(fromTimestamp, toTimestamp, e[0]?.coach_id)
    }

    const handleSelectFromTimestamp = (e) => {
        if (new Date(e) == "Invalid Date") return;
        setFromTimestamp(+new Date(e));
        // props.GetCoachStats(+new Date(e), toTimestamp, selectedCoach[0]?.coach_id)
    }
    const handleSelectToTimestamp = (e) => {
        if (new Date(e) == "Invalid Date") return;
        setToTimestamp(+new Date(e));
        // props.GetCoachStats(fromTimestamp, +new Date(e), selectedCoach[0]?.coach_id)
    }

    const handleGetCoachStats = () => {
        props.GetCoachStats(fromTimestamp, toTimestamp, selectedCoach[0]?.coach_id)
    }

    let dataWithSearch = JSON.parse(JSON.stringify(props.TrainingInfo.coachStats));

    const handleSetSearch = (e) => {
        setSearch(e.target.value)
    }

    if (search && search.length >= 1) {
        let words = search.toLowerCase().split(" ");
        let requests_save = JSON.parse(JSON.stringify(dataWithSearch));
        dataWithSearch = [];
        for (let i = 0; i < requests_save.length; i++) {
            let matches = 0;
            let types = requests_save[i].type ? requests_save[i].type.toLowerCase().split(" ") : [];
            let directions = requests_save[i].direction ? requests_save[i].direction.toLowerCase().split(" ") : [];
            let group_names = requests_save[i].group_name ? requests_save[i].group_name.toLowerCase().split(" ") : [];
            let coach_names = requests_save[i].coach_name ? requests_save[i].coach_name.toLowerCase().split(" ") : [];
            let coach_ids = requests_save[i].coach_id ? requests_save[i].coach_id.toLowerCase().split(" ") : [];
            let student_names = requests_save[i].student_name ? requests_save[i].student_name.toLowerCase().split(" ") : [];
            let student_ids = requests_save[i].student_id ? requests_save[i].student_id.toLowerCase().split(" ") : [];
            let topics = requests_save[i].topic ? requests_save[i].topic.toLowerCase().split(" ") : [];
            let zoom_links = requests_save[i].zoom_link ? requests_save[i].zoom_link.toLowerCase().split(" ") : [];
            let additional_infos = requests_save[i].additional_info ? requests_save[i].additional_info.toLowerCase().split(" ") : [];
            let replays = requests_save[i].replay ? requests_save[i].replay.toLowerCase().split(" ") : [];
            let blacklists = requests_save[i].blacklist ? requests_save[i].blacklist.toLowerCase().split(" ") : [];
            let sub_types = requests_save[i].subType ? requests_save[i].subType.toLowerCase().split(" ") : [];

            types.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            directions.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            group_names.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            coach_names.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            coach_ids.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            student_names.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            student_ids.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            topics.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            zoom_links.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            additional_infos.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            replays.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            blacklists.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })
            sub_types.forEach(e => {
                words.forEach(word => {
                    if (e == word) matches++;
                })
            })

            words.forEach(word => {
                if ((requests_save[i].type && requests_save[i].type.toLowerCase().includes(word)) ||
                    (requests_save[i].direction && requests_save[i].direction.toLowerCase().includes(word)) ||
                    (requests_save[i].group_name && requests_save[i].group_name.toLowerCase().includes(word)) ||
                    (requests_save[i].coach_name && requests_save[i].coach_name.toLowerCase().includes(word)) ||
                    (requests_save[i].coach_id && requests_save[i].coach_id.toLowerCase().includes(word)) ||
                    (requests_save[i].student_name && requests_save[i].student_name.toLowerCase().includes(word)) ||
                    (requests_save[i].student_id && requests_save[i].student_id.toLowerCase().includes(word)) ||
                    (requests_save[i].topic && requests_save[i].topic.toLowerCase().includes(word)) ||
                    (requests_save[i].zoom_link && requests_save[i].zoom_link.toLowerCase().includes(word)) ||
                    (requests_save[i].additional_info && requests_save[i].additional_info.toLowerCase().includes(word)) ||
                    (requests_save[i].replay && requests_save[i].replay.toLowerCase().includes(word)) ||
                    (requests_save[i].blacklist && requests_save[i].blacklist.toLowerCase().includes(word)) ||
                    (requests_save[i].subType && requests_save[i].subType.toLowerCase().includes(word))) matches++;
            })

            if (matches != 0) dataWithSearch.push(requests_save[i])
        }
    }

    return (
        <div>
            <div>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 50px 1fr 40px 1fr 40px 1fr",
                    padding: "50px 50px 10px 50px",
                    color: "white"
                }}>
                    <input type="date" onBlur={(e) => handleSelectFromTimestamp(e.target.value)}/>
                    <div></div>
                    <input type="date" onBlur={(e) => handleSelectToTimestamp(e.target.value)}/>
                    <div></div>
                    <CoachSelector options={props.TrainingInfo.coachesList}
                                   handleSelectCoach={handleSelectCoach}/>
                    <div></div>
                    <div className={"button default-button green-back cursor-pointer"}
                    style={{width: "95%", marginLeft: "30px"}}
                    onClick={handleGetCoachStats}>
                        Поиск
                    </div>
                </div>
                <hr style={{margin: "30px 50px 15px 50px"}}/>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    padding: "10px 50px 10px 30px",
                    color: "white"
                }}>
                    <GroupSelector selected={props.TrainingInfo.coachStatsSelectedGroupFilter}
                                   options={props.TrainingInfo.coachStatsGroupFilter}
                                   handleSelectFilterGroup={handleSelectFilterGroup}/>
                    <TypeSelector selected={props.TrainingInfo.coachStatsSelectedTypeFilter}
                                  options={props.TrainingInfo.coachStatsTypeFilter}
                                  handleSelectFilterType={handleSelectFilterType}/>
                    <StatusSelector selected={props.TrainingInfo.coachStatsSelectedStatusFilter}
                                    options={props.TrainingInfo.coachStatsStatusFilter}
                                    handleSelectFilterStatus={handleSelectFilterStatus}/>
                    <DifficultSelector selected={props.TrainingInfo.coachStatsSelectedDifficultFilter}
                                       options={props.TrainingInfo.coachStatsDifficultFilter}
                                       handleSelectFilterDifficult={handleSelectFilterDifficult}/>
                </div>
            </div>

            {
                props.TrainingInfo.coachStats && props.TrainingInfo.coachStats[0] &&
                <div>
                    <div style={{textAlign: "left", marginTop: "25px", padding: "0 2.5%"}}>
                        <input type="text" placeholder={"Поиск..."}
                               onBlur={(e) => handleSetSearch(e)}
                               style={{
                                   outline: "none",
                                   backgroundColor: "#33333d",
                                   border: "1px solid black",
                                   borderBottom: "none",
                                   padding: "3px 5px",
                                   color: "white",
                                   width: "300px"}}/>
                    </div>

                    <div className="support-users-list" style={{padding: "0 2.5%"}}>
                        <div className={"support-users-list-header"}
                             style={{gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr`}}>
                            <div className={"item"}>
                                Тема
                            </div>
                            <div className={"item"}>
                                Тип
                            </div>
                            <div className={"item"}>
                                Начало
                            </div>
                            <div className={"item"}>
                                Конец
                            </div>
                            <div className={"item"}>
                                Статус
                            </div>
                        </div>

                        {
                            dataWithSearch.map((training, i) => <TrainingRow key={`${training.topic} ${i}`}
                                                                                            creatorCoaches={props.TrainingInfo.creatorCoaches}
                                                                                            creatorInfo={props.TrainingInfo.creatorInfo}
                                                                                            training={training}
                                                                                            EditTraining={props.EditTraining} subTypesList={props.TrainingInfo.subTypesList}
                                                                                            isCoach={props.AuthInfo.isCoach} SendManualNotifications={props.SendManualNotifications}/>)
                        }
                    </div>
                </div>
            }
        </div>

    )
}

const TrainingRow = (props) => {
    // const from_date = moment(moment.tz(moment.unix(+props.training.from_timestamp / 1000), "Europe/Moscow"));
    // const to_date = moment(moment.tz(moment.unix(+props.training.to_timestamp / 1000), "Europe/Moscow"));


    const from_date = moment(props.training.from_timestamp);
    const to_date = moment(props.training.to_timestamp);

    const [confirmCancel, setConfirmCancel] = useState(false);

    let type = `Общая`;
    if (props.training.type == "group") type = `Групповая (${props.training.group_name})`;
    if (props.training.type == "stream") type = `Потоковая (${props.training.direction})`;
    if (props.training.type == "personal") type = `Персональная (${props.training.student_name})`;

    let directions = [];

    if (Object.keys(props.creatorInfo)[0]) {
        directions = [...Object.keys(props.creatorInfo)]
    }

    const handleSelectSubType = (e) => {
        if (e[0]) {
            props.EditTraining(props.training._id, "subType", e[0].name)
        } else {
            props.EditTraining(props.training._id, "subType", "Нет")
        }
    }

    const handleStartDateChange = (date) => {
        props.EditTraining(props.training._id, "from_timestamp", +date)
    };
    const handleEndDateChange = (date) => {
        props.EditTraining(props.training._id, "to_timestamp", +date)
    };
    const handleCancelTraining = () => {
        if (!confirmCancel) {
            setConfirmCancel(true)
            setTimeout(() => {
                setConfirmCancel(false)
            }, 5000)
            return;
        }
        props.EditTraining(props.training._id, "to_timestamp", 0)
    }

    const handleCancelClosedTraining = () => {
        props.EditTraining(props.training._id, "cancel_training", 0)
    }

    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            }
        }
    });
    return (
        <Popup key={props.training.topic + " " + "trainingList" + `${from_date} ${to_date}`}
               trigger={
                   <div className={"support-users-list-items"}
                        style={{gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr`}}>
                       <div className={"item"}>
                           {props.training.topic}
                       </div>
                       <div className={"item"} style={!props.training.zoom_link ? {color: "red"} : null}>
                           {type}
                       </div>
                       <div className={"item"}>
                           {from_date.format('DD.MM.YYYY - HH:mm')}
                       </div>
                       <div className={"item"}>
                           {to_date.format('DD.MM.YYYY - HH:mm')}
                       </div>
                       <div className={"item"}>
                           {

                               props.training.to_timestamp == 0 ?
                                   <span style={{color: "grey"}}>Отменено</span>
                                   :
                                   props.training.isClosed ? <span style={{color: "tomato"}}>Завершено</span> :
                                       <span style={{color: "lightgreen"}}>Запланировано</span>
                           }
                       </div>
                   </div>
               }
               modal
               nested
        >
            {(close) => (
                <div>
                    <div id="get-advice" className="main-popup">
                        <form className="form" style={{color: "white"}}>
                            {
                                props.training.to_timestamp > 0 && props.training.isClosed ?
                                    <div style={{position: "fixed", right: "20px", top: "10px"}}>

                                        <div className={"default-button red-back"}
                                             onClick={handleCancelClosedTraining}
                                             style={{backgroundColor: "red"}}>
                                            Отменить тренировку
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                props.training.to_timestamp == 0 || props.training.isClosed ?
                                    null
                                    :
                                    <div style={{position: "fixed", right: "20px", top: "10px"}}>
                                        <div className={"default-button red-back"}
                                             onClick={handleCancelTraining}
                                             style={confirmCancel ? {backgroundColor: "red"} : null}>
                                            {confirmCancel ? "Кликните снова, чтобы удалить" : "Отменить тренировку"}
                                        </div>
                                    </div>
                            }

                            {
                                    <div className="formgroup">
                                        <div className={"training-list-item-name"}>Тема</div>
                                        <input type="text" title={"Тема"} defaultValue={props.training.topic}
                                               placeholder={"Тема"}
                                               onBlur={(e) => props.EditTraining(props.training._id, "topic", e.target.value)}
                                        />
                                    </div>
                            }

                            {
                                props.isCoach ?
                                    <div className="formgroup">
                                        <span style={{color: "#9fa2c2", fontWeight: "bolder"}}>Тип:</span> {type}
                                    </div>
                                    :
                                    <div style={{marginTop: "-20px"}} className={"coach-training-editor"}>
                                        <div className={"name"}>
                                            Создатель
                                        </div>
                                        <div className={"info"}>
                                            <div>{props.training.created_by ? props.training.created_by : "Нет данных"}
                                            </div>
                                        </div>
                                    </div>
                            }

                            {
                                props.isCoach ?
                                    <div className="formgroup">
                                        <span style={{color: "#9fa2c2", fontWeight: "bolder"}}>Тип:</span> {type}
                                    </div>
                                    :
                                    <div style={{marginTop: "-20px"}} className={"coach-training-editor"}>
                                        <div className={"name"}>
                                            Тип
                                        </div>
                                        <div className={"info"}>
                                            <div>{type}
                                            </div>
                                        </div>
                                    </div>
                            }

                            <div style={{margin: "0 0 20px -15px"}}>
                                <SubTypeSelector
                                    selectedSubType={props.training.subType ? [{name: props.training.subType}] : []}
                                    options={props.subTypesList}
                                    handleSelectSubType={handleSelectSubType}/>
                            </div>



                            {
                                !props.training.isClosed ?
                                    <div>
                                        <div style={{
                                            filter: "brightness(0) invert(1)",
                                            textAlign: "center",
                                            marginTop: "-20px",
                                            marginBottom: "15px"
                                        }}>
                                            <ThemeProvider theme={defaultMaterialTheme}>

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DateTimePicker
                                                        format="dd.MM.yyyy HH:mm"
                                                        margin="normal"
                                                        label="Начало тренировки"
                                                        value={+props.training.from_timestamp}
                                                        onChange={handleStartDateChange}
                                                    />
                                                </MuiPickersUtilsProvider>

                                            </ThemeProvider>
                                        </div>

                                        <div style={{color: "#c7b465", fontSize: "12px", padding: "0 0 30px 0"}}>Важно! При изменении времени тренировки на время, которое меньше, чем через 4 дня от текущего времени - все уведомления по ней будут выключены, кроме одного (за 15 минут до тренировки). Ниже вы можете в любое время сделать рассылку нажатием кнопки "Оповестить о тренировке"</div>

                                        <div style={{margin: "0 0 30px 0"}} onClick={() => props.SendManualNotifications(props.training._id)} className={"default-button default-back transition cursor-pointer"}>Оповестить о тренировке</div>

                                    </div> :
                                    <div>
                                        <div style={{marginTop: "-20px"}}
                                             className={"coach-training-editor"}>
                                            <div className={"name"}>
                                                Начало занятия
                                            </div>
                                            <div className={"info"}>
                                                <div>{from_date.format('DD.MM.YYYY - HH:MM')}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{marginTop: "-20px"}}
                                             className={"coach-training-editor"}>
                                            <div className={"name"}>
                                                Конец занятия
                                            </div>
                                            <div className={"info"}>
                                                <div>{to_date.format('DD.MM.YYYY - HH:MM')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                            {
                                props.isCoach ? <div className="formgroup">
                                    <span style={{
                                        color: "#9fa2c2",
                                        fontWeight: "bolder"
                                    }}>Тренер:</span> {props.training.coach_name}
                                </div> : <div style={{marginTop: "-20px"}} className={"coach-training-editor"}>
                                    <div className={"name"}>
                                        Тренер
                                    </div>
                                    <div className={"info"} style={{cursor: "pointer"}}>
                                        <Popup key={`setCoach training-create ${props.training.coach_name}`}
                                               trigger={
                                                   <div>{props.training.coach_name ? props.training.coach_name : "Не выбрано"}<img
                                                       style={{width: "12px", margin: "0 10px"}} src={EditIcon}/>Изменить
                                                   </div>
                                               }
                                               modal
                                               nested
                                        >
                                            {() => (
                                                <div>
                                                    <div id="get-advice" className="user-page-popup">
                                                        <form className="form">
                                                            {
                                                                props.creatorCoaches.map((e, ei) => {
                                                                    return <div key={`${e.id} ${ei}`}
                                                                                onClick={() => {
                                                                                    props.EditTraining(props.training._id, "coach", {
                                                                                        id: e.id, nick: e.username
                                                                                    })
                                                                                }}
                                                                                className={"role-page-access-block default-back"}>{e.username}
                                                                    </div>
                                                                })
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                    </div>
                                </div>

                            }

                            {
                                props.training.type != "all" ?
                                    props.isCoach ? <div className="formgroup">
                                            <span style={{
                                                color: "#9fa2c2",
                                                fontWeight: "bolder"
                                            }}>Направление:</span> {props.training.direction}
                                        </div> :
                                        <div style={{marginTop: "-20px"}} className={"coach-training-editor"}>
                                            <div className={"name"}>
                                                Направление
                                            </div>
                                            <div className={"info"} style={{cursor: "pointer"}}>
                                                <Popup key={`setDirection training-create ${props.training.direction}`}
                                                       trigger={
                                                           <div>{props.training.direction ? props.training.direction : "Не выбрано"}<img
                                                               style={{width: "12px", margin: "0 10px"}}
                                                               src={EditIcon}/>Изменить
                                                           </div>
                                                       }
                                                       modal
                                                       nested
                                                >
                                                    {() => (
                                                        <div>
                                                            <div id="get-advice" className="user-page-popup">
                                                                <form className="form">
                                                                    {
                                                                        directions.map((e, ei) => {
                                                                            return <div key={`${e} ${ei}`}
                                                                                        onClick={() => props.EditTraining(props.training._id, "direction", e)}
                                                                                        className={"role-page-access-block default-back"}>{e}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                        </div>
                                    : null
                            }
                            {
                                props.training.type == "group" ?
                                    props.isCoach ?
                                        <div className="formgroup">
                                            <span style={{
                                                color: "#9fa2c2",
                                                fontWeight: "bolder"
                                            }}>Группа:</span> {props.training.group_name}
                                        </div>
                                        :
                                        <div style={{marginTop: "-20px"}} className={"coach-training-editor"}>
                                            <div className={"name"}>
                                                Группа
                                            </div>
                                            <div className={"info"} style={{cursor: "pointer"}}>
                                                <Popup key={`setGroup training-create ${props.training.group_name}`}
                                                       trigger={
                                                           <div>{props.training.group_name ? props.training.group_name : "Не выбрано"}<img
                                                               style={{width: "12px", margin: "0 10px"}}
                                                               src={EditIcon}/>Изменить
                                                           </div>
                                                       }
                                                       modal
                                                       nested
                                                >
                                                    {() => (
                                                        <div>
                                                            <div id="get-advice" className="user-page-popup">
                                                                <form className="form">
                                                                    {
                                                                        props.creatorInfo[props.training.direction]?.groups?.map((e, ei) => {
                                                                            return <div key={`${e} ${ei}`}
                                                                                        onClick={() => props.EditTraining(props.training._id, "group_name", e)}
                                                                                        className={"role-page-access-block default-back"}>{e}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                        </div>
                                    : null
                            }
                            {
                                props.training.type == "personal" ?
                                    props.isCoach ?
                                        <div className="formgroup">
                                            <span style={{
                                                color: "#9fa2c2",
                                                fontWeight: "bolder"
                                            }}>Ученик:</span> {props.training.student_name}
                                        </div>
                                        :
                                        <div style={{marginTop: "-20px"}} className={"coach-training-editor"}>
                                            <div className={"name"}>
                                                Ученик
                                            </div>
                                            <div className={"info"} style={{cursor: "pointer"}}>
                                                <Popup
                                                    key={`setStudent training-create ${props.training.student_name} ${props.training.student_id} ${props.training._id}`}
                                                    trigger={
                                                        <div>{props.training.student_name ? props.training.student_name : "Не выбрано"}<img
                                                            style={{width: "12px", margin: "0 10px"}} src={EditIcon}/>Изменить
                                                        </div>
                                                    }
                                                    modal
                                                    nested
                                                >
                                                    {() => (
                                                        <div>
                                                            <div id="get-advice" className="user-page-popup">
                                                                <form className="form">
                                                                    {
                                                                        props.creatorInfo[props.training.direction]?.students?.map((e, ei) => {
                                                                            return <div
                                                                                key={`${e.user_id} ${ei} ${props.training._id} ${props.training.from_timestamp}`}
                                                                                onClick={() => {
                                                                                    props.EditTraining(props.training._id, "student", {
                                                                                        id: e.user_id, nick: e.nick
                                                                                    })
                                                                                }}
                                                                                className={"role-page-access-block default-back"}>{e.nick}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                        </div>
                                    : null
                            }
                            <div className="formgroup">
                                <div className={"training-list-item-name"}>Zoom</div>
                                <input type="text" title={"Zoom"} defaultValue={props.training.zoom_link}
                                       placeholder={"Zoom"}
                                       onBlur={(e) => props.EditTraining(props.training._id, "zoom_link", e.target.value)}
                                />
                            </div>
                            <div className="formgroup">
                                <div className={"training-list-item-name"}>Дополнительная информация</div>
                                <input type="text" title={"Дополнительная информация"}
                                       defaultValue={props.training.additional_info}
                                       placeholder={"Дополнительная информация"}
                                       onBlur={(e) => props.EditTraining(props.training._id, "additional_info", e.target.value)}
                                />
                            </div>
                            {
                                props.isCoach ? null :
                                    <div className="formgroup">
                                        <div className={"training-list-item-name"}>Запись тренировки</div>
                                        <input type="text" title={"Запись тренировки"}
                                               defaultValue={props.training.replay}
                                               placeholder={"Запись тренировки"}
                                               onBlur={(e) => props.EditTraining(props.training._id, "replay", e.target.value)}
                                        />
                                    </div>
                            }
                            {
                                props.isCoach ? null :
                                    <div className="formgroup">
                                        <div className={"training-list-item-name"}>Кому не отправлять (ID's через
                                            пробелы)
                                        </div>
                                        <input type="text" title={"Кому не отправлять (ID's через пробелы)"}
                                               defaultValue={props.training.blacklist}
                                               placeholder={"Кому не отправлять (ID's через пробелы)"}
                                               onBlur={(e) => props.EditTraining(props.training._id, "blacklist", e.target.value)}
                                        />
                                    </div>
                            }
                            <div className={"default-button green-back"} style={{padding: "20px 0"}}
                                 onClick={close}>
                                ОК
                            </div>

                            {
                                props.training.to_timestamp == 0 || props.training.isClosed ?
                                    <Popup
                                        key={props.training.topic + " " + "checkFeedback" + `${from_date} ${to_date}`}
                                        trigger={<div className={"default-button default-back"}>Посмотреть отзывы</div>}
                                        modal
                                        nested
                                    >
                                        {() => (
                                            <div>
                                                <div id="get-advice" className="main-popup"
                                                     style={{textAlign: "left", color: "white"}}>
                                                    {
                                                        props.training.lk_feedbacks ?
                                                            <div
                                                                dangerouslySetInnerHTML={{__html: props.training.lk_feedbacks}}/>
                                                            : "Отзывов о тренировке нет"
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                    :
                                    null
                            }
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}