import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from 'redux-thunk';
import mainReducer from "./reducers/mainReducer";
import authReducer from "./reducers/authReducer";
import wikiReducer from "./reducers/wikiReducer";
import rolesReducer from "./reducers/rolesReducer";
import pagesReducer from "./reducers/pagesReducer";
import adminReducer from "./reducers/adminReducer";
import categoriesReducer from "./reducers/categoriesReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import diskReducer from "./reducers/diskReducer";
import learnReducer from "./reducers/learnReducer";
import supportReducer from "./reducers/supportReducer";
import auditorReducer from "./reducers/auditorReducer";
import fsChangerReducer from "./reducers/fsChangerReducer";
import trainingReducer from "./reducers/trainingReducer";
import formularReducer from "./reducers/formularReducer";
import schedulerReducer from "./reducers/schedulerReducer";
import referralReducer from "./reducers/referralReducer";
import leakfinderReducer from "./reducers/leakfinderReducer";
import salaryReducer from "./reducers/salaryReducer";
import devReducer from "./reducers/devReducer";
import leaderboardReducer from "./reducers/leaderboardReducer";
import leakfinderSpinReducer from "./reducers/leakfinderSpinReducer";
import sharkReducer from "./reducers/sharkReducer";
import coaReducer from "./reducers/coaReducer";
import requisitesReducer from "./reducers/requisitesReducer";

let reducers = combineReducers({
    mainReducer, authReducer, wikiReducer, rolesReducer, pagesReducer,
    adminReducer, categoriesReducer, notificationsReducer, diskReducer,
    learnReducer, supportReducer, auditorReducer, fsChangerReducer,
    trainingReducer, formularReducer, schedulerReducer, referralReducer,
    leakfinderReducer, salaryReducer, leaderboardReducer, devReducer, leakfinderSpinReducer, sharkReducer,
    coaReducer, requisitesReducer
});

let store = createStore(reducers, applyMiddleware(thunkMiddleware));

export default store;