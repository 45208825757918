import React, {useEffect, useRef, useState} from 'react';
import {Tooltip} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import s from './styles.module.css'

export default (props) => {
    const [ascSort, setAscSort] = useState('inactive');
    const [descSort, setDescSort] = useState('inactive');
    const [noSort, setNoSort] = useState('inactive');
    const refButton = useRef(null);

    const onMenuClicked = () => {
        props.showColumnMenu(refButton.current);
    };

    const onSortChanged = () => {
        setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
        setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
        setNoSort(
            !props.column.isSortAscending() && !props.column.isSortDescending()
                ? 'active'
                : 'inactive'
        );
    };

    const onSortRequested = (order, event) => {
        props.setSort(order, event.shiftKey);
    };

    useEffect(() => {
        props.column.addEventListener('sortChanged', onSortChanged);
        onSortChanged();
    }, []);

    let menu = null;
    if (props.enableMenu) {
        menu = (
            <div
                ref={refButton}
                className="customHeaderMenuButton"
                onClick={() => onMenuClicked()}
            >
                <i className={`fa ${props.menuIcon}`}></i>
            </div>
        );
    }

    return (
        <div onClick={(event) => {
            if (!props.enableSorting) return
            if (noSort === 'active') onSortRequested('asc', event)
            if (ascSort === 'active') onSortRequested('desc', event)
            if (descSort === 'active') onSortRequested('', event)
        }} className={s.container_header}>
            <Tooltip title={props.displayName} placement="top-start" arrow>
                <div className="customHeaderLabel">{props.displayName} {props.enableSorting &&
                    <div style={{display: 'inline-block'}}>
                        {ascSort === 'active' && <ArrowDropDownIcon className={s.top_svg}/>}
                        {descSort === 'active' && <ArrowDropUpIcon className={s.top_svg}/>}
                        {/*{noSort === 'active' && <div className={s.no_sort}*/}
                        {/*>*/}

                        {/*    <ArrowDropUpIcon className={s.top_svg_together}/>*/}
                        {/*    <ArrowDropDownIcon className={s.top_svg_together}/>*/}
                        {/*</div>}*/}
                    </div>}</div>
            </Tooltip>


        </div>
    );
};
