import React, {useState} from 'react';
import Container from "../container";
import s from './styles.module.css'
import table4 from '../../../../assests/table4.png'
import {PlayerChangeStatus, PlayerTransferChangeStatus} from "../../../../../redux/reducers/mainReducer";
import moment from "moment";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import ImgField from "../../../../common/fields/imgField";
import ColorText from "../../../../common/fields/colorText";
import NumberField from "../../../../common/fields/numberField";
import NumberCeilField from "../../../../common/fields/numberCeilField";
import NumberToFixed1Field from "../../../../common/fields/numberToFixed1Field";
import NumberToFixed2Field from "../../../../common/fields/numberToFixed2Field";
import TimestampToDateField from "../../../../common/fields/timestampToDateField";
import TimestampToTimeField from "../../../../common/fields/timestampToTimeField";
import TimestampToPeriodField from "../../../../common/fields/timestampToPeriodField";
import TextField from "../../../../common/fields/textField";
import AcceptButtonField from "../../../../common/fields/acceptButtonField";
import DeclineButtonField from "../../../../common/fields/declineButtonField";
import OpenUrlButton from "../../../../common/fields/openUrlButton";
import TrainingRemoveField from "../../../../common/fields/trainingRemoveField";

const FourthContent = (props) => {
    const [timestampFrom, setTimestampFrom] = useState(null);
    const [timestampTo, setTimestampTo] = useState(null);
    const [search, setSearch] = useState("");

    const handleSetTimestampFrom = (e) => {
        const date = new Date(e);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        setTimestampFrom(+date);
        props.UserSetStatusFilter(false, search, +date, timestampTo);
    }
    const handleSetTimestampTo = (e) => {
        const date = new Date(e);
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        setTimestampTo(+date);
        props.UserSetStatusFilter(false, search, timestampFrom, +date);
    }
    const handleSetSearch = (e) => {
        setSearch(e);
        props.UserSetStatusFilter(false, e, timestampFrom, timestampTo);
    }

    return (
        <Container top='35px'>
            <div className={s.statusFilters} style={{margin: "0 0 20px 0", fontSize: "13px", width: "100%", display: "flex", flexFlow: "wrap" }}>
                <div style={{
                    margin: "5px 10px",
                }}>
                    <input onChange={(e) => handleSetSearch(e.target.value)} value={search} style={{width: "300px",padding: "5px 5px", borderRadius: "5px", outline: "none"}} type="text" placeholder={"Поиск..."}/>
                </div>
                <div style={{

                    margin: "5px 10px",
                }}>
                    <input onBlur={(e) => handleSetTimestampFrom(e.target.value)} style={{padding: "4px 5px", borderRadius: "5px", outline: "none"}} type="date"/>
                    <input onBlur={(e) => handleSetTimestampTo(e.target.value)} style={{padding: "4px 5px", borderRadius: "5px", outline: "none"}} type="date"/>
                </div>
                <div style={{
                    padding: "6px 15px",
                    margin: "5px 10px",
                    backgroundColor: `${props.TrainingInfo.userStatusFilters.includes("Тренировка в расписании") ? "#329447" : "#4f4f4f"}`,
                    color: "white",
                    borderRadius: "5px",
                    cursor: "pointer"
                }} onClick={() => props.UserSetStatusFilter("Тренировка в расписании", search, timestampFrom, timestampTo)}>
                    Будущие
                </div>
                <div style={{
                    padding: "6px 15px",
                    margin: "5px 10px",
                    backgroundColor: `${props.TrainingInfo.userStatusFilters.includes("На рассмотрении") ? "#329447" : "#4f4f4f"}`,
                    color: "white",
                    borderRadius: "5px",
                    cursor: "pointer"
                }} onClick={() => props.UserSetStatusFilter("На рассмотрении", search, timestampFrom, timestampTo)}>
                    На рассмотрении
                </div>
                <div style={{
                    padding: "6px 15px",
                    margin: "5px 10px",
                    backgroundColor: `${props.TrainingInfo.userStatusFilters.includes("Тренировка завершена") ? "#329447" : "#4f4f4f"}`,
                    color: "white",
                    borderRadius: "5px",
                    cursor: "pointer"
                }} onClick={() => props.UserSetStatusFilter("Тренировка завершена", search, timestampFrom, timestampTo)}>
                    Прошедшие
                </div>
                <div style={{
                    padding: "6px 15px",
                    margin: "5px 10px",
                    backgroundColor: `${props.TrainingInfo.userStatusFilters.includes("Тренировка отменена") ? "#329447" : "#4f4f4f"}`,
                    color: "white",
                    borderRadius: "5px",
                    cursor: "pointer"
                }} onClick={() => props.UserSetStatusFilter("Тренировка отменена", search, timestampFrom, timestampTo)}>
                    Отмененные
                </div>
                <div style={{
                    padding: "6px 15px",
                    margin: "5px 10px",
                    backgroundColor: `${props.TrainingInfo.userStatusFilters.includes("Отклонено") ? "#329447" : "#4f4f4f"}`,
                    color: "white",
                    borderRadius: "5px",
                    cursor: "pointer"
                }} onClick={() => props.UserSetStatusFilter("Отклонено", search, timestampFrom, timestampTo)}>
                    Отклоненные
                </div>
                <div style={{
                    padding: "6px 15px",
                    margin: "5px 10px",
                    backgroundColor: `${props.TrainingInfo.userStatusFilters.includes("Запрос устарел") ? "#329447" : "#4f4f4f"}`,
                    color: "white",
                    borderRadius: "5px",
                    cursor: "pointer",
                }} onClick={() => props.UserSetStatusFilter("Запрос устарел", search, timestampFrom, timestampTo)}>
                    Устаревшие
                </div>
            </div>
            <div className='ag-theme-alpine-dark' style={{height: 620, width: '100%', position: 'relative'}}>
                <AgGridReact
                    style={{height: 620, width: '100%'}}
                    rowData={props.TrainingInfo.userRequests}
                    enableBrowserTooltips={true}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowHeight={70}
                    defaultColDef={{
                        editable: true,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        filter: false,
                        floatingFilter: false,
                        resizable: true,
                    }}
                    frameworkComponents={{
                        imgField: ImgField,
                        colorText: ColorText,
                        numberField: NumberField,
                        numberCeilField: NumberCeilField,
                        numberToFixed1Field: NumberToFixed1Field,
                        numberToFixed2Field: NumberToFixed2Field,
                        timestampToDateField: TimestampToDateField,
                        timestampToTimeField: TimestampToTimeField,
                        timestampToPeriodField: TimestampToPeriodField,
                        textField: TextField,
                        acceptButtonField: AcceptButtonField,
                        declineButtonField: DeclineButtonField,
                        openUrlButton: OpenUrlButton,
                        trainingRemoveField: TrainingRemoveField
                    }}
                >
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Дата'}
                        field={'timestamp'}
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='timestampToDateField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Время'}
                        field='timestamp'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='timestampToTimeField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Тренер'}
                        field='coachName'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Статус'}
                        field='status'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='colorText'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Тема'}
                        field='user_topic'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Запись'}
                        field={'blablabla'}
                        editable={false}
                        sortable={false}
                        filter={false}
                        cellRenderer='openUrlButton'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Причина отмены'}
                        field='reason'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'⠀'}
                        field={'blablabla'}
                        editable={false}
                        sortable={false}
                        filter={false}
                        cellRenderer='trainingRemoveField'
                    />
                </AgGridReact>
            </div>
        </Container>
    );
};

export default FourthContent;