import React, {useState} from 'react';
import moment from "moment/moment";
import Popup from "reactjs-popup";
import Select from "react-dropdown-select";
import {COA_EditRow, COA_RemoveRow} from "../../../../redux/reducers/coaReducer";

const ParentSelector_LVL_2 = (props) => {
    return (
        <Select
            placeholder={"Выберите зависимость с LVL 1"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#232531",
                border: "none",
                outline: "none",
                borderRadius: "300px",
                zIndex: "104",
                color: "white",
                padding: "5px 10px 5px 10px"
            }}
        />
    )
}
const ParentSelector_LVL_3 = (props) => {
    return (
        <Select
            placeholder={"Выберите зависимость с LVL 2"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#232531",
                border: "none",
                outline: "none",
                borderRadius: "300px",
                zIndex: "103",
                color: "white",
                padding: "5px 10px 5px 10px"
            }}
        />
    )
}
const ParentSelector_LVL_4 = (props) => {
    return (
        <Select
            placeholder={"Выберите зависимость с LVL 3"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#232531",
                border: "none",
                outline: "none",
                borderRadius: "300px",
                zIndex: "103",
                color: "white",
                padding: "5px 10px 5px 10px"
            }}
        />
    )
}

const CoaPage = (props) => {
    const [creatorSelectedLvl, setCreatorSelectedLvl] = useState(1);

    return (
        <div>
            <Popup key={`coa_creator ${props.COAInfo.rows_all.length}`}
                   trigger={
                       <div className={"button default-button default-back"}>
                           Добавить новую строку
                       </div>}
                   modal
                   nested
            >
                {() => (
                    <div>
                        <div id="get-advice" className="main-popup">
                            <div style={{
                                marginTop: "-40px",
                                display: "grid",
                                gridTemplateColumns: "1fr 70px 4px 70px 4px 70px 4px 70px 1fr",
                                color: "white",
                                textAlign: "center"
                            }}>
                                <div></div>
                                <div style={{
                                    cursor: "pointer", borderRadius: "300px",
                                    backgroundColor: creatorSelectedLvl == 1 ? "#49793e" : "#232531"
                                }}
                                     onClick={() => setCreatorSelectedLvl(1)}>LVL 1
                                </div>
                                <div></div>
                                <div style={{
                                    cursor: "pointer", borderRadius: "300px",
                                    backgroundColor: creatorSelectedLvl == 2 ? "#49793e" : "#232531"
                                }}
                                     onClick={() => setCreatorSelectedLvl(2)}>LVL 2
                                </div>
                                <div></div>
                                <div style={{
                                    cursor: "pointer", borderRadius: "300px",
                                    backgroundColor: creatorSelectedLvl == 3 ? "#49793e" : "#232531"
                                }}
                                     onClick={() => setCreatorSelectedLvl(3)}>LVL 3
                                </div>
                                <div></div>
                                <div style={{
                                    cursor: "pointer", borderRadius: "300px",
                                    backgroundColor: creatorSelectedLvl == 4 ? "#49793e" : "#232531"
                                }}
                                     onClick={() => setCreatorSelectedLvl(4)}>LVL 4
                                </div>
                                <div></div>
                            </div>
                            {
                                creatorSelectedLvl == 1 && <Creator_LVL_1 COA_CreateRow={props.COA_CreateRow}/>
                            }
                            {
                                creatorSelectedLvl == 2 &&
                                <Creator_LVL_2 COA_CreateRow={props.COA_CreateRow} COAInfo={props.COAInfo}/>
                            }
                            {
                                creatorSelectedLvl == 3 &&
                                <Creator_LVL_3 COA_CreateRow={props.COA_CreateRow} COAInfo={props.COAInfo}/>
                            }
                            {
                                creatorSelectedLvl == 4 &&
                                <Creator_LVL_4 COA_CreateRow={props.COA_CreateRow} COAInfo={props.COAInfo}/>
                            }
                        </div>
                    </div>
                )}
            </Popup>

            <div style={{color: "white", marginTop: "30px", fontSize: "14px"}}>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "55px 55px 55px 55px 1fr 100px 100px 100px 1fr 24px",
                    marginBottom: "10px"
                }}>
                    <div>LVL 1</div>
                    <div>LVL 2</div>
                    <div>LVL 3</div>
                    <div>LVL 4</div>
                    <div>Название</div>
                    <div>Type</div>
                    <div>Group</div>
                    <div>Active</div>
                    <div>Описание</div>
                    <div></div>
                </div>
                {
                    props.COAInfo.rows_all.map((row, i) => {
                        return <div style={{
                            backgroundColor: i % 2 == 0 ? "#4c516a" : "initial",
                            display: "grid", gridTemplateColumns: "55px 55px 55px 55px 1fr 100px 100px 100px 1fr 24px"
                        }}>
                            <div>{!row.lvl_1_refer && !row.lvl_2_refer && !row.lvl_3_refer ? row.public_id : ""}</div>
                            <div>{row.lvl_1_refer ? row.public_id : ""}</div>
                            <div>{row.lvl_2_refer ? row.public_id : ""}</div>
                            <div>{row.lvl_3_refer ? row.public_id : ""}</div>
                            <div>{row.name}</div>
                            <div>{row.type ? row.type : ""}</div>
                            <div>{row.group ? row.group : ""}</div>
                            <div>{row.lvl_3_refer ? `${row.is_active}` : ""}</div>
                            <div>{row.description ? row.description : ""}</div>
                            <Popup
                                key={`coa_editor ${row.name}${row.type}${row.group}${row.is_active}${row.description}${i}`}
                                trigger={
                                    <div style={{cursor: "pointer"}}>✏️</div>}
                                modal
                                nested
                            >
                                {() => (
                                    <div>
                                        <div id="get-advice" className="main-popup">
                                            {
                                                !row.lvl_1_refer && !row.lvl_2_refer && !row.lvl_3_refer &&
                                                <div>
                                                    <Editor_LVL_1_2_3 lvl={1} row={row} COA_EditRow={props.COA_EditRow} COA_RemoveRow={props.COA_RemoveRow} rows_all_length={props.COAInfo.rows_all.length}/>
                                                </div>
                                            }
                                            {
                                                row.lvl_1_refer &&
                                                <div>
                                                    <Editor_LVL_1_2_3 lvl={2} row={row} COA_EditRow={props.COA_EditRow} COA_RemoveRow={props.COA_RemoveRow} rows_all_length={props.COAInfo.rows_all.length}/>
                                                </div>
                                            }
                                            {
                                                row.lvl_2_refer &&
                                                <div>
                                                    <Editor_LVL_1_2_3 lvl={3} row={row} COA_EditRow={props.COA_EditRow} COA_RemoveRow={props.COA_RemoveRow} rows_all_length={props.COAInfo.rows_all.length}/>
                                                </div>
                                            }
                                            {
                                                row.lvl_3_refer &&
                                                <div>
                                                    <Editor_LVL_4 lvl={4} row={row} COA_EditRow={props.COA_EditRow} COA_RemoveRow={props.COA_RemoveRow} rows_all_length={props.COAInfo.rows_all.length}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

const Editor_LVL_1_2_3 = (props) => {
    const [selectedKey, setSelectedKey] = useState("name");
    const [newValue, setNewValue] = useState(props.row.name);

    const handleSelectKey = (key) => {
        setSelectedKey(key);
        setNewValue(props.row[key]);
    }

    const handleEdit = () => {
        props.COA_EditRow({public_id: props.row.public_id, key: selectedKey, newValue}, props.lvl)
    }

    return (
        <div>
            <div style={{
                width: "500px",
                marginTop: "-40px",
                display: "grid",
                gridTemplateColumns: "1fr",
                color: "white",
                textAlign: "center"
            }}>
                <div style={{
                    cursor: "pointer", borderRadius: "300px",
                    backgroundColor: selectedKey == "name" ? "#49793e" : "#232531"
                }}
                     onClick={() => handleSelectKey("name")}>Название
                </div>
            </div>

            <div style={{marginTop: "30px"}}>
                <input style={{
                    backgroundColor: "#232531",
                    width: "100%",
                    border: "none",
                    borderRadius: "300px",
                    padding: "7px 10px 7px 10px",
                    outline: "none",
                    color: "white"
                }}
                       type="text" placeholder={"Введите новое значение"} value={newValue} onChange={(e) => setNewValue(e.target.value)}/>

                <div style={{marginTop: "30px", marginBottom: "-30px"}}
                     className={"button default-button green-back"} onClick={handleEdit}>
                    Изменить
                </div>

                <Popup
                    key={`coa_remover ${props.rows_all_length}`}
                    trigger={
                        <div style={{marginTop: "40px", marginBottom: "-30px"}}
                             className={"button default-button red-back"}>
                            Удалить строку
                        </div>
                    }
                    modal
                    nested
                >
                    {() => (
                        <div>
                            <div id="get-advice" className="main-popup" style={{color: "white", textAlign: "center"}}>
                                <div>Вы уверены в своём действии?</div>
                                <div className={"button default-button red-back"} style={{marginTop: "20px"}} onClick={() => props.COA_RemoveRow(props.row.public_id, props.lvl)}>
                                    Да, удалить строку
                                </div>
                            </div>

                        </div>
                    )}
                </Popup>
            </div>
        </div>
    )
}
const Editor_LVL_4 = (props) => {
    const [selectedKey, setSelectedKey] = useState("name");
    const [newValue, setNewValue] = useState(props.row.name);

    const handleSelectKey = (key) => {
        setSelectedKey(key);
        setNewValue(props.row[key]);
    }

    const handleEdit = () => {
        props.COA_EditRow({public_id: props.row.public_id, key: selectedKey, newValue}, props.lvl)
    }

    return (
        <div>
            <div style={{
                width: "500px",
                marginTop: "-40px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                color: "white",
                textAlign: "center"
            }}>
                <div style={{
                    cursor: "pointer", borderRadius: "300px",
                    backgroundColor: selectedKey == "name" ? "#49793e" : "#232531"
                }}
                     onClick={() => handleSelectKey("name")}>Название
                </div>
                <div style={{
                    cursor: "pointer", borderRadius: "300px",
                    backgroundColor: selectedKey == "type" ? "#49793e" : "#232531"
                }}
                     onClick={() => handleSelectKey("type")}>Type
                </div>
                <div style={{
                    cursor: "pointer", borderRadius: "300px",
                    backgroundColor: selectedKey == "group" ? "#49793e" : "#232531"
                }}
                     onClick={() => handleSelectKey("group")}>Group
                </div>
                <div style={{
                    cursor: "pointer", borderRadius: "300px",
                    backgroundColor: selectedKey == "description" ? "#49793e" : "#232531"
                }}
                     onClick={() => handleSelectKey("description")}>Описание
                </div>
                <div style={{
                    cursor: "pointer", borderRadius: "300px",
                    backgroundColor: selectedKey == "is_active" ? "#49793e" : "#232531"
                }}
                     onClick={() => handleSelectKey("is_active")}>Active
                </div>
            </div>

            <div style={{marginTop: "30px"}}>
                {
                    selectedKey == "is_active" ?
                        <input style={{
                            backgroundColor: "#232531",
                            width: "100%",
                            height: "50px",
                            border: "none",
                            padding: "20px",
                            outline: "none",
                            color: "white"
                        }}
                               type="checkbox" placeholder={"Введите новое значение"} checked={newValue} onChange={(e) => setNewValue(e.target.checked)}/>
                        :
                        <input style={{
                            backgroundColor: "#232531",
                            width: "100%",
                            border: "none",
                            borderRadius: "300px",
                            padding: "7px 10px 7px 10px",
                            outline: "none",
                            color: "white"
                        }}
                               type="text" placeholder={"Введите новое значение"} value={newValue} onChange={(e) => setNewValue(e.target.value)}/>
                }

                <div style={{marginTop: "30px", marginBottom: "-30px"}}
                     className={"button default-button green-back"} onClick={handleEdit}>
                    Изменить
                </div>

                <Popup
                    key={`coa_remover ${props.rows_all_length}`}
                    trigger={
                        <div style={{marginTop: "40px", marginBottom: "-30px"}}
                             className={"button default-button red-back"}>
                            Удалить строку
                        </div>
                    }
                    modal
                    nested
                >
                    {() => (
                        <div>
                            <div id="get-advice" className="main-popup" style={{color: "white", textAlign: "center"}}>
                                <div>Вы уверены в своём действии?</div>
                                <div className={"button default-button red-back"} style={{marginTop: "20px"}} onClick={() => props.COA_RemoveRow(props.row.public_id, props.lvl)}>
                                    Да, удалить строку
                                </div>
                            </div>

                        </div>
                    )}
                </Popup>
            </div>
        </div>
    )
}

const Creator_LVL_1 = (props) => {
    const [name, setName] = useState("");

    const handleSend = () => {
        props.COA_CreateRow({name}, 1)
    }

    return (
        <div style={{marginTop: "30px"}}>
            <input style={{
                backgroundColor: "#232531",
                width: "100%",
                border: "none",
                borderRadius: "300px",
                padding: "7px 10px 7px 10px",
                outline: "none",
                color: "white"
            }}
                   type="text" placeholder={"Введите название"} value={name} onChange={(e) => setName(e.target.value)}/>
            {
                name && <div style={{marginTop: "30px", marginBottom: "-30px"}}
                             className={"button default-button green-back"} onClick={handleSend}>
                    Добавить
                </div>
            }
        </div>
    )
}
const Creator_LVL_2 = (props) => {
    const [name, setName] = useState("");
    const [selectedParent, setSelectedParent] = useState([]);

    const handleSelect = (e) => {
        if (e[0]) {
            setSelectedParent(e)
        } else {
            setSelectedParent([])
        }
    }

    const handleSend = () => {
        if (selectedParent && selectedParent[0]) {
            props.COA_CreateRow({name, ref_id: selectedParent[0].id, ref_public_id: selectedParent[0].public_id}, 2)
        }
    }

    return (
        <div style={{marginTop: "30px"}}>
            <div style={{marginBottom: "10px"}}>
                <ParentSelector_LVL_2 options={props.COAInfo.rows_lvl_1}
                                      selected={selectedParent}
                                      handleSelect={handleSelect}/>
            </div>
            {
                selectedParent && selectedParent[0] && <input style={{
                    backgroundColor: "#232531",
                    width: "100%",
                    border: "none",
                    borderRadius: "300px",
                    padding: "7px 10px 7px 10px",
                    outline: "none",
                    color: "white"
                }}
                                                              type="text" placeholder={"Введите название"} value={name}
                                                              onChange={(e) => setName(e.target.value)}/>
            }
            {name && <div style={{marginTop: "30px", marginBottom: "-30px"}}
                          className={"button default-button green-back"} onClick={handleSend}>
                Добавить
            </div>
            }
        </div>
    )
}
const Creator_LVL_3 = (props) => {
    const [name, setName] = useState("");
    const [selectedParent, setSelectedParent] = useState([]);
    const [parentData2, setParentData2] = useState([])
    const [selectedParent2, setSelectedParent2] = useState([]);

    const handleSelect = (e) => {
        if (e[0]) {
            setSelectedParent(e);
            let pd2 = [];
            props.COAInfo.rows_lvl_2.forEach(row => {
                if (row.lvl_1_refer == e[0].id) pd2.push(row)
            });
            setParentData2(pd2);
        } else {
            setSelectedParent([])
            setParentData2([])
            setSelectedParent2([])
        }
    }
    const handleSelectParent2 = (e) => {
        if (e[0]) {
            setSelectedParent2(e);
        } else {
            setSelectedParent2([])
        }
    }

    const handleSend = () => {
        if (selectedParent && selectedParent[0]) {
            props.COA_CreateRow({name, ref_id: selectedParent2[0].id, ref_public_id: selectedParent2[0].public_id}, 3)
        }
    }

    return (
        <div style={{marginTop: "30px"}}>
            <div style={{marginBottom: "10px"}}>
                <ParentSelector_LVL_2 options={props.COAInfo.rows_lvl_1}
                                      selected={selectedParent}
                                      handleSelect={handleSelect}/>
            </div>
            {
                selectedParent && selectedParent[0] && <div style={{marginBottom: "10px"}}>
                    <ParentSelector_LVL_3 options={parentData2}
                                          selected={selectedParent2}
                                          handleSelect={handleSelectParent2}/>
                </div>
            }
            {
                selectedParent2 && selectedParent2[0] && <input style={{
                    backgroundColor: "#232531",
                    width: "100%",
                    border: "none",
                    borderRadius: "300px",
                    padding: "7px 10px 7px 10px",
                    outline: "none",
                    color: "white"
                }}
                                                                type="text" placeholder={"Введите название"}
                                                                value={name} onChange={(e) => setName(e.target.value)}/>
            }
            {
                name && <div style={{marginTop: "30px", marginBottom: "-30px"}}
                             className={"button default-button green-back"} onClick={handleSend}>
                    Добавить
                </div>
            }
        </div>
    )
}
const Creator_LVL_4 = (props) => {
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [group, setGroup] = useState("");
    const [description, setDescription] = useState("");

    const [selectedParent, setSelectedParent] = useState([]);
    const [parentData2, setParentData2] = useState([])
    const [selectedParent2, setSelectedParent2] = useState([]);
    const [parentData3, setParentData3] = useState([])
    const [selectedParent3, setSelectedParent3] = useState([]);

    const handleSelect = (e) => {
        if (e[0]) {
            setSelectedParent(e);
            let pd2 = [];
            props.COAInfo.rows_lvl_2.forEach(row => {
                if (row.lvl_1_refer == e[0].id) pd2.push(row)
            });
            setParentData2(pd2);
        } else {
            setSelectedParent([])
            setParentData2([])
            setSelectedParent2([])
            setParentData3([])
            setSelectedParent3([])
        }
    }
    const handleSelectParent2 = (e) => {
        if (e[0]) {
            setSelectedParent2(e);
            let pd3 = [];
            props.COAInfo.rows_lvl_3.forEach(row => {
                if (row.lvl_2_refer == e[0].id) pd3.push(row)
            });
            setParentData3(pd3);
        } else {
            setSelectedParent2([])
            setParentData3([])
            setSelectedParent3([])
        }
    }

    const handleSelectParent3 = (e) => {
        if (e[0]) {
            setSelectedParent3(e);
        } else {
            setSelectedParent3([])
        }
    }

    const handleSend = () => {
        if (selectedParent && selectedParent[0]) {
            props.COA_CreateRow({
                name,
                type,
                group,
                description,
                ref_id: selectedParent3[0].id,
                ref_public_id: selectedParent3[0].public_id
            }, 4)
        }
    }

    return (
        <div style={{marginTop: "30px"}}>
            <div style={{marginBottom: "10px"}}>
                <ParentSelector_LVL_2 options={props.COAInfo.rows_lvl_1}
                                      selected={selectedParent}
                                      handleSelect={handleSelect}/>
            </div>
            {
                selectedParent && selectedParent[0] && <div style={{marginBottom: "10px"}}>
                    <ParentSelector_LVL_3 options={parentData2}
                                          selected={selectedParent2}
                                          handleSelect={handleSelectParent2}/>
                </div>
            }
            {
                selectedParent2 && selectedParent2[0] && <div style={{marginBottom: "10px"}}>
                    <ParentSelector_LVL_4 options={parentData3}
                                          selected={selectedParent3}
                                          handleSelect={handleSelectParent3}/>
                </div>
            }
            {
                selectedParent3 && selectedParent3[0] && <input style={{
                    backgroundColor: "#232531",
                    width: "500px",
                    border: "none",
                    borderRadius: "300px",
                    padding: "7px 10px 7px 10px",
                    outline: "none",
                    color: "white", marginBottom: "10px"
                }}
                                                                type="text" placeholder={"Введите название"}
                                                                value={name} onChange={(e) => setName(e.target.value)}/>
            }
            {
                name && <input style={{
                    backgroundColor: "#232531",
                    width: "500px",
                    border: "none",
                    borderRadius: "300px",
                    padding: "7px 10px 7px 10px",
                    outline: "none",
                    color: "white", marginBottom: "10px"
                }}
                               type="text" placeholder={"Введите тип"}
                               value={type} onChange={(e) => setType(e.target.value)}/>
            }
            {
                type && <input style={{
                    backgroundColor: "#232531",
                    width: "500px",
                    border: "none",
                    borderRadius: "300px",
                    padding: "7px 10px 7px 10px",
                    outline: "none",
                    color: "white", marginBottom: "10px"
                }}
                               type="text" placeholder={"Введите группу"}
                               value={group} onChange={(e) => setGroup(e.target.value)}/>
            }
            {
                group && <input style={{
                    backgroundColor: "#232531",
                    width: "500px",
                    border: "none",
                    borderRadius: "300px",
                    padding: "7px 10px 7px 10px",
                    outline: "none",
                    color: "white"
                }}
                                type="text" placeholder={"Введите описание"}
                                value={description} onChange={(e) => setDescription(e.target.value)}/>
            }
            {
                description && <div style={{marginTop: "30px", marginBottom: "-30px"}}
                                    className={"button default-button green-back"} onClick={handleSend}>
                    Добавить
                </div>
            }
        </div>
    )
}


export default CoaPage;