import React from 'react';
import s from './styles.module.css';

const OpenUrlButton = ({data}) => {

    let component = <a className={s.number}
                         href={data.replay} target="_blank"
                         style={{
                             cursor: "pointer",
                             backgroundColor: "rgba(255,255,255,0)",
                             color: "rgb(76,125,203)",
                             border: "none"
                         }}>📥 Скачать</a>

    if (!data.replay || data.replay == "none") component = <span className={s.number}>Недоступно</span>

    if (data.replay == "request") component = <span className={s.number} style={{color: "rgb(255,160,82)"}}>Через саппорта</span>

    return component
}


export default OpenUrlButton;