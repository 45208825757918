import React from 'react';
import s from './styles.module.css'
import close from '../../assests/x-small.png'
import classNames from "classnames";

const PopUpContainer = ({children, width = 650, height = 650, open, onClose, className}) => {
    return (
        <div className={classNames(s.popup, className)} style={{display: open ? 'flex' : 'none'}}>

            <div className={s.popup_content} style={{width, height}}>
                <img className={s.close} src={close} alt="close" onClick={onClose}/>
                {children}
            </div>
        </div>
    );
};

export default PopUpContainer;