import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import App from "./App.js";
import {GetProfile} from "../redux/reducers/authReducer";
import Cookies from "js-cookie";
import {DeleteNotification, StartNotifications} from "../redux/reducers/notificationsReducer";

class AppContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.GetProfile(Cookies.get("id"), Cookies.get("email"), Cookies.get("token"));
        this.props.StartNotifications()
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.NotificationsInfo.notificationsList.length != this.props.NotificationsInfo.notificationsList.length) return true;
        if (nextProps.NotificationsInfo.timestamp != this.props.NotificationsInfo.timestamp) return false
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <App {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer,
    NotificationsInfo: state.notificationsReducer
})


export default compose(
    connect(mapStateToProps, {
        GetProfile, StartNotifications, DeleteNotification
    }),
    withRouter
)(AppContainer)