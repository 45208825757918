import React, {useState, useRef} from "react";
import MainLoading from "../../common/mainLoading";

export default function ProfileCustomInfoSidebar(props) {
    let ru_name = React.createRef();
    let en_name = React.createRef();

    let createRow = () => {
        props.AdminTemplatesSetNewRow(ru_name.current.value, en_name.current.value)
    }

    return (
        props.isLoading ? <MainLoading/> :
            <div className={"sidebar"}>
                <div className={"item"}>
                    <div className={"name"}>
                        Новая строка
                    </div>
                    <div className={"info"}>
                        <input ref={ru_name} className={"small-input"} placeholder={"Название на русском"}/>
                        <input ref={en_name} className={"small-input"} placeholder={"Название для БД"}/>
                    </div>
                    <button onClick={createRow} className={"small-button green-back transition"}>Добавить
                    </button>
                </div>
            </div>
    )
}