import React from 'react';
import {CardHeader, Tooltip} from "@mui/material";

const CalendarSelectedData = ({name, time, title, center = false}) => {
    return (
        <Tooltip placement="left"
                 title={center ? '' : `${name} ${time} ${title}`}>
            <div>
                <CardHeader
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    sx={{
                        display: "flex",
                        alignItems: 'center',
                        maxWidth: '100%',
                        justifyContent: 'center',
                        overflow: "hidden",
                        padding: '5px',
                        fontSize: '0',
                        transition: '.3s all',
                        borderRadius: '10px',
                        margin: '0 auto',
                        "& .MuiCardHeader-content": {
                            overflow: "hidden"
                        },
                        "& .MuiCardHeader-content .MuiTypography-root": {
                            fontSize: '14px',
                            color: '#ddd',
                            textAlign: center && 'center'
                        },
                        "&:hover": {
                            background: '#1fa8a8',
                            color: '#fff !important'
                        }
                    }}
                    title={<>
                                                                                                      <span style={{
                                                                                                          fontSize: '14px',
                                                                                                          marginTop: '0px',
                                                                                                          height: '10px',
                                                                                                      }}>{name}</span>
                        <span style={{
                            margin: '0 10px',
                            fontSize: '14px',
                            marginTop: '0px',
                            height: '10px',
                        }}>{time}</span>
                        <span style={{
                            fontSize: '14px',
                            marginTop: '0px',
                            height: '10px',
                        }}>{title}</span>
                    </>}
                    titleTypographyProps={{noWrap: true}}
                    subheaderTypographyProps={{noWrap: true}}>

                </CardHeader>
            </div>
        </Tooltip>
    );
};

export default CalendarSelectedData;
