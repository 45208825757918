import React, {useState} from 'react';
import LeakfinderDetailsTable_1 from "./leakfinderDetails_table_1";
import LeakfinderDetailsTable_3_4 from "./leakfinderDetails_table_3_4";
import LeakfinderDetailsTable_2 from "./leakfinderDetails_table_2";
import LeakfinderDetailsTable_5 from "./leakfinderDetails_table_5";
import LeakfinderDetailsTable_6 from "./leakfinderDetails_table_6";
import {LeakfinderEditInfoInteractiveTables} from "../../../../redux/reducers/leakfinderReducer";
import moment from "moment";
import Popup from "reactjs-popup";

const LeakfinderDetails = (props) => {
    const [selectedTable, setSelectedTable] = useState("1");

    let component = "Данная таблица в данный момент не существует. В разработке.";

    if (selectedTable == "3") {
        component = <LeakfinderDetailsTable_3_4 {...props} tableNumber={3}/>
    } else if (selectedTable == "1") {
        component = <LeakfinderDetailsTable_1 {...props}/>
    } else if (selectedTable == "2") {
        component = <LeakfinderDetailsTable_2 {...props}/>
    } else if (selectedTable == "4") {
        component = <LeakfinderDetailsTable_3_4 {...props} tableNumber={4}/>
    } else if (selectedTable == "5") {
        component = <LeakfinderDetailsTable_5 {...props}/>
    } else if (selectedTable == "6") {
        component = <LeakfinderDetailsTable_6 {...props}/>
    } else {
        component = "Данная таблица в данный момент в разработке.";
    }

    const handleSelectTable = (num) => {
        setSelectedTable(num)
    }

    const handleEditInfoInteractiveTables = (newValue) => {
        props.LeakfinderEditInfoInteractiveTables("edit", newValue);
    }
    const handleSendInfoInteractiveTables = () => {
        props.LeakfinderEditInfoInteractiveTables("send", props.LeakfinderInfo.mtt_leakfinder_interactive_tables_info);
    }

    return (
        <div style={{color: "white"}}>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 5px 1fr 5px 1fr 5px 1fr 5px 1fr 5px 1fr 5px 50px 1fr", margin: "20px 0"}}>
                <div></div>
                <div style={{padding: "4px 0"}} className={`${selectedTable == 1 ? "green-back" : "default-back"} cursor-pointer`} onClick={() => handleSelectTable("1")}>Частоты по игрокам</div>
                <div></div>
                <div style={{padding: "4px 0"}} className={`${selectedTable == 2 ? "green-back" : "default-back"} cursor-pointer`} onClick={() => handleSelectTable("2")}>Частоты по периодам</div>
                <div></div>
                <div style={{padding: "4px 0"}} className={`${selectedTable == 3 ? "green-back" : "default-back"} cursor-pointer`} onClick={() => handleSelectTable("3")}>Стремление к эталонам</div>
                <div></div>
                <div style={{padding: "4px 0"}} className={`${selectedTable == 4 ? "green-back" : "default-back"} cursor-pointer`} onClick={() => handleSelectTable("4")}>Изменение стратегий</div>
                <div></div>
                <div style={{padding: "4px 0"}} className={`${selectedTable == 5 ? "green-back" : "default-back"} cursor-pointer`} onClick={() => handleSelectTable("5")}>Количество турниров/рук</div>
                <div></div>
                <div style={{padding: "4px 0"}} className={`${selectedTable == 6 ? "green-back" : "default-back"} cursor-pointer`} onClick={() => handleSelectTable("6")}>Попадание в эталоны</div>
                <div></div>
                <Popup key={`int tab edit info`}
                       trigger={
                           <div style={{cursor: "pointer"}}>
                               📕❓
                           </div>}
                       modal
                       nested
                >
                    {() => (
                        <div>
                            <div id="get-advice" style={{
                                backgroundColor: "#1e1e25",
                                padding: "10px 50px",
                                textAlign: "left",
                                color: "white",
                                width: "100%"
                            }}>
                                <form className="form">
                                    <div className="formgroup" style={{margin: "17px 0"}}>
                                        <textarea onChange={(e) => handleEditInfoInteractiveTables(e.target.value)} value={props.LeakfinderInfo.mtt_leakfinder_interactive_tables_info} style={{outline: "none", border: "none", color: "white", width: "1000px", backgroundColor: "#1e1e25"}} rows="40"></textarea>
                                    </div>
                                </form>
                                <div onClick={() => handleSendInfoInteractiveTables()} style={{cursor: "pointer"}} className={"green-back button default-button"}>
                                    Сохранить
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>
                <div> </div>
            </div>
            <div>{component}</div>
        </div>
    );
};

export default LeakfinderDetails;