import s from './style.module.css';
import Calendar from "./components/calendar/calendar";
import ActionRight from "./components/action_right";
import {useEffect, useState} from "react";
import {v4} from "uuid";
import {useMediaQuery} from "@mui/material";
import classNames from "classnames";
import {RemoveCalendarDate, SchedulerAddDates} from "../../../../redux/reducers/schedulerReducer";

function ScheduleCalendar(props) {
    const isBreakPoint = useMediaQuery('(max-width:1200px)');

    const [showAdded, setShowAdded] = useState(false)
    // lastSelectedDate - все выбранные даты у календаря
    const [lastSelectedDate, setLastSelectedDate] = useState([]);

    // chooseDate - массив со всеми данными , по дням, задачам, времени
    const [chooseDate, setChooseDate] = useState([])



    const addDate = (user, title, time) => {

        if (!user || !user.name || !user.id || !time) return;

        let uniqueArray = lastSelectedDate
            .map(function (date) {
                return date.getTime()
            })
            .filter(function (date, i, array) {
                return array.indexOf(date) === i;
            })
            .map(function (time) {
                return new Date(time);
            });

        const datesList = uniqueArray.map((el) => ({
            date: el,
            name: user.name,
            id_user: user.id,
            id: v4(),
            title: title,
            time: time
        }));

        props.SchedulerAddDates(datesList)
    }

    const removeChooseDate = (id) => {
        props.RemoveCalendarDate(id)
    }

    useEffect(() => {
        if (!isBreakPoint) {
            setShowAdded(false)
        }
        if (showAdded && isBreakPoint) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showAdded, isBreakPoint])
    return (
        <div className={s.App}>
            <div className={s.calendar}>
                <Calendar removeChooseDate={removeChooseDate} chooseDate={props.ScheduleInfo.calendar}
                          setLastSelectedDate={setLastSelectedDate}
                          lastSelectedDate={lastSelectedDate}/>
            </div>
            <div className={classNames(s.action, (isBreakPoint && showAdded) && s.action_mobile)}>
                <ActionRight lastSelectedDate={lastSelectedDate} addDate={addDate} closeModal={setShowAdded} supportsList={props.ScheduleInfo.supportsList}/>
            </div>

            {(isBreakPoint && !showAdded) && <div className={s.plus} onClick={() => setShowAdded(!showAdded)}>
                +
            </div>}
        </div>
    );
}

export default ScheduleCalendar;
