import React from 'react';
import s from "./styles.module.css";
import Input from "../../../../common/input";

import usd from '../../../../assests/usd.png'
import eur from '../../../../assests/eur.png'

const BlockOne = ({
                      value,
                      onChange,
                      currency,
                      bottomText,
                      png_logo,
                      topText,
                      inputTitle,
                      topTitle,
                      UserSetFormData, hasBank
                  }) => {
    let bankImage;
    if (currency) {
        if (topTitle == "USD") {
            bankImage = usd
        } else {
            bankImage = eur;
        }
    }
    const handleChange = (e) => {
        onChange(e)
        UserSetFormData(e, "sum")
    }

    return (
        <div>
            <div className={s.top}>
                <div className={s.title_top}>
                    <span>{topText}</span>
                    <p>{topTitle}</p>
                </div>
                {
                    (bankImage || png_logo) &&
                    <div className={s.iconTop}>
                        <img style={{width: "40px", borderRadius: "50px"}} src={bankImage ? bankImage : png_logo} alt="..."/>
                    </div>
                }
            </div>
            {
                hasBank ? <Input type="number" value={value} title={inputTitle} onChange={currency ? handleChange : null}/>
                    : <Input value={"Выберите вариант получения"}/>
            }

            {/*<p className={s.avilibe}>{bottomText}oiju</p>*/}
        </div>
    );
};

export default BlockOne;