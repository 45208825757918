import React, {useEffect, useState} from 'react';
import Select from "react-dropdown-select";
import {useDispatch} from "react-redux";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CustomDropdownRenderer from "../../Util/Dropdown/CustomDropdownRenderer";

const GroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите группу"}
            labelField={"coach_group"}
            valueField={"coach_group"}
            sortBy={"coach_group"}
            contentRenderer={() => props.selectedGroup && props.selectedGroup[0] ? <div>{props.selectedGroup[0].coach_group}</div> : <div>Группа не выбрана</div>}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedGroup && props.selectedGroup[0] ? props.selectedGroup : []}
            onChange={(value) => {
                props.handleSelectGroup(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const RankSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите ранг"}
            labelField={"rank"}
            valueField={"rank"}
            sortBy={"rank"}
            contentRenderer={() => props.selectedRank && props.selectedRank[0] ? <div>{props.selectedRank[0].rank}</div> : <div>Ранг не выбран</div>}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedRank && props.selectedRank[0] ? props.selectedRank : []}
            onChange={(value) => {
                props.handleSelectRank(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const PlayersSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите игрока"}
            labelField={"nick"}
            valueField={"nick"}
            sortBy={"nick"}
            dropdownRenderer={CustomDropdownRenderer}
            contentRenderer={() => <div>Выбрано игроков: {props.selectedPlayers.length} из {props.options.length}</div>}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedPlayers}
            onChange={(value) => {
                props.handleSelectPlayers(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}

const LeakfinderDetailsTable_5 = (props) => {
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedRank, setSelectedRank] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [startDate, setStartDate] = useState(+new Date());
    const [endDate, setEndDate] = useState(+new Date(+new Date() + 3600000));

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isNaN(+startDate) && !isNaN(+endDate)) props.LeakfinderGetInteractiveTableData_5(+startDate, +endDate, null, "getData")
            //
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [startDate, endDate, props.LeakfinderInfo.interactiveTableData_5]);

    const handleSelectGroup = (e) => {
        let players = [];

        if (!e || !e[0]) {
            setSelectedPlayers(players);
            setSelectedGroup([]);
        } else {
            props.LeakfinderInfo.players.forEach(player => {
                e.forEach(se => {
                    if (se.coach_group == player.coach_group) {
                        players.push(player);
                    }
                })
            })
            setSelectedGroup(e)
            setSelectedPlayers(players);
            setSelectedRank([]);
        }

    }


    const handleSelectRank = (e) => {
        let players = [];
        if (!e || !e[0]) {
            setSelectedPlayers(players);
            setSelectedRank([])

        } else {
            props.LeakfinderInfo.players.forEach(player => {
                e.forEach(se => {
                    if (se.rank == player.rank) {
                        players.push(player);
                    }
                })
            })
            setSelectedRank(e)
            setSelectedPlayers(players);
            setSelectedGroup([]);
        }

    }

    const handleSelectPlayers = (e) => {
        if (e && e[0]) {
            setSelectedPlayers(e)
        } else {
            setSelectedPlayers([])
        }

    }

    const handleStartDateChange = (date) => {
        if (new Date(date) == "Invalid Date") return;
        setStartDate(+new Date(date));
    };
    const handleEndDateChange = (date) => {
        if (new Date(date) == "Invalid Date") return;
        setEndDate(+new Date(date));
    };

    const rows = JSON.parse(JSON.stringify(selectedPlayers));

    if (props.LeakfinderInfo.interactiveTableData_5 && props.LeakfinderInfo.interactiveTableData_5[0]) {
        props.LeakfinderInfo.interactiveTableData_5.forEach(e => {
            let ri = rows.findIndex(ee => ee.id == e.id);
            if (ri != -1) rows[ri] = JSON.parse(JSON.stringify(e));
        })
    }

    const handleGetDate = (date_from, date_to, user_id) => {
        props.LeakfinderGetInteractiveTableData_5(date_from, date_to, user_id, "analyze")
    }

    return (
            <div>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 20px 1fr 10px 1fr", color: "white"}}>
                    <GroupSelector options={props.LeakfinderInfo.groups}
                                   selectedGroup={selectedGroup}
                                   handleSelectGroup={handleSelectGroup}/>
                    <RankSelector options={props.LeakfinderInfo.ranks}
                                  selectedRank={selectedRank}
                                  handleSelectRank={handleSelectRank}/>
                    <PlayersSelector options={props.LeakfinderInfo.players}
                                     selectedPlayers={selectedPlayers}
                                     handleSelectPlayers={handleSelectPlayers}/>
                    <div></div>
                    <input type="date" onBlur={(e) => handleStartDateChange(e.target.value)}/>
                    <div></div>
                    <input type="date" onBlur={(e) => handleEndDateChange(e.target.value)}/>
                    {/*<div style={{filter: "brightness(0) invert(1)", margin: "-10px 0 0 0"}}>*/}
                    {/*    <ThemeProvider theme={defaultMaterialTheme}>*/}
                    {/*        <MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                    {/*            <DateTimePicker*/}
                    {/*                format="dd.MM.yyyy HH:mm"*/}
                    {/*                margin="normal"*/}
                    {/*                label="От"*/}
                    {/*                value={startDate}*/}
                    {/*                onChange={handleStartDateChange}*/}
                    {/*            />*/}
                    {/*        </MuiPickersUtilsProvider>*/}

                    {/*    </ThemeProvider>*/}
                    {/*</div>*/}
                    {/*<div style={{filter: "brightness(0) invert(1)", margin: "-10px 0 0 0"}}>*/}
                    {/*    <ThemeProvider theme={defaultMaterialTheme}>*/}
                    {/*        <MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                    {/*            <DateTimePicker*/}
                    {/*                format="dd.MM.yyyy HH:mm"*/}
                    {/*                margin="normal"*/}
                    {/*                label="До"*/}
                    {/*                value={endDate}*/}
                    {/*                onChange={handleEndDateChange}*/}
                    {/*            />*/}
                    {/*        </MuiPickersUtilsProvider>*/}

                    {/*    </ThemeProvider>*/}
                    {/*</div>*/}
                </div>
                <div style={{
                    width: '99.8%',
                    margin: "20px 0 0 5px", color: "white", fontSize: "11px"
                }}>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px", padding: "5px 0"}}>
                        <div>Ник</div>
                        <div>Турниров Shark</div>
                        <div>Турниров Leakfinder</div>
                        <div>Разница турниров</div>
                        <div>Рук Shark</div>
                        <div>Рук Leakfinder</div>
                        <div>Разница рук</div>
                        <div></div>
                    </div>
                    {
                        rows.map((row, i) => {
                            return <div key={`${i} leak tournaments ${row._id}`} style={{backgroundColor: i % 2 == 0 ? "#262736" : "#1b1d24",
                                display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px", padding: "5px 0"}}>
                                <div>{row.nick}</div>
                                <div>{row.total_tournaments_shark ? row.total_tournaments_shark : "-"}</div>
                                <div>{row.total_tournaments_leakfinder ? row.total_tournaments_leakfinder : "-"}</div>
                                <div>{row.total_tournaments_shark && row.total_tournaments_leakfinder ? +row.total_tournaments_shark - +row.total_tournaments_leakfinder : "-"}</div>
                                <div>{row.total_hands_shark ? row.total_hands_shark : "-"}</div>
                                <div>{row.total_hands_leakfinder ? row.total_hands_leakfinder : "-"}</div>
                                <div>{row.total_hands_leakfinder && row.total_hands_shark ? +row.total_hands_shark - +row.total_hands_leakfinder : "-"}</div>
                                { <button
                                    onClick={() => handleGetDate(+startDate, +endDate, row.id)}
                                    style={{
                                        cursor: "pointer",
                                        backgroundColor: "rgba(255,255,255,0)",
                                        color: "rgb(178,18,18)",
                                        border: "none"
                                    }}>🔎</button>}

                            </div>
                        })
                    }
                </div>
            </div>
    );
};

export default LeakfinderDetailsTable_5;