import React, {useEffect, useState} from 'react';
import Select from 'react-dropdown-select';
import Popup from "reactjs-popup";
import moment from "moment/moment";
import {SalaryRemoveRow, SupportChangeRowsDirectionsFilter} from "../../../../redux/reducers/salaryReducer";
import {useDispatch} from "react-redux";

const UserSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите сотрудника"}
            labelField={"public_nickname"}
            valueField={"public_nickname"}
            options={props.options}
            values={[]}
            onChange={(value) => {
                if (value && value[0]) {
                    props.handleChangeUser(value[0].nickname, value[0].id, value[0].division, value[0].direction)
                }
            }
            }
            style={{backgroundColor: "initial", border: "none", color: "white"}}
        />
    )
}

const TypeSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите тип начисления"}
            labelField={"ru_name"}
            valueField={"name"}
            options={props.options}
            values={[]}
            onChange={(value) => {
                if (value && value[0]) {
                props.handleChangeType(value[0].name)
                }
            }
            }
            style={{backgroundColor: "initial", border: "none", color: "white"}}
        />
    )
}
const SalaryRowsDirectionSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать направления"}
            labelField={"user_direction"}
            valueField={"user_direction"}
            sortBy={"user_direction"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            multi
            options={props.options}
            values={props.selectedDirection}
            onChange={(value) => {
                props.handleSelectDirection(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 15px 0 0", fontSize: "19px", border: "none", zIndex: "101"}}
        />
    )
}

const RowsList = (props) => {
    const dispatch = useDispatch();

    const [user_nickname, set_user_nickname] = useState(null);
    const [user_id, set_user_id] = useState(null);
    const [user_division, set_user_division] = useState(null);
    const [user_direction, set_user_direction] = useState(null);

    const [salary_type, set_salary_type] = useState(null);
    const [month_period, set_month_period] = useState(null);
    const [year_period, set_year_period] = useState(null);

    const [cash_sum, set_cash_sum] = useState(null);
    const [asia_sum, set_asia_sum] = useState(null);
    const [spin_sum, set_spin_sum] = useState(null);
    const [mtt_sum, set_mtt_sum] = useState(null);

    const [description, set_description] = useState(null);

    const [sorting, setSorting] = useState({ key: 'user_nickname', ascending: false })

    const handleSelectDirection = (e) => {
        props.SupportChangeRowsDirectionsFilter(e && e[0] ? e : [])
    }

    let data = props.SalaryInfo.rows.map(e => {
        if (props.SalaryInfo.supportSelectedRowsDirections.length == 0) return e;
        if (props.SalaryInfo.supportSelectedRowsDirections.some(ee => ee.user_direction == e.user_direction)) return e;
        return null;
    });
    data = data.filter(e => e != null);

    const handleChangeUser = (nick, id, division, direction) => {
        set_user_nickname(nick)
        set_user_id(id)
        set_user_division(division)
        set_user_direction(direction)
    }

    const handleChangeType = (name) => {
        set_salary_type(name);
    }

    const handleSave = () => {
        props.SalaryAddRow(`${month_period}-${year_period}`, user_nickname, user_id, user_division, user_direction,
            cash_sum, asia_sum, spin_sum, mtt_sum, description, salary_type)
    }


    function applySorting(key, ascending) {
        setSorting({ key: key, ascending: ascending });
    }

    useEffect(() => {
        if (props.SalaryInfo.rows && props.SalaryInfo.rows[0]) {
            // Copy array to prevent data mutation
            const currentCopy = [...props.SalaryInfo.rows];

            let numberKeys = ['init_timestamp', 'cash_sum', 'asia_sum', 'spin_sum', 'mtt_sum'];

            // Apply sorting
            const sortedCurrentUsers = currentCopy.sort((a, b) => {
                return numberKeys.includes(sorting.key) ? a[sorting.key] - b[sorting.key] : a[sorting.key].localeCompare(b[sorting.key]);
            });

            // Replace currentUsers with sorted currentUsers
            props.SalaryInfo.rows = sorting.ascending ? sortedCurrentUsers : sortedCurrentUsers.reverse();
        }
    }, [props.SalaryInfo.rows, sorting]);

    return (
        <div>
            <div>
                <Popup key={"rowSalaryCreator"}
                       trigger={
                           <div className={"default-button green-back transition"}>Добавить начисление</div>}
                       modal
                       nested
                >
                    {(close) => (
                        <div>
                            <div id="get-advice"
                                 style={{
                                     backgroundColor: "#1e1e25",
                                     padding: "10px 120px",
                                     borderRadius: "30px",
                                     textAlign: "left",
                                     color: "white"
                                 }}>
                                <form className="form">
                                    <div className="formgroup">
                                        <div style={{margin: "0 0 20px 0", width: "700px"}}>
                                            <div style={{fontWeight: "bolder", padding: "10px"}}>Сотрудник</div>
                                            <UserSelector options={props.SalaryInfo.users}
                                                          handleChangeUser={handleChangeUser}/>
                                        </div>
                                        <div style={{margin: "0 0 20px 0", width: "700px"}}>
                                            <div style={{fontWeight: "bolder", padding: "10px"}}>Тип выплаты</div>
                                            <TypeSelector options={[{name: "salary", ru_name: "Зарплата"}, {name: "prepayment", ru_name: "Аванс"}]}
                                                          handleChangeType={handleChangeType}/>
                                        </div>
                                        <div style={{margin: "20px 0"}}>
                                            <div style={{fontWeight: "bolder", padding: "10px"}}>Месяц периода</div>
                                            <input className={"ag-input-field"} type="number" style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#32323f",
                                                borderRadius: "30px", width: "200px"
                                            }}
                                                   onChange={(e) => set_month_period(e.target.value)}
                                                   placeholder="От 1 до 12"/>
                                        </div>
                                        <div style={{margin: "20px 0"}}>
                                            <div style={{fontWeight: "bolder", padding: "10px"}}>Год периода</div>
                                            <input className={"ag-input-field"} type="number" style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#32323f",
                                                borderRadius: "30px", width: "200px"
                                            }}
                                                   onChange={(e) => set_year_period(e.target.value)}
                                                   placeholder="Например: 2022"/>
                                        </div>
                                        <div style={{margin: "20px 0"}}>
                                            <div style={{fontWeight: "bolder", padding: "10px"}}>Долларов за CASH</div>
                                            <input className={"ag-input-field"} type="number" style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#32323f",
                                                borderRadius: "30px", width: "300px"
                                            }}
                                                   onChange={(e) => set_cash_sum(e.target.value)}
                                                   placeholder="Введите число"/>
                                        </div>
                                        <div style={{margin: "20px 0"}}>
                                            <div style={{fontWeight: "bolder", padding: "10px"}}>Долларов за ASIA</div>
                                            <input className={"ag-input-field"} type="number" style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#32323f",
                                                borderRadius: "30px", width: "300px"
                                            }}
                                                   onChange={(e) => set_asia_sum(e.target.value)}
                                                   placeholder="Введите число"/>
                                        </div>
                                        <div style={{margin: "20px 0"}}>
                                            <div style={{fontWeight: "bolder", padding: "10px"}}>Долларов за SPIN</div>
                                            <input className={"ag-input-field"} type="number" style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#32323f",
                                                borderRadius: "30px", width: "300px"
                                            }}
                                                   onChange={(e) => set_spin_sum(e.target.value)}
                                                   placeholder="Введите число"/>
                                        </div>
                                        <div style={{margin: "20px 0"}}>
                                            <div style={{fontWeight: "bolder", padding: "10px"}}>Долларов за MTT</div>
                                            <input className={"ag-input-field"} type="number" style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#32323f",
                                                borderRadius: "30px", width: "300px"
                                            }}
                                                   onChange={(e) => set_mtt_sum(e.target.value)}
                                                   placeholder="Введите число"/>
                                        </div>
                                        <div style={{margin: "20px 0"}}>
                                            <div style={{fontWeight: "bolder", padding: "10px"}}>Описание работы</div>
                                            <input className={"ag-input-field"} type="text" style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#32323f",
                                                borderRadius: "30px", width: "700px"
                                            }}
                                                   onChange={(e) => set_description(e.target.value)}
                                                   placeholder="Введите текст"/>
                                        </div>
                                    </div>
                                </form>
                                <button style={{marginTop: "20px", borderRadius: "30px"}} className={"send-btn default-button green-back"}
                                        onClick={handleSave}>Создать начисление
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>
            <div style={{margin: "30px 0 0px 30px"}}>
                <SalaryRowsDirectionSelector options={props.SalaryInfo.supportRowsDirections}
                                              selectedDirection={props.SalaryInfo.supportSelectedRowsDirections}
                                              handleSelectDirection={handleSelectDirection}/>
            </div>
            <div style={{
                width: '99.8%',
                margin: "20px 0 0 5px", color: "white", fontSize: "11px"
            }}>
                <div style={{display: "grid", gridTemplateColumns: "100px 80px 60px 1fr 140px 60px 60px 60px 60px 3fr 50px", padding: "5px 0"}}>
                    <div onClick={() => applySorting('init_timestamp', !sorting.ascending)}>Дата</div>
                    <div onClick={() => applySorting('salary_type', !sorting.ascending)}>Тип</div>
                    <div onClick={() => applySorting('period', !sorting.ascending)}>Период</div>
                    <div onClick={() => applySorting('user_nickname', !sorting.ascending)}>Сотрудник</div>
                    <div onClick={() => applySorting('user_division', !sorting.ascending)}>Отдел</div>
                    <div onClick={() => applySorting('cash_sum', !sorting.ascending)}>CASH Sum</div>
                    <div onClick={() => applySorting('asia_sum', !sorting.ascending)}>ASIA Sum</div>
                    <div onClick={() => applySorting('spin_sum', !sorting.ascending)}>SPIN Sum</div>
                    <div onClick={() => applySorting('mtt_sum', !sorting.ascending)}>MTT Sum</div>
                    <div onClick={() => applySorting('description', !sorting.ascending)}>Описание</div>
                    <div></div>
                </div>
                {
                    data.map((row, i) => {
                        return <div key={`${i} salaryRow ${row._id}`} style={{backgroundColor: i % 2 == 0 ? "#262736" : "#1b1d24",
                            display: "grid", gridTemplateColumns: "100px 80px 60px 1fr 140px 60px 60px 60px 60px 3fr 50px", padding: "5px 0"}}>
                            <div>{moment(+row.init_timestamp).format("DD.MM.YYYY")}</div>
                            <div>{row.salary_type}</div>
                            <div>{row.period}</div>
                            <div>{row.user_nickname}</div>
                            <div>{row.user_division}</div>
                            <div>{+row.cash_sum && !isNaN(+row.cash_sum) ? (+row.cash_sum).toFixed(2) : 0}</div>
                            <div>{+row.asia_sum && !isNaN(+row.asia_sum) ? (+row.asia_sum).toFixed(2) : 0}</div>
                            <div>{+row.spin_sum && !isNaN(+row.spin_sum) ? (+row.spin_sum).toFixed(2) : 0}</div>
                            <div>{+row.mtt_sum && !isNaN(+row.mtt_sum) ? (+row.mtt_sum).toFixed(2) : 0}</div>
                            <div>{row.description}</div>
                            <button onClick={() => dispatch(SalaryRemoveRow(row._id))} style={{
                                cursor: "pointer",
                                backgroundColor: "rgba(255,255,255,0)",
                                color: "rgb(178,18,18)",
                                border: "none"
                            }}>❌</button>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default RowsList;