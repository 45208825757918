import React from 'react';
import classNames from "classnames";
import s from './styles.module.css'
import user from "../../assests/user.png";
import {Tooltip} from "@mui/material";
import {ReactComponent as Logout} from "../../assests/logout.svg";
import Cookies from "js-cookie";
import config from "../../../v_1/config.json";

const UserMain = ({hiddenRight,email,username, avatar, AuthInfo}) => {
    const convertEmail = email.length > 17 ? email.slice(0, 17) + '...' : email;

    const handleLogOut = () => {
        Cookies.remove("id", { domain: config.domain, path: '/' });
        Cookies.remove("token", { domain: config.domain, path: '/' });
        Cookies.remove("username", { domain: config.domain, path: '/' });
        Cookies.remove("avatar", { domain: config.domain, path: '/' });
        Cookies.remove("email", { domain: config.domain, path: '/' });
        Cookies.remove("WEB_SITE_USER_VERSION", { domain: config.domain, path: '/' });
        Cookies.remove("WEB_SITE_USER_VERSION");
        window.location.href = "/"
    }

    const goto = () => {
        Cookies.set('WEB_SITE_USER_VERSION', '1', {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        window.location.href = '/';
    }

    let isShowOldVersionButton = false;

    if (AuthInfo.isDeveloper || AuthInfo.isCoach || AuthInfo.isOwner || AuthInfo.isAdmin
        || AuthInfo.isAuditAdmin || AuthInfo.isFsChangerAdmin || AuthInfo.isFsChangerSupport || AuthInfo.isWikiCreator
        || AuthInfo.isSupport || AuthInfo.isDisk || AuthInfo.isFinancesAdmin || AuthInfo.isSpinSupport
        || AuthInfo.isCashSupport || AuthInfo.isMTTSupport || AuthInfo.isMTSNGSupport || AuthInfo.isRefSupport
        || AuthInfo.isAuditor || AuthInfo.isPokerhubSupport) {
        isShowOldVersionButton = true;
    }

    return (
        <div className={classNames(s.user, hiddenRight && s.hidden_user)}>
            <div className={classNames(s.user_icon)}>
                <img style={{width: "60px", borderRadius: "100px"}} src={`https://cdn.discordapp.com/avatars/${Cookies.get("id")}/${Cookies.get("avatar")}`} alt="user"/>
            </div>
            {!hiddenRight && <>
                <div className={s.user_tet}>
                    <p>{username}</p>
                    <Tooltip title={email}>
                        <span>{convertEmail}</span>
                    </Tooltip>
                    {
                        isShowOldVersionButton && <p onClick={goto} style={{fontSize: "11px", color: "#0094e1", cursor: "pointer"}}>Перейти в старую версию ЛК</p>
                    }
                </div>

                <div onClick={handleLogOut} className={s.user_logout}>
                    <Logout/>
                </div>
            </>}
        </div>
    );
};

export default UserMain;