import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import WikiPage from "./wikiPage";
import {GetPage} from "../../../redux/reducers/wikiReducer";


class WikiPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.GetPage(this.props.match.params.id)
        if (this.props.AuthInfo.isWikiCreator) this.props.changeCurrentPage("WIKI : Редактор статьи")
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <WikiPage {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer,
    WikiInfo: state.wikiReducer
})

export default compose(
    connect(mapStateToProps, {
        GetPage
    }),
    withRouter
)(WikiPageContainer)