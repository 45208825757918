import React, {useState} from 'react';
import s from './styles.module.css'
import Container from "../container";
import classNames from "classnames";
import usd from '../../../assests/usd.png'
import smallusd from '../../../assests/smallUsd.png'
import smalleur from '../../../assests/smallEur.png'
import small1 from '../../../assests/small1.png'
import small2 from '../../../assests/small2.png'
import small3 from '../../../assests/small3.png'
import small4 from '../../../assests/small4.png'
import small5 from '../../../assests/small5.png'
import small6 from '../../../assests/small6.png'
import small7 from '../../../assests/small7.png'
import small8 from '../../../assests/small8.png'
import small9 from '../../../assests/small9.png'
import small10 from '../../../assests/small10.png'
import small11 from '../../../assests/small11.png'
import tinkoff from '../../../assests/tinkoff.png'
import BlockOne from "./1-block";
import GiveCurrency from "./2-block_item/giveCurrency";
import FormBlock from "./3-block_last";
import Input from "../../../common/input";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GetCurrency from "./2-block_item/getCurrency";

const giveCurrency = [
    {icon: smallusd, name: 'Доллар США', currencyName: 'USD', id: 1},
    {icon: smalleur, name: 'Евро', currencyName: 'EUR', id: 2}
]
const getCurrency = [
    {icon: small1, name: 'Тинькофф', currencyName: 'RUB', id: 1},
    {icon: small2, name: 'Приват24', currencyName: 'UAH', id: 2},
    {icon: small3, name: 'ВТБ', currencyName: 'RUB', id: 3},
    {icon: small4, name: 'Ощадбанк', currencyName: 'UAH', id: 4},
    {icon: small5, name: 'Visa', currencyName: 'RUB', id: 5},
    {icon: small6, name: 'MasterCard', currencyName: 'RUB', id: 6},
    {icon: small7, name: 'Visa', currencyName: 'UAH', id: 7},
    {icon: small8, name: 'MasterCard', currencyName: 'UAH', id: 8},
    {icon: small9, name: 'Монобанк', currencyName: 'UAH', id: 9},
    {icon: small10, name: 'Сбербанк', currencyName: 'RUB', id: 10},
    {icon: small11, name: 'WebMoney', currencyName: 'USD', id: 11},
]

const navigate = ['Все', 'RUB', 'UAH', 'USD']

const ExchangerContent = (props) => {
    const [giveMoney, setGiveMoney] = useState(null)
    const [getMoney, setGetMoney] = useState(null)
    const [selectGiveCurrency, setSelectGiveMoney] = useState(1)
    const [selectGetCurrency, setSelectGetMoney] = useState(1)
    const [selectNavigate, setSelectNavigate] = useState('Все')
    const [showList, setShowList] = useState(false)
    const [showCurrencyList, setShowCurrencyList] = useState(false)
    const [valueInputs, setValueInputs] = useState({
        name: '',
        numberCard: '',
        numberMobile: '',
        nickname: '',
    });

    const inputs = [
        {placeholder: 'ФИО', name: 'name'},
        {placeholder: 'Реквизиты', name: 'numberCard'},
        // {placeholder: 'Номер телефона', name: 'numberMobile'},
        {placeholder: 'Никнейм в команде', name: 'nickname'},

    ];

    const UserSetCurrentBank = (bank) => {
        props.UserSetCurrentBank(bank)
    }

    const UserSetCurrentType = (type) => {
        setSelectNavigate(type)
        props.UserSetCurrentType(type)
    }
    const UserSetFormData = (e, ttype) => {
        if (ttype == "checkbox") return props.UserSetFormData(ttype, !e)
        props.UserSetFormData(ttype, e.currentTarget.value)
    }

    return (
        <div className={s.exchanger}>
            <div className={classNames(s.exchanger_box, s.between_box)}>
                <Container className={classNames(s.box1)}>

                    <BlockOne hasBank={!!props.FsChangerInfo.userCurrentBank} value={giveMoney} UserSetFormData={UserSetFormData} onChange={(e) => setGiveMoney(e.target.value)} topTitle={props.FsChangerInfo.userCurrency}
                              bottomText={''} currency={props.FsChangerInfo.userCurrency} inputTitle={props.FsChangerInfo.userCurrency} topText={'Отдаю'}/>
                </Container>

                <Container className={s.box3}>
                    {giveCurrency.map((item, index) => <GiveCurrency setSelectGiveMoney={setSelectGiveMoney}
                                                                     key={index} {...item}
                                                                     select={selectGiveCurrency === item.id}
                                                                     UserSetFormData={UserSetFormData}/>)}
                    <div className={s.hidden} onClick={() => setShowList(!showList)}>
                        <p>{showList ? 'Скрыть полный список' : 'Показать полный список'}</p><ExpandMoreIcon
                        className={classNames(s.svg, showList && s.rotate)}/>
                    </div>
                </Container>
            </div>

            <div className={classNames(s.exchanger_box, s.second_box, s.between_box)}>
                <Container className={classNames(s.box2)}>
                    <BlockOne value={props.FsChangerInfo.userSum ? props.FsChangerInfo.FfsMin : 0} UserSetFormData={UserSetFormData} onChange={(e) => setGetMoney(e.target.value)} topTitle={props.FsChangerInfo.userCurrentBank && props.FsChangerInfo.userCurrentBank.public_name ? props.FsChangerInfo.userCurrentBank.public_name : "Не указано"}
                              bottomText={''} inputTitle={props.FsChangerInfo.userCurrentBank && props.FsChangerInfo.userCurrentBank.public_type ? props.FsChangerInfo.userCurrentBank.public_type : ""}
                              png_logo={props.FsChangerInfo.userCurrentBank ? props.FsChangerInfo.userCurrentBank.png_logo : ""}
                              topText={'Получаю'} hasBank={!!props.FsChangerInfo.userCurrentBank}/>
                </Container>

                <Container className={s.box4}>
                    <div className={s.navigate}>
                        <p
                            onClick={() => UserSetCurrentType("all")}
                            className={classNames(s.navigateP, selectNavigate === "all" && s.activeNavigate)}>Все</p>
                        {
                            props.FsChangerInfo.userTypesList.map((type, index) => <p key={index}
                                                                                      onClick={() => UserSetCurrentType(type)}
                                                                                      className={classNames(s.navigateP, selectNavigate === type && s.activeNavigate)}>{type}</p>
                            )
                        }
                    </div>
                    <div className={classNames(s.list_currency, showCurrencyList && s.show_currency_list)}>
                        {
                            props.FsChangerInfo.userEditedBanksList.map((item, index) => <GetCurrency
                                setSelectGiveMoney={setSelectGetMoney}
                                key={index} item={item}
                                select={selectGetCurrency === item._id}
                                UserSetCurrentBank={UserSetCurrentBank}/>
                            )
                        }
                    </div>
                    <div className={s.hidden} onClick={() => setShowCurrencyList(!showCurrencyList)}>
                        <p>{showCurrencyList ? 'Скрыть полный список' : 'Показать полный список'}</p><ExpandMoreIcon
                        className={classNames(s.svg, showCurrencyList && s.rotate)}/>
                    </div>
                </Container>
            </div>

            <div className={s.exchanger_box}>
                <Container className={s.box5}>
                    <FormBlock valueInputs={valueInputs} setValueInputs={setValueInputs} inputs={inputs}
                               userSum={props.FsChangerInfo.userSum} FfsMin={props.FsChangerInfo.FfsMin}
                               Fbank={props.FsChangerInfo.Fbank} UserSetFormData={UserSetFormData}
                               FsChangerInfo={props.FsChangerInfo} UserSendTicket={props.UserSendTicket}/>

                </Container>
            </div>

        </div>
    );
};

export default ExchangerContent;