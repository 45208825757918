import React from 'react';
import s from './styles.module.css'

const Item = ({title, subtitle, titleColor, subtitleColor, background, cursor = false, onClick}) => {
    return (
        <div className={s.item} style={{background: background, cursor: cursor && 'pointer'}} onClick={onClick}>
            <h3 style={{color: titleColor}}>{title}</h3>
            <p style={{color: subtitleColor, fontSize: !title ? '20px' : '14px'}}>{subtitle}</p>
        </div>
    );
};

export default Item;
