import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import ContractsMTT from "./contractsMTT";
import {DispatchSupportSetCurrentUser, SupportGetMTTContracts} from "../../../../../redux/reducers/supportReducer";

class ContractsContainerMTT extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }


    componentDidMount() {
        this.props.changeCurrentPage(`MTT: Контракты`);
        this.props.SupportGetMTTContracts();
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <ContractsMTT {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    SupportInfo: state.supportReducer,
    // FormularInfo: state.formularReducer
})


export default compose(
    connect(mapStateToProps, {
        SupportGetMTTContracts, DispatchSupportSetCurrentUser
    }),
    withRouter
)(ContractsContainerMTT)