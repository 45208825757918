import React, {useState} from 'react';
import s from "./styles.module.css";

const SubItem = ({userNames, periods, subtitle, ind}) => {
    const [open, setOpen] = useState(false)
    let values_main = []

    for (let i = 0; i < userNames.length; i++) {
        const perid = periods.map((el) => subtitle.data[el]?.find((f) => f.username === userNames[i]))
        const filtered = perid.filter(f => f !== undefined)
        if (perid) {
            values_main = [...values_main, (filtered.map((el) => el?.value).reduce((acc, inc) => acc = acc + inc, 0)) / filtered.length].filter((f) => !isNaN(f))
        }
    }

    return (
        <div style={{backgroundColor: ind % 2 == 0 ? "#424954" : "initial"}}>
            <div className={s.content_table_content_center_top}>
                <div className={s.plus} onClick={() => setOpen(!open)}>
                    {open ? '-' : '+'}
                </div>
                <div className={s.table_box}>{subtitle.name}</div>
                <div
                    className={s.table_box}>{`${subtitle.standards[0]} - ${subtitle.standards[1]}`}</div>
                <div className={s.table_box}>
                    {isNaN((values_main.reduce((acc, inc) => acc = acc + inc, 0)) / values_main.length) ? '-' : ((values_main.reduce((acc, inc) => acc = acc + inc, 0)) / values_main.length).toFixed(2)}
                    {/*ср*/}
                </div>
                <div style={{display: "grid", gridTemplateColumns: `repeat(${periods.length}, 100px)`, gap: "10px"}}>
                    {periods.map((per, ind) => {
                        const avg = subtitle.data[per]?.reduce((acc = 0, inc) => acc = acc + inc.value, 0)

                        return <div
                            key={`${ind} per2`}
                            className={s.perido}>{isNaN(avg / subtitle.data[per]?.length) ? '-' : (avg / subtitle.data[per]?.length).toFixed(2)}</div>
                    })}
                </div>
            </div>

            <div className={s.content_table_content_center_bottom} style={{
                maxHeight: open ? '9000px' : '0px'
            }}>
                {userNames.map((name, index) => {
                    let values = []
                    for (let i = 0; i < periods.length; i++) {
                        const findValue = subtitle.data[periods[i]]?.find((f) => f.username === name)

                        if (findValue) {
                            values = [...values, findValue.value]
                        }
                    }
                    // console.log(values)
                    values_main = [...values_main, isNaN(values.reduce((acc, inc) => acc = acc + inc, 0)) ? '-' : ((values.reduce((acc, inc) => acc = acc + inc, 0)) / values?.length).toFixed(2)]

                    return <div key={`${index} name`} style={{backgroundColor: index % 2 == 0 ? "#556c93" : "#2a2a32"}}
                                className={s.content_table_content_center_bottom_users}>
                        <div className={s.table_box}></div>
                        <div className={s.table_box}>{name}</div>
                        <div className={s.table_box}>
                            {isNaN(values.reduce((acc, inc) => acc = acc + inc, 0) / values?.length) ? '-' : ((values.reduce((acc, inc) => acc = acc + inc, 0)) / values?.length).toFixed(2)}
                        </div>
                        <div style={{display: "grid", gridTemplateColumns: `repeat(${periods.length}, 100px)`, gap: "10px"}}>
                            {periods.map((per, ind) => {
                                const findValue = subtitle.data[per]?.find((f) => f.username === name)

                                return <div key={`${ind} pre3`} className={s.perido}>
                                    {findValue ? findValue.value : '-'}
                                </div>
                            })}
                        </div>
                    </div>
                })}
            </div>

        </div>
    );
};

export default SubItem;
