import {LEADERBOARD_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import Cookies from "js-cookie";
import {ChangeMainLoading} from "./mainReducer";

const SET_SAVES = 'SET_SAVES';
const SET_SAVE = 'SET_SAVE';
const SET_DUELS = 'SET_DUELS';
const SET_BLOCK = 'SET_BLOCK';

let initialState = {
    saves: [],
    currentSaveShark: null,
    currentSaveLeakfinder: null,

    currentSaveShark_reserve: null,
    currentSaveLeakfinder_reserve: null,
    blocks: null,
    selected_block: [],

    duels: []
};

const leaderboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BLOCK: {
            if (!action.block[0]?.name) return state;

            let shark_data = null;
            let leakfinder_data = null;

            if (state.currentSaveShark_reserve) {
                let data = JSON.parse(JSON.stringify(state.currentSaveShark_reserve));
                data = data.filter(e => e.block == action.block[0].name);
                if (data && data[0]) {
                    shark_data = [...data];
                }
            }
            if (state.currentSaveLeakfinder_reserve) {
                let data = JSON.parse(JSON.stringify(state.currentSaveLeakfinder_reserve));
                data = data.filter(e => e.block == action.block[0].name);
                if (data && data[0]) {
                    leakfinder_data = [...data];
                }
            }
            return {
                ...state,
                currentSaveShark: shark_data ? [...shark_data] : null,
                currentSaveLeakfinder: leakfinder_data ? [...leakfinder_data] : null,
                selected_block: action.block[0].name
            }
        }
        case SET_DUELS: {
            return {
                ...state,
                duels: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SET_SAVES: {
            return {
                ...state,
                saves: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SET_SAVE: {
            let blocks = [];
            if (state.blocks) blocks = [...state.blocks];
            if (action.data.result) {
                action.data.result.forEach(e => {
                    if (e.block) blocks.push(e.block)
                })
            }
            blocks = [...new Set(blocks)];

            if (action.data.type == "shark") {
                return {
                    ...state,
                    currentSaveShark: JSON.parse(JSON.stringify(action.data.result)).sort((a, b) => b.username.localeCompare(a.username)),
                    currentSaveShark_reserve: JSON.parse(JSON.stringify(action.data.result)).sort((a, b) => b.username.localeCompare(a.username)),
                    currentSaveLeakfinder: state.currentSaveLeakfinder ? [...state.currentSaveLeakfinder_reserve] : null,
                    blocks: blocks,
                    selected_block: []
                }
            } else {
                return {
                    ...state,
                    currentSaveLeakfinder: JSON.parse(JSON.stringify(action.data.result)).sort((a, b) => b.username.localeCompare(a.username)),
                    currentSaveLeakfinder_reserve: JSON.parse(JSON.stringify(action.data.result)).sort((a, b) => b.username.localeCompare(a.username)),
                    currentSaveShark: state.currentSaveShark ? [...state.currentSaveShark_reserve] : null,
                    blocks: blocks,
                    selected_block: []
                }
            }
        }
        default:
            return state;
    }
};

export const SetSaves = (data) => ({type: SET_SAVES, data});
export const SetSave = (data) => ({type: SET_SAVE, data});
export const SetDuels = (data) => ({type: SET_DUELS, data});
const SetBlockAC = (block) => ({type: SET_BLOCK, block});

export const SetBlock = (block) => {
    return (dispatch) => {
        dispatch(SetBlockAC(block))
    }
}

export const ExportToGoogleTable = (csv) => {
    return (dispatch) => {
        const body = {
            csv, token: Cookies.get("token")
        };
        LEADERBOARD_API.MTT_ExportToGoogleTable(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Экспорт данных в гугл таблицу", data.message))
                    return
                }
                dispatch(AddNotification("success", "Экспорт данных в гугл таблицу", "Готово. Можете перейти в гугл таблицу, которая привязана к экспорту :)"))
            })
    }
}

export const ChangeDuelStatus = (_id, winner_id, winner_username) => {
    return (dispatch) => {
        LEADERBOARD_API.MTT_ChangeDuelStatus(_id, winner_id, winner_username)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение статуса дуэли", data.message))
                    return
                }
                dispatch(SetDuels(data));
                dispatch(AddNotification("success", "Изменение статуса дуэли", "Статус изменен. Если статус не поменялся, то обновите страницу :)"))
            })
    }
}
export const GetDuels = () => {
    return (dispatch) => {
        LEADERBOARD_API.MTT_GetDuels()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение дуэлей", data.message))
                    return
                }
                dispatch(SetDuels(data));
            })
    }
}
export const GetSaves = () => {
    return (dispatch) => {
        LEADERBOARD_API.MTT_GetSaves()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение сохранений", data.message))
                    return
                }
                dispatch(SetSaves(data));
            })
    }
}
export const GetSave = (_id) => {
    return (dispatch) => {
        LEADERBOARD_API.MTT_GetSave(_id)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение сохранения", data.message))
                    return
                }
                dispatch(SetSave(data));
            })
    }
}
export const GetInfo = (timestamp_from, timestamp_to, type) => {
    return (dispatch) => {
        LEADERBOARD_API.MTT_GetInfoByPeriod(timestamp_from, timestamp_to, type)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение информации", data.message))
                    return
                }
                dispatch(AddNotification("success", "Получение информации", "Запрос отправлен. Ожидайте, в течении 1-5 минут появится сохранение с данными. Как только появится сохранение с данными - вы получите оповещение (если вы разрешали сайту их отправлять)"))
            })
    }
}

export default leaderboardReducer;