
import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const Container = ({children, padding, height, width, top, className}) => {
    return (
        <div className={classNames(s.children, className)}
             style={{
                 padding: padding,
                 minHeight: height,
                 maxWidth: width,
                 width: '100%',
                 marginTop: top
             }}>
            {children}
        </div>
    );
};

export default Container;