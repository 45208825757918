import React from 'react';
import s from './styles.module.css'
import Input from "../../../../common/input";
import TextArea from "../../../../common/textarea";
import arrowDown from '../../../../assests/arrowDownExchanger.png'
import arrowTop from '../../../../assests/arrowTopExchanger.png'
import classNames from "classnames";
import LastItem from "./lastItem";
import Popup from "reactjs-popup";
import OvalLoading from '../../../../assests/oval_load.svg'
import RequisitesCreatorContainer from "../../../../components/requisites/requisitesCreatorContainer";
import RequisitesSelectorContainer from "../../../../components/requisites/requisitesSelectorContainer";

const FormBlock = (props) => {

    const data = [
        {icon: arrowDown, title: 'При обмене у менял вы бы получили: ', price: `${props.Fbank}`, type: 'red'},
        {icon: arrowTop, title: 'При обмене у нас вы получите: ', price: `${props.FfsMin}`, type: 'yellow'},
    ]

    const onChangeNickname = (e) => {
        props.UserSetFormData(e, "nickname")
    }

    const onChangeCard = (e) => {
        props.UserSetFormData(e, "card")
    }

    const UserSendRequest = () => {
        props.UserSendTicket("request", props.FsChangerInfo.userCheckbox, props.FsChangerInfo.userCurrentBank?.public_type,
            props.FsChangerInfo.userCurrentBank?.public_name, props.FsChangerInfo.userCard, props.FsChangerInfo.userNickname,
            `${props.FsChangerInfo.userSum} ${props.FsChangerInfo.userCurrency}`, props.FsChangerInfo.userCurrentHelp);
    }

    return (
        <div>
            <div className={s.top}>
                <span>Данные для запроса обмена</span>
                <p>Ввод данных</p>
            </div>

            <div className={s.inputs}>
                <TextArea className={s.textarea}
                          onChange={onChangeCard}
                          name={"Реквизиты"} value={props.FsChangerInfo.userCard}
                          placeholder={props.FsChangerInfo.userCurrentBank ? props.FsChangerInfo.userCurrentBank.card_help : "Введите свои реквизиты"}/>
                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 1fr"}}>
                    <RequisitesCreatorContainer description={props.FsChangerInfo.userCard}/>
                    <div></div>
                    <RequisitesSelectorContainer onChangeCard={onChangeCard} type={"changer"}/>
                </div>
                <Input className={s.input}
                       onChange={onChangeNickname}
                       name={"Никнейм"} value={props.FsChangerInfo.userNickname}
                       placeholder={"Ваш никнейм в команде"}/>
            </div>

            <div className={s.checkbox}>
                <input checked={props.FsChangerInfo.userCheckbox} type="checkbox"
                       onClick={(e) => props.UserSetFormData(props.FsChangerInfo.userCheckbox, "checkbox")}/>
                <Popup key={"checkbox_rules_fs_changer"}
                       trigger={
                           <p style={{cursor: "pointer"}}>Я согласен с <span
                               style={{color: "#1088f8"}}>правилами</span> обмена</p>}
                       modal
                       nested
                >
                    {(close) => (
                        <div>
                            <div id="get-advice" className={s.popup}>
                                <form className="form">
                                    <div className={s.formgroup}>
                                        <div style={{
                                            width: "5%", marginTop: "-30px",
                                            float: "right",
                                            cursor: "pointer"
                                        }} onClick={close}>
                                            <img
                                                src="https://icon-library.com/images/close-icon-png-transparent/close-icon-png-transparent-14.jpg"
                                                alt="img"/>
                                        </div>
                                        <span style={{color: "white"}}>
Правила обмена:<br/>
1. Стороны соглашения.<br/><br/>

Команда Firestorm является стороной соглашения по части отправки средств игрока на карту или другой источник. <br/><br/>

2. Условия соглашения.<br/>
Заказчик соглашается с тем, что конечная сумма вывода будет уточнена в фактический момент обмена. Команда имеет право отказаться от обмена или изменить курсы и комиссии по поданной заявке на обмен в соответствии с ситуацией на рынке в момент фактического обмена. Все цифры представленные на сайте являются примерными.
<br/><br/>
3. Предмет соглашения.<br/>
Команда производит отправку средств в указанной валюте по реквизитам указанным Игроком.
<br/><br/>
4. Непредвиденные обстоятельства.<br/>
В случае, когда в процессе обработки заявки Заказчика возникают непредвиденные обстоятельства, способствующие невыполнению Командой обмена, сроки выполнения заявки переносятся на соответствующий срок длительности форс-мажора. За просроченные обязательства Команда ответственности не несет.
<br/><br/>
5. Претензии и споры.<br/>
Претензии по настоящему соглашению принимаются Командой в форме электронного сообщения, в котором Заказчик указывает суть претензии. Данное сообщение отправляется на странице обмена по кнопке "Обратиться в саппорт".
<br/><br/>
6. Отказ от обязательств.<br/>
6.1 Команда имеет право отказа на выполнение заявки, причем без объяснения причин. Данный пункт применяется по отношению к любому клиенту.<br/>
6.2 Команда не несет ответственность за обменные операции в случае несоблюдения данных правил, правил безопасности, ошибок при заполнении реквизитов, неотправке средств мерчантами и других случаев неполучения Заказчиком средств.<br/>
                                                </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>


            {
                props.FsChangerInfo.request_step == 1 ?
                    <div className={s.btn} onClick={UserSendRequest}>
                        <span>
                            Вывести деньги
                        </span>
                    </div>
                    :
                    <Popup key={"request_loading_fs_changer"}
                           defaultOpen={true}
                           modal
                           nested
                    >
                        {() => (
                            <div>
                                <div id="get-advice">
                                    <form className="form">
                                        <div className={s.formgroup}>
                                            {
                                                props.FsChangerInfo.request_step == 2 ?
                                                    <img src={OvalLoading} alt="loading" style={{width: "90px"}}/>
                                                    :
                                                    <svg className="ft-green-tick" xmlns="http://www.w3.org/2000/svg"
                                                         height={100} width={100} viewBox="0 0 48 48"
                                                         aria-hidden="true">
                                                        <circle className="circle" fill="#5bb543" cx={24} cy={24}
                                                                r={22}/>
                                                        <path className="tick" fill="none" stroke="#FFF" strokeWidth={6}
                                                              strokeLinecap="round" strokeLinejoin="round"
                                                              strokeMiterlimit={10} d="M14 27l5.917 4.917L34 17"/>
                                                    </svg>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Popup>
            }


            {
                props.userSum != 0 ?
                    <div className={s.last}>
                        {data.map((el, index) => <LastItem index={index} el={el} type={el.type}/>)}
                    </div> : null
            }
        </div>
    );
};

export default FormBlock;