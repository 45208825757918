import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import SupportsEditor from "./supportsEditor";
import {EditSupportAccess, GetSupportsAccessesList} from "../../../../redux/reducers/supportReducer";
import {GetUsersList} from "../../../../redux/reducers/adminReducer";

class SupportsEditorContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Редактор саппортов")
        this.props.GetSupportsAccessesList();
        this.props.GetUsersList(this.props.AuthInfo.id, this.props.AuthInfo.email, this.props.AuthInfo.accessToken)
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <SupportsEditor {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer,
    SupportsInfo: state.supportReducer,
    AdminInfo: state.adminReducer
})

export default compose(
    connect(mapStateToProps, {
        EditSupportAccess, GetSupportsAccessesList, GetUsersList
    }),
    withRouter
)(SupportsEditorContainer)