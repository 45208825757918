import {FIN_RESULTS_API, TRANSFERS_API, USER_API, USER_MAIN_PAGE} from "../../api/util";
import Cookies from "js-cookie";
import {NotificationManager} from "react-notifications";

const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE';
const CHANGE_MAIN_LOADING = 'CHANGE_MAIN_LOADING';
const MAIN_SET_MAIN_PAGE_INFO = 'MAIN_SET_MAIN_PAGE_INFO';
const MAIN_CHANGE_PERIOD_FORMULAR_ROWS = 'MAIN_CHANGE_PERIOD_FORMULAR_ROWS';
const MAIN_SORT_FORMULAR_ROWS = 'MAIN_SORT_FORMULAR_ROWS';
const MAIN_CHANGE_PERIOD_FINRESULTS_ROWS = 'MAIN_CHANGE_PERIOD_FINRESULTS_ROWS';
const MAIN_CHANGE_PERIOD_TRANSFERS_ROWS = 'MAIN_CHANGE_PERIOD_TRANSFERS_ROWS';
const MAIN_SET_FINRESULTS_PAGE_INFO = 'MAIN_SET_FINRESULTS_PAGE_INFO';
const MAIN_SET_TRANSFERS_PAGE_INFO = 'MAIN_SET_TRANSFERS_PAGE_INFO';
const MAIN_CHANGE_FINRESULTS_ROW_STATUS = 'MAIN_CHANGE_FINRESULTS_ROW_STATUS';
const MAIN_CHANGE_TRANSFER_ROW_STATUS = 'MAIN_CHANGE_TRANSFER_ROW_STATUS';

let initialState = {
    currentPage: "Главная страница",
    isLoading: false,
    mainPageData: {
        isRowsSorted: false,
        isPlayer: false,
        username: "Нет данных",
        formular_player_share_percent: "N/A",
        email: "Нет данных",
        notes: null,
        avatar: null,
        contractType: "Нет данных",
        contractDate: "Нет данных",
        sums: {
            all_time_profit: 0,
            all_time_hands: 0,

            period_profit: 0,
            period_hands: 0,

            selected_hands: 0,
            selected_rake: 0,
            selected_profit: 0,
            selected_three_bet: 0,
            selected_con_bet: 0,

            selected_count: 0,
            selected_prize: 0,
            selected_prizeBountyComponent: 0
        },
        rows: [],
        uniqueLimits: [], uniqueClubs: [], uniqueUnions: [], uniqueAccounts: [], uniqueNetworks: [], uniquePlayerNames: []
    },
    finResultsPageData: {
        isRowsSorted: false,
        sums: {
            selected_profit: 0,
            selected_rakeback: 0,
            selected_calculated: 0,
            selected_not_calculated: 0
        },
        rows: []
    },
    transfersPageData: {
        isRowsSorted: false,
        sums: {
            reserved_1: 0,
            reserved_2: 0,
            reserved_3: 0,
            reserved_4: 0,
            reserved_5: 0,
        },
        rows: []
    }
};

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case MAIN_CHANGE_TRANSFER_ROW_STATUS: {
            let rows = JSON.parse(JSON.stringify(state.transfersPageData.rows));
            const rowIndex = rows.findIndex(e => e._id == action._id);
            if (rowIndex != -1) {
                rows[rowIndex].status = action.level;
                return {
                    ...state,
                    transfersPageData: {
                        ...state.transfersPageData,
                        rows: JSON.parse(JSON.stringify(rows))
                    }
                }
            } else {
                return state;
            }
        }
        case MAIN_CHANGE_FINRESULTS_ROW_STATUS: {
            let rows = JSON.parse(JSON.stringify(state.finResultsPageData.rows));
            const rowIndex = rows.findIndex(e => e._id == action._id);
            if (rowIndex != -1) {
                rows[rowIndex].status = action.level;
                return {
                    ...state,
                    finResultsPageData: {
                        ...state.finResultsPageData,
                        rows: JSON.parse(JSON.stringify(rows))
                    }
                }
            } else {
                return state;
            }
        }
        case MAIN_SORT_FORMULAR_ROWS: {
            let sortedRows = JSON.parse(JSON.stringify(state.mainPageData.rows));
            switch (action.ttype) {
                case "Date": {
                    state.mainPageData.isRowsSorted ?
                    sortedRows.sort((a, b) => b.date_timestamp-a.date_timestamp)
                        : sortedRows.sort((b, a) => b.date_timestamp-a.date_timestamp)
                    break;
                }
                case "Limit": {
                    state.mainPageData.isRowsSorted ?
                        sortedRows.sort((a, b) => b.limit-a.limit)
                        : sortedRows.sort((b, a) => b.limit-a.limit)
                    break;
                }
                case "Hands": {
                    state.mainPageData.isRowsSorted ?
                        sortedRows.sort((a, b) => b.hands-a.hands)
                        : sortedRows.sort((b, a) => b.hands-a.hands)
                    break;
                }
                case "Profit": {
                    state.mainPageData.isRowsSorted ?
                        sortedRows.sort((a, b) => b.profit-a.profit)
                        : sortedRows.sort((b, a) => b.profit-a.profit)
                    break;
                }
                case "Profit_USD": {
                    state.mainPageData.isRowsSorted ?
                        sortedRows.sort((a, b) => b.profit_usd-a.profit_usd)
                        : sortedRows.sort((b, a) => b.profit_usd-a.profit_usd)
                    break;
                }
                case "RB_USD": {
                    state.mainPageData.isRowsSorted ?
                        sortedRows.sort((a, b) => b.rakeback_usd-a.rakeback_usd)
                        : sortedRows.sort((b, a) => b.rakeback_usd-a.rakeback_usd)
                    break;
                }
                default: {
                    break;
                }
            }
            return {
                ...state,
                mainPageData: {
                    ...state.mainPageData,
                    isRowsSorted: !state.mainPageData.isRowsSorted,
                    rows: JSON.parse(JSON.stringify(sortedRows))
                }
            }
        }
        case MAIN_CHANGE_PERIOD_FORMULAR_ROWS: {
            return {
                ...state,
                mainPageData: {
                    ...state.mainPageData,
                    sums: {
                        ...state.mainPageData.sums,
                        period_rakeback: action.data.sums.period_rakeback,
                        selected_hands: action.data.sums.selected_hands,
                        selected_rake: action.data.sums.selected_rake,
                        selected_profit: action.data.sums.selected_profit,
                        selected_three_bet: action.data.sums.selected_three_bet,
                        selected_con_bet: action.data.sums.selected_con_bet,
                        selected_limit: action.data.sums.selected_limit,

                        selected_count: action.data.sums.selected_count,
                        selected_prize: action.data.sums.selected_prize,
                        selected_prizeBountyComponent: action.data.sums.selected_prizeBountyComponent,
                    },
                    rows: JSON.parse(JSON.stringify(action.data.rows)),
                    uniqueLimits: action.data.uniqueLimits ? JSON.parse(JSON.stringify(action.data.uniqueLimits)) : null,
                    uniqueClubs: action.data.uniqueClubs ? JSON.parse(JSON.stringify(action.data.uniqueClubs)) : null,
                    uniqueUnions:  action.data.uniqueUnions ? JSON.parse(JSON.stringify(action.data.uniqueUnions)) : null,
                    uniqueAccounts: action.data.uniqueAccounts ? JSON.parse(JSON.stringify(action.data.uniqueAccounts)) : null,

                    uniqueNetworks: action.data.uniqueNetworks ? JSON.parse(JSON.stringify(action.data.uniqueNetworks)) : null,
                    uniquePlayerNames: action.data.uniquePlayerNames ? JSON.parse(JSON.stringify(action.data.uniquePlayerNames)) : null,
                }
            }
        }
        case MAIN_CHANGE_PERIOD_TRANSFERS_ROWS: {
            return {
                ...state,
                transfersPageData: {
                    ...state.transfersPageData,
                    sums: {
                        ...state.transfersPageData.sums,
                        reserved_1: action.data.sums.reserved_1,
                        reserved_2: action.data.sums.reserved_2,
                        reserved_3: action.data.sums.reserved_3,
                        reserved_4: action.data.sums.reserved_4,
                        reserved_5: action.data.sums.reserved_5,
                    },
                    rows: JSON.parse(JSON.stringify(action.data.rows)),
                    uniqueIds: JSON.parse(JSON.stringify(action.data.uniqueIds)),
                    uniqueTargets: JSON.parse(JSON.stringify(action.data.uniqueTargets)),
                    uniqueMethods: JSON.parse(JSON.stringify(action.data.uniqueMethods)),
                }
            }
        }
        case MAIN_CHANGE_PERIOD_FINRESULTS_ROWS: {
            return {
                ...state,
                finResultsPageData: {
                    ...state.finResultsPageData,
                    sums: {
                        ...state.finResultsPageData.sums,
                        selected_profit: action.data.sums.selected_profit,
                        selected_rakeback: action.data.sums.selected_rakeback,
                        selected_calculated: action.data.sums.selected_calculated,
                        selected_not_calculated: action.data.sums.selected_not_calculated
                    },
                    rows: JSON.parse(JSON.stringify(action.data.rows)),
                    uniqueIds: JSON.parse(JSON.stringify(action.data.uniqueIds)),
                    uniqueClubs: JSON.parse(JSON.stringify(action.data.uniqueClubs)),
                    uniqueUnions: JSON.parse(JSON.stringify(action.data.uniqueUnions)),
                    uniqueSetCounts: JSON.parse(JSON.stringify(action.data.uniqueSetCounts)),
                }
            }
        }
        case MAIN_SET_MAIN_PAGE_INFO: {
            return {
                ...state,
                mainPageData: JSON.parse(JSON.stringify(action.data))
            }
        }
        case MAIN_SET_FINRESULTS_PAGE_INFO: {
            return {
                ...state,
                finResultsPageData: JSON.parse(JSON.stringify(action.data))
            }
        }
        case MAIN_SET_TRANSFERS_PAGE_INFO: {
            return {
                ...state,
                transfersPageData: JSON.parse(JSON.stringify(action.data))
            }
        }
        case CHANGE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.pageName
            };
        case CHANGE_MAIN_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        default:
            return state;
    }
};

export const ChangeCurrentPage = (pageName) => ({type: CHANGE_CURRENT_PAGE, pageName});
export const ChangeMainLoading = (isLoading) => ({type: CHANGE_MAIN_LOADING, isLoading})
export const SortFormularRows = (ttype) => ({type: MAIN_SORT_FORMULAR_ROWS, ttype});

const SetMainPageInfo = (data) => ({type: MAIN_SET_MAIN_PAGE_INFO, data})
const SetFinResultsPageInfo = (data) => ({type: MAIN_SET_FINRESULTS_PAGE_INFO, data})
const SetTransfersPageInfo =(data)=>({type: MAIN_SET_TRANSFERS_PAGE_INFO, data})

const ChangePeriodFormularRows = (data) => ({type: MAIN_CHANGE_PERIOD_FORMULAR_ROWS, data})
const ChangePeriodFinResultsRows = (data) => ({type: MAIN_CHANGE_PERIOD_FINRESULTS_ROWS, data})
const ChangePeriodTransfersRows = (data) => ({type: MAIN_CHANGE_PERIOD_TRANSFERS_ROWS, data})
export const GetMainPageInfo = () => {
    return (dispatch) => {
        USER_MAIN_PAGE.getMainPageInfo()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return;
                dispatch(SetMainPageInfo(data))
            })
    }
}
export const GetFinResultsPageInfo = () => {
    return (dispatch) => {
        FIN_RESULTS_API.getFinResultsPageInfo()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return;
                dispatch(SetFinResultsPageInfo(data))
            })
    }
}
export const GetTransfersPageInfo = () => {
    return (dispatch) => {
        TRANSFERS_API.getTransfersPageInfo()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return;
                dispatch(SetTransfersPageInfo(data))
            })
    }
}
export const GetFormularRowsByPeriod =(data)=> {
    return (dispatch) => {
        const body = {
            data: {...data},
            token: Cookies.get("token")
        }
        USER_MAIN_PAGE.getFormularRowsByPeriod(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return;
                dispatch(ChangePeriodFormularRows(data))
            })
    }
}
export const GetFinResultsRowsByPeriod = (data) => {
    return (dispatch) => {
        const body = {
            data: {...data},
            token: Cookies.get("token")
        }
        FIN_RESULTS_API.getFinResultsRowsByPeriod(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return;
                dispatch(ChangePeriodFinResultsRows(data))
            })
    }
}
export const PlayerSendSetRequest = () => {
    return (dispatch) => {
        FIN_RESULTS_API.playerSendSetRequest()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return window.alert(data.message);
                window.alert("Запрос на расчёт сета отправлен! Проверить запрос вы можете в своей личной конференции")
            })
    }
}
export const GetTransfersRowsByPeriod = (data)=> {
    return (dispatch) => {
        const body = {
            data: {...data},
            token: Cookies.get("token")
        }
        TRANSFERS_API.getTransfersRowsByPeriod(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return;
                dispatch(ChangePeriodTransfersRows(data))
            })
    }
}
const ChangeFinResultRowStatus = (_id, level) => ({type: MAIN_CHANGE_FINRESULTS_ROW_STATUS, _id, level})
export const PlayerChangeStatus = (_id, level) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"), _id, level
        }
        FIN_RESULTS_API.changeStatus(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return;
                dispatch(ChangeFinResultRowStatus(_id, level))
            })
    }
}
const ChangeTransferRowStatus = (_id, level) => ({type: MAIN_CHANGE_TRANSFER_ROW_STATUS, _id, level})
export const PlayerTransferChangeStatus = (_id, level) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"), _id, level
        }
        TRANSFERS_API.changeStatus(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return;
                dispatch(ChangeTransferRowStatus(_id, level))
            })
    }
}

export const V_2_ChangeNotes = (newValue) => {
    return (dispatch) => {
        USER_API.changeNotes({id: Cookies.get("id"), access_token: Cookies.get("token"), newValue})
            .then(data => {

            })
    }
}

export const UserSendQuestion = (question) => {
    return (dispatch) => {
        USER_API.sendQuestion(question)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) return NotificationManager.error(data.message);
                return NotificationManager.success("Вопрос отправлен!");
            })
    }
}
export default mainReducer;