import React, {useState, useRef} from "react";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Popup from "reactjs-popup";
import moment from "moment";
import Select from "react-dropdown-select";

const PlayerSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать игрока"}
            labelField={"username"}
            valueField={"username"}
            sortBy={"username"}
            create
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "initial",
                margin: "-3px 0 0 10px",
                fontSize: "16px",
                border: "none",
                zIndex: "101",
                outline: "none"
            }}
        />
    )
}
const ClubSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать клуб"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            create
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "initial",
                margin: "-3px 0 0 10px",
                fontSize: "16px",
                border: "none",
                zIndex: "101",
                outline: "none"
            }}
        />
    )
}

export default function Player(props) {
    let ddate = new Date();
    const [fromTimestamp, setFromTimestamp] = useState(+new Date(ddate.getFullYear(), ddate.getMonth(), 1));
    const [toTimestamp, setToTimestamp] = useState(+new Date());

    const [selectedPlayer, setSelectedPlayer] = useState([]);
    const [selectedClub, setSelectedClub] = useState([]);

    const handleSelectPlayer = (e) => {
        if (!e || !e[0]) {
            setSelectedPlayer([])
        } else {
            setSelectedPlayer(e)
        }
    }

    const handleSelectClub = (e) => {
        if (!e || !e[0]) {
            setSelectedClub([])
        } else {
            setSelectedClub(e)
        }
    }

    let rhands = React.createRef();
    let rrake = React.createRef();
    let rprofit = React.createRef();
    let rvpip = React.createRef();
    let rpfr = React.createRef();
    let rthree_bet = React.createRef();
    let rcon_bet = React.createRef();
    let limit = React.createRef();
    let account_id = React.createRef();

    const handleChangeInfo = (type, v1, v2 = null) => {
        // if (type == "player") {
        //     setSelectedPlayerId(v1);
        //     setSelectedPlayerName(v2);
        // }
        // if (type == "club") {
        //     setSelectedClubName(v1)
        // }
        if (type == "from_timestamp") {
            setFromTimestamp(+v1);
        }
        if (type == "to_timestamp") {
            setToTimestamp(+v1);
        }
    }
    const handleGetPlayerInfo = () => {
        if (fromTimestamp && toTimestamp && selectedPlayer[0]) {
            props.SupportGetPlayerInfo(selectedPlayer[0].id, selectedClub[0] ? selectedClub[0].name : null, fromTimestamp, toTimestamp)
        }
    }
    const handleCreateRow = () => {
        props.SupportUpdatePlayer({
            type: "create",
            player_id: selectedPlayer[0].id,
            club_name: selectedClub[0] ? selectedClub[0].name : null,
            date_timestamp: toTimestamp,
            hands: rhands.current.value,
            rake: rrake.current.value,
            profit: rprofit.current.value,
            limit: limit.current.value,
            account_id: account_id.current.value
        })
        rhands.current.value = "";
        rrake.current.value = "";
        rprofit.current.value = "";
        limit.current.value = "";
        account_id.current.value = "";
    }
    return (
        <>
            <div>
                <div style={{
                    color: "white",
                    backgroundColor: "#1f1f27",
                    padding: "5px",

                }}>
                    <div style={{
                        marginTop: "10px",
                        display: "grid", gridTemplateColumns: "1fr 300px 20px 300px 1fr"
                    }}>
                        <div></div>
                        <PlayerSelector options={props.SupportInfo.usersListFilter}
                                        selected={selectedPlayer}
                                        handleSelect={handleSelectPlayer}/>
                        <div></div>
                        <ClubSelector options={props.FormularInfo.clubsList}
                                      selected={selectedClub}
                                      handleSelect={handleSelectClub}/>
                        <div></div>
                    </div>

                    <div style={{filter: "brightness(0) invert(1)", margin: "10px"}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                disableToolbar
                                variant="inline"
                                format="dd.MM.yyyy HH:mm"
                                margin="normal"
                                id="date-picker-inline"
                                label="ОТ"
                                value={fromTimestamp}
                                onChange={(date) => handleChangeInfo("from_timestamp", date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                disableToolbar
                                variant="inline"
                                format="dd.MM.yyyy HH:mm"
                                margin="normal"
                                id="date-picker-inline"
                                label="ДО"
                                value={toTimestamp}
                                onChange={(date) => handleChangeInfo("to_timestamp", date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <br/>
                        <div style={{fontSize: "12px"}}>
                            При создании новой строки для игрока - за дату возьмётся число, что указано в "ДО"
                        </div>
                    </div>
                    <div style={{padding: "0 40%", margin: "30px 0 10px 0"}}>

                        <div onClick={handleGetPlayerInfo}
                             className={"default-button green-back"}>
                            Найти
                        </div>
                    </div>
                </div>
            </div>
            {
                selectedPlayer[0] && toTimestamp && fromTimestamp &&
                <div className={"training-connectors-list-page"}>
                    <div style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px"}}
                         className={"columns-names"}>
                        <div className={"column-name"}>
                            Дата
                        </div>
                        <div className={"column-name"}>
                            Клуб
                        </div>
                        <div className={"column-name"}>
                            Союз
                        </div>
                        <div className={"column-name"}>
                            ID аккаунта
                        </div>
                        <div className={"column-name"}>
                            Лимит
                        </div>
                        <div className={"column-name"}>
                            Кол-во рук
                        </div>
                        <div className={"column-name"}>
                            Рейк
                        </div>
                        <div className={"column-name"}>
                            Профит
                        </div>
                        <div/>
                    </div>
                    <div className={"training-connectors-list"}>

                        <div style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px"}}
                             className={"connector"}>
                            <div className={"name"}>
                                {moment(+toTimestamp).format("DD.MM.YYYY")}
                            </div>
                            <div className={"name"}>
                                ---
                            </div>
                            <div className={"name"}>
                                ---
                            </div>

                            <div className={"column-data"}>
                                <input
                                    ref={account_id}
                                />
                            </div>

                            <div className={"column-data"}>
                                <input
                                    type={"number"}
                                    ref={limit}
                                />
                            </div>

                            <div className={"column-data"}>
                                <input
                                    type={"number"}
                                    ref={rhands}
                                />
                            </div>
                            <div className={"column-data"}>
                                <input
                                    type={"number"}
                                    ref={rrake}
                                />
                            </div>
                            <div className={"column-data"}>
                                <input
                                    type={"number"}
                                    ref={rprofit}
                                />
                            </div>

                            {
                                !props.isLoading && <div
                                    onClick={handleCreateRow}
                                    className={"createConnector"}>
                                    Создать
                                </div>
                            }

                        </div>


                        {
                            props.FormularInfo.playerInfo.map((row, i) => <Row
                                key={`${i} ${row._id}`}
                                row={row}
                                SupportUpdatePlayer={props.SupportUpdatePlayer}
                                isLoading={props.isLoading}
                            />)
                        }
                    </div>
                </div>
            }

        </>
    );
}

const Row = (props) => {
    const date = moment(+props.row.date_timestamp).format("DD.MM.YYYY")

    return (
        <div style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px"}} className={"connector"}>
            <div className={"name"}>
                {
                    date
                }
            </div>
            <div className={"name"}>
                {
                    props.row.club_name
                }
            </div>
            <div className={"name"}>
                {
                    props.row.club_union
                }
            </div>
            <div className={"column-data"}>
                <input
                    onBlur={(e) => props.SupportUpdatePlayer({
                        type: "update",
                        _id: props.row._id,
                        row: "account_id",
                        newValue: e.target.value
                    })}
                    defaultValue={props.row.account_id}/>
            </div>
            <div className={"column-data"}>
                <input
                    type={"number"}
                    onBlur={(e) => props.SupportUpdatePlayer({
                        type: "update",
                        _id: props.row._id,
                        row: "limit",
                        newValue: e.target.value
                    })}
                    defaultValue={props.row.limit}/>
            </div>

            <div className={"column-data"}>
                <input
                    type={"number"}
                    onBlur={(e) => props.SupportUpdatePlayer({
                        type: "update",
                        _id: props.row._id,
                        row: "hands",
                        newValue: e.target.value
                    })}
                    defaultValue={props.row.hands}/>
            </div>
            <div className={"column-data"}>
                <input
                    type={"number"}
                    onBlur={(e) => props.SupportUpdatePlayer({
                        type: "update",
                        _id: props.row._id,
                        row: "rake",
                        newValue: e.target.value
                    })}
                    defaultValue={props.row.rake}/>
            </div>
            <div className={"column-data"}>
                <input
                    type={"number"}
                    onBlur={(e) => props.SupportUpdatePlayer({
                        type: "update",
                        _id: props.row._id,
                        row: "profit",
                        newValue: e.target.value
                    })}
                    defaultValue={props.row.profit}/>
            </div>
            {
                !props.isLoading && <div
                    onClick={() => props.SupportUpdatePlayer({
                        type: "delete",
                        _id: props.row._id
                    })}
                    style={{backgroundColor: "tomato"}}
                    className={"createConnector"}>
                    Удалить
                </div>
            }


        </div>
    )
}