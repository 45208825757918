import React, {useEffect, useMemo, useState} from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const Progress = ({value = 0, type = 'horizontal', data, isDefaultLogic = false}) => {

    let v = (100 * value) / 16000;
    if (isDefaultLogic) v = value;

    const [persent, setPersent] = useState(0)

    useMemo(() => {
        if (persent >= v) return
        setTimeout(() => setPersent(() => persent + 1), 30);

    }, [persent, v])

    return (
        <div className={classNames(type === 'vertical' ? s.vertical_box : s.progress_box)}>
            <div className={classNames(type === 'vertical' ? s.vertical : s.progress)}>
                <div className={classNames(type === 'vertical' ? s.vertical_progress : s.complete_progress)} style={{
                    width: type !== 'vertical' ? `${persent}%` : '11px',
                    height: type !== 'vertical' ? '100%' : `${persent}%`,
                }}/>
            </div>
        </div>
    );
};

export default Progress;