import React, {useState, useRef} from "react";
import {NavLink} from "react-router-dom";
import Popup from "reactjs-popup";
import {CompactPicker} from 'react-color';
import MainLoading from "../../common/mainLoading";

function uniq(a) {
    return a.sort().filter(function (item, pos, ary) {
        return !pos || item != ary[pos - 1];
    });
}

export default function CategoriesList(props) {
    let createCategoryName = React.createRef();
    let createCategoryIconUrl = React.createRef();

    let subCategoriesList = [];
    if (props.PagesInfo.pagesList[0]) {
        subCategoriesList = props.PagesInfo.pagesList.map(page => page.subCategoryName);
        subCategoriesList = uniq(subCategoriesList);
        subCategoriesList = subCategoriesList.map(subCategoryName => {
            let body = {
                name: subCategoryName,
                iconUrl: "https://lk.firestorm.team/img/icon2.svg",
                color: "#ffffff",
                priority: 0
            }
            props.PagesInfo.pagesList.forEach(page => {
                if (page.subCategoryName == subCategoryName && page.subCategoryIconUrl) {
                    body.iconUrl = page.subCategoryIconUrl;
                    body.color = page.subCategoryNameColor;
                    body.priority = page.subCategoryPriority;
                }
            })
            return body;
        })
    }
    let createCategory = () => {
        props.CreateCategory(props.AuthInfo.accessToken, props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username,
            createCategoryName.current.value, createCategoryIconUrl.current.value);
    }

    return (
        props.isLoading ? <MainLoading/>
            :
        <div className="categories-list">
            <Popup key={props.CategoriesInfo.categoriesList.length + " " + "creationCategoryPopup"}
                   trigger={<a className={"creator"}>Создать категорию</a>}
                   modal
                   nested
            >
                {() => (
                    <div>
                        <div id="get-advice" className="main-popup">
                            <form className="form">
                                <div className="formgroup">
                                    <input ref={createCategoryName} type="text" placeholder="Название категории"/>
                                </div>
                                <div className="formgroup">
                                    <input type="text" ref={createCategoryIconUrl}
                                           placeholder="Ссылка на иконку (обязательно PNG с прозрачным фоном)"/>
                                </div>
                            </form>
                            <button onClick={createCategory} className={"send-btn"}>Создать</button>
                            {
                                props.CategoriesInfo.creationError ?
                                    <div className={"error"}>{props.CategoriesInfo.creationError}</div>
                                    : null
                            }
                        </div>
                    </div>
                )}
            </Popup>

            <div className={"blocks"}>
                <div>
                    <div className={"name"}>Категории</div>
                    {props.CategoriesInfo.categoriesList.map((category, i) => <CategoryBlock category={category}
                                                                                             AuthInfo={props.AuthInfo}
                                                                                             key={category.name + " " + "i"}
                                                                                             CategoryIndex={i}
                                                                                             EditCategory={props.EditCategory}/>)}
                </div>
                <div>
                    <div className={"name"}>Подкатегории</div>
                    {subCategoriesList.map((subCategory, i) => <SubCategoryBlock iconUrl={subCategory.iconUrl}
                                                                                 name={subCategory.name}
                                                                                 color={subCategory.color}
                                                                                 priority={subCategory.priority}
                                                                                 key={subCategory.name + " " + "i"}
                                                                                 EditSubCategory={props.EditSubCategory}
                    />)}

                </div>
            </div>
        </div>
    );
}

const CategoryBlock = (props) => {
    let categoryName = React.createRef();
    let categoryIconUrl = React.createRef();
    let categoryPriority = React.createRef();

    let EditCategory = (e) => {
        if (e.target.accessKey == 'name') props.EditCategory(e.target.accessKey, props.category.name, categoryName.current.value)
        if (e.target.accessKey == 'icon') props.EditCategory(e.target.accessKey, props.category.name, categoryIconUrl.current.value)
        if (e.target.accessKey == 'priority') props.EditCategory(e.target.accessKey, props.category.name, categoryPriority.current.value)

    }
    return (
        <Popup key={props.category.name + " " + "moderateCategoryPopup"}
               trigger={
                   <div className={"category-block"}>
                       <div className={"category-avatar"}>
                           <img src={props.category.iconUrl} alt={"categoryIcon"}/>
                       </div>
                       <div className={"category-name"}>
                           {props.category.name}
                       </div>
                   </div>}
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="main-popup">
                        <form className="form">
                            <div className="formgroup">
                                <input ref={categoryName} type="text" title="Название категории"
                                       defaultValue={props.category.name} accessKey={"name"} onBlur={EditCategory}
                                       placeholder={"Название категории"}
                                />
                            </div>
                            <div className="formgroup">
                                <input ref={categoryIconUrl} type="text" title="Иконка категории"
                                       defaultValue={props.category.iconUrl} accessKey={"icon"} onBlur={EditCategory}
                                       placeholder={"Иконка категории"}
                                />
                            </div>
                            <div className="formgroup">
                                <input ref={categoryPriority} type="text" title="Приоритет категории (number)"
                                       defaultValue={props.category.priority} accessKey={"priority"}
                                       onBlur={EditCategory}
                                       placeholder={"Приоритет категории (number)"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}
const SubCategoryBlock = (props) => {
    let subCategoryName = React.createRef();
    let subCategoryIconUrl = React.createRef();
    let subCategoryPriority = React.createRef();

    const colorEditor = useRef(null);
    const [color, setColor] = useState();

    let handleColorChangeComplete = (color) => {
        setColor(color.hex);
        props.EditSubCategory("color", props.name, color.hex)
    };

    let EditSubCategory = (e) => {
        if (e.target.accessKey == 'name') props.EditSubCategory(e.target.accessKey, props.name, subCategoryName.current.value)
        if (e.target.accessKey == 'icon') props.EditSubCategory(e.target.accessKey, props.name, subCategoryIconUrl.current.value)
        if (e.target.accessKey == 'priority') props.EditSubCategory(e.target.accessKey, props.name, subCategoryPriority.current.value)

    }
    return (
        <Popup key={props.name + " " + "moderateSubCategoryPopup"}
               trigger={
                   <div className={"category-block"}>
                       <div className={"sub-category-avatar"}>
                           <img src={props.iconUrl} alt={"categoryIcon"}/>
                       </div>
                       <div style={{color: props.color}} className={"category-name"}>
                           {props.name}
                       </div>
                   </div>}
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="main-popup">
                        <form className="form">
                            <div className="formgroup">
                                <input ref={subCategoryName} type="text" title="Название подкатегории"
                                       defaultValue={props.name} accessKey={"name"} onBlur={EditSubCategory}
                                       placeholder={"Название подкатегории"}
                                />
                            </div>

                            <div className="subcategory-color-picker">
                                <div className={"subcategory-color-picker-name"}>Цвет названия подкатегории</div>
                                <CompactPicker
                                    color={color}
                                    onChangeComplete={handleColorChangeComplete}
                                />
                            </div>

                            <div className="formgroup">
                                <input ref={subCategoryIconUrl} type="text" title="Иконка подкатегории"
                                       defaultValue={props.iconUrl} accessKey={"icon"} onBlur={EditSubCategory}
                                       placeholder={"Иконка подкатегории"}
                                />
                            </div>
                            <div className="formgroup">
                                <input ref={subCategoryPriority} type="text" title="Приоритет подкатегории (number)"
                                       defaultValue={props.priority} accessKey={"priority"} onBlur={EditSubCategory}
                                       placeholder={"Приоритет подкатегории (number)"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}