export const formatDate = (_date) => {
    let d = new Date(_date);
    const day = d?.getDate();
    const month = d?.getMonth();
    const year = d?.getFullYear();

    return day + '-' + month + '-' + year;
}

export const getYear = (date) => {
    let d = new Date(date);
    return d.getFullYear();
}

export const getMonth = (date, delta) => {
    let d = new Date(date);
    let result = d.getMonth();

    if (delta) {
        result += delta;

        if (result < 0) {
            result = 11;
        } else if (result > 11) {
            result = 0;
        }
    }

    return result;
}

export const data = {
    years: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020],
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekDayNames: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    weekDayNamesFull: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
};
