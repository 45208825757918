import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Input from "../../../common/input";
import CustomSelect from "../../../common/select";
import classNames from "classnames";
import PopUpContainer from "../../../common/popup_container";
import closeArrow from "../../../assests/arrow_small.png";
import CloseArrowPopup from "../../../common/closeArrowPopup";
import {useWindowSize} from "../../../hooks/useWindowSize";
import Textarea from "../../../common/textarea";
import {RefSendRequest} from "../../../../redux/reducers/referralReducer";
import RequisitesCreatorContainer from "../../../components/requisites/requisitesCreatorContainer";
import RequisitesSelectorContainer from "../../../components/requisites/requisitesSelectorContainer";

const dataClub = [
    {id: 1, title: 'Club 1'},
    {id: 2, title: 'Club 2'},
    {id: 3, title: 'Club 3'},
    {id: 4, title: 'Club 4'},
];

const PopupGetMoney = (props) => {
    const {width} = useWindowSize()

    const [valueCurrency, setValueCurrency] = useState()
    const [cardInfo, setCardInfo] = useState()
    const [valueSelect, setValueSelect] = useState({})
    const [isError, setIsError] = useState(false)
    const [checked, setChecked] = useState(true)



    const [selectedData, setSelectedData] = useState([
        {
            title: 'Выберите счет для вывода',
            data: dataClub,
            titleHeader: 'Union:',
            name: 'union',
            id: 1,
            isShowMenu: false
        },
    ])

    useEffect(() => {
        setIsError(+valueCurrency > props.ReferralInfo.full_info.available_sum + 0.1)
    }, [valueCurrency, props.ReferralInfo.full_info.available_sum])

    const handleSendRequest = () => {
        let v = valueCurrency;
        let c = cardInfo;
        setCardInfo(null)
        setValueCurrency(null)
        props.RefSendRequest(+v, c);
        props.showErrorOrSuccess(checked ? 'success' : 'error');
    }

    return (
        <div className={s.get_money}>
            <CloseArrowPopup title={'Вывод средств'} onClose={props.onClose}/>
            <h2>{width > 768 ? 'Вывод средств' : 'Ввод данных'}</h2>
            <div className={s.action_box}>
                <div className={s.input_box}>
                    <Input className={isError && s.error_input} type={'number'} value={valueCurrency}
                           placeholder={'Введите сумму'}
                           title={<div className={s.input_right}>
                               <p className={s.sum}>$ {isNaN(props.ReferralInfo.full_info.available_sum) ? 0 : props.ReferralInfo.full_info.available_sum.toFixed(2)}</p>
                               <p className={s.all} onClick={() => setValueCurrency(isNaN(props.ReferralInfo.full_info.available_sum) ? 0 : props.ReferralInfo.full_info.available_sum.toFixed(2))}>Все</p>
                           </div>} onChange={(e) => setValueCurrency(e.target.value)}/>
                    {isError && <span className={s.error}>Превышена сумма</span>}
                </div>

                <div className={s.selected_box} style={{width: '100%'}}>
                    <Textarea className={isError && s.error_input} value={cardInfo}
                           placeholder={'Введите реквизиты для вывода (номер карты, название банка, номер телефона, ФИО), желаемую валюту'}
                           title={<div className={s.input_right}>
                    </div>} onChange={(e) => setCardInfo(e.target.value)}/>
                    {/*{selectedData.map((el, i) => <CustomSelect*/}
                    {/*    className={s.select}*/}
                    {/*    setValue={(element) => setValueSelect({...valueSelect, [el.name]: element})}*/}
                    {/*    value={valueSelect[el.name]}*/}
                    {/*    setSelectedData={setSelectedData}*/}
                    {/*    selectedData={selectedData}*/}
                    {/*    {...el}*/}
                    {/*/>)}*/}
                    {/*<p className={s.added_pay} onClick={() => props.setAddedPay(true)}>*/}
                    {/*    Добавить новый счет*/}
                    {/*</p>*/}
                </div>

                {/*<div className={s.checkbox_box}>*/}
                {/*    <div className={s.checkbox}>*/}
                {/*        <input type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)}/>*/}
                {/*        <p>Я согласен с <span className={s.checkbox_text}>правилами вывода</span></p>*/}
                {/*    </div>*/}
                {/*</div>*/}


                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 1fr"}}>
                    <RequisitesCreatorContainer description={cardInfo}/>
                    <div></div>
                    <RequisitesSelectorContainer setCardInfo={setCardInfo} type={"salary"}/>
                </div>

                <div className={classNames(s.btn, isError && s.btn_error)} style={{marginTop: "30px"}}
                     onClick={() => !isError && handleSendRequest()}>
                    Вывести деньги
                </div>
            </div>
        </div>
    );
};

export default PopupGetMoney;
