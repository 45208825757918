import React from 'react';
import s from './styles.module.css';
const ImgField = (value) => {
    return (
        <div className={s.img}>
            <img src={value.value} alt='' />
        </div>
    );
};

export default ImgField;