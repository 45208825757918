import {ADMIN_API, CATEGORIES_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";

const SET_PAGES_LIST = 'SET_PAGES_LIST';
const ADD_NEW_PAGE = 'ADD_NEW_PAGE';
const DELETE_PAGE_FROM_LIST = 'DELETE_PAGE_FROM_LIST';
const CHANGE_CREATION_CATEGORY_NAME = 'CHANGE_CREATION_CATEGORY_NAME';
const CHANGE_CREATION_DOC_TYPE_NAME = 'CHANGE_CREATION_DOC_TYPE_NAME';
const CHANGE_CREATION_SUBCATEGORY_NAME = 'CHANGE_CREATION_SUBCATEGORY_NAME';
const SET_PAGE_CREATION_ERROR = 'SET_PAGE_CREATION_ERROR';
const EDIT_PAGE = 'EDIT_PAGE';
const EDIT_SUBCATEGORIES_IN_LIST = 'EDIT_SUBCATEGORIES_IN_LIST';


let initialState = {
    pagesList: [],
    creationCategoryName: "Выбрать категорию",
    creationDocTypeName: "Выбрать тип страницы",
    creationSubCategoryName: "Выбрать подкатегорию",
    subCategoriesList: [],
    pageCreationError: null
};

const pagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_SUBCATEGORIES_IN_LIST: {
            let newPagesList = state.pagesList.map(page => {
                if (action.editType == 'name' && page.subCategoryName == action.oldName) {
                    return {
                        ...page,
                        subCategoryName: action.newValue
                    }
                } else if (action.editType == 'icon' && page.subCategoryName == action.oldName) {
                    return {
                        ...page,
                        subCategoryIconUrl: action.newValue
                    }
                } else if (action.editType == 'color' && page.subCategoryName == action.oldName) {
                    return {
                        ...page,
                        subCategoryNameColor: action.newValue
                    }
                } else if (action.editType == 'priority' && page.subCategoryName == action.oldName) {
                    return {
                        ...page,
                        subCategoryPriority: action.newValue
                    }
                } else {
                    return page;
                }
            })
            return {
                ...state,
                pagesList: [...newPagesList]
            }
        }
        case EDIT_PAGE:
            let editedPagesList = state.pagesList.map(page => {
                if (page.name == action.page.oldName) {
                    return action.page.page
                } else {
                    return page;
                }
            })
            return {
                ...state,
                pagesList: [...editedPagesList]
            }
        case SET_PAGE_CREATION_ERROR:
            return {
                ...state,
                pageCreationError: action.message
            }
        case CHANGE_CREATION_SUBCATEGORY_NAME:
            return {
                ...state,
                creationSubCategoryName: action.creationSubCategoryName
            }
        case CHANGE_CREATION_CATEGORY_NAME:
            return {
                ...state,
                creationCategoryName: action.creationCategoryName
            }
        case CHANGE_CREATION_DOC_TYPE_NAME:
            return {
                ...state,
                creationDocTypeName: action.creationDocTypeName
            }
        case SET_PAGES_LIST:
            let subCategoriesList = action.pages.map(page => page.subCategoryName)
            subCategoriesList = uniq(subCategoriesList);
            return {
                ...state,
                pagesList: action.pages,
                subCategoriesList: [...subCategoriesList]
            };
        case ADD_NEW_PAGE:
            return {
                ...state,
                pagesList: [action.page, ...state.pagesList]
            };
        case DELETE_PAGE_FROM_LIST:
            let isPageFound = false;
            let pageIndex = 0;
            let newPagesList = [...state.pagesList];
            state.pagesList.forEach((page, i) => {
                if (page.name == action.pageName) {
                    isPageFound = true;
                    pageIndex = i;
                }
            })
            if (isPageFound) {
                newPagesList.splice(pageIndex, 1);
                return {
                    ...state,
                    pagesList: [...newPagesList]
                }
            } else {
                return state;
            }
        default:
            return state;
    }
};

export const SetPagesList = (pages) => ({type: SET_PAGES_LIST, pages});
const AddNewPage = (page) => ({type: ADD_NEW_PAGE, page})
const DeletePageFromList = (pageName)=>({type: DELETE_PAGE_FROM_LIST, pageName})
export const ChangeCreationCategoryName = (creationCategoryName)=>({type: CHANGE_CREATION_CATEGORY_NAME, creationCategoryName})
export const ChangeCreationDocTypeName = (creationDocTypeName)=>({type: CHANGE_CREATION_DOC_TYPE_NAME, creationDocTypeName})
export const ChangeCreationSubCategoryName = (creationSubCategoryName) => ({type: CHANGE_CREATION_SUBCATEGORY_NAME, creationSubCategoryName})
const EditPageInList = (page) => ({type: EDIT_PAGE, page})
const EditSubCategoriesInList = (editType, oldName, newValue) => ({type: EDIT_SUBCATEGORIES_IN_LIST, editType, oldName, newValue})

export const SetPageCreationError = (message) => ({
    type: SET_PAGE_CREATION_ERROR,
    message
})

export const EditSubCategory = (type, name, newValue) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            subCategoryInfo: {
                type, name, newValue
            }
        }

        if (type == "name" && newValue.length > 18) {
            dispatch(AddNotification("error", "Изменение подкатегории", "Максимум 18 символов для названия подкатегории!"))
            dispatch(ChangeMainLoading(false))
            return;
        }

        CATEGORIES_API.editSubCategory(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение подкатегории", data.message))
                    return
                }
                dispatch(EditSubCategoriesInList(type, name, newValue))
                dispatch(AddNotification("success", "Изменение подкатегории", "Подкатегория изменена"))
            })
    }
}
export const EditPage = (id, email, username, access_token, pageName, propertyName, newValue) => {
  return (dispatch) => {
      dispatch(ChangeMainLoading(true))
      if (propertyName == "type" && newValue.toLowerCase() != 'документ' && newValue.toLowerCase() != 'таблица' &&
          newValue.toLowerCase() != 'график' && newValue.toLowerCase() != 'курс' && newValue.toLowerCase() != 'фрейм') {
          dispatch(AddNotification("error", "Изменение страницы", "Тип страницы должен быть строго: документ/таблица/график/курс/фрейм!"))
          dispatch(ChangeMainLoading(false))
          return;
      }
      if (!propertyName || !newValue) {
          dispatch(AddNotification("error", "Изменение страницы", "Нельзя сохранить пустое поле!"))
          dispatch(ChangeMainLoading(false))
          return;
      }
      if (propertyName == "name" && newValue.length > 24) {
          dispatch(AddNotification("error", "Изменение страницы", "Максимум 24 символа для названия страницы!"))
          dispatch(ChangeMainLoading(false))
          return;
      }
      if (propertyName == "subCategoryName" && newValue.length > 18) {
          dispatch(AddNotification("error", "Изменение страницы", "Максимум 18 символов для названия подкатегории!"))
          dispatch(ChangeMainLoading(false))
          return;
      }

      let body = {
          access_token,
          info: {
              id, email, username
          },
          page_info: {
              name: pageName,
              propertyName, newValue
          }
      }

      ADMIN_API.editPage(body)
          .then(data => {
              dispatch(ChangeMainLoading(false))
              if (data.resultCode && data.resultCode != 1) {
                  dispatch(AddNotification("error", "Изменение страницы", data.message))
                  return
              }
              dispatch(EditPageInList(data))
              dispatch(AddNotification("success", "Изменение страницы", "Страница изменена"))
          })
  }
};
export const DeletePage = (id,email,username,access_token,pageName) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id,email,username
            },
            access_token,
            page_info: {
                name: pageName
            }
        }
        ADMIN_API.adminDeletePage(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление страницы", data.message))
                    return
                }
                dispatch(DeletePageFromList(pageName))
                dispatch(AddNotification("success", "Удаление страницы", "Страница удалена"))
            })
    }
}

export const GetPagesList = (id, email, access_token) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        ADMIN_API.getPagesList(id, email, access_token)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение списка страниц", data.message))
                    return
                }
                dispatch(SetPagesList(data))
            })
    }
}
export const CreatePage = (id, email, username, access_token, name, description,
                           type, docId, categoryName, subCategoryName) => {
    return (dispatch) => {

        dispatch(ChangeMainLoading(true))
        if (type.toLowerCase() != 'документ' && type.toLowerCase() != 'таблица' &&
            type.toLowerCase() != 'график' && type.toLowerCase() != 'курс' && type.toLowerCase() != "фрейм") {
            dispatch(ChangeMainLoading(false))
            dispatch(AddNotification("error", "Создание страницы", "Тип страницы должен быть строго: документ/таблица/график/курс/фрейм!"))
            return;
        }
        if (!name || !type || !docId || !categoryName || !subCategoryName || !description) {
            dispatch(ChangeMainLoading(false))
            dispatch(AddNotification("error", "Создание страницы", "Все поля обязательны к заполнению!"))
            return;
        }
        if (name.length > 24) {
            dispatch(ChangeMainLoading(false))
            dispatch(AddNotification("error", "Создание страницы", "Максимум 24 символа для названия страницы!"))
            return;
        }
        if (subCategoryName.length > 18) {
            dispatch(ChangeMainLoading(false))
            dispatch(AddNotification("error", "Создание страницы", "Максимум 18 символов для названия подкатегории!"))
            return;
        }

        let body = {
            info: {
                id, email, username
            },
            access_token,
            page_info: {
                name, description,
                type, categoryName, subCategoryName,
                docId, docIndex: "0"
            }
        }

        ADMIN_API.createPage(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание страницы", data.message))
                    return
                }
                dispatch(AddNewPage(body.page_info))
                dispatch(AddNotification("success", "Создание страницы", "Страница создана"))
            })
    }
}

function uniq(a) {
    return a.sort().filter(function (item, pos, ary) {
        return !pos || item != ary[pos - 1];
    });
}

export default pagesReducer;

