import React, {useState, useRef} from "react"
import MainLoading from "../common/mainLoading";

export default function WikiViewer(props) {
    return (
        props.isLoading ? <MainLoading/>
            :
            <div className={"wiki-article"}><div dangerouslySetInnerHTML={{__html: props.WikiInfo.currentPage.content}}/></div>

    );
}
