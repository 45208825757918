import * as axios from "axios";
import Cookies from "js-cookie";

// let baseURL = 'http://localhost:3001/api/lk';
let baseURL = 'https://api.firestorm.team/api/lk';
// let ssURL = 'http://195.201.60.237:3002';
let ssURL = 'https://frame.firestorm.team';

let instance = axios.create({
    withCredentials: false,
    baseURL: baseURL
});

let ss_instance = axios.create({
    withCredentials: false,
    baseURL: ssURL
});

export const C_SUPPORTS_API = {
    getWelcomeMessageInfo() {
        return instance.get(`/c_support/welcomemessageinfo?token=${Cookies.get("token")}`).then(r => r.data)
    },
    addWelcomeMessageInfo(body) {
        return instance.post(`/c_support/addwelcomemessageinfo`, body).then(r => r.data)
    },
    removeWelcomeMessageInfo(guildId, roleId) {
        return instance.get(`/c_support/removewelcomemessageinfo?token=${Cookies.get("token")}&guildId=${guildId}&roleId=${roleId}`).then(r => r.data)
    },
    editWelcomeMessageInfo(body) {
        return instance.post(`/c_support/editwelcomemessageinfo`, body).then(r => r.data)
    },
    getSpinPoolDataByPeriod(period) {
        return instance.get(`/c_support/spin/pool/data?token=${Cookies.get("token")}&period=${period}`).then(r => r.data)
    },
    getMttTournamentsByPeriod(date_from, date_to) {
        return instance.get(`/c_support/mtt/players/tournaments?token=${Cookies.get("token")}&date_from=${date_from}&date_to=${date_to}`).then(r => r.data)
    },
    MttTournamentsExportToGoogleTable(body) {
        return instance.post(`/c_support/mtt/players/tournaments/export`, body).then(r => r.data)
    },
    removeMttTournamentById(_id) {
        return instance.get(`/c_support/mtt/players/tournament/remove?token=${Cookies.get("token")}&_id=${_id}`).then(r => r.data);
    }
}

export const DEV_API = {
    sendNotification() {
        return instance.get(`/dev/test/sendNotification?token=${Cookies.get("token")}`).then(r => r.data)
    },
    errorLogs_GetSystemNames() {
        return instance.get(`/dev/logs/errors/system/names?token=${Cookies.get("token")}`).then(r => r.data)
    },
    errorLogs_GetSystem(system) {
        return instance.get(`/dev/logs/errors/system?token=${Cookies.get("token")}&system=${system}`).then(r => r.data)
    },
    errorLogs_EditLog(body) {
        return instance.post(`/dev/logs/errors/log`, body).then(r => r.data)
    }
}

export const LEADERBOARD_API = {
    MTT_GetInfoByPeriod(timestamp_from, timestamp_to, type) {
        return instance.get(`/leaderboard/info?token=${Cookies.get('token')}&timestamp_from=${timestamp_from}&timestamp_to=${timestamp_to}&type=${type}`).then(r => r.data);
    },
    MTT_GetSaves() {
        return instance.get(`/leaderboard/info/saves?token=${Cookies.get('token')}`).then(r => r.data);
    },
    MTT_GetSave(_id) {
        return instance.get(`/leaderboard/info/save?token=${Cookies.get('token')}&_id=${_id}`).then(r => r.data);
    },
    MTT_GetDuels() {
        return instance.get(`/leaderboard/info/duels?token=${Cookies.get('token')}`).then(r => r.data);
    },
    MTT_ChangeDuelStatus(_id, winner_id, winner_username) {
        return instance.get(`/leaderboard/info/duel?token=${Cookies.get('token')}&_id=${_id}&winner_id=${winner_id}&winner_username=${winner_username}`).then(r => r.data);
    },
    MTT_ExportToGoogleTable(body) {
        return instance.post(`/leaderboard/export/google/tables`, body).then(r => r.data);
    },
}

export const SALARY_API = {
    supportGetTickets(body) {
        return instance.post(`/salary/support/getTickets`, body).then(r => r.data)
    },
    supportChangeStatus(body) {
        return instance.post(`/salary/support/changeTicketStatus`, body).then(r => r.data)
    },
    supportEditTicket(body) {
        return instance.post(`/salary/support/ticket`, body).then(r=> r.data)
    },
    adminDeleteTicket(timestamp_creation) {
        return instance.delete(`/salary/admin/ticket?token=${Cookies.get('token')}&timestamp_creation=${timestamp_creation}`).then(r=> r.data);
    },



    getUsersState(period) {
        return instance.get(`/salary/admin/users/state?token=${Cookies.get("token")}&period=${period}`).then(r => r.data)
    },
    getProfiles() {
        return instance.get(`/salary/profiles?token=${Cookies.get("token")}`).then(r => r.data)
    },
    getUsers() {
        return instance.get(`/salary/users?token=${Cookies.get("token")}`).then(r => r.data)
    },
    addUser(body) {
        return instance.put(`/salary/users`, body).then(r => r.data)
    },
    removeUser(_id) {
        return instance.delete(`/salary/users?token=${Cookies.get("token")}&_id=${_id}`).then(r => r.data)
    },
    getRows() {
        return instance.get(`/salary/rows?token=${Cookies.get("token")}`).then(r => r.data)
    },
    addRow(body) {
        return instance.put(`/salary/rows`, body).then(r => r.data)
    },
    removeRow(_id) {
        return instance.delete(`/salary/rows?token=${Cookies.get("token")}&_id=${_id}`).then(r => r.data)
    },
    getFullInfo() {
        return ss_instance.get(`/salary/get_full_info/${Cookies.get("token")}/${Cookies.get("id")}`).then(r => r.data)
    },
    sendWithdrawRequest(body) {
        return ss_instance.post(`/salary/get/${Cookies.get("token")}/${Cookies.get("id")}`, body).then(r => r.data)
    },
}

export const LEAKFINDER_SPIN_API = {
    removeSavesByName(saveName) {
        return instance.get(`/leakfinder_spin/removeSavesByName?token=${Cookies.get("token")}&saveName=${saveName}`).then(r => r.data)
    },
    saveComment(body) {
        return instance.post(`/leakfinder_spin/saveComment`, body).then(r => r.data)
    },
    analyzeAll(timestamp_from, timestamp_to, selectedRanks, db_info, saveName) {
        return instance.post(`/leakfinder_spin/analyzeAll`, {
            timestamp_from, saveName,
            timestamp_to,
            token: Cookies.get("token"),
            selectedRanks,
            isHeroNeed: db_info.is_hero_need

        }).then(r => r.data)
    },
    analyzePlayer(selectedRoomNames, timestamp_from, timestamp_to, selectedRanks, db_info, saveName, type) {
        console.log(selectedRoomNames, timestamp_from, timestamp_to, selectedRanks, db_info, saveName)
        let rnames = [...selectedRoomNames];
        if (db_info.is_hero_need) {
            rnames.push({name:"PS:Hero"})
        }
        return instance.post(`/leakfinder_spin/analyze`, {
            saveName, type,
            selectedRoomNames: rnames,
            timestamp_from,
            timestamp_to,
            token: Cookies.get("token"),
            selectedRanks,
            db_name: db_info.name,
            db_user: db_info.user,
            db_pass: db_info.pass,
            is_hero_need: db_info.is_hero_need

        }).then(r => r.data)
    },
    getPlayers() {
        return instance.get(`/leakfinder_spin/getPlayers?token=${Cookies.get("token")}`).then(r => r.data)
    },
    saveAnalyze(body) {
        return instance.post(`/leakfinder_spin/saveAnalyze`, body).then(r => r.data)
    },
    getSaves(body) {
        return instance.post(`/leakfinder_spin/getSaves`, body).then(r => r.data)
    },
    deleteSave(_id) {
        return instance.delete(`/leakfinder_spin/save?_id=${_id}&token=${Cookies.get("token")}`).then(r => r.data)
    },
    getSchemas() {
        return instance.get(`/leakfinder_spin/schemas`).then(r => r.data)
    },

    schemaCreate(body) {
        return instance.post(`/leakfinder_spin/schemas/create`, body).then(r => r.data)
    },

    schemaEdit(body) {
        return instance.post(`/leakfinder_spin/schemas/edit`, body).then(r => r.data)
    },

    schemaDelete(body) {
        return instance.post(`/leakfinder_spin/schemas/delete`, body).then(r => r.data)
    },
}

export const LEAKFINDER_API = {
    saveCommentToAnalyze(body) {
        return instance.post(`/leakfinder/analyze/comment`, body).then(r => r.data);
    },
    removeSavesByName(saveName) {
        return instance.get(`/leakfinder/removeSavesByName?token=${Cookies.get("token")}&saveName=${saveName}`).then(r => r.data)
    },
    saveComment(body) {
        return instance.post(`/leakfinder/saveComment`, body).then(r => r.data)
    },
    analyzeAll(timestamp_from, timestamp_to, selectedRanks, db_info, saveName) {
        return instance.post(`/leakfinder/analyzeAll`, {
            timestamp_from, saveName,
            timestamp_to,
            token: Cookies.get("token"),
            selectedRanks,
            isHeroNeed: db_info.is_hero_need

        }).then(r => r.data)
    },
    getAnalyzeListUsers() {
        return instance.get(`/leakfinder/analyzeListUsers`).then(r => r.data)
    },
    analyzePlayer(selectedRoomNames, timestamp_from, timestamp_to, selectedRanks, db_info, saveName,type) {
        let rnames = [...selectedRoomNames];
        if (db_info.is_hero_need) {
            rnames.push({name:"PS:Hero"})
        }
        return instance.post(`/leakfinder/analyze`, {
            type,
            selectedRoomNames: rnames,
            timestamp_from,
            timestamp_to,
            token: Cookies.get("token"),
            selectedRanks,
            db_name: db_info.name,
            db_user: db_info.user,
            db_pass: db_info.pass,
            is_hero_need: db_info.is_hero_need,
            saveName

        }).then(r => r.data)
    },
    editInfoInteractiveTables(body) {
        return instance.post(`/leakfinder/editInfoInteractiveTables`, body).then(r => r.data)
    },
    getPlayers() {
        return instance.get(`/leakfinder/getPlayers?token=${Cookies.get("token")}`).then(r => r.data)
    },
    getInteractiveTableData_1(body) {
        return instance.post(`/leakfinder/getInteractiveTableData_1`, body).then(r => r.data)
    },
    getInteractiveTableData_2(body) {
        return instance.post(`/leakfinder/getInteractiveTableData_2`, body).then(r => r.data)
    },
    regenerateInteractiveTable2() {
        return instance.get(`/leakfinder/rit2?token=${Cookies.get("token")}`).then(r => r.data)
    },
    getInteractiveTableData_3(body) {
        return instance.post(`/leakfinder/getInteractiveTableData_3`, body).then(r => r.data)
    },
    getInteractiveTableData_5(body) {
        return instance.post(`/leakfinder/getInteractiveTableData_5`, body).then(r => r.data)
    },
    saveAnalyze(body) {
        return instance.post(`/leakfinder/saveAnalyze`, body).then(r => r.data)
    },
    getSaves(body) {
        return instance.post(`/leakfinder/getSaves`, body).then(r => r.data)
    },
    deleteSave(_id) {
        return instance.delete(`/leakfinder/save?_id=${_id}&token=${Cookies.get("token")}`).then(r => r.data)
    },
    getSchemas() {
        return instance.get(`/leakfinder/schemas`).then(r => r.data)
    },

    schemaCreate(body) {
        return instance.post(`/leakfinder/schemas/create`, body).then(r => r.data)
    },

    schemaEdit(body) {
        return instance.post(`/leakfinder/schemas/edit`, body).then(r => r.data)
    },

    schemaDelete(body) {
        return instance.post(`/leakfinder/schemas/delete`, body).then(r => r.data)
    },

    getStartStackTableRows(body) {
        return instance.post(`/leakfinder/startstack/table`, body).then(r => r.data)
    },
    getStartStackTableSaves() {
        return instance.get(`/leakfinder/startstack/saves?token=${Cookies.get("token")}`).then(r => r.data)
    },
    getStartStackTableSave(_id) {
        return instance.get(`/leakfinder/startstack/save?token=${Cookies.get("token")}&_id=${_id}`).then(r => r.data)
    },
    deleteStartStackTableSave(_id) {
        return instance.delete(`/leakfinder/startstack/save?token=${Cookies.get("token")}&_id=${_id}`).then(r => r.data)
    }
}

export const REF_API = {
    getFullInfo() {
        return ss_instance.get(`/referral/get_full_info/${Cookies.get("token")}/${Cookies.get("id")}`).then(r => r.data)
    },
    sendWithdrawRequest(body) { // body: { sum: Number }
        return ss_instance.post(`/referral/get/${Cookies.get("token")}/${Cookies.get("id")}`, body).then(r => r.data)
    }, // data: '{"init_date": "10.08.2022 10:39:16", "description": "Вывод бонуса за приведённых игроков", "sum": 250, "currency": "USD", "type": "getCashOut", "transfer_type": "refer"}'
       // Нужно дату к объекту парсить
    supportFixData() {
        return ss_instance.post(`/referral/fix/${Cookies.get("token")}/${Cookies.get("id")}`).then(r => r.data)
    },
    supportGetCashIns(user_id) {
        return instance.get(`/referral/support/user?token=${Cookies.get("token")}&user_id=${user_id}`).then(r => r.data)
    },
    supportGetUsers() {
        return instance.get(`/referral/support/users?token=${Cookies.get("token")}`).then(r=>r.data)
    },
    supportChangeUserInvites(userId, newValue) {
        return instance.post(`/referral/support/user/invites`, {
            userId, token: Cookies.get("token"), newValue
        }).then(r => r.data);
    }
}

export const SCHEDULER_API = {

    editUsers(username, id, discord_server_id, type) {
        return instance.get(`scheduler/editUsers?token=${Cookies.get("token")}&username=${username}&id=${id}&discord_server_id=${discord_server_id}&type=${type}`).then(r => r.data)
    },
    getUsers() {
        return instance.get(`scheduler/users`).then(r => r.data)
    },

    getList(user_id) {
        return instance.get(`scheduler/list?token=${Cookies.get("token")}&user_id=${user_id}`).then(r => r.data)
    },
    editRow(body) {
        return instance.post(`scheduler/row`, body).then(r => r.data)
    },
    getCalendarInfo() {
        return instance.get(`scheduler/calendar/info`).then(r=>r.data)
    },
    createCalendarDates(body) {
        return instance.post(`scheduler/calendar/dates`, body).then(r=>r.data)
    },
    removeCalendarDate(id) {
        return instance.get(`scheduler/calendar/date/remove?token=${Cookies.get("token")}&id=${id}`).then(r=>r.data)
    }
}

export const USER_MAIN_PAGE = {
    getMainPageInfo() {
        return instance.get(`main?token=${Cookies.get("token")}`).then(r=>r.data)
    },
    getFormularRowsByPeriod(body) {
        return instance.post(`main/formularByPeriod`, body).then(r => r.data)
    }
}
export const TRANSFERS_API = {
    supportGetTransfersList(skipElements, limitElements) {
        return instance.get(`transfers/support/transfers?token=${Cookies.get("token")}&skipElements=${skipElements}&limitElements=${limitElements}`).then(r => r.data)
    },
    supportUpdateTransfer(body) {
        return instance.post(`transfers/support/transfers`, body).then(r => r.data)
    },
    // user:
    getTransfersPageInfo() {
        return instance.get(`transfers/user?token=${Cookies.get("token")}`).then(r=>r.data)
    },
    getTransfersRowsByPeriod(body) {
        return instance.post(`transfers/user/byPeriod`, body).then(r => r.data)
    },
    changeStatus(body) {
        return instance.post(`transfers/user/changeStatus`, body).then(r=>r.data)
    }
}
export const FIN_RESULTS_API = {
    pushIdsData(body) {
        return instance.post(`fin/ids`, body).then(r => r.data)
    },
    pushResultsData(body) {
        return instance.post(`fin/results`, body).then(r => r.data)
    },
    supportGetResultsList(body) {
        return instance.post(`fin/support/results`, body).then(r => r.data)
    },
    supportChangeRow(body) {
        return instance.post(`fin/support/changeRow`, body).then(r=>r.data);
    },
    supportUpdateSet(body) {
        return instance.post(`fin/support/updateSet`, body).then(r=>r.data);
    },
    // user:
    getFinResultsPageInfo() {
        return instance.get(`fin/user?token=${Cookies.get("token")}`).then(r=>r.data)
    },
    getFinResultsRowsByPeriod(body) {
        return instance.post(`fin/user/byPeriod`, body).then(r => r.data)
    },
    changeStatus(body) {
        return instance.post(`fin/user/changeStatus`, body).then(r=>r.data)
    },
    playerSendSetRequest() {
        return instance.get(`fin/user/sendSetRequest?token=${Cookies.get("token")}`).then(r => r.data)
    }
}
export const FORMULAR_ROWS_API = {
    supportGetSpinContracts() {
        return instance.get(`formular/rows/spin/contracts?token=${Cookies.get("token")}`).then(r => r.data);
    },
    supportGetMTTContracts() {
        return instance.get(`formular/rows/mtt/contracts?token=${Cookies.get("token")}`).then(r => r.data);
    },
    supportGetClubs() {
        return instance.get(`formular/rows/clubs`).then(r => r.data)
    },
    supportUpdateClub(body) {
        return instance.post(`formular/rows/clubs`, body).then(r => r.data)
    },
    supportGetPlayerInfo(player_id, club_name, from_timestamp, to_timestamp) {
        return instance.get(`formular/rows/player?token=${Cookies.get("token")}&player_id=${player_id}&club_name=${club_name}&from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}`).then(r => r.data)
    },
    supportUpdatePlayer(body) {
        return instance.post(`formular/rows/player`, body).then(r => r.data);
    }
}

export const REQUISITES_API = {
    getRequisites() {
        return instance.get(`/fschanger/requisites?token=${Cookies.get("token")}`).then(r => r.data)
    },
    removeRequisites(id) {
        return instance.get(`/fschanger/requisites/remove?token=${Cookies.get("token")}&id=${id}`).then(r => r.data)
    },
    createRequisites(body) {
        return instance.post(`/fschanger/requisites`, body).then(r => r.data)
    },
}

export const FS_CHANGER_API = {
    adminChangeServerConverter(body) {
        return instance.post(`/fschanger/admin/bank/converters`, body).then(r => r.data)
    },
    adminGetBanksList() {
        return instance.get(`/fschanger/admin/bank?token=${Cookies.get('token')}`).then(r => r.data);
    },
    adminCreateBank(body) {
        return instance.put(`/fschanger/admin/bank`, body).then(r => r.data);
    },
    adminEditBank(body) {
        return instance.post(`/fschanger/admin/bank`, body).then(r => r.data);
    },
    adminDeleteBank(public_type, public_name) {
        return instance.delete(`/fschanger/admin/bank?token=${Cookies.get('token')}&public_type=${public_type}&public_name=${public_name}`).then(r => r.data)
    },
    supportGetTickets(body) {
        return instance.post(`/fschanger/support/getTickets`, body).then(r => r.data)
    },
    supportChangeStatus(body) {
        return instance.post(`/fschanger/support/changeTicketStatus`, body).then(r => r.data)
    },
    supportEditTicket(body) {
        return instance.post(`/fschanger/support/ticket`, body).then(r=> r.data)
    },
    adminDeleteTicket(timestamp_creation) {
        return instance.delete(`/fschanger/admin/ticket?token=${Cookies.get('token')}&timestamp_creation=${timestamp_creation}`).then(r=> r.data);
    },


    userSendTicket(body) {
        return instance.put(`/fschanger/user/tickets`, body).then(r => r.data)
    },
    userGetTickets() {
        return instance.get(`/fschanger/user/tickets?token=${Cookies.get('token')}`).then(r => r.data)
    },
    userGetBanks() {
        return instance.get(`/fschanger/user/banks`).then(r => r.data)
    },
    userChangeStatus(body) {
        return instance.post(`/fschanger/user/changeTicketStatus`, body).then(r => r.data)
    }
}
export const COA_API = {
    getAll() {
        return instance.get(`coa/getAll?token=${Cookies.get("token")}`).then(r => r.data);
    },
    create(body) {
        return instance.post(`coa/create`, body).then(r => r.data);
    },
    edit(body) {
        return instance.post(`coa/edit`, body).then(r => r.data);
    },
    remove(lvl, public_id) {
        return instance.get(`coa/remove?token=${Cookies.get("token")}&public_id=${public_id}&lvl=${lvl}`).then(r => r.data);
    },

    transfersGetInfo() {
        return instance.get(`coa/transfers/getInfo?token=${Cookies.get("token")}`).then(r => r.data);
    },
    transfersGetByDate(dateFrom, dateTo, type) {
        return instance.get(`coa/transfers/getByDate?token=${Cookies.get("token")}&dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}`).then(r => r.data);
    },
    transfersCreate(body) {
        return instance.post(`coa/transfers/create`, body).then(r => r.data);
    },
    transfersEdit(body) {
        return instance.post(`coa/transfers/edit`, body).then(r => r.data);
    },
    transfersRemove(id) {
        return instance.get(`coa/transfers/remove?token=${Cookies.get("token")}&id=${id}`).then(r => r.data);
    },
}

export const TRAINING_API = {
    coachNotificatorGetInfo() {
        return instance.get(`training/notification/info?token=${Cookies.get("token")}`).then(r => r.data);
    },
    coachNotificatorEditInfo(body) {
        return instance.post(`training/notification/edit`, body).then(r => r.data);
    },
    getSubTypesList() {
        return instance.get(`training/uniqSubTypes`).then(r => r.data);
    },
    getCoachesList() {
        return instance.get(`training/coaches/list?token=${Cookies.get("token")}`).then(r => r.data);
    },
    getPlayersList() {
        return instance.get(`training/players/list?token=${Cookies.get("token")}`).then(r => r.data);
    },
    exportCoachStatsToGoogleTable(body) {
        return instance.post(`training/coaches/stats/export`, body).then(r => r.data);
    },
    exportPlayerStatsToGoogleTable(body) {
        return instance.post(`training/players/stats/export`, body).then(r => r.data);
    },
    getCoachStats(from_timestamp, to_timestamp, coach_id) {
        return instance.get(`training/coaches/stats?from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}&coach_id=${coach_id}&token=${Cookies.get("token")}`).then(r => r.data)
    },
    setStudentsList(training_id) {
        return instance.get(`training/setStudentsList?training_id=${training_id}&token=${Cookies.get("token")}`).then(r => r.data)
    },
    getPlayerStats(from_timestamp, to_timestamp, student_id) {
        return instance.get(`training/players/stats?from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}&student_id=${student_id}&token=${Cookies.get("token")}`).then(r => r.data)
    },
    editVisits(body) {
        return instance.post(`training/visits`, body).then(r=>r.data)
    },
    editNotVisitedReason(body) {
        return instance.post(`training/notVisitedReason`, body).then(r=>r.data)
    },
    getAllFeedbacks(from_timestamp, to_timestamp) {
        return instance.get(`training/allFeedbacks?token=${Cookies.get("token")}&from_timestamp=${from_timestamp}&to_timestamp=${to_timestamp}`).then(r => r.data)
    },
    getPlayers() {
        return instance.get(`training/players?token=${Cookies.get("token")}`).then(r => r.data)
    },
    sendManualNotifications(body) {
        return instance.post(`training/sendManualNotifications`, body).then(r => r.data)
    },
    sendDiscordMessage(players, message, file) {
        const formData = new FormData();
        formData.append('file', file);

        formData.append('message', message)
        formData.append('token', Cookies.get('token'))
        formData.append('players', JSON.stringify(players))

        return instance.post(`training/players/sendDiscordMessage`, formData).then(r => r.data)
    },
    sendPlayersTask(body) {
        return ss_instance.post(`mtt_tasks/add/${Cookies.get("token")}/${Cookies.get("id")}`, body).then(r => r.data);
    },
    // sendDiscordMessage(body) {
    //     return instance.post(`training/players/sendDiscordMessage`, body).then(r => r.data)
    // },
    coachChangePersonalSlotRequest(body) {
        return instance.post(`training/coach/request`, body).then(r => r.data);
    },
    coachGetRequests() {
        return instance.get(`/training/coach/requests?token=${Cookies.get("token")}`).then(r => r.data)
    },
    userSendPersonalRequest(body) {
        return instance.post(`/training/user/personalRequest`, body).then(r=>r.data)
    },
    userRemoveRequest(body) {
        return instance.post(`/training/user/personalRequestRemove`, body).then(r=>r.data)
    },
    userGetPersonalRequestInfo() {
        return instance.get(`/training/user/personalRequestInfo?token=${Cookies.get("token")}`).then(r=>r.data)
    },
    coachPersonalSlotsRemoveTimeFromDay(month, year, day, time) {
        return instance.delete(`/training/coach/personal/slots/hour?token=${Cookies.get("token")}&month=${month}&year=${year}&day=${day}&time=${time}`).then(r=>r.data)
    },
    coachPersonalSlotsAddTimeToDay(body) {
        return instance.put(`/training/coach/personal/slots/hour`, body).then(r => r.data)
    },
    coachPersonalSlotsGetMonth(month, year) {
        return instance.get(`/training/coach/personal/slots?token=${Cookies.get("token")}&month=${month}&year=${year}`).then(r=>r.data)
    },
    coachPersonalSlotsGetMonthDay(month, year, day) {
        return instance.get(`/training/coach/personal/slots/day?token=${Cookies.get("token")}&month=${month}&year=${year}&day=${day}`).then(r=>r.data)
    },
    editTraining(body) {
        return instance.post(`/training/list`, body).then(r=>r.data);
    },
    getTrainingsList(type, list_type, hide_cancel) {
        return instance.get(`/training/list?token=${Cookies.get("token")}&type=${type}&list_type=${list_type}&hide_cancel=${hide_cancel}`).then(r=>r.data)
    },
    creatorCreate(body) {
        return instance.put(`/training/creator`, body).then(r=> r.data)
    },
    creatorGetInfo() {
        return instance.get(`/training/creator?token=${Cookies.get('token')}`).then(r=>r.data)
    },
    connectorCreate(body) {
        return instance.put(`/training/connector`, body).then(r => r.data)
    },
    connectorEdit(body) {
        return instance.post(`/training/connector`, body).then(r=> r.data)
    },
    connectorsGet() {
        return instance.get(`/training/connector?token=${Cookies.get('token')}`).then(r=>r.data)
    },
    connectorDelete(direction, group_name) {
        return instance.delete(`/training/connector?token=${Cookies.get('token')}&direction=${direction}&group_name=${group_name}`).then(r => r.data)
    }
}
export const PROFILE_CUSTOM_INFO_API = {
    supportGetEditLogs(id) {
        return instance.get(`/pci/support/user/logs?token=${Cookies.get("token")}&id=${id}`).then(r => r.data);
    },
    supportUpdateUserCoach(body) {
        return instance.post(`/pci/coaches`, body).then(r => r.data)
    },
    supportGetCoachesList(){
        return instance.get(`/pci/coaches`).then(r => r.data)
    },
    adminTemplatesCreateRow(body) {
        return instance.put(`/pci/admin/templates`, body).then(r => r.data)
    },
    adminTemplatesEditRow(body) {
        return instance.post(`/pci/admin/templates`, body).then(r => r.data)
    },
    adminTemplatesGetRows() {
        return instance.get(`/pci/admin/templates?token=${Cookies.get('token')}`).then(r => r.data)
    },
    supportGetUsersList() {
        return instance.get(`/pci/support/users?token=${Cookies.get('token')}`).then(r => r.data)
    },
    supportGetUser(id, direction) {
        return instance.get(`/pci/support/user?token=${Cookies.get('token')}&id=${id}&direction=${direction}`).then(r => r.data)
    },
    supportEditUser(body) {
        return instance.post(`/pci/support/user`, body).then(r => r.data)
    },
    supportEditUserMTT(body) {
        return instance.post(`/pci/support/user/mtt`, body).then(r => r.data)
    },
    supportEditUserSPIN(body) {
        return instance.post(`/pci/support/user/spin`, body).then(r => r.data)
    }
}

export const AUDITOR_API = {
    adminAddSecondSupportToTask(body) {
        return instance.post(`/auditor/auditors/tasks/newSupport`, body).then(r=>r.data)
    },
    getSupportsList() {
        return instance.get(`/auditor/supports/list`).then(r=>r.data)
    },
    adminGetAllTasksByPeriod(direction, month, year, downloadType, setCount) {
        return instance.get(`/auditor/auditors/tasks/download?setCount=${setCount == null ? "0" : setCount}&id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}&direction=${direction}&month=${month}&year=${year}&downloadType=${downloadType}`).then(r => r.data)
    },
    adminStartTasks(body) {
        return instance.post(`/auditor/auditors/tasks/start`, body).then(r => r.data)
    },
    adminChangeFollowStatus(body) {
        return instance.post(`/auditor/auditors/tasks/followStatus`, body).then(r => r.data)
    },
    adminDeleteTask(body) {
        return instance.post(`/auditor/auditors/tasks/delete`, body).then(r => r.data)
    },
    adminGetPlayersList() {
        return instance.get(`/auditor?id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}`).then(r => r.data)
    },
    adminDeletePlayer(playerName) {
        return instance.delete(`/auditor?playerName=${playerName}&id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}`).then(r => r.data)
    },
    adminEditPlayer(body) {
        return instance.post(`/auditor`, body).then(r => r.data)
    },
    adminCreatePlayer(body) {
        return instance.put(`/auditor`, body).then(r => r.data)
    },
    adminCreateAuditor(body) {
        return instance.put(`/auditor/auditors`, body).then(r => r.data)
    },
    adminGetAuditorsList() {
        return instance.get(`/auditor/auditors?id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}`).then(r => r.data)
    },
    adminDeleteAuditor(auditorId) {
        return instance.delete(`/auditor/auditors?auditorId=${auditorId}&id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}`).then(r => r.data)
    },
    adminEditAuditor(body) {
        return instance.post(`/auditor/auditors`, body).then(r => r.data)
    },
    supportGetTasksList(period, setCount) {
        return instance.get(`/auditor/auditors/tasks/list?period=${period}&setCount=${setCount == null ? "0" : setCount}&id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}`).then(r => r.data)
    },
    supportGetTask(nickname, period, setCount) {
        return instance.get(`/auditor/auditors/tasks/task?setCount=${setCount}&nickname=${nickname}&period=${period}&id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}`).then(r => r.data)
    },
    supportSaveTask(body) {
        return instance.post(`/auditor/auditors/tasks/task`, body).then(r => r.data)
    }
}

export const LEARN_API = {
    get(name) {
        return instance.get(`/learn?name=${name}`).then(r => r.data);
    },
    edit(body) {
        return instance.post(`/learn`, body).then(r => r.data);
    }
}

export const SUPPORTS_API = {
    closeTT(body) {
        return instance.post(`/tasksTickets/closeTicket`, body).then(r => r.data)
    },
    getTTList(params) {
        return instance.get(`/ttList`, params).then(r => r.data)
    },
    getAccessesList() {
        return instance.get(`/supports?id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}`).then(r => r.data);
    },
    edit(body) {
        return instance.post(`/supports`, body).then(r => r.data)
    }
}

export const DISK_API = {
    createDir(name, parent) {
        return instance.post(`/disk/files`, {
            name,
            type: "dir",
            parent,
            username: Cookies.get('username'),
            token: Cookies.get('token')
        })
            .then(response => response.data)
    },
    getFiles(parent = null, supportTask = null) {
        if (supportTask) {
            if (supportTask.includes("AUDIT") || supportTask.includes("SCREENS") || supportTask.includes("HH")) {
                return instance.get(`/disk/files?supportTask=${supportTask}&token=${Cookies.get('token')}&username=${Cookies.get('username')}`)
                    .then(r => r.data)
            } else {
                return instance.get(`/disk/files?${parent ? `parent=${parent}&token=${Cookies.get('token')}&username=${Cookies.get('username')}`
                    : `token=${Cookies.get('token')}&username=${Cookies.get('username')}`}`)
                    .then(r => r.data)
            }
        } else {
            return instance.get(`/disk/files?${parent ? `parent=${parent}&token=${Cookies.get('token')}&username=${Cookies.get('username')}`
                : `token=${Cookies.get('token')}&username=${Cookies.get('username')}`}`)
                .then(r => r.data)
        }

    },
    uploadFile(file, dirId) {
        const formData = new FormData();
        formData.append('file', file);

        formData.append('username', Cookies.get('username'))
        formData.append('token', Cookies.get('token'))

        if (dirId) {
            formData.append('parent', dirId)
        }
        return instance.post(`/disk/files/upload`, formData).then(r => r)
    },
    async downloadFile(file) {
        const r = await fetch(baseURL + `/disk/files/download?id=${file._id}&token=${Cookies.get('token')}&username=${Cookies.get('username')}`)
        if (r && r.data && r.data.resultCode && r.data.resultCode != 1) return r.data;
        const blob = await r.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = file.name;
        document.body.appendChild(link)
        link.click();
        link.remove();
        return null;
    },
    async downloadDir(file) {
        const r = await fetch(baseURL + `/disk/files/downloadDir?id=${file._id}&token=${Cookies.get('token')}&username=${Cookies.get('username')}`)
        if (r && r.data && r.data.resultCode && r.data.resultCode != 1) return r.data;
        const blob = await r.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = file.name;
        document.body.appendChild(link)
        link.click();
        link.remove();
        return null;
    },
    async previewImage(file) {
        const r = await fetch(baseURL + `/disk/files/download?id=${file._id}&token=${Cookies.get('token')}&username=${Cookies.get('username')}`)
        if (r && r.data && r.data.resultCode && r.data.resultCode != 1) return r.data;
        const blob = await r.blob();
        const imageUrl = window.URL.createObjectURL(blob);
        window.open(imageUrl, '_blank').focus();
        return null;
    },
    deleteFile(file) {
        return instance.delete(`/disk/files?id=${file._id}&token=${Cookies.get('token')}&username=${Cookies.get('username')}`).then(r => r.data)
    }
}

export const USER_API = {
    getAuth(token) {
        return instance.get(`auth?token=${token}`).then(response => response.data);
    },
    getProfile(id, email, access_token) {
        return instance.get(`profile?id=${id}&email=${email}&access_token=${access_token}`).then(response => response.data);
    },
    changeOnline(body) {
        return instance.post(`profile/online`, body).then(r => r.data);
    },
    changeNotes(body) {
        return instance.post(`profile/notes`, body).then(r => r.data);
    },
    changeTTFilters(userId, serversFilter, statusesFilter, targetsFilter) {
        return instance.post(`profile/TTFilters`, {
            userId,
            serversFilter,
            statusesFilter,
            targetsFilter
        }).then(r => r.data)
    },
    sendQuestion(question) {
        return instance.post(`/main/userSendQuestion`, {
            question, token: Cookies.get("token")
        }).then(r => r.data)
    }
};

export const ROLE_API = {
    create(body) {
        return instance.put(`role`, body).then(response => response.data);
    }
}

export const CATEGORIES_API = {
    create(body) {
        return instance.post(`admin/categories`, body).then(response => response.data);
    },
    get(id, email, access_token) {
        return instance.get(`admin/categories?id=${id}&email=${email}&access_token=${access_token}`).then(response => response.data);
    },
    editCategory(body) {
        return instance.post(`admin/editCategory`, body).then(r => r.data)
    },
    editSubCategory(body) {
        return instance.post(`admin/editSubCategory`, body).then(r => r.data)
    }
}

export const WIKI_API = {
    getList() {
        return instance.get(`wiki-list?id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}`).then(response => response.data)
    },
    saveWiki(body) {
        return instance.post(`wiki`, body).then(response => response.data)
    },
    getPage(id) {
        return instance.get(`wiki?id=${id}&userId=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}`).then(response => response.data)
    },
    addRoleAccess(body) {
        return instance.post(`wiki/addRoleAccess`, body).then(r => r.data)
    },
    removeRoleAccess(body) {
        return instance.post(`wiki/removeRoleAccess`, body).then(r => r.data)
    },
    remove(body) {
        return instance.post(`wiki/remove`, body).then(r => r.data);
    },
    changeCategory(body) {
        return instance.post(`wiki/changeCategory`, body).then(r => r.data);
    }
}

export const ADMIN_API = {
    getRolesList(id, email, access_token) {
        return instance.get(`admin/roles?id=${id}&email=${email}&access_token=${access_token}`).then(response => response.data)
    },
    getPagesList(id, email, access_token) {
        return instance.get(`admin/pages?id=${id}&email=${email}&access_token=${access_token}`).then(response => response.data)
    },
    createRole(body) {
        return instance.put(`admin/role`, body).then(r => r.data)
    },
    editRole(body) {
        return instance.post(`admin/role`, body).then(r => r.data)
    },
    createPage(body) {
        return instance.put(`admin/page`, body).then(r => r.data)
    },
    editPage(body) {
        return instance.post(`admin/editPage`, body).then(r => r.data)
    },
    getUsersList(id, email, access_token) {
        return instance.get(`admin/users?id=${id}&email=${email}&access_token=${access_token}`).then(response => response.data)
    },
    getUser(userId, id, email, access_token) {
        return instance.get(`admin/user?userId=${userId}&id=${id}&email=${email}&access_token=${access_token}`).then(response => response.data)
    },
    giveRole(body) {
        return instance.put(`admin/user/role`, body).then(r => r.data);
    },
    deleteRole(body) {
        return instance.post(`admin/user/deleteRole`, body).then(r => r.data);
    },
    addRolePageAccess(body) {
        return instance.post(`admin/role/addPageAccess`, body).then(r => r.data);
    },
    deleteRolePageAccess(body) {
        return instance.post(`admin/role/deletePageAccess`, body).then(r => r.data);
    },
    adminDeleteRole(body) {
        return instance.post(`admin/deleteRole`, body).then(r => r.data)
    },
    adminDeletePage(body) {
        return instance.post(`admin/deletePage`, body).then(r => r.data)
    },
    setAccess(body) {
        return instance.post(`admin/access`, body).then(r => r.data)
    },
    editProfile(body) {
        return instance.post(`admin/profile`, body).then(r => r.data)
    }

}
