import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import {FormularSupportEditUserSPIN} from "../../../../redux/reducers/formularReducer";

export default function UserProfileSPIN(props) {
    const [selectedSubPage, setSelectedSubPage] = useState("main");

    let content = null;

    const user = props.isContract ? props.User : props.SupportInfo.currentUser;

    const handleEditProfile = (type, newValue) => {
        if (props.isContract) {
            props.FormularSupportEditUserSPIN(user.user_id, "SPIN", "main", type, null, newValue)
        } else {
            props.SupportEditUserSPIN(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "main", type, null, newValue);
        }
    }

    const inputStyle = {
        padding: "2px 10px", width: "100vh",
        backgroundColor: "#313444", fontSize: "14px", color: "#dedede",
        border: "none"
    }

    const inputRapsStyle = {
        padding: "2px 10px",
        margin: "5px 10px 5px 0",
        backgroundColor: "#313444", fontSize: "14px", color: "#dedede",
        border: "none"
    }

    const nameStyle = {margin: "0 10px 5px 0"}

    if (selectedSubPage == "main") {
        content = <div style={{margin: '2% 7% 0 5%'}}>
            {
                !user.spin_profile ?
                    <div style={{padding: "20px"}}>Игрок еще не инициализирован. Пожалуйста, подождите ~10-30 минут до
                        завершения
                        процесса.</div> :
                    <div style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexFlow: "wrap"
                    }}>
                        <div>
                            <div style={nameStyle}>Дата прихода в команду</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("date_join", e.target.value)}
                                   defaultValue={user?.spin_profile?.date_join} type="date"/>
                        </div>
                        <div>
                            <div style={nameStyle}>ФИО</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("full_real_name", e.target.value)}
                                   defaultValue={user?.spin_profile?.full_real_name} type="text"/>
                        </div>
                        <div>
                            <div style={nameStyle}>Тип контракта</div>
                            <input style={inputStyle}
                                   onBlur={(e) => handleEditProfile("contract_type", e.target.value)}
                                   defaultValue={user?.spin_profile?.contract_type} type="text"/>
                        </div>
                        <div>
                            <div style={nameStyle}>Длительность контракта (кол-во месяцев)</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("contract_months_duration", e.target.value)}
                                   defaultValue={user?.spin_profile?.contract_months_duration} type="number"/>
                        </div>
                        <div>
                            <div style={nameStyle}>Дата начала контракта</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("contract_date_start", e.target.value)}
                                   defaultValue={user?.spin_profile?.contract_date_start} type="date"/>
                        </div>

                    </div>
            }
        </div>;
    }

    return <Popup key={`mtt_user_profile`}
                  trigger={
                      <div className={"btn default-back transition"} style={{padding: "5px"}}>Профайл</div>
                  }
                  modal
                  contentStyle={{
                      width: "100%",
                      height: "100%",
                      margin: "0",
                      padding: 0,
                      backgroundColor: "#1e1e25"
                  }}
                  nested
    >
        {(close) => (
            <div style={{backgroundColor: "#1e1e25"}}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1px 1fr"}}>
                    <div style={{textAlign: "left", margin: "15px 5px 0 5px"}}>
                        <span style={{
                            color: 'white',
                            backgroundColor: "rgb(54,140,211)",
                            padding: "7px 30px 10px 30px",
                            fontSize: "15px",
                            cursor: "pointer"
                        }}
                              onClick={() => setSelectedSubPage("main")}>Main Info</span>
                    </div>
                    <div></div>
                    <div style={{textAlign: "right", margin: "15px 5px 0 0"}}>
                    <span style={{
                        color: 'white',
                        backgroundColor: "rgb(206,77,77)",
                        padding: "7px 30px 10px 30px",

                        fontSize: "15px",
                        cursor: "pointer"
                    }}
                          onClick={close}>Закрыть</span>
                    </div>
                </div>


                {
                    content
                }
            </div>
        )}
    </Popup>
}