import React, {useState} from 'react';
import s from './styles.module.css'
import ThirdContent from "./3-content";
import FourthContent from "./4-content";

const Main = (props) => {
    return (
        <div className={s.box}>
            <div className={s.logo}>
                <h3>Личный кабинет</h3>
            </div>

            <div className={s.content}>
                <h2 className={s.title}>Тренировки</h2>
                <div className={s.bg}/>
                <ThirdContent {...props}/>
                <FourthContent {...props}/>
            </div>
        </div>
    );
};

export default Main;