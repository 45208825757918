import React from 'react';
import s from './styles.module.css';
import {Tooltip} from "@mui/material";
import classNames from "classnames";

const StatusText = ({value}) => {

    return <Tooltip title={value} placement="top-start" arrow>
        <p className={s.color_text} style={{color:value===0 ? '#929293' : '#FAB800'}}>{value == 0 ? "Неактивен" : "Активен"}</p>
    </Tooltip>

};

export default StatusText;