import React from 'react';
import Popup from "reactjs-popup";
import ProfileSPINContainer from "../../User_Profile_SPIN/profileContainer";
const moment = require('moment');

const Contracts = (props) => {
    function openDiskPage(e) {
        window.open(`https://lk.firestorm.team/disk/`, 'name', 'width=800,height=600');
    }

    function formatDate(date) {
        if (!date || date.length <= 3) return false;
        let splitted_date = date.split("-");
        return `${splitted_date[2]}.${splitted_date[1]}.${splitted_date[0]}`
    }

    function calculateDate(duration, date) {
        if (!date || date.length <= 3) return false;
        let splitted_date = date.split("-");
        let formatted_date = moment(`${splitted_date[2]}.${splitted_date[1]}.${splitted_date[0]}`, "DD.MM.YYYY");
        formatted_date.add(duration, 'months');
        return formatted_date.format("DD.MM.YYYY");
    }

    return (
        <div>
            <div style={{display: "grid", gridTemplateColumns: "10fr 1fr"}}>
                <div style={{display: "grid", gridTemplateColumns: "2fr 2fr 3fr 2fr 2fr 2fr", color: "white", textAlign: "center", padding: "20px 20px 5px 20px", fontWeight: "bolder"}}>
                    <div>Ник</div>
                    <div>Дата прихода в команду</div>
                    <div>Тип контракта</div>
                    <div>Срок контракта</div>
                    <div>Дата начала</div>
                    <div>Дата окончания теста</div>
                </div>
                <div style={{display: "grid", gridTemplateColumns: "1fr 3px 1fr"}}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

            <div>
                {
                    props.FormularInfo.spinContractRows.map((e, i) => {

                        return <div style={{display: "grid", gridTemplateColumns: "10fr 1fr", backgroundColor: i % 2 == 0 ? "#1d1d25" : "initial", color: "white", textAlign: "center", margin: "3px 20px"}}>
                            <Popup key={`spin contracts ${i + 1} ${e.user_id}`}
                                   trigger={
                                       <div style={{display: "grid", gridTemplateColumns: "2fr 2fr 3fr 2fr 2fr 2fr"}}>
                                           <div style={{padding: "5px"}}>{e.nick}</div>
                                           <div style={{padding: "5px"}}>{e.spin_profile?.date_join ? formatDate(e.spin_profile.date_join) : "Нет данных"}</div>
                                           <div style={{padding: "5px"}}>{e.spin_profile?.contract_type ? e.spin_profile.contract_type : "Нет данных"}</div>
                                           <div style={{padding: "5px"}}>{e.spin_profile?.contract_months_duration ? `${e.spin_profile.contract_months_duration} мес.` : "Нет данных"}</div>
                                           <div style={{padding: "5px"}}>{e.spin_profile?.contract_date_start ? formatDate(e.spin_profile.contract_date_start) : "Нет данных"}</div>
                                           <div style={{padding: "5px"}}>{e.spin_profile?.contract_months_duration && e.spin_profile?.contract_date_start ? calculateDate(e.spin_profile.contract_months_duration, e.spin_profile.contract_date_start) : "Нет данных"}</div>
                                           {/*<Popup key={`spin contractss ${i + 1} ${e.user_id}`}*/}
                                           {/*       trigger={*/}
                                           {/*           <div>Открыть</div>*/}
                                           {/*       }*/}
                                           {/*       modal*/}
                                           {/*>*/}
                                           {/*    {() => (*/}
                                           {/*        <div>*/}
                                           {/*            <div id="get-advice" style={{*/}
                                           {/*                backgroundColor: "rgba(21,21,33,1)",*/}
                                           {/*                padding: "10px 50px 30px 50px",*/}
                                           {/*                textAlign: "left",*/}
                                           {/*                color: "white",*/}
                                           {/*                width: "1076px"*/}
                                           {/*            }}>*/}
                                           {/*                <form className="form">*/}
                                           {/*                    Здесь профайл*/}
                                           {/*                </form>*/}
                                           {/*            </div>*/}
                                           {/*        </div>*/}
                                           {/*    )}*/}
                                           {/*</Popup>*/}
                                       </div>
                                   }
                                   modal
                            >
                                {() => (
                                    <div>
                                        <div id="get-advice" style={{
                                            backgroundColor: "rgb(49,49,68)",
                                            padding: "30px 50px 30px 50px",
                                            textAlign: "left",
                                            color: "white",
                                            width: "1076px"
                                        }}>
                                            <form className="form">
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr", backgroundColor: "#1d1d25"}}>
                                                    <div style={{fontWeight: "bolder"}}>Ник</div>
                                                    <div></div>
                                                    <div>{e.nick}</div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr"}}>
                                                    <div style={{fontWeight: "bolder"}}>Активный игрок</div>
                                                    <div></div>
                                                    <div>{e.isActive ? "Да" : "Нет"}</div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr", backgroundColor: "#1d1d25"}}>
                                                    <div style={{fontWeight: "bolder"}}>ФИО</div>
                                                    <div></div>
                                                    <div>{e.spin_profile?.full_real_name ? e.spin_profile.full_real_name : "Нет данных"}</div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr"}}>
                                                    <div style={{fontWeight: "bolder"}}>Дата прихода в команду</div>
                                                    <div></div>
                                                    <div>{e.spin_profile?.date_join ? formatDate(e.spin_profile.date_join) : "Нет данных"}</div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr", backgroundColor: "#1d1d25"}}>
                                                    <div style={{fontWeight: "bolder"}}>Конфа дискорд</div>
                                                    <div></div>
                                                    <div><a target="_blank" rel="noopener noreferrer" className={"transition"} style={{color: "#599dbd"}}
                                                            href={`discord://discord.com/channels/${e.guild_id}/${e.channel_id}`}>Открыть</a></div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr"}}>
                                                    <div style={{fontWeight: "bolder"}}>Почта</div>
                                                    <div></div>
                                                    <div>{e.email}</div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr", backgroundColor: "#1d1d25"}}>
                                                    <div style={{fontWeight: "bolder"}}>Тип контракта</div>
                                                    <div></div>
                                                    <div>{e.spin_profile?.contract_type ? e.spin_profile.contract_type : "Нет данных"}</div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr"}}>
                                                    <div style={{fontWeight: "bolder"}}>Срок контракта</div>
                                                    <div></div>
                                                    <div>{e.spin_profile?.contract_months_duration ? `${e.spin_profile.contract_months_duration} мес.` : "Нет данных"}</div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr", backgroundColor: "#1d1d25"}}>
                                                    <div style={{fontWeight: "bolder"}}>Дата начала</div>
                                                    <div></div>
                                                    <div>{e.spin_profile?.contract_date_start ? formatDate(e.spin_profile.contract_date_start) : "Нет данных"}</div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr"}}>
                                                    <div style={{fontWeight: "bolder"}}>Дата окончания теста</div>
                                                    <div></div>
                                                    <div>{e.spin_profile?.contract_months_duration && e.spin_profile?.contract_date_start ? calculateDate(e.spin_profile.contract_months_duration, e.spin_profile.contract_date_start) : "Нет данных"}</div>
                                                </div>
                                                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr", backgroundColor: "#1d1d25"}}>
                                                    <div style={{fontWeight: "bolder"}}>История изменения типа контракта</div>
                                                    <div></div>
                                                    <div>
                                                        {
                                                            e.spin_profile?.contract_type_history ?
                                                                e.spin_profile.contract_type_history.map(h => {
                                                                    return <div>
                                                                        {moment(+new Date(h.date)).format("DD.MM.YYYY")} - {h.changer_name} >>> {h.name}
                                                                    </div>
                                                                })
                                                                : "Без изменений"
                                                        }
                                                    </div>
                                                </div>

                                                {/*<div style={{fontWeight: "bolder", backgroundColor: "#1d1d25"}}>История изменения типа контракта</div>*/}
                                                {/*<div>*/}
                                                {/*    {*/}
                                                {/*        props.spin_profile?.contract_type_history ?*/}
                                                {/*            props.spin_profile.contract_type_history.map(h => {*/}
                                                {/*                return <div>*/}
                                                {/*                    {moment(+new Date(h.date)).format("DD.MM.YYYY")} - {h.changer_name} >>> {h.name}*/}
                                                {/*                </div>*/}
                                                {/*            })*/}
                                                {/*            : "Без изменений"*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </Popup>

                            <div style={{display: "grid", gridTemplateColumns: "1fr 3px 1fr"}}>
                                <div style={{padding: "5px"}} href="/" target="popup"
                                     onClick={openDiskPage}
                                     accessKey={`path_of_url`}
                                     className={"default-back transition"}>Диск</div>
                                <div></div>
                                <ProfileSPINContainer isContract={true} User={e}/>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default Contracts;