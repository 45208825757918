import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import List from "./list";
import {
    CoachStatsSetFilterDifficult,
    CoachStatsSetFilterGroup, CoachStatsSetFilterStatus, CoachStatsSetFilterType,
    CreatorGetInfo, EditNotVisitedReason,
    EditTraining, EditVisits, GetCoachesList, GetCoachStats, GetSubTypesList,
    GetTrainingsList,
    SendManualNotifications
} from "../../../../redux/reducers/trainingReducer";

class ListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Coach: Список тренировок");
        this.props.CreatorGetInfo();
        // this.props.GetTrainingsList("1", "self");
        this.props.GetCoachesList()
        this.props.GetSubTypesList()

    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <List {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    TrainingInfo: state.trainingReducer
})


export default compose(
    connect(mapStateToProps, {
        GetTrainingsList, EditTraining, CreatorGetInfo, SendManualNotifications, GetSubTypesList,
        GetCoachesList, GetCoachStats, EditVisits, EditNotVisitedReason,
        CoachStatsSetFilterGroup, CoachStatsSetFilterType, CoachStatsSetFilterStatus, CoachStatsSetFilterDifficult
    }),
    withRouter
)(ListContainer)