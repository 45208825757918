import React, {useMemo} from 'react';
import s from './styles.module.css'
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import ImgField from "../../../common/fields/imgField";
import ColorText from "../../../common/fields/colorText";
import NumberField from "../../../common/fields/numberField";
import CustomHeader from "../../../common/fields/customHeader";
import table1 from "../../../assests/table1.png";
import Progress from "../../../common/fields/progress";
import Money from "../../../common/fields/money";
import StatusText from "../../../common/fields/status";
import NumberCeilField from "../../../common/fields/numberCeilField";
import NumberToFixed1Field from "../../../common/fields/numberToFixed1Field";
import NumberToFixed2Field from "../../../common/fields/numberToFixed2Field";
import TimestampToDateField from "../../../common/fields/timestampToDateField";
import TimestampToTimeField from "../../../common/fields/timestampToTimeField";
import TimestampToPeriodField from "../../../common/fields/timestampToPeriodField";
import TextField from "../../../common/fields/textField";
import AcceptButtonField from "../../../common/fields/acceptButtonField";
import DeclineButtonField from "../../../common/fields/declineButtonField";

const rows = [
    {
        icon: table1,
        name: 'Jane Cooper',
        date: '08.09.2022',
        progress: 80,
        money: {
            firstValue: '$ 3641,00 ',
            secondValue: '/ $5 000,00',
            order: 3,
            data: [
                {firstValue: '$ 1000', secondValue: '$ 50', order: 1},
                {firstValue: '$ 2000', secondValue: '$ 75', order: 2},
                {firstValue: '$ 5000', secondValue: '$ 125', order: 3},
                {firstValue: '$ 10000', secondValue: '$ 250', order: 4},
                {firstValue: '$ 16000', secondValue: '$ 500', order: 4},
            ]
        },
        status: 'Активен',
        plusMoney: '$ 3641,00',
        yourPlusMoney: '$ 75',
    },
    {
        icon: table1,
        name: 'Guy Hawkins',
        date: '30.08.2022',
        progress: 20,
        money: {
            firstValue: '$1233 ',
            secondValue: '/ $2000',
            order: 2,
            data: [
                {firstValue: '$ 1000', secondValue: '$ 50', order: 1},
                {firstValue: '$ 2000', secondValue: '$ 75', order: 2},
                {firstValue: '$ 5000', secondValue: '$ 125', order: 3},
                {firstValue: '$ 10000', secondValue: '$ 250', order: 4},
                {firstValue: '$ 16000', secondValue: '$ 500', order: 4},
            ]
        },
        status: 'Неактивен',
        plusMoney: '$1233',
        yourPlusMoney: '$50',

    },
    {
        icon: table1,
        name: 'Cameron Williamson',
        date: '07.09.2022',
        progress: 60,
        money: {
            firstValue: '$ 621,00 ',
            secondValue: '/ $1 000,00',
            order: 1,
            data: [
                {firstValue: '$ 1000', secondValue: '$ 50', order: 1},
                {firstValue: '$ 2000', secondValue: '$ 75', order: 2},
                {firstValue: '$ 5000', secondValue: '$ 125', order: 3},
                {firstValue: '$ 10000', secondValue: '$ 250', order: 4},
                {firstValue: '$ 16000', secondValue: '$ 500', order: 4},
            ]
        },
        status: 'Активен',
        plusMoney: '$  621,00',
        yourPlusMoney: '$ 0',
    },
    {
        icon: table1,
        name: 'Dianne Russell',
        date: '02.09.2022',
        progress: 40,
        money: {
            firstValue: '$ 121,00 ',
            secondValue: '/ $1 000,00',
            order: 1,
            data: [
                {firstValue: '$ 1000', secondValue: '$ 50', order: 1},
                {firstValue: '$ 2000', secondValue: '$ 75', order: 2},
                {firstValue: '$ 5000', secondValue: '$ 125', order: 3},
                {firstValue: '$ 10000', secondValue: '$ 250', order: 4},
                {firstValue: '$ 16000', secondValue: '$ 500', order: 4},
            ]
        },
        status: 'Активен',
        plusMoney: '$ 121,00',
        yourPlusMoney: '$ 0',
    },
    {
        icon: table1,
        name: 'Jane Cooper',
        date: '08.09.2022',
        progress: 40,
        money: {
            firstValue: '$ 121,00 ',
            secondValue: '/ $1 000,00',
            order: 1,
            data: [
                {firstValue: '$ 1000', secondValue: '$ 50', order: 1},
                {firstValue: '$ 2000', secondValue: '$ 75', order: 2},
                {firstValue: '$ 5000', secondValue: '$ 125', order: 3},
                {firstValue: '$ 10000', secondValue: '$ 250', order: 4},
                {firstValue: '$ 16000', secondValue: '$ 500', order: 4},
            ]
        },
        status: 'Активен',
        plusMoney: '$ 3641,00',
        yourPlusMoney: '$ 75',
    },
    {
        icon: table1,
        name: 'Jane Cooper',
        date: '08.09.2022',
        progress: 40,
        money: {
            firstValue: '$ 121,00 ',
            secondValue: '/ $1 000,00',
            order: 1,
            data: [
                {firstValue: '$ 1000', secondValue: '$ 50', order: 1},
                {firstValue: '$ 2000', secondValue: '$ 75', order: 2},
                {firstValue: '$ 5000', secondValue: '$ 125', order: 3},
                {firstValue: '$ 10000', secondValue: '$ 250', order: 4},
                {firstValue: '$ 16000', secondValue: '$ 500', order: 4},
            ]
        },
        status: 'Активен',
        plusMoney: '$ 3641,00',
        yourPlusMoney: '$ 75',
    },

];
const MainTable = (props) => {
    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    return (
        <div className={s.main_table}>
            <h3>Мои рефералы:</h3>

            <div className={s.table}>
                <div className='ag-theme-alpine-dark'
                     style={{height: 424, width: '100%', position: 'relative', marginTop: '33px'}}>
                    <AgGridReact
                        style={{height: 424, width: '100%'}}
                        rowData={props.ReferralInfo.full_info.invited_players}
                        enableBrowserTooltips={true}
                        tooltipShowDelay={0}
                        tooltipHideDelay={20}
                        rowHeight={70}
                        components={components}
                        suppressMenu={true}
                        defaultColDef={{
                            editable: true,
                            sortable: true,
                            flex: 1,
                            minWidth: 100,
                            filter: false,
                            floatingFilter: false,
                            resizable: true,
                            menuTabs: false,
                            suppressMenu: true,
                            headerComponentParams: {
                                menuIcon: 'fa-bars',
                            },
                        }}
                        frameworkComponents={{
                            imgField: ImgField,
                            colorText: ColorText,
                            numberField: NumberField,
                            progress: Progress,
                            money: Money,
                            statusText:StatusText,

                            numberCeilField: NumberCeilField,
                            numberToFixed1Field: NumberToFixed1Field,
                            numberToFixed2Field: NumberToFixed2Field,
                            timestampToDateField: TimestampToDateField,
                            timestampToTimeField: TimestampToTimeField,
                            timestampToPeriodField: TimestampToPeriodField,
                            textField: TextField,
                            acceptButtonField: AcceptButtonField,
                            declineButtonField: DeclineButtonField
                        }}


                    >
                        <AgGridColumn headerName={''} field='icon' editable={true} sortable={false} filter={false}
                                      cellRenderer='imgField' flex={0.2}/>
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={`Никнейм`}
                            field='nick'
                            editable={false}
                            sortable={false}
                            filter={false}
                            cellRendererFramework='colorText'

                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Дата привлечения'}
                            field='date'
                            editable={true}
                            sortable={true}
                            filter={false}
                            cellRenderer='timestampToDateField'
                            suppressMenu={true}
                            // tooltip by ag-grid
                            tooltipField='PR'

                            // tooltip header by ag-grid
                            headerTooltip={'BTL'}
                            cellRendererParams={{
                                color: '#fff'
                            }}
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Прогресс'}
                            field='all_profit'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='progress'
                            flex={1.5}
                            // tooltip by ag-grid
                            tooltipField='btl'

                            // tooltip header by ag-grid
                            headerTooltip={'BTL'}
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={''}
                            field='all_profit'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='money'
                            flex={1.6}
                        />

                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Статус'}
                            field='is_active'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='statusText'
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Прибыль реферала'}
                            field='all_profit'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='textField'
                            cellRendererParams={{
                                color: '#fff',
                                isCurrency: true
                            }}
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Ваша прибыль'}
                            field='sum'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='colorText'
                            cellRendererParams={{
                                color: '#1089F9',
                                isCurrency: true
                            }}
                        />
                    </AgGridReact>
                </div>
            </div>
        </div>
    );
};

export default MainTable;
