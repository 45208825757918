import React, {useState, useRef} from "react";
import {NavLink} from "react-router-dom";
import Popup from "reactjs-popup";
import {DeleteRolePageAccess, EditRole} from "../../../../redux/reducers/rolesReducer";
import MainLoading from "../../common/mainLoading";

export default function RolesList(props) {
    let createRoleName = React.createRef();

    let createRole = () => {
        props.CreateRole(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username,
            props.AuthInfo.accessToken, createRoleName.current.value, props.PagesInfo.pagesList);
    }

    return (
        props.isLoading ? <MainLoading/>
            :
        <div className="roles-list">
            <Popup key={props.RolesInfo.rolesAccessesList.length + " " + "creationRolePopup"}
                trigger={<a className={"creator"}>Создать новую роль</a>}
                modal
                nested
            >
                {() => (
                    <div>
                        <div id="get-advice" className="main-popup">
                            <form className="form">
                                <div className="formgroup">
                                    <input ref={createRoleName} type="text" placeholder="Название роли"/>
                                </div>
                            </form>
                            <button onClick={createRole} className={"send-btn"}>Создать</button>
                        </div>
                    </div>
                )}
            </Popup>

            {props.RolesInfo.rolesAccessesList.map((role, i) => <RoleBlock EditRole={props.EditRole} role={role} AuthInfo={props.AuthInfo}
                                                                           AddRolePageAccess={props.AddRolePageAccess}
                                                                           DeleteRolePageAccess={props.DeleteRolePageAccess}
                                                                           key={i + " " + role.info.name + " " + i}
                                                                           DeleteRole={props.DeleteRole} RolesInfo={props.RolesInfo}/>)}

        </div>
    );
}

const RoleBlock = (props) => {
    let DeleteRole = (e) => {
        props.DeleteRole(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.role.info.name)
    }

    return (
        <Popup key={props.RolesInfo.rolesAccessesList.length + " " + "deleteRolePopup"}
            trigger={<div className={"role-block"}>
                <div className={"role-name"}>
                    {props.role.info.name}
                </div>
                <div className={"role-pages"}>
                    <span
                        className={"light-blue"}>Доступы к страницам:</span> {props.role.info.pageAccesses.map(access =>
                    <AccessedPage access={access}/>)}

                </div>
                <div className={"role-info"}>
                    <span className={"light-blue"}>Создатель роли:</span> {props.role.info.initiator}
                </div>
            </div>}
            modal
            nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="user-page-popup">
                        <form className="form">
                            <div className={"formgroup"}>
                                <div style={{color: "white", fontSize: "14px", textAlign: "center", padding: "10px 0"}}>
                                    ID роли в дискорде
                                </div>
                                <div>
                                    <input onBlur={(e) => props.EditRole(props.role.info.name, "discord_id", e.target.value)} defaultValue={props.role.info.discord_id}/>
                                </div>
                            </div>
                            {props.role.pages.map((page, i) => <PageAccessBlock page={page} AuthInfo={props.AuthInfo}
                                                                                AddRolePageAccess={props.AddRolePageAccess}
                                                                                DeleteRolePageAccess={props.DeleteRolePageAccess}
                                                                                roleName={props.role.info.name}
                                                                                key={i + " " + props.role.info.name}/>)}
                            <div className="formgroup">
                                <div onClick={DeleteRole} className={"main-delete-role"}>УДАЛИТЬ РОЛЬ</div>
                            </div>

                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}

const AccessedPage = (props) => {
    return (
        <span className={"page-access-name"}>{props.access.name}; </span>
    )
}

const PageAccessBlock = (props) => {
    let AddRolePageAccess = (e) => {
        props.AddRolePageAccess(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username,
            props.AuthInfo.accessToken, props.roleName, props.page.page.name)
    }
    let DeleteRolePageAccess = (e) => {
        props.DeleteRolePageAccess(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username,
            props.AuthInfo.accessToken, props.roleName, props.page.page.name)
    }

    return (
        props.page.isAccessed ? <div
                onClick={DeleteRolePageAccess}
                className={"role-page-access-block role-page-access-block-accessed"}>{props.page.page.name}</div>
            : <div
                onClick={AddRolePageAccess}
                className={"role-page-access-block role-page-access-block-denied"}>{props.page.page.name}</div>
    )
}