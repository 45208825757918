import React, {useState} from 'react';
import Select from "react-dropdown-select";
import {useDispatch} from "react-redux";
import {AddNotification} from "../../../../redux/reducers/notificationsReducer";
import Table3 from "./details/table_2/index"
import Table4 from "./details/table_3/index"
import CustomDropdownRenderer from "../../Util/Dropdown/CustomDropdownRenderer";

const GroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите группу"}
            labelField={"coach_group"}
            valueField={"coach_group"}
            sortBy={"coach_group"}
            contentRenderer={() => props.selectedGroup && props.selectedGroup[0] ? <div>{props.selectedGroup[0].coach_group}</div> : <div>Группа не выбрана</div>}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedGroup && props.selectedGroup[0] ? props.selectedGroup : []}
            onChange={(value) => {
                props.handleSelectGroup(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const RankSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите ранг"}
            labelField={"rank"}
            valueField={"rank"}
            sortBy={"rank"}
            contentRenderer={() => props.selectedRank && props.selectedRank[0] ? <div>{props.selectedRank[0].rank}</div> : <div>Ранг не выбран</div>}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedRank && props.selectedRank[0] ? props.selectedRank : []}
            onChange={(value) => {
                props.handleSelectRank(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const PlayersSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите игрока"}
            labelField={"nick"}
            valueField={"nick"}
            sortBy={"nick"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            dropdownRenderer={CustomDropdownRenderer}
            contentRenderer={() => <div>Выбрано игроков: {props.selectedPlayers.length} из {props.options.length}</div>}
            options={props.options}
            values={props.selectedPlayers}
            onChange={(value) => {
                props.handleSelectPlayers(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}

const PeriodSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите период #1"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedPeriod}
            onChange={(value) => {
                props.handleSelectPeriod(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const PeriodSelector2 = (props) => {
    return (
        <Select
            placeholder={"Выберите период #2"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedPeriod2}
            onChange={(value) => {
                props.handleSelectPeriod2(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}

const SchemaSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите ранг"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectSchema(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const LeakfinderDetailsTable_3_4 = (props) => {
    const dispatch = useDispatch();

    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedRank, setSelectedRank] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState([]);
    const [selectedPeriod2, setSelectedPeriod2] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState([]);
    const [selectedRoomNames, setSelectedRoomNames] = useState([]);

    const handleSelectGroup = (e) => {
        let players = [];
        let rnames = [];

        if (!e || !e[0]) {
            setSelectedRoomNames(JSON.parse(JSON.stringify(rnames)))
            setSelectedPlayers(players);

            setSelectedGroup([]);
        } else {
            props.LeakfinderInfo.players.forEach(player => {
                e.forEach(se => {
                    if (se.coach_group == player.coach_group) {
                        players.push(player);
                        player.room_names.forEach(rn => {
                            rnames.push(rn)
                        })
                    }
                })
            })
            setSelectedGroup(e)

            setSelectedRoomNames(JSON.parse(JSON.stringify(rnames)))
            setSelectedPlayers(players);

            setSelectedRank([]);
        }

    }


    const handleSelectRank = (e) => {
        let players = [];
        let rnames = [];

        if (!e || !e[0]) {
            setSelectedRoomNames(JSON.parse(JSON.stringify(rnames)))
            setSelectedPlayers(players);
            setSelectedRank([])

        } else {
            props.LeakfinderInfo.players.forEach(player => {
                e.forEach(se => {
                    if (se.rank == player.rank) {
                        players.push(player);
                        player.room_names.forEach(rn => {
                            rnames.push(rn)
                        })
                    }
                })
            })
            setSelectedRank(e)
            setSelectedRoomNames(JSON.parse(JSON.stringify(rnames)))
            setSelectedPlayers(players);
            setSelectedGroup([]);
        }

    }
    const handleSelectPlayers = (e) => {
        if (e && e[0]) {
            let rnames = [];

            e.forEach(j => {
                j.room_names.forEach(k => {
                    rnames.push(k)
                })
            })

            setSelectedRoomNames(JSON.parse(JSON.stringify(rnames)))
            setSelectedPlayers(e)

        } else {
            setSelectedRoomNames(JSON.parse(JSON.stringify([])));
            setSelectedPlayers([])

        }

    }
    const handleSelectPeriod = (e) => {
        if (e && e[0]) {
            setSelectedPeriod(JSON.parse(JSON.stringify(e)));
        } else {
            setSelectedPeriod(JSON.parse(JSON.stringify([])));
        }
    }

    const handleSelectPeriod2 = (e) => {
        if (e && e[0]) {
            setSelectedPeriod2(JSON.parse(JSON.stringify(e)));
        } else {
            setSelectedPeriod2(JSON.parse(JSON.stringify([])));
        }
    }

    const handleSelectSchema = (e) => {
        if (e && e[0]) {
            setSelectedSchema(JSON.parse(JSON.stringify(e)));
        } else {
            setSelectedSchema(JSON.parse(JSON.stringify([])));
        }
    }

    const handleGetData = () => {
        if (!selectedSchema[0]) return dispatch(AddNotification("error", "Получение данных по таблице", "Не выбран ранг!"))
        if (!selectedPeriod[0]) return dispatch(AddNotification("error", "Получение данных по таблице", "Не выбран период!"))
        if (!selectedPeriod2[0]) return dispatch(AddNotification("error", "Получение данных по таблице", "Не выбран период #2!"))
        if (!selectedRoomNames[0]) return dispatch(AddNotification("error", "Получение данных по таблице", "Нет румнеймов у выбранных ников!"))

        props.LeakfinderGetInteractiveTableData_3(selectedPeriod[0].name, selectedPeriod2[0].name, selectedRoomNames)
    }

    return (
        <div>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 20px 1fr", color: "white"}}>
                <GroupSelector options={props.LeakfinderInfo.groups}
                               selectedGroup={selectedGroup}
                               handleSelectGroup={handleSelectGroup}/>
                <RankSelector options={props.LeakfinderInfo.ranks}
                              selectedRank={selectedRank}
                              handleSelectRank={handleSelectRank}/>
                <PlayersSelector options={props.LeakfinderInfo.players}
                                 selectedPlayers={selectedPlayers}
                                 handleSelectPlayers={handleSelectPlayers}/>
                <PeriodSelector options={props.LeakfinderInfo.periodsList}
                                selectedPeriod={selectedPeriod}
                                handleSelectPeriod={handleSelectPeriod}/>
                <PeriodSelector2 options={props.LeakfinderInfo.periodsList2}
                                 selectedPeriod2={selectedPeriod2}
                                 handleSelectPeriod2={handleSelectPeriod2}/>
                <SchemaSelector options={props.LeakfinderInfo.schemas}
                                selectedSchema={selectedSchema}
                                handleSelectSchema={handleSelectSchema}/>
                <div></div>
                <div className={"button default-button default-back cursor-pointer"} style={{height: "40px"}} onClick={handleGetData}>
                    Загрузить
                </div>
            </div>
            {
                props.LeakfinderInfo.interactiveTableData_3 && props.LeakfinderInfo.interactiveTableData_3[0] && selectedSchema[0] &&
                <div style={{color: "white"}}>
                    {
                        props.tableNumber == 3 ? <Table3 schema={selectedSchema[0]} periods={props.LeakfinderInfo.interactiveTableData_3}/>
                            : <Table4 schema={selectedSchema[0]} periods={props.LeakfinderInfo.interactiveTableData_3}/>
                    }
                </div>
            }

        </div>
    );
};

export default LeakfinderDetailsTable_3_4;