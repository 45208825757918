import {FS_CHANGER_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";
import Cookies from "js-cookie";

const CHANGER_USER_SET_TICKETS_LIST = 'CHANGER_USER_SET_TICKETS_LIST';
const CHANGER_USER_SET_BANKS_LIST = 'CHANGER_USER_SET_BANKS_LIST';
const CHANGER_USER_SET_CURRENT_BANK = 'CHANGER_USER_SET_CURRENT_BANK';
const CHANGER_USER_SET_CURRENT_TYPE = 'CHANGER_USER_SET_CURRENT_TYPE';
const CHANGER_USER_SET_FORM_DATA = 'CHANGER_USER_SET_FORM_DATA';
const CHANGER_USER_CHANGE_TICKET_STATUS = 'CHANGER_USER_CHANGE_TICKET_STATUS';
const CHANGER_USER_SET_REQUEST_STEP = 'CHANGER_USER_SET_REQUEST_STEP';

const CHANGER_ADMIN_SET_BANKS_LIST = 'CHANGER_ADMIN_SET_BANKS_LIST';
const CHANGER_SUPPORT_SET_TICKETS_LIST = 'CHANGER_SUPPORT_SET_TICKETS_LIST';
const CHANGER_SUPPORT_UPDATE_TICKETS_STATUS = 'CHANGER_SUPPORT_UPDATE_TICKETS_STATUS';
const CHANGER_SUPPORT_CHANGE_TYPES_FILTER = 'CHANGER_SUPPORT_CHANGE_TYPES_FILTER';
const CHANGER_SUPPORT_CHANGE_STATUSES_FILTER = 'CHANGER_SUPPORT_CHANGE_STATUSES_FILTER';
const CHANGER_SUPPORT_CHANGE_TICKET_STATUS = 'CHANGER_SUPPORT_CHANGE_TICKET_STATUS';
const CHANGER_ADMIN_PUSH_NEW_BANK = 'CHANGER_ADMIN_PUSH_NEW_BANK';
const CHANGER_ADMIN_DELETE_BANK = 'CHANGER_ADMIN_DELETE_BANK';
const CHANGER_ADMIN_DELETE_TICKET = 'CHANGER_ADMIN_DELETE_TICKET';

let initialState = {
    userTicketsList: [],

    userBanksList: [],
    userEditedBanksList: [],
    userEurInUSD: null,
    userTypesList: [],
    userCurrentType: null,
    userCurrentBank: null,
    userCurrentBankName: null,
    userSum: 0,
    userCurrency: "USD",
    userCard: null,
    userNickname: null,
    userCurrentHelp: null,
    userCheckbox: false,
    userFeedbacks: [],
    FfsMin: null,
    FfsMax: null,
    Fbank: null,
    FaverageExchange: null,
    Fpercent: null,

    adminBanksList: [],
    supportTicketsList: [],
    supportTicketsCount: null,
    supportTicketsCurrentCount: null,
    types: [],
    statuses: [],

    request_step: 1
};

const fsChangerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGER_USER_SET_REQUEST_STEP: {
            if (action.step == 1) {
                return {
                    ...state,
                    request_step: action.step,
                    userNickname: "",
                    userCard: "",
                    userCheckbox: false,
                }
            }
            return {
                ...state,
                request_step: action.step
            }
        }
        case CHANGER_USER_CHANGE_TICKET_STATUS: {
            const ticketIndex = state.userTicketsList.findIndex(e => e.timestamp_creation == action.timestamp_creation);
            if (ticketIndex != -1) state.userTicketsList[ticketIndex] = JSON.parse(JSON.stringify(action.ticket))

            return {
                ...state,
                userTicketsList: JSON.parse(JSON.stringify(state.userTicketsList))
            }
        }
        case CHANGER_USER_SET_TICKETS_LIST: {
            return {
                ...state,
                userTicketsList: [...action.data]
            }
        }
        case CHANGER_USER_SET_FORM_DATA: {
            if (action.ttype == "sum") state.userSum = action.value;
            if (action.ttype == "currency") state.userCurrency = action.value;
            if (action.ttype == "card") state.userCard = action.value;
            if (action.ttype == "nickname") state.userNickname = action.value;
            if (action.ttype == "checkbox") state.userCheckbox = action.value;

            if (state.userCurrentBank && state.userSum && state.userCurrency) {
                let fsMin = (+state.userSum * +state.userCurrentBank.public_sell) * (1 - +state.userCurrentBank.fs_percent);
                let fsMax = (+state.userSum * +state.userCurrentBank.public_sell) * (1 - +state.userCurrentBank.fs_percent);
                let bank = (+state.userSum * +state.userCurrentBank.public_sell) * (1 - +state.userCurrentBank.public_percent);
                let averageExchange = state.userCurrentBank.public_type.toLowerCase() == "crypto" ? state.userCurrentBank.public_sell.toFixed(6) : state.userCurrentBank.public_sell.toFixed(2);

                let formatter = new Intl.NumberFormat("en-US", {
                    style: 'percent',
                    maximumFractionDigits: 2
                });
                let percent = formatter.format(state.userCurrentBank.public_percent);

                if (state.userCurrency === "EUR") {
                    fsMin = (+state.userSum * +state.userEurInUSD * +state.userCurrentBank.public_sell) * (1 - +state.userCurrentBank.fs_percent);
                    fsMax = (+state.userSum * +state.userEurInUSD * +state.userCurrentBank.public_sell) * (1 - +state.userCurrentBank.fs_percent);
                    bank = (+state.userSum * +state.userEurInUSD * +state.userCurrentBank.public_sell) * (1 - +state.userCurrentBank.public_percent);
                    averageExchange = state.userCurrentBank.public_type.toLowerCase() == "crypto" ? state.userCurrentBank.public_sell.toFixed(6) : (1 * +state.userEurInUSD * state.userCurrentBank.public_sell).toFixed(2)

                }

                let userCurrentHelp = `Вывод ${state.userSum} ${state.userCurrency} на ${state.userCurrentBank.public_name}${state.userCurrentBank.public_type.toLowerCase() == "crypto" ? ":" : ` (${state.userCurrentBank.public_type}):`}\n` +
                    `Средний курс у менял xe.com: ~${averageExchange}\n` +
                    `Средняя комиссия у менял: ${percent}\n` +
                    `При обмене у менял вы бы получили: ${state.userCurrentBank.public_type.toLowerCase() == "crypto" && bank ? bank.toFixed(6) : bank.toFixed(2)}\n\n` +
                    `При обмене у нас вы получите ${state.userCurrentBank.public_type.toLowerCase() == "crypto" && fsMin ? fsMin.toFixed(6) : fsMin.toFixed(2)}`

                state.userCurrentHelp = userCurrentHelp;
                state.FfsMin = fsMin.toFixed(2);
                state.FfsMax = fsMax.toFixed(2);
                state.Fbank = bank.toFixed(2);
                state.FaverageExchange = averageExchange;
                state.Fpercent = percent;
            }

            return {
                ...state
            }
        }
        case CHANGER_USER_SET_CURRENT_TYPE: {
            let userEditedBanksList;
            if (action.ttype == 'all') {
                userEditedBanksList = [...state.userBanksList]
            } else {
                userEditedBanksList = state.userBanksList.filter(e => e.public_type == action.ttype)
            }
            return {
                ...state,
                userEditedBanksList: [...userEditedBanksList],
                userCurrentType: action.ttype
            }
        }
        case CHANGER_USER_SET_CURRENT_BANK: {
            if (state.userCurrentBank && state.userSum && state.userCurrency) {
                let fsMin = (+state.userSum * +action.userCurrentBank.public_sell) * (1 - +action.userCurrentBank.fs_percent);
                let fsMax = (+state.userSum * +action.userCurrentBank.public_sell) * (1 - +action.userCurrentBank.fs_percent);
                let bank = (+state.userSum * +action.userCurrentBank.public_sell) * (1 - +action.userCurrentBank.public_percent);
                let averageExchange = action.userCurrentBank.public_type.toLowerCase() == "crypto" ? action.userCurrentBank.public_sell.toFixed(6) : action.userCurrentBank.public_sell.toFixed(2)

                let formatter = new Intl.NumberFormat("en-US", {
                    style: 'percent',
                    maximumFractionDigits: 2
                });
                let percent = formatter.format(action.userCurrentBank.public_percent);

                if (state.userCurrency === "EUR") {
                    fsMin = (+state.userSum * +state.userEurInUSD * +action.userCurrentBank.public_sell) * (1 - +action.userCurrentBank.fs_percent);
                    fsMax = (+state.userSum * +state.userEurInUSD * +action.userCurrentBank.public_sell) * (1 - +action.userCurrentBank.fs_percent);
                    bank = (+state.userSum * +state.userEurInUSD * +action.userCurrentBank.public_sell) * (1 - +action.userCurrentBank.public_percent);
                    averageExchange = action.userCurrentBank.public_type.toLowerCase() == "crypto" ? action.userCurrentBank.public_sell.toFixed(6) : (1 * +state.userEurInUSD * action.userCurrentBank.public_sell).toFixed(2)
                }

                let userCurrentHelp = `Вывод ${state.userSum} ${state.userCurrency} на ${action.userCurrentBank.public_name}${action.userCurrentBank.public_type.toLowerCase() == "crypto" ? ":" : ` (${action.userCurrentBank.public_type}):`}\n` +
                    `Средний курс у менял xe.com: ~${averageExchange}\n` +
                    `Средняя комиссия у менял: ${percent}\n` +
                    `При обмене у менял вы бы получили: ${action.userCurrentBank.public_type.toLowerCase() == "crypto" && bank ? bank.toFixed(6) : bank.toFixed(2)}\n\n` +
                    `При обмене у нас вы получите ${action.userCurrentBank.public_type.toLowerCase() == "crypto" && fsMin ? fsMin.toFixed(6) : fsMin.toFixed(2)}`

                state.userCurrentHelp = userCurrentHelp;
                state.FfsMin = fsMin.toFixed(2);
                state.FfsMax = fsMax.toFixed(2);
                state.Fbank = bank.toFixed(2);
                state.FaverageExchange = averageExchange;
                state.Fpercent = percent;
            }

            return {
                ...state,
                userCurrentBank: action.userCurrentBank,
                userCurrentBankName: `${action.userCurrentBank.public_name} (${action.userCurrentBank.public_type})`,
            }
        }
        case CHANGER_USER_SET_BANKS_LIST: {
            let userTypesList = [];
            let userEurInUSD;
            if (action.userBanksList[0]) {
                action.userBanksList.forEach(e => {
                    if (e.public_name == "fs_eur_to_dollar") userEurInUSD = e.fs_sell_min
                })
                userTypesList = action.userBanksList.map(bank => bank.public_type);
                userTypesList = uniq(userTypesList);
                userTypesList = userTypesList.filter(e => e != "fs_server");
            }
            return {
                ...state,
                userBanksList: [...action.userBanksList.filter(e => e.public_type != "fs_server")],
                userCurrentType: "all",
                userEditedBanksList: [...action.userBanksList.filter(e => e.public_type != "fs_server")],
                userTypesList: [...userTypesList],
                userEurInUSD: userEurInUSD,
                userFeedbacks: [...action.feedbacks]
            }
        }
        case CHANGER_ADMIN_DELETE_BANK: {
            const bankIndex = state.adminBanksList.findIndex(e => e.public_type == action.public_type && e.public_name == action.public_name);
            if (bankIndex != -1) state.adminBanksList.splice(bankIndex, 1);

            return {
                ...state,
                adminBanksList: JSON.parse(JSON.stringify(state.adminBanksList))
            }
        }
        case CHANGER_ADMIN_PUSH_NEW_BANK: {
            return {
                ...state,
                adminBanksList: [action.bank, ...state.adminBanksList]
            }
        }
        case CHANGER_ADMIN_SET_BANKS_LIST: {
            return {
                ...state,
                adminBanksList: [...action.adminBanksList]
            }
        }
        case CHANGER_ADMIN_DELETE_TICKET: {
            const ticketIndex = state.supportTicketsList.findIndex(e => e.timestamp_creation == action.timestamp_creation);
            if (ticketIndex != -1) state.supportTicketsList.splice(ticketIndex, 1);

            return {
                ...state,
                supportTicketsList: JSON.parse(JSON.stringify(state.supportTicketsList))
            }
        }
        case CHANGER_SUPPORT_CHANGE_TICKET_STATUS: {
            const ticketIndex = state.supportTicketsList.findIndex(e => e.timestamp_creation == action.timestamp_creation);
            if (ticketIndex != -1) state.supportTicketsList[ticketIndex] = JSON.parse(JSON.stringify(action.ticket))

            return {
                ...state,
                supportTicketsList: JSON.parse(JSON.stringify(state.supportTicketsList))
            }
        }
        case CHANGER_SUPPORT_UPDATE_TICKETS_STATUS: {
            let newList = [...state.supportTicketsList];
            if (action.body.status == "new") {
                return {
                    ...state,
                    supportTicketsList: [action.body, ...state.supportTicketsList],
                    supportTicketsCount: +newList.length + 1,
                    supportTicketsCurrentCount: +state.supportTicketsCurrentCount + 1
                }
            } else {
                let elementToChangeIndex = newList.findIndex(e => e.timestamp_creation == action.body.timestamp_creation);
                if (elementToChangeIndex == -1) {
                    return state;
                } else {
                    newList[elementToChangeIndex] = {...action.body};
                    if (!state.statuses.includes(action.body.status)) {
                        newList.splice(elementToChangeIndex, 1)
                    }
                    return {
                        ...state,
                        supportTicketsList: [...newList]
                    }
                }
            }
        }
        case CHANGER_SUPPORT_CHANGE_STATUSES_FILTER: {
            if (state.statuses.includes(action.status)) {
                let statusIndex = state.statuses.findIndex(e => e == action.status)
                let newStatuses = [...state.statuses]
                newStatuses.splice(statusIndex, 1);
                return {
                    ...state,
                    statuses: [...newStatuses]
                }
            } else {
                return {
                    ...state,
                    statuses: [...state.statuses, action.status]
                }
            }
        }
        case CHANGER_SUPPORT_CHANGE_TYPES_FILTER: {
            if (state.types.includes(action.ttype)) {
                let typeIndex = state.types.findIndex(e => e == action.ttype)
                let newTypes = [...state.types]
                newTypes.splice(typeIndex, 1);
                return {
                    ...state,
                    types: [...newTypes]
                }
            } else {
                return {
                    ...state,
                    types: [...state.types, action.ttype]
                }
            }
        }
        case CHANGER_SUPPORT_SET_TICKETS_LIST: {
            if (action.changeType == "set") {
                return {
                    ...state,
                    supportTicketsList: [...action.list],
                    supportTicketsCount: action.count,
                    supportTicketsCurrentCount: 50
                }
            } else if (action.changeType == "new") {
                return {
                    ...state,
                    supportTicketsList: [...action.list],
                    supportTicketsCount: action.count,
                    supportTicketsCurrentCount: action.list.length
                }
            } else {
                return {
                    ...state,
                    supportTicketsList: [...state.supportTicketsList, ...action.list],
                    supportTicketsCount: action.count,
                    supportTicketsCurrentCount: state.supportTicketsCurrentCount + 50
                }
            }
        }
        default:
            return state;
    }
};

const UserSetTicketsList = (data) => ({type: CHANGER_USER_SET_TICKETS_LIST, data})
const UserSetBanksList = (userBanksList, feedbacks) => ({type: CHANGER_USER_SET_BANKS_LIST, userBanksList, feedbacks})
export const UserSetCurrentBank = (userCurrentBank) => ({type: CHANGER_USER_SET_CURRENT_BANK, userCurrentBank}) // передаем прям банк целиком
export const UserSetCurrentType = (ttype) => ({type: CHANGER_USER_SET_CURRENT_TYPE, ttype});
export const UserSetFormData = (ttype, value) => ({type: CHANGER_USER_SET_FORM_DATA, ttype, value})
export const ExchangerSetRequestStep = (step) => ({type: CHANGER_USER_SET_REQUEST_STEP, step})

const SupportSetTicketsList = (list, changeType, count) => ({
    type: CHANGER_SUPPORT_SET_TICKETS_LIST,
    list,
    changeType,
    count
})
const SupportChangeStatusesFilterAC = (status) => ({type: CHANGER_SUPPORT_CHANGE_STATUSES_FILTER, status}) //ChangeStatusesFilterAC
const SupportChangeTypesFilterAC = (ttype) => ({type: CHANGER_SUPPORT_CHANGE_TYPES_FILTER, ttype}) //ChangeServersFilterAC
export const SupportUpdateTicketsStatus = (body) => ({type: CHANGER_SUPPORT_UPDATE_TICKETS_STATUS, body}) //UpdateTTStatus
const SupportChangeTicketStatusAC = (timestamp_creation, ticket) => ({
    type: CHANGER_SUPPORT_CHANGE_TICKET_STATUS,
    timestamp_creation,
    ticket
})
const UserChangeTicketStatusAC = (timestamp_creation, ticket) => ({
    type: CHANGER_USER_CHANGE_TICKET_STATUS,
    timestamp_creation,
    ticket
})
const AdminPushNewBank = (bank) => ({type: CHANGER_ADMIN_PUSH_NEW_BANK, bank})
const AdminSetBanksList = (adminBanksList) => ({type: CHANGER_ADMIN_SET_BANKS_LIST, adminBanksList})
const AdminDeleteBankAC = (public_type, public_name) => ({type: CHANGER_ADMIN_DELETE_BANK, public_type, public_name})
const AdminDeleteTicketAC = (timestamp_creation) => ({type: CHANGER_ADMIN_DELETE_TICKET, timestamp_creation})

export const SupportChangeStatusesFilter = (status) => { //ChangeStatusesFilter
    return (dispatch) => {
        dispatch(SupportChangeStatusesFilterAC(status));
    }
}
export const SupportChangeTypesFilter = (ttype) => { //ChangeServersFilter
    return (dispatch) => {
        dispatch(SupportChangeTypesFilterAC(ttype));
    }
}

export const UserSendTicket = (type, checkbox, public_type, public_name, card, nickname, value, userCurrentHelp) => {
    return (dispatch) => {
        if (type == "request" && !checkbox) return window.alert("Для отправки запроса необходимо согласиться с правилами обмена")
        let SUM = value ? value.split(" ") : 0;
        if (+SUM[0] <= 49) return window.alert("Суммы меньше 50 USD/EUR временно не обрабатываются");
        dispatch(ChangeMainLoading(true))
        dispatch(ExchangerSetRequestStep(2))
        let body = {
            type, public_type, public_name, card, nickname, value, userCurrentHelp,
            token: Cookies.get('token')
        }
        FS_CHANGER_API.userSendTicket(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(ExchangerSetRequestStep(1))
                    window.alert(data.message)
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(ExchangerSetRequestStep(3))
                dispatch(ChangeMainLoading(false))
                setTimeout(() => {
                    dispatch(ExchangerSetRequestStep(1))
                }, 3000)
            })
    }
}
export const UserGetBanksList = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        FS_CHANGER_API.userGetBanks()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка банков`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(UserSetBanksList(data.Banks, data.Feedback))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const UserGetTicketsList = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        FS_CHANGER_API.userGetTickets()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка ваших тикетов`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(UserSetTicketsList(data))

                dispatch(ChangeMainLoading(false))
            })
    }
}
export const AdminCreateBank = (creation_info) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get('token'),
            creation_info: {
                ...creation_info
            }
        }
        FS_CHANGER_API.adminCreateBank(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Создание банка`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(AdminPushNewBank(data));
                dispatch(AddNotification("success", `Создание банка`, "Создано"))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const AdminEditBank = (edition_info) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            edition_info: {...edition_info},
            token: Cookies.get('token')
        }
        FS_CHANGER_API.adminEditBank(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение банка`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const AdminChangeServerConverter = (currency, value) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            currency, value,
            token: Cookies.get('token')
        }
        FS_CHANGER_API.adminChangeServerConverter(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение конвертации`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const AdminDeleteBank = (public_type, public_name) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        FS_CHANGER_API.adminDeleteBank(public_type, public_name)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Удаление банка`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(AdminDeleteBankAC(public_type, public_name))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const UserChangeTicketStatus = (timestamp_creation, status) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            timestamp_creation, status,
            token: Cookies.get('token')
        }
        FS_CHANGER_API.userChangeStatus(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение статуса`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(UserChangeTicketStatusAC(timestamp_creation, data));
                dispatch(AddNotification("success", `Изменение статуса`, "Статус перевода подтвержден!\n\nПо желанию вы можете оставить отзыв в своей конференции командой /отзыв"))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const AdminDeleteTicket = (timestamp_creation) => {
    return (dispatch) => {
        FS_CHANGER_API.adminDeleteTicket(timestamp_creation)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Удаление тикета`, data.message))
                    return
                }
                dispatch(AdminDeleteTicketAC(timestamp_creation))
            })
    }
}

export const SupportEditTicket = (timestamp_creation, type, newValue) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get('token'),
            edition_info: {
                timestamp_creation, type, newValue
            }
        }
        FS_CHANGER_API.supportEditTicket(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение тикета`, data.message))
                    return
                }
            })
    }
}
export const SupportChangeTicketStatus = (timestamp_creation, status) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            timestamp_creation, status,
            token: Cookies.get('token')
        }
        FS_CHANGER_API.supportChangeStatus(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение статуса`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportChangeTicketStatusAC(timestamp_creation, data));
                dispatch(AddNotification("success", `Изменение статуса`, "Статус изменен"))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const AdminGetBanksList = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        FS_CHANGER_API.adminGetBanksList()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка банков`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(AdminSetBanksList(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const SupportGetTicketsList = (changeType, types, statuses, skipElements, limitElements) => { //GetTTList
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            filters: {
                types,
                statuses
            },
            skipElements,
            limitElements,
            token: Cookies.get('token')
        }
        FS_CHANGER_API.supportGetTickets(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка вопросов / запросов по обменам`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                if (changeType == 'set') {
                    dispatch(SupportSetTicketsList([], changeType, null))
                    dispatch(SupportSetTicketsList(data.tasks ? data.tasks : [], changeType, data.count ? data.count : null))
                } else {
                    dispatch(SupportSetTicketsList(data.tasks ? data.tasks : [], changeType, data.count ? data.count : null))
                }
                dispatch(ChangeMainLoading(false))
            })
    }
}

function uniq(a) {
    return a.sort().filter(function (item, pos, ary) {
        return !pos || item != ary[pos - 1];
    });
}

export default fsChangerReducer;