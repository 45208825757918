import React from 'react';
import MainLoading from "../common/mainLoading";
import Cookies from "js-cookie";
import config from "../../config.json";

const Auth = (props) => {


    if (props.AuthInfo.accessToken && !props.AuthInfo.isDeveloper && !props.AuthInfo.isCoach && !props.AuthInfo.isOwner && !props.AuthInfo.isAdmin
    && !props.AuthInfo.isAuditAdmin && !props.AuthInfo.isFsChangerAdmin && !props.AuthInfo.isFsChangerSupport && !props.AuthInfo.isWikiCreator
        && !props.AuthInfo.isSupport && !props.AuthInfo.isDisk && !props.AuthInfo.isFinancesAdmin && !props.AuthInfo.isSpinSupport
        && !props.AuthInfo.isCashSupport && !props.AuthInfo.isMTTSupport && !props.AuthInfo.isMTSNGSupport && !props.AuthInfo.isRefSupport
        && !props.AuthInfo.isAuditor && !props.AuthInfo.isPokerhubSupport) {
        Cookies.set('WEB_SITE_USER_VERSION', "2", {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        window.location.href = '/';
    }

    const goToNewVersion = () => {
        Cookies.set('WEB_SITE_USER_VERSION', "2", {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        window.location.href = '/';
    }

    return (
        props.isLoading ? <MainLoading/>
            :
        <div className={"auth"}>
            { props.AuthInfo.accessToken ?
                <div className="sidebar-user">
                    <div className="sidebar-user__photo">
                        <img src={`https://cdn.discordapp.com/avatars/${props.AuthInfo.id}/${props.AuthInfo.avatar}?size=32`} alt={"userAvatar"}/>
                    </div>
                    <div className="sidebar-user__info">
                        <div className="sidebar-user__head">
                            <div className="sidebar-user__login" >{props.AuthInfo.username}</div>
                            <a onClick={props.Logout} className="sidebar-user__exit">выйти</a>
                        </div>
                        <div className="sidebar-user__email" style={{fontSize: "10px"}}>{props.AuthInfo.email}</div>
                        <div onClick={goToNewVersion} className="sidebar-user__email" style={{color: "#0094e1", cursor: "pointer"}}>Перейти в новую версию ЛК</div>
                    </div>
                </div>
                :
                <div className={"bad_status"}>
                    <span>Для работы в личном кабинете нужно <a href={"https://discord.com/api/oauth2/authorize?client_id=809033060234297406&redirect_uri=https%3A%2F%2Flk.firestorm.team%2F&response_type=code&scope=email%20identify"}>авторизоваться</a><br/><br/>Для успешной авторизации требуется чтобы у вас в дискорде была подтверждена почта и был корректный никнейм</span>
                     {/*<span>Для работы в личном кабинете нужно <a href={"https://discord.com/api/oauth2/authorize?client_id=809033060234297406&redirect_uri=http%3A%2F%2Flocalhost%3A3000&response_type=code&scope=email%20identify"}>авторизоваться</a><br/><br/>Для успешной авторизации требуется чтобы у вас в дискорде была подтверждена почта и был корректный никнейм</span>*/}
                </div>
            }

        </div>
    )
}

export default Auth;
