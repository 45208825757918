import {COA_API} from "../../api/util";
import {ChangeMainLoading} from "./mainReducer";
import {AddNotification} from "./notificationsReducer";
import Cookies from "js-cookie";

const COA_SET_ROWS = 'COA_SET_ROWS';
const COA_CREATE_ROW = 'COA_CREATE_ROW';
const COA_EDIT_ROW = 'COA_EDIT_ROW';
const COA_REMOVE_ROW = 'COA_REMOVE_ROW';


const COA_TRANSFERS_SET_INFO = 'COA_TRANSFERS_SET_INFO';
const COA_TRANSFERS_SET_ROWS = 'COA_TRANSFERS_SET_ROWS';
const COA_TRANSFERS_CREATE_ROW = 'COA_TRANSFERS_CREATE_ROW';
const COA_TRANSFERS_EDIT_ROW = 'COA_TRANSFERS_EDIT_ROW';
const COA_TRANSFERS_REMOVE_ROW = 'COA_TRANSFERS_REMOVE_ROW';


let initialState = {
    rows_all: [],
    rows_lvl_1: [],
    rows_lvl_2: [],
    rows_lvl_3: [],
    rows_lvl_4: [],

    rows_transfers: [],
    transfers_debits: [],
    transfers_credits: [],
    transfers_users: [],
    transfers_currencies: []
};

const coaReducer = (state = initialState, action) => {
    switch (action.type) {
        case COA_SET_ROWS:
            return {
                ...state,
                rows_all: JSON.parse(JSON.stringify(action.data.ALL_ROWS)),
                rows_lvl_1: JSON.parse(JSON.stringify(action.data.ROWS_LVL_1)),
                rows_lvl_2: JSON.parse(JSON.stringify(action.data.ROWS_LVL_2)),
                rows_lvl_3: JSON.parse(JSON.stringify(action.data.ROWS_LVL_3)),
                rows_lvl_4: JSON.parse(JSON.stringify(action.data.ROWS_LVL_4)),
            };
        case COA_CREATE_ROW: {
            if (action.lvl == 1) {
                return {
                    ...state,
                    rows_all: [...state.rows_all, action.data].sort((a, b) => a.public_id - b.public_id),
                    rows_lvl_1: [...state.rows_lvl_1, action.data].sort((a, b) => a.public_id - b.public_id)
                }
            } else if (action.lvl == 2) {
                return {
                    ...state,
                    rows_all: [...state.rows_all, action.data].sort((a, b) => a.public_id - b.public_id),
                    rows_lvl_2: [...state.rows_lvl_2, action.data].sort((a, b) => a.public_id - b.public_id)
                }
            } else if (action.lvl == 3) {
                return {
                    ...state,
                    rows_all: [...state.rows_all, action.data].sort((a, b) => a.public_id - b.public_id),
                    rows_lvl_3: [...state.rows_lvl_3, action.data].sort((a, b) => a.public_id - b.public_id)
                }
            } else if (action.lvl == 4) {
                return {
                    ...state,
                    rows_all: [...state.rows_all, action.data].sort((a, b) => a.public_id - b.public_id),
                    rows_lvl_4: [...state.rows_lvl_4, action.data].sort((a, b) => a.public_id - b.public_id)
                }
            } else {
                return state;
            }
        }
        case COA_EDIT_ROW: {
            const newRowsAll = JSON.parse(JSON.stringify(state.rows_all));
            const newRowsLvl = JSON.parse(JSON.stringify(state[`rows_lvl_${action.lvl}`]));

            const indexInAll = newRowsAll.findIndex(e => e.public_id == action.data.public_id);
            const indexInLvl = newRowsLvl.findIndex(e => e.public_id == action.data.public_id);

            if (indexInAll != -1) {
                newRowsAll[indexInAll][action.data.key] = action.data.newValue;
            }
            if (indexInLvl != -1) {
                newRowsLvl[indexInLvl][action.data.key] = action.data.newValue;
            }

            return {
                ...state,
                rows_all: JSON.parse(JSON.stringify(newRowsAll)),
                [`rows_lvl_${action.lvl}`]: JSON.parse(JSON.stringify(newRowsLvl))
            }
        }
        case COA_REMOVE_ROW: {
            const newRowsAll = JSON.parse(JSON.stringify(state.rows_all));
            const newRowsLvl = JSON.parse(JSON.stringify(state[`rows_lvl_${action.lvl}`]));

            const indexInAll = newRowsAll.findIndex(e => e.public_id == action.public_id);
            const indexInLvl = newRowsLvl.findIndex(e => e.public_id == action.public_id);

            if (indexInAll != -1) {
                newRowsAll.splice(indexInAll, 1);
            }
            if (indexInLvl != -1) {
                newRowsLvl.splice(indexInLvl, 1);
            }

            return {
                ...state,
                rows_all: JSON.parse(JSON.stringify(newRowsAll)),
                [`rows_lvl_${action.lvl}`]: JSON.parse(JSON.stringify(newRowsLvl))
            }
        }

        case COA_TRANSFERS_SET_INFO: {
            return {
                ...state,
                transfers_debits: JSON.parse(JSON.stringify(action.data.debits)),
                transfers_credits: JSON.parse(JSON.stringify(action.data.credits)),
                transfers_users: JSON.parse(JSON.stringify(action.data.users)),
                transfers_currencies: JSON.parse(JSON.stringify(action.data.currencies)),
            }
        }
        case COA_TRANSFERS_SET_ROWS:
            return {
                ...state,
                rows_transfers: JSON.parse(JSON.stringify(action.data)),
            };
        case COA_TRANSFERS_CREATE_ROW: {
            return {
                ...state,
                rows_transfers: [...state.rows_transfers, action.data].sort((a, b) => a.id - b.id),
            }
        }
        case COA_TRANSFERS_EDIT_ROW: {
            const newRowsAll = JSON.parse(JSON.stringify(state.rows_transfers));
            const indexInAll = newRowsAll.findIndex(e => e.id == action.data.id);

            if (indexInAll != -1) {
                newRowsAll[indexInAll][action.data.key] = action.data.newValue;
            }

            return {
                ...state,
                rows_transfers: JSON.parse(JSON.stringify(newRowsAll))
            }
        }
        case COA_TRANSFERS_REMOVE_ROW: {
            const newRowsAll = JSON.parse(JSON.stringify(state.rows_transfers));
            const indexInAll = newRowsAll.findIndex(e => e.id == action.id);

            if (indexInAll != -1) {
                newRowsAll.splice(indexInAll, 1);
            }

            return {
                ...state,
                rows_transfers: JSON.parse(JSON.stringify(newRowsAll))
            }
        }
        default:
            return state;
    }
};


const setRows = (data) => ({type: COA_SET_ROWS, data});
const createRow = (data, lvl) => ({type: COA_CREATE_ROW, data, lvl});
const editRow = (data, lvl) => ({type: COA_EDIT_ROW, data, lvl});
const removeRow = (public_id, lvl) => ({type: COA_REMOVE_ROW, public_id, lvl});

const transfersSetRows = (data) => ({type: COA_TRANSFERS_SET_ROWS, data});
const transfersSetInfo = (data) => ({type: COA_TRANSFERS_SET_INFO, data});
const transfersCreateRow = (data) => ({type: COA_TRANSFERS_CREATE_ROW, data});
const transfersEditRow = (data) => ({type: COA_TRANSFERS_EDIT_ROW, data});
const transfersRemoveRow = (id) => ({type: COA_TRANSFERS_REMOVE_ROW, id});

export const COA_GetAllRows = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        COA_API.getAll()
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение всех строк", data.message))
                    return
                }
                dispatch(setRows(data))
            })
    }
}
export const COA_CreateRow = (body_data, lvl) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true));
        let body = {
            data: body_data,
            lvl,
            token: Cookies.get("token")
        }
        COA_API.create(body)
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание строки", data.message))
                    return
                }
                dispatch(createRow(data, lvl))
            })
    }
}
export const COA_EditRow = (body_data, lvl) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true));
        let body = {
            data: body_data,
            lvl,
            token: Cookies.get("token")
        }
        COA_API.edit(body)
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение строки", data.message))
                    return
                }
                dispatch(editRow(body_data, lvl))
            })
    }
}
export const COA_RemoveRow = (public_id, lvl) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true));
        COA_API.remove(lvl, public_id)
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление строки", data.message))
                    return
                }
                dispatch(removeRow(public_id, lvl))
            })
    }
}

export const COA_Transfers_GetInfo = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        COA_API.transfersGetInfo()
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение информации для формы создания", data.message))
                    return
                }
                dispatch(transfersSetInfo(data))
            })
    }
}
export const COA_Transfers_GetByDate = (dateFrom, dateTo, type) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        COA_API.transfersGetByDate(dateFrom, dateTo, type)
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение трансферов", data.message))
                    return
                }
                dispatch(transfersSetRows(data))
            })
    }
}
export const COA_Transfers_CreateRow = (body_data) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true));
        let body = {
            data: body_data,
            token: Cookies.get("token")
        }
        COA_API.transfersCreate(body)
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание трансфера", data.message))
                    return
                }
                dispatch(transfersCreateRow(data))
            })
    }
}
export const COA_Transfers_EditRow = (body_data) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true));
        let body = {
            data: body_data,
            token: Cookies.get("token")
        }
        COA_API.transfersEdit(body)
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение трансфера", data.message))
                    return
                }
                dispatch(transfersEditRow(body_data))
            })
    }
}
export const COA_Transfers_RemoveRow = (id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true));
        COA_API.transfersRemove(id)
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление трансфера", data.message))
                    return
                }
                dispatch(transfersRemoveRow(id))
            })
    }
}

export default coaReducer;