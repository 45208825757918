import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Progress from "../../../common/fields/progress";
import CloseArrowPopup from "../../../common/closeArrowPopup";
import {useWindowSize} from "../../../hooks/useWindowSize";
import classNames from "classnames";

const someData = {
    yourProfit: [
        {sum: '$50'},
        {sum: '$75'},
        {sum: '$125'},
        {sum: '$250'},
        {sum: '$500'},
    ],
    rekrutProfit: [
        {sum: '$1000', order: 1},
        {sum: '$2000', order: 2},
        {sum: '$5000', order: 3},
        {sum: '$10000', order: 4},
        {sum: '$16000', order: 5},
    ]
}
const PopupRefProgram = ({onClose}) => {
    const {width} = useWindowSize()

    return (
        <div className={s.ref_program_box}>
            <CloseArrowPopup className={s.close_arrow} title={'Реферальная программа Team Firestorm'}
                             onClose={onClose}/>
            <div className={s.header_box}>
                <h3>Реферальная программа
                    Team Firestorm</h3>
            </div>

            <div className={s.progress_box}>
                <div className={s.top_progress}>
                    {someData.yourProfit?.map((el, i) => <div key={i} className={s.top_progress_item}>
                        <div className={s.text_box}>
                            <p>Ваша прибыль</p>
                            <span className={s.top_sum}>{el.sum}</span>
                        </div>
                        <div className={s.progress_line}/>
                    </div>)}
                </div>

                <div className={s.middle_progress}>
                    <Progress value={80} type={width <= 768 && 'vertical'} isDefaultLogic={true}/>
                </div>

                <div className={s.bottom_progress}>
                    {someData.rekrutProfit?.map((el, i) => <div key={i} className={s.top_progress_item}>
                        <div className={classNames(s.progress_line, s.progress_line2)}/>
                        <div className={classNames(s.order, s.order2)}>{el.order}</div>
                        <div className={classNames(s.text_box, s.text_box2)}>
                            <p className={s.bottom_profit}>Профит рекрута</p>
                            <span>{el.sum}</span>
                        </div>
                    </div>)}
                </div>
            </div>

            <div className={s.last_item_box}>
                <div className={s.last_item}>
                    <p>Стандартные условия <span>— действуют с момента прихода игрока и в течении 12 месяцев.</span></p>
                </div>
                <div className={s.last_item}>
                    <p>Премиум условия <span>— доступны после 10 привлеченных рекрутов, и действуют в течении всего нахождения игрока в команде.</span>
                    </p>
                </div>
                <div className={s.line}/>
                <div className={s.last_item}>
                    <p>10+ <span>— игроков для получения премиальных условий реферальной программы</span></p>
                </div>
                <div className={s.last_item}>
                    <p> $1000 <span>— при прохождении игроком всех этапов выплаты</span></p>
                </div>
            </div>
        </div>
    );
};

export default PopupRefProgram;
