import {LEARN_API} from "../../api/util";
import {ChangeMainLoading} from "./mainReducer";
import {AddNotification} from "./notificationsReducer";
import Cookies from "js-cookie";

const SET_DATA = 'SET_DATA';

let initialState = {
    data: null
};

const learnReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                data: action.data
            };
        default:
            return state;
    }
};

const SetData = (data) => ({type: SET_DATA, data});

export const GetLearn = (name) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        LEARN_API.get(name)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Загрузка курса", data.message))
                    return
                }
                dispatch(SetData(null))
                dispatch(SetData(data))
            })
    }
}
export const EditLearn = (data) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                email: Cookies.get('email'),
                id: Cookies.get('id')
            },
            access_token: Cookies.get('token'),
            learn_info: data
        };

        LEARN_API.edit(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение курса", data.message))
                    return
                }
                dispatch(SetData(null))
                dispatch(SetData(data))
                dispatch(AddNotification("success", "Изменение курса", "Успешно"))

            })
    }
}

export default learnReducer;