import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import ScheduleCalendar from "./scheduleCalendar";
import {CssBaseline} from "@mui/material";
import {GetCalendarInfo, RemoveCalendarDate, SchedulerAddDates} from "../../../../redux/reducers/schedulerReducer";

class LeaderboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }


    componentDidMount() {
        this.props.changeCurrentPage("Support: График работы");
        this.props.GetCalendarInfo()
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <>
                <ScheduleCalendar {...this.props}/>
            </>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    ScheduleInfo: state.schedulerReducer
})


export default compose(
    connect(mapStateToProps, {
        GetCalendarInfo, SchedulerAddDates, RemoveCalendarDate
    }),
    withRouter
)(LeaderboardContainer)