import React, {useState, useRef} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {SupportChangeRow} from "../../../../redux/reducers/formularReducer";
import moment from "moment";

export default function FinResultsRows(props) {

    return (
        <div className={"training-connectors-list-page"}>
            <div style={{gridTemplateColumns: "2fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 2fr 1fr 1fr"}}
                 className={"columns-names"}>
                <div className={"column-name"}>
                    account_id
                </div>
                <div className={"column-name"}>
                    nickname
                </div>
                <div className={"column-name"}>
                    club_name
                </div>
                <div className={"column-name"}>
                    profit_chip
                </div>
                <div className={"column-name"}>
                    rake_chip
                </div>
                <div className={"column-name"}>
                    tax
                </div>
                <div className={"column-name"}>
                    profit_usd
                </div>
                <div className={"column-name"}>
                    rakeback_usd
                </div>
                <div className={"column-name"}>
                    period
                </div>
                <div className={"column-name"}>
                    set
                </div>
                <></>
            </div>
            <div className={"training-connectors-list"}>
                <div id="scrollableDiv" style={{height: "85vh", overflow: "auto"}}>
                    <InfiniteScroll
                        dataLength={props.FormularInfo.supportResultsRows.length}
                        next={props.fetchMoreData}
                        hasMore={props.FormularInfo.supportResultsRows.length <= props.FormularInfo.supportResultsRowsCount}
                        scrollableTarget="scrollableDiv"
                    >
                        {
                            props.FormularInfo.supportResultsRows.map((row, i) => {
                                const convertNick = row.nickname.length > 13 ? row.nickname.slice(0, 13) + '...' : row.nickname;

                                return <div key={`${row._id} finresrow ${row.setCount}`}
                                            style={{
                                                gridTemplateColumns: "2fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 2fr 1fr 1fr",
                                                backgroundColor: i % 2 == 0 ? "#2b2b38" : "#1f1f27"
                                            }}
                                            className={"connector"}>
                                    <div className={"name"}>
                                        {row.id}
                                    </div>
                                    <div className={"name"} title={row.nickname}>
                                        {convertNick}
                                    </div>
                                    <div className={"name"}>
                                        {row.club_name}
                                    </div>
                                    <div className={"name"}>
                                        {row.profit_chip ? +row.profit_chip.toFixed(2) : 0}
                                    </div>
                                    <div className={"name"}>
                                        {row.rake_chip ? +row.rake_chip.toFixed(2) : 0}
                                    </div>
                                    <div className={"name"}>
                                        {row.tax ? +row.tax.toFixed(2) : 0}
                                    </div>
                                    <div className={"name"}>
                                        {row.profit_usd ? +row.profit_usd.toFixed(2) : 0}
                                    </div>
                                    <div className={"name"}>
                                        {row.rakeback_usd ? +row.rakeback_usd.toFixed(2) : 0}
                                    </div>
                                    <div className={"name"}>
                                        {moment(+row.from_timestamp).format("DD.MM")}-{moment(+row.to_timestamp).format("DD.MM.YYYY")}
                                    </div>
                                    <input type={"number"} className={"name"} defaultValue={row.setCount}
                                           onBlur={(e) => props.SupportChangeRow(row._id, "setCount", e.target.value)}/>
                                    {
                                        row.status == 4 && row.setCount == 0 ?
                                            <div className={"default-button transition orange-back"}
                                                 style={{padding: "1px 0"}}
                                                 onClick={() => props.SupportChangeRow(row._id, "status", 1)}>
                                                Вернуть
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                            })
                        }
                    </InfiniteScroll>
                </div>

            </div>
        </div>
    );
}
