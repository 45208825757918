import React, {useState, useRef} from "react"
import MainLoading from "../../common/mainLoading";

import {Redirect} from "react-router-dom";

export default function CustomPage(props) {
    let url = null;

    if (props.AuthInfo.currentDocumentPage) {

        if (props.docType.toLowerCase() == "документ") url = `https://docs.google.com/document/d/${props.AuthInfo.currentDocumentPage.docId}/edit#gid=0?usp=sharing?widget=true&amp;headers=false`
        if (props.docType.toLowerCase() == "таблица") url = `https://docs.google.com/spreadsheets/d/${props.AuthInfo.currentDocumentPage.docId}?usp=sharing?widget=true&amp;headers=false`
        if (props.docType.toLowerCase() == "график") url = props.AuthInfo.currentDocumentPage.docId
        if (props.docType.toLowerCase() == "фрейм") {
            url = props.AuthInfo.currentDocumentPage.docId;
            url = url.replace('{user.token}', props.AuthInfo.accessToken).replace('{user._id}', props.AuthInfo._id)
        }
        if (props.docType.toLowerCase() == "курс") return <Redirect to={`/learn/${props.AuthInfo.currentDocumentPage.name}`}/>
    }

    return (
        props.isLoading ? <MainLoading/>
            :
            props.AuthInfo.currentDocumentPage ?
                <iframe
                    width="100%"
                    height={props.docType.toLowerCase() == "график" ? "2000px" : "800px"}
                    src={url}
                    frameborder="0"
                    allowFullScreen="true"
                ></iframe>
                : null
    );
}
