import React, {useState} from "react";

import {AgGridColumn, AgGridReact} from "ag-grid-react";
import NumberToFixed1Field from "../../../../v_2/common/fields/numberToFixed1Field";
import NumberToFixed2Field from "../../../../v_2/common/fields/numberToFixed2Field";
import TextField from "../../../../v_2/common/fields/textField";
import s from "../../../../v_2/pages/FinResults/4-content/styles.module.css";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DateTimePicker, DatePicker
} from '@material-ui/pickers';
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import NumberField from "../../../../v_2/common/fields/numberField";
import BoolField from "../../../../v_2/common/fields/boolField";
// import {DatePicker} from "@mui/x-date-pickers";

const StartStackTable = (props) => {
    const [fromDate, setFromDate] = useState(+new Date());
    const [toDate, setToDate] = useState(+new Date());

    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            },

        },
    });

    return (
        <div>

            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                padding: "50px 50px 10px 50px"
            }}>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            format="dd.MM.yyyy"
                            margin="normal"
                            label="От"
                            value={fromDate}
                            onChange={(e) => setFromDate(+e)}
                            style={{filter: "brightness(0) invert(1)", margin: "0 10px 0 20px"}}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            format="dd.MM.yyyy"
                            margin="normal"
                            label="До"
                            value={toDate}
                            onChange={(e) => setToDate(+e)}
                            style={{filter: "brightness(0) invert(1)", margin: "0 20px 0 10px"}}
                        />
                    </MuiPickersUtilsProvider>

                </ThemeProvider>
                <div className={"orange-back"} style={{
                    cursor: "pointer",
                    height: "50px",
                    color: "white",
                    textAlign: "center",
                    padding: "12px",
                    marginRight: "10px"
                }}
                     onClick={() => props.LeakfinderGetStartStackTableRows(fromDate, toDate, props.LeakfinderInfo.db_info)}
                >
                    Анализ
                </div>
                <Popup key={"lk-heads-editor"}
                       trigger={
                           <div className={"default-back"} style={{
                               cursor: "pointer",
                               height: "50px",
                               color: "white",
                               textAlign: "center",
                               padding: "12px",
                               marginLeft: "10px"
                           }}
                           >
                               Сохранения
                           </div>}
                       modal
                       nested
                >
                    {() => (
                        <div>
                            <div id="get-advice" style={{
                                backgroundColor: "#1e1e25",
                                padding: "10px 50px",
                                textAlign: "left",
                                color: "white",
                                width: "90vh"
                            }}>
                                <form className="form">
                                    <div className="formgroup" style={{marginTop: "17px"}}>
                                        {
                                            props.LeakfinderInfo.startStackTableSaves.map((save, i) => {
                                                    return <div style={{display: "grid", gridTemplateColumns: "1fr 70px 70px",
                                                        textAlign: "center",
                                                        color: "white",
                                                        margin: "15px 0",
                                                        backgroundColor: "#2d2d3d",
                                                        borderRadius: "6px"
                                                    }}>
                                                        <div style={{
                                                            padding: "10px 5px",
                                                        }}>
                                                            {save.date}
                                                        </div>

                                                        <div style={{
                                                            padding: "10px 5px",
                                                            cursor: "pointer",
                                                        }}
                                                             onClick={() => props.LeakfinderGetStartStackTableSave(save._id)}
                                                             className={"green-back transition"}>
                                                            Load
                                                        </div>

                                                        <div style={{
                                                            padding: "10px 5px",
                                                            borderRadius: "0 6px 6px 0",
                                                            cursor: "pointer",
                                                        }}
                                                             onClick={() => props.LeakfinderDeleteStartStackTableSave(save._id)}
                                                             className={"red-back transition"}
                                                        >
                                                            Delete
                                                        </div>
                                                    </div>
                                                }
                                            )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>


            {
                props.LeakfinderInfo.startStackTableCurrentSave && props.LeakfinderInfo.startStackTableCurrentSave.data.rows[0] ?
                    <div className='ag-theme-alpine-dark'
                         style={{
                             height: '70vh',
                             width: '100%',
                             position: 'relative',
                             margin: "40px 0 0 5px",
                             padding: "0 50px"
                         }}>
                        <AgGridReact
                            style={{height: 400, width: '100%'}}
                            rowData={props.LeakfinderInfo.startStackTableCurrentSave.data.rows}
                            enableBrowserTooltips={true}
                            tooltipShowDelay={0}
                            tooltipHideDelay={2000}
                            rowHeight={70}
                            defaultColDef={{
                                editable: true,
                                sortable: true,
                                flex: 1,
                                minWidth: 100,
                                filter: false,
                                floatingFilter: false,
                                resizable: true,
                            }}
                            frameworkComponents={{
                                numberToFixed1Field: NumberToFixed1Field,
                                numberToFixed2Field: NumberToFixed2Field,
                                textField: TextField,
                                numberField: NumberField,
                                boolField: BoolField
                            }}
                        >
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'ID'}
                                field={'id_tourney'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Ник'}
                                field={'player_name'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Рум'}
                                field={'site_name'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'№ турнира'}
                                field={'tourney_no'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Дата'}
                                field={'date_start'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Флаги турнира'}
                                field={'val_flags'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Скорость турнира'}
                                field={'val_speed'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Описание'}
                                field={'description'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Флаг саттелита'}
                                field={'flg_sat_seat'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='boolField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Бай-ин'}
                                field={'buyin'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberToFixed2Field'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'ID руки'}
                                field={'id_hand'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Стек в фишках'}
                                field={'amt_before'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberToFixed2Field'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Анте в фишках'}
                                field={'amt_ante'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberToFixed2Field'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Размер блайнда'}
                                field={'amt_bb'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberToFixed2Field'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Размер стека в ББ'}
                                field={'startstack'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberToFixed2Field'
                            />
                        </AgGridReact>
                    </div>
                    : null
            }

            {
                props.LeakfinderInfo.startStackTableCurrentSave && props.LeakfinderInfo.startStackTableCurrentSave.result_table.rows[0] ?
                    <div className='ag-theme-alpine-dark'
                         style={{
                             height: '40vh',
                             width: '100%',
                             position: 'relative',
                             margin: "40px 0 0 5px",
                             padding: "0 50px"
                         }}>
                        <AgGridReact
                            style={{height: 400, width: '100%', fontSize: "10px"}}
                            rowData={props.LeakfinderInfo.startStackTableCurrentSave.result_table.rows}
                            enableBrowserTooltips={true}
                            tooltipShowDelay={0}
                            tooltipHideDelay={2000}
                            rowHeight={70}
                            defaultColDef={{
                                editable: true,
                                sortable: true,
                                flex: 1,
                                minWidth: 20,
                                filter: false,
                                floatingFilter: false,
                                resizable: true,
                                fontSize: "11px"
                            }}
                            frameworkComponents={{
                                numberToFixed1Field: NumberToFixed1Field,
                                numberToFixed2Field: NumberToFixed2Field,
                                textField: TextField,
                                numberField: NumberField
                            }}
                        >

                            <AgGridColumn
                                headerClass={s.header_styles_mini}
                                headerName={'nick'}
                                field={'player_name'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles_mini}
                                headerName={'room'}
                                field={'site_abbrev'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles_mini}
                                headerName={'buyin'}
                                field={'buyin'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberToFixed2Field'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles_mini}
                                headerName={'tourney_count'}
                                field={'tourney_count'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='textField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles_mini}
                                headerName={'sum_buyin'}
                                field={'sum_buyin'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberToFixed2Field'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles_mini}
                                headerName={'amt_won'}
                                field={'amt_won'}
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberToFixed2Field'
                            />
                        </AgGridReact>
                    </div>
                    : null
            }
        </div>
    );
};

export default StartStackTable;