import React, {useEffect, useMemo, useRef, useState} from 'react';
import Select from "react-dropdown-select";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import moment from "moment/moment";
import {ExportCoachStatsToGoogleTable, SetStudentsList} from "../../../../redux/reducers/trainingReducer";

const CoachSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите тренера"}
            labelField={"coach_name"}
            valueField={"coach_name"}
            sortBy={"coach_name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelectCoach(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "1"}}
        />
    )
}

const GroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать группу"}
            labelField={"group_name"}
            valueField={"group_name"}
            sortBy={"group_name"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelectFilterGroup(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "1"}}
        />
    )
}

const TypeSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать тип"}
            labelField={"type"}
            valueField={"type"}
            sortBy={"type"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectFilterType(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "0"}}
        />
    )
}

const StatusSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать статусы"}
            labelField={"status"}
            valueField={"status"}
            sortBy={"status"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectFilterStatus(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "0"}}
        />
    )
}

const DifficultSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать сложность"}
            labelField={"subType"}
            valueField={"subType"}
            sortBy={"subType"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectFilterDifficult(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "0"}}
        />
    )
}

const CoachesStat = (props) => {
    const [selectedCoach, setSelectedCoach] = useState([]);
    const [fromTimestamp, setFromTimestamp] = useState(+new Date());
    const [toTimestamp, setToTimestamp] = useState(+new Date());

    const handleSelectFilterType = (e) => {
        props.CoachStatsSetFilterType(e)
    }
    const handleSelectFilterGroup = (e) => {
        props.CoachStatsSetFilterGroup(e)
    }
    const handleSelectFilterDifficult = (e) => {
        props.CoachStatsSetFilterDifficult(e)
    }
    const handleSelectFilterStatus = (type, e) => {
        props.CoachStatsSetFilterStatus(type, e)
    }

    const handleSelectCoach = (e) => {
        setSelectedCoach(JSON.parse(JSON.stringify(e)));
        props.GetCoachStats(fromTimestamp, toTimestamp, e[0]?.coach_id)
    }

    const handleSelectFromTimestamp = (e) => {
        if (new Date(e) == "Invalid Date") return;
        setFromTimestamp(+new Date(e));
        props.GetCoachStats(+new Date(e), toTimestamp, selectedCoach[0]?.coach_id)
    }
    const handleSelectToTimestamp = (e) => {
        if (new Date(e) == "Invalid Date") return;
        setToTimestamp(+new Date(e));
        props.GetCoachStats(fromTimestamp, +new Date(e), selectedCoach[0]?.coach_id)
    }


    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            },

        },
    });

    let trainings_count = 0;

    let reactions_true = 0;
    let reactions_count = 0;

    let visits_true = 0;
    let visits_count = 0;


    props.TrainingInfo.coachStats.forEach(training => {
        trainings_count += 1;

        if (training.students_list && training.students_list[0]) {
            training.students_list.forEach(student => {
                if (student.is_reacted) reactions_true += 1;
                if (student.visit_percent && student.visit_percent >= 1) visits_true += 1;
                reactions_count += 1;
                visits_count += 1;
            })
        }
    })


    let percent_reactions = (reactions_true / reactions_count * 100).toFixed(2);
    let percent_visits = (visits_true / visits_count * 100).toFixed(2);
    let avg_visits = (visits_count / trainings_count).toFixed(2);

    // <div>{props.training.topic}</div>
    // <div>{props.training.type} {props.training.type == "personal" ? ` : ${props.training.student_name}` : ""}
    //     {props.training.type == "group" ? ` : ${props.training.group_name}` : ""} </div>
    // <div>{props.training.subType}</div>
    // <div>{moment(+props.training.from_timestamp).format("DD.MM.YYYY HH:mm")}</div>
    // <div>{props.training.students_list && props.training.students_list[0] ? `${reactions_true}/${props.training.students_list.length}` : `-`}</div>
    // <div>{props.training.students_list && props.training.students_list[0] ? `${visits_true}/${props.training.students_list.length}` : `-`}</div>
    // <div>{status}</div>


    return (
        <div>
            <div style={{textAlign: "left", margin: "10px 0px 0 50px"}}>
                <a
                    style={{
                        color: "#3260da"
                    }}
                    href="https://docs.google.com/spreadsheets/d/1afuAubUt7cFt9Og3dNkVS6iEqQO9AkZqzf5hR4kV5C8/edit#gid=0" target="_blank">Открыть таблицу с экспортированными данными</a>
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                padding: "20px 50px 10px 50px",
                color: "white"
            }}>
                <input type="date" onBlur={(e) => handleSelectFromTimestamp(e.target.value)}/>
                <input type="date" onBlur={(e) => handleSelectToTimestamp(e.target.value)}/>
                <CoachSelector options={props.TrainingInfo.coachesList}
                               handleSelectCoach={handleSelectCoach}/>
                <GroupSelector selected={props.TrainingInfo.coachStatsSelectedGroupFilter}
                               options={props.TrainingInfo.coachStatsGroupFilter}
                               handleSelectFilterGroup={handleSelectFilterGroup}/>
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                padding: "10px 50px 10px 50px",
                color: "white"
            }}>
                <TypeSelector selected={props.TrainingInfo.coachStatsSelectedTypeFilter}
                              options={props.TrainingInfo.coachStatsTypeFilter}
                              handleSelectFilterType={handleSelectFilterType}/>
                <StatusSelector selected={props.TrainingInfo.coachStatsSelectedStatusFilter}
                                options={props.TrainingInfo.coachStatsStatusFilter}
                                handleSelectFilterStatus={handleSelectFilterStatus}/>
                <DifficultSelector selected={props.TrainingInfo.coachStatsSelectedDifficultFilter}
                                   options={props.TrainingInfo.coachStatsDifficultFilter}
                                   handleSelectFilterDifficult={handleSelectFilterDifficult}/>
                {
                    props.TrainingInfo.coachStats && props.TrainingInfo.coachStats[0] &&
                    <div onClick={() => props.ExportCoachStatsToGoogleTable(props.TrainingInfo.coachStats)}
                        className={"button default-back default-button cursor-pointer"}
                        style={{marginLeft: "15px"}}>
                        Export to Google Table
                    </div>
                }
            </div>

            <div>
                {
                    props.TrainingInfo.coachStats[0] && <div>
                        <div style={{
                            color: "white",
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr", margin: "30px 0 20px 0"
                        }}>
                            <div>Тренировок всего: {trainings_count}</div>
                            <div>Процент реакций: {percent_reactions}</div>
                            <div>Процент посещений: {percent_visits}</div>
                            <div>В среднем посещений: {avg_visits}</div>
                        </div>
                        <div style={{
                            color: "white",
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr", margin: "30px 0 20px 0"
                        }}>
                            <div>Название</div>
                            <div>Тип</div>
                            <div>Сложность</div>
                            <div>Начало</div>
                            <div>Реакций</div>
                            <div>Посещение</div>
                            <div>Статус</div>
                        </div>
                    </div>
                }

                {props.TrainingInfo.coachStats.map((e, i) => {
                    return <TrainingRowPopup training={e} i={i} EditVisits={props.EditVisits}
                                             EditNotVisitedReason={props.EditNotVisitedReason} SetStudentsList={props.SetStudentsList}/>
                })}
            </div>
        </div>
    );
};

const TrainingRowPopup = (props) => {
    let visits_true = 0;
    let reactions_true = 0;

    const handleSetStudentsList = () => {
        props.SetStudentsList(props.training._id)
    }

    if (props.training.students_list && props.training.students_list[0]) {
        props.training.students_list.forEach(student => {
            if (student.is_reacted) reactions_true += 1;
            if (student.visit_percent && student.visit_percent >= 1) visits_true += 1;
        })
    }

    let status = "Активно";
    if (props.training.isClosed) status = "Завершена";
    if (props.training.isClosed && props.training.to_timestamp <= 5) status = "Отменена";

    return <Popup key={`TrainingRowPopup ${props.training._id}`}
                  trigger={
                      <div className={props.i % 2 == 0 ? "default-back" : "default-back-darker"} style={{
                          cursor: "pointer",
                          color: "white",
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                      }}
                      >
                          <div>{props.training.topic}</div>
                          <div>{props.training.type} {props.training.type == "personal" ? ` : ${props.training.student_name}` : ""}
                              {props.training.type == "group" ? ` : ${props.training.group_name}` : ""} </div>
                          <div>{props.training.subType}</div>
                          <div>{moment(+props.training.from_timestamp).format("DD.MM.YYYY HH:mm")}</div>
                          <div>{props.training.students_list && props.training.students_list[0] ? `${reactions_true}/${props.training.students_list.length}` : `-`}</div>
                          <div>{props.training.students_list && props.training.students_list[0] ? `${visits_true}/${props.training.students_list.length}` : `-`}</div>
                          <div>{status}</div>
                      </div>}
                  modal
                  nested
    >
        {() => (
            <div>
                <div id="get-advice" style={{
                    backgroundColor: "#1e1e25",
                    padding: "10px 50px",
                    textAlign: "left",
                    color: "white",
                    width: "90vh"
                }}>
                    <form className="form">
                        <div className={"orange-back default-button button"} onClick={handleSetStudentsList}>Инициализировать список учеников (для крайних случаев)</div>
                        <div className="formgroup" style={{marginTop: "17px"}}>
                            {
                                props.training.students_list && props.training.students_list[0] ?
                                    <div style={{padding: "20px 0 40px 10px"}}>
                                        <div style={{
                                            display: "grid",
                                            gridTemplateColumns: "2fr 1fr 2fr 2fr",
                                            marginBottom: "30px", padding: "5px"
                                        }}>
                                            <div>Ник ученика</div>
                                            <div>Реакция</div>
                                            <div>Присутствовал %</div>
                                            <div>Причина отсутствия</div>
                                        </div>
                                        {
                                            props.training.students_list.map((e, i) => {
                                                return <div key={`${e._id} ${props.training._id}`} className={"default-back-darker"} style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "2fr 1fr 2fr 2fr",
                                                    margin: "10px 0 20px 0", height: "30px", padding: "5px"
                                                }}>
                                                    <div>{e.student_name}</div>
                                                    <div>{e.is_reacted ? "Да" : "Нет"}</div>
                                                    <div>
                                                        <VisitedCheckbox e={e} training={props.training}
                                                                         EditVisits={props.EditVisits}/>
                                                    </div>
                                                    <div>
                                                        <input
                                                            onChange={(event) => props.EditNotVisitedReason(props.training._id, e.student_id, event.target.value)}
                                                            style={{
                                                                width: "100%",
                                                                marginTop: "-2px",
                                                                backgroundColor: "#2b2d3a",
                                                                color: "white"
                                                            }} type="text" placeholder={"..................."}
                                                            defaultValue={e.not_visited_reason}/>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    : <div style={{padding: "20px 0 40px 10px"}}>Статистика не записывалась, т.к.
                                        тренировка еще не завершена, либо её отменили до её автоматического завершения.</div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        )}
    </Popup>
}

const VisitedCheckbox = (props) => {
    const [value, setValue] = useState(+props.e.visit_percent ? +props.e.visit_percent : 0);

    const handleChange = (event) => {
        setValue(getPoints(event.target.value));
    };

    const handleMouseUp = (event) => {
        props.EditVisits(props.training._id, props.e.student_id, getPoints(event.target.value));
    };

    const getPoints = (value) => {
        if (value >= 100) {
            return 100;
        } else if (value >= 75) {
            return 75;
        } else if (value >= 50) {
            return 50;
        } else if (value >= 25) {
            return 25;
        } else {
            return 0;
        }
    };

    return <div style={{color: "white", display: "grid", gridTemplateColumns: "150px 10px"}}>
        <input style={{width: "90%"}}
               type="range"
               min={0}
               max={100}
               value={value}
               onChange={handleChange}
               onMouseUp={handleMouseUp}
        />
        <span>{value}</span>
    </div>

}
export default CoachesStat;

