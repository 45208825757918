import React from 'react';
import classNames from "classnames";
import s from './styles.module.css'
import {ReactComponent as Vesi} from "../../assests/vesi.svg";

const PlayersMain = (props) => {
    return (
        <div title={"Фукнционал находится в разработке.\n" +
            "Мы добавим автоматический расчет доли игрока на основе данных игрока в личном кабинете"} className={classNames(s.box_item, props.hiddenRight && s.box_item_hidden)}>
            {!props.hiddenRight && <>
                <div>
                    <p className={s.right_p}>Доля игрока</p>
                </div>
                <div className={s.box_persent}>
                    <Vesi/>
                    <p className={s.persent}>{props.formular_player_share_percent}%</p>
                </div>
            </>}

            {props.hiddenRight && <div className={s.hidden_first}>
                <Vesi/>
                <p className={s.persent}>{props.formular_player_share_percent}%</p>
            </div>}

        </div>
    );
};

export default PlayersMain;