import {REF_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";

const REFERRAL_SET_FULL_INFO = 'REFERRAL_SET_FULL_INFO';
const REFERRAL_CREATE_NEW_REQUEST = 'REFERRAL_CREATE_NEW_REQUEST';
const REFERRAL_SUPPORT_SET_USER_CASH_INFO = 'REFERRAL_SUPPORT_SET_USER_CASH_INS';
const REFERRAL_SUPPORT_SET_USERS = 'REFERRAL_SUPPORT_SET_USERS';
const REFERRAL_SUPPORT_EDIT_USER = 'REFERRAL_SUPPORT_EDIT_USER';

let initialState = {
    full_info: {
        invited_players: [],
        cash_ins: [],
        cash_outs: []
    },
    support_user_cash_ins: [],
    support_user_cash_outs: [],
    support_user_available_sum: 0,

    support_users: []
};

const referralReducer = (state = initialState, action) => {
    switch (action.type) {
        case REFERRAL_SUPPORT_EDIT_USER: {
            if (action.subType == "invites") {
                let uIndex = state.support_users.findIndex(e => e.id == action.userId);
                let newUsersList = [...state.support_users];

                if (uIndex != -1) newUsersList[uIndex].refers = JSON.parse(JSON.stringify(action.data));

                return {
                    ...state,
                    support_users: JSON.parse(JSON.stringify(newUsersList))
                }
            } else {
                return state
            }
        }
        case REFERRAL_SUPPORT_SET_USERS: {
            return {
                ...state,
                support_users: JSON.parse(JSON.stringify(action.data))
            }
        }
        case REFERRAL_CREATE_NEW_REQUEST: {
            return {
                ...state,
                full_info: {
                    ...state.full_info,
                    available_sum: state.full_info.available_sum - action.value,
                    cash_outs: [action.request, ...state.full_info.cash_outs]
                }
            }
        }
        case REFERRAL_SET_FULL_INFO: {
            return {
                ...state,
                full_info: JSON.parse(JSON.stringify(action.data))
            }
        }
        case REFERRAL_SUPPORT_SET_USER_CASH_INFO: {
            let ins = [];
            let outs = [];
            let available_sum = 0;
            action.data.forEach(e => {
                if (e.type == "getCashIn") {
                    ins.push(e)
                    available_sum += e.sum;
                }
                if (e.type == "getCashOut") {
                    outs.push(e)
                    available_sum -= e.sum;
                }
            })
            return {
                ...state,
                support_user_cash_ins: JSON.parse(JSON.stringify(ins)),
                support_user_cash_outs: JSON.parse(JSON.stringify(outs)),
                support_user_available_sum: available_sum
            }
        }
        default:
            return state;
    }
};

const SetFullInfo = (data) => ({type: REFERRAL_SET_FULL_INFO, data})
const SetSupportUserCashInfo = (data) => ({type: REFERRAL_SUPPORT_SET_USER_CASH_INFO, data});
const SupportSetUsers = (data) => ({type: REFERRAL_SUPPORT_SET_USERS, data})
const SupportEditUser = (data, subType, userId) => ({type: REFERRAL_SUPPORT_EDIT_USER, data, subType, userId})

export const SupportChangeUserInvites = (userId, newValue) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        REF_API.supportChangeUserInvites(userId, newValue)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение списка приглашенных пользователя", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportEditUser(data, "invites", userId))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const SupportGetUsers = () => {
    return (dispatch) => {
        REF_API.supportGetUsers()
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение списка пользователей", data.message))
                    return
                }
                dispatch(SupportSetUsers(data))
            })
    }
}
export const SupportGetUserCashInfo = (user_id) => {
    return (dispatch) => {
        REF_API.supportGetCashIns(user_id)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Поиск данных по пользователю", data.message))
                    return
                }
                dispatch(SetSupportUserCashInfo(data))
            })
    }
}

export const GetFullInfo = (file) => {
    return (dispatch) => {
        REF_API.getFullInfo()
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    window.alert(data.message)
                    return
                }
                dispatch(SetFullInfo(data))
            })
    }
}

const RefCreateNewRequest = (value, request) => ({type: REFERRAL_CREATE_NEW_REQUEST, value, request})

export const RefSendRequest = (value, cardInfo) => {
    return (dispatch) => {
        let body = {
            sum: value,
            msg: cardInfo
        }
        REF_API.sendWithdrawRequest(body).then(data => {
            if (data && data.resultCode && data.resultCode != 1) {
                window.alert(data.message)
                return
            }
            dispatch(RefCreateNewRequest(value, data))
        })
    }
}

export const SupportFixData = () => {
    return (dispatch) => {
        REF_API.supportFixData().then(data => {
            if (data.resultCode && data.resultCode != 1) {
                dispatch(AddNotification("error", "Фиксация данных", data.message))
                return
            }
            dispatch(AddNotification("success", "Фиксация данных", data.message))
        })
    }
}
export default referralReducer;