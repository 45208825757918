import React from 'react';
import s from './styles.module.css'

const Item = ({img,title,subtitle,color}) => {
    return (
        <div className={s.item}>
            <div>
                <img src={img} alt=""/>
            </div>
            <div className={s.text}>
                <span>{title}</span>
                <p style={{color:color}}>{subtitle}</p>
            </div>
        </div>
    );
};

export default Item;