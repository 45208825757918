import React, {useState} from 'react';
import s from './styles.module.css'
import Row from "./row";
import ActionTable from "../action";

function getPercent (value, standards) {
    if (!standards || (!standards[0] && !standards[1])) standards = [0,0];

    if (isNaN(+value)) value = 0;

    if (!standards[0])

    if (isNaN(+standards[0])) standards[0] = 0;
    if (isNaN(+standards[1])) standards[1] = 0;

    if (standards[0].length <= 0) standards[0] = 0;
    if (standards[1].length <= 0) standards[1] = 0;

    // Вычисляем разницу между верхней и нижней границей эталона
    let etalon_gape = +standards[1] - +standards[0];

    // для каждого значения etalon_gape определяем коэффициент для дальнейших расчетов
    let keff = 0;
    if (etalon_gape > 0 && etalon_gape <= 3) {
        keff = 13;
    } else if (etalon_gape > 3 && etalon_gape <= 5) {
        keff = 8;
    } else if (etalon_gape > 5 && etalon_gape <= 10) {
        keff = 5;
    } else if (etalon_gape > 10) {
        keff = 3;
    }

    // Далее каждый процент расхождения умножаем на этот коэфф и тем самым получаем %попадания
    let diff = 0;
    if (+value < +standards[0]) {
        diff = +standards[0] - +value;
    }
    else if (+value > +standards[1]) {
        diff = +value - +standards[1];
    }

    //  попадание = 100 - расхождение*КОЭФФ;
    //      ЕСЛИ попадание < 0 то попадание = 0;
    let hit = 100 - diff * keff;
    if (hit < 0) hit = 0;

    return +hit;
}

function getColorPercent (value) {
    let color = '#7FFF00';

    if (+value <= 90) color = '#FFFF00';
    if (+value <= 80) color = '#FF4500';

    return color;
}

const Table = ({saves, schema}) => {
    const [typeSaves, setTypeSaves] = useState('player_info')

    let schema_type = typeSaves == "player_info" ? "schema_pre_flop" : "schema_post_flop";

    const saves_data = saves[0]?.data[typeSaves]
    const schema_data = {}
    const schema_keys = Object.keys(schema[schema_type])
    const players = saves.map((name) => name.username)

    for (let i = 0; i < schema_keys.length; i++) {
        schema_data[schema_keys[i]] = schema[schema_type][schema_keys[i]].filter(f => f['is_visible'])
    }

    const newData = {}

    for (let i = 0; i < players.length; i++) {
        const data = {}
        const findUserData1 = saves?.find(f => f.username === players[i])

        for (let j = 0; j < schema_keys.length; j++) {
            const key = schema_keys[j]
            const currentKey1 = schema[schema_type][key]

            const hz = currentKey1?.map((key_s) => {
                    const item = findUserData1.data[typeSaves] && findUserData1.data[typeSaves][key] && findUserData1.data[typeSaves][key]?.find((f) => f.name === key_s.name) ? findUserData1.data[typeSaves][key]?.find((f) => f.name === key_s.name) : null

                    if (!item) return

                    const standart_filter = schema[schema_type][key].find(f => f.name === item.name)?.standards
                    const value = getPercent(item?.value, standart_filter)

                    if (!standart_filter) return
                    return {
                        ...key_s,
                        standards: standart_filter,
                        username: players[i],
                        tooltip_value: `wow`,
                        initial_value: item.value,
                        value: value,
                        isNegative: value === 0 ? 0 : (value > 0 ? false : value)
                    }
                }
            )
            data[key] = hz?.filter(f => f)
        }


        let totalCount = {count: 0, length: 0, hard_percent: 0};

        for (let y = 0; y < Object.keys(data).length; y++) {
            const reduce = data[Object.keys(data)[y]]?.reduce((acc = 0, inc) => {
                if (!inc || !inc.is_visible || inc.value === 0) {
                    return acc
                }
                return acc = acc + inc?.value
            }, 0)

            const reduce_length = data[Object.keys(data)[y]]?.reduce((acc = 0, inc) => {
                if (inc.is_visible !== false) {
                    return acc = acc + 1
                } else {
                    return acc
                }
            }, 0)

            const reduce_hard_percent = data[Object.keys(data)[y]]?.reduce((acc = 0, inc) => {
                if (inc.is_visible !== false && !isNaN(inc.initial_value)) {
                    let result = 0;

                    if (inc.standards && !isNaN(+inc.standards[0]) && !isNaN(+inc.standards[1])) {
                        if (+inc.initial_value >= +inc.standards[0] && +inc.initial_value <= +inc.standards[1]) {
                            result = 1;
                        }
                    }

                    return acc = acc + result
                } else {
                    return acc
                }
            }, 0)

            totalCount = {
                count: totalCount.count + reduce,
                length: totalCount.length + reduce_length,
                hard_percent: totalCount.hard_percent + reduce_hard_percent
            }
        }

        newData[players[i]] = {...newData[players[i]], data, total: totalCount.count, length: totalCount.length, hard_percent: totalCount.hard_percent}
    }


    const getAvgCategiry = (key) => {
        let avg = []
        for (let i = 0; i < schema_data[key].length; i++) {
            let length = players.length

            const avg1 = players.reduce((acc = 0, inc) => {
                const find_data_by_userName = newData[inc].data[key]
                const find_current_name = find_data_by_userName.find((f) => f.name === schema_data[key][i].name)
                // if (find_current_name?.value === 0) {
                //     length = length - 1
                // }

                return acc = acc + (find_current_name?.value || 0)
            }, 0)
            const avg_complete = avg1 / length
            avg = [...avg, avg_complete]
        }
        const filtered_zero = avg.filter(f => !isNaN(f))
        const result = filtered_zero.reduce((acc, inc) => acc = +acc + +inc, 0)
        return (result / filtered_zero.length).toFixed(2)
    }





    return (
        <>
            <ActionTable changeTypeSave={(type) => setTypeSaves(type)}/>

            <div className={s.content}>
                <div style={{overflow: 'auto'}}>
                    <div className={s.header}>
                        <Row min={200} max={200}></Row>
                        <Row min={150} max={150}></Row>
                        <Row min={120} max={120} type={"avg"}>Среднее</Row>
                        <Row max={'100%'}>Игрок</Row>
                    </div>

                    {Object.keys(newData).map((key, i) => {
                        const avg = Object.keys(newData).reduce((acc = 0, inc) => {
                            if (newData[inc].total === 0) {
                                return acc
                            } else {
                                return acc = acc + (newData[inc].total / newData[inc].length)
                            }
                        }, 0)

                        const length = Object.keys(newData).filter(f => newData[f].total !== 0)

                        return <div style={{display: i === 0 ? 'flex' : 'none'}} key={i}>
                            <div>
                                <br/>
                                <Row min={200} max={200} isCategory={true} align={'left'}>{null}</Row>
                                <br/>
                            </div>

                            <div>
                                <br/>
                                <Row min={150} max={150} isCategory={true} align={'left'}>{null}</Row>
                                <br/>
                            </div>

                            <div>
                                <br/>
                                <Row min={120} max={120} type={"avg"} isCategory={true} align={'left'}>{null}</Row>
                                <br/>
                                <div>
                                    <Row type={"avg"}
                                        align={'left'}>{isNaN(avg / length.length) ? 0 : (avg / length.length).toFixed(2)}</Row>
                                </div>
                            </div>

                            <div>
                                <br/>
                                <div className={s.players}>
                                    {players.map((name, index) => {

                                            return (
                                                <div className={s.test} key={`players${index}`}>
                                                    <Row min={120} max={120} isCategory={true}
                                                         align={'center'}>{name}</Row>
                                                    <br/>
                                                    <Row
                                                        align={'left'}>{isNaN(newData[name].total / newData[name].length) ? 0 : (newData[name].total / newData[name].length).toFixed(2)}
                                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                                        {((newData[name].hard_percent / newData[name].length) * 100).toFixed(2)}</Row>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                                <br/>
                            </div>
                        </div>
                    })}
                </div>
                <br/>
                <div style={{overflow: 'auto'}}>
                    <div className={s.header}>
                        <Row min={200} max={200}>Стат</Row>
                        <Row min={150} max={150}>Эталон</Row>
                        <Row min={120} max={120} type={"avg"}>Среднее</Row>
                        <Row max={'100%'}>Игрок</Row>
                    </div>

                    {schema_keys?.map((key, i) => {
                        return <div key={`main_content${i}`} style={{
                            display: (!saves_data[key]) && 'none'
                        }}>
                            {schema_data[key]?.length === 0 ? null :
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <br/>
                                        <Row min={200} max={200} isCategory={true} align={'left'}>{key}</Row>
                                        <br/>
                                        <div>
                                            {!saves_data[key] ? null : saves_data[key].map((sub_stat, index) => {
                                                return <Row
                                                    hidden={!Boolean(schema_data[key].find(f => f.name === sub_stat.name))}
                                                    key={`sub_state${index}`} align={'left'}>{sub_stat.name}</Row>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <br/>
                                        <Row min={150} max={150} isCategory={true} align={'left'}>{null}</Row>
                                        <br/>
                                        <div>
                                            {schema_data[key].map((schema_Value, index) => {
                                                const schema_values = schema_Value.standards

                                                return <Row
                                                    key={`schema_Value${index}`}
                                                    align={'center'}>
                                                    {`${schema_values[0]} - ${schema_values[1]}`}
                                                </Row>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <br/>

                                        <Row min={120} max={120} isCategory={true} type={"avg"}
                                             align={'left'}
                                             style={{}}>{isNaN(getAvgCategiry(key)) ? null : getAvgCategiry(key)}</Row>
                                        <br/>
                                        <div>
                                            {!saves_data[key] ? null : saves_data[key].map((sub_stat, index) => {
                                                const schema_standart = schema_data[key].find(f => f.name === sub_stat.name)

                                                const avg = players.reduce((acc = 0, inc) => {
                                                    const find_data_by_userName = saves.find(f => f.username === inc)?.data[typeSaves][key]
                                                    const find_current_name = find_data_by_userName.find((f) => f.name === sub_stat.name)

                                                    return acc = acc + getPercent(find_current_name?.value, schema_standart?.standards)
                                                }, 0)

                                                const avg_complete = avg / players.length


                                                return <Row key={`avg${index}`} type={"avg"}
                                                    // style={{color: getColor(avg_complete.toFixed(2), (schema_standart?.standards || [0, 0]))}}
                                                            hidden={!Boolean(schema_data[key].find(f => f.name === sub_stat.name))}
                                                            align={'center'}>{avg_complete.toFixed(2)}</Row>
                                            })}
                                        </div>
                                    </div>
                                    <div>
                                        <br/>
                                        <div className={s.players}>
                                            {players.map((name, index) => {
                                                    const find_data_by_userName = saves.find(f => f.username === name)?.data[typeSaves][key]

                                                    return (
                                                        <div className={s.test} key={`players${index}`}>
                                                            <Row min={120} max={120} isCategory={true}
                                                                 align={'center'}>{name}</Row>
                                                            <br/>
                                                            {find_data_by_userName?.map((value, index) => {
                                                                const schema_standart = schema_data[key].find(f => f.name === value.name)

                                                                return <Row
                                                                    style={{color: getColorPercent(getPercent(value.value, schema_standart?.standards))}}
                                                                    hidden={!Boolean(schema_data[key].find(f => f.name === value.name))}
                                                                    key={`players_value_${index}`} min={120} max={120}
                                                                    title={value.value}
                                                                    align={'center'}>{getPercent(value.value, schema_standart?.standards).toFixed(2)}</Row>
                                                            })}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>

                                        <br/>
                                    </div>
                                </div>
                            }
                        </div>
                    })}
                </div>
            </div>
        </>
    );
};

export default Table;
