import React from 'react';

const ActionTable = ({changeTypeSave}) => {
    return (
        <div style={{margin: "30px 0", display: "grid", gridTemplateColumns: "1fr 100px 5px 100px 1fr"}}>
            <div></div>
            <div className={"default-back cursor-pointer"} onClick={() => changeTypeSave('player_info')}>PRE-FLOP</div>
            <div></div>
            <div className={"default-back cursor-pointer"} onClick={() => changeTypeSave('sub_player_info')}>POST-FLOP</div>
            <div></div>
        </div>
    );
};

export default ActionTable;
