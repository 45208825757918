import React, {useState, useRef} from "react";
// import JoditEditor from "jodit-react";
import Popup from "reactjs-popup";
import MainLoading from "../common/mainLoading";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function WikiCreator(props) {
    const editor = useRef(null);


    let modules = {
        'toolbar': [
            [{ 'font': [] }, { 'size': [] }],
            [ 'bold', 'italic', 'underline', 'strike' ],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'super' }, { 'script': 'sub' }],
            [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
            [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
            [ 'direction', { 'align': [] }],
            [ 'link', 'image', 'video', 'formula' ],
            [ 'clean' ]
        ]
    };

    let formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color', 'background',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    const [content, setContent] = useState(
        props.WikiInfo.currentPage && props.WikiInfo.currentPage.content && props.type == 1 ?
            props.WikiInfo.currentPage.content : "");
    const [name, setName] = useState(props.name ? props.name : null);
    const [tags, setTags] = useState(props.tags ? props.tags : null);
    const [isPreview, setPreview] = useState(props.type == 1 ? true : false);

    let createWikiCategory = React.createRef();

    const config = {
        readonly: false,
        height: 700,
        minWidth: 1200
    };

    const SendWiki = async () => {
        let type = props.type == 1 ? 1 : 2;

        props.SaveWiki(props.AuthInfo.accessToken, name, content, tags,
            props.AuthInfo.email, props.AuthInfo.id, props.AuthInfo.username, type, props.match.params.id, props.history)

    }

    const Remove = () => {
        props.Remove(props.id, props.history)
    }

    let rolesAccesses = [];

    if (props.type == 1) {
        props.RolesInfo.rolesList.forEach(role => {
            let isRoleFound = false;
            props.rolesAccesses.forEach(roleA => {
                if (role.name == roleA.name) isRoleFound = true
            })
            isRoleFound ? rolesAccesses.push({name: role.name, isAccessed: true}) : rolesAccesses.push({
                name: role.name,
                isAccessed: false
            })
        })
    }

    let ChangeCategoryName = (e) => {
        if (e && e.target && e.target.accessKey) {
            props.ChangeCategory(e.target.accessKey, props.id)
        } else {
            props.ChangeCategory(createWikiCategory.current.value, props.id)
        }
    };

    return (
        props.isLoading ? <MainLoading/>
            :
            <div className={"wiki-wrapper"} style={{textAlign: "initial"}}>
                <div className="wiki-creator wiki-page">

                    {isPreview ?
                        <div>
                            <div className={"wikiPreviewDescription"}>
                                <div>
                                    Название: {name}
                                </div>
                                <div>
                                    Теги: {tags}
                                </div>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: content}}/>
                        </div>
                        :
                        <div>
                            <input defaultValue={name} className={"name"}
                                   placeholder={"Название статьи"}
                                   title={"Название статьи"}
                                   onBlur={e => setName(e.target.value)}/>
                            <input defaultValue={tags} className={"tags"}
                                   placeholder={"Тэги для статьи (через пробел)"}
                                   title={"Тэги для статьи (через пробел)"}
                                   onBlur={e => setTags(e.target.value)}/>

                            <div className={"editor"} onMouseLeave={null}>
                                {/*<CKEditor*/}
                                {/*    editor={ ClassicEditor }*/}
                                {/*    data={content}*/}
                                {/*    onChange={ ( event, editor ) => {*/}
                                {/*        const data = editor.getData();*/}
                                {/*        setContent(data)*/}
                                {/*    } }*/}
                                {/*/>*/}
                                <ReactQuill theme="snow" value={content} onChange={setContent} modules={modules} formats={formats}/>
                                {/*<JoditEditor*/}
                                {/*    ref={editor}*/}
                                {/*    value={content}*/}
                                {/*    config={config}*/}
                                {/*    onBlur={newContent => setContent(newContent)}*/}
                                {/*    onChange={(newContent) => {*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    }

                    {
                        props.type == 1 ?
                            <button onClick={Remove} className={"save transition red-back"}>Удалить статью</button>
                            : null
                    }

                    {
                        props.type == 1 && !isPreview ?
                            <Popup key={props.category}
                                   trigger={<button
                                       className={"save transition blue-back"}>Категория: {props.category ? props.category : "none"}</button>}
                                   modal
                                   nested
                            >
                                {() => (
                                    <div>
                                        <div id="get-advice" className="creation-page-category-selector-popup">
                                            <div className={"subCategoryCreator"}>
                                                <input type="text" ref={createWikiCategory}
                                                       placeholder="Если нужно ввести вручную"/>
                                                <button onClick={ChangeCategoryName}
                                                        className={"subCategoryCreatorButton"}>
                                                    Применить
                                                </button>
                                            </div>

                                            {
                                                props.categories.map((category, i) => {
                                                    return (
                                                        <button key={category + " " + i}
                                                                accessKey={category}
                                                                onClick={ChangeCategoryName}
                                                                className={"send-btn"}>{category}</button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )}
                            </Popup>
                            : null
                    }

                    {
                        props.type == 1 && !isPreview ?
                            <Popup key={"wikiAccessesEditor"}
                                   trigger={
                                       <button className={"save transition blue-back"}>Доступ для ролей</button>}
                                   modal
                                   nested
                            >
                                {() => (
                                    <div>
                                        <div id="get-advice" className="user-page-popup">
                                            <form className="form">
                                                {rolesAccesses.map((role, i) => <RoleAccessBlock
                                                    key={"wra" + role.name} role={role}
                                                    AddRoleAccess={props.AddRoleAccess}
                                                    RemoveRoleAccess={props.RemoveRoleAccess} AuthInfo={props.AuthInfo}
                                                    wiki_id={props.id}/>)}
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </Popup> : null
                    }

                    {
                        isPreview ?
                            <button onClick={() => setPreview(false)}
                                    className={"save transition orange-back"}>Редактирование</button>
                            : <button onClick={() => setPreview(true)}
                                      className={"save transition orange-back"}>Предпросмотр</button>
                    }

                    {
                        isPreview ?
                            null
                            : <button onClick={SendWiki} className={"save transition green-back"}>{props.type == 1 ?
                                "Сохранить" : "Создать статью"}</button>
                    }

                </div>
            </div>
    );
}

const RoleAccessBlock = (props) => {
    let AddRoleAccess = (e) => {
        props.AddRoleAccess(props.wiki_id, props.role.name)
    }
    let RemoveRoleAccess = (e) => {
        props.RemoveRoleAccess(props.wiki_id, props.role.name)
    }

    return (
        props.role.isAccessed ? <div onClick={RemoveRoleAccess}
                                     className={"role-page-access-block role-page-access-block-accessed"}>{props.role.name}</div>
            : <div onClick={AddRoleAccess}
                   className={"role-page-access-block role-page-access-block-denied"}>{props.role.name}</div>
    )
}