import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import Main from "./index";
import {
    GetFinResultsPageInfo, GetFinResultsRowsByPeriod,
    GetTransfersPageInfo, GetTransfersRowsByPeriod, PlayerTransferChangeStatus
} from "../../../redux/reducers/mainReducer";

class TransfersContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.GetTransfersPageInfo()
        // this.props.GetFinResultsPageInfo()
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <Main {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer
})

export default compose(
    connect(mapStateToProps, {
        GetTransfersPageInfo, GetTransfersRowsByPeriod, PlayerTransferChangeStatus, GetFinResultsPageInfo,
        GetFinResultsRowsByPeriod
    }),
    withRouter
)(TransfersContainer)