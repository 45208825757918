import React, {useState} from 'react';
import Container from "../container";
import s from './styles.module.css'
import classNames from "classnames";
import {useWindowSize} from "../../../../hooks/useWindowSize";
import Popup from "reactjs-popup";
import MainLoading from "../../../../../v_1/components/common/mainLoading";
import OvalLoading from "../../../../assests/oval_load.svg";

const ThirdContent = (props) => {
    const {width, height} = useWindowSize()

    return (
        !props.MainInfo.isLoading ?
            <Container top='35px' overflow={false}>
                <div className={s.coaches_list_title}>
                    Для отправки запроса на тренировку выберите тренера
                </div>
                {
                    props.TrainingInfo.userCoaches[0] ?
                        <div className={s.coaches_list_btns}>
                            {props.TrainingInfo.userCoaches.map(coach => {
                                return <CoachRow key={`coaches-info ${coach.coach_name} ${coach.direction}`}
                                                 CoachSetChangerPersonalSlotRequestStep={props.CoachSetChangerPersonalSlotRequestStep}
                                                 isLoading={props.isLoading}
                                                 trainingList={props.TrainingInfo.trainingList}
                                                 step={props.TrainingInfo.coachStep}
                                                 coach={coach} UserSendPersonalRequest={props.UserSendPersonalRequest}/>
                            })}
                        </div>
                        :
                        <div style={{color: "#d7d7d7"}}>
                            Свободные тренеры для записи не найдены<br/>
                        </div>
                }
            </Container>
            :
            <Popup key={"request_loading_fs_changer"}
                   defaultOpen={true}
                   modal
                   nested
            >
                {() => (
                    <div>
                        <div id="get-advice">
                            <form className="form">
                                <div className={s.formgroup}>
                                    <img src={OvalLoading} alt="loading" style={{width: "90px"}}/>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Popup>
    );
};


const CoachRow = (props) => {
    const MONTHS = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
    const DATE = new Date();
    const DAY = DATE.getDate();
    const MONTH = DATE.getMonth();
    const YEAR = DATE.getFullYear();

    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(MONTH);
    const [selectedYear, setSelectedYear] = useState(YEAR);

    const [selectedDayTimestamp, setSelectedDayTimestamp] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedTimestamp, setSelectedTimestamp] = useState(null);
    const [topic, setTopic] = useState(null);
    const [description, setDescription] = useState(null);

    const [isSend, setIsSend] = useState("11");

    const handleSelectDay = (day, timestamp) => {
        props.CoachSetChangerPersonalSlotRequestStep(1, [])
        setTopic(null);
        setDescription(null);
        setSelectedTime(null);
        setSelectedTimestamp(null);
        setSelectedDay(day);
        setSelectedDayTimestamp(timestamp);
    }
    const handleSelectTime = (time, timestamp) => {
        props.CoachSetChangerPersonalSlotRequestStep(1, [])
        setTopic(null);
        setDescription(null);
        setSelectedTime(time);
        setSelectedTimestamp(timestamp);
    }
    const handleChangeTopic = (ttopic) => {
        props.CoachSetChangerPersonalSlotRequestStep(1, [])
        setTopic(ttopic)
    }
    const handleChangeDescription = (ddescription) => {
        props.CoachSetChangerPersonalSlotRequestStep(1, [])
        setDescription(ddescription)
    }
    const handleChangeIsSend = (close) => {
        // setIsSend("22")
        setTopic(null);
        setDescription(null);
        setSelectedTime(null);
        setSelectedTimestamp(null);

        setSelectedDay(null);
        setSelectedDayTimestamp(null);
        props.UserSendPersonalRequest(props.coach.coach_id, selectedDayTimestamp, selectedTimestamp, topic, description, props.coach.direction, props.coach.coach_name)
        close()
    }

    const handleChangeMonth = (action) => {
        if (action == "prev") {
            if (+selectedMonth == 0) {
                setSelectedYear(+selectedYear - 1)
                setSelectedMonth(11)

            } else {
                setSelectedMonth(+selectedMonth - 1)
            }
        }
        if (action == "next") {
            if (+selectedMonth == 11) {
                setSelectedYear(+selectedYear + 1)
                setSelectedMonth(0)
            } else {
                setSelectedMonth(+selectedMonth + 1)
            }
        }

        setSelectedDay(null);
        setTopic(null);
        setDescription(null);
        setSelectedTime(null);
        setSelectedTimestamp(null);
    }

    return (
        <Popup key={`coaches-info-popup ${props.coach.coach_name} ${props.coach.direction} ${props.isLoading}`}
               trigger={
                   <div className={s.coaches_list_btn}>
                       {props.coach.coach_name} ({props.coach.direction})
                   </div>
               }
               modal
               nested
        >
            {(close) => (
                <div>
                    <div id="get-advice" className="main-popup user-request-personal-training">
                        <form className="form">
                            <div className={"calendar"}>
                                <div className={"name"} style={{textAlign: "center", fontSize: "19px"}}>
                                    <span>Запись к тренеру {props.coach.coach_name} ({props.coach.direction})</span>
                                </div>
                                <div className={"name"} style={{textAlign: "center"}}>
                                    <div style={{textAlign: "center", fontSize: "15px", color: "#537dc9", margin: "-15px 0 2px 0"}}>{MONTHS[selectedMonth]} {selectedYear} г.</div>
                                    <span onClick={() => handleChangeMonth("prev")}
                                          style={{cursor: "pointer"}}>◀️</span>
                                    <span onClick={() => handleChangeMonth("next")}
                                          style={{marginLeft: "5px",cursor: "pointer"}}>▶️</span>


                                </div>
                                {
                                    props.coach.coach_days && props.coach.coach_days.findIndex(e => e.month == `${selectedMonth + 1}-${selectedYear}`) != -1?
                                        <div className={"name"} style={{margin: "30px 0 9px 0"}}>
                                            Выберите желаемый день
                                        </div>
                                        : null
                                }
                                <div className={"content"}>
                                    {
                                        props.coach.coach_days.map((day, i) => {
                                            if (day.month != `${selectedMonth + 1}-${selectedYear}`) return null
                                            return <div className={`item ${selectedDay == day.day ? "active" : null}`}
                                                        onClick={() => handleSelectDay(day.day, day.timestamp_day)}
                                                        key={`coach-calc ${day.day} ${i}`}>
                                                <span>{day.day}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            {
                                selectedDay && <div className={"calendar"} style={{marginTop: "15px"}}>

                                    <div className={"name"} style={{margin: "30px 0 9px 0"}}>
                                        Выберите время
                                    </div>
                                    <div className={"content"}>
                                        {
                                            props.coach.coach_days.map((day, i) => {
                                                if (day.day == selectedDay) {
                                                    return day.times.map(time => {
                                                        return <div
                                                            className={`item ${selectedTimestamp == time.timestamp ? "active" : null}`}
                                                            onClick={() => handleSelectTime(time.time, time.timestamp)}
                                                            key={`coach-calcl ${props.coach.coach_name} ${props.coach.direction} ${time.time} ${i}`}>
                                                            <span>{time.time}</span>
                                                        </div>
                                                    })
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }


                            {
                                selectedDay && selectedTime && <div className={"calendar"} style={{marginTop: "15px"}}>
                                    <div className={"name"} style={{margin: "30px 0 9px 0"}}>
                                        Введите кратко название темы к обучению
                                    </div>
                                    <div className={"content"}>
                                        <input onChange={(e) => handleChangeTopic(e.target.value)}/>
                                    </div>
                                </div>
                            }


                            {
                                selectedDay && selectedTime && <div className={"calendar"} style={{marginTop: "25px"}}>
                                    <div className={"name"} style={{margin: "30px 0 9px 0"}}>
                                        Опишите подробно, что хотите изучить
                                    </div>
                                    <div className={"content"}>
                                        <textarea rows={10} onChange={(e) => handleChangeDescription(e.target.value)}/>
                                    </div>
                                </div>
                            }

                            {
                                props.step == 2 ? props.trainingList[0] ?
                                        <div style={{color: "#dc4242", marginTop: "20px", fontWeight: "bolder"}}>
                                            Вы не можете отправить запрос на выбранное время, у вас есть пересечение по
                                            времени с другими тренировками:<br/>
                                            {
                                                props.trainingList.map((e, iii) => {
                                                    return <div
                                                        style={{color: "#ea5a5a", fontWeight: "normal", marginTop: "3px"}}>
                                                        {iii + 1}. {e}
                                                    </div>
                                                })
                                            }
                                        </div>
                                        : <div>Неизвестный конфликт в создании запроса на это время. Попросите своего
                                            руководителя связаться с разработчиком</div>
                                    : null
                            }

                            {
                                selectedDay && selectedTime && props.step == 1 &&
                                <div onClick={() => handleChangeIsSend(close)}
                                     className={"default-button green-back transition"}
                                     style={{marginTop: "30px", padding: "15px 0"}}>
                                    Отправить запрос
                                </div>
                            }
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}

export default ThirdContent;