import React from 'react';
import s from './styles.module.css'
import {ReactComponent as Notepad} from "../../assests/notepad.svg";
import {useWindowSize} from "../../hooks/useWindowSize";

const NotepadMain = (props) => {

    const {width, height} = useWindowSize()
    return (
        <div className={s.notepad}>

            {!props.hiddenRight && <>
                <div className={s.notepad_header}>
                    <Notepad/>
                    <p className={s.right_p}>Блокнот</p>
                </div>

                <div>
                    <textarea onBlur={(e) => props.V_2_ChangeNotes(e.target.value)}
                              style={{height: `${props.height}px`}} defaultValue={props.AuthInfo.notes}
                              className={s.area} placeholder={'Введите любой текст'}/>
                </div>
            </>}

            {props.hiddenRight && <div className={s.hidden_first}>
                <Notepad/>
                {
                    width < 900 &&
                    <textarea onBlur={(e) => props.V_2_ChangeNotes(e.target.value)} style={{height: `${props.height}px`}}
                              defaultValue={props.AuthInfo.notes} className={s.area}
                              placeholder={'Введите любой текст'}/>
                }
            </div>}

        </div>
    );
};

export default NotepadMain;