import React from 'react';
import s from './styles.module.css'

const Container = ({children, top, overflow = true}) => {

    return (
        <div className={s.container} style={{
            marginTop: top,
            overflow: overflow ? 'hidden' : 'visible'
        }}>
            {children}
        </div>
    );
};

export default Container;