import React from 'react';

const RequisitesCreator = (props) => {
    const handleCreate = () => {
        props.CreateRequisites(props.description)
    }

    return (
        <div style={{color: "white", backgroundColor: "#1088f7", textAlign: "center", padding: "5px", borderRadius: "10px", marginTop: "5px", cursor: "pointer"}} onClick={handleCreate}>
            Сохранить реквизиты
        </div>
    );
};

export default RequisitesCreator;