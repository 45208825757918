import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Container from "../container";
import {ReactComponent as Arrow} from "../../../assests/arrow_bottom_text.svg";
import {useWindowSize} from "../../../hooks/useWindowSize";
import classNames from "classnames";

const SecondContent = () => {
    const {width, height} = useWindowSize()

    const mobilePoint = width > 470

    const [showMore, setShowMore] = useState(false)

    useEffect(() => {
        if (mobilePoint) {
            setShowMore(true)
        } else {
            setShowMore(false)
        }
    }, [width])
    return (
        <Container top='35px'>
            <h3 className={s.h3}>Новости и объявления</h3>
            <p className={s.p}>
                Всем привет! С вами команда Firestorm и вы находитесь в своём личном кабинете!

            </p>
            {(showMore) && <>
                <p className={s.p}> Данный кабинет разработан нами, чтобы улучшить опыт от нахождения в команде для каждого игрока. Мы планируем постоянно дорабатывать и улучшать его, чтобы сделать единое окно", где в одном месте соберем всю нужную информацию и все наши сервисы для игроков.
                </p>
                <p className={s.p}>
                    Сейчас личный кабинет только встает на свой путь развития, и мы просим вас давать нам обратную связь по работе с ним, и отнестись с пониманием, если что-то где-то будет работать не так как планировалось.
                </p>
                <p className={s.p}>
                    Давайте вместе работать над улучшением кабинета, чтобы каждый игрок мог насладиться комфортом при работе в команде!
                </p> </>}


            {!mobilePoint &&
                <div className={classNames(s.arrow, showMore && s.rotate)} onClick={() => setShowMore(!showMore)}>
                    <Arrow/>
                </div>}
        </Container>
    );
};

export default SecondContent;