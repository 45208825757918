
import React from 'react';
import s from './styles.module.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const data = [
    {
        title: 'Сколько времени займет обработка заявки?',
        subTitle: 'Обмены происходят в рабочее время с 09:00 по 21:00 МСК. Ваша заявка будет обработана в течении 12 часов.'
    },
    {
        title: 'Какая комиссия на обмен?',
        subTitle: 'Фиксированной комиссии на обмен нет. Мы ежедневно будем отслеживать ситуацию на рынке, и в зависимости от неё отображать информацию о примерных суммах к получению в разделе дополнительной информации в блоке выше.'
    },
    {
        title: 'Есть ли риск потерять деньги?',
        subTitle: 'Риск сведен к минимуму. Если правильно заполнить все реквизиты, то шанс на успех 99%.'
    },
]
const AboutExchanger = () => {
    return (
        <div className={s.about}>
            <h3 className={s.main_title}>Самые частые вопросы и ответы (FAQ)</h3>
            {data.map((el, index) => <div key={`accord ${index}`} className={s.accrodion_item}>
                <Accordion sx={{
                    // "& .MuiPaper-root": {
                    backgroundColor: "rgba(0, 0, 0, 0) !important",
                    borderBottom: "2px solid #3F4156",
                    borderRadius: "0 !important",
                    padding: "0 0 !important",
                // }
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h3 className={s.title}>{el.title}</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className={s.subTitle}>
                            {el.subTitle}
                        </p>
                    </AccordionDetails>
                </Accordion>
            </div>)}

        </div>
    );
};

export default AboutExchanger;