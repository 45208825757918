import {FS_CHANGER_API, REF_API, SALARY_API} from "../../api/util";
import {ChangeMainLoading} from "./mainReducer";
import {AddNotification} from "./notificationsReducer";
import Cookies from "js-cookie";

const SALARY_SET_PROFILES = 'SALARY_SET_PROFILES';

const SALARY_SET_USERS = 'SALARY_SET_USERS';
const SALARY_SET_USER = 'SALARY_SET_USER';
const SALARY_DELETE_USER = 'SALARY_DELETE_USER';

const SALARY_SET_ROWS = 'SALARY_SET_ROWS';
const SALARY_SET_ROW = 'SALARY_SET_ROW';
const SALARY_DELETE_ROW = 'SALARY_DELETE_ROW';

const SALARY_CREATE_NEW_REQUEST = 'SALARY_CREATE_NEW_REQUEST';
const SALARY_SET_FULL_INFO = 'SALARY_SET_FULL_INFO';

const SALARY_ADMIN_DELETE_TICKET = 'SALARY_ADMIN_DELETE_TICKET';
const SALARY_SUPPORT_CHANGE_TICKET_STATUS = 'SALARY_SUPPORT_CHANGE_TICKET_STATUS';
const SALARY_SUPPORT_SET_TICKETS_LIST = 'SALARY_SUPPORT_SET_TICKETS_LIST';
const SALARY_SUPPORT_CHANGE_STATUSES_FILTER = 'SALARY_SUPPORT_CHANGE_STATUSES_FILTER';
const SALARY_SUPPORT_CHANGE_TYPES_FILTER = 'SALARY_SUPPORT_CHANGE_TYPES_FILTER';
const SALARY_SUPPORT_CHANGE_DIRECTIONS_FILTER = 'SALARY_SUPPORT_CHANGE_DIRECTIONS_FILTER';
const SALARY_SUPPORT_CHANGE_USERS_DIRECTIONS_FILTER = 'SALARY_SUPPORT_CHANGE_USERS_DIRECTIONS_FILTER';
const SALARY_SUPPORT_CHANGE_ROWS_DIRECTIONS_FILTER = 'SALARY_SUPPORT_CHANGE_ROWS_DIRECTIONS_FILTER';
const SALARY_ADMIN_SET_USERS_STATE = 'SALARY_ADMIN_SET_USERS_STATE';


let getPeriods = () => {
    let years = 2;
    let startYear = 2023;
    let months = 12;

    let periods = [];

    for (let i = 0; i < years; i++) {
        for (let j = 1; j < months + 1; j++) {
            periods.push({name: `${j}-${startYear + i}`});
        }
    }

    return periods;
}

let initialState = {
    profiles: [],
    users: [],
    rows: [],
    full_info: {
        invited_players: [],
        cash_ins: [],
        cash_outs: []
    },

    supportTicketsList: [],
    supportTicketsCount: null,
    supportTicketsCurrentCount: null,
    types: ["question", "request"],
    statuses: ["new", "active"],
    directions: ['SPIN', 'MTT', 'CASH'],
    supportTicketsDirections: [],
    supportSelectedTicketsDirections: [],

    supportUsersDirections: [],
    supportSelectedUsersDirections: [],

    supportRowsDirections: [],
    supportSelectedRowsDirections: [],

    usersState: [],
    usersStatePeriods: getPeriods(),
    usersStateUsers: [],
    usersStateDirections: []
};

const salaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SALARY_ADMIN_SET_USERS_STATE: {
            const h = [...new Set(action.data.map(item => item.direction))];
            const directions = [];
            h.forEach(e => directions.push({direction: e}));

            const u = [...new Set(action.data.map(item => item.nickname))];
            const users = [];
            u.forEach(e => users.push({nickname: e}));

            return {
                ...state, usersStateUsers: users, usersStateDirections: directions,
                usersState: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SALARY_SUPPORT_CHANGE_STATUSES_FILTER: {
            if (state.statuses.includes(action.status)) {
                let statusIndex = state.statuses.findIndex(e => e == action.status)
                let newStatuses = [...state.statuses]
                newStatuses.splice(statusIndex, 1);
                return {
                    ...state,
                    statuses: [...newStatuses]
                }
            } else {
                return {
                    ...state,
                    statuses: [...state.statuses, action.status]
                }
            }
        }
        case SALARY_SUPPORT_CHANGE_TYPES_FILTER: {
            if (state.types.includes(action.ttype)) {
                let typeIndex = state.types.findIndex(e => e == action.ttype)
                let newTypes = [...state.types]
                newTypes.splice(typeIndex, 1);
                return {
                    ...state,
                    types: [...newTypes]
                }
            } else {
                return {
                    ...state,
                    types: [...state.types, action.ttype]
                }
            }
        }

        case SALARY_SUPPORT_CHANGE_USERS_DIRECTIONS_FILTER: {
            return {
                ...state,
                supportSelectedUsersDirections: JSON.parse(JSON.stringify(action.directions))
            }
        }
        case SALARY_SUPPORT_CHANGE_ROWS_DIRECTIONS_FILTER: {
            return {
                ...state,
                supportSelectedRowsDirections: JSON.parse(JSON.stringify(action.directions))
            }
        }
        case SALARY_SUPPORT_CHANGE_DIRECTIONS_FILTER: {
            return {
                ...state,
                supportSelectedTicketsDirections: JSON.parse(JSON.stringify(action.directions))
            }
        }
        case SALARY_ADMIN_DELETE_TICKET: {
            const ticketIndex = state.supportTicketsList.findIndex(e => e.timestamp_creation == action.timestamp_creation);
            if (ticketIndex != -1) state.supportTicketsList.splice(ticketIndex, 1);

            return {
                ...state,
                supportTicketsList: JSON.parse(JSON.stringify(state.supportTicketsList))
            }
        }
        case SALARY_SUPPORT_CHANGE_TICKET_STATUS: {
            const ticketIndex = state.supportTicketsList.findIndex(e => e.timestamp_creation == action.timestamp_creation);
            if (ticketIndex != -1) state.supportTicketsList[ticketIndex] = JSON.parse(JSON.stringify(action.ticket))

            return {
                ...state,
                supportTicketsList: JSON.parse(JSON.stringify(state.supportTicketsList))
            }
        }
        case SALARY_SUPPORT_SET_TICKETS_LIST: {
            if (action.changeType == "set") {
                const h = [...new Set(action.list.map(item => item.direction))];
                const directions = [];
                h.forEach(e => directions.push({direction: e}));

                return {
                    ...state,
                    supportTicketsList: [...action.list],
                    supportTicketsCount: action.count,
                    supportTicketsCurrentCount: 50,
                    supportTicketsDirections: directions
                }
            } else if (action.changeType == "new") {
                const h = [...new Set(action.list.map(item => item.direction))];
                const directions = [];
                h.forEach(e => directions.push({direction: e}));
                return {
                    ...state,
                    supportTicketsList: [...action.list],
                    supportTicketsCount: action.count,
                    supportTicketsCurrentCount: action.list.length,
                    supportTicketsDirections: directions
                }
            } else {
                const h = [...new Set([...state.supportTicketsList, ...action.list].map(item => item.direction))];
                const directions = [];
                h.forEach(e => directions.push({direction: e}));

                return {
                    ...state,
                    supportTicketsList: [...state.supportTicketsList, ...action.list],
                    supportTicketsCount: action.count,
                    supportTicketsCurrentCount: state.supportTicketsCurrentCount + 50,
                    supportTicketsDirections: directions
                }
            }
        }
        case SALARY_CREATE_NEW_REQUEST: {
            return {
                ...state,
                full_info: {
                    ...state.full_info,
                    available_sum: state.full_info.available_sum - action.value,
                    cash_outs: [action.request, ...state.full_info.cash_outs]
                }
            }
        }
        case SALARY_SET_FULL_INFO: {
            return {
                ...state,
                full_info: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SALARY_SET_PROFILES: {
            return {
                ...state,
                profiles: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SALARY_SET_USERS: {
            const h = [...new Set(action.data.map(item => item.direction))];
            const directions = [];
            h.forEach(e => directions.push({direction: e}));

            return {
                ...state,
                supportUsersDirections: directions,
                users: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SALARY_SET_USER: {
            return {
                ...state,
                users: [action.data, ...state.users]
            }
        }
        case SALARY_DELETE_USER: {
            let newUsersList = JSON.parse(JSON.stringify(state.users));
            let uIndex = newUsersList.findIndex(e => e._id == action._id);
            if (uIndex != -1) {
                newUsersList.splice(uIndex, 1);
                return {
                    ...state,
                    users: JSON.parse(JSON.stringify(newUsersList))
                }
            } else {
                return state;
            }
        }
        case SALARY_SET_ROWS: {
            const h = [...new Set(action.data.map(item => item.user_direction))];
            const directions = [];
            h.forEach(e => directions.push({user_direction: e}));

            return {
                ...state,
                supportRowsDirections: directions,
                rows: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SALARY_SET_ROW: {
            return {
                ...state,
                rows: [action.data, ...state.rows]
            }
        }
        case SALARY_DELETE_ROW: {
            let newRowsList = JSON.parse(JSON.stringify(state.rows));
            let rIndex = newRowsList.findIndex(e => e._id == action._id);
            if (rIndex != -1) {
                newRowsList.splice(rIndex, 1);
                return {
                    ...state,
                    rows: JSON.parse(JSON.stringify(newRowsList))
                }
            } else {
                return state;
            }
        }
        default:
            return state;
    }
};

const SalarySetProfiles = (data) => ({type: SALARY_SET_PROFILES, data});

const SalarySetUsers = (data) => ({type: SALARY_SET_USERS, data});
const SalarySetUser = (data) => ({type: SALARY_SET_USER, data})
const SalaryDeleteUser = (_id) => ({type: SALARY_DELETE_USER, _id});

const SalarySetRows = (data) => ({type: SALARY_SET_ROWS, data});
const SalarySetRow = (data) => ({type: SALARY_SET_ROW, data})
const SalaryDeleteRow = (_id) => ({type: SALARY_DELETE_ROW, _id})

const SetFullInfo = (data) => ({type: SALARY_SET_FULL_INFO, data})
const SalaryCreateNewRequest = (value, request) => ({type: SALARY_CREATE_NEW_REQUEST, value, request})
const SalaryAdminSetUsersState = (data) => ({type: SALARY_ADMIN_SET_USERS_STATE, data});


export const SalaryAdminGetUsersState = (period) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        SALARY_API.getUsersState(period)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение отчёта`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SalaryAdminSetUsersState(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SalaryGetProfiles = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        SALARY_API.getProfiles()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка профилей ЛК`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SalarySetProfiles(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SalaryGetUsers = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        SALARY_API.getUsers()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка пользователей`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SalarySetUsers(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SalaryAddUser = (nickname, id, division, direction) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            nickname, id, division, direction
        }
        SALARY_API.addUser(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Добавление пользователя`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SalarySetUser(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SalaryRemoveUser = (_id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        SALARY_API.removeUser(_id)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Удаление пользователя`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SalaryDeleteUser(_id))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SalaryGetRows = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        SALARY_API.getRows()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка начислений`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SalarySetRows(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SalaryAddRow = (period, user_nickname, user_id, user_division, user_direction,
                             cash_sum, asia_sum, spin_sum, mtt_sum, description, salary_type) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            period, user_nickname, user_division, user_direction, user_id,
            cash_sum, asia_sum, spin_sum, mtt_sum, description, salary_type
        }

        SALARY_API.addRow(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Добавление начисления`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SalarySetRow(data))
                dispatch(AddNotification("success", `Добавление начисления`, "Начисление добавлено"))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SalaryRemoveRow = (_id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        SALARY_API.removeRow(_id)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Удаление начисления`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SalaryDeleteRow(_id))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SalarySendRequest = (value, cardInfo) => {
    return (dispatch) => {
        let body = {
            sum: value,
            msg: cardInfo
        }
        SALARY_API.sendWithdrawRequest(body).then(data => {
            if (data && data.resultCode && data.resultCode != 1) {
                window.alert(data.message)
                return
            }
            dispatch(SalaryCreateNewRequest(value, data))
        })
    }
}

export const SalaryGetFullInfo = (file) => {
    return (dispatch) => {
        SALARY_API.getFullInfo()
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    window.alert(data.message)
                    return
                }
                // NotificationManager.info("Загрузка данных")
                // NotificationManager.success('Success message', 'Title here');
                // NotificationManager.warning('Warning message');
                // NotificationManager.error('Чёто не так 10 сек',null, 10000);
                // NotificationManager.error('Чёто не так 5 сек', 'Click me!', 5000);
                // NotificationManager.error('Чёто не так 2 сек', 'Click me!', 2000);
                dispatch(SetFullInfo(data))
            })
    }
}


const SupportSetTicketsList = (list, changeType, count) => ({
    type: SALARY_SUPPORT_SET_TICKETS_LIST,
    list,
    changeType,
    count
})
export const SupportGetTicketsList = (changeType, types, statuses, directions, skipElements, limitElements) => { //GetTTList
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            filters: {
                types,
                statuses, directions
            },
            skipElements,
            limitElements,
            token: Cookies.get('token')
        }
        SALARY_API.supportGetTickets(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка вопросов / запросов по обменам`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                if (changeType == 'set') {
                    dispatch(SupportSetTicketsList([], changeType, null))
                    dispatch(SupportSetTicketsList(data.tasks ? data.tasks : [], changeType, data.count ? data.count : null))
                } else {
                    dispatch(SupportSetTicketsList(data.tasks ? data.tasks : [], changeType, data.count ? data.count : null))
                }
                dispatch(ChangeMainLoading(false))
            })
    }
}


const SupportChangeTicketStatusAC = (timestamp_creation, ticket) => ({
    type: SALARY_SUPPORT_CHANGE_TICKET_STATUS,
    timestamp_creation,
    ticket
})
export const SupportChangeTicketStatus = (timestamp_creation, status) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            timestamp_creation, status,
            token: Cookies.get('token')
        }
        SALARY_API.supportChangeStatus(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение статуса`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportChangeTicketStatusAC(timestamp_creation, data));
                dispatch(AddNotification("success", `Изменение статуса`, "Статус изменен"))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SupportEditTicket = (timestamp_creation, type, newValue) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get('token'),
            edition_info: {
                timestamp_creation, type, newValue
            }
        }
        SALARY_API.supportEditTicket(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение тикета`, data.message))
                    return
                }
            })
    }
}

const AdminDeleteTicketAC = (timestamp_creation) => ({type: SALARY_ADMIN_DELETE_TICKET, timestamp_creation})
export const AdminDeleteTicket = (timestamp_creation) => {
    return (dispatch) => {
        SALARY_API.adminDeleteTicket(timestamp_creation)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Удаление тикета`, data.message))
                    return
                }
                dispatch(AdminDeleteTicketAC(timestamp_creation))
            })
    }
}

const SupportChangeStatusesFilterAC = (status) => ({type: SALARY_SUPPORT_CHANGE_STATUSES_FILTER, status}) //ChangeStatusesFilterAC
const SupportChangeTypesFilterAC = (ttype) => ({type: SALARY_SUPPORT_CHANGE_TYPES_FILTER, ttype}) //ChangeServersFilterAC
const SupportChangeDirectionsFilterAC = (directions) => ({type: SALARY_SUPPORT_CHANGE_DIRECTIONS_FILTER, directions})
export const SalarySupportChangeStatusesFilter = (status) => { //ChangeStatusesFilter
    return (dispatch) => {
        dispatch(SupportChangeStatusesFilterAC(status));
    }
}
export const SalarySupportChangeTypesFilter = (ttype) => { //ChangeServersFilter
    return (dispatch) => {
        dispatch(SupportChangeTypesFilterAC(ttype));
    }
}

export const SalarySupportChangeDirectionsFilter = (directions) => {
    return (dispatch) => {
        dispatch(SupportChangeDirectionsFilterAC(directions));
    }
}

const SupportChangeUsersDirectionsFilterAC = (directions) => ({type: SALARY_SUPPORT_CHANGE_USERS_DIRECTIONS_FILTER, directions})
const SupportChangeRowsDirectionsFilterAC = (directions) => ({type: SALARY_SUPPORT_CHANGE_ROWS_DIRECTIONS_FILTER, directions})

export const SupportChangeUsersDirectionsFilter = (directions) => {
    return (dispatch) => {
        dispatch(SupportChangeUsersDirectionsFilterAC(directions));
    }
}
export const SupportChangeRowsDirectionsFilter = (directions) => {
    return (dispatch) => {
        dispatch(SupportChangeRowsDirectionsFilterAC(directions));
    }
}

export default salaryReducer;