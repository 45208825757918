import React, {useEffect, useMemo, useRef, useState} from 'react';
import Select from "react-dropdown-select";
import Popup from "reactjs-popup";
import moment from "moment/moment";
import MainLoading from "../../common/mainLoading";
import {SupportMttTournamentsExportToGoogleTable} from "../../../../redux/reducers/supportReducer";

const GroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать группу"}
            labelField={"coach_group"}
            valueField={"coach_group"}
            sortBy={"coach_group"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelectFilterGroup(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "1"}}
        />
    )
}


const TournamentsMtt = (props) => {
    const [fromTimestamp, setFromTimestamp] = useState(+new Date());
    const [toTimestamp, setToTimestamp] = useState(+new Date());

    const handleSelectFilterGroup = (e) => {
        props.SupportMttTournamentsGroupFilterSet(e)
    }

    const handleSelectFromTimestamp = (e) => {
        if (new Date(e) == "Invalid Date") return;
        setFromTimestamp(+new Date(e));
    }
    const handleSelectToTimestamp = (e) => {
        if (new Date(e) == "Invalid Date") return;
        setToTimestamp(+new Date(e));
    }

    const handleGetData = () => {
        props.SupportMttTournamentsGet(new Date(fromTimestamp), new Date(toTimestamp))
    }

    let exportData = "Ник;Profit;ROI;ABI;AFS;Total;GGNetwork;GGNetwork (old);888Poker;Chico;iPoker;PartyPoker;PokerStars;WPN;PokerStars(FR-ES-PT);Winamax.fr;\n";
    props.SupportInfo.mttTournamentsList.forEach(row => {
        let _1 = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        let _2 = [0, 0, 0, 0, 0, 0, 0, 0, 0];

        let networks = [
            "GGNetwork",
            "888Poker",
            "Chico", "iPoker",
            "PartyPoker", "PokerStars",
            "WPN", "PokerStars(FR-ES-PT)",
            "Winamax.fr"];

        networks.forEach((e, i) => {
            if (row.networks[e]?.totalT || row.networks[e]?.totalM) {
                _1[i] = row.networks[e].totalT;
                _2[i] = row.networks[e].totalM;
            }
        })

        exportData += `${row.nick};${row.total_profit ? row.total_profit.toFixed(2) : 0};${row.roi_total ? row.roi_total.toFixed(2) : 0}%;` +
            `${row.avg_buyins ? row.avg_buyins.toFixed(2) : 0};${row.afs ? row.afs.toFixed(2) : 0};` +
            `${row.total_tournaments} / ${+row.total_entries + +row.total_tournaments};` +
            `${row.total_tournaments_gg} / ${+row.total_entries_gg + +row.total_tournaments_gg};` +
            `${_1[0]} / ${+_2[0] + +_1[0]};` +
            `${_1[1]} / ${+_2[1] + +_1[1]};` +
            `${_1[2]} / ${+_2[2] + +_1[2]};` +
            `${_1[3]} / ${+_2[3] + +_1[3]};` +
            `${_1[4]} / ${+_2[4] + +_1[4]};` +
            `${_1[5]} / ${+_2[5] + +_1[5]};` +
            `${_1[6]} / ${+_2[6] + +_1[6]};` +
            `${_1[7]} / ${+_2[7] + +_1[7]};` +
            `${_1[8]} / ${+_2[8] + +_1[8]};\n`;

    })

    const handleExportData = () => {
        props.SupportMttTournamentsExportToGoogleTable(exportData);
    }

    return (
        <div>
            <div style={{textAlign: "left", margin: "10px 0px 0 50px"}}>
                <a
                    style={{
                        color: "#3260da"
                    }}
                    href="https://docs.google.com/spreadsheets/d/1afuAubUt7cFt9Og3dNkVS6iEqQO9AkZqzf5hR4kV5C8/edit#gid=0" target="_blank">Открыть таблицу с экспортированными данными</a>
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 20px 1fr 10px 1fr",
                padding: "20px 50px 10px 50px",
                color: "white"
            }}>
                <input type="date" onBlur={(e) => handleSelectFromTimestamp(e.target.value)}/>
                <input type="date" onBlur={(e) => handleSelectToTimestamp(e.target.value)}/>
                <GroupSelector selected={props.SupportInfo.mttTournamentsSelectedGroupFilter}
                               options={props.SupportInfo.mttTournamentsGroupFilter}
                               handleSelectFilterGroup={handleSelectFilterGroup}/>
                <div></div>
                <div onClick={() => handleGetData()}
                     className={"default-back default-button"}
                >
                    Загрузить
                </div>
                <div></div>
                <div onClick={() => handleExportData()}
                     className={"default-back default-button"}
                >
                    Export
                </div>

            </div>
            <div>
                {
                    props.SupportInfo.mttTournamentsList[0] && <div>
                        <div style={{
                            color: "white",
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                            margin: "30px 10px 20px 0",
                        }}>
                            <div>Ник</div>
                            <div>Profit</div>
                            <div>ROI</div>
                            <div>ABI</div>
                            <div>AFS</div>
                            <div>Total</div>
                            <div>GGNetwork</div>
                            <div>GGNetwork (old)</div>
                            <div>888Poker</div>
                            <div>Chico</div>
                            <div>iPoker</div>
                            <div>PartyPoker</div>
                            <div>PokerStars</div>
                            <div>WPN</div>
                            <div>PokerStars(FR-ES-PT)</div>
                            <div>Winamax.fr</div>
                        </div>
                    </div>
                }

                <div style={{height: "70vh", overflow: "scroll"}}>
                    {
                        props.isLoading ? <MainLoading/>
                            :
                            props.SupportInfo.mttTournamentsList.map((e, i) => {
                                return <RowPopup row={e} i={i}
                                                 SupportRemoveMttTournamentById={props.SupportRemoveMttTournamentById}
                                />
                            })
                    }
                </div>

            </div>
        </div>
    );
};

const RowPopup = (props) => {
    let _1 = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    let _2 = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    let networks = [
        "GGNetwork",
        "888Poker",
        "Chico", "iPoker",
        "PartyPoker", "PokerStars",
        "WPN", "PokerStars(FR-ES-PT)",
        "Winamax.fr"];

    networks.forEach((e, i) => {
        if (props.row.networks[e]?.totalT || props.row.networks[e]?.totalM) {
            _1[i] = props.row.networks[e].totalT;
            _2[i] = props.row.networks[e].totalM;
        }
    })

    return <Popup key={`rowPopup ${props.i}`}
                  trigger={
                      <div className={props.i % 2 == 0 ? "default-back" : "default-back-darker"} style={{
                          cursor: "pointer",
                          color: "white",
                          display: "grid",
                          gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                      }}
                      >
                          <div>{props.row.nick}</div>
                          <div>{props.row.total_profit ? props.row.total_profit.toFixed(2) : 0}</div>
                          <div>{props.row.roi_total ? props.row.roi_total.toFixed(2) : 0}%</div>
                          <div>{props.row.avg_buyins ? props.row.avg_buyins.toFixed(2) : 0}</div>
                          <div>{props.row.afs ? props.row.afs.toFixed(2) : 0}</div>
                          <div>{props.row.total_tournaments} / {+props.row.total_entries + +props.row.total_tournaments}</div>
                          <div>{props.row.total_tournaments_gg} / {+props.row.total_entries_gg + +props.row.total_tournaments_gg}</div>
                          <div>{_1[0]} / {+_2[0] + +_1[0]}</div>
                          <div>{_1[1]} / {+_2[1] + +_1[1]}</div>
                          <div>{_1[2]} / {+_2[2] + +_1[2]}</div>
                          <div>{_1[3]} / {+_2[3] + +_1[3]}</div>
                          <div>{_1[4]} / {+_2[4] + +_1[4]}</div>
                          <div>{_1[5]} / {+_2[5] + +_1[5]}</div>
                          <div>{_1[6]} / {+_2[6] + +_1[6]}</div>
                          <div>{_1[7]} / {+_2[7] + +_1[7]}</div>
                          <div>{_1[8]} / {+_2[8] + +_1[8]}</div>
                      </div>}
                  modal
                  nested
    >
        {() => {
            let csvData = "ID|Турнир|Дата|Бай-ин|Позиция|Приз игрока|Реентри|Игроков|Призовой турнир|\n";
            if (props.row.tournaments && props.row.tournaments[0]) {
                props.row.tournaments.forEach(e => {
                    csvData += `${e.tournament.id}|${e.tournament.name}|${moment(e.time_start).format("DD.MM.YYYY HH:mm")}|${e.buy_in}|${e.player_position}|${e.player_prize}|${e.reentry}|${e.players_count}|${e.total_prize}|\n`;
                })
            }

            const handleExportCSV = () => {
                let link = document.createElement('a');
                link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvData));
                link.setAttribute('download', `${props.row.nick}`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            return <div>
                <div id="get-advice" style={{
                    backgroundColor: "#1e1e25",
                    padding: "10px 50px",
                    textAlign: "left",
                    color: "white",
                    width: "100%"
                }}>
                    <form className="form">
                        <div className="formgroup" style={{marginTop: "17px"}}>
                            <div style={{padding: "20px 0 40px 10px"}}>
                                <div onClick={handleExportCSV} className={"default-button default-back"}>Export CSV</div>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 3fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 40px",
                                    marginBottom: "30px", padding: "5px"
                                }}>
                                    <div>ID</div>
                                    <div>Турнир</div>
                                    <div>Дата</div>
                                    <div>Бай-ин</div>
                                    <div>Позиция</div>
                                    <div>Приз игрока</div>
                                    <div>Реентри</div>
                                    <div>Игроков</div>
                                    <div>Призовой турнира</div>
                                    <div></div>
                                </div>
                                {
                                    props.row.tournaments.map((e, i) => {
                                        return <div key={e._id}
                                                    className={i % 2 == 0 ? "default-back" : "default-back-darker"}
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns: "1fr 3fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 40px",
                                                        padding: "2px"
                                                    }}>
                                            <div>{e.tournament.id}</div>
                                            <div>{e.tournament.name}</div>
                                            <div>{moment(e.time_start).format("DD.MM.YYYY HH:mm")}</div>
                                            <div>{e.buy_in}</div>
                                            <div>{e.player_position}</div>
                                            <div>{e.player_prize}</div>
                                            <div>{e.reentry}</div>
                                            <div>{e.players_count}</div>
                                            <div>{e.total_prize}</div>
                                            <div onClick={() => props.SupportRemoveMttTournamentById(e._id)}
                                                 style={{cursor: "pointer"}}>
                                                ❌
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        }}
    </Popup>
}
// test
export default TournamentsMtt;

