import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import UserPage from "./userPage";
import {
    DeleteRole,
    DEV_TEST_SendNotification, EditProfile,
    GetCurrentUser,
    GiveRole,
    SetAccess
} from "../../../../redux/reducers/adminReducer";
import {GetRolesList} from "../../../../redux/reducers/rolesReducer";

class UserPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Работа с пользователем")
        this.props.GetCurrentUser(this.props.match.params.id, this.props.AuthInfo.id, this.props.AuthInfo.email, this.props.AuthInfo.accessToken)
        this.props.GetRolesList(this.props.AuthInfo.id, this.props.AuthInfo.email, this.props.AuthInfo.accessToken)
        // this.props.changeCurrentPage("WIKI : Список статей")
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <UserPage {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer,
    AdminInfo: state.adminReducer,
    RolesInfo: state.rolesReducer
})

export default compose(
    connect(mapStateToProps, {
        GetCurrentUser, GetRolesList, GiveRole, DeleteRole, SetAccess, DEV_TEST_SendNotification, EditProfile
    }),
    withRouter
)(UserPageContainer)