import React, {useState, useRef} from "react";

export default function Connectors(props) {
    return (
        <div className={"training-connectors-list-page"}>
            <div className={"columns-names"}>
                <div className={"column-name"}>
                    Направление
                </div>
                <div className={"column-name"}>
                    Название группы
                </div>
                <div className={"column-name"}>
                    ID каналов
                </div>
                <div>
                    ID каналов расписаний
                </div>
                <div/>
            </div>
            <div className={"training-connectors-list"}>
                {
                    props.TrainingInfo.connectorsList.map((connector, i) => <Connector key={`${i} ${connector.direction} ${connector.group_name}`}
                                                                                       connector={connector}
                                                                                       ConnectorEdit={props.ConnectorEdit}
                                                                                       ConnectorDelete={props.ConnectorDelete}/>)
                }
            </div>
        </div>
    );
}

const Connector = (props) => {
    return (
        <div className={"connector"}>
            <div className={"name"}>
                {props.connector.direction}
            </div>
            <div className={"name"}>
                {props.connector.group_name}
            </div>
            <div className={"column-data"}>
                <input onBlur={(e) => props.ConnectorEdit(props.connector.direction, props.connector.group_name, "channels", e.target.value)}
                       defaultValue={props.connector.channels}/>
            </div>
            <div className={"column-data"}>
                <input onBlur={(e) => props.ConnectorEdit(props.connector.direction, props.connector.group_name, "schedule_channels", e.target.value)}
                       defaultValue={props.connector.schedule_channels}/>
            </div>
            <div onClick={() => props.ConnectorDelete(props.connector.direction, props.connector.group_name)} className={"deleteConnector"}>
                x
            </div>
        </div>
    )
}