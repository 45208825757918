import React, {useState, useRef} from "react";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import moment from "moment";
import Popup from "reactjs-popup";

export default function FullInfos(props) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleStartDateChange = (date) => {
        setStartDate(+date);
    };
    const handleEndDateChange = (date) => {
        setEndDate(+date);
    };
    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            },

        },
    });

    const handleGetAllFeedbacks = () => {
        props.TrainingsGetAllFeedbacks(+startDate, +endDate)
    }

    return <div>
        <div style={{textAlign: "center"}}>
            <div style={{filter: "brightness(0) invert(1)", margin: "10px"}}>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            format="dd.MM.yyyy HH:mm"
                            margin="normal"
                            label="От"
                            value={startDate}
                            onChange={handleStartDateChange}
                        />
                    </MuiPickersUtilsProvider>
                    &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            format="dd.MM.yyyy HH:mm"
                            margin="normal"
                            label="До"
                            value={endDate}
                            onChange={handleEndDateChange}
                        />
                    </MuiPickersUtilsProvider>

                </ThemeProvider>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "1fr 250px 1fr", marginTop: "30px"}}>
                <div></div>
                <div className={"default-button default-back transition cursor-pointer"} onClick={handleGetAllFeedbacks}>
                    Инсайты
                </div>
                <div></div>
            </div>
        </div>
        {
            props.TrainingInfo.allFeedbacks && <div style={{color: "white"}}>
                <div
                    style={{display: "grid", gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr", margin: "30px 0 10px 0"}}>
                    <div>Тип тренировки</div>
                    <div>Тема</div>
                    <div>Дата</div>
                    <div>Ученик</div>
                    <div>Тренер</div>
                </div>
            <div>
                {
                    props.TrainingInfo.allFeedbacks.map((feedback, i) => {
                        return <Popup key={`fb ${feedback.topic} ${feedback.from_timestamp} ${feedback.student_id}`}
                               trigger={
                                   <div
                                        style={{display: "grid", gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr", cursor: "pointer", padding: "5px 0", backgroundColor: i % 2 == 0 ? "#2b2b38" : "#1f1f27"}}>
                                       <div>{feedback.type}</div>
                                       <div>{feedback.topic}</div>
                                       <div>{moment(+feedback.from_timestamp).format("DD.MM.YYYY HH:mm")}</div>
                                       <div>{feedback.student_name}</div>
                                       <div>{feedback.coach_name}</div>
                                   </div>
                               }
                               modal
                               nested
                        >
                            {() => (
                                <div>
                                    <div id="get-advice" className="main-popup" style={{width: "500px"}}>
                                        <form className="form" style={{color: "white", textAlign: "left"}}>
                                            <div className="formgroup">
                                                <div>
                                                    Дата: {moment(+feedback.from_timestamp).format("DD.MM.YYYY HH:mm")}
                                                </div>
                                                <div>
                                                    Тип: {feedback.type}
                                                </div>
                                                <div>
                                                    Тема: {feedback.topic}
                                                </div>
                                                <div>
                                                    Ученик: {feedback.student_name} ({feedback.student_id})
                                                </div>
                                                <div>
                                                    Тренер: {feedback.coach_name}
                                                </div>
                                                <div style={{marginTop: "20px"}}>
                                                    <div>
                                                        Вопрос №1: {feedback.question_1}
                                                    </div>
                                                    <div style={{color: "#bdac45"}}>
                                                        Ответ: {feedback.answer_1}
                                                    </div>
                                                    <div>
                                                        Вопрос №2: {feedback.question_2}
                                                    </div>
                                                    <div style={{color: "#bdac45"}}>
                                                        Ответ: {feedback.answer_2}
                                                    </div>
                                                    <div>
                                                        Вопрос №3: {feedback.question_3}
                                                    </div>
                                                    <div style={{color: "#bdac45"}}>
                                                        Ответ: {feedback.answer_3}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    })
                }
            </div>
            </div>
        }
    </div>
}