import React, {useState} from 'react';
import Popup from "reactjs-popup";

export default function Users(props) {
    return (
        <div className="support-users-list">
            <div className={"support-users-list-header"}
                 style={{gridTemplateColumns: `1fr 1fr 1fr 3fr`}}>
                <div className={"item"}>
                    Никнейм
                </div>
                <div className={"item"}>
                    Направление
                </div>
                <div className={"item"}>
                    Инвайт-код
                </div>
                <div className={"item"}>
                    Приглашенные игроки
                </div>
            </div>

            {
                props.ReferralInfo.support_users.map((user, i) => <UserRow key={`${user.id} ${i}`}
                                                                            user={user} SupportChangeUserInvites={props.SupportChangeUserInvites} isLoading={props.isLoading}/>)
            }
        </div>
    )
}

const UserRow = (props) => {
    const [invitesChangerInput, setInvitesChangerInput] = useState("");

    let invitedUsers = '';

    props.user.refers.forEach(e => invitedUsers += `${e};  `)

    let invitedUsersArr = props.user.refers.map(e => {
        return <span className={"red-text-hover cursor-pointer"} key={`inv user ${e}`} onClick={() => props.SupportChangeUserInvites(props.user.id, e)}>
            {e}{"; "}
        </span>
    })

    const handleEditInvites = () => {
        if (invitesChangerInput) {
            props.SupportChangeUserInvites(props.user.id, invitesChangerInput)
            setInvitesChangerInput("");
        }
    }

    return (
        <Popup key={`${props.user.id} editor`}
               trigger={
                   <div className={"support-users-list-items"}
                        style={{gridTemplateColumns: `1fr 1fr 1fr 3fr`}}>
                       <div className={"item"}>
                           {props.user.username}
                       </div>
                       <div className={"item"}>
                           {props.user.direction}
                       </div>
                       <div className={"item"}>
                           {props.user.code}
                       </div>
                       <div className={"item"}>
                           {invitedUsers}
                       </div>
                   </div>
               }
               modal
               nested
        >
            {() => (
                <div style={{marginBottom: "10px"}}>
                    <div style={{color: "#f3f3f3", fontWeight: "bolder", textAlign: "center", fontSize: "17px", margin: "10px"}}>Информация о {props.user.username}</div>
                    <div id="get-advice" className="main-popup" style={{padding: "40px", width: "800px"}}>
                        {
                            props.isLoading ? <div style={{color: "white"}}>...</div>
                                :<div className={"form"}>
                                    <div className={"formgroup"}>
                                        <div style={{textAlign: "left", color: "#e0e0e0"}}>
                                            <div style={{fontWeight: "bolder"}}>Направление</div>
                                            <div style={{ color: "#9a9a9a"}}>
                                                {props.user.direction}
                                            </div>
                                        </div>

                                        <div style={{textAlign: "left", color: "#e0e0e0"}}>
                                            <div style={{fontWeight: "bolder"}}>Инвайт-код</div>
                                            <div style={{ color: "#9a9a9a"}}>
                                                {props.user.code}
                                            </div>
                                        </div>

                                        <div style={{textAlign: "left", color: "#e0e0e0"}}>
                                            <div style={{fontWeight: "bolder"}}>Приглашенные игроки</div>
                                            <div style={{ color: "#9a9a9a"}}>
                                                {invitedUsersArr && invitedUsersArr[0] ? invitedUsersArr : "Список пуст"}
                                            </div>
                                        </div>
                                        <div style={{textAlign: "left", color: "#e0e0e0", marginTop: "20px"}}>
                                            <div style={{fontWeight: "bolder"}}>Изменение приглашенных игроков</div>
                                            <div style={{ color: "#9a9a9a", fontSize: "12.5px"}}>
                                                Для добавления нового пользователя - введите нужный никнейм, для удаления - нажмите на один из ников выше или введите его ниже
                                            </div>
                                            <input value={invitesChangerInput} onChange={(e) => setInvitesChangerInput(e.target.value)} onBlur={(e) => handleEditInvites(invitesChangerInput)} style={{marginTop: "5px", width: "100%"}} placeholder={"Введите никнейм"}/>
                                        </div>
                                    </div>

                                </div>

                        }

                    </div>

                </div>
            )}
        </Popup>
    )
}