import React from 'react';
import Popup from "reactjs-popup";
import ProfileMTTContainer from "../../User_Profile_MTT/profileContainer";
import {DispatchSupportSetCurrentUser} from "../../../../../redux/reducers/supportReducer";
const moment = require('moment');

const ContractsMTT = (props) => {
    function formatDate(date) {
        return moment(new Date(date)).format('DD.MM.YYYY')
    }

    return (
        <div>
            <div style={{display: "grid", gridTemplateColumns: "10fr 1fr"}}>
                <div style={{display: "grid", gridTemplateColumns: "2fr 2fr 3fr 2fr 2fr", color: "white", textAlign: "center", padding: "20px 20px 5px 20px", fontWeight: "bolder"}}>
                    <div>Ник</div>
                    <div>date_start_contract</div>
                    <div>contract_duration_type</div>
                    <div>date_end_contract</div>
                    <div>date_start_contract_distance</div>
                </div>
                <div style={{display: "grid", gridTemplateColumns: "1fr"}}>
                    <div></div>
                </div>
            </div>

            <div>
                {
                    props.SupportInfo.usersList.map((e, i) => {
                        return <div key={`${e.id}`} style={{display: "grid", gridTemplateColumns: "10fr 1fr", backgroundColor: i % 2 == 0 ? "#1d1d25" : "initial", color: "white", textAlign: "center", margin: "3px 20px"}}>
                            <div style={{display: "grid", gridTemplateColumns: "2fr 2fr 3fr 2fr 2fr"}}>
                                <div style={{padding: "5px"}}>{e.username}</div>
                                <div style={{padding: "5px"}}>{e.mtt_contracts?.date_start_contract ? formatDate(e.mtt_contracts.date_start_contract) : "Нет данных"}</div>
                                <div style={{padding: "5px"}}>{e.mtt_contracts?.contract_duration_type ? e.mtt_contracts.contract_duration_type : "Нет данных"}</div>
                                <div style={{padding: "5px"}}>{e.mtt_contracts?.date_end_contract ? formatDate(e.mtt_contracts.date_end_contract) : "Нет данных"}</div>
                                <div style={{padding: "5px"}}>{e.mtt_contracts?.date_start_contract_distance ? formatDate(e.mtt_contracts.date_start_contract_distance) : "Нет данных"}</div>
                            </div>

                            <div style={{display: "grid", gridTemplateColumns: "1fr"}} onMouseEnter={() => props.DispatchSupportSetCurrentUser(e, [], true)}>
                                <ProfileMTTContainer isContract={true} User={e}/>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default ContractsMTT;