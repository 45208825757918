import React, {useState} from 'react';
import s from './styles.module.css'
import FirstContent from "./1-content";
import SecondContent from "./2-content";
import ThirdContent from "./3-content";
import FourthContent from "./4-content";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Grab from '../../assests/drag_2.png'
import {useLocalStorage} from "../../hooks/useLocalStorage";



const Main = (props) => {

    const data = [<FirstContent {...props}/>, <SecondContent/>, <ThirdContent {...props}/>, <FourthContent {...props}/>,]

    const [characters, setCharacters] = useLocalStorage('main_components_2', [
        {order: 0},
        {order: 1},
        {order: 2},
    ])

    const handleOndragEnd = (res) => {
        const items = Array.from(characters)
        const [reorderItem] = items.splice(res.source.index, 1)
        items?.splice(res.destination?.index, 0, reorderItem)
        setCharacters(items)
    }
    return (

        <div className={s.box}>
            <div className={s.logo}>
                <h3>Личный кабинет</h3>
            </div>

            <div className={s.content}>
                <h2 className={s.title}>Главная страница</h2>
                <div className={s.bg}/>
                <DragDropContext onDragEnd={handleOndragEnd}>
                    <Droppable droppableId='characters'>
                        {(provided) => {

                            return <div {...provided.droppableProps} ref={provided.innerRef} className='characters'>
                                {characters.map(({order, component}, index) => <Draggable key={order.toString()}
                                                                                          draggableId={order.toString()}
                                                                                          index={index}>
                                    {(provided) => {
                                        return <div
                                            className={s.dnd}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <div  {...provided.dragHandleProps} className={s.drageble}>
                                                <img style={{marginLeft: "8px"}} src={Grab} alt={"grab"}/>
                                            </div>
                                            {data[order]}
                                        </div>
                                    }}

                                </Draggable>)}
                            </div>
                        }}

                    </Droppable>
                </DragDropContext>
                <FourthContent {...props}/>
            </div>
        </div>
    );
};

export default Main;