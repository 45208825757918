import React from 'react';
import Select from "react-dropdown-select";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import NumberToFixed2Field from "../../../../v_2/common/fields/numberToFixed2Field";
import TextField from "../../../../v_2/common/fields/textField";
import IsBoolField from "../../../../v_2/common/fields/isBoolField";
import s from "../../../../v_2/pages/Trainings/User/4-content/styles.module.css";

const PeriodSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите период"}
            labelField={"period"}
            valueField={"period"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectPeriod(value)
            }
            }
            style={{ backgroundColor: "initial", margin: "10px 0 0 0", fontSize: "18px", border: "none", zIndex: "101", outline: "none", textAlign: "center", color: "white" }}
        />
    )
}

const SpinPoolData = (props) => {
    // const Registered_Periods = [
    //     "Январь 2023", "Февраль 2023", "Март 2023", "Апрель 2023",
    //     "Май 2023", "Июнь 2023", "Июль 2023", "Август 2023",
    //     "Сентябрь 2023", "Октябрь 2023", "Ноябрь 2023", "Декабрь 2023"
    // ];

    const Registered_Periods = [
        {period: "Январь 2023"},{period: "Февраль 2023"},{period: "Март 2023"},{period: "Апрель 2023"},
        {period: "Май 2023"},{period: "Июнь 2023"},{period: "Июль 2023"},{period: "Август 2023"},
        {period: "Сентябрь 2023"},{period: "Октябрь 2023"},{period: "Ноябрь 2023"},{period: "Декабрь 2023"},
    ]

    const handleSelectPeriod = (e) => {
        if (e[0] && e[0].period) {
            props.C_SupportGetSpinPoolDate(e[0].period)
        }
    }

    const handleExportData = () => {
        let generatedCSV = "Nickname;Tourneys Count;ABI;Chip EV;Pool EV;EV BI;Netwon;Pool Profit;Active last 3 days\n"

        props.SupportInfo.spinPoolData.forEach(element => {
            generatedCSV += `${element.nickname};${element.tourneys};${element.abi};${element.chip_ev};${element.pool_ev};${element.ev_bi};${element.netwon};${element.profit};${element.is_active_last_three_days ? "TRUE" : "FALSE"}\n`
        })

        let link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(generatedCSV));
        link.setAttribute('download', "export.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <div>
            <div style={{textAlign: "center", margin: "0 40%"}}>
                <PeriodSelector options={Registered_Periods} handleSelectPeriod={handleSelectPeriod} />
            </div>
            {
                props.SupportInfo.spinPoolData[0] ?
                    <div style={{marginTop: "20px", color: "white", cursor: "pointer"}} onClick={handleExportData}>
                        Выгрузить данные
                    </div>
                    : null
            }
            <div style={{marginTop: "30px"}}>
                <div className='ag-theme-alpine-dark' style={{height: 900, width: '100%', position: 'relative'}}>
                    <AgGridReact
                        style={{height: 900, width: '100%'}}
                        rowData={props.SupportInfo.spinPoolData}
                        enableBrowserTooltips={true}
                        tooltipShowDelay={0}
                        tooltipHideDelay={2000}
                        rowHeight={70}
                        defaultColDef={{
                            editable: true,
                            sortable: true,
                            flex: 1,
                            minWidth: 100,
                            filter: false,
                            floatingFilter: false,
                            resizable: true,
                        }}
                        frameworkComponents={{
                            numberToFixed2Field: NumberToFixed2Field,
                            textField: TextField,
                            isBoolField: IsBoolField
                        }}
                    >
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Nickname'}
                            field={'nickname'}
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='textField'
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Tourneys count'}
                            field='tourneys'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='numberToFixed2Field'
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'ABI'}
                            field='abi'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='numberToFixed2Field'
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Chip EV'}
                            field='chip_ev'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='numberToFixed2Field'
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Pool EV'}
                            field='pool_ev'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='numberToFixed2Field'
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'EV BI'}
                            field={'ev_bi'}
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='numberToFixed2Field'
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Netwon'}
                            field='netwon'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='numberToFixed2Field'
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Pool Profit'}
                            field='profit'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='numberToFixed2Field'
                        />
                        <AgGridColumn
                            headerClass={s.header_styles}
                            headerName={'Active last 3 days'}
                            field='is_active_last_three_days'
                            editable={false}
                            sortable={true}
                            filter={false}
                            cellRenderer='isBoolField'
                        />
                    </AgGridReact>
                </div>
            </div>
        </div>
    );
};

export default SpinPoolData;