import React, {useState, useRef} from "react";
import MainLoading from "../../common/mainLoading";
import Popup from "reactjs-popup";
import EditIcon from "../../../images/edit_pencil.png";

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
} from '@material-ui/pickers';
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import Select from "react-dropdown-select";

const SubTypeSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать сложность"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            create={true}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectSubType(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 6px", fontSize: "16px", border: "none", color: "white", width: "300px"}}
        />
    )
}

const CoachSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать тренера"}
            labelField={"username"}
            valueField={"username"}
            sortBy={"username"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected && props.selected[0] ? props.selected : []}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 6px", fontSize: "16px", border: "none", color: "white", width: "300px"}}
        />
    )
}

const PlayerSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать ученика"}
            labelField={"nick"}
            valueField={"nick"}
            sortBy={"nick"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected && props.selected[0] ? props.selected : []}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 6px", fontSize: "16px", border: "none", color: "white", width: "300px"}}
        />
    )
}

export default function Creator(props) {
    const [type, setType] = useState(undefined);
    const [direction, setDirection] = useState(undefined);
    const [groups, setGroups] = useState([]);
    const [studentName, setStudentName] = useState(undefined);
    const [studentId, setStudentId] = useState(undefined);
    const [topic, setTopic] = useState(undefined);
    const [subType, setSubType] = useState("Нет");
    const [additionalInfo, setAdditionalInfo] = useState(undefined);
    const [zoom, setZoom] = useState(undefined);

    const [coachId, setCoachId] = useState(undefined);
    const [coachName, setCoachName] = useState(undefined);

    const [startDate, setStartDate] = useState(+new Date());
    const [endDate, setEndDate] = useState(+new Date(+new Date() + 3600000));

    const handleSetGroups = (name) => {
        let ind = groups.findIndex(e => e == name);
        if (ind != -1) {
            let ng = JSON.parse(JSON.stringify(groups));
            ng.splice(ind, 1);
            setGroups([...ng])
        } else {
            setGroups([...groups, name]);
        }
    }

    const handleSelectSubType = (e) => {
        if (e[0]) {
            setSubType(e[0].name)
        } else {
            setSubType("Нет")
        }
    }

    const CreatorCreate = () => {
        props.CreatorCreate({
            step: props.TrainingInfo.creatorError ? 2 : 1,
            type, from_timestamp: startDate, to_timestamp: endDate, direction, groups, studentName,
            studentId, topic, additionalInfo, zoom, isSupport: !props.AuthInfo.isCoach, coachName, coachId, subType
        }, props.TrainingInfo.creatorError, props.history)
    }
    const handleStartDateChange = (date) => {
        setStartDate(+date);
    };
    const handleEndDateChange = (date) => {
        setEndDate(+date);
    };

    let directions = [];

    if (Object.keys(props.TrainingInfo.creatorInfo)[0]) {
        directions = [...Object.keys(props.TrainingInfo.creatorInfo)]
    }


    let localizedType = 'Не выбрано';
    if (type === "all") localizedType = "Общая";
    if (type === "stream") localizedType = "Потоковая";
    if (type === "group") localizedType = "Групповая";
    if (type === "personal") localizedType = "Индивидуальная";

    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            },

        },
    });

    const handleSelectCoach = (e) => {
        setCoachId(e && e[0] ? e[0].id : null);
        setCoachName(e && e[0] ? e[0].username : null);
    }


    const handleSelectPlayer = (e) => {
        setStudentId(e && e[0] ? e[0].user_id : null);
        setStudentName(e && e[0] ? e[0].nick : null);
    }

    return !props.isLoading ?
        <div className={"support-user-editor-page"}>
            <div>
                <div style={{filter: "brightness(0) invert(1)", margin: "10px"}}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                format="dd.MM.yyyy HH:mm"
                                margin="normal"
                                label="Начало тренировки"
                                value={startDate}
                                onChange={handleStartDateChange}
                            />
                        </MuiPickersUtilsProvider>

                    </ThemeProvider>
                </div>
                <div style={{color: "#dc721a", fontSize: "12px", padding: "0 20%"}}>Важно! При создании тренировки на этой странице вы должны указывать своё локальное время</div>
                <div style={{color: "#c7b465", fontSize: "12px", padding: "0 20%"}}>Важно! При создании тренировки, которая начнётся раньше, чем через 4 дня от текущего времени - все уведомления по ней будут выключены, кроме одного (за 15 минут до тренировки). На странице тренировки вы можете в любое время сделать рассылку нажатием кнопки "Оповестить о тренировке"</div>
            </div>

            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Тип тренировки
                </div>
                <div className={"info"} style={{cursor: "pointer"}}>
                    <Popup key={"setType training-create" + localizedType}
                           trigger={
                               <div>{localizedType}<img style={{width: "12px", margin: "0 10px"}} src={EditIcon}/>Изменить
                               </div>
                           }
                           modal
                           nested
                    >
                        {() => (
                            <div>
                                <div id="get-advice" className="user-page-popup">
                                    <form className="form">
                                        <div
                                            onClick={() => setType("all")}
                                            className={"role-page-access-block default-back"}>Общая
                                        </div>
                                        <div
                                            onClick={() => setType("stream")}
                                            className={"role-page-access-block default-back"}>Потоковая
                                        </div>
                                        <div
                                            onClick={() => setType("group")}
                                            className={"role-page-access-block default-back"}>Групповая
                                        </div>
                                        <div
                                            onClick={() => setType("personal")}
                                            className={"role-page-access-block default-back"}>Индивидуальная
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Popup>
                </div>
            </div>

            {
                type && !props.AuthInfo.isCoach ?
                    <div className={"support-user-editor-page-item"}>
                        <div className={"name"} style={{marginTop: "15px"}}>
                            Тренер
                        </div>
                            <CoachSelector options={props.TrainingInfo.creatorCoaches}
                                          selected={[]}
                                          handleSelect={handleSelectCoach}/>
                            {/*<Popup key={`setCoach training-create ${coachName}`}*/}
                            {/*       trigger={*/}
                            {/*           <div>{coachName ? coachName : "Не выбрано"}<img*/}
                            {/*               style={{width: "12px", margin: "0 10px"}} src={EditIcon}/>Изменить*/}
                            {/*           </div>*/}
                            {/*       }*/}
                            {/*       modal*/}
                            {/*       nested*/}
                            {/*>*/}
                            {/*    {() => (*/}
                            {/*        <div>*/}
                            {/*            <div id="get-advice" className="user-page-popup">*/}
                            {/*                <form className="form">*/}
                            {/*                    {*/}
                            {/*                        props.TrainingInfo.creatorCoaches.map((e, ei) => {*/}
                            {/*                            return <div key={`${e.id} ${ei}`}*/}
                            {/*                                        onClick={() => {*/}
                            {/*                                            setCoachId(e.id);*/}
                            {/*                                            setCoachName(e.username);*/}
                            {/*                                        }}*/}
                            {/*                                        className={"role-page-access-block default-back"}>{e.username}*/}
                            {/*                            </div>*/}
                            {/*                        })*/}
                            {/*                    }*/}
                            {/*                </form>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*</Popup>*/}
                    </div>
                    : null
            }

            {
                type && type != "all" ?
                    <div className={"support-user-editor-page-item"}>
                        <div className={"name"}>
                            Направление
                        </div>
                        <div className={"info"} style={{cursor: "pointer"}}>
                            <Popup key={`setDirection training-create ${direction}`}
                                   trigger={
                                       <div>{direction ? direction : "Не выбрано"}<img
                                           style={{width: "12px", margin: "0 10px"}} src={EditIcon}/>Изменить
                                       </div>
                                   }
                                   modal
                                   nested
                            >
                                {() => (
                                    <div>
                                        <div id="get-advice" className="user-page-popup">
                                            <form className="form">
                                                {
                                                    directions.map((e, ei) => {
                                                        return <div key={`${e} ${ei}`}
                                                                    onClick={() => setDirection(e)}
                                                                    className={"role-page-access-block default-back"}>{e}
                                                        </div>
                                                    })
                                                }
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </div>
                    </div>
                    : null
            }

            {
                type && direction ?
                    type == "group" ?
                        <div className={"support-user-editor-page-item"}>
                            <div className={"name"}>
                                Группы
                            </div>
                            <div className={"info"} style={{cursor: "pointer"}}>
                                <Popup key={`setGroup training-create 123`}
                                       trigger={
                                           <div>{groups[0] ? groups.map(e => `${e}; `) : "Не выбрано"}<img
                                               style={{width: "12px", margin: "0 10px"}} src={EditIcon}/>Изменить
                                           </div>
                                       }
                                       modal
                                       nested
                                >
                                    {() => (
                                        <div>
                                            <div id="get-advice" className="user-page-popup">
                                                <form className="form">
                                                    {
                                                        props.TrainingInfo.creatorInfo[direction].groups.map((e, ei) => {
                                                            return <div key={`${e} ${ei}`}
                                                                        onClick={() => handleSetGroups(e)}
                                                                        className={"role-page-access-block default-back"}>{e}
                                                            </div>
                                                        })
                                                    }
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </div>
                        : type == "personal" ?
                            <div className={"support-user-editor-page-item"}>
                                <div className={"name"} style={{marginTop: "15px"}}>
                                    Ученик
                                </div>
                                <PlayerSelector options={props.TrainingInfo.creatorInfo[direction].students}
                                               selected={[]}
                                               handleSelect={handleSelectPlayer}/>
                                {/*<div className={"info"} style={{cursor: "pointer"}}>*/}
                                {/*    <Popup key={`setStudent training-create ${studentName}`}*/}
                                {/*           trigger={*/}
                                {/*               <div>{studentName ? studentName : "Не выбрано"}<img*/}
                                {/*                   style={{width: "12px", margin: "0 10px"}} src={EditIcon}/>Изменить*/}
                                {/*               </div>*/}
                                {/*           }*/}
                                {/*           modal*/}
                                {/*           nested*/}
                                {/*    >*/}
                                {/*        {() => (*/}
                                {/*            <div>*/}
                                {/*                <div id="get-advice" className="user-page-popup">*/}
                                {/*                    <form className="form">*/}
                                {/*                        {*/}
                                {/*                            props.TrainingInfo.creatorInfo[direction].students.map((e, ei) => {*/}
                                {/*                                return <div key={`${e.user_id} ${ei}`}*/}
                                {/*                                            onClick={() => {*/}
                                {/*                                                setStudentName(e.nick);*/}
                                {/*                                                setStudentId(e.user_id);*/}
                                {/*                                            }}*/}
                                {/*                                            className={"role-page-access-block default-back"}>{e.nick}*/}
                                {/*                                </div>*/}
                                {/*                            })*/}
                                {/*                        }*/}
                                {/*                    </form>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        )}*/}
                                {/*    </Popup>*/}
                                {/*</div>*/}
                            </div>
                            : null : null
            }

            {
                type && type != "personal" ?
                    <div className={"support-user-editor-page-item"}>
                        <div className={"name"}>

                        </div>
                        <SubTypeSelector
                                       options={props.TrainingInfo.subTypesList}
                                       handleSelectSubType={handleSelectSubType}/>
                    </div>
                    : null
            }

            {
                type ?
                    <div className={"support-user-editor-page-item"}>
                        <div className={"name"}>
                            Тема занятия
                        </div>
                        <textarea
                            onChange={(e) => setTopic(e.target.value)}
                            value={topic} rows={1}/>
                    </div>
                    : null
            }

            {
                type ?
                    <div className={"support-user-editor-page-item"}>
                        <div className={"name"}>
                            Ссылка на Zoom
                        </div>
                        <textarea
                            onChange={(e) => setZoom(e.target.value)}
                            value={zoom} rows={1}/>
                    </div>
                    : null
            }

            {
                type ?
                    <div className={"support-user-editor-page-item"}>
                        <div className={"name"}>
                            Дополнительная информация
                        </div>
                        <textarea
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                            value={additionalInfo} rows={1}/>
                    </div>
                    : null
            }

            {
                props.TrainingInfo.creatorError ?
                    <div style={{color: "tomato", fontSize: "15px", margin: "25px 0 -20px 0"}}>
                        {props.TrainingInfo.creatorError}
                    </div>
                    : null
            }

            {
                type ?
                    <div style={{padding: "2% 40%"}}>
                        <div
                            className={`transition default-button ${props.TrainingInfo.creatorError ? "orange-back" : "green-back"}`}
                            style={{padding: "15px", borderRadius: "25px"}}
                            onClick={CreatorCreate}>Создать
                        </div>
                    </div>
                    : null
            }
        </div> : <MainLoading/>
}