import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import MainLoading from "../../common/mainLoading";
import Select from "react-dropdown-select";

const SubTypeSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать сложность"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            create={true}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectSubType(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", color: "white"}}
        />
    )
}

export default function PersonalSlots(props) {
    const DATE = new Date();
    const DAY = DATE.getDate();
    const MONTH = DATE.getMonth();
    const YEAR = DATE.getFullYear();

    const MONTHS = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

    const [daysInMonth, setDaysInMonth] = useState(Array.from({length: (new Date(YEAR, MONTH, 0).getDate())}, (_, i) => i + 1))

    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(MONTH);
    const [selectedYear, setSelectedYear] = useState(YEAR);
    const [selectedHours, setSelectedHours] = useState(null);
    const [subType, setSubType] = useState(undefined)

    const handleSelectSubType = (e) => {
        if (e[0]) {
            setSubType(e[0].name)
        } else {
            setSubType("Нет")
        }
    }

    const handleSelectHours = (e) => {
        if (e.target.value.length < 6) {
            if (e.target.value[0] && isNaN(e.target.value[0])) return;
            if (e.target.value[1] && isNaN(e.target.value[1])) return;
            if (selectedHours && e.target.value.length < selectedHours.length) return setSelectedHours(e.target.value)
            if (e.target.value.length == 2) return setSelectedHours(`${e.target.value}:`)
            // if (e.target.value[2] && e.target.value[2] != ":") return;
            if (e.target.value[3] && isNaN(e.target.value[3])) return;
            if (e.target.value[4] && isNaN(e.target.value[4])) return;
            setSelectedHours(e.target.value)
        }
    }

    const handleSelectDay = (day) => {
        setSelectedDay(day);
        props.CoachPersonalSlotsGetMonthDay(selectedMonth + 1, selectedYear, day);
    }

    const handleChangeMonth = (action) => {
        if (action == "prev") {
            if (+selectedMonth == 0) {
                setSelectedYear(+selectedYear - 1)
                setSelectedMonth(11)

                setDaysInMonth(Array.from({length: (new Date(+selectedYear - 1, 12, 0).getDate())}, (_, i) => i + 1))
                props.CoachPersonalSlotsGetMonth(12, +selectedYear - 1)

            } else {
                setSelectedMonth(+selectedMonth - 1)

                setDaysInMonth(Array.from({length: (new Date(selectedYear, +selectedMonth, 0).getDate())}, (_, i) => i + 1))
                props.CoachPersonalSlotsGetMonth(selectedMonth, selectedYear)
            }
        }
        if (action == "next") {
            if (+selectedMonth == 11) {
                setSelectedYear(+selectedYear + 1)
                setSelectedMonth(0)

                setDaysInMonth(Array.from({length: (new Date(+selectedYear + 1, 1, 0).getDate())}, (_, i) => i + 1))
                props.CoachPersonalSlotsGetMonth(1, +selectedYear + 1)
            } else {
                setSelectedMonth(+selectedMonth + 1)

                setDaysInMonth(Array.from({length: (new Date(selectedYear, +selectedMonth + 2, 0).getDate())}, (_, i) => i + 1))
                props.CoachPersonalSlotsGetMonth(+selectedMonth + 2, selectedYear)
            }
        }

        setSelectedDay(null);
        setSelectedHours(null);
    }

    return (
        <div className={"coach-personal-slots"}>
            <div className={"form"}>
                <div className={"calendar"}>
                    <div className={"name"}>
                        <span onClick={() => handleChangeMonth("prev")} style={{cursor: "pointer"}}>◀️</span>
                        <span onClick={() => handleChangeMonth("next")}
                              style={{marginLeft: "5px", marginRight: "10px", cursor: "pointer"}}>▶️</span>
                        {MONTHS[selectedMonth]} {selectedYear} г.
                    </div>
                    <div className={"content"}>
                        {
                            daysInMonth.map((day, i) => {
                                let slot = props.TrainingInfo.coachPersonalSlotsMonthList.find(e => e.month == `${selectedMonth + 1}-${selectedYear}` && e.day == day)

                                let color = 'mediumseagreen';

                                if (slot && slot.times[0]) {
                                    if (slot.times.findIndex(e => e.is_reserved == false) == -1) color = '#ea5a5a'
                                    if (slot.times.some(e => e.is_reserved == true) && slot.times.some(e => e.is_reserved == false)) color = '#debd18'
                                }

                                return <div className={`item ${selectedDay == day ? "active" : null}`}
                                            onClick={() => handleSelectDay(day)}
                                            key={`coach-cal ${day} ${i}`}>
                                    {
                                        slot && slot.times[0] ?
                                            <span style={{backgroundColor: color}}>{day}</span>
                                            : <span>{day}</span>
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>

                {/*{*/}
                {/*    selectedDay ?*/}
                {/*        <div style={{width: "350px", margin: "55px 0 -40px 0"}}>*/}
                {/*            <SubTypeSelector*/}
                {/*                options={props.TrainingInfo.subTypesList}*/}
                {/*                handleSelectSubType={handleSelectSubType}/>*/}
                {/*        </div>*/}
                {/*        : null*/}
                {/*}*/}

                {
                    selectedDay ?
                        <div>

                            <div className={"add-hours"}>
                                <input value={selectedHours} onChange={handleSelectHours} placeholder={"23:59"}/>
                                <div
                                    onClick={() => props.CoachPersonalSlotsAddTimeToDay(selectedMonth + 1, selectedYear, selectedDay, selectedHours, subType)}
                                    className={"add-hours-btn"}><span>Добавить время</span></div>
                            </div>
                            <div style={{color: "#dc721a", fontSize: "12px", padding: "1%", textAlign: "left"}}>Важно!
                                При создании
                                тренировки на этой странице вы должны указывать московское время
                            </div>
                        </div>

                        : null
                }

                {
                    props.TrainingInfo.coachPersonalSlotsMonthDay && !props.isLoading && selectedDay ?
                        <div className={"calendar"} style={{marginTop: "30px"}}>
                            <div className={"content"}>
                                {
                                    props.TrainingInfo.coachPersonalSlotsMonthDay.times.map(e => {
                                        return <div className={`item time`}
                                                    onClick={() => props.CoachPersonalSlotsRemoveTimeFromDay(selectedMonth + 1, selectedYear, selectedDay, e.time)}
                                                    key={`coach-cal-time ${e.time} ${e.timestamp}`}>
                                            <span>{e.time}</span>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        : null
                }

            </div>


            <div className={"requests"}>
                <div className={"name"}>
                    Запросы на тренировку
                </div>
                {
                    props.TrainingInfo.coachRequests[0] ?
                        props.TrainingInfo.coachRequests.map(request => {
                            return <RequestRow key={`coach-info ${request.user_timestamp}`}
                                               step={props.TrainingInfo.coachStep}
                                               request={request}
                                               CoachChangePersonalSlotRequest={props.CoachChangePersonalSlotRequest}
                                               isLoading={props.isLoading}/>
                        })
                        :
                        <div className={"info"}>
                            В данный момент список запросов пуст.
                        </div>
                }
            </div>
        </div>
    )
}

const RequestRow = (props) => {
    const [isSend, setIsSend] = useState("11");

    let date = props.request.month.split("-")

    const [declineReason, setDeclineReason] = useState(null);

    const handleSetDeclineReason = (value) => {
        setDeclineReason(value)
    }

    return (
        <Popup key={`coach-info-popup ${props.request.user_timestamp} ${isSend}`}
               trigger={
                   <div className={"request-row"}>
                       <div style={{fontWeight: "bolder"}}>
                           {`${props.request.day}.${date[0]}.${date[1]} ${props.request.time}`}
                       </div>
                       <div>
                           {props.request.user_topic}
                       </div>
                       <div style={{fontWeight: "bolder"}}>
                           Нажмите для просмотра деталей
                       </div>
                   </div>
               }
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="main-popup user-request-personal-training">
                        <form className="form">
                            <div className="formgroup">
                                                <span style={{color: "white"}}>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Дата: </span>{`${props.request.day}.${date[0]}.${date[1]} ${props.request.time}`}
                                                    <br/>
                                                    <br/>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Направление: </span>{props.request.user_direction}
                                                    <br/>
                                                    <br/>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Никнейм: </span> {props.request.user_name} ({props.request.user_id})
                                                    <br/><br/>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Тема: </span> {props.request.user_topic}
                                                    <br/><br/>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Описание: </span> {props.request.user_description}
                                                    <br/><br/>
                                                    <span style={{
                                                        color: "#e84a4a",
                                                        fontSize: "12px"
                                                    }}>Важно! При создании тренировки, которая начнётся раньше, чем через 4 дня от текущего времени - все уведомления по ней будут выключены, кроме одного (за 15 минут до тренировки). На странице тренировки вы можете в любое время сделать рассылку нажатием кнопки "Оповестить о тренировке"</span>
                                                </span>
                            </div>


                            {
                                props.isLoading ? <MainLoading/>
                                    : <div>
                                        {
                                            props.step == 2 && <div
                                                style={{marginTop: "30px", padding: "10px 0", color: "#cc5d03"}}>
                                                Внимание! На это время уже есть запланированная тренировка, будет пересечение!
                                                Подтвердите создание, если уверены в этом действии
                                            </div>
                                        }
                                        {
                                            props.step == 1 ?
                                                <div className={"default-button green-back transition"}
                                                     onClick={() => props.CoachChangePersonalSlotRequest("accept", props.step, props.request.timestamp_day, props.request.timestamp)}
                                                     style={{marginTop: "30px", padding: "10px 0"}}>
                                                    Записать на тренировку
                                                </div>
                                                :
                                                <div className={"default-button orange-back transition"}
                                                     onClick={() => props.CoachChangePersonalSlotRequest("accept", props.step, props.request.timestamp_day, props.request.timestamp)}
                                                     style={{marginTop: "30px", padding: "10px 0"}}>
                                                    Подтвердить создание тренировки
                                                </div>
                                        }

                                        <div className={"formgroup"}>
                                            <div
                                                style={{margin: "20px 0 10px 0", fontSize: "14px", fontWeight: "bolder"}}>
                                                Для отмены запроса введите причину отмены
                                            </div>
                                            <input value={declineReason}
                                                   onChange={(e) => setDeclineReason(e.target.value)}/>
                                        </div>
                                        {
                                            declineReason && <div className={"default-button red-back transition"}
                                                                  onClick={() => props.CoachChangePersonalSlotRequest("decline", props.step, props.request.timestamp_day, props.request.timestamp, declineReason)}
                                                                  style={{padding: "10px 0"}}>
                                                Отклонить запрос
                                            </div>
                                        }

                                    </div>
                            }


                        </form>


                    </div>
                </div>
            )}
        </Popup>
    )
}