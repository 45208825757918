export const getColor = (value, standards) => {
    let step;
    let color = '#ff3305';

    //standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
    if (standards[0].length <= 0) standards[0] = 0;
    if (standards[1].length <= 0) standards[1] = 0;

    //ищем шаг в 5% от мин.значения эталона
    step = standards[0] * 0.05;

    //получаем значение в etalon из standards которое ближе к показателю value
    let etalon = standards.reduce(function (a, c) {
        return Math.abs(a - value) < Math.abs(c - value) ? a : c;
    })
    let mod_dif = Math.abs(value - etalon);

    if (value >= standards[0] && value <= standards[1]) {
        color = '#7FFF00'; //зеленый
    } else if (mod_dif <= step) {
        color = '#FFFF00'; //желтый
    } else {
        color = '#FF4500'; //красный
    }
    return color;
}

export function getDiff(value, etalon_min, etalon_max) {
    if (value >= etalon_min && value <= etalon_max) {
        return 0; //  - это число, которое показывает насколько мы отклоняемся от стратегии. Т.к. в этом ИФе value1 внутри мин/макс эталона, то расхождение со стратой равно 0
    } else {
        if (value > etalon_max) {
            return (etalon_max - value ); //если значение value больше максимального предела эталона, то разницу считаем именно от верхнего предела
        } else {
            return value - etalon_min; //иначе считаем от нижнего предела эталона
        }
    }
}
