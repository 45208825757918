import {USER_API} from "../../api/util";
import Cookies from "js-cookie";
import {ChangeMainLoading} from "./mainReducer";
import config from "../../v_1/config.json";
import {NotificationManager} from "react-notifications";

let onlineChanger = null;

const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
const LOGOUT = 'LOGOUT';
const CHANGE_CURRENT_DOCUMENT_PAGE = 'LOGOUT';

let initialState = {
    _id: null,
    id: null,
    username: null,
    accessToken: null,
    level: null,
    email: null,
    avatar: null,
    notes: null,

    TTServersFilter: null,
    TTStatusesFilter: null,

    isDeveloper: null,
    initiatorDeveloper: null,

    isOwner: null,
    initiatorOwner: null,

    isAdmin: null,
    initiatorAdmin: null,

    isWikiCreator: null,
    initiatorWikiCreator: null,

    isDisk: null,
    initiatorDisk: null,

    isAuditor: null,
    initiatorAuditor: null,

    isPlayer: null,
    initiatorPlayer: null,

    directions: [],
    roles: [],
    pageAccesses: [],
    currentDocumentPage: null,

    verificator: {}
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_USER_INFO:
            return {
                ...action.profile,
                roles: action.profile.roles,
                directions: action.profile.directions,
                pageAccesses: action.pageAccesses,
                verificator: action.verificator
            };
        case CHANGE_CURRENT_DOCUMENT_PAGE:
            return {
                ...state,
                currentDocumentPage: action.document
            }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

const ChangeUserInfo = (profile, pageAccesses, verificator) =>
    ({type: CHANGE_USER_INFO, profile, pageAccesses, verificator});
const ClearAuthData = () => ({type: LOGOUT});

const setCurrentDocumentPage = (document) => ({type: CHANGE_CURRENT_DOCUMENT_PAGE, document})

export const ChangeTTFilters = (userId, serversFilter, statusesFilter, targetsFilter) => {
    return (dispatch) => {
        USER_API.changeTTFilters(userId, serversFilter, statusesFilter, targetsFilter)
    }
}

export const ChangeNotes = (id, access_token, newValue) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        USER_API.changeNotes({id, access_token, newValue})
            .then(data => {

                dispatch(ChangeMainLoading(false))
                })
    }
}
export const ChangeCurrentDocumentPage = (docName, pageAccesses) => {
    return (dispatch) => {
        pageAccesses.forEach(page => {
            if (page.name == docName) {
                dispatch(setCurrentDocumentPage(null))
                dispatch(setCurrentDocumentPage(page))
            }
        })
    }
}

export const Logout = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        Cookies.remove("id", { domain: config.domain, path: '/' });
        Cookies.remove("token", { domain: config.domain, path: '/' });
        Cookies.remove("username", { domain: config.domain, path: '/' });
        Cookies.remove("avatar", { domain: config.domain, path: '/' });
        Cookies.remove("email", { domain: config.domain, path: '/' });
        Cookies.remove("WEB_SITE_USER_VERSION", { domain: config.domain, path: '/' });
        Cookies.remove("WEB_SITE_USER_VERSION");
        onlineChanger = null;
        dispatch(ClearAuthData());
        dispatch(ChangeMainLoading(false))
        window.location.href = '/';
    }
}
export const GetProfile = (id, email, access_token) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        USER_API.getProfile(id, email, access_token)
            .then(data => {
                dispatch(ChangeMainLoading(false));
                if (data.resultCode && data.resultCode !== 1) return;

                if (onlineChanger == null) {
                    onlineChanger = setInterval(() => {
                        USER_API.changeOnline({id: data.profile.id, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}).then(data => {
                            if (data) {
                                let latestVersion = `${data}`;
                                let currentVersion = localStorage.getItem('version');
                                if (!currentVersion || currentVersion != latestVersion) {
                                    if ('caches' in window) {
                                        caches.keys().then((names) => {
                                            names.forEach(name => {
                                                caches.delete(name);
                                            })
                                        });

                                        localStorage.clear();
                                        localStorage.setItem('version', latestVersion);

                                        window.location.reload();

                                        return
                                    }

                                    localStorage.clear();
                                    localStorage.setItem('version', latestVersion);
                                }
                            }
                        })
                    }, 30000);
                }

                dispatch(ChangeUserInfo(data.profile, data.pageAccesses, data.verificator))
            })
    }
}
export const GetAuth = (token) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        USER_API.getAuth(token)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode !== 1) return window.location.href = '/'

                Cookies.set('id', data.profile.id, {
                    domain: config.domain,
                    path: '/',
                    expires: 365 // срок годности в днях
                });
                Cookies.set('token', data.profile.accessToken, {
                    domain: config.domain,
                    path: '/',
                    expires: 365 // срок годности в днях
                });
                Cookies.set('username', data.profile.username, {
                    domain: config.domain,
                    path: '/',
                    expires: 365 // срок годности в днях
                });
                Cookies.set('avatar', data.profile.avatar, {
                    domain: config.domain,
                    path: '/',
                    expires: 365 // срок годности в днях
                });
                Cookies.set('email', data.profile.email, {
                    domain: config.domain,
                    path: '/',
                    expires: 365 // срок годности в днях
                });
                Cookies.set('WEB_SITE_USER_VERSION', '2', {
                    domain: config.domain,
                    path: '/',
                    expires: 365 // срок годности в днях
                });

                if (!data?.profile?.email || !data?.profile?.username) return window.location.href = '/';

                window.location.href = '/';

                dispatch(ChangeUserInfo(data.profile, data.pageAccesses))
            })
    }
}

export default authReducer;