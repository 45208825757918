import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import MainLoading from "../../common/mainLoading";

export default function IndivSlots(props) {

    return (
        <div className={"coach-personal-slots"}>
            <div className={"form"}>
                <div className={"calendar"}>

                </div>

            </div>


            {/*<div className={"requests"}>*/}
            {/*    <div className={"name"}>*/}
            {/*        Запросы на тренировку*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        props.TrainingInfo.coachRequests[0] ?*/}
            {/*            props.TrainingInfo.coachRequests.map(request => {*/}
            {/*                return <RequestRow key={`coach-info ${request.user_timestamp}`}*/}
            {/*                                   step={props.TrainingInfo.coachStep}*/}
            {/*                                   request={request}*/}
            {/*                                   CoachChangePersonalSlotRequest={props.CoachChangePersonalSlotRequest}*/}
            {/*                                   isLoading={props.isLoading}/>*/}
            {/*            })*/}
            {/*            :*/}
            {/*            <div className={"info"}>*/}
            {/*                В данный момент список запросов пуст.*/}
            {/*            </div>*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    )
}

// const RequestRow = (props) => {
//     return (
//         <Popup key={`coach-info-popup ${props.request.user_timestamp} ${isSend}`}
//                trigger={
//                    <div className={"request-row"}>
//                        <div style={{fontWeight: "bolder"}}>
//                            {`${props.request.day}.${date[0]}.${date[1]} ${props.request.time}`}
//                        </div>
//                        <div>
//                            {props.request.user_topic}
//                        </div>
//                        <div style={{fontWeight: "bolder"}}>
//                            Нажмите для просмотра деталей
//                        </div>
//                    </div>
//                }
//                modal
//                nested
//         >
//             {() => (
//                 <div>
//                     <div id="get-advice" className="main-popup user-request-personal-training">
//                         <form className="form">
//
//                         </form>
//
//
//                     </div>
//                 </div>
//             )}
//         </Popup>
//     )
// }