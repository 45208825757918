import React from 'react';
import s from './styles.module.css'

const Row = ({isCategory = false,style, hidden = false, children, min = 100, max = 200, align = 'center', type}) => {
    return (
        <div className={s.row} style={{
            minWidth: min,
            maxWidth: max,
            textAlign: align,
            fontWeight: isCategory && 600,
            fontSize: 14,
            justifyContent: align === 'left' ? 'flex-start' : 'center',
            display: hidden && 'none',
            backgroundColor: type == "avg" ? "#2c383f" : null,
            ...style
        }}>
            <p>
                {children}
            </p>
        </div>
    );
};

export default Row;
