import {WIKI_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import Cookies from "js-cookie";
import {ChangeMainLoading} from "./mainReducer";

const SET_LIST = 'SET_LIST';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const ADD_ROLE_ACCESS = 'ADD_ROLE_ACCESS';
const REMOVE_ROLE_ACCESS = 'REMOVE_ROLE_ACCESS';
const CHANGE_CATEGORY = 'CHANGE_CATEGORY';
const SEARCH_SHOW_CATEGORY = 'SEARCH_SHOW_CATEGORY';
const SEARCH_TEXT = 'SEARCH_TEXT';

let initialState = {
    pages: [],
    searchPages: [],
    categoriesList: [],
    currentPage: null
};

const wikiReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_TEXT: {
            if (!action.words[0]) return {
                ...state,
                searchPages: [...state.pages]
            }
            let searchPages = [];

            state.pages.forEach(wiki => {
                let matches = 0;
                let tags = wiki.tags.toLowerCase().split(" ");
                let contents = wiki.content.toLowerCase().split(" ");
                let names = wiki.name.toLowerCase().split(" ");

                tags.forEach(tag => {
                    action.words.forEach(word => {
                        if (tag == word) matches++;
                    })
                })
                contents.forEach(content => {
                    action.words.forEach(word => {
                        if (content == word) matches++;
                    })
                })
                names.forEach(name => {
                    action.words.forEach(word => {
                        if (name == word) matches++;
                    })
                })

                action.words.forEach(word => {
                    if (wiki.tags.toLowerCase().includes(word) ||
                        wiki.content.toLowerCase().includes(word) ||
                        wiki.name.toLowerCase().includes(word)) matches++;
                })

                if (matches != 0) searchPages.push({ ...wiki, matches })
            });

            if (searchPages[0]) searchPages.sort((b, a) => a.matches - b.matches)

            return {
                ...state,
                searchPages: [...searchPages]
            }
        }
        case SEARCH_SHOW_CATEGORY: {
            let pages = state.pages.filter(page => page.category == action.name)
            return {
                ...state,
                searchPages: [...pages]
            }
        }
        case SET_LIST:
            if (action.pages[0]) action.pages.sort((b, a) => a.timestamp - b.timestamp);
            let categoriesList = [];
            if (action.pages[0]) {
                categoriesList = action.pages.map(page => page.category)
                categoriesList = uniq(categoriesList);
            }
            return {
                ...state,
                pages: action.pages,
                searchPages: action.pages,
                categoriesList: categoriesList
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.page
            };
        case ADD_ROLE_ACCESS: {
            return {
                ...state,
                currentPage: {
                    ...state.currentPage,
                    rolesAccesses: [...state.currentPage.rolesAccesses, {name: action.roleName}]
                }
            }
        }
        case REMOVE_ROLE_ACCESS: {
            let accessIndex = state.currentPage.rolesAccesses.findIndex(x => x.name == action.roleName);
            if (accessIndex >= 0) state.currentPage.rolesAccesses.splice(accessIndex, 1)
            return {
                ...state,
                currentPage: {
                    ...state.currentPage,
                    rolesAccesses: [...state.currentPage.rolesAccesses]
                }
            }
        }
        case CHANGE_CATEGORY: {
            let newCategoriesList = [...state.categoriesList];
            if (!newCategoriesList.includes(action.name)) newCategoriesList.push(action.name)
            return {
                ...state,
                currentPage: {
                    ...state.currentPage,
                    category: action.name
                },
                categoriesList: [...newCategoriesList]
            }
        }
        default:
            return state;
    }
};

export const SetList = (pages) => ({type: SET_LIST, pages});
export const SetCurrentPage = (page) => ({type: SET_CURRENT_PAGE, page})
const AddRoleAccessAC = (roleName) => ({type: ADD_ROLE_ACCESS, roleName})
const RemoveRoleAccessAC = (roleName) => ({type: REMOVE_ROLE_ACCESS, roleName})
const ChangeCategoryAC = (name) => ({type: CHANGE_CATEGORY, name})
export const SearchCategory = (name) => ({ type: SEARCH_SHOW_CATEGORY, name})
export const SearchTextAC = (words) => ({ type: SEARCH_TEXT, words})

export const SearchText = (text) => {
    return (dispatch) => {
        let words = text.toLowerCase().split(" ");
        dispatch(SearchTextAC(words))
    }
}

export const ChangeCategory = (name, wiki_id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            wiki_info: {
                id: wiki_id,
                category: name
            }
        }

        WIKI_API.changeCategory(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение категории", data.message))
                    return
                }
                dispatch(ChangeCategoryAC(name))
                dispatch(AddNotification("success", "Изменение категории", "Название изменено"))
            })
    }
}
export const AddRoleAccess = (wiki_id, role_name) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            wiki_info: {
                id: wiki_id,
                role_name
            }
        }
        WIKI_API.addRoleAccess(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Выдача доступа к статье", data.message))
                    return
                }
                dispatch(AddRoleAccessAC(role_name))
            })
    }
}

export const RemoveRoleAccess = (wiki_id, role_name) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            wiki_info: {
                id: wiki_id,
                role_name
            }
        }
        WIKI_API.removeRoleAccess(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление доступа к статье", data.message))
                    return
                }
                dispatch(RemoveRoleAccessAC(role_name))
            })
    }
}

export const GetList = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        WIKI_API.getList()
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение списка статей", data.message))
                    return
                }
                dispatch(SetList(data))
            })
    }
}
export const GetPage = (id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        WIKI_API.getPage(id)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Страница статьи", data.message))
                    return
                }
                dispatch(SetCurrentPage(null))
                dispatch(SetCurrentPage(data))
            })
    }
}
export const Remove = (wiki_id, history) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        const body = {
            info: {
                email: Cookies.get("email"),
                id: Cookies.get("id")
            },
            wiki_info: {
                id: wiki_id
            },
            access_token: Cookies.get("token")
        }
        WIKI_API.remove(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление статьи", data.message))
                    return
                }
                history.push('/wiki/list');
                dispatch(AddNotification("success", "Удаление статьи", "Статья удалена!"))
            })
    }
}

export const SaveWiki = (access_token, wikiName, wikiContent, wikiTags,
                         email, id, username, type = 2, wikiId = 1, history = null) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        if (!wikiName || !wikiContent || !wikiTags) {
            dispatch(ChangeMainLoading(false))
            dispatch(AddNotification("error", "Создание/изменение статьи", "Все поля, что присутствуют на странице должны быть заполнены!\n\nБывает такое, что всё заполнено, но ошибка все равно появляется, решение:\nНажмите на форму ввода контента статьи и снова на кнопку сохранения"))
            return;
        }
        const body = {
            type,
            info: {
                access_token, email, id, username
            },
            wiki_info: {
                category: "none",
                name: wikiName,
                tags: wikiTags,
                content: wikiContent,
                id: wikiId
            },
            access_token
        }
        WIKI_API.saveWiki(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание/изменение статьи", data.message))
                    return
                }
                if (type == 2) {
                    // window.location.href = `/wiki/${data}`
                    history.push(`/wiki/${data}`);
                    dispatch(AddNotification("success", "Создание статьи", "Статья создана!"))
                    return;
                }
                dispatch(AddNotification("success", "Изменение статьи", "Сохранено"))
            })
    }
}

function uniq(a) {
    return a.sort().filter(function (item, pos, ary) {
        return !pos || item != ary[pos - 1];
    });
}

export default wikiReducer;