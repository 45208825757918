import {DISK_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";

const SET_FILES_LIST = 'SET_FILES_LIST';
const SET_NEW_FILE = 'SET_NEW_FILE';
const PUSH_TO_STACK = 'PUSH_TO_STACK';
const DELETE_FILE = 'DELETE_FILE';
const MINUS_UPLOAD_FILES_COUNT = 'MINUS_UPLOAD_FILES_COUNT';
const PLUS_UPLOAD_FILES_COUNT = 'PLUS_UPLOAD_FILES_COUNT';
const DISK_SORT_TABLE = 'DISK_SORT_TABLE';

let initialState = {
    filesList: [],
    currentDir: null,
    dirStack: [],
    dirNamesStack: [],
    uploadFilesCount: 0
};

const diskReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISK_SORT_TABLE: {
            let sorted = [];
            if (action.isByStart) {
                sorted = state.filesList.sort((a, b) => a[action.tableName].localeCompare(b[action.tableName]))
            } else {
                sorted = state.filesList.sort((b, a) => a[action.tableName].localeCompare(b[action.tableName]))
            }
            return {
                ...state,
                filesList: [...sorted]
            }
        }
        case MINUS_UPLOAD_FILES_COUNT: {
            return {
                ...state,
                uploadFilesCount: state.uploadFilesCount - 1
            }
        }
        case PLUS_UPLOAD_FILES_COUNT: {
            return {
                ...state,
                uploadFilesCount: state.uploadFilesCount + 1
            }
        }
        case DELETE_FILE: {
            return {
                ...state,
                filesList: [...state.filesList.filter(file => file._id != action.fileId)]
            }
        }
        case SET_FILES_LIST:
            return {
                ...state,
                currentDir: action.currentDir,
                filesList: [...action.filesList],
                dirStack: action.dirStack ? [...action.dirStack] : [...state.dirStack]
            };
        case SET_NEW_FILE: {
            return {
                ...state,
                filesList: [...state.filesList, action.file]
            }
        }
        case PUSH_TO_STACK:
            return {
                ...state,
                dirStack: [...state.dirStack, action.dir],
                dirNamesStack: [...state.dirNamesStack, action.dirName]
            };
        default:
            return state;
    }
};

export const SetFilesList = (filesList, currentDir = null, dirStack = null) => ({type: SET_FILES_LIST, filesList, currentDir, dirStack});
export const SetNewFile = (file) => ({type: SET_NEW_FILE, file})
export const PushToStack = (dir, dirName) => ({type: PUSH_TO_STACK, dir, dirName});
export const DeleteFileFromState = (fileId) => ({type: DELETE_FILE, fileId})
const MinusUploadFilesCount = () => ({type: MINUS_UPLOAD_FILES_COUNT})
const PlusUploadFilesCount = () => ({type: PLUS_UPLOAD_FILES_COUNT})
export const sortDiskTable = (tableName, isByStart) => ({ type: DISK_SORT_TABLE, tableName, isByStart})

export const DeleteFile = (file) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        DISK_API.deleteFile(file)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "База файлов", data.message))
                    return
                }
                dispatch(DeleteFileFromState(file._id))
            })
    }
}

export const DownloadFile = (file) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        DISK_API.downloadFile(file)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "База файлов", data.message))
                    return
                }
            })
    }
}
export const DownloadDir = (file) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        DISK_API.downloadDir(file)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "База файлов", data.message))
                    return
                }
            })
    }
}
export const PreviewImage = (file) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        DISK_API.previewImage(file)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "База файлов", data.message))
                    return
                }
            })
    }
}

export const UploadFile = (file, dirId = null) => {
    return (dispatch) => {
        dispatch(PlusUploadFilesCount())
        DISK_API.uploadFile(file, dirId)
            .then(r => {
                dispatch(MinusUploadFilesCount())
                if (r.data.resultCode && r.data.resultCode != 1) {
                    dispatch(AddNotification("error", "База файлов", r.data.message))
                    return
                }
                dispatch(SetNewFile(r.data))
            })
    }
}
export const CreateDir = (dirName, parent) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        DISK_API.createDir(dirName, parent)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "База файлов", data.message))
                    return
                }
                dispatch(SetNewFile(data))
            })
    }
}

export const GetFiles = (currentDir = null, supportTask = null) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        DISK_API.getFiles(currentDir, supportTask)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "База файлов", data.message))
                    return
                }
                if (supportTask && data.currentDir) {
                    dispatch(SetFilesList(data.files, data.currentDir, data.dirStack))
                } else {
                    dispatch(SetFilesList(data, currentDir))
                }
            })
    }
}
export default diskReducer;