import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import WikiCreator from "./wikiCreator";
import {
    AddRoleAccess,
    Remove,
    RemoveRoleAccess,
    SaveWiki,
    ChangeCategory,
    GetList
} from "../../../redux/reducers/wikiReducer";
import {GetRolesList} from "../../../redux/reducers/rolesReducer";

class WikiCreatorContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.GetList()
        if (!this.props.type) this.props.changeCurrentPage("WIKI : Редактор статьи")
        this.props.GetRolesList(this.props.AuthInfo.id, this.props.AuthInfo.email, this.props.AuthInfo.accessToken)
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <WikiCreator {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer,
    WikiInfo: state.wikiReducer,
    RolesInfo: state.rolesReducer
})

export default compose(
    connect(mapStateToProps, {
        SaveWiki, GetRolesList, AddRoleAccess, RemoveRoleAccess, Remove, ChangeCategory, GetList
    }),
    withRouter
)(WikiCreatorContainer)