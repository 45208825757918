import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import moment from "moment";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import Select from "react-dropdown-select";

// SPINS 444471730732269569
// CASH 743480972816678922
// MTT 928562934412607500

const weekDays = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"]


const UserSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать"}
            labelField={"username"}
            valueField={"username"}
            sortBy={"username"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            multi
            options={props.options}
            values={props.selected && props.selected[0] ? props.selected : []}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}

export default function Scheduler(props) {

    return (
        <div className="support-users-list">
            <div className={"support-users-list-header"}
                 style={{gridTemplateColumns: `1fr 1fr 1fr`}}>
                <div className={"item"}>
                    Задача
                </div>
                <div className={"item"}>
                    Последнее оповещение
                </div>
                <div className={"item"}>
                    Следующее оповещение
                </div>
            </div>

            {
                props.SchedulerInfo.list.map((e, i) => {
                    return e.is_active ? <Row key={`${e.topic} ${i}`} row={e} i={i}
                                              EditRow={props.EditRow} users={props.SchedulerInfo.users}/> : null
                })
            }
        </div>
    )
}

const Row = (props) => {
    const timestamp_first_notify = moment(+props.row.timestamp_first_notify);
    const timestamp_latest_notify = moment(+props.row.timestamp_latest_notify);
    const timestamp_next_notify = moment(+props.row.timestamp_next_notify);

    const [confirmCancel, setConfirmCancel] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(props.row.users && props.row.users[0] ? props.row.users : []);

    const handleCancelTask = () => {
        if (!confirmCancel) {
            setConfirmCancel(true)
            setTimeout(() => {
                setConfirmCancel(false)
            }, 5000)
            return;
        }
        props.EditRow("hide", {_id: props.row._id})
    }
    const handleEditUsers = (id, username, discord_server_id, selected) => {
        props.EditRow("edit", {
            type: "users",
            subType: selected ? "remove" : "add",
            _id: props.row._id,
            user_id: id,
            username, discord_server_id
        })
    }
    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            }
        }
    });

    const handleSelectedUsers = (e) => {
        setSelectedUsers(e && e[0] ? e : []);
        props.EditRow("edit", {
            type: "users",
            users: e && e[0] ? e : [],
            _id: props.row._id
        })
    }

    return (
        <Popup key={props.row.topic + " " + "taskRow" + `${props.i}`}
               trigger={
                   <div className={"support-users-list-items"}
                        style={{gridTemplateColumns: `1fr 1fr 1fr`}}>
                       <div className={"item"}>
                           {props.row.topic}
                       </div>
                       <div className={"item"}>
                           {timestamp_latest_notify.format('DD.MM.YYYY HH:mm')}
                       </div>
                       <div className={"item"}>
                           {timestamp_next_notify.format('DD.MM.YYYY HH:mm')}
                       </div>
                   </div>
               }
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="main-popup">
                        <form className="form" style={{color: "white"}}>
                            <div style={{position: "fixed", right: "20px", top: "10px"}}>

                                <div className={"default-button red-back"}
                                     onClick={handleCancelTask}
                                     style={confirmCancel ? {backgroundColor: "red"} : null}>
                                    {confirmCancel ? "Кликните снова, чтобы удалить" : "Удалить из расписания"}
                                </div>
                            </div>

                            <div className="formgroup">
                                        <span style={{
                                            color: "#9fa2c2",
                                            fontWeight: "bolder"
                                        }}></span> {props.row.topic}
                            </div>

                            <div className="formgroup">
                                <span style={{
                                    color: "#9fa2c2",
                                    fontWeight: "bolder"
                                }}>Тип:</span> {props.row.notify_type} {props.row.notify_helper ? `(${props.row.notify_type == "EVERY_WEEK_DAY" ? weekDays[props.row.notify_helper - 1] : props.row.notify_helper})` : null}
                            </div>

                            <div className="formgroup">
                                <span style={{
                                    color: "#9fa2c2",
                                    fontWeight: "bolder"
                                }}>Первое оповещение:</span> {timestamp_first_notify.format('DD.MM.YYYY HH:mm')}
                            </div>

                            <div className="formgroup">
                                <span style={{
                                    color: "#9fa2c2",
                                    fontWeight: "bolder"
                                }}>Последнее оповещение:</span> {timestamp_latest_notify.format('DD.MM.YYYY HH:mm')}
                            </div>

                            <div className="formgroup">
                                <span style={{
                                    color: "#9fa2c2",
                                    fontWeight: "bolder"
                                }}>Следующее оповещение:</span> {timestamp_next_notify.format('DD.MM.YYYY HH:mm')}
                            </div>

                            <div>
                                <div
                                    style={{color: "#9fa2c2", fontWeight: "bolder"}}>
                                    Ответственные
                                </div>
                                <UserSelector options={props.users}
                                              selected={selectedUsers}
                                              handleSelect={handleSelectedUsers}/>
                            </div>

                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}