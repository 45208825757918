import React from 'react';
import {Switch, Route} from "react-router-dom";
import MainContainer from "../pages/Main/mainContainer";
import FinResultsContainer from "../pages/FinResults/finResultsContainer";
import TransfersContainer from "../pages/Transfers/transfersContainer";
import ExchangerContainer from "../pages/Exchanger/exchangerContainer";
import TrainingsContainer from "../pages/Trainings/User/trainingsContainer";
import RefProgramContainer from "../pages/ref_program/refProgramContainer";
import SalaryContainer from "../pages/Salary/salaryContainer";
import WikiListContainer from "../pages/Wiki/wikiListContainer";
import WikiSearchContainer from "../pages/Wiki/wikiSearchContainer";
import WikiCreatorContainer from "../pages/Wiki/wikiCreatorContainer";
import WikiPageContainer from "../pages/Wiki/wikiPageContainer";
import LearnContainer from "../pages/MainFaq/learnContainer";
import LeakfinderContainer from "../pages/Leakfinder/leakfinderContainer";
import LeakfinderSpinContainer from "../pages/Leakfinder/leakfinderSpinContainer";

const Routers = () => {
    return (
        <Switch>
            <Route exact path="/" render={() => <MainContainer/>}/>
            <Route exact path="/fin_results" render={() => <FinResultsContainer/>}/>
            <Route exact path="/transfers" render={() => <TransfersContainer/>}/>
            <Route exact path="/exchanger" render={() => <ExchangerContainer/>}/>
            <Route exact path="/trainings" render={() => <TrainingsContainer/>}/>
            <Route exact path="/salary" render={() => <SalaryContainer/>}/>
            <Route exact path="/leakfinder/mtt" render={() => <LeakfinderContainer/>}/>
            <Route exact path="/leakfinder/spin" render={() => <LeakfinderSpinContainer/>}/>
            <Route exact path="/ref-program" render={() => <RefProgramContainer/>}/>
            <Route path="/wiki/list" render={() => <WikiListContainer/>}/>
            <Route path="/wiki/search" render={() => <WikiSearchContainer/>}/>
            <Route path="/wiki/creator" render={() => <WikiCreatorContainer/>}/>
            <Route path="/wiki/:id" render={() => <WikiPageContainer/>}/>
            <Route path="/faq/main" render={() => <LearnContainer/>}/>
        </Switch>
    );
};

export default Routers;