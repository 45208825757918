import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import WelcomeMessageEditor from "./welcomeMessageEditor";
import {
    AddWelcomeMessageInfo, EditWelcomeMessageInfo,
    GetWelcomeMessageInfo, RemoveWelcomeMessageInfo
} from "../../../../redux/reducers/supportReducer";

class WelcomeMessageEditorContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("DS BOT Welcome message editor");
        this.props.GetWelcomeMessageInfo();
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <WelcomeMessageEditor {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer,
    SupportInfo: state.supportReducer
})

export default compose(
    connect(mapStateToProps, {
        GetWelcomeMessageInfo, AddWelcomeMessageInfo, EditWelcomeMessageInfo, RemoveWelcomeMessageInfo
    }),
    withRouter
)(WelcomeMessageEditorContainer)