import React, {useState, useRef} from "react";
import {NavLink} from "react-router-dom";
import Popup from "reactjs-popup";
import {CompactPicker} from 'react-color';
import MainLoading from "../../common/mainLoading";

export default function PagesList(props) {
    let createPageName = React.createRef();
    let createPageDocId = React.createRef();
    let createPageDescription = React.createRef();
    let createPageSubCategory = React.createRef();
    let createPageSubCategoryCreator = React.createRef();

    let createPage = () => {
        props.CreatePage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username,
            props.AuthInfo.accessToken, createPageName.current.value,
            createPageDescription.current.value, props.PagesInfo.creationDocTypeName,
            createPageDocId.current.value, props.PagesInfo.creationCategoryName, props.PagesInfo.creationSubCategoryName);
    }

    let ChangeCreationCategoryName = (e) => {
        props.ChangeCreationCategoryName(e.target.accessKey)
    }
    let ChangeCreationDocTypeName = (e) => {
        props.ChangeCreationDocTypeName(e.target.accessKey)
    }
    let ChangeSubCategoryName = (e) => {
        if (e && e.target && e.target.accessKey) {
            props.ChangeCreationSubCategoryName(e.target.accessKey)
        } else {
            props.ChangeCreationSubCategoryName(createPageSubCategoryCreator.current.value)
        }
    };

    return (
        <div className="pages-list">
            <Popup key={props.PagesInfo.pagesList.length + " " + "creationPagePopup"}
                   trigger={<a className={"creator"}>Создать страницу</a>}
                   modal
                   nested
            >
                {() => (
                    <div>
                        <div id="get-advice" className="main-popup">
                            <form className="form">
                                <div className="formgroup">
                                    <input ref={createPageName} type="text" placeholder="Название страницы"/>
                                </div>
                                <DocTypeEditorPopup DocTypeName={props.PagesInfo.creationDocTypeName}
                                                    ChangeDocTypeName={ChangeCreationDocTypeName}/>
                                <div className="formgroup">
                                    <input type="text" ref={createPageDocId}
                                           placeholder="ID документа/таблицы. Если график/фрейм - ссылка"/>
                                </div>
                                <div className="formgroup">
                                    <input type="text" ref={createPageDescription}
                                           placeholder="Краткое описание"/>
                                </div>
                                <CategoryEditorPopup PagesInfo={props.PagesInfo} CategoriesInfo={props.CategoriesInfo}
                                                     ChangeCategoryName={ChangeCreationCategoryName}
                                                     CategoryName={props.PagesInfo.creationCategoryName}/>
                                <SubCategoryEditorPopup SubCategoryName={props.PagesInfo.creationSubCategoryName}
                                                        createPageSubCategoryCreator={createPageSubCategoryCreator}
                                                        ChangeSubCategoryName={ChangeSubCategoryName} PagesInfo={props.PagesInfo}
                                />
                            </form>
                            <button onClick={createPage} className={"send-btn"}>Создать</button>
                            {
                                props.PagesInfo.pageCreationError ?
                                    <div className={"error"}>{props.PagesInfo.pageCreationError}</div>
                                    : null
                            }
                        </div>
                    </div>
                )}
            </Popup>

            <div className={"pages-list-header"}>
                <div className={"item"}>
                    Название
                </div>
                <div className={"item"}>
                    Приоритет
                </div>
                <div className={"item"}>
                    Категория
                </div>
                <div className={"item"}>
                    Подкатегория
                </div>
                <div className={"item"}>
                    Тип
                </div>
                <div className={"item"}>
                    Идентификатор
                </div>
                <div className={"item"}>
                    Описание
                </div>
            </div>
            {props.PagesInfo.pagesList.map((page, i) => <PageBlock SetPageCreationError={props.SetPageCreationError}
                                                                   EditPage={props.EditPage}
                                                                   DeletePage={props.DeletePage} page={page}
                                                                   AuthInfo={props.AuthInfo} key={page.name + " " + "i"}
                                                                   PageIndex={i}
                                                                   CategoriesInfo={props.CategoriesInfo} PagesInfo={props.PagesInfo}/>)}
        </div>
    );
}

const PageBlock = (props) => {
    let createPageSubCategoryCreator = React.createRef();
    let editPageName = React.createRef();
    let editPagePriority = React.createRef();
    let editPageDocId = React.createRef();
    let editPageDescription = React.createRef();

    const colorEditor = useRef(null);
    const [color, setColor] = useState();

    let handleColorChangeComplete = (color) => {
        setColor(color.hex);
        props.EditPage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.page.name, "color", color.hex)
    };

    let DeletePage = (e) => {
        props.DeletePage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.page.name)
    }
    const ChangePageName = (e) => {
        props.EditPage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.page.name, "name", editPageName.current.value)
    }
    const ChangePagePriority = (e) => {
        props.EditPage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.page.name, "priority", editPagePriority.current.value)
    }
    const ChangeTypeName = (e) => {
        props.EditPage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.page.name, "type", e.target.accessKey)
    }
    const ChangePageDocId = (e) => {
        props.EditPage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.page.name, "docId", editPageDocId.current.value)
    }
    const ChangePageDescription = (e) => {
        props.EditPage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.page.name, "description", editPageDescription.current.value)
    }
    const ChangeCategoryName = (e) => {
        props.EditPage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.page.name, "categoryName", e.target.accessKey)
    }
    const ChangeSubCategoryName = (e) => {
        if (e && e.target && e.target.accessKey) {
            props.EditPage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
                props.page.name, "subCategoryName", e.target.accessKey)
        } else {
            props.EditPage(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
                props.page.name, "subCategoryName", createPageSubCategoryCreator.current.value)
        }
    }

    return (
        props.isLoading ? <MainLoading/>
            :
        <Popup key={props.PageIndex + " " + "moderatePagePopup"}
               trigger={
                   <div className={"pages-list-items"}>
                       <div style={{color: props.page.color}} className={"item"}>
                           {props.page.name}
                       </div>
                       <div className={"item"}>
                           {props.page.priority}
                       </div>
                       <div className={"item"}>
                           {props.page.categoryName}
                       </div>
                       <div className={"item"}>
                           {props.page.subCategoryName}
                       </div>
                       <div className={"item"}>
                           {props.page.type}
                       </div>
                       <div className={"item"}>
                           {props.page.docId}
                       </div>
                       <div className={"item"}>
                           {props.page.description}
                       </div>
                   </div>}
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="main-popup">
                        <form className="form">
                            <div className="formgroup">
                                <input ref={editPageName} type="text" title="Название страницы" defaultValue={props.page.name}
                                       onBlur={ChangePageName}/>
                            </div>

                            <div className="subcategory-color-picker">
                                <div className={"subcategory-color-picker-name"}>Цвет названия страницы</div>
                                <CompactPicker
                                    color={color}
                                    onChangeComplete={handleColorChangeComplete}
                                />
                            </div>

                            <div className="formgroup">
                                <input ref={editPagePriority} type="text" title="Приоритет страницы (number)" defaultValue={props.page.priority}
                                       onBlur={ChangePagePriority} placeholder={"Приоритет страницы (number)"}/>
                            </div>

                            <DocTypeEditorPopup DocTypeName={props.page.type}
                                                ChangeDocTypeName={ChangeTypeName}/>

                            <div className="formgroup">
                                <input ref={editPageDocId} type="text" title="ID документа/таблицы. Если график/фрейм - ссылка" defaultValue={props.page.docId}
                                       onBlur={ChangePageDocId}/>
                            </div>

                            <div className="formgroup">
                                <input ref={editPageDescription} type="text" title="Краткое описание" defaultValue={props.page.description}
                                       onBlur={ChangePageDescription}/>
                            </div>

                            <CategoryEditorPopup PagesInfo={props.PagesInfo} CategoriesInfo={props.CategoriesInfo}
                                                 ChangeCategoryName={ChangeCategoryName}
                                                 CategoryName={props.page.categoryName}/>
                            <SubCategoryEditorPopup SubCategoryName={props.page.subCategoryName}
                                                    createPageSubCategoryCreator={createPageSubCategoryCreator}
                                                    ChangeSubCategoryName={ChangeSubCategoryName} PagesInfo={props.PagesInfo}
                            />
                            <div className="formgroup">
                                <div onClick={DeletePage} className={"main-delete-role"}>УДАЛИТЬ СТРАНИЦУ</div>
                            </div>
                        </form>

                        { props.PagesInfo.pageCreationError ?
                            <div className={"error"}>{props.PagesInfo.pageCreationError}</div>
                            : null
                        }
                    </div>
                </div>
            )}
        </Popup>
    )
}

let DocTypeEditorPopup = (props) => {
    return (
        <Popup key={props.DocTypeName}
               trigger={<a className={"creation-page-selector-popup-name"}>Тип
                   страницы: {props.DocTypeName}</a>}
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="creation-page-category-selector-popup">
                        <button accessKey="Документ" onClick={props.ChangeDocTypeName}
                                className={"send-btn"}>Документ
                        </button>
                        <button accessKey="Таблица" onClick={props.ChangeDocTypeName}
                                className={"send-btn"}>Таблица
                        </button>
                        <button accessKey="График" onClick={props.ChangeDocTypeName}
                                className={"send-btn"}>График
                        </button>
                        <button accessKey="Фрейм" onClick={props.ChangeDocTypeName}
                                className={"send-btn"}>Фрейм
                        </button>
                        <button accessKey="Курс" onClick={props.ChangeDocTypeName}
                                className={"send-btn"}>Курс
                        </button>
                    </div>
                </div>
            )}
        </Popup>
    )
}
let CategoryEditorPopup = (props) => {
    return (
        <Popup key={props.CategoryName}
               trigger={<a className={"creation-page-selector-popup-name"}>Название категории: {props.CategoryName}</a>}
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="creation-page-category-selector-popup">
                        {
                            props.CategoriesInfo.categoriesList.map((category, i) => {
                                return (
                                    <button key={category.name + " " + i} accessKey={category.name}
                                            onClick={props.ChangeCategoryName}
                                            className={"send-btn"}>{category.name}</button>
                                )
                            })
                        }
                    </div>
                </div>
            )}
        </Popup>
    )
}
let SubCategoryEditorPopup = (props) => {
    return (
        <div className="formgroup page-creator-subCategory">
            <Popup key={props.SubCategoryName}
                   trigger={<a className={"creation-page-selector-popup-name"}>Название
                       подкатегории: {props.SubCategoryName}</a>}
                   modal
                   nested
            >
                {() => (
                    <div>
                        <div id="get-advice" className="creation-page-category-selector-popup">
                            <div className={"subCategoryCreator"}>
                                <input type="text" ref={props.createPageSubCategoryCreator}
                                       placeholder="Если нужно ввести вручную"/>
                                <button onClick={props.ChangeSubCategoryName}
                                        className={"subCategoryCreatorButton"}>
                                    Применить
                                </button>
                            </div>

                            {
                                props.PagesInfo.subCategoriesList.map((subCategory, i) => {
                                    return (
                                        <button key={subCategory + " " + i}
                                                accessKey={subCategory}
                                                onClick={props.ChangeSubCategoryName}
                                                className={"send-btn"}>{subCategory}</button>
                                    )
                                })
                            }
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    )
}