import React, {useState} from 'react';
import Container from "../container";
import s from './styles.module.css'
import classNames from "classnames";
import Items from "./item";
import CustomSelect from "../../../common/select";
import CustomDatePicker from "../../../common/date_picker";
import {useWindowSize} from "../../../hooks/useWindowSize";
import moment from "moment-timezone";

const ThirdContent = (props) => {
    const {width, height} = useWindowSize()

    const isMobile = width <= 768

    const [value, setValue] = useState({
        union: 'all',
        club: 'all',
        limit: 'all',
        account: 'all',

        network: 'all',
        player_name: 'all'
    })

    const [isNeedUpdateRows, setIsNeedUpdateRows] = useState(false)

    const dateInit = new Date()

    const dateStartDate = new Date(dateInit.getFullYear(),dateInit.getMonth(),1)
    const dateEndDate = new Date()

    const [date, setDate] = useState([
        {
            startDate: new Date(+dateStartDate),
            endDate: new Date(+dateEndDate),
            key: 'selection',
            reqStartDate: `${dateStartDate.getFullYear()}/${dateStartDate.getMonth() + 1}/${dateStartDate.getDate()}`,
            reqEndDate: `${dateEndDate.getFullYear()}/${dateEndDate.getMonth() + 1}/${dateEndDate.getDate()}`

        },
    ]);

    const filters = {
        account: value.account,
        limit: value.limit,
        club_name: value.club,
        club_union: value.union,
        startDate: date[0].reqStartDate,
        endDate: date[0].reqEndDate,

        network: value.network,
        player_name: value.player_name
    }

    const handleSetValue = (value) => {
        setIsNeedUpdateRows(true)
        setValue(value)
    }

    const handleSetDate = (date) => {
        setDate(date)
        // setIsNeedUpdateRows(true)
    }

    if (isNeedUpdateRows) {
        setIsNeedUpdateRows(false);
        props.GetFormularRowsByPeriod(filters)
    }

    const [selectedData, setSelectedData] = useState([
        {id: 1, isShowMenu: false},
        {id: 2, isShowMenu: false},
        {id: 3, isShowMenu: false},
        {id: 4, isShowMenu: false},
    ])

    return (
        props.AuthInfo.verificator && props.AuthInfo.verificator.direction && (props.AuthInfo.verificator.direction == "CASH" || props.AuthInfo.verificator.direction == "ASIA")?
        <Container top='35px' overflow={false}>
            <div className={s.navigate}>
                <div className={classNames(s.box_item, s.first_box_item)}>
                    <p>Период:</p>
                    <div style={{width: '68%'}}>
                        <CustomDatePicker z={isMobile ? 10 : 10} state={date} setState={handleSetDate} filters={filters} GetData={props.GetFormularRowsByPeriod} system={"mainPage"} setIsNeedUpdateRows={setIsNeedUpdateRows}/>
                    </div>
                </div>

                <div className={s.box_item}>
                    <p>AccID:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Выбрать аккаунт'} data={props.MainInfo.mainPageData.uniqueAccounts}
                                      setValue={(element) => handleSetValue({...value, account: element})} value={value.account}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[0].id}
                                      isShowMenu={selectedData[0].isShowMenu}
                        />
                    </div>
                </div>

                <div className={s.box_item}>
                    <p>Union:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Выбрать клуб'} data={props.MainInfo.mainPageData.uniqueUnions}
                                      setValue={(element) => handleSetValue({...value, union: element})} value={value.union}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[1].id}
                                      isShowMenu={selectedData[1].isShowMenu}
                        />
                    </div>
                </div>

                <div className={s.box_item}>
                    <p>Club:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Выбрать клуб'} data={props.MainInfo.mainPageData.uniqueClubs}
                                      setValue={(element) => handleSetValue({...value, club: element})} value={value.club}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[2].id}
                                      isShowMenu={selectedData[2].isShowMenu}
                        />
                    </div>
                </div>

                <div className={s.box_item}>
                    <p>Limit:</p>
                    <div style={{width: '68%'}}>
                        <CustomSelect title={'Выбрать лимит'} data={props.MainInfo.mainPageData.uniqueLimits}
                                      setValue={(element) => handleSetValue({...value, limit: element})} value={value.limit}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      id={selectedData[3].id}
                                      isShowMenu={selectedData[3].isShowMenu}
                        />
                    </div>
                </div>

            </div>

            <div className={s.box_items}>
                <Items {...props.MainInfo.mainPageData.sums}/>
            </div>
        </Container>
            : props.AuthInfo.verificator && props.AuthInfo.verificator.direction && props.AuthInfo.verificator.direction == "MTT" ?
                <Container top='35px' overflow={false}>
                    <div className={s.navigate}>
                        <div className={classNames(s.box_item, s.first_box_item)}>
                            <p>Период:</p>
                            <div style={{width: '68%'}}>
                                <CustomDatePicker z={isMobile ? 10 : 10} state={date} setState={handleSetDate} filters={filters} GetData={props.GetFormularRowsByPeriod} system={"mainPage"} setIsNeedUpdateRows={setIsNeedUpdateRows}/>
                            </div>
                        </div>

                        <div className={s.box_item}>
                            <p>Network:</p>
                            <div style={{width: '68%'}}>
                                <CustomSelect title={'Выбрать нетворк'} data={props.MainInfo.mainPageData.uniqueNetworks}
                                              setValue={(element) => handleSetValue({...value, network: element})} value={value.network}
                                              setSelectedData={setSelectedData}
                                              selectedData={selectedData}
                                              id={selectedData[0].id}
                                              isShowMenu={selectedData[0].isShowMenu}
                                />
                            </div>
                        </div>

                        {/*<div className={s.box_item}>*/}
                        {/*    <p>Nickname:</p>*/}
                        {/*    <div style={{width: '68%'}}>*/}
                        {/*        <CustomSelect title={'Выбрать никнейм'} data={props.MainInfo.mainPageData.uniquePlayerNames}*/}
                        {/*                      setValue={(element) => handleSetValue({...value, player_name: element})} value={value.player_name}*/}
                        {/*                      setSelectedData={setSelectedData}*/}
                        {/*                      selectedData={selectedData}*/}
                        {/*                      id={selectedData[1].id}*/}
                        {/*                      isShowMenu={selectedData[1].isShowMenu}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className={s.box_items}>
                        <Items {...props.MainInfo.mainPageData.sums} direction={"MTT"}/>
                    </div>
                </Container>
            : null
    );
};

export default ThirdContent;