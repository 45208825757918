
import React from 'react';
import s from "../styles.module.css";
import classNames from "classnames";

const LastItem = ({index, el, type}) => {
    return (
        <div className={s.last_box}>
            <div className={s.first_last}>
                <div className={classNames(s.img, type === 'red' && s.first_img, type === 'yellow' && s.second_img)}
                     key={index}>
                    <img src={el.icon} alt=""/>
                </div>
                <p className={s.title}>{el.title} <span
                    className={classNames(type === 'red' && s.first_price, type === 'yellow' && s.second_price)}>{el.price}</span>
                </p>

            </div>

        </div>
    );
};

export default LastItem;