import {ChangeMainLoading} from "./mainReducer";
import {AddNotification} from "./notificationsReducer";
import {SCHEDULER_API} from "../../api/util";
import Cookies from "js-cookie";
import {v4} from "uuid";

const SCHEDULER_SET_LIST = 'SCHEDULER_SET_LIST';
const SCHEDULER_EDIT_ROW = 'SCHEDULER_EDIT_ROW';
const SCHEDULER_UPDATE_ROW = 'SCHEDULER_UPDATE_ROW';

const SCHEDULER_SET_CALENDAR_INFO = 'SCHEDULER_SET_CALENDAR_INFO';
const SCHEDULER_ADD_DATES = 'SCHEDULER_ADD_DATES';
const SCHEDULER_REMOVE_DATE = 'SCHEDULER_REMOVE_DATE';

const SCHEDULER_SET_USERS = 'SCHEDULER_SET_USERS';

let initialState = {
    list: [],

    supportsList: [],
    calendar: [],

    users: []
};

const schedulerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SCHEDULER_SET_USERS: {
            return {
                ...state,
                users: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SCHEDULER_SET_CALENDAR_INFO: {
            return {
                ...state,
                supportsList: JSON.parse(JSON.stringify(action.supportsList)),
                calendar: JSON.parse(JSON.stringify(action.calendar))
            }
        }
        case SCHEDULER_ADD_DATES: {
            return {
                ...state,
                calendar: [...state.calendar, ...action.datesList]
            }
        }
        case SCHEDULER_REMOVE_DATE: {
            return {
                ...state,
                calendar: state.calendar.filter(f => f.id !== action.id)
            }
        }
        case SCHEDULER_UPDATE_ROW: {
            if (action.ttype == "create") {
                return {
                    ...state,
                    list: [JSON.parse(JSON.stringify(action.data)), ...state.list]
                }
            }
            let updatedList = JSON.parse(JSON.stringify(state.list));
            let taskRowIndex = updatedList.findIndex(e => e._id == action.data._id);
            if (taskRowIndex != -1) updatedList[taskRowIndex] = JSON.parse(JSON.stringify(action.data));
            return {
                ...state,
                list: JSON.parse(JSON.stringify(updatedList))
            }
        }
        case SCHEDULER_SET_LIST: {
            return {
                ...state,
                list: JSON.parse(JSON.stringify(action.data))
            }
        }
        default:
            return state;
    }
};

const SetList = (data) => ({type: SCHEDULER_SET_LIST, data});
const UpdateRow = (ttype, data) => ({type: SCHEDULER_UPDATE_ROW, ttype, data});

const SetCalendarInfo = (supportsList, calendar) => ({type: SCHEDULER_SET_CALENDAR_INFO, supportsList, calendar});

const AddDates = (datesList) => ({type: SCHEDULER_ADD_DATES, datesList});
const RemoveDate = (id) => ({type: SCHEDULER_REMOVE_DATE, id});
const SetUsers = (data) => ({type: SCHEDULER_SET_USERS, data});

export const GetUsers = () => {
    return dispatch => {
        SCHEDULER_API.getUsers()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение пользователей", data.message))
                    return
                }
                dispatch(SetUsers(data))
            })
    }
}

export const EditUsers = (username, id, discord_server_id, type) => {
    return dispatch => {
        SCHEDULER_API.editUsers(username, id, discord_server_id, type)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение пользователей", data.message))
                    return
                }
                dispatch(SetUsers(data))
            })
    }
}

export const RemoveCalendarDate = (id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        SCHEDULER_API.removeCalendarDate(id)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление даты", data.message))
                    return
                }
                dispatch(RemoveDate(id))
            })
    }
}
export const GetCalendarInfo = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        SCHEDULER_API.getCalendarInfo()
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение расписания", data.message))
                    return
                }
                dispatch(SetCalendarInfo(data.supportsList, data.calendar))
            })
    }
}

export const SchedulerAddDates = (datesList) => {
    return dispatch => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            datesList
        }
        SCHEDULER_API.createCalendarDates(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Работа с расписанием", data.message))
                    return
                }
                dispatch(AddDates(datesList))
            })
    }
}


export const GetList = (user_id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        SCHEDULER_API.getList(user_id)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение расписания", data.message))
                    return
                }
                dispatch(SetList(data))
            })
    }
}
export const EditRow = (type, d) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            type,
            data: {...d}
        }
        SCHEDULER_API.editRow(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Работа с расписанием", data.message))
                    return
                }
                dispatch(UpdateRow(type, data))
            })
    }
}

export default schedulerReducer;

