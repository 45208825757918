import React, { useState, useRef } from "react";

export default function UserSearch(props) {

    let handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            props.AdminSearchUser(event.target.value)
        }
    }

    return (
            <div>
                <div className={"wiki-search"}>
                    <input onKeyPress={handleKeyPress} placeholder={"Поиск: по никнейму / ID / Email"}/>
                </div>
            </div>
    );
}
