import React, {useState} from 'react';
import s from './styles.module.css'
import {accordionItems} from "./left_side_bat.constants";
import ItemNavigate from "../../common/accordion";
import logo from '../../assests/logo.png'
import logoHidden from '../../assests/logo_hidden.png'
import hidden from './icons/Group 3191.png'
import hiddenBack from '../../assests/hiddenBack.png'
import classNames from "classnames";
import {ReactComponent as Main} from "./icons/1.svg";
import {useWindowSize} from "../../hooks/useWindowSize";
import TrainerButton from "../../common/TrainerButton";
import ShopButton from "../../common/ShowButton";
import SelectButton from "../../common/SelectButton";

const LeftSideBar = ({
                         setSelected,
                         selected,
                         showRightMenu,
                         setSelectFirst,
                         selectFirst,
                         setSelectSecond,
                         selectSecond,
                         setSelectThird,
                         selectThird,
                         hiddenLeft,
                         setHiddenLeft,
                         hiddenRight,
                         setHiddenRight,
                         AuthInfo
                     }) => {
    const {width, height} = useWindowSize()

    return (
        <div className={classNames(s.leftSideBar, hiddenLeft && s.hiddenBar)}>
            <>
                <div className={classNames(s.logo, hiddenLeft && s.logog_hidden)}>
                    <img src={!hiddenLeft ? logo : logoHidden} alt="logo"/>
                </div>
                <div className={s.leftSideBarContent}>
                    <div>
                        <SelectButton hiddenLeft={hiddenLeft}/>

                        {accordionItems().map((item, index) => <ItemNavigate
                            AuthInfo={AuthInfo}
                            setSelectThird={setSelectThird}
                            selectThird={selectThird}
                            setSelectFirst={setSelectFirst}
                            selectFirst={selectFirst}
                            setSelectSecond={setSelectSecond}
                            selectSecond={selectSecond}
                            item={item}
                            showRightMenu={showRightMenu}
                            key={index}
                            svgComponent={item.svgComponent}
                            title={item.title}
                            setSelected={setSelected}
                            selected={selected}  {...item}
                            hiddenLeft={hiddenLeft}/>)}

                        <ShopButton hiddenLeft={hiddenLeft}/>
                        <TrainerButton hiddenLeft={hiddenLeft}/>

                    </div>
                </div>
            </>
            {width > 1200 && <div className={classNames(s.hidden, hiddenLeft && s.hiddenHidden)}
                                  onClick={() => {
                                      if (width <= 1600) {
                                          if (hiddenLeft) {
                                              setHiddenLeft(!hiddenLeft)
                                              setHiddenRight(true)
                                          } else {
                                              setHiddenLeft(!hiddenLeft)
                                          }
                                      } else {
                                          setHiddenLeft(!hiddenLeft)
                                      }


                                  }}>
                <img src={!hiddenLeft ? hidden : hiddenBack} alt="hidden"/>
                {!hiddenLeft && <p>Скрыть меню</p>}
            </div>}


        </div>
    );
};

export default LeftSideBar;