import {ADMIN_API, PROFILE_CUSTOM_INFO_API, DEV_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";
import Cookies from "js-cookie";

const SET_USERS_LIST = 'SET_USERS_LIST';
const CHANGE_CURRENT_USER = 'CHANGE_CURRENT_USER';
const CHANGE_ADD_ROLE_ERROR = 'CHANGE_ADD_ROLE_ERROR';
const CHANGE_DELETE_ROLE_ERROR = 'CHANGE_DELETE_ROLE_ERROR';
const CHANGE_AUDIT_ADMIN_ACCESS_CHECKBOX = 'CHANGE_AUDIT_ADMIN_ACCESS_CHECKBOX';
const CHANGE_FS_CHANGER_ADMIN_ACCESS_CHECKBOX = 'CHANGE_FS_CHANGER_ADMIN_ACCESS_CHECKBOX';
const CHANGE_FS_CHANGER_SUPPORT_ACCESS_CHECKBOX = 'CHANGE_FS_CHANGER_SUPPORT_ACCESS_CHECKBOX';
const CHANGE_FS_CHANGER_USER_ACCESS_CHECKBOX = 'CHANGE_FS_CHANGER_USER_ACCESS_CHECKBOX';
const CHANGE_ADMIN_ACCESS_CHECKBOX = 'CHANGE_ADMIN_ACCESS_CHECKBOX';
const CHANGE_AUDITOR_ACCESS_CHECKBOX = 'CHANGE_AUDITOR_ACCESS_CHECKBOX';
const CHANGE_DISK_ACCESS_CHECKBOX = 'CHANGE_DISK_ACCESS_CHECKBOX';
const CHANGE_WIKICREATOR_ACCESS_CHECKBOX = 'CHANGE_WIKICREATOR_ACCESS_CHECKBOX';
const CHANGE_MTSNGSupport_ACCESS_CHECKBOX = 'CHANGE_MTSNGSupport_ACCESS_CHECKBOX';
const CHANGE_MTTSupport_ACCESS_CHECKBOX = 'CHANGE_MTTSupport_ACCESS_CHECKBOX';
const CHANGE_CashSupport_ACCESS_CHECKBOX = 'CHANGE_CashSupport_ACCESS_CHECKBOX';
const CHANGE_SpinSupport_ACCESS_CHECKBOX = 'CHANGE_SpinSupport_ACCESS_CHECKBOX';
const CHANGE_FINANCES_ADMIN_ACCESS_CHECKBOX = 'CHANGE_FINANCES_ADMIN_ACCESS_CHECKBOX'
const CHANGE_REF_SUPPORT_ACCESS_CHECKBOX = 'CHANGE_REF_SUPPORT_ACCESS_CHECKBOX'
const CHANGE_LEADERBOARD_ADMIN_ACCESS_CHECKBOX = 'CHANGE_LEADERBOARD_ADMIN_ACCESS_CHECKBOX'
const ADD_ROLE_TO_USER = 'ADD_ROLE_TO_USER';
const DELETE_ROLE_FROM_USER = 'DELETE_ROLE_FROM_USER';
const ADMIN_FIND_USER_BY_NAME_IN_ARR = 'ADMIN_FIND_USER_BY_NAME_IN_ARR';
const ADMIN_SEARCH_USER = 'ADMIN_SEARCH_USER'
const ADMIN_TEMPLATES_SET_ROWS = 'ADMIN_TEMPLATES_SET_ROWS'
const ADMIN_TEMPLATES_SET_NEW_ROW = 'ADMIN_TEMPLATES_SET_NEW_ROW';
const ADMIN_TEMPLATES_EDIT_ROW = 'ADMIN_TEMPLATES_EDIT_ROW';
const CHANGE_COACH_ACCESS_CHECKBOX = 'CHANGE_COACH_ACCESS_CHECKBOX';
const CHANGE_LEAKFINDER_ADMIN_ACCESS_CHECKBOX = 'CHANGE_LEAKFINDER_ADMIN_ACCESS_CHECKBOX';
const CHANGE_POKERHUB_SUPPORT_ACCESS_CHECKBOX = 'CHANGE_POKERHUB_SUPPORT_ACCESS_CHECKBOX';
const ADMIN_EDIT_PROFILE = "ADMIN_EDIT_PROFILE";

let initialState = {
    usersList: [],
    usersSearchList: [],
    currentUser: null,
    availableRolesForCurrentUser: [],
    addRoleError: null,
    deleteRoleError: null,

    templateRows: []
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_EDIT_PROFILE: {
            let newCurrentUser = JSON.parse(JSON.stringify(state.currentUser));
            newCurrentUser[action.key] = action.newValue;
            return {
                ...state,
                currentUser: newCurrentUser
            }
        }
        case ADMIN_TEMPLATES_EDIT_ROW: {
            const index = state.templateRows.findIndex(e => e._id == action.id);
            const newTemplateRows = [...state.templateRows]

            if (index != -1) {
                if (action.ttype == "isVisible") {
                    newTemplateRows[index].isVisible = !newTemplateRows[index].isVisible;
                } else if (action.ttype == "position") {
                    let nextId = '';
                    let mainDocCopy;

                    newTemplateRows.forEach((doc, i) => {
                        if (doc._id == action.id) {
                            mainDocCopy = JSON.parse(JSON.stringify(doc));
                            if (action.subType == "top") {
                                doc.ru_name = newTemplateRows[i + 1].ru_name;
                                doc.en_name = newTemplateRows[i + 1].en_name;
                                doc.isVisible = newTemplateRows[i + 1].isVisible;
                                nextId = newTemplateRows[i + 1]._id;
                            } else {
                                doc.ru_name = newTemplateRows[i - 1].ru_name;
                                doc.en_name = newTemplateRows[i - 1].en_name;
                                doc.isVisible = newTemplateRows[i - 1].isVisible;
                                nextId = newTemplateRows[i - 1]._id;
                            }
                        }
                    })
                    newTemplateRows.forEach((doc) => {
                        if (doc._id == nextId) {
                            doc.ru_name = mainDocCopy.ru_name;
                            doc.en_name = mainDocCopy.en_name;
                            doc.isVisible = mainDocCopy.isVisible;
                        }
                    })
                }
            }

            return {
                ...state,
                templateRows: JSON.parse(JSON.stringify(newTemplateRows))
            }
        }
        case ADMIN_TEMPLATES_SET_NEW_ROW: {
            return {
                ...state,
                templateRows: [action.row, ...state.templateRows]
            }
        }
        case ADMIN_TEMPLATES_SET_ROWS: {
            return {
                ...state,
                templateRows: JSON.parse(JSON.stringify(action.data.reverse()))
            }
        }
        case ADMIN_SEARCH_USER: {
            if (!action.words[0]) return {
                ...state,
                usersSearchList: [...state.usersList]
            }
            let searchUsers = [];

            state.usersList.forEach(user => {
                let matches = 0;

                action.words.forEach(word => {
                    if (user.id.toLowerCase().includes(word.toLowerCase()) ||
                        user.username.toLowerCase().includes(word.toLowerCase()) ||
                        user.email.toLowerCase().includes(word.toLowerCase())) matches++;
                })

                if (matches != 0) searchUsers.push({...user, matches})
            });

            if (searchUsers[0]) searchUsers.sort((b, a) => a.matches - b.matches)

            return {
                ...state,
                usersSearchList: [...searchUsers]
            }
        }
        case ADMIN_FIND_USER_BY_NAME_IN_ARR: {
            let userIndex = state.usersList.findIndex(e => e.username.toLowerCase().includes(action.text.toLowerCase()))
            if (userIndex == -1) return state;
            let newUsersList = [...state.usersList]
            arrayMove(newUsersList, userIndex, 0)
            return {
                ...state,
                usersList: [...newUsersList]
            }
        }
        case DELETE_ROLE_FROM_USER:
            let isRoleFound = false;
            let roleIndex = 0;
            let newRolesList = [...state.currentUser.roles];

            state.currentUser.roles.forEach((role, i) => {
                if (role.name == action.roleName) {
                    roleIndex = i;
                    isRoleFound = true;
                }
            })
            if (isRoleFound) {
                newRolesList.splice(roleIndex, 1)
                return {
                    ...state,
                    currentUser: {
                        ...state.currentUser,
                        roles: [...newRolesList]
                    }
                }
            } else {
                return state
            }
        case ADD_ROLE_TO_USER:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    roles: [...state.currentUser.roles, action.role]
                }
            };
        case SET_USERS_LIST:
            return {
                ...state,
                usersList: action.users,
                usersSearchList: action.users
            };
        case CHANGE_CURRENT_USER:
            return {
                ...state,
                currentUser: action.user
            }
        case CHANGE_ADD_ROLE_ERROR:
            return {
                ...state,
                addRoleError: action.text
            }
        case CHANGE_DELETE_ROLE_ERROR:
            return {
                ...state,
                deleteRoleError: action.text
            }
        case CHANGE_REF_SUPPORT_ACCESS_CHECKBOX: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isRefSupport: !state.currentUser.isRefSupport
                }
            }
        }
        case CHANGE_POKERHUB_SUPPORT_ACCESS_CHECKBOX: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isPokerhubSupport: !state.currentUser.isPokerhubSupport
                }
            }
        }
        case CHANGE_MTSNGSupport_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isMTSNGSupport: !state.currentUser.isMTSNGSupport
                }
            }
        case CHANGE_MTTSupport_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isMTTSupport: !state.currentUser.isMTTSupport
                }
            }
        case CHANGE_COACH_ACCESS_CHECKBOX: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isCoach: !state.currentUser.isCoach
                }
            }
        }
        case CHANGE_FS_CHANGER_ADMIN_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isFsChangerAdmin: !state.currentUser.isFsChangerAdmin
                }
            }
        case CHANGE_FS_CHANGER_SUPPORT_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isFsChangerSupport: !state.currentUser.isFsChangerSupport
                }
            }
        case CHANGE_FS_CHANGER_USER_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isFsChangerUser: !state.currentUser.isFsChangerUser
                }
            }
        case CHANGE_CashSupport_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isCashSupport: !state.currentUser.isCashSupport
                }
            }
        case CHANGE_SpinSupport_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isSpinSupport: !state.currentUser.isSpinSupport
                }
            }
        case CHANGE_AUDIT_ADMIN_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isAuditAdmin: !state.currentUser.isAuditAdmin
                }
            }
        case CHANGE_ADMIN_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isAdmin: !state.currentUser.isAdmin
                }
            }
        case CHANGE_FINANCES_ADMIN_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isFinancesAdmin: !state.currentUser.isFinancesAdmin
                }
            }
        case CHANGE_AUDITOR_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isAuditor: !state.currentUser.isAuditor
                }
            }
        case CHANGE_DISK_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isDisk: !state.currentUser.isDisk
                }
            }
        case CHANGE_WIKICREATOR_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isWikiCreator: !state.currentUser.isWikiCreator
                }
            }
        case CHANGE_LEADERBOARD_ADMIN_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isLeaderboardAdmin: !state.currentUser.isLeaderboardAdmin
                }
            }

        case CHANGE_LEAKFINDER_ADMIN_ACCESS_CHECKBOX:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isLeakfinderAdmin: !state.currentUser.isLeakfinderAdmin
                }
            }
        default:
            return state;
    }
};

export const SetUsersList = (users) => ({type: SET_USERS_LIST, users});
export const ChangeCurrentUser = (user) => ({type: CHANGE_CURRENT_USER, user});
export const FindUserByNameInArr = (text) => ({type: ADMIN_FIND_USER_BY_NAME_IN_ARR, text})
const ChangeAddRoleError = (text) => ({type: CHANGE_ADD_ROLE_ERROR, text})
const ChangeDeleteRoleError = (text) => ({type: CHANGE_DELETE_ROLE_ERROR, text})
const ChangeCoachAccessCheckbox = () => ({type: CHANGE_COACH_ACCESS_CHECKBOX});
const ChangeAdminAccessCheckbox = () => ({type: CHANGE_ADMIN_ACCESS_CHECKBOX});
const ChangeAuditAdminAccessCheckbox = () => ({type: CHANGE_AUDIT_ADMIN_ACCESS_CHECKBOX});
const ChangeFsChangerAdminAccessCheckbox = () => ({type: CHANGE_FS_CHANGER_ADMIN_ACCESS_CHECKBOX})
const ChangeFsChangerSupportAccessCheckbox = () => ({type: CHANGE_FS_CHANGER_SUPPORT_ACCESS_CHECKBOX})
const ChangeFsChangerUserAccessCheckbox = () => ({type: CHANGE_FS_CHANGER_USER_ACCESS_CHECKBOX})
const ChangeAuditorAccessCheckbox = () => ({type: CHANGE_AUDITOR_ACCESS_CHECKBOX});
const ChangeDiskAccessCheckbox = () => ({type: CHANGE_DISK_ACCESS_CHECKBOX});
const ChangeWikiCreatorAccessCheckbox = () => ({type: CHANGE_WIKICREATOR_ACCESS_CHECKBOX});
const ChangeMTSNGSupportAccessCheckbox = () => ({type: CHANGE_MTSNGSupport_ACCESS_CHECKBOX});
const ChangeMTTSupportAccessCheckbox = () => ({type: CHANGE_MTTSupport_ACCESS_CHECKBOX});
const ChangeCashSupportAccessCheckbox = () => ({type: CHANGE_CashSupport_ACCESS_CHECKBOX});
const ChangeSpinSupportAccessCheckbox = () => ({type: CHANGE_SpinSupport_ACCESS_CHECKBOX});
const ChangeFinancesAdminAccessCheckbox = () => ({type: CHANGE_FINANCES_ADMIN_ACCESS_CHECKBOX});
const ChangeRefSupportAccessCheckbox = () => ({type: CHANGE_REF_SUPPORT_ACCESS_CHECKBOX});
const ChangeLeaderboardAdminAccessCheckbox = () => ({type: CHANGE_LEADERBOARD_ADMIN_ACCESS_CHECKBOX});
const ChangeLeakfinderAdminAccessCheckbox = () => ({type: CHANGE_LEAKFINDER_ADMIN_ACCESS_CHECKBOX});
const ChangePokerhubSupportAccessCheckbox = () => ({type: CHANGE_POKERHUB_SUPPORT_ACCESS_CHECKBOX});

const AddRoleToUser = (role) => ({type: ADD_ROLE_TO_USER, role});
const DeleteRoleFromUser = (roleName) => ({type: DELETE_ROLE_FROM_USER, roleName})
const EditProfileAC = (key, newValue, userId) => ({type: ADMIN_EDIT_PROFILE, key, newValue, userId})

export const AdminSearchUser = (text) => {
    return (dispatch) => {
        let words = text.toLowerCase().split(" ");
        dispatch({type: ADMIN_SEARCH_USER, words})
    }
}

const AdminTemplatesSetRows = (data) => ({type: ADMIN_TEMPLATES_SET_ROWS, data})
export const AdminTemplatesGetRows = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        PROFILE_CUSTOM_INFO_API.adminTemplatesGetRows()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение существующих шаблонов", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(AdminTemplatesSetRows(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}
const AdminTemplatesSetNewRowAC = (row) => ({type: ADMIN_TEMPLATES_SET_NEW_ROW, row});

export const DEV_TEST_SendNotification = () => {
    return (dispatch) => {
        DEV_API.sendNotification()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "DEV", data.message))
                    return
                }
            })
    }
}
export const AdminTemplatesSetNewRow = (ru_name, en_name) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get('token'),
            ru_name, en_name
        }
        PROFILE_CUSTOM_INFO_API.adminTemplatesCreateRow(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание нового шаблона", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(AdminTemplatesSetNewRowAC(data))
                dispatch(ChangeMainLoading(false))
                dispatch(AddNotification("success", "Создание нового шаблона", "Создано"))
            })
    }
}
export const AdminTemplatesEditRowAC = (id, ttype, subType) => ({type: ADMIN_TEMPLATES_EDIT_ROW, id, ttype, subType})
export const AdminTemplatesEditRow = (id, type, subType, newValue) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get('token'),
            id, type, subType, newValue
        }
        PROFILE_CUSTOM_INFO_API.adminTemplatesEditRow(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение шаблона", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                if (type == "position" || type == "isVisible") dispatch(AdminTemplatesEditRowAC(id, type, subType))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const SetAccess = (userId, type, id, access_token, username, email) => {
    return (dispatch) => {
        let body = {
            userId, type, id, access_token, username, email
        }
        dispatch(ChangeMainLoading(true))
        ADMIN_API.setAccess(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Выдача доступа", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                if (type == 'coach') dispatch(ChangeCoachAccessCheckbox())
                if (type == 'admin') dispatch(ChangeAdminAccessCheckbox())
                if (type == 'auditAdmin') dispatch(ChangeAuditAdminAccessCheckbox())
                if (type == 'fsChangerAdmin') dispatch(ChangeFsChangerAdminAccessCheckbox())
                if (type == 'fsChangerSupport') dispatch(ChangeFsChangerSupportAccessCheckbox())
                if (type == 'fsChangerUser') dispatch(ChangeFsChangerUserAccessCheckbox())
                if (type == 'auditor') dispatch(ChangeAuditorAccessCheckbox())
                if (type == 'disk') dispatch(ChangeDiskAccessCheckbox())
                if (type == 'wikiCreator') dispatch(ChangeWikiCreatorAccessCheckbox())
                if (type == 'MTSNGSupport') dispatch(ChangeMTSNGSupportAccessCheckbox())
                if (type == 'MTTSupport') dispatch(ChangeMTTSupportAccessCheckbox())
                if (type == 'CashSupport') dispatch(ChangeCashSupportAccessCheckbox())
                if (type == 'SpinSupport') dispatch(ChangeSpinSupportAccessCheckbox())
                if (type == "financesAdmin") dispatch(ChangeFinancesAdminAccessCheckbox())
                if (type == 'refSupport') dispatch(ChangeRefSupportAccessCheckbox())
                if (type == 'leaderboardAdmin') dispatch(ChangeLeaderboardAdminAccessCheckbox())
                if (type == 'leakfinderAdmin') dispatch(ChangeLeakfinderAdminAccessCheckbox())
                if (type == 'POKERHUBSupport') dispatch(ChangePokerhubSupportAccessCheckbox())



                dispatch(ChangeMainLoading(false))
            })
    }
}
export const EditProfile = (userId, key, newValue) => {
    return (dispatch) => {
        let body = {
            userId, newValue, key, id: Cookies.get("id"), access_token: Cookies.get("token"), username: Cookies.get("username"), email: Cookies.get("email")
        }
        dispatch(ChangeMainLoading(true))
        ADMIN_API.editProfile(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение доп. информации", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }

                dispatch(EditProfileAC(key, newValue, userId))

                dispatch(ChangeMainLoading(false))
            })
    }
}
export const GetUsersList = (id, email, access_token) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        ADMIN_API.getUsersList(id, email, access_token)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение списка пользователей", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SetUsersList([]))
                dispatch(SetUsersList(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const GetCurrentUser = (userId, id, email, access_token) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        ADMIN_API.getUser(userId, id, email, access_token)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение данных пользователя", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(ChangeCurrentUser(null))
                dispatch(ChangeCurrentUser(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const GiveRole = (initiator_id, initiator_email, initiator_username,
                         initiator_access_token, userId, username, roleName) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info_initiator: {
                id: initiator_id, email: initiator_email, username: initiator_username
            },
            access_token_initiator: initiator_access_token,
            userId,
            role_info: {
                name: roleName
            },
            username
        }
        ADMIN_API.giveRole(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Выдача роли", data.message))
                    return
                }
                dispatch(AddRoleToUser({name: roleName, initiator: initiator_username}));
            })
    }
}
export const DeleteRole = (initiator_id, initiator_email, initiator_username, access_token_initiator,
                           userId, roleName) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            userId,
            roleName,
            info_initiator: {
                id: initiator_id, email: initiator_email, username: initiator_username
            },
            access_token_initiator
        }
        ADMIN_API.deleteRole(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Снятие роли", data.message))
                    return
                }
                dispatch(DeleteRoleFromUser(roleName))
            })
    }
}

function arrayMove(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

export default adminReducer;

