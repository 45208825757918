import {AUDITOR_API} from "../../api/util";
import {ChangeMainLoading} from "./mainReducer";
import {AddNotification} from "./notificationsReducer";
import Cookies from "js-cookie";
import axios from "axios";

const AUDITOR_SUPPORT_SET_CURRENT_PLAYER = 'AUDITOR_SUPPORT_SET_CURRENT_PLAYER';
const AUDITOR_SUPPORT_SET_TASKS_LIST = 'AUDITOR_SUPPORT_SET_TASKS_LIST';
const AUDITOR_SUPPORT_SET_TASKS_MONTH = 'AUDITOR_SUPPORT_SET_TASKS_MONTH';
const AUDITOR_SUPPORT_SET_TASKS_YEAR = 'AUDITOR_SUPPORT_SET_TASKS_YEAR';
const AUDITOR_ADMIN_DELETE_PLAYER = 'AUDITOR_ADMIN_DELETE_PLAYER';
const AUDITOR_ADMIN_CREATE_PLAYER = 'AUDITOR_ADMIN_CREATE_PLAYER';
const AUDITOR_ADMIN_EDIT_PLAYER = 'AUDITOR_ADMIN_EDIT_PLAYER';
const AUDITOR_ADMIN_SET_PLAYERS_LIST = 'AUDITOR_ADMIN_SET_PLAYERS_LIST';
const AUDITOR_SEARCH_AUDIT_PLAYERS_BY_DIRECTION = 'AUDITOR_SEARCH_AUDIT_PLAYERS_BY_DIRECTION';
const AUDITOR_ADMIN_DELETE_AUDITOR = 'AUDITOR_ADMIN_DELETE_AUDITOR';
const AUDITOR_ADMIN_CREATE_AUDITOR = 'AUDITOR_ADMIN_CREATE_AUDITOR';
const AUDITOR_ADMIN_EDIT_AUDITOR = 'AUDITOR_ADMIN_EDIT_AUDITOR';
const AUDITOR_ADMIN_SET_AUDITORS_LIST = 'AUDITOR_ADMIN_SET_AUDITORS_LIST';
const AUDITOR_SUPPORT_SET_TASKS_DIRECTION = 'AUDITOR_SUPPORT_SET_TASKS_DIRECTION';
const AUDITOR_SUPPORT_SET_TASKS_SET = 'AUDITOR_SUPPORT_SET_TASKS_SET';
const AUDITOR_ADMIN_SET_TASKS_LIST = 'AUDITOR_ADMIN_SET_TASKS_LIST';
const AUDITOR_ADMIN_DELETE_TASK_FROM_LIST = 'AUDITOR_ADMIN_DELETE_TASK_FROM_LIST';
const AUDITOR_ADMIN_SORT_TASKS = 'AUDITOR_ADMIN_SORT_TASKS';
const AUDITOR_SORT_TASKS = 'AUDITOR_SORT_TASKS';
const AUDITOR_SET_SUPPORTS_LIST = 'AUDITOR_SET_SUPPORTS_LIST';

let date = new Date();
let month = date.getMonth();
let year = date.getFullYear()

if (+month == 0) {
    month = 12;
    year = +year - 1;
}

let initialState = {
    supportTasksListMonth: month,
    supportTasksListYear: year,
    adminTasksListDirection: null,
    playersList: [],
    searchPlayersList: [],
    auditorsList: [],
    tasksList: [],
    adminTasksList: [],
    tasksPreviousRooms: [],
    currentPlayer: null,
    adminTasksListSet: null,
    adminTasksListSort: false,
    auditorTasksListSort: false,

    supportsList: []
};

const auditorReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUDITOR_SET_SUPPORTS_LIST: {
            return {
                ...state,
                supportsList: [...action.list]
            }
        }
        case AUDITOR_SORT_TASKS: {
            let sortedList = [];

            if (!state.auditorTasksListSort) {
                sortedList = state.tasksList.slice().sort((a, b) => a[action.columnName].localeCompare(b[action.columnName]))
            } else {
                sortedList = state.tasksList.slice().sort((b, a) => a[action.columnName].localeCompare(b[action.columnName]))
            }

            return {
                ...state,
                tasksList: JSON.parse(JSON.stringify(sortedList)),
                auditorTasksListSort: !state.auditorTasksListSort
            }
        }
        case AUDITOR_ADMIN_SORT_TASKS: {
            let sortedList = [];

            if (!state.adminTasksListSort) {
                sortedList = state.adminTasksList.slice().sort((a, b) => a[action.columnName].localeCompare(b[action.columnName]))
            } else {
                sortedList = state.adminTasksList.slice().sort((b, a) => a[action.columnName].localeCompare(b[action.columnName]))
            }

            return {
                ...state,
                adminTasksList: [...sortedList],
                adminTasksListSort: !state.adminTasksListSort
            }
        }
        case AUDITOR_ADMIN_DELETE_TASK_FROM_LIST: {
            let newAdminTasksList = [...state.adminTasksList];
            let taskIndex = newAdminTasksList.findIndex(e => e.nickname == action.nickname)
            newAdminTasksList.splice(taskIndex, 1);

            return {
                ...state,
                adminTasksList: [...newAdminTasksList]
            }
        }
        case AUDITOR_ADMIN_SET_TASKS_LIST: {
            return {
                ...state,
                adminTasksList: action.tasks
            }
        }
        case AUDITOR_SEARCH_AUDIT_PLAYERS_BY_DIRECTION: {
            if (!action.words[0]) return {
                ...state,
                searchPlayersList: [...state.playersList]
            }
            let searchPlayersList = [];

            state.playersList.forEach(player => {
                let matches = 0;

                action.words.forEach(word => {
                    if (player.playerDirection.toLowerCase().includes(word)) matches++;
                })

                if (matches != 0) searchPlayersList.push({...player, matches})
            });

            if (searchPlayersList[0]) searchPlayersList.sort((b, a) => a.matches - b.matches)

            return {
                ...state,
                searchPlayersList: [...searchPlayersList]
            }
        }
        case AUDITOR_SUPPORT_SET_CURRENT_PLAYER: {
            if (action.player == null) {
                return {
                    ...state,
                    currentPlayer: action.player
                }
            }

            return {
                ...state,
                currentPlayer: {...action.player}
            }
        }
        case AUDITOR_SUPPORT_SET_TASKS_DIRECTION: {
            return {
                ...state,
                adminTasksListDirection: action.text
            }
        }
        case AUDITOR_SUPPORT_SET_TASKS_SET: {
            return {
                ...state,
                adminTasksListSet: action.text
            }
        }
        case AUDITOR_SUPPORT_SET_TASKS_MONTH: {
            return {
                ...state,
                supportTasksListMonth: action.text
            }
        }
        case AUDITOR_SUPPORT_SET_TASKS_YEAR: {
            return {
                ...state,
                supportTasksListYear: action.text
            }
        }
        case AUDITOR_SUPPORT_SET_TASKS_LIST: {
            return {
                ...state,
                tasksList: action.tasks,
                tasksPreviousRooms: action.previousRooms
            }
        }
        case AUDITOR_ADMIN_SET_AUDITORS_LIST: {
            return {
                ...state,
                auditorsList: action.data
            }
        }
        case AUDITOR_ADMIN_CREATE_AUDITOR: {
            return {
                ...state,
                auditorsList: [action.auditor, ...state.auditorsList]
            }
        }
        case AUDITOR_ADMIN_DELETE_AUDITOR: {
            let auditorIndex = state.auditorsList.findIndex(e => e.id == action.auditorId)
            if (auditorIndex == -1) return state;
            state.auditorsList.splice(auditorIndex, 1)
            return {
                ...state,
                auditorsList: [...state.auditorsList]
            }
        }
        case AUDITOR_ADMIN_EDIT_AUDITOR: {
            let newAuditorsList = [...state.auditorsList]
            let auditorIndex = newAuditorsList.findIndex(e => e.id == action.auditor.id)
            if (auditorIndex == -1) return state;
            newAuditorsList[auditorIndex] = {...action.auditor}
            return {
                ...state,
                auditorsList: [...newAuditorsList]
            }
        }
        case AUDITOR_ADMIN_SET_PLAYERS_LIST:
            return {
                ...state,
                playersList: action.data,
                searchPlayersList: []
            };
        case AUDITOR_ADMIN_CREATE_PLAYER: {
            return {
                ...state,
                playersList: [action.player, ...state.playersList],
                searchPlayersList: []
            }
        }
        case AUDITOR_ADMIN_DELETE_PLAYER: {
            let playerIndex = state.playersList.findIndex(e => e.playerName == action.playerName)
            if (playerIndex == -1) return state;
            state.playersList.splice(playerIndex, 1)
            return {
                ...state,
                playersList: [...state.playersList],
                searchPlayersList: []
            }
        }
        case AUDITOR_ADMIN_EDIT_PLAYER: {
            let newPlayersList = [...state.playersList]
            let playerIndex = newPlayersList.findIndex(e => e.playerName == action.player.playerName)
            if (playerIndex == -1) return state;
            newPlayersList[playerIndex] = {...action.player}
            return {
                ...state,
                playersList: [...newPlayersList]
            }
        }
        default:
            return state;
    }
};

const SupportSetCurrentPlayer = (player) => ({type: AUDITOR_SUPPORT_SET_CURRENT_PLAYER, player})
const SupportSetTasksList = (tasks, previousRooms) => ({type: AUDITOR_SUPPORT_SET_TASKS_LIST, tasks, previousRooms})
const AdminSetTasksList = (tasks) => ({type: AUDITOR_ADMIN_SET_TASKS_LIST, tasks})
const SupportSetTasksMonth = (text) => ({type: AUDITOR_SUPPORT_SET_TASKS_MONTH, text})
const SupportSetTasksYear = (text) => ({type: AUDITOR_SUPPORT_SET_TASKS_YEAR, text})
const SupportSetTasksDirection = (text) => ({type: AUDITOR_SUPPORT_SET_TASKS_DIRECTION, text})
const SupportSetTasksSet = (text) => ({type: AUDITOR_SUPPORT_SET_TASKS_SET, text})
const AdminSetPlayersList = (data) => ({type: AUDITOR_ADMIN_SET_PLAYERS_LIST, data});
const AdminDeletePlayerAC = (playerName) => ({type: AUDITOR_ADMIN_DELETE_PLAYER, playerName})
const AdminCreatePlayerAC = (player) => ({type: AUDITOR_ADMIN_CREATE_PLAYER, player});
const AdminEditPlayerAC = (player) => ({type: AUDITOR_ADMIN_EDIT_PLAYER, player});

const AdminSetAuditorsList = (data) => ({type: AUDITOR_ADMIN_SET_AUDITORS_LIST, data});
const AdminDeleteAuditorAC = (auditorId) => ({type: AUDITOR_ADMIN_DELETE_AUDITOR, auditorId})
const AdminCreateAuditorAC = (auditor) => ({type: AUDITOR_ADMIN_CREATE_AUDITOR, auditor});
const AdminEditAuditorAC = (auditor) => ({type: AUDITOR_ADMIN_EDIT_AUDITOR, auditor});
export const SearchAuditPlayersByDirectionAC = (words) => ({type: AUDITOR_SEARCH_AUDIT_PLAYERS_BY_DIRECTION, words})
const AdminDeleteTaskFromList = (nickname) => ({type: AUDITOR_ADMIN_DELETE_TASK_FROM_LIST, nickname})
export const AdminSortTasks = (columnName) => ({type: AUDITOR_ADMIN_SORT_TASKS, columnName});
export const AuditorSortTasks = (columnName) => ({type: AUDITOR_SORT_TASKS, columnName});

const SetSupportsList = (list) => ({type: AUDITOR_SET_SUPPORTS_LIST, list});

export const AdminAddSecondSupportToTask = (id, nickname, period, setCount, direction) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            access_token: Cookies.get("token"),
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            add_info: {
                id, nickname, period, setCount, direction
            }
        }
        AUDITOR_API.adminAddSecondSupportToTask(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение таски", data.message))
                    return
                }
                dispatch(AddNotification("success", "Изменение таски", data))
            })
    }
}

export const GetSupportsList = () => {
    return (dispatch) => {
        AUDITOR_API.getSupportsList()
            .then(data => {
                dispatch(SetSupportsList(data))
            })
    }
}

export const AdminChangeFollowStatus = (nickname, period, setCount, index) => {
    return (dispatch) => {
        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            nickname, period, index, setCount
        }

        AUDITOR_API.adminChangeFollowStatus(body)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Отправка на редактирование", data.message))
                    return
                }
                dispatch(AddNotification("success", "Отправлено", data))
            })
    }
}
export const AdminDeleteTask = (nickname, period, setCount, deletionType) => {
    return (dispatch) => {
        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            nickname, period, setCount, deletionType
        }

        AUDITOR_API.adminDeleteTask(body)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление таски", data.message))
                    return
                }
                dispatch(AddNotification("success", "Удаление таски", data))
                dispatch(AdminDeleteTaskFromList(nickname))
            })
    }
}
export const SearchAuditPlayersByDirection = (text) => {
    return (dispatch) => {
        let words = text.toLowerCase().split(" ");
        dispatch(SearchAuditPlayersByDirectionAC(words))
    }
}

export const SupportSaveTask = (edition_info, currentPlayer = null) => {
    return (dispatch) => {
        if (currentPlayer && edition_info.type == "close") {
            if (!currentPlayer.debts[0].usd_amount || !currentPlayer.debts[0].eur_amount || !currentPlayer.wallets[0].skrill_usd ||
                !currentPlayer.wallets[0].skrill_eur || !currentPlayer.wallets[0].cash_usd || !currentPlayer.wallets[0].crypto_usdt ||
                !currentPlayer.wallets[0].crypto_other || !currentPlayer.wallets[0].other)
                return dispatch(AddNotification("error", "Завершение", "Все ячейки на странице обязательны к заполнению"))
            let isError = false;
            currentPlayer.rooms.forEach(room => {
                if (!room.total_tournaments || !room.profit || !room.bonuses || !room.coins_miles || !room.start_balance ||
                    !room.current_balance || !room.personal_duty || !room.start_T_money || !room.current_T_money) isError = true;

            })
            if (isError) return dispatch(AddNotification("error", "Завершение", "Все ячейки у румов обязательны к заполнению.\nДоп.инфо: Стартовый и конечный баланс не могут быть отрицательным значением"))
        }

        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            edition_info: {
                ...edition_info
            }
        }
        AUDITOR_API.supportSaveTask(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Сохранение", data.message))
                    return
                }
                // dispatch(SupportSetCurrentPlayer(null))
                dispatch(SupportSetCurrentPlayer(data))
            })
    }
}
export const SupportGetTask = (period, nickname, setCount) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        AUDITOR_API.supportGetTask(nickname, period, setCount)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Загрузка игрока", data.message))
                    return
                }
                dispatch(SupportSetCurrentPlayer(null))
                dispatch(SupportSetCurrentPlayer(data))
            })
    }
}
export const SetSupportTasksListMonth = (month, year, direction = null, type = null, setCount) => {
    return (dispatch) => {
        dispatch(SupportSetTasksMonth(month))

        if (type == "adminTasks") {
            if (month && year && direction) return dispatch(AdminGetAllTasksByPeriod(direction, month, year, "0", setCount))
        }

        if (month && year) dispatch(SupportGetTasksList(`${month}-${year}`, setCount))
    }
}
export const SetSupportTasksListYear = (month, year, direction = null, type = null, setCount) => {
    return (dispatch) => {
        dispatch(SupportSetTasksYear(year))

        if (type == "adminTasks") {
            if (month && year && direction) return dispatch(AdminGetAllTasksByPeriod(direction, month, year, "0", setCount))
        }

        if (month && year) dispatch(SupportGetTasksList(`${month}-${year}`, setCount))
    }
}

export const SetAdminTasksListDirection = (month, year, direction, setCount) => {
    return (dispatch) => {
        dispatch(SupportSetTasksDirection(direction))
        if (month && year && direction) dispatch(AdminGetAllTasksByPeriod(direction, month, year, "0", setCount))
    }
}

export const SetSupportTasksListSet = (month, year, direction = null, setCount, type) => {
    return (dispatch) => {
        dispatch(SupportSetTasksSet(setCount))

        if (type == "supportTasks") {
            if (month && year) dispatch(SupportGetTasksList(`${month}-${year}`, setCount))
        }

        if (month && year && direction) dispatch(AdminGetAllTasksByPeriod(direction, month, year, "0", setCount))
    }
}


export const AdminGetAllTasksByPeriod = (direction, month, year, downloadType = "0", setCount) => {
    return (dispatch) => {
        if (downloadType != 0) {
            axios({
                url: `https://api.firestorm.team/api/lk/auditor/auditors/tasks/download?setCount=${setCount == null ? "0": setCount}&id=${Cookies.get('id')}&email=${Cookies.get('email')}&access_token=${Cookies.get('token')}&direction=${direction}&month=${month}&year=${year}&downloadType=${downloadType}`,
                method: 'GET'
            }).then((response) => {
                if (response.data.length == 0) return dispatch(AddNotification("error", "Загрузка списка тасок", "Нет данных для выгрузки"))
                downloadJSON(`${downloadType}_${month}-${year}.json`, response.data)
            });
        } else {
            dispatch(ChangeMainLoading(true))
            AUDITOR_API.adminGetAllTasksByPeriod(direction, month, year, downloadType, setCount)
                .then(data => {
                    dispatch(ChangeMainLoading(false))
                    if (data && data.resultCode && data.resultCode != 1) {
                        dispatch(AddNotification("error", "Загрузка списка тасок", data.message))
                        return
                    }
                    dispatch(AdminSetTasksList([]))
                    dispatch(AdminSetTasksList(data))
                })
        }
    }
}
export const SupportGetTasksList = (period, setCount) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        AUDITOR_API.supportGetTasksList(period, setCount)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Загрузка списка игроков", data.message))
                    return
                }
                dispatch(SupportSetTasksList([], []))
                dispatch(SupportSetTasksList(data.tasks[0] ? data.tasks : [], data.previousRooms[0] ? data.previousRooms : []))
            })
    }
}
export const AdminGetAuditorsList = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        AUDITOR_API.adminGetAuditorsList()
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Загрузка списка аудиторов", data.message))
                    return
                }
                dispatch(AdminSetAuditorsList([]))
                dispatch(AdminSetAuditorsList(data))
            })
    }
}
export const AdminCreateAuditor = (creation_info) => {
    return (dispatch) => {
        if (!creation_info.id || !creation_info.name) return dispatch(AddNotification("error", "Создание аудитора", "Требуется указать аудитора для создания"))
        if (!creation_info.direction) return dispatch(AddNotification("error", "Создание аудитора", "Требуется указать направление для создания"))

        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            creation_info: {
                ...creation_info
            }
        }
        AUDITOR_API.adminCreateAuditor(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание аудитора", data.message))
                    return
                }
                dispatch(AdminCreateAuditorAC(data))
                dispatch(AddNotification("success", "Создание аудитора", `Аудитор с ником ${creation_info.name} добавлен в список`))
            })
    }
}
export const AdminDeleteAuditor = (auditorId) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        AUDITOR_API.adminDeleteAuditor(auditorId)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление аудитора", data.message))
                    return
                }
                dispatch(AdminDeleteAuditorAC(auditorId))
            })
    }
}
export const AdminEditAuditor = (edition_info) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            edition_info: {
                ...edition_info
            }
        }
        AUDITOR_API.adminEditAuditor(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение аудитора", data.message))
                    return
                }
                dispatch(AdminEditAuditorAC(data))
            })
    }
}

export const AdminGetPlayersList = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        AUDITOR_API.adminGetPlayersList()
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Загрузка списка игроков", data.message))
                    return
                }
                dispatch(AdminSetPlayersList([]))
                dispatch(AdminSetPlayersList(data))
            })
    }
}
export const AdminStartPeriod = (creation_info) => {
    return (dispatch) => {
        if (!creation_info.direction || !creation_info.month || !creation_info.year) return dispatch(AddNotification("error", "Старт периода", "Все поля для старта периода обязательны к заполнению"))
        dispatch(ChangeMainLoading(true))

        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            month: creation_info.month,
            year: creation_info.year,
            direction: creation_info.direction
        };

        AUDITOR_API.adminStartTasks(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Старт периода", data.message));
                    return
                }
                return dispatch(AddNotification("success", "Старт периода", data));
            });
    }
}
export const AdminCreatePlayer = (creation_info) => {
    return (dispatch) => {
        if (!creation_info.playerName) return dispatch(AddNotification("error", "Создание игрока", "Требуется указать ник игрока для создания"))
        if (creation_info.playerName.includes("/") || creation_info.playerName.includes("\\") || creation_info.playerName.includes("&")
            || creation_info.playerName.includes("#") || creation_info.playerName.includes("?"))
            return dispatch(AddNotification("error", "Создание игрока", "Символы для ника (/ \\ & # ?) запрещены"))
        dispatch(ChangeMainLoading(true))

        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            creation_info: {
                ...creation_info
            }
        }
        AUDITOR_API.adminCreatePlayer(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание игрока", data.message))
                    return
                }
                dispatch(AdminCreatePlayerAC(data))
            })
    }
}
export const AdminDeletePlayer = (playerName) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        AUDITOR_API.adminDeletePlayer(playerName)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление игрока", data.message))
                    return
                }
                dispatch(AdminDeletePlayerAC(playerName))
            })
    }
}
export const AdminEditPlayer = (edition_info) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token'),
            edition_info: {
                ...edition_info
            }
        }
        AUDITOR_API.adminEditPlayer(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение игрока", data.message))
                    return
                }
                dispatch(AdminEditPlayerAC(data))
            })
    }
}

const downloadJSON = (filename, arrayOfJson) => {
    let link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(JSON.stringify(arrayOfJson)));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default auditorReducer;