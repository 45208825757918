import React, {useState, useRef} from "react";

export default function CoachTainingsListSidebar(props) {
    const [listType, setListType] = useState("self");

    const handleChangeListType = (lt) => {
        setListType(lt)
        props.GetTrainingsList("1", lt, "1")
    }

    return (
        !props.isLoading ?
            <>
                <div className={"default-button blue-back"} onClick={() => handleChangeListType("self")}>
                    Моё расписание
                </div>
                <div className={"default-button default-back"} onClick={() => handleChangeListType("all")}>
                    Всё расписание
                </div>
                <br/>
                <br/>

                <div className={"default-button orange-back"} onClick={() => props.GetTrainingsList("1", listType, "0")}>
                    Показать завершенные/отмененные
                </div>
                <div className={"default-button orange-back"} onClick={() => props.GetTrainingsList("1", listType, "1")}>
                    Скрыть завершенные/отмененные
                </div>

                <br/>
                <br/>
            </>
            : null
    )
}