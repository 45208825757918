import {useState} from "react";
import s from './styles.module.css'
import classNames from "classnames";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {data, formatDate, getMonth, getYear} from "../../utils/calendar_utils";
import * as calendar from "../../utils/calendar";
import CalendarWeek from "./calendar_week";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const Calendar = ({
                      lastSelectedDate,

                      setLastSelectedDate,
                      chooseDate,
                      removeChooseDate,
                  }) => {
    // lastSelectedDate - все дни при зажатом выделении лкм
    const [pressLKMData, setPressLKMData] = useState([]);

    const [date, setDate] = useState(new Date());
    const handleNextMonthButtonClick = () => {
        setDate(new Date(getYear(date), getMonth(date) + 1));
    }
    const handlePrevMonthButtonClick = () => {
        setDate(new Date(getYear(date), getMonth(date) - 1));
    }
    const months = [calendar.getMonthData(getYear(date), getMonth(date))]

    return (
        <div className={s.calendar_box_main}>

            <div className={s.twoCalendarBox}>
                {months.map((month, i) => (
                    <div className={s.calendar_box} key={i}>
                        <div className={s.calendar_header_action}>
                            <h1 className={classNames(s.name_month, i !== 0 && s.next_month)}>
                                {data.monthNames[getMonth(date) + i]}
                                <span className={s.name_year}>
                            {getYear(date)}
                            </span>
                            </h1>

                            {i === 0 && <div className={s.reset} onClick={() => setLastSelectedDate([])}>
                                <RestartAltIcon/>
                            </div>}

                            {i === 0 && <div className={s.change_month_box}>
                                <KeyboardArrowDownIcon onClick={handlePrevMonthButtonClick} sx={{
                                    transform: 'rotate(90deg)'
                                }}/>
                                <KeyboardArrowDownIcon onClick={handleNextMonthButtonClick} sx={{
                                    transform: 'rotate(-90deg)'
                                }}/>
                            </div>}
                        </div>
                        <div className={s.calendarTable}>

                            <div className={s.test}>
                                {data.weekDayNames.map((name, i) =>
                                    <div key={i} className={s.days_week_name}>{name}</div>
                                )}
                            </div>

                            <div>
                                {month.map((week, index) => {

                                        return <div key={index} className={s.week}>
                                            {week.map((_date, index) => {
                                                    // test - переменная которая находит все обьекты из chooseDate на каждый день и сопоставляет их по дате и делает массив из одинаковых дат
                                                    const test = chooseDate.filter(f => formatDate(f.date) === formatDate(_date))

                                                    return _date ?
                                                        <CalendarWeek pressLKMData={pressLKMData}
                                                                      setPressLKMData={setPressLKMData}
                                                                      removeChooseDate={removeChooseDate}
                                                                      index={index} test={test}
                                                                      key={index}
                                                                      setLastSelectedDate={setLastSelectedDate} _date={_date}
                                                                      lastSelectedDate={lastSelectedDate}/>
                                                        :
                                                        <div className={classNames(s.test3, s.empty_day)} key={index}/>
                                                }
                                            )}
                                        </div>
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default Calendar;
