import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Tickets(props) {

    return (
        props.FsChangerInfo.supportTicketsList[0] ?
            <div className={"tasks-tickets-page"}>
                <div className={"columns-name"}>
                    <div>Тип</div>
                    <div>Дата</div>
                    <div style={{marginLeft: "10px"}}>Никнейм</div>
                    <div style={{marginLeft: "15px"}}>Описание</div>
                    <div style={{marginLeft: "25px"}}>Статус</div>
                </div>
                <div className={"list"}>
                    <div id="scrollableDiv" style={{height: "85vh", overflow: "auto", "padding": "0 10px 0 10px"}}>
                        <InfiniteScroll
                            dataLength={props.FsChangerInfo.supportTicketsList.length}
                            next={props.fetchMoreData}
                            hasMore={props.FsChangerInfo.supportTicketsList.length <= props.FsChangerInfo.supportTicketsCount}
                            scrollableTarget="scrollableDiv"
                        >
                            {
                                props.FsChangerInfo.supportTicketsList.map((tt, i) => <TaskTicket index={i}
                                                                                                  key={tt.timestamp_creation + i}
                                                                                                  AdminDeleteTicket={props.AdminDeleteTicket}
                                                                                                  AuthInfo={props.AuthInfo}
                                                                                                  tt={tt}
                                                                                                  SupportChangeTicketStatus={props.SupportChangeTicketStatus}
                                                                                                  SupportEditTicket={props.SupportEditTicket}/>)
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </div> : <a style={{color: "white"}}>По выбранным фильтрам ничего не найдено.</a>
    )
}

const TaskTicket = (props) => {
    let status = "Новый";
    if (props.tt.status == "active") status = "В работе"
    if (props.tt.status == "accepting") status = "Отправлено"
    if (props.tt.status == "closed") status = "Выполнен"
    if (props.tt.status == "canceled") status = "Отменен"

    return (
        <Popup key={props.tt.timestamp_creation}
               trigger={
                   <div className={"item transition " + props.tt.status} title={props.tt.message}>
                       <div>
                           {props.tt.type == "request" ? "Запрос" : "Вопрос"}
                       </div>
                       <div>
                           {props.tt.date_creation}
                       </div>
                       <div>
                           {props.tt.author_name}
                       </div>
                       <div>
                           {props.tt.message.substring(0, 60) + "..."}
                       </div>
                       <div>
                           {status}
                       </div>
                   </div>}
               modal
               nested
        >
            {() => (
                <div>

                    {
                        props.AuthInfo.isFsChangerAdmin ?
                            <div id="get-advice" className="tasks-tickets-popup">
                                <div className={"column"}>


                                    <div className={"info-block"}>
                                        <div className={"name"}>
                                            Создано
                                        </div>
                                        <div className={"desc"}>
                                            {props.tt.date_creation}
                                        </div>
                                    </div>
                                    <div className={"info-block"}>
                                        <div className={"name"}>
                                            Взято в работу
                                        </div>
                                        <div className={"desc"}>
                                            {props.tt.date_set_in_work}
                                        </div>
                                    </div>
                                    <div className={"info-block"}>
                                        <div className={"name"}>
                                            Закрыто
                                        </div>
                                        <div className={"desc"}>
                                            {props.tt.date_close}
                                        </div>
                                    </div>
                                    <div className={"info-block"}>
                                        <div className={"name"}>
                                            Взял в работу
                                        </div>
                                        <div className={"desc"}>
                                            {props.tt.support_set_in_work}
                                        </div>
                                    </div>
                                    <div className={"info-block"}>
                                        <div className={"name"}>
                                            Выполнивший
                                        </div>
                                        <div className={"desc"}>
                                            {props.tt.support_close}
                                        </div>
                                    </div>

                                    {
                                        props.AuthInfo.isFsChangerAdmin ?
                                            <div>
                                                <div
                                                    onClick={() => props.AdminDeleteTicket(props.tt.timestamp_creation)}
                                                    className={"red-back transition default-button"} style={{background: "red"}}>Удалить из БД
                                                </div>
                                                <br/>
                                            </div>
                                            : null
                                    }
                                    <div
                                        onClick={() => props.SupportChangeTicketStatus(props.tt.timestamp_creation, "canceled")}
                                        className={"red-back transition default-button"}>Отменить
                                    </div>

                                </div>
                                <div className={"column"}>
                                    <div className={"user-message"}>
                                        <div style={{whiteSpace: "pre-line"}} className={"user-content"}>
                                            <div
                                                className={"name"}>{props.tt.author_name + " (" + props.tt.author_id + ")"}</div>
                                            {props.tt.message}
                                        </div>
                                    </div>
                                </div>
                                <div className={"column"}>

                                    <div className={"info-block"}>
                                        <div className={"name"}>
                                            Статус
                                        </div>
                                        <div className={"desc"}>
                                            {status}
                                        </div>
                                    </div>

                                    <div className={"info-block"}>
                                        <div className={"name"}>
                                            Направление
                                        </div>
                                        <div className={"desc"}>
                                            {props.tt.full_info && props.tt.full_info.direction ? props.tt.full_info.direction : "Unknown"}
                                        </div>
                                    </div>

                                    <div className={"info-block"}>
                                        {props.tt.status == "new" ? <div
                                            onClick={() => props.SupportChangeTicketStatus(props.tt.timestamp_creation, "active")}
                                            className={"transition orange-back default-button"}>Взять в работу</div> : null}
                                        {props.tt.status == "active" ? props.tt.type == "request" ? <div
                                                    onClick={() => props.SupportChangeTicketStatus(props.tt.timestamp_creation, "accepting")}
                                                    style={{marginTop: "10px"}}
                                                    className={"transition green-back default-button"}>Отправлено</div>
                                                : <div
                                                    onClick={() => props.SupportChangeTicketStatus(props.tt.timestamp_creation, "accepting")}
                                                    style={{marginTop: "10px"}}
                                                    className={"transition green-back default-button"}>Завершить</div>
                                            : null}
                                    </div>

                                    {props.tt.type == "request" && props.tt.full_info ?
                                        <div>
                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    Курс xe.com
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "xe_com_exchange", e.target.value)}
                                                    type="number" style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.xe_com_exchange}/>
                                            </div>
                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    Курс обмена
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "exchange", e.target.value)}
                                                    type="number" style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.exchange}/>
                                            </div>
                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    Сумма USDT
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "sum", e.target.value)}
                                                    type="number" style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.sum}/>
                                            </div>


                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    from_currency
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "from_currency", e.target.value)}
                                                    style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.from_currency}/>
                                            </div>

                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    from_sum
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "from_sum", e.target.value)}
                                                    type="number" style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.from_sum}/>
                                            </div>

                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    from_nickname
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "from_nickname", e.target.value)}
                                                    style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.from_nickname}/>
                                            </div>

                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    to_bank
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "to_bank", e.target.value)}
                                                    style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.to_bank}/>
                                            </div>
                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    to_currency
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "to_currency", e.target.value)}
                                                    style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.to_currency}/>
                                            </div>


                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    to_sum
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "to_sum", e.target.value)}
                                                    type="number" style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.to_sum}/>
                                            </div>

                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    to_requisites
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "to_requisites", e.target.value)}
                                                    style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.to_requisites}/>
                                            </div>
                                            <div style={{marginBottom: "20px"}}>
                                                <div style={{color: "white"}}>
                                                    direction
                                                </div>
                                                <input
                                                    onBlur={(e) => props.SupportEditTicket(props.tt.timestamp_creation, "direction", e.target.value)}
                                                    style={{width: "170px"}}
                                                    defaultValue={props.tt.full_info.direction}/>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>

                            </div>
                            : <div style={{textAlign: "center", color: "tomato"}} id="get-advice" className="main-popup">
                                <div className={"column"}>
                                    Нет доступа
                                </div>
                            </div>
                    }

                </div>
            )}
        </Popup>
    )
}