import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    SupportChangeTypesFilter,
    SupportChangeStatusesFilter,
    SupportGetTicketsList, SupportChangeTicketStatus, SupportEditTicket, AdminDeleteTicket
} from "../../../../redux/reducers/fsChangerReducer";
import Tickets from "./tickets";


class TicketsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }


    componentDidMount() {
        this.props.changeCurrentPage("Запросы и вопросы по обменам")
        this.props.SupportGetTicketsList("set", ["question", "request"], ["new", "active"], 0, 50)
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.FsChangerInfo.types.length != prevProps.FsChangerInfo.types.length ||
        this.props.FsChangerInfo.statuses.length != prevProps.FsChangerInfo.statuses.length) {
            this.props.SupportGetTicketsList("set", this.props.FsChangerInfo.types, this.props.FsChangerInfo.statuses, 0, 50)
        }
    }

    fetchMoreData = () => {
        this.props.SupportGetTicketsList("update", this.props.FsChangerInfo.types, this.props.FsChangerInfo.statuses, this.props.FsChangerInfo.supportTicketsList.length, 50)
    };

    render() {
        return (
            <Tickets fetchMoreData={this.fetchMoreData} {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    FsChangerInfo: state.fsChangerReducer
})


export default compose(
    connect(mapStateToProps, {
        SupportGetTicketsList, SupportChangeStatusesFilter, SupportChangeTypesFilter, SupportChangeTicketStatus,
        SupportEditTicket, AdminDeleteTicket
    }),
    withRouter
)(TicketsContainer)