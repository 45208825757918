import React, {useState, useRef} from "react";
import MainLoading from "../../common/mainLoading";
import Popup from "reactjs-popup";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker, DateTimePicker,
} from '@material-ui/pickers';
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import ProfileMTTContainer from "../User_Profile_MTT/profileContainer";
import Select from "react-dropdown-select";
import ProfileSPINContainer from "../User_Profile_SPIN/profileContainer";

const RoomSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать рум"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "initial",
                margin: "-3px 0 0 10px",
                fontSize: "14px",
                border: "none",
                zIndex: "101",
                outline: "none"
            }}
        />
    )
}
const RoomRemoveSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать румнейм"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "initial",
                margin: "-3px 0 0 10px",
                fontSize: "14px",
                border: "none",
                zIndex: "101",
                outline: "none"
            }}
        />
    )
}


const CoachGroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            create
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedCoachGroup}
            onChange={(value) => {
                props.handleSelectCoachGroup(value)
            }
            }
            style={{
                backgroundColor: "initial",
                margin: "-3px 0 0 10px",
                fontSize: "14px",
                border: "none",
                zIndex: "102",
                outline: "none"
            }}
        />
    )
}

const SelectGroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "initial",
                margin: "-3px 0 0 10px",
                fontSize: "14px",
                border: "none",
                zIndex: "103",
                outline: "none"
            }}
        />
    )
}

const CoachQuotaSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать"}
            labelField={"username"}
            valueField={"username"}
            sortBy={"username"}
            create
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "initial",
                margin: "-3px 0 0 10px",
                fontSize: "14px",
                border: "none",
                zIndex: "101",
                outline: "none"
            }}
        />
    )
}

export default function User(props) {
    const [roomnamesChangerType, setRoomnamesChangerType] = useState("add");
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectedRemoveRooms, setSelectedRemoveRooms] = useState([]);
    const [addRoomnameNick, setAddRoomnameNick] = useState("");

    const currentRooms = props.SupportInfo.currentUser && props.SupportInfo.currentUser.room_names && props.SupportInfo.currentUser.room_names[0] ?
        props.SupportInfo.currentUser.room_names.map(e => ({name: e})) : [];

    const rooms = [
        {name: "PokerStars", shortname: "PS"},
        {name: "GGnetwork", shortname: "GGN"},
        {name: "Tigergaming", shortname: "TG"},
        {name: "Winnings (Pokerking)", shortname: "WPN"},
        {name: "IPoker (RedStar)", shortname: "IP(RS)"},
        {name: "Pacific", shortname: "PAC"},
        {name: "Winamax", shortname: "WMX"},
        {name: "PokerStarsES", shortname: "PSfr"},
        {name: "PartyPoker", shortname: "PP"},
        {name: "BetOnline", shortname: "BO"},
        {name: "Sportbetting", shortname: "SB"},
        {name: "Microgaming", shortname: "MPN"},
        {name: "Bodog", shortname: "BOD"},
        {name: "PokerDom", shortname: "PD"},
        {name: "PartyPokerLive", shortname: "PPL"},
        {name: "ACR(Americas Cardroom)", shortname: "ACR"},
        {name: "IPoker (Champion)", shortname: "IP(CH)"},
        {name: "IPoker (BetClic)", shortname: "IP(BET)"},
    ]

    const handleSelectRoomnames = (e) => {
        if (e[0]) {
            setSelectedRooms(e);
        } else {
            setSelectedRooms([]);
        }
    }
    const handleSelectRemoveRoomnames = (e) => {
        if (e[0]) {
            setSelectedRemoveRooms(e);
        } else {
            setSelectedRemoveRooms([]);
        }
    }

    const handleSelectCoachGroup = (e) => {
        if (e[0]) {
            props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_coach_group", e[0].name);
        } else {
            props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_coach_group", "");
        }
    }

    const handleSelectSelectGroup = (e) => {
        if (e[0]) {
            props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_select_group", e[0].name);
        } else {
            props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_select_group", "");
        }
    }
    // if (props.SupportInfo.currentUser.coaches_quota.some(e => coach.id == e.id)) {
    //     return <div
    //         key={`${coach.id} update-coach-user`}
    //         onClick={() => props.SupportUpdateUserCoach("remove", coach.id, coach.username, props.SupportInfo.currentUser.direction, props.SupportInfo.currentUser.id)}
    //         className={"role-page-access-block green-back"}>{coach.username}
    //     </div>
    // } else {
    //     return <div
    //         key={`${coach.id} update-coach-user`}
    //         onClick={() => props.SupportUpdateUserCoach("add", coach.id, coach.username, props.SupportInfo.currentUser.direction, props.SupportInfo.currentUser.id)}
    //         className={"role-page-access-block default-back"}>{coach.username}
    //     </div>
    // }

    const handleSelectCoachQuota = (e) => {
        if (e[0]) {
            let maxIndex = e.length - 1;
            if (!props.SupportInfo.currentUser.coaches_quota.some(ee => ee.id == e[maxIndex].id)) {
                props.SupportUpdateUserCoach("add", e[maxIndex].id, e[maxIndex].username, props.SupportInfo.currentUser.direction, props.SupportInfo.currentUser.id)
            } else {
                props.SupportInfo.currentUser.coaches_quota.forEach(ee => {
                    if (!e.some(eee => eee.id == ee.id)) {
                        props.SupportUpdateUserCoach("remove", ee.id, ee.name, props.SupportInfo.currentUser.direction, props.SupportInfo.currentUser.id)
                    }
                })
            }
        } else {
            if (props.SupportInfo.currentUser.coaches_quota[0] && props.SupportInfo.currentUser.coaches_quota[0].id) {
                props.SupportUpdateUserCoach("remove", props.SupportInfo.currentUser.coaches_quota[0].id, props.SupportInfo.currentUser.coaches_quota[0].name, props.SupportInfo.currentUser.direction, props.SupportInfo.currentUser.id)
            }
        }
    }

    const handleChangeConverterDate = (date) => {
        props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_converter_date", +date)
    };

    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            }
        }
    });
    return props.SupportInfo.currentUser ? props.isLoading ? <MainLoading/>
        :
        <div className={"support-user-editor-page"}>
            <div style={{margin: "20px 0", padding: "0 20%", color: "white"}}>
                {props.SupportInfo.currentUser.direction == "MTT" ?
                    <ProfileMTTContainer {...props} /> : props.SupportInfo.currentUser.direction == "SPIN" ?
                        <ProfileSPINContainer {...props}/> : null}
            </div>


            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Никнейм
                </div>
                <div className={"info"}>
                    {props.SupportInfo.currentUser.username}
                </div>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Email
                </div>
                <div className={"info"}>
                    {props.SupportInfo.currentUser.email}
                </div>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Направление
                </div>
                <div className={"info"}>
                    {props.SupportInfo.currentUser.direction}
                </div>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Был онлайн
                </div>
                <div className={"info"}>
                    {props.SupportInfo.currentUser.online}
                </div>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Роли в ЛК
                </div>
                <div className={"info"}>
                    {props.SupportInfo.currentUser.roles.map(e => `${e.name} | `)}
                </div>
            </div>
            {
                props.SupportInfo.currentUser.email && <div className={"support-user-editor-page-item"}>
                    <div className={"name"}>
                        Доступ к Leakfinder'у
                    </div>
                    <div className={"info"}>
                        <input type="checkbox" checked={props.SupportInfo.currentUser.isPlayerLeakfinderAccess}
                               onClick={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "isPlayerLeakfinderAccess", !props.SupportInfo.currentUser.isPlayerLeakfinderAccess)}
                        />
                    </div>
                </div>
            }

            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Отсутствует на тренировках
                </div>
                <div className={"info"}>
                    <input type="checkbox" checked={props.SupportInfo.currentUser.is_training_inactive}
                           onClick={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "is_training_inactive", !props.SupportInfo.currentUser.is_training_inactive)}
                    />
                </div>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Причина отсутствия на тренировках
                </div>
                <input className={"small-input"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "training_inactive_reason", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.training_inactive_reason}/>
            </div>

            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Таймзона
                </div>
                <input className={"small-input"} type={"text"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_timezone", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.timezone}/>
            </div>

            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Formular Google Sheet ID
                </div>
                <input className={"small-input"} type={"text"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_formular_google_sheet_id", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.formular_google_sheet_id}/>
                <div style={{color: "#832b2b", padding: "5px 15px", fontSize: "13px"}}>
                    Прежде чем вставлять ID - ОБЯЗАТЕЛЬНО выдайте доступ почте бота
                </div>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Shark Alias
                </div>
                <input className={"small-input"} type={"text"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_shark_alias", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.shark_alias}/>
            </div>

            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Ники игрока в румах
                </div>
                {/*<input className={"small-input"}*/}
                {/*       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_room_names", e.target.value)}*/}
                {/*       placeholder={"room:ник для добавления/удаления"}/>*/}
                <Popup key={`user-rommnames`}
                       trigger={
                           <div className={"btn default-back transition"}><span
                               style={{padding: "0 8px"}}>Изменить</span></div>
                       }
                       modal
                       nested
                >
                    {() => (
                        <div>
                            <div id="get-advice" className="user-page-popup"
                                 style={{
                                     color: "white",
                                     width: "1000px",
                                     height: `${roomnamesChangerType == "add" ? "300px" : "260px"}`
                                 }}>
                                <form className="form">
                                    <div style={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 110px 5px 110px 1fr",
                                        marginTop: "25px"
                                    }}>
                                        <div></div>
                                        <div style={{padding: "3px 10px", textAlign: "center", cursor: "pointer"}}
                                             className={`btn default-button transition ${roomnamesChangerType == "add" ? "green-back" : "default-back"}`}
                                             onClick={() => setRoomnamesChangerType("add")}>Добавление
                                        </div>
                                        <div></div>
                                        <div style={{padding: "3px 10px", textAlign: "center", cursor: "pointer"}}
                                             className={`btn default-button transition ${roomnamesChangerType == "remove" ? "red-back" : "default-back"}`}
                                             onClick={() => setRoomnamesChangerType("remove")}>Удаление
                                        </div>
                                        <div></div>
                                    </div>

                                    {
                                        roomnamesChangerType == "add" &&
                                        <div style={{
                                            margin: "40px 0 0 -20px",
                                            display: "grid",
                                            gridTemplateColumns: "1fr 300px 1fr"
                                        }}>
                                            <div></div>
                                            <RoomSelector options={rooms}
                                                          selected={selectedRooms}
                                                          handleSelect={handleSelectRoomnames}/>
                                            <div></div>
                                        </div>
                                    }
                                    {
                                        roomnamesChangerType == "add" &&
                                        <div style={{
                                            margin: "10px 0 0 -20px",
                                            display: "grid",
                                            gridTemplateColumns: "1fr 300px 1fr"
                                        }}>
                                            <div></div>
                                            <input style={{
                                                margin: "0 0 0 15px",
                                                padding: "5px 10px",
                                                color: "white",
                                                backgroundColor: "#353748",
                                                outline: "none",
                                                border: "none"
                                            }} placeholder={"Введите ник в руме"} value={addRoomnameNick}
                                                   onChange={(e) => setAddRoomnameNick(e.target.value)} type={"text"}/>
                                            <div></div>
                                        </div>
                                    }

                                    {
                                        roomnamesChangerType == "add" && addRoomnameNick && addRoomnameNick.length >= 3 && selectedRooms[0] &&
                                        <div style={{
                                            margin: "40px 0 0 -20px",
                                            display: "grid",
                                            gridTemplateColumns: "1fr 130px 1fr"
                                        }}>
                                            <div></div>
                                            <div style={{marginLeft: "10px"}}
                                                 onClick={() => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_room_names_add", `${selectedRooms[0].shortname}:${addRoomnameNick}`)}
                                                 className={"button default-button green-back"}>Добавить
                                            </div>
                                            <div></div>
                                        </div>
                                    }

                                    {
                                        roomnamesChangerType == "remove" &&
                                        <div style={{
                                            margin: "40px 0 0 -20px",
                                            display: "grid",
                                            gridTemplateColumns: "1fr 300px 1fr"
                                        }}>
                                            <div></div>
                                            <RoomSelector options={currentRooms}
                                                          selected={selectedRemoveRooms}
                                                          handleSelect={handleSelectRemoveRoomnames}/>
                                            <div></div>
                                        </div>
                                    }
                                    {
                                        roomnamesChangerType == "remove" && selectedRemoveRooms[0] &&
                                        <div style={{
                                            margin: "40px 0 0 -20px",
                                            display: "grid",
                                            gridTemplateColumns: "1fr 130px 1fr"
                                        }}>
                                            <div></div>
                                            <div
                                                onClick={() => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_room_names_remove", selectedRemoveRooms[0].name)}
                                                style={{marginLeft: "10px"}}
                                                className={"button default-button red-back"}>Удалить
                                            </div>
                                            <div></div>
                                        </div>
                                    }

                                </form>
                            </div>
                        </div>
                    )}
                </Popup>
                <div style={{color: "gray", padding: "5px 15px", fontSize: "13px"}}>
                    Текущий список: {props.SupportInfo.currentUser.room_names.map(e => <span> {e};</span>)}
                </div>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Активный игрок
                </div>
                <input className={"small-input"} type={"number"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_is_active", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.isActive}/>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    % Доли игрока (формуляр)
                </div>
                <input className={"small-input"} type={"number"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_formular_player_share_percent", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.formular_player_share_percent}/>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Никнейм для фин. результатов
                </div>
                <input className={"small-input"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_fin_results_nickname", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.fin_results_nickname}/>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    ID канала (игрок) для уведомлений
                </div>
                <input className={"small-input"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_training_channel_id", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.training_channel_id}/>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    ID канала (тренер) для уведомлений
                </div>
                <input className={"small-input"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_training_coach_channel_id", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.training_coach_channel_id}/>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    ID ветки Select
                </div>
                <input className={"small-input"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_select_channel_id", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.select_channel_id}/>
            </div>
            {
                props.SupportInfo.currentUser.rank_level != "not_registered" &&
                <div className={"support-user-editor-page-item"}>
                    <div className={"name"}>
                        Ранг
                    </div>
                    <input className={"small-input"} type={"number"}
                           onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_rank_level", e.target.value)}
                           defaultValue={props.SupportInfo.currentUser.rank_level}/>
                </div>
            }

            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Select Group
                </div>

                <div style={{
                    color: "white", width: "270px"
                }}>

                    <SelectGroupSelector options={[{name: "A"},{name: "B"},{name: "C"},{name: "D"},{name: "E"}]}
                                        selected={props.SupportInfo.currentUser?.select_group ? [{name: props.SupportInfo.currentUser.select_group}] : []}
                                         handleSelect={handleSelectSelectGroup}/>
                </div>
            </div>

            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Группа для тренировок (блок)
                </div>

                <div style={{
                    color: "white", width: "270px"
                }}>

                    <CoachGroupSelector options={props.SupportInfo.currentUser?.UniqGroups}
                                        selectedCoachGroup={props.SupportInfo.currentUser?.coach_group ? [{name: props.SupportInfo.currentUser.coach_group}] : []}
                                        handleSelectCoachGroup={handleSelectCoachGroup}/>
                </div>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Доступные тренеры
                </div>
                <div style={{
                    color: "white", width: "270px"
                }}>

                    <CoachQuotaSelector options={props.SupportInfo.coachesList}
                                        selected={props.SupportInfo.currentUser?.coaches_quota && props.SupportInfo.currentUser.coaches_quota[0]
                                            ? props.SupportInfo.currentUser.coaches_quota.map(e => ({
                                                username: e.name,
                                                id: e.id
                                            }))
                                            : []}
                                        handleSelect={handleSelectCoachQuota}/>
                </div>

                {/*<Popup key={`user-coaches-quota ${Math.random(1)}`}*/}
                {/*    trigger={*/}
                {/*        <div className={"btn default-back transition"}><span>Открыть список</span></div>*/}
                {/*    }*/}
                {/*    modal*/}
                {/*    nested*/}
                {/*>*/}
                {/*    {() => (*/}
                {/*        <div>*/}
                {/*            <div id="get-advice" className="user-page-popup">*/}
                {/*                <form className="form">*/}
                {/*                    {*/}
                {/*                        props.SupportInfo.coachesList.map(coach => {*/}
                {/*                            if (props.SupportInfo.currentUser.coaches_quota.some(e => coach.id == e.id)) {*/}
                {/*                                return <div*/}
                {/*                                    key={`${coach.id} update-coach-user`}*/}
                {/*                                    onClick={() => props.SupportUpdateUserCoach("remove", coach.id, coach.username, props.SupportInfo.currentUser.direction, props.SupportInfo.currentUser.id)}*/}
                {/*                                    className={"role-page-access-block green-back"}>{coach.username}*/}
                {/*                                </div>*/}
                {/*                            } else {*/}
                {/*                                return <div*/}
                {/*                                    key={`${coach.id} update-coach-user`}*/}
                {/*                                    onClick={() => props.SupportUpdateUserCoach("add", coach.id, coach.username, props.SupportInfo.currentUser.direction, props.SupportInfo.currentUser.id)}*/}
                {/*                                    className={"role-page-access-block default-back"}>{coach.username}*/}
                {/*                                </div>*/}
                {/*                            }*/}
                {/*                        })*/}
                {/*                    }*/}
                {/*                </form>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</Popup>*/}
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Текущая квота на тренировки
                </div>
                <input className={"small-input"} type={"number"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_training_quota", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.training_quota}/>
            </div>
            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    ID личной конференции
                </div>
                <input className={"small-input"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_channel_id", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.channel_id}/>
            </div>

            {/*<div className={"support-user-editor-page-item"}>*/}
            {/*    <div className={"name"}>*/}
            {/*        Leakfinder Analyze DB Name*/}
            {/*    </div>*/}
            {/*    <input className={"small-input"}*/}
            {/*           onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_leakfinder_db_name", e.target.value)}*/}
            {/*           defaultValue={props.SupportInfo.currentUser.leakfinder_db_name}/>*/}
            {/*</div>*/}

            <div className={"support-user-editor-page-item"}>
                <div className={"name"}>
                    Leakfinder Analyze DB Group
                </div>
                <input className={"small-input"}
                       onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, "server_leakfinder_db_group", e.target.value)}
                       defaultValue={props.SupportInfo.currentUser.leakfinder_db_group}/>
            </div>

            {
                props.SupportInfo.currentUser ?
                    <div className={"support-user-editor-page-item"}>
                        <div className={"name"}>
                            Продление конвертера
                        </div>
                        <div style={{margin: "5px 15px", filter: "brightness(0) invert(1)"}}>
                            <ThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker
                                        format="dd.MM.yyyy HH:mm"
                                        value={+props.SupportInfo.currentUser.converter_date}
                                        onChange={handleChangeConverterDate}
                                    />
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </div>
                    </div>
                    : null
            }

            {
                props.SupportInfo.usersCustomInfoTemplates.map(template => {
                    let Info = null;
                    props.SupportInfo.currentUser.custom_info.forEach(info => {
                        if (info.en_name == template.en_name) Info = {...info}
                    })
                    return Info ?
                        <div key={Info.en_name + "supportUserEditor"} className={"support-user-editor-page-item"}>
                            <div className={"name"}>
                                {template.ru_name}
                            </div>
                            <textarea
                                onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, Info.en_name, e.target.value)}
                                defaultValue={Info.data} rows={1}/>
                        </div>
                        :
                        <div key={template.en_name + "supportUserEditor"} className={"support-user-editor-page-item"}>
                            <div className={"name"}>
                                {template.ru_name}
                            </div>
                            <textarea
                                onBlur={(e) => props.SupportEditUser(props.SupportInfo.currentUser.id, props.SupportInfo.currentUser.direction, template.en_name, e.target.value)}
                                defaultValue={template.data} rows={1}/>
                        </div>
                })
            }

            {
                props.SupportInfo.currentUserLogs[0] ?
                    <Popup key={"props.SupportInfo.currentUserLogs[0]"}
                           trigger={
                               <div style={{margin: "30px 0", padding: "0 20%", color: "white"}}>
                                   <div className={"default-back default-button"} style={{width: "100%"}}>Логи
                                       изменений
                                   </div>
                               </div>}
                           modal
                           nested
                           contentStyle={{
                               width: "100%",
                               height: "100%",
                               margin: "0",
                               padding: 0,
                               backgroundColor: "#1e1e25"
                           }}
                    >
                        {(close) => (
                            <div>
                                <div style={{color: "white"}}>
                                    <div style={{textAlign: "right", margin: "15px 5px 0 0"}}>
                    <span style={{
                        color: 'white',
                        backgroundColor: "rgb(206,77,77)",
                        padding: "7px 30px 10px 30px",

                        fontSize: "15px",
                        cursor: "pointer"
                    }}
                          onClick={close}>Закрыть</span>
                                    </div>

                                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr"}}>
                                        <div>Date</div>
                                        <div>Editor</div>
                                        <div>Target</div>
                                        <div>Type</div>
                                        <div>newValue</div>
                                    </div>

                                    {
                                        props.SupportInfo.currentUserLogs.map((e, i) => <div style={{
                                            backgroundColor: i % 2 == 0 ? "none" : "#2d2d3a",
                                            display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr"
                                        }}>
                                            <div>{e.date}</div>
                                            <div>{e.editor_name}</div>
                                            <div>{e.target_name}</div>
                                            <div>{e.type}</div>
                                            <div>{e.newValue}</div>
                                        </div>)
                                    }

                                </div>
                            </div>
                        )}
                    </Popup>
                    : null
            }
        </div> : null
}