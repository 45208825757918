import React, {useState, useRef, useEffect} from "react";
import Popup from "reactjs-popup";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

export default function TasksTickets(props) {
    const [deadlineColorInterval, setDeadlineColorInterval] = useState(false);


    useEffect(() => {

        //Implementing the setInterval method
        const interval = setInterval(() => {
            setDeadlineColorInterval(!deadlineColorInterval)
        }, 2000)

        //Clearing the interval
        return () => clearInterval(interval);
    }, [deadlineColorInterval]);

    return (
        props.SupportInfo.TTList[0] ?
            <div className={"tasks-tickets-page"}>
                <div className={"columns-name"}>
                    <div>Сервер</div>
                    <div>Дата</div>
                    <div>Пользователь</div>
                    <div>Сообщение</div>
                    <div>Вложение</div>
                    <div>Дедлайн</div>
                    <div>Статус</div>
                </div>
                <div className={"list"}>
                    <div id="scrollableDiv" style={{height: "85vh", overflow: "auto", "padding": "0 10px 0 10px"}}>
                        <InfiniteScroll
                            dataLength={props.SupportInfo.TTList.length}
                            next={props.fetchMoreData}
                            hasMore={props.SupportInfo.TTList.length <= props.SupportInfo.TTCount}
                            scrollableTarget="scrollableDiv"
                        >
                            {
                                props.SupportInfo.TTList.map((tt, i) => <TaskTicket index={i} deadlineColorInterval={deadlineColorInterval}
                                                                                    key={tt.timestamp_creation + i}
                                                                                    tt={tt} CloseTT={props.CloseTT}/>)
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </div> : <a style={{color: "white"}}>По выбранным фильтрам ничего не найдено.</a>
    )
}

const TaskTicket = (props) => {
    let status = "Новый";
    if (props.tt.status == "active") status = "В работе"
    if (props.tt.status == "closed") status = "Выполнен"
    if (props.tt.status == "canceled") status = "Отменён"
    if (props.tt.status == "deleted") status = "Удалён"

    let isNeedColorForInterval = false;
    let colorForInterval;

    if ((status == "Новый" || status == "В работе") && props.tt.timestamp_deadline){
        isNeedColorForInterval = true;

        let timestamp_deadline_in_seconds = +new Date(+props.tt.timestamp_deadline) / 1000;
        let timestamp_current_in_second = +new Date() / 1000;

        if (timestamp_current_in_second >= timestamp_deadline_in_seconds) {
            colorForInterval = "#7a3a3a";
        } else if (timestamp_current_in_second + 3600 >= timestamp_deadline_in_seconds) {
            colorForInterval = "#a96444";
        } else if (timestamp_current_in_second + 86400 >= timestamp_deadline_in_seconds) {
            colorForInterval = "#94864c";
        }
    }

    return (
        <Popup key={props.tt.timestamp_creation}
               trigger={
                   <div
                       className={"item transition " + props.tt.status}
                        style={{backgroundColor: props.deadlineColorInterval && isNeedColorForInterval ? colorForInterval : ""}}
                        title={props.tt?.message?.content ? props.tt.message.content : "Пустое сообщение"}>
                       <div>
                           {props.tt.server.name}
                       </div>
                       <div>
                           {props.tt.question_date}
                       </div>
                       <div>
                           {props.tt.user.name}
                       </div>
                       <div>
                           {props.tt?.message?.content ? props.tt.message.content.substring(0, 60) + "..." : "Пустое сообщение"}
                       </div>
                       <div>
                           {props.tt?.message?.attachments[0] ? "Есть" : "Нет"}
                       </div>
                       <div>
                           {props.tt?.timestamp_deadline ? moment(+props.tt.timestamp_deadline).format("DD.MM.YYYY") : "Нет"}
                       </div>
                       <div>
                           {status}
                       </div>
                   </div>}
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="tasks-tickets-popup">
                        <div className={"column"}>
                            <div className={"info-block"}>
                                <div className={"name"}>
                                    Дата создания
                                </div>
                                <div className={"desc"}>
                                    {props.tt.question_date}
                                </div>
                            </div>
                            <div className={"info-block"}>
                                <div className={"name"}>
                                    Дата выполнения
                                </div>
                                <div className={"desc"}>
                                    {props.tt.reaction_date ? props.tt.reaction_date : "Нет"}
                                </div>
                            </div>
                            <div className={"info-block"}>
                                <div className={"name"}>
                                    Дата первой реакции
                                </div>
                                <div className={"desc"}>
                                    {props.tt.answer_date ? props.tt.answer_date : "Нет"}
                                </div>
                            </div>
                            <div className={"info-block"}>
                                <div className={"name"}>
                                    Взял в работу
                                </div>
                                <div className={"desc"}>
                                    {props.tt.helper.id ? props.tt.helper.name : "Не назначен"}
                                </div>
                            </div>
                            <div className={"info-block"}>
                                <div className={"name"}>
                                    Выполнивший
                                </div>
                                <div className={"desc"}>
                                    {props.tt.emoji_author ? props.tt.emoji_author : "Нет"}
                                </div>
                            </div>
                            <br/><br/>
                            {
                                props.tt.status != "closed" && props.tt.status != "canceled"
                                && props.tt.status != "deleted" ?
                                    <div onClick={() => props.CloseTT(props.tt)}
                                         className={"red-back transition default-button"}>Отменить</div>
                                    : null
                            }
                        </div>
                        <div className={"column"}>
                            <div className={"user-message"}>
                                <div className={"user-content"}>
                                    <div
                                        className={"name"}>{props.tt.user.name}</div>
                                    {props.tt?.message?.content ? props.tt.message.content : "Пустое сообщение"}
                                </div>
                                <div className={"user-attachments"}>
                                    {props.tt?.message?.attachments[0] ? props.tt.message.attachments
                                        .map((attachment, i) => <div key={attachment}>
                                            <a target="_blank" rel="noopener noreferrer" href={attachment}>Перейти к
                                                вложению {1 + i}</a>
                                        </div>) : null}
                                </div>
                            </div>
                            {
                                props.tt.messages_history && props.tt.messages_history[0] ?
                                    props.tt.messages_history.map(message => <Message key={message.id + "mh"}
                                                                                      message={message}/>) : null
                            }
                        </div>
                        <div className={"column"}>
                            <div className={"info-block"}>
                                <div className={"name"}>
                                    Статус
                                </div>
                                <div className={"desc"}>
                                    {status}
                                </div>
                            </div>
                            <div className={"info-block"}>
                                <div className={"name"}>
                                    Сервер
                                </div>
                                <div className={"desc"}>
                                    {props.tt.server.name}
                                </div>
                            </div>
                            <div className={"info-block"}>
                                <div className={"name"}>
                                    Канал
                                </div>
                                <div className={"desc"}>
                                    {props.tt.channel.name}
                                </div>
                            </div>
                            <div className={"info-block"}>
                                <br/><br/>
                                <a target="_blank" rel="noopener noreferrer" className={"transition"}
                                   href={`discord://discord.com/channels/${props.tt?.server?.id}/${props.tt?.channel?.id}/${props.tt?.message?.id}`}>Перейти
                                    в Discord</a>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    )
}

const Message = (props) => {
    return (
        <div className={"user-message"}>
            <div className={`${props.message.type}-content`}>
                <div className={"name"}>{props.message.name}</div>
                {props.message?.content ? props.message.content : "Пустое сообщение"}
            </div>
            <div className={`${props.message.type}-attachments`}>
                {props.message?.attachments[0] ? props.message.attachments
                    .map((attachment, i) => <div key={attachment}>
                        <a target="_blank" rel="noopener noreferrer" href={attachment}>Перейти к вложению {1 + i}</a>
                    </div>) : null}
            </div>
        </div>
    )
}