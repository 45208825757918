import React from 'react';
import s from './styles.module.css'
import arrowDown from "../../../assests/arrowDownExchanger.png";
import arrowTop from "../../../assests/arrowTopExchanger.png";
import LastItem from "../2-content/3-block_last/lastItem";

const ExchangerInfo = (props) => {
    const data = [
        {icon: arrowDown, title: 'Средний курс у менял xe.com: ', price: `~${props.FsChangerInfo.FaverageExchange}`, type: 'red'},
        {icon: arrowDown, title: 'Средняя комиссия у менял: ', price: `${props.FsChangerInfo.Fpercent}`, type: 'red'},
        {icon: arrowDown, title: 'При обмене у менял вы получили: ', price: `${props.FsChangerInfo.Fbank}`, type: 'red'},
        {icon: arrowTop, title: 'При обмене у нас вы получите: ', price: `${props.FsChangerInfo.FfsMin}`, type: 'yellow'},
    ]

    return (
        <div className={s.info}>
            {
                props.FsChangerInfo.userCurrentHelp && props.FsChangerInfo.userSum  && props.FsChangerInfo.userSum != 0 &&
                <div className={s.info_first}>
                    <h3>Вывод {props.FsChangerInfo.userSum} на {props.FsChangerInfo.userCurrentBank.public_name} ({props.FsChangerInfo.userCurrentBank.public_type})</h3>
                    {data.map((el, index) => <LastItem index={index} el={el} type={el.type}/>)}
                </div>
            }

            <div className={s.info_second}>
                <h4>Быстрый и надежный вывод средств на различные платежные системы.</h4>
                <p>Сейчас многие наши игроки столкнулись с проблемами при обменах: невозможность вывода на ту или иную
                    платежную систему, высокие проценты обмена у менял, плохие курсы обмена, высокие риски обмана и как
                    результат потеря заработанных денег. Необходимость тратить время и нервы на поиск обмена.</p>
                <p>
                    Мы искали и пробовали различные варианты того, как можно вам помочь и как нам кажется нашли
                    оптимальный. Мы создали данный сервис, который должен помочь вам быстро и надежно вывести
                    заработанное. Мы не являемся "менялами", но имеем большой опыт в работе с ними, и готовы тратить
                    наше время, чтобы сэкономить ваше, и сэкономить ваши деньги. Всё что вам нужно, это выбрать способ
                    получения средств и подать заявку на вывод средств на нашем портале
                </p>
            </div>
        </div>
    );
};

export default ExchangerInfo;