import SpedometrMain from "../common/spedometr_main";
import s from "../components/right_side_bar/styles.module.css";
import PlayersMain from "../common/players_main";
import React from "react";

export const whatShowRightPanel = (location, hiddenRight, props) => {
    console.log(props)

    let stepMttTournamentsNum = 600;
    let stepMttProfitsNum = 10000;
    if (props.MainInfo.mainPageData.sums.selected_count) {
        let result = Math.ceil(+props.MainInfo.mainPageData.sums.selected_count / 600);
        if (result > 1) {
            stepMttTournamentsNum = 600 + result * 200
        }
    }
    if (props.MainInfo.mainPageData.sums.selected_prize) {
        let result = Math.ceil(+props.MainInfo.mainPageData.sums.selected_prize / 10000);
        if (result > 1) {
            stepMttProfitsNum = 10000 + result * 10000
        }
    }
    switch (location) {
        case '/' : {
            return props.AuthInfo.verificator.direction == "MTT" ?
                <>
                    <SpedometrMain min={props.MainInfo.mainPageData.sums.selected_count ? Math.ceil(props.MainInfo.mainPageData.sums.selected_count) : 0} max={stepMttTournamentsNum} hiddenRight={props.hiddenRight} maxS={stepMttTournamentsNum} currentS={props.MainInfo.mainPageData.sums.selected_count ? Math.ceil(props.MainInfo.mainPageData.sums.selected_count) : 0}
                                   titleS={props.MainInfo.mainPageData.sums.selected_count ? Math.ceil(props.MainInfo.mainPageData.sums.selected_count) : 0} typeS={'blue'} classNameS={s.blue} title={'Сыграно турниров'} title_help={"---"}/>

                    <SpedometrMain min={`$${props.MainInfo.mainPageData.sums.selected_prize ? Math.ceil(props.MainInfo.mainPageData.sums.selected_prize) : 0}`} max={`$${stepMttProfitsNum}`} hiddenRight={props.hiddenRight} maxS={stepMttProfitsNum} currentS={props.MainInfo.mainPageData.sums.selected_prize ? Math.ceil(props.MainInfo.mainPageData.sums.selected_prize) : 0}
                                   titleS={`$${props.MainInfo.mainPageData.sums.selected_prize ? Math.ceil(props.MainInfo.mainPageData.sums.selected_prize) : 0}`} typeS={'gold'} classNameS={s.blue} title={'Профит'} title_help={"---"}/>
                </>
                :
                <>
                    <SpedometrMain min={props.MainInfo.mainPageData.sums.period_hands ? Math.ceil(props.MainInfo.mainPageData.sums.period_hands) : 0} max={'30 000'} hiddenRight={props.hiddenRight} maxS={30000} currentS={props.MainInfo.mainPageData.sums.period_hands ? Math.ceil(props.MainInfo.mainPageData.sums.period_hands) : 0}
                                   titleS={props.MainInfo.mainPageData.sums.period_hands ? Math.ceil(props.MainInfo.mainPageData.sums.period_hands) : 0} typeS={'blue'} classNameS={s.blue} title={'Сыграно раздач'} title_help={"В данном счетчике указана рекомендуемая дистанция для игры в Cash. \n" +
                        "Подсчет турниров ведется за календарный месяц."}/>

                    <SpedometrMain min={`$${props.MainInfo.mainPageData.sums.period_profit ? Math.ceil(props.MainInfo.mainPageData.sums.period_profit) : 0}`} max={'$10 000'} hiddenRight={props.hiddenRight} maxS={10000} currentS={props.MainInfo.mainPageData.sums.period_profit ? Math.ceil(props.MainInfo.mainPageData.sums.period_profit) : 0}
                                   titleS={`$${props.MainInfo.mainPageData.sums.period_profit ? Math.ceil(props.MainInfo.mainPageData.sums.period_profit) : 0}`} typeS={'gold'} classNameS={s.blue} title={'Профит'} title_help={"Подсчет профита ведется за календарный месяц.\n" +
                        "Мы добавим возможность устанавливать себе цели для каждого игрока в последующих обновлениях."}/>

                    <PlayersMain hiddenRight={hiddenRight} formular_player_share_percent={props.MainInfo.mainPageData.formular_player_share_percent}/>
            </>
        }

        case '/exchanger' : {
            return <>
                {/*<PlayersMain hiddenRight={hiddenRight}/>*/}
            </>
        }

        default :
            return null
    }
}