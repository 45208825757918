import React from 'react';
import s from './styles.module.css'

const Row = ({
                 id,
                 isCategory = false,
                 style,
                 tooltip,
                 hidden = false,
                 children,
                 min = 100,
                 max = 200,
                 align = 'center',
                 className, type
             }) => {
    return (
        <div className={s.row + ' ' + className} id={id} style={{
            minWidth: min,
            maxWidth: max,
            textAlign: align,
            fontWeight: isCategory && 600,
            fontSize: 14,
            justifyContent: align === 'left' ? 'flex-start' : 'center',
            display: hidden && 'none',
            backgroundColor: type == "avg" ? "#2c383f" : null,
            ...style
        }}>
            <p title={tooltip}>
                {children}
            </p>
        </div>
    );
};

export default Row;
