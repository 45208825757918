
import React from 'react';
import s from './styles.module.css'
import Container from "../../container";
import {Rating} from "@mui/material";


const CommentBox = ({date, user, stars = 3, message}) => {
    return (
        <Container className={s.comment_box}>
            <p className={s.date}>{date}</p>
            <p className={s.name}>{user.name}</p>
            <Rating name="size-small" defaultValue={stars} size="small" readOnly/>
            <p className={s.text}>{message}</p>
        </Container>
    );
};

export default CommentBox;