import React, {useState} from 'react';
import Popup from "reactjs-popup";

const LeakfinderSchemas = (props) => {
    const [schemaCreatorRankName, setSchemaCreatorRankName] = useState(null);
    const [selectedSchemaIndex, setSelectedSchemaIndex] = useState(null);

    const handleCreateSchema = (close) => {
        if (schemaCreatorRankName && schemaCreatorRankName.length >= 2) {
            props.LeakfinderCreateSchema(schemaCreatorRankName)
            setSchemaCreatorRankName(null)
            close();
        }
    }

    const handleChangeRow = (categoryName, index, key, value) => {
        props.LeakfinderPreEditSchema(categoryName, index, key, value, selectedSchemaIndex)
    }

    const handleChangeCategory = (categoryName, flop) => {
        if (flop == "pre") {
            const length = props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_pre_flop[categoryName].length;
            const indexOfTrue = props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_pre_flop[categoryName].findIndex(e => e.is_visible == true);
            for (let i = 0; i < length; i++) {
                handleChangeRow(categoryName, i, "is_visible", indexOfTrue == -1 ? true : false)
            }
        } else if (flop == "post") {
            const length = props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_post_flop[categoryName].length;
            const indexOfTrue = props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_post_flop[categoryName].findIndex(e => e.is_visible == true);
            for (let i = 0; i < length; i++) {
                handleChangeRow(categoryName, i, "is_visible", indexOfTrue == -1 ? true : false)
            }
        }
    }

    const handleDeleteSchema = () => {
        props.LeakfinderDeleteSchema(props.LeakfinderInfo.schemas[selectedSchemaIndex]._id)
        setSelectedSchemaIndex(null)
    }

    const skipCategories = ['TOTAL PREFLOP', 'Unopened', 'vs 1 Raiser', 'vs 1 Raiser + Call (s)', 'BB Defense', 'TOTAL POSTFLOP', 'Single Raise Pot', '3bet pot', 'SB vs BB'];

    return (
        <div>
            <div style={{textAlign: "left", margin: "30px"}}>
                {
                    props.LeakfinderInfo.schemas.map((s, i) => <span key={`schemaPicker${i}`}
                                                                     style={{
                                                                         color: "white",
                                                                         cursor: "pointer",
                                                                         padding: "10px 30px",
                                                                         backgroundColor: i == selectedSchemaIndex ? "#0073d7" : "grey",
                                                                         borderRadius: "20px",
                                                                         margin: "20px"
                                                                     }}
                                                                     onClick={() => setSelectedSchemaIndex(i)}>
                        {s.name}
                    </span>)
                }
                <Popup key={"createSchema"}
                       trigger={
                           <span
                               style={{
                                   color: "#14b245", cursor: "pointer", padding: "10px", borderRadius: "20px",
                                   margin: "20px", textDecoration: "underline",
                                   textDecorationColor: "#52ff85"
                               }}>
                               {"Создать новый ранг (схему)"}
                           </span>}
                       modal
                       nested
                >
                    {(close) => (
                        <div>
                            <div id="get-advice" className="main-popup">
                                <form className="form">
                                    <div className="formgroup">
                                        <input onChange={(e) => setSchemaCreatorRankName(e.target.value)} type="text"
                                               placeholder="Название ранга"/>
                                    </div>
                                </form>
                                <button onClick={() => handleCreateSchema(close)} className={"send-btn"}>Создать
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>

            {
                selectedSchemaIndex !== null &&
                <div style={{textAlign: "left", margin: "90px 30px 40px 30px"}}>
                <span style={{
                    color: "white",
                    cursor: "pointer",
                    padding: "10px 30px",
                    backgroundColor: "#0073d7",
                    borderRadius: "20px",
                    margin: "20px"
                }}
                      onClick={() => props.LeakfinderEditSchema(props.LeakfinderInfo.schemas[selectedSchemaIndex]._id, props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_pre_flop, props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_post_flop, props.LeakfinderInfo.schemas[selectedSchemaIndex].pre_flop_total_percent_etalon, props.LeakfinderInfo.schemas[selectedSchemaIndex].post_flop_total_percent_etalon)}>
                    Сохранить изменения у выбранного ранга
                </span>
                    <span style={{
                        color: "white",
                        cursor: "pointer",
                        padding: "10px 30px",
                        backgroundColor: "#673232",
                        borderRadius: "20px",
                        margin: "20px"
                    }} onClick={handleDeleteSchema}>
                    Удалить ранг (схему)
                </span>
                </div>
            }

            <div style={{margin: "30px"}}>
                {
                    selectedSchemaIndex !== null &&
                    <div style={{
                        padding: "50px 60px 40px 30px",
                        fontSize: "15px",
                        lineHeight: "1.2em"
                    }}>


                        <div style={{marginBottom: "65px"}}>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 10px 1fr 3fr",
                                color: "#0073d7",
                                fontWeight: "bolder"
                            }}>
                                <span> </span>
                                <span>Эталон (мин)</span>
                                <div></div>
                                <span>Эталон (макс)</span>
                                <div></div>
                            </div>

                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 10px 1fr 3fr",
                                color: "white", textAlign: "left", padding: "5px"
                            }}>
                                <span>Preflop Percent Etalon</span>
                                <input type={"number"} style={{textAlign: "center"}} value={props.LeakfinderInfo.schemas[selectedSchemaIndex].pre_flop_total_percent_etalon[0]}
                                       onChange={(e) => handleChangeRow(null, null, "pre_flop_total_percent_etalon_min", e.target.value)}/>
                                <div></div>
                                <input type={"number"} style={{textAlign: "center"}} value={props.LeakfinderInfo.schemas[selectedSchemaIndex].pre_flop_total_percent_etalon[1]}
                                       onChange={(e) => handleChangeRow(null, null, "pre_flop_total_percent_etalon_max", e.target.value)}/>
                                <div></div>
                            </div>

                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 10px 1fr 3fr",
                                color: "white", textAlign: "left", padding: "5px"
                            }}>
                                <span>Postflop Percent Etalon</span>
                                <input type={"number"} style={{textAlign: "center"}} value={props.LeakfinderInfo.schemas[selectedSchemaIndex].post_flop_total_percent_etalon[0]}
                                       onChange={(e) => handleChangeRow(null, null, "post_flop_total_percent_etalon_min", e.target.value)}/>
                                <div></div>
                                <input type={"number"} style={{textAlign: "center"}} value={props.LeakfinderInfo.schemas[selectedSchemaIndex].post_flop_total_percent_etalon[1]}
                                       onChange={(e) => handleChangeRow(null, null, "post_flop_total_percent_etalon_max", e.target.value)}/>
                                <div></div>
                            </div>
                        </div>

                        <Header/>

                        {Object.keys(props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_pre_flop).map((keyName, i) => (
                            <div key={`${keyName} ${i} ${selectedSchemaIndex}`}>
                                <div style={{
                                    textAlign: "left",
                                    color: "#0073d7",
                                    fontWeight: "bolder",
                                    borderTop: "1px #0073d7 solid"
                                }}>{keyName} { !skipCategories.includes(keyName) ? <span style={{cursor: "pointer", color: "#48a4ad"}} onClick={() => handleChangeCategory(keyName, "pre")}> Снять/выделить всё</span> : null}
                                </div>
                                {props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_pre_flop[keyName].map((e, j) => {
                                    return <Row e={e} i={j} handleChangeRow={handleChangeRow} categoryName={keyName}/>
                                })}
                            </div>
                        ))}

                        {Object.keys(props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_post_flop).map((keyName, i) => (
                            <div key={`${keyName} ${i} ${selectedSchemaIndex}`}>
                                <div style={{
                                    textAlign: "left",
                                    color: "#0073d7",
                                    fontWeight: "bolder",
                                    borderTop: "1px #0073d7 solid"
                                }}>{keyName} { !skipCategories.includes(keyName) ? <span style={{cursor: "pointer", color: "#48a4ad"}} onClick={() => handleChangeCategory(keyName, "post")}> Снять/выделить всё</span> : null}
                                </div>
                                {props.LeakfinderInfo.schemas[selectedSchemaIndex].schema_post_flop[keyName].map((e, j) => {
                                    return <Row e={e} i={j} handleChangeRow={handleChangeRow} categoryName={keyName}/>
                                })}
                            </div>
                        ))}
                    </div>
                }


            </div>
        </div>
    );
};


const Header = (props) => {
    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "25% 25% 10% 1% 10% 1% 10% 1% 10%",
            color: "#0073d7",
            fontWeight: "bolder"
        }}>
            <span>Отображать в поиске</span>
            <span>Название</span>
            <span>Эталон (мин)</span>
            <div></div>
            <span>Эталон (макс)</span>
            <div></div>
            <span>Эталон EV (мин)</span>
            <div></div>
            <span>Эталон EV (макс)</span>
        </div>
    )
}

const Row = (props) => {
    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "25% 25% 10% 1% 10% 1% 10% 1% 10%",
            backgroundColor: (+props.i) % 2 == 0 ? "#1d1d25" : "initial",
            color: "white", textAlign: "left", padding: "5px"
        }}>
            <input type={"checkbox"} checked={props.e.is_visible}
                   onClick={() => props.handleChangeRow(props.categoryName, props.i, "is_visible", !props.e.is_visible)}/>
            <span>{props.e.name}</span>
            <input type={"number"} style={{textAlign: "center"}} value={props.e.standards[0]}
                   onChange={(e) => props.handleChangeRow(props.categoryName, props.i, "standards_min", e.target.value)}/>
            <div></div>
            <input type={"number"} style={{textAlign: "center"}} value={props.e.standards[1]}
                   onChange={(e) => props.handleChangeRow(props.categoryName, props.i, "standards_max", e.target.value)}/>
            <div></div>
            <input type={"number"} style={{textAlign: "center"}} value={props.e.evStandards[0]}
                   onChange={(e) => props.handleChangeRow(props.categoryName, props.i, "standards_ev_min", e.target.value)}/>
            <div></div>
            <input type={"number"} style={{textAlign: "center"}} value={props.e.evStandards[1]}
                   onChange={(e) => props.handleChangeRow(props.categoryName, props.i, "standards_ev_max", e.target.value)}/>
        </div>
    )
}


export default LeakfinderSchemas;