import React from 'react';
import {accordionItems} from "../../../left_side_bar/left_side_bat.constants";

import classNames from "classnames";
import s from './styles.module.css'
import {ReactComponent as Main} from "../../../left_side_bar/icons/1.svg";
import ItemNavigate from "../../../../common/accordion";
import TrainerButton from "../../../../common/TrainerButton";
import ShopButton from "../../../../common/ShowButton";
import SelectButton from "../../../../common/SelectButton";


const NavigateMenu = ({
                          setSelected, showRightMenu,
                          selected,
                          setSelectFirst,
                          selectFirst,
                          setSelectSecond,
                          selectSecond,
                          setSelectThird,
                          selectThird,
                          hiddenLeft,
                          setHiddenLeft,
                          hiddenRight,
                          setHiddenRight,
                          onClose,
                          AuthInfo
                      }) => {

    return (
        <div style={{paddingBottom: '30px'}}>
            <SelectButton hiddenLeft={hiddenLeft}/>

            {accordionItems().map((item, index) => <ItemNavigate
                AuthInfo={AuthInfo}
                onClose={onClose}
                setSelectThird={setSelectThird}
                selectThird={selectThird}
                setSelectFirst={setSelectFirst}
                selectFirst={selectFirst}
                setSelectSecond={setSelectSecond}
                selectSecond={selectSecond}
                item={item}
                showRightMenu={showRightMenu}
                key={index}
                svgComponent={item.svgComponent}
                title={item.title}
                setSelected={setSelected}
                selected={selected}  {...item}
                hiddenLeft={hiddenLeft}/>)}

            <ShopButton hiddenLeft={hiddenLeft}/>
            <TrainerButton hiddenLeft={hiddenLeft}/>
        </div>
    );
};

export default NavigateMenu;