import React, {useState} from 'react';
import Select from 'react-dropdown-select';
import {TrainingsSendPlayersTask} from "../../../redux/reducers/trainingReducer";

const GroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите группу"}
            labelField={"coach_group"}
            valueField={"coach_group"}
            sortBy={"coach_group"}
            create
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectGroup(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "101"}}
        />
    )
}

const DirectionSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите направление"}
            labelField={"direction"}
            valueField={"direction"}
            sortBy={"direction"}
            create
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectDirection(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "101"}}
        />
    )
}

const PlayerSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите игрока"}
            labelField={"nickname"}
            valueField={"nickname"}
            sortBy={"nickname"}
            multi
            create
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedPlayers}
            onChange={(value) => {
                props.handleSelectPlayer(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "101"}}
        />
    )
}

const CoachDiscordSender = (props) => {
    const [selectedType, setSelectedType] = useState("default")


    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [message, setMessage] = useState("");
    const [file, setFile] = useState("");

    const [description, setDescription] = useState("");
    const [date, setDate] = useState(8);
    const [upCount, setUpCount] = useState(1);
    const [upDescription, setUpDescription] = useState("");

    let uploadFileHandler = (e) => {
        const files = [...e.target.files];
        setFile(files[0])
    }

    const handleSelectGroup = (value) => {
        if (value && value[0] && value[0].coach_group) {
            props.DiscordSenderUpdateCurrentPlayers("group", value[0].coach_group)
        } else {
            props.DiscordSenderUpdateCurrentPlayers("default", null)
        }
    }
    const handleSelectDirection = (value) => {
        if (value && value[0] && value[0].direction) {
            props.DiscordSenderUpdateCurrentPlayers("direction", value[0].direction)
        } else {
            props.DiscordSenderUpdateCurrentPlayers("default", null)
        }
    }
    const handleSelectPlayer = (value) => {
        setSelectedPlayers(value)
    }

    const handleSendMessage = () => {
        props.TrainingsSendDiscordMessage(selectedPlayers, message, file);
        setMessage("")
    }

    const handleSelectAll = () => {
        setSelectedPlayers(props.TrainingInfo.discord_sender_current_players_list)
    }

    const handleRemoveAll = () => {
        setSelectedPlayers([])
    }

    const handleChangeDate = (e) => {
        setDate(e.target.value && !isNaN(+e.target.value) ? +e.target.value : 0);
    }
    const handleChangeUpCount = (e) => {
        setUpCount(e.target.value && !isNaN(+e.target.value) ? +e.target.value : 0);
    }
    const handleChangeUpDescription = (e) => {
        setUpDescription(e.target.value);
    }

    const handleSendPlayersTask = () => {
        props.TrainingsSendPlayersTask(selectedPlayers, message, description, date, upCount, upDescription)
    }

    return (
        <div>
            <div style={{display: "grid", gridTemplateColumns: "1fr 200px 20px 200px 1fr",
                marginTop: "30px",}}>
                <div></div>
                <div className={`button default-button ${selectedType == "default" ? "green-back" : "default-back"}`}
                     onClick={() => setSelectedType("default")}
                >Обычная рассылка
                </div>
                <div></div>
                <div className={`button default-button ${selectedType == "task" ? "green-back" : "default-back"}`}
                     onClick={() => setSelectedType("task")}
                >Задача по времени
                </div>
                <div></div>
            </div>
            <div>
                <textarea style={{
                    width: "90%",
                    marginTop: "20px",
                    padding: "10px",
                    marginLeft: "-20px",

                }}
                          value={message} onChange={(e) => setMessage(e.target.value)}
                          rows={15} placeholder={"Введите сообщение..."}/>
            </div>

            {
                selectedType == "task" && <div>
                <textarea style={{
                    width: "90%",
                    marginTop: "20px",
                    padding: "10px",
                    marginLeft: "-20px",
                    backgroundColor: "#232531",
                    color: "white"
                }}
                          value={description} onChange={(e) => setDescription(e.target.value)}
                          rows={2} placeholder={"Введите описание задачи (это будет отправляться саппортам, в случае, если игрок не выполнил задачу к нужному времени (чтобы полный текст задачи не писал бот))"}/>
                </div>
            }

            {
                selectedType == "task" && <div style={{textAlign: "left", margin: "20px 4.5%",
                    color: "white"}}>
                    <p>Введите кол-во часов на выполнение задачи</p>
                    <input
                        style={{
                            textAlign: "left",
                            width: "100px",
                            marginTop: "10px",
                            padding: "5px",
                            backgroundColor: "#232531", color: "white"
                        }}
                        type="number" value={date} onChange={handleChangeDate}/>
                </div>
            }

            {
                selectedType == "task" && <div style={{textAlign: "left", margin: "20px 4.5%",
                    color: "white"}}>
                    <p>Введите количество апов</p>
                    <input
                        style={{
                            textAlign: "left",
                            width: "100px",
                            marginTop: "10px",
                            padding: "5px",
                            backgroundColor: "#232531", color: "white"
                        }}
                        type="number" value={upCount} onChange={handleChangeUpCount}/>
                </div>
            }

            {
                selectedType == "task" && <div style={{textAlign: "left", margin: "20px 4.5%",
                    color: "white"}}>
                    <p>Введите комментарий для апов</p>
                    <input
                        style={{
                            textAlign: "left",
                            width: "900px",
                            marginTop: "10px",
                            padding: "5px",
                            backgroundColor: "#232531", color: "white"
                        }}
                        type="text" value={upDescription} onChange={handleChangeUpDescription}/>
                </div>
            }

            {
                selectedType == "default" ?
                    <div style={{textAlign: "left", margin: "20px 4.5%", color: "white", fontSize: "13px"}}>
                        <div>Минимальное количество символов: 20 (текущее кол-во символов: {message.length})</div>
                        <div>Максимальное количество символов: 1920 (текущее кол-во символов: {message.length})</div>
                        <div>Максимальный размер вложения: 7 МБ</div>
                        <div>Если требуется помощь по разметке: <a
                            href={"https://discord.fandom.com/ru/wiki/%D0%A4%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D1%82%D0%B5%D0%BA%D1%81%D1%82%D0%B0"}
                            target="_blank" style={{color: "#2f84da"}}>Кликните сюда</a></div>
                        {message.length < 20 ?
                            <div style={{color: "#e86455"}}>Недостаточно символов, сервер вернёт ошибку!</div> : null}
                        {message.length > 1919 ?
                            <div style={{color: "#e86455"}}>Слишком много символов, сервер вернёт ошибку!</div> : null}
                        {selectedPlayers.length <= 0 ?
                            <div style={{color: "#e86455"}}>Вы не выбрали игроков, сервер вернёт ошибку!</div> : null}
                        {file && file.size >= 7340032 ?
                            <div style={{color: "#e86455"}}>Размер вложения превышает 7 МБ, сервер вернёт ошибку!</div> : null}
                    </div>
                    :
                    <div style={{textAlign: "left", margin: "20px 4.5%", color: "white", fontSize: "13px"}}>
                        <div>Если требуется помощь по разметке: <a
                            href={"https://discord.fandom.com/ru/wiki/%D0%A4%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D1%82%D0%B5%D0%BA%D1%81%D1%82%D0%B0"}
                            target="_blank" style={{color: "#2f84da"}}>Кликните сюда</a></div>
                        {selectedPlayers.length <= 0 ?
                            <div style={{color: "#e86455"}}>Вы не выбрали игроков, сервер вернёт ошибку!</div> : null}
                    </div>
            }


            {
                selectedType == "default" &&
                <div style={{textAlign: "left", margin: "20px 4.5%"}}>
                    <div><input multiple={false} onChange={uploadFileHandler} type={"file"}
                                style={{color: "white"}}/></div>
                </div>
            }

            {
                selectedPlayers[0] && message ?
                    selectedType == "default" ?
                        <div style={{margin: "30px 20%"}}>
                            <div className={"default-button green-back transition cursor-pointer"}
                                 onClick={handleSendMessage}>
                                Отправить
                            </div>
                        </div>
                        :
                        <div style={{margin: "30px 20%"}}>
                            <div className={"default-button green-back transition cursor-pointer"}
                                 onClick={handleSendPlayersTask}>
                                Отправить
                            </div>
                        </div>
                    : null
            }

            <div style={{
                margin: "0 3% 0 1.3%",
                display: "grid",
                gridTemplateColumns: "2fr 2fr 2fr 1fr 1fr",
                padding: "50px 50px 10px 50px",
                color: "white"
            }}>
                <div style={{marginTop: "-10px"}}>
                    <div style={{marginLeft: "17px", textAlign: "left"}}>
                        Группа
                    </div>
                    <div>
                        <GroupSelector options={props.TrainingInfo.discord_sender_groups}
                                       handleSelectGroup={handleSelectGroup}/>
                    </div>
                </div>
                <div style={{marginTop: "-10px"}}>
                    <div style={{marginLeft: "17px", textAlign: "left"}}>
                        Направление
                    </div>
                    <div>
                        <DirectionSelector options={props.TrainingInfo.discord_sender_directions}
                                           handleSelectDirection={handleSelectDirection}/>
                    </div>
                </div>
                <div style={{marginTop: "-10px"}}>
                    <div style={{marginLeft: "17px", textAlign: "left"}}>
                        Игроки
                    </div>
                    <div>
                        <PlayerSelector options={props.TrainingInfo.discord_sender_current_players_list}
                                        selectedPlayers={selectedPlayers}
                                        handleSelectPlayer={handleSelectPlayer}/>
                    </div>
                </div>
                <div className={"default-button cursor-pointer default-back"} onClick={handleSelectAll}
                     style={{height: "35px", width: "90%", marginLeft: "20px"}}
                >
                    Выбрать всех
                </div>

                <div className={"default-button cursor-pointer default-back"} onClick={handleRemoveAll}
                     style={{height: "35px", width: "90%", marginLeft: "20px"}}
                >
                    Убрать всех
                </div>
            </div>
        </div>
    );
};

export default CoachDiscordSender;