import React from 'react';
import Container from "../container";
import s from './styles.module.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import ImgField from '../../../common/fields/imgField';
import ColorText from '../../../common/fields/colorText';
import NumberField from '../../../common/fields/numberField';
import NumberCeilField from "../../../common/fields/numberCeilField";
import NumberToFixed1Field from "../../../common/fields/numberToFixed1Field";
import TimestampToDateField from "../../../common/fields/timestampToDateField";
import TextField from "../../../common/fields/textField";
import RakeStakeField from "../../../common/fields/rakeStakeField";
import ShortTimestampToDateField from "../../../common/fields/timestampShortToDateField";

const FourthContent = (props) => {
    return (
        props.AuthInfo.verificator && props.AuthInfo.verificator.direction && (props.AuthInfo.verificator.direction == "CASH" || props.AuthInfo.verificator.direction == "ASIA") ?
        <Container top='35px'>
            <div className='ag-theme-alpine-dark' style={{ height: 620, width: '100%', position: 'relative' }}>
                <AgGridReact
                    style={{ height: 620, width: '100%' }}
                    rowData={props.MainInfo.mainPageData.rows}
                    enableBrowserTooltips={true}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowHeight={70}

                    // enableRangeSelection={true}
                    // rowSelection={'multiple'}
                    // suppressCopyRowsToClipboard={true}
                    // enableCellTextSelection={true}

                    defaultColDef={{
                        editable: true,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        filter: false,
                        floatingFilter: false,
                        resizable: true,
                        menuTabs: false,
                    }}
                    frameworkComponents={{
                        imgField: ImgField,
                        colorText: ColorText,
                        numberField: NumberField,
                        numberCeilField: NumberCeilField,
                        numberToFixed1Field: NumberToFixed1Field,
                        timestampToDateField: TimestampToDateField,
                        textField: TextField
                    }}
                >
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Союз'}
                        field={'club_union'}
                        editable={false}
                        sortable={false}
                        filter={false}
                        cellRenderer='colorText'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Аккаунт'}
                        field='account_id'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Дата'}
                        field='date_timestamp'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='timestampToDateField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Лимит'}
                        field='limit'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberCeilField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Руки'}
                        field='hands'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberCeilField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Профит'}
                        field='profit'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberToFixed1Field'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'Профит USD'}
                        field={"profit_usd"}
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberToFixed1Field'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={'RB USD'}
                        field='rakeback_usd'
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberToFixed1Field'
                    />
                </AgGridReact>
            </div>
        </Container> :
            props.AuthInfo.verificator && props.AuthInfo.verificator.direction && props.AuthInfo.verificator.direction == "MTT" ?
                <Container top='35px'>
                    <div className='ag-theme-alpine-dark' style={{ height: 620, width: '100%', position: 'relative' }}>
                        <AgGridReact
                            style={{ height: 620, width: '100%' }}
                            rowData={props.MainInfo.mainPageData.rows}
                            enableBrowserTooltips={true}
                            tooltipShowDelay={0}
                            tooltipHideDelay={2000}
                            rowHeight={70}

                            // enableRangeSelection={true}
                            // rowSelection={'multiple'}
                            // suppressCopyRowsToClipboard={true}
                            // enableCellTextSelection={true}

                            defaultColDef={{
                                editable: true,
                                sortable: true,
                                flex: 1,
                                minWidth: 100,
                                filter: false,
                                floatingFilter: false,
                                resizable: true,
                                menuTabs: false,
                            }}
                            frameworkComponents={{
                                imgField: ImgField,
                                colorText: ColorText,
                                numberField: NumberField,
                                numberCeilField: NumberCeilField,
                                numberToFixed1Field: NumberToFixed1Field,
                                timestampToDateField: TimestampToDateField,
                                textField: TextField,
                                rakeStakeField: RakeStakeField,
                                shortTimestampToDateField: ShortTimestampToDateField
                            }}
                        >
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Network'}
                                field={'network'}
                                editable={false}
                                sortable={false}
                                filter={false}
                                cellRenderer='colorText'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Date'}
                                field='date'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='shortTimestampToDateField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'BuyIn'}
                                field='buyin'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Ребаи'}
                                field='multientries'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberField'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Profit'}
                                field='profit'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='numberField'
                            />
                        </AgGridReact>
                    </div>
                </Container>
                :null
    );
};

export default FourthContent;