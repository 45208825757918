import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import Leakfinder from "./leakfinderSpin";
import {
    LeakfinderGetSaves,
    LeakfinderGetSchemas,
    LeakfinderSetCurrentSave,
    LeakfinderSpliceSave
} from "../../../redux/reducers/leakfinderSpinReducer";

class LeakfinderSpinContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.LeakfinderGetSchemas()

        if (this.props?.AuthInfo?.verificator?.room_names && this.props.AuthInfo.verificator.room_names[0]) {
            this.props.LeakfinderGetSaves(this.props.AuthInfo.verificator.room_names.map(e => ({name: e})))
        }
    }

    componentWillUnmount() {
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <Leakfinder {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    LeakfinderInfo: state.leakfinderSpinReducer
})


export default compose(
    connect(mapStateToProps, {
        LeakfinderSetCurrentSave,
        LeakfinderGetSaves,
        LeakfinderGetSchemas,
        LeakfinderSpliceSave,
    }),
    withRouter
)(LeakfinderSpinContainer)