import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import AuditList from "./AuditList";
import {AuditorSortTasks, SupportGetTasksList} from "../../../../redux/reducers/auditorReducer";
import sortImg from "../../../images/sort.png";

class AuditListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Список игроков")
        this.props.SupportGetTasksList(`${this.props.AuditorInfo.supportTasksListMonth}-${this.props.AuditorInfo.supportTasksListYear}`, this.props.AuditorInfo.adminTasksListSet)
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        return (
            <AuditList {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    AuditorInfo: state.auditorReducer
})


export default compose(
    connect(mapStateToProps, {
        SupportGetTasksList, AuditorSortTasks
    }),
    withRouter
)(AuditListContainer)