import React from 'react';
import s from './styles.module.css';
import {useDispatch} from "react-redux";
import {SalaryRemoveRow, SalaryRemoveUser} from "../../../redux/reducers/salaryReducer";

const SalaryDeleteButton = ({data}) => {
        const dispatch = useDispatch();

        return !data.author ?
            <button className={s.number}
                    onClick={() => dispatch(SalaryRemoveUser(data._id))} style={{
                cursor: "pointer",
                backgroundColor: "rgba(255,255,255,0)",
                color: "rgb(178,18,18)",
                border: "none"
            }}>❌ Удалить</button>
            :
            <button className={s.number}
                    onClick={() => dispatch(SalaryRemoveRow(data._id))} style={{
                cursor: "pointer",
                backgroundColor: "rgba(255,255,255,0)",
                color: "rgb(178,18,18)",
                border: "none"
            }}>❌</button>
    }


export default SalaryDeleteButton;