import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {ReactComponent as HowPay} from "../../../assests/how_pay.svg";
import {ReactComponent as Support} from "../../../assests/support.svg";
import Item from "./item";
import CopyBox from "./copy_box";
import SendQuestionToSupportContainer from "../../../common/popups/sendQuestionToSupportContainer";

const headerItems = [
    {title: 'Как происходят выплаты', svg: <HowPay/>, link: 'ref_program'},
    {title: 'Обратиться в Саппорт', svg: <Support/>, link: 'question_popup'},
]
const HeaderProgram = ({openPopUp, headerData, GET_MONEY_POPUP, REF_PROGRAM_POPUP, code}) => {
    return (
        <div>

            <div className={s.logo_title_box}>
                <div className={s.top_left}>
                    <h2>Реферальная программа</h2>
                    <p>Используй свой личный реферальный промокод,
                        чтобы получить до 1000$ за каждого
                        приведенного игрока.</p>
                </div>

                <div className={s.top_right}>
                    {headerItems.map((el, index) => {
                        if (el.link === REF_PROGRAM_POPUP) {
                            return <div className={classNames(s.header_item)}
                                        onClick={() => openPopUp(REF_PROGRAM_POPUP)}>
                                {el.svg}
                                <p>{el.title}</p>
                            </div>
                        }
                        if (el.link === "question_popup") {
                            return <SendQuestionToSupportContainer title={el.title} svg={el.svg} popupClassName={classNames(s.header_item)}/>
                        }
                        return <div className={classNames(s.header_item)}>
                            {el.svg}
                            <p>{el.title}</p>
                        </div>

                    })}
                </div>
            </div>

            <div className={s.items_box}>
                {headerData.map((el, i) => <Item onClick={() => el.cursor && openPopUp(GET_MONEY_POPUP)}
                                                 subtitle={el.subTitle && el.subTitle} background={el.background}
                                                 title={el.title && `$ ${el.title}`} titleColor={el.titleColor}
                                                 cursor={el.cursor}
                />)}

            </div>

            <div className={s.ref_link_box}>
                <div className={s.copy_box_1}>
                    <CopyBox title={'Ваша реферальная ссылка:'} value={`https://team-firestorm.ru/?roistat=ref${code}`}/>
                </div>

                <div className={s.copy_box_2}>
                    <CopyBox title={'Код:'} value={code}/>
                </div>
            </div>
        </div>
    );
};

export default HeaderProgram;
