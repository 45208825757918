import React, {useState, useRef} from "react";

export default function FinResults(props) {

    const [clubNames, setClubNames] = useState(null);
    const [accountIds, setAccountIds] = useState(null);
    const [profitChips, setProfitChips] = useState(null);
    const [rakeChips, setRakeChips] = useState(null);
    const [taxes, setTaxes] = useState(null);
    const [fromDates, setFromDates] = useState(null);
    const [toDates, setToDates] = useState(null);

    const [isGenerated, setIsGenerate] = useState(false);

    const handleGenerate = () => {
        if (!clubNames || !accountIds || !profitChips || !rakeChips || !taxes || !toDates || !fromDates) return;
        let c = clubNames.split("\n").map(el => el.replace(/^\s+|\s+$/g, ''));
        let a = accountIds.split("\n").map(el => el.replace(/\s/g, ''));
        let p = profitChips.split("\n").map(el => el.replace(/\s/g, ''));
        let r = rakeChips.split("\n").map(el => el.replace(/\s/g, ''));
        let t = taxes.split("\n").map(el => el.replace(/\s/g, ''));
        let fd = fromDates.split("\n").map(el => el.replace(/\s/g, ''));
        let td = toDates.split("\n").map(el => el.replace(/\s/g, ''));

        let highestColumn = c.length;
        if (a.length >= highestColumn) highestColumn = a.length;
        if (p.length >= highestColumn) highestColumn = p.length;
        if (r.length >= highestColumn) highestColumn = r.length;
        if (t.length >= highestColumn) highestColumn = t.length;
        if (fd.length >= highestColumn) highestColumn = fd.length;
        if (td.length >= highestColumn) highestColumn = td.length;
        if (highestColumn > c.length || highestColumn > a.length || highestColumn > p.length ||
            highestColumn > r.length || highestColumn > t.length || highestColumn > fd.length
            || highestColumn > td.length) return;

        setClubNames(c);
        setAccountIds(a);
        setProfitChips(p);
        setRakeChips(r);
        setTaxes(t);
        setFromDates(fd);
        setToDates(td);

        setIsGenerate(true);
    }

    const handleChangeInput = (type, index, value) => {
        const spliceRow = () => {
            clubNames.splice(index, 1);
            accountIds.splice(index, 1);
            profitChips.splice(index, 1);
            rakeChips.splice(index, 1);
            taxes.splice(index, 1);
            fromDates.splice(index, 1);
            toDates.splice(index, 1);
            setClubNames(JSON.parse(JSON.stringify(clubNames)));
            setAccountIds(JSON.parse(JSON.stringify(accountIds)));
            setProfitChips(JSON.parse(JSON.stringify(profitChips)));
            setRakeChips(JSON.parse(JSON.stringify(rakeChips)));
            setTaxes(JSON.parse(JSON.stringify(taxes)));
            setFromDates(JSON.parse(JSON.stringify(fromDates)));
            setToDates(JSON.parse(JSON.stringify(toDates)));
        }

        if (type == "clubNames" && !value && !accountIds[index] && !profitChips[index]
            && !rakeChips[index] && !taxes[index]) return spliceRow();
        if (type == "accountIds" && !value && !clubNames[index] && !profitChips[index]
            && !rakeChips[index] && !taxes[index]) return spliceRow();
        if (type == "profitChips" && !value && !accountIds[index] && !clubNames[index]
            && !rakeChips[index] && !taxes[index]) return spliceRow();
        if (type == "rakeChips" && !value && !accountIds[index] && !profitChips[index]
            && !clubNames[index] && !taxes[index]) return spliceRow();
        if (type == "taxes" && !value && !accountIds[index] && !profitChips[index]
            && !rakeChips[index] && !clubNames[index]) return spliceRow();


        if (type == "clubNames") clubNames[index] = value;
        if (type == "accountIds") accountIds[index] = value;
        if (type == "profitChips") profitChips[index] = value;
        if (type == "rakeChips") rakeChips[index] = value;
        if (type == "taxes") taxes[index] = value;
        if (type == "fromDates") fromDates[index] = value;
        if (type == "toDates") toDates[index] = value;
    }
    const handleSendData = () => {
        let data = [];

        clubNames.forEach(e => data.push({ club_name: e }))
        accountIds.forEach((e, i) => data[i].account_id = e)
        profitChips.forEach((e, i) => data[i].profit_chip = e)
        rakeChips.forEach((e, i) => data[i].rake_chip = e)
        taxes.forEach((e, i) => data[i].tax = e)
        fromDates.forEach((e, i) => data[i].from_date_timestamp = e)
        toDates.forEach((e, i) => data[i].to_date_timestamp = e)

        props.PushResultsData(data)
    }

    return (
        <>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 160px", color: "white", marginBottom: "20px"}}>
                <div>
                    Club_name
                </div>
                <div>
                    Account_Id
                </div>
                <div>
                    Profit_Chip
                </div>
                <div>
                    Rake_Chip
                </div>
                <div>
                    Tax
                </div>
                <div>
                    From_Date
                </div>
                <div>
                    To_Date
                </div>
                <></>
            </div>

            {
                isGenerated ?
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 160px"}}>
                        <div>
                            {
                                clubNames.map((e, i) => {
                                    return <div style={{marginBottom: "10px", marginRight: "10px"}}
                                                key={`${e} ${i} c`}>
                                        <input onBlur={(e) => handleChangeInput("clubNames", i, e.target.value)}
                                               style={{width: "100%", backgroundColor: "lightyellow", border: "none", textAlign: "center"}}
                                               defaultValue={e}/>
                                    </div>
                                })
                            }
                        </div>
                        <div>
                            {
                                accountIds.map((e, i) => {
                                    return <div style={{marginBottom: "10px", marginRight: "10px"}}
                                                key={`${e} ${i} a`}>
                                        <input onBlur={(e) => handleChangeInput("accountIds", i, e.target.value)}
                                               style={{width: "100%", backgroundColor: "lightyellow", border: "none", textAlign: "center"}}
                                               defaultValue={e}/>
                                    </div>
                                })
                            }
                        </div>
                        <div>
                            {
                                profitChips.map((e, i) => {
                                    return <div style={{marginBottom: "10px", marginRight: "10px"}}
                                                key={`${e} ${i} p`}>
                                        <input onBlur={(e) => handleChangeInput("profitChips", i, e.target.value)}
                                               style={{width: "100%", backgroundColor: "lightyellow", border: "none", textAlign: "center"}}
                                               defaultValue={e}/>
                                    </div>
                                })
                            }
                        </div>
                        <div>
                            {
                                rakeChips.map((e, i) => {
                                    return <div style={{marginBottom: "10px", marginRight: "10px"}}
                                                key={`${e} ${i} r`}>
                                        <input onBlur={(e) => handleChangeInput("rakeChips", i, e.target.value)}
                                               style={{width: "100%", backgroundColor: "lightyellow", border: "none", textAlign: "center"}}
                                               defaultValue={e}/>
                                    </div>
                                })
                            }
                        </div>
                        <div>
                            {
                                taxes.map((e, i) => {
                                    return <div style={{marginBottom: "10px", marginRight: "10px"}}
                                                key={`${e} ${i} t`}>
                                        <input onBlur={(e) => handleChangeInput("taxes", i, e.target.value)}
                                               style={{width: "100%", backgroundColor: "lightyellow", border: "none", textAlign: "center"}}
                                               defaultValue={e}/>
                                    </div>
                                })
                            }
                        </div>


                        <div>
                            {
                                fromDates.map((e, i) => {
                                    return <div style={{marginBottom: "10px", marginRight: "10px"}}
                                                key={`${e} ${i} fd`}>
                                        <input onBlur={(e) => handleChangeInput("fromDates", i, e.target.value)}
                                               style={{width: "100%", backgroundColor: "lightyellow", border: "none", textAlign: "center"}}
                                               defaultValue={e}/>
                                    </div>
                                })
                            }
                        </div>
                        <div>
                            {
                                toDates.map((e, i) => {
                                    return <div style={{marginBottom: "10px", marginRight: "10px"}}
                                                key={`${e} ${i} td`}>
                                        <input onBlur={(e) => handleChangeInput("toDates", i, e.target.value)}
                                               style={{width: "100%", backgroundColor: "lightyellow", border: "none", textAlign: "center"}}
                                               defaultValue={e}/>
                                    </div>
                                })
                            }
                        </div>

                        {
                            !props.isLoading && <button style={{height: "50px"}} onClick={handleSendData}>Сохранить в базу</button>
                        }
                    </div>
                    :
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 160px"}}>
                        <textarea style={{backgroundColor: "whitesmoke", textAlign: "center"}} onChange={(e) => setClubNames(e.target.value)}
                                  type="text" rows={10}/>
                        <textarea style={{backgroundColor: "whitesmoke", textAlign: "center"}} onChange={(e) => setAccountIds(e.target.value)}
                                  type="text"/>
                        <textarea style={{backgroundColor: "whitesmoke", textAlign: "center"}} onChange={(e) => setProfitChips(e.target.value)}
                                  type="text"/>
                        <textarea style={{backgroundColor: "whitesmoke", textAlign: "center"}} onChange={(e) => setRakeChips(e.target.value)}
                                  type="text"/>
                        <textarea style={{backgroundColor: "whitesmoke", textAlign: "center"}} onChange={(e) => setTaxes(e.target.value)}
                                  type="text"/>

                        <textarea style={{backgroundColor: "whitesmoke", textAlign: "center"}} onChange={(e) => setFromDates(e.target.value)}
                                  type="text"/>
                        <textarea style={{backgroundColor: "whitesmoke", textAlign: "center"}} onChange={(e) => setToDates(e.target.value)}
                                  type="text"/>
                        {
                            !props.isLoading && <button style={{height: "50px"}} onClick={handleGenerate}>Предсоздать</button>
                        }

                    </div>
            }
        </>
    );
}
