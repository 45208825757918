import React from 'react';
import classNames from "classnames";
import s from "./styles.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {NavLink} from "react-router-dom";
import {ShowToolTip} from "../index";

const ThirdAccordion = ({
                            item,
                            title,
                            isAccordion,
                            svgComponent,
                            path,
                            setSelected,
                            selected,
                            hiddenLeft,
                            setSelectThird,
                            setSelectFirst,
                            setSelectSecond
                        }) => {

    return (
        <NavLink to={path}>
            <ShowToolTip hiddenLeft={hiddenLeft} title={title}>
                <div className={classNames(s.item, selected === path && s.active, hiddenLeft && s.hiddenLeft)}
                     onClick={(e) => {
                         setSelected(path)
                         setSelectThird(item?.selectSecondParent ? item?.selectSecondParent : null)
                         setSelectFirst(item?.selectSecondParent ? item?.selectSecondParent : null)
                         setSelectSecond(item?.selectSecondParent ? item?.selectSecondParent : null)
                     }}>
                    {svgComponent}

                    {!hiddenLeft && <p>{title}</p>}
                </div>
            </ShowToolTip>
        </NavLink>
    );
};

export default ThirdAccordion;