
import React, {useContext, useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as ArrowLeft} from "../../../assests/arrowLeft.svg";
import {ReactComponent as ArrowRight} from "../../../assests/arrowRight.svg";
import CommentBox from "./item";
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import {useWindowSize} from "../../../hooks/useWindowSize";

const LastCommentaries = (props) => {
    const [swiperRef, setSwiperRef] = useState(null);

    const {width, height} = useWindowSize()

    const showSlide1200 =
        ((width > 1000 && width <= 1200) && 2.3) ||
        ((width > 320 && width <= 1000) && 1.3) ||
        (width > 1200 && 3)
    const showbetween = (width <= 524) ? 50 : 20
    const showbetweenScroll = width <= 524

    return (
        <div className={s.comment}>
            <div className={s.comment_header}>
                <h3 className={s.title}>Последние переводы</h3>
                <div className={s.arrows}>
                    <ArrowLeft className={s.arrow_left} onClick={() => swiperRef.slidePrev()}/>
                    <ArrowRight className={s.arrow_right}
                                onClick={() => swiperRef.slideNext()}
                    />
                </div>
            </div>

            <div className={s.comment_box}>
                <Swiper
                    onSwiper={setSwiperRef}
                    spaceBetween={showbetween}
                    slidesPerView={showSlide1200}
                    noSwiping={!showbetweenScroll}
                    noSwipingClass={'swiper-slide'}
                >
                    {props.FsChangerInfo.userFeedbacks.map((el, index) => <SwiperSlide key={el.timestamp_creation} virtualIndex={index}>
                        <CommentBox key={index} {...el} />
                    </SwiperSlide>)}
                </Swiper>
            </div>
        </div>
    );
};

export default LastCommentaries;