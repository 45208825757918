import './App.css';
import {BrowserRouter, useHistory} from "react-router-dom";
import LeftSideBar from "./components/left_side_bar";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "./hooks/useWindowSize";
import MobileHeader from "./components/mobile_header";
import MenuMobileContainer from "./components/menu_mobile_container/menuMobileContainerContainer";
import classNames from "classnames";
import RightSideBarContainer from "./components/right_side_bar/rightSidebarContainer";
import RoutersContainer from "./routers/routersContainer";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import CloseImg from "../v_1/images/close.svg";

function App(props) {
    const navigate = useHistory()

    const {width, height} = useWindowSize()

    const [showRightMenu, setShowRightMenu] = useState(false)

    const [hiddenLeft, setHiddenLeft] = useState(false)
    const [hiddenRight, setHiddenRight] = useState(false)

    const [selected, setSelected] = useState('/main')

    const [type, setType] = useState(null)

    const [selectFirst, setSelectFirst] = useState(null)
    const [selectSecond, setSelectSecond] = useState(null)
    const [selectThird, setSelectThird] = useState(null)

    const mobilePoint = width > 767
    const DeleteNotification = (e) => {
        props.DeleteNotification(e.target.accessKey)
    }
    useEffect(() => {
        const body = document.querySelector('.routes_content')
        const body2 = document.querySelector('body')
        if (showRightMenu) {
            body.style.display = 'none'
            body2.style.background = '#1E1D2B'
        } else {
            body.style.display = 'flex'
            body2.style.background = '#1E1D2B'
        }

    }, [showRightMenu])

    useEffect(() => {
        if (width <= 1200) {
            setHiddenRight(true)
            setHiddenLeft(true)
        }

        if (width <= 1600) {
            setHiddenRight(true)
        }
    }, [width])

    useEffect(() => {
        if (!mobilePoint) setHiddenLeft(false)
    }, [width])

    return (
        <>
            <div style={{
                position: "fixed",
                top: "0",
                right: "0",
                width: "330px",
                maxWidth: "100%",
                zIndex: "1000",
                paddingTop: "0px",
                backgroundColor: "#1e1d2b"
            }}>
                {props.NotificationsInfo.notificationsList.map((notification, i) => {
                    let nType = '';
                    if (notification.type == "error") nType = "warning-block"
                    if (notification.type == "success") nType = "successnew-block"
                    if (notification.type == "warning") nType = "warns-block"

                    return (
                        <div key={notification.title + " " + i} className={"notification" + " " + nType}
                        style={{marginTop: "10px"}}>
                            <div className="notification-head">
                                <div className="notification-head__title">{notification.title}</div>
                                <div className="notification-close">
                                    <img accessKey={i} onClick={DeleteNotification} src={CloseImg}
                                         alt="close"/>
                                </div>
                            </div>
                            <p>{notification.description}</p>
                        </div>
                    )
                })}
            </div>

            <MenuMobileContainer type={type} setSelected={setSelected} selected={selected}
                                 showRightMenu={showRightMenu}
                                 onClose={() => {
                                     setShowRightMenu(false)
                                     setType(null)
                                 }}
                                 setSelectThird={setSelectThird}
                                 selectThird={selectThird}
                                 setSelectFirst={setSelectFirst}
                                 selectFirst={selectFirst}
                                 setSelectSecond={setSelectSecond}
                                 selectSecond={selectSecond}
                                 setHiddenLeft={setHiddenLeft} hiddenLeft={hiddenLeft}
                                 setHiddenRight={setHiddenRight}
                                 hiddenRight={hiddenRight}/>


            <div className="App">
                {mobilePoint &&
                    <LeftSideBar
                        setSelectThird={setSelectThird}
                        selectThird={selectThird}
                        setSelectFirst={setSelectFirst}
                        selectFirst={selectFirst}
                        setSelectSecond={setSelectSecond}
                        selectSecond={selectSecond}
                        showRightMenu={showRightMenu}
                        selected={selected} setSelected={setSelected}
                        setHiddenLeft={setHiddenLeft} hiddenLeft={hiddenLeft}
                        setHiddenRight={setHiddenRight}
                        hiddenRight={hiddenRight}
                        AuthInfo={props.AuthInfo}
                    />
                }
                {!mobilePoint && <MobileHeader
                    setType={setType}
                    setShowRightMenu={() => setShowRightMenu(true)}/>}

                <div
                    className={classNames('routes_content', (!hiddenLeft || !hiddenRight) ? 'changeWidth' : 'changeWidthSecond', (!hiddenLeft && !hiddenRight) && 'changeFullWidth')}>
                    <div className='routes_content_item'>
                        <RoutersContainer/>
                    </div>
                </div>

                {mobilePoint &&
                    <RightSideBarContainer hiddenLeft={hiddenLeft} setHiddenRight={setHiddenRight} hiddenRight={hiddenRight}
                                  setHiddenLeft={setHiddenLeft}/>
                }

            </div>

            <NotificationContainer/>
        </>
    );
}

export default App;
