import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import {CoachSetChangerPersonalSlotRequestStep, UserSendPersonalRequest} from "../../../../redux/reducers/trainingReducer";
import MainLoading from "../../common/mainLoading";

export default function Request(props) {
    return (
        <div className={"user-personal-trainings-requests"}>


            <div className={"requests"}>
                <div className={"name"}>
                    Для отправки запроса на тренировку выберите тренера
                </div>
                {
                    props.TrainingInfo.userCoaches[0] ?
                        <div className={"coaches"}>
                            {props.TrainingInfo.userCoaches.map(coach => {
                                return <CoachRow key={`coaches-info ${coach.coach_name} ${coach.direction}`}
                                                 CoachSetChangerPersonalSlotRequestStep={props.CoachSetChangerPersonalSlotRequestStep}
                                                 isLoading={props.isLoading}
                                                 trainingList={props.TrainingInfo.trainingList}
                                                 step={props.TrainingInfo.coachStep}
                                                 coach={coach} UserSendPersonalRequest={props.UserSendPersonalRequest}/>
                            })}
                        </div>
                        :
                        <div className={"info"}>
                            Свободные тренеры для записи не найдены.<br/>
                        </div>
                }
            </div>


            <div className={"requests"}>
                <div className={"name"}>
                    Ваши запросы
                </div>
                {
                    props.TrainingInfo.userRequests[0] ?
                        props.TrainingInfo.userRequests.map(request => {
                            let date = request.date.split("-")
                            return <Popup key={`request-info ${request.timestamp}`}
                                          trigger={
                                              <div className={"request-row"}>
                                                  <div style={{fontWeight: "bolder"}}>
                                                      {`${date[0]}.${date[1]}.${date[2]}`}
                                                  </div>
                                                  <div>
                                                      {request.coachName}
                                                  </div>
                                                  <div>
                                                      <span style={{
                                                          fontWeight: "bolder",
                                                          fontSize: "13px",
                                                          color: `${request.status_level == 1 ? "orange" : request.status_level == 2 ? "greenyellow" : request.status_level == 3 ? "tomato" : request.status_level == 5 ? "yellow" : "tomato"}`
                                                      }}>
                                                          {request.status}
                                                      </span>
                                                  </div>
                                                  <div style={{fontWeight: "bolder"}}>
                                                      Нажмите для просмотра деталей
                                                  </div>
                                              </div>
                                          }
                                          modal
                                          nested
                            >
                                {() => (
                                    <div>
                                        <div id="get-advice" className="main-popup">
                                            <form className="form">
                                                <div className="formgroup">
                                                <span style={{color: "white"}}>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Дата: </span>{`${date[0]}.${date[1]}.${date[2]}`}
                                                    <br/>
                                                    <br/>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Тренер: </span>{request.coachName}
                                                    <br/>
                                                    <br/>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Статус: </span> {request.status}
                                                    <br/><br/>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Тема: </span> {request.user_topic}
                                                    <br/><br/>
                                                    <span style={{
                                                        fontWeight: "bolder",
                                                        color: "#405c91"
                                                    }}>Описание: </span> {request.user_description}
                                                    <br/><br/>
                                                    {
                                                        request.status_level == 4 && <div>
                                                               <span
                                                                   style={{
                                                                       fontWeight: "bolder",
                                                                       color: "#405c91"
                                                                   }}>Причина отмены запроса: </span> {request.reason ? request.reason : "Не указано"}
                                                        </div>
                                                    }
                                                </span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        })
                        :
                        <div className={"info"}>
                            Активные запросы не найдены.
                        </div>
                }
            </div>
        </div>
    )
}
const CoachRow = (props) => {
    const MONTHS = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
    const DATE = new Date();
    const DAY = DATE.getDate();
    const MONTH = DATE.getMonth();
    const YEAR = DATE.getFullYear();

    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(MONTH);
    const [selectedYear, setSelectedYear] = useState(YEAR);

    const [selectedDayTimestamp, setSelectedDayTimestamp] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedTimestamp, setSelectedTimestamp] = useState(null);
    const [topic, setTopic] = useState(null);
    const [description, setDescription] = useState(null);

    const [isSend, setIsSend] = useState("11");

    const handleSelectDay = (day, timestamp) => {
        props.CoachSetChangerPersonalSlotRequestStep(1, [])
        setTopic(null);
        setDescription(null);
        setSelectedTime(null);
        setSelectedTimestamp(null);
        setSelectedDay(day);
        setSelectedDayTimestamp(timestamp);
    }
    const handleSelectTime = (time, timestamp) => {
        props.CoachSetChangerPersonalSlotRequestStep(1, [])
        setTopic(null);
        setDescription(null);
        setSelectedTime(time);
        setSelectedTimestamp(timestamp);
    }
    const handleChangeTopic = (ttopic) => {
        props.CoachSetChangerPersonalSlotRequestStep(1, [])
        setTopic(ttopic)
    }
    const handleChangeDescription = (ddescription) => {
        props.CoachSetChangerPersonalSlotRequestStep(1, [])
        setDescription(ddescription)
    }
    const handleChangeIsSend = () => {
        // setIsSend("22")
        setTopic(null);
        setDescription(null);
        setSelectedTime(null);
        setSelectedTimestamp(null);

        setSelectedDay(null);
        setSelectedDayTimestamp(null);
        props.UserSendPersonalRequest(props.coach.coach_id, selectedDayTimestamp, selectedTimestamp, topic, description, props.coach.direction)
    }

    const handleChangeMonth = (action) => {
        if (action == "prev") {
            if (+selectedMonth == 0) {
                setSelectedYear(+selectedYear - 1)
                setSelectedMonth(11)

            } else {
                setSelectedMonth(+selectedMonth - 1)
            }
        }
        if (action == "next") {
            if (+selectedMonth == 11) {
                setSelectedYear(+selectedYear + 1)
                setSelectedMonth(0)
            } else {
                setSelectedMonth(+selectedMonth + 1)
            }
        }

        setSelectedDay(null);
        setTopic(null);
        setDescription(null);
        setSelectedTime(null);
        setSelectedTimestamp(null);
    }

    return (
        <Popup key={`coaches-info-popup ${props.coach.coach_name} ${props.coach.direction} ${isSend}`}
               trigger={
                   <div className={"coach"}>
                       {props.coach.coach_name} ({props.coach.direction})
                   </div>
               }
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="main-popup user-request-personal-training">
                        <form className="form">
                            <div className={"calendar"}>
                                <div className={"name"}>
                                    <span onClick={() => handleChangeMonth("prev")} style={{cursor: "pointer"}}>◀️</span>
                                    <span onClick={() => handleChangeMonth("next")} style={{marginLeft: "5px", marginRight: "10px", cursor: "pointer"}}>▶️</span>
                                    {MONTHS[selectedMonth]} {selectedYear} г.

                                </div>
                                <div className={"content"}>
                                    {
                                        props.coach.coach_days.map((day, i) => {
                                            if (day.month != `${selectedMonth + 1}-${selectedYear}`) return null
                                            return <div className={`item ${selectedDay == day.day ? "active" : null}`}
                                                        onClick={() => handleSelectDay(day.day, day.timestamp_day)}
                                                        key={`coach-calc ${day.day} ${i}`}>
                                                <span>{day.day}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            {
                                selectedDay && <div className={"calendar"} style={{marginTop: "15px"}}>
                                    <div className={"name"}>
                                        Выберите время
                                    </div>
                                    <div className={"content"}>
                                        {
                                            props.coach.coach_days.map((day, i) => {
                                                if (day.day == selectedDay) {
                                                    return day.times.map(time => {
                                                        return <div
                                                            className={`item ${selectedTimestamp == time.timestamp ? "active" : null}`}
                                                            onClick={() => handleSelectTime(time.time, time.timestamp)}
                                                            key={`coach-calcl ${props.coach.coach_name} ${props.coach.direction} ${time.time} ${i}`}>
                                                            <span>{time.time}</span>
                                                        </div>
                                                    })
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }


                            {
                                selectedDay && selectedTime && <div className={"calendar"} style={{marginTop: "15px"}}>
                                    <div className={"name"}>
                                        Введите кратко название темы к обучению
                                    </div>
                                    <div className={"content"}>
                                        <input onChange={(e) => handleChangeTopic(e.target.value)}/>
                                    </div>
                                </div>
                            }


                            {
                                selectedDay && selectedTime && <div className={"calendar"} style={{marginTop: "25px"}}>
                                    <div className={"name"}>
                                        Опишите подробно, что хотите изучить
                                    </div>
                                    <div className={"content"}>
                                        <textarea rows={10} onChange={(e) => handleChangeDescription(e.target.value)}/>
                                    </div>
                                </div>
                            }

                            {
                                props.step == 2 ? props.trainingList[0] ?
                                        <div style={{color: "#dc4242", marginTop: "20px", fontWeight: "bolder"}}>
                                            Вы не можете отправить запрос на выбранное время, у вас есть пересечение по
                                            времени с другими тренировками:<br/>
                                            {
                                                props.trainingList.map((e, iii) => {
                                                    return <div
                                                        style={{color: "#ea5a5a", fontWeight: "normal", marginTop: "3px"}}>
                                                        {iii + 1}. {e}
                                                    </div>
                                                })
                                            }
                                        </div>
                                        : <div>Неизвестный конфликт в создании запроса на это время. Попросите своего
                                            руководителя связаться с разработчиком</div>
                                    : null
                            }

                            {
                                props.isLoading ? <MainLoading/>
                                    :
                                    selectedDay && selectedTime && props.step == 1 &&
                                    <div onClick={() => handleChangeIsSend()}
                                         className={"default-button green-back transition"}
                                         style={{marginTop: "30px", padding: "15px 0"}}>
                                        Отправить запрос
                                    </div>
                            }
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}