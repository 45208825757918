import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import Users from "./users";
import {ChangeUsersListFilter, SupportGetUsersList} from "../../../../redux/reducers/supportReducer";


class UsersContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }


    componentDidMount() {
        this.props.changeCurrentPage("Support: Список пользователей")
        this.props.SupportGetUsersList();
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <Users {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    SupportInfo: state.supportReducer
})


export default compose(
    connect(mapStateToProps, {
        SupportGetUsersList, ChangeUsersListFilter
    }),
    withRouter
)(UsersContainer)