import React, {useEffect, useState} from "react";

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
} from '@material-ui/pickers';
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import Select from 'react-dropdown-select';
import moment from "moment";
import Popup from "reactjs-popup";
import {Radar, Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    Title,
} from 'chart.js'
import {Tooltip as TT} from "@mui/material";


const defaultSchema = {
    'TOTAL PREFLOP': [],
    'General stat': [
        {
            func: "count_torney",
            name: 'Tourneys',
            value: 0,
            title: "подсказка",
            standards: [400, 1000],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "count_hands",
            name: 'Count hands',
            value: 0,
            title: "подсказка",
            standards: [400, 1000],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "wwsf",
            name: 'WWSF',
            value: 0,
            title: "подсказка",
            standards: [5, 5],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "wtsd",
            name: 'WTSD',
            value: 0,
            title: "подсказка",
            standards: [7, 7],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "wasd",
            name: 'WASD',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip",
            name: 'VPIP',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "pfr",
            name: 'PFR',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'EV category': [
        {
            func: "ev_total",
            name: 'EV Total',
            value: 0,
            title: "подсказка",
            standards: [400, 1000],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "ev_bb_199_ss_60",
            name: 'EV bb>199 eff<60',
            value: 0,
            title: "подсказка",
            standards: [5, 5],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "ev_bb_999",
            name: 'EV bb>999',
            value: 0,
            title: "подсказка",
            standards: [7, 7],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "ev_bb_4999",
            name: 'EV bb>4999',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "ev_bb_25_ss",
            name: 'EV eff <25bb',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "ev_bb_25_60_ss",
            name: 'EV eff 25-60bb',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "ev_bb_60_ss",
            name: 'EV eff >60bb',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "__ev_bb_unopend_total_50bb",
            name: 'EV Unopend Total eff <50bb',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "__ev_bb_vs_1r_total_50bb",
            name: 'EV vs 1R Total eff <50bb',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'Unopened': [],
    'Open Raise': [
        {
            func: "rfi_wai_ep",
            name: "EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "rfi_wai_mp",
            name: "MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "rfi_wai_mp1",
            name: "MP1",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "rfi_wai_hj",
            name: "HJ",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "rfi_wai_co",
            name: "CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "rfi_wai_bu",
            name: "BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'Fold vs 3bet': [
        {
            func: "foldvs3bet_wai_ep",
            name: "EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "foldvs3bet_wai_mp",
            name: "MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "foldvs3bet_wai_mp1",
            name: "MP1",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "foldvs3bet_wai_hj",
            name: "HJ",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "foldvs3bet_wai_co",
            name: "CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "foldvs3bet_wai_bu",
            name: "BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'vs 1 Raiser': [],
    'Call': [
        //     {
        //     func: "vs1r_wai_cc_ep",
        //     name: "EP",
        //     value: 0,
        //     title: "help",
        //     standards: [1, 99999],
        //     is_visible: true,
        //     formula: "None"
        // },
        {
            func: "vs1r_wai_cc_mp",
            name: "MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_cc_co",
            name: "CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_cc_bu",
            name: "BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_cc_sb",
            name: "SB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_cc_bb",
            name: "BB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    '3bet non all-in': [
        //     {
        //     func: "vs1r_wai_3betwai_ep",
        //     name: "EP",
        //     value: 0,
        //     title: "help",
        //     standards: [1, 99999],
        //     is_visible: true,
        //     formula: "None"
        // },
        {
            func: "vs1r_wai_3betwai_mp",
            name: "MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_3betwai_co",
            name: "CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_3betwai_bu",
            name: "BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_3betwai_sb",
            name: "SB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_3betwai_bb",
            name: "BB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    '3bet all-in': [
        //     {
        //     func: "vs1r_wai_3betai_greater8_ep",
        //     name: "EP",
        //     value: 0,
        //     title: "help",
        //     standards: [1, 99999],
        //     is_visible: true,
        //     formula: "None"
        // },
        {
            func: "vs1r_wai_3betai_greater8_mp",
            name: "MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_3betai_greater8_co",
            name: "CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_3betai_greater8_bu",
            name: "BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_3betai_greater8_sb",
            name: "SB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_3betai_greater8_bb",
            name: "BB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'VPIP vs Open Raise<2,4bb, Stack<28bb': [
        //     {
        //     func: "vpip_ep_vs_open_2_4_bb_less28bb",
        //     name: "EP",
        //     value: 0,
        //     title: "help",
        //     standards: [1, 99999],
        //     is_visible: true,
        //     formula: "None"
        // },
        {
            func: "vpip_mp_vs_ep_2_4_bb_less28bb",
            name: "MP vs EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_mp_vs_mp_2_4_bb_less28bb",
            name: "MP vs MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_co_vs_ep_2_4_bb_less28bb",
            name: "CO vs EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_co_vs_mp_2_4_bb_less28bb",
            name: "CO vs MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_bu_vs_ep_2_4_bb_less28bb",
            name: "BU vs EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_bu_vs_mp_2_4_bb_less28bb",
            name: "BU vs MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_bu_vs_co_2_4_bb_less28bb",
            name: "BU vs CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_sb_vs_ep_2_4_bb_less28bb",
            name: "SB vs EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_sb_vs_mp_2_4_bb_less28bb",
            name: "SB vs MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_sb_vs_co_2_4_bb_less28bb",
            name: "SB vs CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_sb_vs_bu_2_4_bb_less28bb",
            name: "SB vs BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'VPIP vs Open Raise<2,4bb, Stack>28bb': [
        //     {
        //     func: "vpip_ep_vs_open_2_4_bb_great28bb",
        //     name: "EP",
        //     value: 0,
        //     title: "help",
        //     standards: [1, 99999],
        //     is_visible: true,
        //     formula: "None"
        // },
        {
            func: "vpip_mp_vs_ep_2_4_bb_great28bb",
            name: "MP vs EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_mp_vs_mp_2_4_bb_great28bb",
            name: "MP vs MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_co_vs_ep_2_4_bb_great28bb",
            name: "CO vs EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_co_vs_mp_2_4_bb_great28bb",
            name: "CO vs MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_bu_vs_ep_2_4_bb_great28bb",
            name: "BU vs EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_bu_vs_mp_2_4_bb_great28bb",
            name: "BU vs MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_bu_vs_co_2_4_bb_great28bb",
            name: "BU vs CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_sb_vs_ep_2_4_bb_great28bb",
            name: "SB vs EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_sb_vs_mp_2_4_bb_great28bb",
            name: "SB vs MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_sb_vs_co_2_4_bb_great28bb",
            name: "SB vs CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vpip_sb_vs_bu_2_4_bb_great28bb",
            name: "SB vs BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'vs 1 Raiser + Call (s)': [],
    'Call_': [
        //     {
        //     func: "vs1r_wai_squezze_cc_ep",
        //     name: "EP",
        //     value: 0,
        //     title: "help",
        //     standards: [1, 99999],
        //     is_visible: true,
        //     formula: "None"
        // },
        {
            func: "vs1r_wai_squezze_cc_mp",
            name: "MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_squezze_cc_co",
            name: "CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_squezze_cc_bu",
            name: "BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_squezze_cc_sb",
            name: "SB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_squezze_cc_bb",
            name: "BB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'Squeeze non all-in': [
        //     {
        //     func: "vs1r_wai_squezze_wai_ep",
        //     name: "EP",
        //     value: 0,
        //     title: "help",
        //     standards: [1, 99999],
        //     is_visible: true,
        //     formula: "None"
        // },
        {
            func: "vs1r_wai_squezze_wai_mp",
            name: "MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_squezze_wai_co",
            name: "CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_squezze_wai_bu",
            name: "BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_squezze_wai_sb",
            name: "SB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_wai_squezze_wai_bb",
            name: "BB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'Squeeze all-in': [
        //     {
        //     func: "vs1r_ai_squezze_greater8_ai_ep",
        //     name: "EP",
        //     value: 0,
        //     title: "help",
        //     standards: [1, 99999],
        //     is_visible: true,
        //     formula: "None"
        // },
        {
            func: "vs1r_ai_squezze_greater8_ai_mp",
            name: "MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_ai_squezze_greater8_ai_co",
            name: "CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_ai_squezze_greater8_ai_bu",
            name: "BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_ai_squezze_greater8_ai_sb",
            name: "SB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "vs1r_ai_squezze_greater8_ai_bb",
            name: "BB",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'BB Defense': [],
    'Fold vs Open Raise<2,4bb': [
        {
            func: "foldvs1R_2_4_bb_vs_ep",
            name: "BB vs EP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "foldvs1R_2_4_bb_vs_mp",
            name: "BB vs MP",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "foldvs1R_2_4_bb_vs_co",
            name: "BB vs CO",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "foldvs1R_2_4_bb_vs_bu",
            name: "BB vs BU",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'SB vs BB_': [
        {
            func: "bvb_sb_raise",
            name: "SB raise",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "bvb_sb_limp",
            name: "SB limp",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "bvb_sb_limp_fold",
            name: "SB limp-fold",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "bvb_sb_limp_raise",
            name: "SB limp-raise",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "bvb_bb_iso",
            name: "BB iso",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "bvb_bb_fold_vs_raise_less2_4",
            name: "BB Fold vs SB raise Open<2,4bb",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "bvb_bb_fold_vs_raise_less2_8",
            name: "BB Fold vs SB raise Open 2,4bb-2,8bb",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "bvb_bb_fold_vs_raise_less3_7",
            name: "BB Fold vs SB raise Open 2,8bb-3,7bb",
            value: 0,
            title: "help",
            standards: [1, 99999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
}
const defaultSchemaPostFlop = {
    'TOTAL POSTFLOP': [],
    'Single Raise Pot': [],
    'General': [
        {
            func: "total_flop_cbet",
            name: 'Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [400, 1000],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_turn_cbet",
            name: 'Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [5, 5],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_river_cbet",
            name: 'Cbet River',
            value: 0,
            title: "подсказка",
            standards: [7, 7],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_flop_fold_and_check_fold",
            name: 'Fold + X/F Flop',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_turn_fold_and_check_fold",
            name: 'Fold + X/F Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_river_fold_and_check_fold",
            name: 'Fold + X/F River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_flop_bet_vs_misscbet",
            name: 'Bet vs MissCbet Flop',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_turn_bet_vs_misscbet",
            name: 'Bet vs MissCbet Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_river_bet_vs_misscbet",
            name: 'Bet vs MissCbet River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_turn_probe",
            name: 'Probe Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_river_probe",
            name: 'Probe River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_turn_delay",
            name: 'Delay Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "total_river_delay",
            name: 'Delay River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },],
    'RAISER OOP': [
        {
            func: "raiser_oop_flop_bet",
            name: 'Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [400, 1000],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_turn_bet",
            name: 'Bet Turn',
            value: 0,
            title: "подсказка",
            standards: [5, 5],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_river_bet",
            name: 'Bet River',
            value: 0,
            title: "подсказка",
            standards: [7, 7],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_flop_check_raise",
            name: 'Check-Raise Flop',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_turn_check_raise",
            name: 'Check-Raise Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_river_check_raise",
            name: 'Check-Raise River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_flop_check_fold",
            name: 'Check-Fold Flop',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_turn_check_fold",
            name: 'Check-Fold Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_river_check_fold",
            name: 'Check-Fold River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_turn_cbet_aftercbet",
            name: '(After Cbet Flop) Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_turn_XF_aftercbet",
            name: '(After Cbet Flop) Check-Fold Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_turn_bet_afterXXflop",
            name: '(After XX Flop) Delay Bet Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_turn_XF_afterXXflop",
            name: '(After XX Flop) Check-Fold Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_river_cbet_after_cbflop_cbturn",
            name: '(After Cbet Flop+Cbet Turn) Cbet river',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_river_bet_after_cbetflop_XXturn",
            name: '(After Cbet Flop+XX Turn) Delay Bet river',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_river_bet_after_XXflop_betturn",
            name: '(After XX Flop+Delay Bet Turn) Cbet river',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_river_bet_after_XXflop_XXturn",
            name: '(After XX Flop+XX Turn) Delay Bet river',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_oop_river_XF_after_XCflop_XCturn",
            name: '(After XC Flop+XC Turn) Check-Fold River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'CALLER OOP': [
        {
            func: "caller_oop_flop_donk",
            name: 'Donk Flop',
            value: 0,
            title: "подсказка",
            standards: [400, 1000],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_turn_donk",
            name: 'Bet Turn',
            value: 0,
            title: "подсказка",
            standards: [5, 5],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_river_donk",
            name: 'Bet River',
            value: 0,
            title: "подсказка",
            standards: [7, 7],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_flop_XFvsBet",
            name: 'Check-Fold Flop',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_turn_XFvsBet",
            name: 'Check-Fold Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_river_XFvsBet",
            name: 'Check-Fold River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_flop_XRvsBet",
            name: 'Check-Raise Flop',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_turn_XRvsBet",
            name: 'Check-Raise Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_river_XRvsBet",
            name: 'Check-Raise River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_turn_cbet_after_XRflop",
            name: '(After XR Flop) Barrel Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_turn_bet_after_XXflop",
            name: '(After XX Flop) Probe Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_turn_XF_after_XXflop",
            name: '(After XX Flop) Check-Fold Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_turn_XF_after_XCflop",
            name: '(After XC Flop) Check-Fold Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_turn_XR_after_XCflop",
            name: '(After XC Flop) Check-Raise Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_turn_donk_after_XCflop",
            name: '(After XC Flop) Donk Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_river_cbet_after_XRflop_Bturn",
            name: '(After XR Flop+Barrel Turn) Barrel River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_river_cbet_after_XXflop_Bturn",
            name: '(After XX Flop+Probe Turn) Barrel River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_river_cbet_after_XXflop_XXturn",
            name: '(After XX Flop+XX Turn) Probe River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_river_cbet_after_XCflop_XXturn",
            name: '(After XC Flop+XX Turn) Probe River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_oop_river_cbet_after_XCflop_XCturn",
            name: '(After XC Flop+XC Turn) Donk River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'RAISER IP': [
        {
            func: "raiser_ip_flop_bet",
            name: 'Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [400, 1000],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_turn_bet",
            name: 'Bet Turn',
            value: 0,
            title: "подсказка",
            standards: [5, 5],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_river_bet",
            name: 'Bet River',
            value: 0,
            title: "подсказка",
            standards: [7, 7],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flop_bet_foldvsXR",
            name: '(After Cbet flop) Fold vs XR',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flop_bet_turn_cbet",
            name: '(After Cbet flop) Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flop_X_turn_bet",
            name: '(After XX flop) Delay Bet Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },

        {
            func: "raiser_ip_flop_X_turn_foldvsbet",
            name: '(After XX Flop) Fold vs Probe Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flop_callvsXR_turn_betvsmiss",
            name: '(After Call vs XR Flop) Bet vs miss Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flop_callvsXR_turn_foldvsbet",
            name: '(After Call vs XR flop) Fold vs Barrel Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flopbet_turn_x_river_bet",
            name: '(After Cbet Flop+XX Turn) Delay Bet River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flopbet_turnbet_rivercbet",
            name: '(After Cbet Flop+Cbet Turn) Cbet River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flopX_turnbet_rivercbet",
            name: '(After XX Flop+Delay Bet Turn) Barrel River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flopX_turnX_rivercbet",
            name: '(After XX Flop+XX Turn) Delay Bet River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flopX_turnX_riverfoldvsbet",
            name: '(After XX Flop+XX Turn) Fold vs Probe River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "raiser_ip_flopX_turncall_riverfoldvsbet",
            name: '(After XX Flop+Call Probe Turn) Fold vs Barrel River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'CALLER IP': [
        {
            func: "caller_ip_flopbet",
            name: 'Bet vs Miss Flop',
            value: 0,
            title: "подсказка",
            standards: [400, 1000],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_turnbet",
            name: 'Bet Turn',
            value: 0,
            title: "подсказка",
            standards: [5, 5],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_riverbet",
            name: 'Bet River',
            value: 0,
            title: "подсказка",
            standards: [7, 7],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flop_raisevsbet",
            name: 'Raise Flop',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_turn_raisevsbet",
            name: 'Raise Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_river_raisevsbet",
            name: 'Raise River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flop_foldvsbet",
            name: 'Fold vs Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_turn_foldvsbet",
            name: 'Fold vs Bet Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_river_foldvsbet",
            name: 'Fold vs Bet River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopbet_turncbet",
            name: '(After Bet vs Miss Flop) Barrel Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopX_turnbetvsmiss",
            name: '(After XX Flop) Bet vs Miss Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopcall_turnbetvsmiss",
            name: '(After Call Flop) Bet vs Miss Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopcall_turnfoldvsbet",
            name: '(After Call Flop) Fold vs Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopbet_turnbet_rivercbet",
            name: '(After Bet vs Miss Flop+Barrel Turn) Barrel River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopX_turnbet_rivercbet",
            name: '(After XX Flop+Bet vs Miss Turn) Barrel River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopcall_turnbet_rivercbet",
            name: '(After Call Flop+Bet vs Miss Turn) Barrel River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopX_turnX_riverbetvsmiss",
            name: '(After XX Flop+XX Turn) Bet vs Miss River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopcall_turncall_riverbetvsmiss",
            name: '(After Call Flop+Call Turn) Bet vs Miss River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "caller_ip_flopcall_turncall_riverfoldvsbet",
            name: '(After Call Flop+Call Turn) Fold vs Cbet River',
            value: 0,
            title: "подсказка",
            standards: [10, 10],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    '3bet pot': [],
    'Raiser OOP': [
        {
            func: "_3betpot_raiseroop_cbetflop",
            name: 'Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_3betpot_raiseroop_delaycbet_turn",
            name: 'Delay Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'Caller OOP': [
        {
            func: "_3betpot_calleroop_foldvscbet_flop",
            name: 'Fold vs Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_3betpot_calleroop_raisevscbet_flop",
            name: 'Raise vs Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_3betpot_calleroop_probe_river",
            name: 'Probe River',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ], // ---
    'Raiser IP': [
        {
            func: "_3betpot_raiserip_cbet_flop",
            name: 'Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_3betpot_raiserip_delaycbet_turn",
            name: 'Delay Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ], // ---
    'Caller IP': [
        {
            func: "_3betpot_callerip_foldvscbet_flop",
            name: 'Fold vs Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_3betpot_callerip_raisevscbet_flop",
            name: 'Raise vs Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_3betpot_callerip_betvsmisscbet_flop",
            name: 'Bet vs MissCbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'SB vs BB': [],
    'Raise Pot': [
        {
            func: "_bvb_sbraiser_cbet_flop",
            name: 'SB Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_sbraiser_XR_flop",
            name: 'SB Check-Raise Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_sbraiser_cbet_turn",
            name: 'SB Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_sbraiser_delay_cbet_turn",
            name: 'SB Delay Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_bbcaller_raisevscbet_flop",
            name: 'BB Raise vs Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_bbcaller_foldvscbet_flop",
            name: 'BB Fold vs Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_bbcaller_foldvscbet_turn",
            name: 'BB Fold vs Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'Isolate Pot': [
        {
            func: "_bvb_bbafteriso_cbet_flop",
            name: 'BB Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
    'Limp Pot': [
        {
            func: "_bvb_sblimper_bet_flop",
            name: 'SB Bet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_sblimper_XR_flop",
            name: 'SB Check-Raise Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_sblimper_XF_flop",
            name: 'SB Check-Fold Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_sblimper_cbet_turn",
            name: 'SB Cbet Turn',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_bbchecker_foldvscbet_flop",
            name: 'BB Fold vs Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_bbchecker_raisevscbet_flop",
            name: 'BB Raise vs Cbet Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
        {
            func: "_bvb_bbchecker_betvsmisscbet_flop",
            name: 'BB Bet vs SB check Flop',
            value: 0,
            title: "подсказка",
            standards: [1, 999],
            evStandards: [1, 100],
            is_visible: true,
            formula: "None"
        },
    ],
}


ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale,
);

function randomInteger(min, max) {
    let rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
}

const GroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите группу"}
            labelField={"leakfinder_db_group"}
            valueField={"leakfinder_db_group"}
            sortBy={"leakfinder_db_group"}
            create
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectGroup(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const DirectionSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите направление"}
            labelField={"direction"}
            valueField={"direction"}
            sortBy={"direction"}
            create
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectDirection(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "103"}}
        />
    )
}
const PlayerSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите игрока"}
            labelField={"nick"}
            valueField={"nick"}
            sortBy={"nick"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedPlayers}
            onChange={(value) => {
                props.handleSelectPlayer(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "101"}}
        />
    )
}
const RoomNameSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите roomname "}
            labelField={"name"}
            valueField={"name"}
            multi
            options={props.options}
            values={props.options}
            onChange={(value) => {
                props.handleSelectRoomNames(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "101"}}
        />
    )
}
const RankSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите ранг "}
            labelField={"name"}
            valueField={"name"}
            options={props.options}
            values={[]}
            onChange={(value) => {
                if (value && value[0]) props.handleSelectRank(value)
            }
            }
            style={{
                backgroundColor: "#4c516a",
                margin: "5px 12px",
                fontSize: "19px",
                border: "none",
                paddingLeft: "11px",
                zIndex: "102"
            }}
        />
    )
}

function Matrix({data, color}) {
    let colors_g = [{v: 0, c: "#014800"}, {v: 0.1, c: "#167101"}, {v: 0.3, c: "#03bc04"},
        {v: 0.75, c: "#07f515"}, {v: 2, c: "#8fea07"}, {v: 5, c: "#d8f506"}];
    let colors_b = [{v: 0, c: "#022e50"}, {v: 0.1, c: "#033684"}, {v: 0.3, c: "#0653b2"},
        {v: 0.75, c: "#0490f1"}, {v: 2, c: "#07cefc"}, {v: 5, c: "#00f3e9"}];
    let colors_r = [{v: 0, c: "#4b000c"}, {v: 0.1, c: "#710303"}, {v: 0.3, c: "#bc0505"},
        {v: 0.75, c: "#f80606"}, {v: 2, c: "#ef0202"}, {v: 5, c: "#f60788"}];

    let selectedColors;

    if (color == "blue") {
        selectedColors = colors_b;
    } else if (color == "red") {
        selectedColors = colors_r;
    } else {
        selectedColors = colors_g;
    }

    let totalHands = 0;

    let lengthWithoutZero = 0;

    data.forEach(e => {
        if (e.value > 0) lengthWithoutZero += 1;
        totalHands += +e.value;
    })

    let avgHands = totalHands / lengthWithoutZero;

    return (
        <div className="lf-matrix" style={{borderRadius: "20px"}}>
            {data.map((item, index) => {
                    let cof = item.value ? +item.value / avgHands.toFixed(3) : -1;

                    let backgroundColor = "#303030";

                    selectedColors.forEach(color => {
                        if (cof >= color.v) backgroundColor = color.c;
                    })

                    return <div key={`${randomInteger(1, 9999999999)}`} className="lf-matrix-item"
                                style={{backgroundColor}}>
                        <div className="lf-matrix-name">{item.name}</div>
                        <div className="lf-matrix-value">{item.value}</div>
                    </div>
                }
            )}
        </div>
    );
}

let coloredCategories = ["Unopened",
    "vs 1 Raiser",
    "vs 1 Raiser + Call (s)",
    "BB Defense",
    "SB vs BB",
    "Single Raise Pot",
    "3bet pot",
    "SB vs BB", "SB vs BB_"]

export default function Leakfinder(props) {
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [saveName, setSaveName] = useState(null);

    const [selectedRoomNames, setSelectedRoomNames] = useState([]);
    const [selectedRanks, setSelectedRanks] = useState([]);
    const [selectedFlop, setSelectedFlop] = useState(1);
    const [isLineChartFullscreen, setIsLineChartFullscreen] = useState(false);
    const [randColor, setRandColor] = useState(1);
    const setRoomNames = (value) => {
        props.LeakfinderEditAnalyzeInfo("roomNames", value)
    }
    const setFromDate = (value) => {
        props.LeakfinderEditAnalyzeInfo("fromDate", value)
    }
    const setToDate = (value) => {
        props.LeakfinderEditAnalyzeInfo("toDate", value)
    }
    const handleSetSelectedFlop = (num) => {
        setSelectedFlop(num)
    }

    const handleGetSaves = (e) => {
        props.LeakfinderGetSaves(props.LeakfinderInfo.analyze_info.roomNames)
    }

    let options = {
        scales: {
            r: {
                angleLines: {
                    display: false
                },
                suggestedMin: 1,
                suggestedMax: 10,
                grid: {
                    color: 'rgba(234,234,234,0.18)',
                },
                pointLabels: {
                    color: 'rgb(0,178,255)',
                    font: {
                        size: 10
                    }
                },
                ticks: {
                    backdropColor: 'rgba(234,234,234,0)',
                    color: "orange"
                }
            }
        },
        plugins: {
            legend: {
                display: true,
            }
        }
    };
    const line_chart_options_not_full_screen = {
        elements: {
            point: {
                radius: isLineChartFullscreen ? 2 : 1
            }
        },
        responsive: true,
        plugins: {
            title: {
                display: true,
            },
            legend: {
                display: false,
            }
        },
        scales: {
            xAxis: {
                type: 'linear'
            }
        }
    };
    const line_chart_options_full_screen = {
        elements: {
            point: {
                radius: isLineChartFullscreen ? 2 : 1
            }
        },
        responsive: true,
        plugins: {
            title: {
                display: true,
            },
            legend: {
                display: true,
            }
        },
        scales: {
            xAxis: {
                type: 'linear'
            }
        }
    };

    const createLineChartData = (label, arrayOfObjects, keyInObject, isHidden = false) => {
        let color = "#3171ee";

        if (keyInObject == "amt_won") color = "#3ad036";
        if (keyInObject == "amt_expected_won") color = "#f1a045";
        if (keyInObject == "blue_line") color = "#3171ee";
        if (keyInObject == "red_line") color = "#ff4646";

        let obj = {
            label,
            borderColor: color,
            backgroundColor: color,
            borderWidth: 1,
            data: {},
            // hidden: isHidden,
        };

        arrayOfObjects.forEach((e, i) => {
            obj.data[`${i * e.step}`] = e[keyInObject];
        })

        return obj;
    }
    // const line_chart_chip_won = createLineChartData("BB Won", props.LeakfinderInfo.line_chart_data, "amt_won");
    // const line_chart_ev_chip_won = createLineChartData("EV BB Won", props.LeakfinderInfo.line_chart_data, "amt_expected_won");
    // const line_chart_blue_line = createLineChartData("BB Won with showdown", props.LeakfinderInfo.line_chart_data, "blue_line");
    // const line_chart_red_line = createLineChartData("BB Won without showdown", props.LeakfinderInfo.line_chart_data, "red_line");
    let line_chart_data_from_saves = [];
    if (props.LeakfinderInfo.currentSaves[0]) {
        props.LeakfinderInfo.currentSaves.forEach((save, i) => {
            if (save.line_chart?.data) {
                const chip_won = createLineChartData(`BB Won Save ${i + 1}`, save.line_chart.data, "amt_won", true);
                const ev_chip_won = createLineChartData(`EV BB Won Save ${i + 1}`, save.line_chart.data, "amt_expected_won", true);
                const blue_line = createLineChartData(`BB Won with showdown Save ${i + 1}`, save.line_chart.data, "blue_line", true);
                const red_line = createLineChartData(`BB Won without showdown Save ${i + 1}`, save.line_chart.data, "red_line", true);
                line_chart_data_from_saves.push({...chip_won})
                line_chart_data_from_saves.push({...ev_chip_won})
                line_chart_data_from_saves.push({...blue_line})
                line_chart_data_from_saves.push({...red_line})
            }
        })
    }
    let line_chart_data_datasets = [
        // line_chart_chip_won, line_chart_ev_chip_won, line_chart_blue_line, line_chart_red_line,
        ...line_chart_data_from_saves
    ];
    const line_chart_data = {
        datasets: line_chart_data_datasets,
    };
    const handleSelectGroup = (e) => {
        let players = [];
        let rnames = [];

        if (e && e[0]) {
            props.LeakfinderInfo.players.forEach(player => {
                e.forEach(se => {
                    if (se.leakfinder_db_group == player.leakfinder_db_group) {
                        players.push(player);
                        player.room_names.forEach(rn => {
                            rnames.push(rn)
                        })
                    }
                })
            })
        }

        setRoomNames(JSON.parse(JSON.stringify(rnames)))
        setSelectedPlayers(players);
        props.LeakfinderAnalyzeSetSelectedPlayers(players)
        props.LeakfinderGetSaves(rnames)
    }
    const handleSelectDirection = (e) => {
        props.LeakfinderAnalyzeSetDirection(e && e[0] ? e[0].direction : "devdel")
    }
    const handleSelectPlayer = (e) => {
        if (e && e[0]) {
            let rnames = [];

            e.forEach(j => {
                j.room_names.forEach(k => {
                    rnames.push(k)
                })
            })

            if (e.length == 1) {
                props.LeakfinderEditDbInfo("name", e[0].leakfinder_db_name)
            } else {
                props.LeakfinderEditDbInfo("name", "")
            }

            setRoomNames(JSON.parse(JSON.stringify(rnames)))
            setSelectedPlayers(e)
            props.LeakfinderAnalyzeSetSelectedPlayers(e)
            props.LeakfinderGetSaves(rnames)
        } else {
            setRoomNames(JSON.parse(JSON.stringify([])));
            setSelectedPlayers([])
            props.LeakfinderAnalyzeSetSelectedPlayers([])
            props.LeakfinderGetSaves([])
        }

    }
    const handleSelectRoomNames = (e) => {
        setSelectedRoomNames(JSON.parse(JSON.stringify(e)))
        setRoomNames(JSON.parse(JSON.stringify(e)));
    }
    const handleSelectRank = (e) => {
        setSelectedRanks(JSON.parse(JSON.stringify(e)));
    }
    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            },

        },
    });


    let schema = {};

    if (selectedRanks && selectedRanks[0]) {
        schema = {...schema, ...selectedRanks[0].schema_pre_flop, ...selectedRanks[0].schema_post_flop};
    } else if (props.LeakfinderInfo.player_info) {
        schema = {...schema, ...props.LeakfinderInfo.player_info, ...props.LeakfinderInfo.sub_player_info};
    } else if (props.LeakfinderInfo.currentSaves && props.LeakfinderInfo.currentSaves[0]) {
        schema = {...schema, ...props.LeakfinderInfo.currentSaves[0].data.player_info, ...props.LeakfinderInfo.currentSaves[0].data.sub_player_info}
    } else {
        schema = {...schema, ...defaultSchema, ...defaultSchemaPostFlop}
    }

    let export_data = {...defaultSchema, ...defaultSchemaPostFlop};

    Object.keys(export_data).map((keyName) => {
        export_data[keyName].forEach(element => {
            element.standards = "-";
            element.values = [];
            element.headers = [];
        })
    });

    let export_data_headers = ["Standarts"];
    let export_room_names = [...props.LeakfinderInfo.analyze_info.roomNames];

    if (props.LeakfinderInfo.player_info) {
        export_data_headers.push("Analyze");
        Object.keys(export_data).map((keyName) => {
            export_data[keyName].forEach(e => {
                let toCheck = {...props.LeakfinderInfo.player_info, ...props.LeakfinderInfo.sub_player_info};
                if (toCheck[keyName]) {
                    const indexOfFunc = toCheck[keyName].findIndex(k => k.func == e.func);
                    if (indexOfFunc == -1) {
                        e.values.push("-")
                    } else {
                        e.values.push(`${toCheck[keyName][indexOfFunc].value}`.includes(".") ? `${toCheck[keyName][indexOfFunc].value}`.replace('.', ',') : `${toCheck[keyName][indexOfFunc].value}`);
                        e.standards = toCheck[keyName][indexOfFunc].standards;
                    }
                } else {
                    e.values.push("-")
                }
            })
        })
    }
    if (props.LeakfinderInfo.currentSaves && props.LeakfinderInfo.currentSaves[0]) {
        props.LeakfinderInfo.currentSaves.forEach((save, saveIndex) => {
            export_data_headers.push(save.topic);
            export_room_names = [...export_room_names, ...save.room_names];

            Object.keys(export_data).map((keyName) => {
                export_data[keyName].forEach(e => {
                    let toCheck = {...save.data.player_info, ...save.data.sub_player_info};
                    if (toCheck[keyName]) {
                        const indexOfFunc = toCheck[keyName].findIndex(k => k.func == e.func);
                        if (indexOfFunc == -1) {
                            e.values.push("-")
                        } else {
                            e.values.push(`${toCheck[keyName][indexOfFunc].value}`.includes(".") ? `${toCheck[keyName][indexOfFunc].value}`.replace('.', ',') : `${toCheck[keyName][indexOfFunc].value}`);
                            if (saveIndex == 0 && !export_data_headers.includes("Analyze")) e.standards = toCheck[keyName][indexOfFunc].standards;
                        }
                    } else {
                        e.values.push("-")
                    }
                })
            })
        })
    }

    const handleExportData = (export_data, export_data_headers, export_room_names) => {
        let updated_room_names = export_room_names.map(e => e.name);
        updated_room_names = [...new Set(updated_room_names)];

        let generatedCSV = `Roomnames;${updated_room_names}\n\nName;`;

        export_data_headers.forEach((e, i) => {
            generatedCSV += `${e}`;
            generatedCSV += i + 1 == export_data_headers.length ? "\n" : ";"
        })

        let keys = ["General stat", "EV category", "Open Raise", "Fold vs 3bet", "Call",
            "3bet non all-in", "3bet all-in", "Call_", "Squeeze non all-in", "Squeeze all-in",
            "VPIP vs Open Raise<2,4bb, Stack<28bb", "VPIP vs Open Raise<2,4bb, Stack>28bb", "Fold vs Open Raise<2,4bb", "SB vs BB_",
            "General", "RAISER OOP", "CALLER OOP", "RAISER IP", "CALLER IP",
            "Raiser OOP", "Caller OOP", "Raiser IP", "Caller IP", "Raise Pot",
            "Isolate Pot", "Limp Pot"
        ];

        keys.forEach(key => {
            generatedCSV += `\n${key}\n`
            export_data[key].forEach((d, i) => {
                generatedCSV += `${d.name};${d.standards};`
                d.values.forEach((v, i) => {
                    generatedCSV += `${v}`;
                    generatedCSV += i + 1 == d.values.length ? "\n" : ";"
                })
            })
        })

        let link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(generatedCSV));
        link.setAttribute('download', "export.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    let s = [];
    let u = [];
    let v = [];
    let b = [];
    let page_percents_total = [];

    Object.keys(selectedFlop == 1 ? defaultSchema : defaultSchemaPostFlop).map((keyName, i) => { // идём по категориям схемы
        // i = индекс категории
        if (!schema[keyName]) return;
        let stats = (selectedFlop == 1 ? schema[keyName] : schema[keyName]).map((j, i) => { // идём по объектам категории схемы
            if (!j.is_visible) return undefined;

            // У нас в выбранной схеме is_visible = true
            // В сохранении is_visible = false, либо просто нету данных

            let columns = []; // Это строка одной функции из категории, 1 значение = 1 столбец
            let getPercent = (v, s) => {
                let value = +(+v).toFixed(2);
                let standards = [0, 0];
                let different = 0;

                if (typeof s == "object") {
                    standards[0] = +s[0];
                    standards[1] = +s[1];
                } else if (s.includes("&")) {
                    standards = [...s.split("&")]
                } else {
                    standards[0] = +s;
                    standards[1] = +s;
                }

//standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
                if (+standards[0].length <= 0) standards[0] = 0;
                if (+standards[1].length <= 0) standards[1] = 0;

                // Вычисляем разницу между верхней и нижней границей эталона
                let etalon_gape = +standards[1] - +standards[0];

                // для каждого значения etalon_gape определяем коэффициент для дальнейших расчетов
                let keff = 0;
                if (etalon_gape > 0 && etalon_gape <= 3) {
                    keff = 13;
                } else if (etalon_gape > 3 && etalon_gape <= 5) {
                    keff = 8;
                } else if (etalon_gape > 5 && etalon_gape <= 10) {
                    keff = 5;
                } else if (etalon_gape > 10) {
                    keff = 3;
                }

                // Далее каждый процент расхождения умножаем на этот коэфф и тем самым получаем %попадания
                let diff = 0;
                if (+value < +standards[0]) {
                    diff = +standards[0] - +value;
                }
                else if (+value > +standards[1]) {
                    diff = +value - +standards[1];
                }

                //  попадание = 100 - расхождение*КОЭФФ;
                //      ЕСЛИ попадание < 0 то попадание = 0;
                let hit = 100 - diff * keff;
                if (hit < 0) hit = 0;

                // console.log(`========================================`)
                // console.log(`etalon_gape = ${etalon_gape}`)
                // console.log(`keff = ${keff}`)
                // console.log(`diff = ${diff.toFixed(1)}`)
                // console.log(`hit = ${hit.toFixed(1)}`)

                return +hit
            }

            if (props.LeakfinderInfo.player_info) {
                if (selectedFlop == 1) {
                    if (props.LeakfinderInfo.player_info[keyName] && props.LeakfinderInfo.player_info[keyName][0]) {
                        let data = props.LeakfinderInfo.player_info[keyName].find(e => e.name == j.name);
                        if (data && data.is_visible != j.is_visible) {
                            columns.push(undefined);
                        } else {
                            if (data) {
                                columns.push(getPercent(data.value, schema[keyName][i].standards))
                            } else {
                                columns.push(undefined);
                            }
                        }
                    } else {
                        columns.push(undefined)
                    }
                } else {
                    if (props.LeakfinderInfo.sub_player_info[keyName] && props.LeakfinderInfo.sub_player_info[keyName][0]) {
                        let data = props.LeakfinderInfo.sub_player_info[keyName].find(e => e.name == j.name);
                        if (data && data.is_visible != j.is_visible) {
                            columns.push(undefined);
                        } else {
                            if (data) {
                                columns.push(getPercent(data.value, schema[keyName][i].standards))
                            } else {
                                columns.push(undefined);
                            }
                        }
                    } else {
                        columns.push(undefined)
                    }
                }
            }
            if (props.LeakfinderInfo.currentSaves[0]) {
                if (selectedFlop == 1) {
                    props.LeakfinderInfo.currentSaves.forEach(e => {
                        if (e.data.player_info[keyName] && e.data.player_info[keyName][0]) {
                            let data = e.data.player_info[keyName].find(e => e.name == j.name);
                            if (data && data.is_visible != j.is_visible) {
                                columns.push(undefined);
                            } else {
                                if (data) {
                                    columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(undefined);
                                }
                            }
                        } else {
                            columns.push(undefined)
                        }
                    })
                } else {
                    props.LeakfinderInfo.currentSaves.forEach(e => {
                        if (e.data.sub_player_info[keyName] && e.data.sub_player_info[keyName][0]) {
                            let data = e.data.sub_player_info[keyName].find(e => e.name == j.name);
                            if (data && data.is_visible != j.is_visible) {
                                columns.push(undefined);
                            } else {
                                if (data) {
                                    columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(undefined);
                                }
                            }
                        } else {
                            columns.push(undefined)
                        }
                    })
                }
            }
            return columns
        })


        stats = stats.map(columns => {
            if (columns && columns.includes(undefined)) {
                return undefined;
            } else {
                return columns
            }
        })
        stats = stats.filter(e => e != undefined) // stats - это набор массивов (строк функций категорий, где 1 значение = 1 столбец)
        // здесь мы понимаем, что категории нету и её нужно вырезать

        s.push(stats);


        let ustats = [];

        let page_percents_category_total = [];

        if (stats && stats[0]) {
            for (let i = 0; i < stats[0].length; i++) {
                let endValue = 0;
                let countOfValues = 0;
                stats.forEach(e => {
                    countOfValues++;
                    endValue += e[i];
                })
                ustats.push(((endValue / countOfValues)));
                page_percents_category_total.push(endValue);
            }
        }

        u = [...u, ...ustats];
        page_percents_total = [...page_percents_total, ...page_percents_category_total];
    })

    let lngt = 0;

    s.forEach(r => {
        lngt += r.length;
    })

    u.forEach((r, i) => {
        let vl = 0;
        s.forEach(e => {
            e.forEach(p => {
                if (+p[i] == 100) {
                    vl += 1;
                }
            })
        })

        let vvv = ((vl / lngt) * 100);
        v.push(vvv <= 0 ? 0 : vvv) // TODO: получение процента по всем категориям (попадание/не попадание)
    })

    let categories_count = s.filter(e => e[0]);
    let columns_count = page_percents_total.length / categories_count.length;

    const saves_categories = [];
    for (let i = 0; i < columns_count; i++) {
        saves_categories.push(page_percents_total.filter((value, index) => index % columns_count === i));
    }

    saves_categories.forEach(sc => {
        b.push((sc.reduce((a, b) => +a + +b) / lngt))
    });

















    let s_2 = [];
    let u_2 = [];
    let v_2 = [];
    let b_2 = [];
    let page_percents_total_2 = [];

    Object.keys(selectedFlop == 1 ? defaultSchemaPostFlop : defaultSchema).map((keyName, i) => { // идём по категориям схемы
        // i = индекс категории
        if (!schema[keyName]) return;
        let stats = (selectedFlop == 1 ? schema[keyName] : schema[keyName]).map((j, i) => { // идём по объектам категории схемы
            if (!j.is_visible) return undefined;

            // У нас в выбранной схеме is_visible = true
            // В сохранении is_visible = false, либо просто нету данных

            let columns = []; // Это строка одной функции из категории, 1 значение = 1 столбец
            let getPercent = (v, s) => {
                let value = +(+v).toFixed(2);
                let standards = [0, 0];
                let different = 0;

                if (typeof s == "object") {
                    standards[0] = +s[0];
                    standards[1] = +s[1];
                } else if (s.includes("&")) {
                    standards = [...s.split("&")]
                } else {
                    standards[0] = +s;
                    standards[1] = +s;
                }

//standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
                if (+standards[0].length <= 0) standards[0] = 0;
                if (+standards[1].length <= 0) standards[1] = 0;

                // Вычисляем разницу между верхней и нижней границей эталона
                let etalon_gape = +standards[1] - +standards[0];

                // для каждого значения etalon_gape определяем коэффициент для дальнейших расчетов
                let keff = 0;
                if (etalon_gape > 0 && etalon_gape <= 3) {
                    keff = 13;
                } else if (etalon_gape > 3 && etalon_gape <= 5) {
                    keff = 8;
                } else if (etalon_gape > 5 && etalon_gape <= 10) {
                    keff = 5;
                } else if (etalon_gape > 10) {
                    keff = 3;
                }

                // Далее каждый процент расхождения умножаем на этот коэфф и тем самым получаем %попадания
                let diff = 0;
                if (+value < +standards[0]) {
                    diff = +standards[0] - +value;
                }
                else if (+value > +standards[1]) {
                    diff = +value - +standards[1];
                }

                //  попадание = 100 - расхождение*КОЭФФ;
                //      ЕСЛИ попадание < 0 то попадание = 0;
                let hit = 100 - diff * keff;
                if (hit < 0) hit = 0;

                // console.log(`========================================`)
                // console.log(`etalon_gape = ${etalon_gape}`)
                // console.log(`keff = ${keff}`)
                // console.log(`diff = ${diff.toFixed(1)}`)
                // console.log(`hit = ${hit.toFixed(1)}`)

                return +hit
            }

            if (props.LeakfinderInfo.player_info) {
                if (selectedFlop == 1) {
                    if (props.LeakfinderInfo.sub_player_info[keyName] && props.LeakfinderInfo.sub_player_info[keyName][0]) {
                        let data = props.LeakfinderInfo.sub_player_info[keyName].find(e => e.name == j.name);
                        if (data && data.is_visible != j.is_visible) {
                            columns.push(undefined);
                        } else {
                            if (data) {
                                columns.push(getPercent(data.value, schema[keyName][i].standards))
                            } else {
                                columns.push(undefined);
                            }
                        }
                    } else {
                        columns.push(undefined)
                    }
                } else {
                    if (props.LeakfinderInfo.player_info[keyName] && props.LeakfinderInfo.player_info[keyName][0]) {
                        let data = props.LeakfinderInfo.player_info[keyName].find(e => e.name == j.name);
                        if (data && data.is_visible != j.is_visible) {
                            columns.push(undefined);
                        } else {
                            if (data) {
                                columns.push(getPercent(data.value, schema[keyName][i].standards))
                            } else {
                                columns.push(undefined);
                            }
                        }
                    } else {
                        columns.push(undefined)
                    }
                }
            }


            if (props.LeakfinderInfo.currentSaves[0]) {
                if (selectedFlop == 1) {
                    props.LeakfinderInfo.currentSaves.forEach(e => {
                        if (e.data.sub_player_info[keyName] && e.data.sub_player_info[keyName][0]) {
                            let data = e.data.sub_player_info[keyName].find(e => e.name == j.name);
                            if (data && data.is_visible != j.is_visible) {
                                columns.push(undefined);
                            } else {
                                if (data) {
                                    columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(undefined);
                                }
                            }
                        } else {
                            columns.push(undefined)
                        }
                    })
                } else {
                    props.LeakfinderInfo.currentSaves.forEach(e => {
                        if (e.data.player_info[keyName] && e.data.player_info[keyName][0]) {
                            let data = e.data.player_info[keyName].find(e => e.name == j.name);
                            if (data && data.is_visible != j.is_visible) {
                                columns.push(undefined);
                            } else {
                                if (data) {
                                    columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(undefined);
                                }
                            }
                        } else {
                            columns.push(undefined)
                        }
                    })
                }
            }
            return columns
        })


        stats = stats.map(columns => {
            if (columns && columns.includes(undefined)) {
                return undefined;
            } else {
                return columns
            }
        })
        stats = stats.filter(e => e != undefined) // stats - это набор массивов (строк функций категорий, где 1 значение = 1 столбец)
        // здесь мы понимаем, что категории нету и её нужно вырезать

        s_2.push(stats);


        let ustats = [];

        let page_percents_category_total = [];

        if (stats && stats[0]) {
            for (let i = 0; i < stats[0].length; i++) {
                let endValue = 0;
                let countOfValues = 0;
                stats.forEach(e => {
                    countOfValues++;
                    endValue += e[i];
                })
                ustats.push(((endValue / countOfValues)));
                page_percents_category_total.push(endValue);
            }
        }

        u_2 = [...u_2, ...ustats];
        page_percents_total_2 = [...page_percents_total_2, ...page_percents_category_total];
    })

    let lngt_2 = 0;

    s_2.forEach(r => {
        lngt_2 += r.length;
    })

    u_2.forEach((r, i) => {
        let vl = 0;
        s_2.forEach(e => {
            e.forEach(p => {
                if (+p[i] == 100) {
                    vl += 1;
                }
            })
        })

        let vvv = ((vl / lngt) * 100);
        v_2.push(vvv <= 0 ? 0 : vvv) // TODO: получение процента по всем категориям (попадание/не попадание)
    })

    let categories_count_2 = s_2.filter(e => e[0]);
    let columns_count_2 = page_percents_total_2.length / categories_count_2.length;

    const saves_categories_2 = [];
    for (let i = 0; i < columns_count_2; i++) {
        saves_categories_2.push(page_percents_total_2.filter((value, index) => index % columns_count_2 === i));
    }

    saves_categories_2.forEach(sc => {
        b_2.push((sc.reduce((a, b) => +a + +b) / lngt_2))
    });








    // console.log(s, u, v, b, page_percents_total)
    // console.log(s_2, u_2, v_2, b_2, page_percents_total_2)
    // console.log(lngt, lngt_2)


    // ([Тотал Префлоп] * [Кол-во стат префлопа] + [Тотал Постфлоп] * [Кол-во стат постфлопа]) / [Кол-во стат префлопа] + [кол-во стат постфлопа]








    const handleCloseLineChartFullscreen = (close) => {
        setIsLineChartFullscreen(false);
        close()
    }

    return (
        <div style={{color: "white"}}>
            {
                // props.isLoading ? <div style={{
                //         color: "white",
                //         padding: "50px 50px 10px 50px",
                //         textAlign: "left",
                //         display: "grid",
                //         gridTemplateColumns: "60px 1fr"
                //     }}>
                //         <img src={OvalLoading} alt="loading" style={{width: "90px"}}/>
                //         <div style={{padding: "5px 0 0 20px"}}>
                //
                //             Идёт подсчёт данных
                //             <br/>
                //             Примерное время ожидания ~1-2 мин
                //         </div>
                //     </div> :
                <div>
                    <div style={{
                        display: "grid", gridTemplateColumns: "1fr 3fr", textAlign: "center",
                        padding: "50px 50px 10px 50px"
                    }}>
                        <div>
                            <div style={{marginTop: "-10px"}}>
                                <div style={{marginLeft: "17px", textAlign: "left"}}>
                                    Игрок
                                </div>
                                <div>
                                    <PlayerSelector options={props.LeakfinderInfo.current_players}
                                                    selectedPlayers={selectedPlayers}
                                                    handleSelectPlayer={handleSelectPlayer}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div id="get-advice" style={{
                                    padding: "10px 35px",
                                    textAlign: "left",
                                    color: "white",
                                    margin: "-15px 20px 0 0"
                                }}>
                                    <form className="form">
                                        <div className="formgroup">
                                            {props.LeakfinderInfo.savesList[0] ?
                                                <div style={{
                                                    fontSize: "14px",
                                                    lineHeight: "1.1em"
                                                }}>
                                                    <div style={{
                                                        display: "grid",
                                                        gridTemplateColumns: "33% 18% 18% 18% 5px 10% 5px 3%",
                                                        color: "#0073d7",
                                                        fontWeight: "bolder"
                                                    }}>
                                                        <span>Название</span>
                                                        <span>Дата сохранения</span>
                                                        <span>От</span>
                                                        <span>До</span>
                                                        <div></div>
                                                        <span>Комментарий</span>
                                                        <div></div>
                                                        <span></span>
                                                    </div>
                                                    {props.LeakfinderInfo.savesList.map(save => {
                                                        return <div key={save._id}>
                                                            <div style={{
                                                                display: "grid",
                                                                gridTemplateColumns: "33% 18% 18% 18% 5px 10% 5px 3%",
                                                            }}>
                                                                <span style={{
                                                                    cursor: "pointer",
                                                                    textDecoration: "underline",
                                                                    textDecorationColor: "#0073d7"
                                                                }}
                                                                      onClick={() => props.LeakfinderSetCurrentSave(save)}>{save.topic}</span>
                                                                <span>{moment(+save.timestamp_creation).format("DD.MM.YYYY HH:mm")}</span>
                                                                <span>{moment(+save.date_from).format("DD.MM.YYYY HH:mm")}</span>
                                                                <span>{moment(+save.date_to).format("DD.MM.YYYY HH:mm")}</span>
                                                                <div></div>
                                                                <Popup key={"analyzeOnes"}
                                                                       trigger={
                                                                           <span style={{cursor: "pointer", textDecoration: "underline"}}>📄 Edit</span>
                                                                       }
                                                                       modal
                                                                       nested
                                                                >
                                                                    {() => (
                                                                        <div>
                                                                            <div id="get-advice" className="user-page-popup"
                                                                                 style={{color: "white", textAlign: "center"}}>
                                                                                <form className="form">
                                                                                    <div style={{padding: "10px 20px 20px 20px", width: "600px"}}>
                                                                                        <div style={{fontWeight:"bolder", marginBottom: "20px"}}>Комментарий для сохранения {save.topic}</div>
                                                                                        <textarea defaultValue={save.comment}
                                                                                                  onBlur={(e) => props.SaveCommentToAnalyze(save._id, e.target.value)}
                                                                                                  rows={20}
                                                                                                  placeholder={`Введите комментарий для ${save.topic}`}
                                                                                                  style={{
                                                                                                      padding: "20px 30px",
                                                                                                      borderRadius: 0
                                                                                                  }}/>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Popup>
                                                                <div></div>
                                                                {
                                                                    props.AuthInfo.isLeakfinderAdmin ?
                                                                        <span className={"cursor-pointer"}
                                                                              onClick={() => props.LeakfinderDeleteSave(save._id)}>❌</span>
                                                                        :
                                                                        <span></span>
                                                                }
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            }

            <div>
                <Popup key={"analyzeAll"}
                       trigger={
                           <span style={{
                               cursor: "pointer",
                               height: "50px",
                               color: "#0072d5",
                               textAlign: "center",
                               margin: "12px 0 0 12px"
                           }}>
                            Произвести дополнительный расчёт
                        </span>}
                       modal
                       nested
                >
                    {() => (
                        <div style={{backgroundColor: "#1e1e25", borderRadius: "10px"}}>
                            <div id="get-advice"
                                 style={{color: "white", textAlign: "center"}}>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 2fr",
                                    padding: "50px 0 30px 20px",
                                    marginRight: "-70px"
                                }}>
                                    <div>
                                        <div style={{margin: "-10px 10px 10px 0"}}>
                                            <div style={{marginLeft: "17px", textAlign: "left"}}>
                                                Игрок
                                            </div>
                                            <div style={{width: "300px"}}>
                                                <PlayerSelector options={props.LeakfinderInfo.current_players}
                                                                selectedPlayers={selectedPlayers}
                                                                handleSelectPlayer={handleSelectPlayer}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{marginTop: "-10px"}}>
                                            <div style={{marginLeft: "17px", textAlign: "left"}}>
                                                Roomnames
                                            </div>
                                            <div style={{width: "300px"}}>
                                                <RoomNameSelector options={props.LeakfinderInfo.analyze_info.roomNames}
                                                                  handleSelectRoomNames={handleSelectRoomNames}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePicker
                                                    format="dd.MM.yyyy hh:mm:ss"
                                                    margin="normal"
                                                    label="От"
                                                    value={props.LeakfinderInfo.analyze_info.fromDate}
                                                    onChange={(e) => setFromDate(+e)}
                                                    style={{filter: "brightness(0) invert(1)", margin: "0 10px 0 20px"}}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePicker
                                                    format="dd.MM.yyyy hh:mm:ss"
                                                    margin="normal"
                                                    label="До"
                                                    value={props.LeakfinderInfo.analyze_info.toDate}
                                                    onChange={(e) => setToDate(+e)}
                                                    style={{filter: "brightness(0) invert(1)", margin: "0 20px 0 10px"}}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>

                                        {/*<div style={{*/}
                                        {/*    width: "384px",*/}
                                        {/*    cursor: "pointer",*/}
                                        {/*    color: "white",*/}
                                        {/*    textAlign: "center",*/}
                                        {/*    margin: "5px 106px",*/}
                                        {/*    padding: "5px 0",*/}
                                        {/*}}>*/}
                                        {/*    <RankSelector options={props.LeakfinderInfo.schemas}*/}
                                        {/*                  handleSelectRank={handleSelectRank}/>*/}
                                        {/*</div>*/}

                                        <div style={{
                                            cursor: "pointer",
                                            color: "white",
                                            textAlign: "center",
                                            margin: "40px 117px 40px 118px",
                                            padding: "5px 0"
                                        }}
                                             className={`${props.LeakfinderInfo.db_info.is_hero_need ? "green-back " : "red-back "}`}
                                             onClick={(e) => props.LeakfinderEditDbInfo("is_hero_need", !props.LeakfinderInfo.db_info.is_hero_need)}>
                                            {
                                                props.LeakfinderInfo.db_info.is_hero_need ? `"Hero" учитывается` : `Нажмите, чтобы учитывать "Hero"`
                                            }
                                        </div>

                                        <Popup key={"analyzeOne"}
                                               trigger={<div className={"orange-back"} style={{
                                                   cursor: "pointer",
                                                   color: "white",
                                                   textAlign: "center",
                                                   margin: "8px 117px 0 118px",
                                                   padding: "5px 0",
                                               }}
                                               >
                                                   Анализ
                                               </div>}
                                               modal
                                               nested
                                        >
                                            {() => (
                                                <div>
                                                    <div id="get-advice" className="user-page-popup"
                                                         style={{color: "white", textAlign: "center"}}>
                                                        <form className="form">
                                                            <div className="formgroup"
                                                                 style={{padding: "10px"}}>
                                                                Вы уверены в своём действии? При запуске анализа
                                                                кнопки
                                                                "Анализ" и "Анализ всех игроков" будут
                                                                недоступны на время, пока
                                                                идет анализ игрока
                                                            </div>
                                                            <div>
                                                                <input value={saveName}
                                                                       onChange={(e) => setSaveName(e.target.value)}
                                                                       type="text"
                                                                       placeholder={"Введите название для сохранений"}
                                                                       style={{
                                                                           width: "90%",
                                                                           padding: "5px",
                                                                           margin: "0 10px 10px 10px"
                                                                       }}/>
                                                            </div>
                                                            <div style={{
                                                                color: "rgba(255,68,89,0.6)",
                                                                margin: "0 10px 30px 10px",
                                                                fontSize: "13px"
                                                            }} className={"cursor-pointer"}
                                                                 onClick={() => props.LeakfinderRemoveSavesByName(saveName)}
                                                            >
                                                                Нажав сюда можно удалить все сохранения с
                                                                указанным названием
                                                            </div>
                                                            <div
                                                                className={"default-button green-back transition cursor-pointer"}
                                                                onClick={() => {
                                                                    props.LeakfinderAnalyzePlayer(props.LeakfinderInfo.analyze_info.roomNames, props.LeakfinderInfo.analyze_info.fromDate, props.LeakfinderInfo.analyze_info.toDate, selectedRanks, props.LeakfinderInfo.db_info, saveName);
                                                                }}
                                                            >
                                                                Запустить
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>


                                        {
                                            props.AuthInfo.isLeakfinderAdmin ?
                                                <Popup key={"analyzeAll"}
                                                       trigger={<div className={"orange-back"} style={{
                                                           cursor: "pointer",
                                                           color: "white",
                                                           textAlign: "center",
                                                           margin: "15px 117px 30px 118px",
                                                           padding: "5px 0"
                                                       }}>
                                                           Анализ всех игроков
                                                       </div>}
                                                       modal
                                                       nested
                                                >
                                                    {() => (
                                                        <div>
                                                            <div id="get-advice" className="user-page-popup"
                                                                 style={{color: "white", textAlign: "center"}}>
                                                                <form className="form">
                                                                    <div className="formgroup"
                                                                         style={{padding: "10px"}}>
                                                                        Вы уверены в своём действии? При запуске анализа
                                                                        всех игроков кнопки
                                                                        "Анализ" и "Анализ всех игроков" будут
                                                                        недоступны на время, пока
                                                                        идет обработка игроков (минимум 4 часа, если
                                                                        выбран большой период)
                                                                    </div>
                                                                    <div>
                                                                        <input value={saveName}
                                                                               onChange={(e) => setSaveName(e.target.value)}
                                                                               type="text"
                                                                               placeholder={"Введите название для сохранений"}
                                                                               style={{
                                                                                   width: "90%",
                                                                                   padding: "5px",
                                                                                   margin: "0 10px 10px 10px"
                                                                               }}/>
                                                                    </div>
                                                                    <div style={{
                                                                        color: "rgba(255,68,89,0.6)",
                                                                        margin: "0 10px 30px 10px",
                                                                        fontSize: "13px"
                                                                    }} className={"cursor-pointer"}
                                                                         onClick={() => props.LeakfinderRemoveSavesByName(saveName)}
                                                                    >
                                                                        Нажав сюда можно удалить все сохранения с
                                                                        указанным названием
                                                                    </div>
                                                                    <div
                                                                        className={"default-button green-back transition cursor-pointer"}
                                                                        onClick={() => props.LeakfinderAnalyzeAll(props.LeakfinderInfo.analyze_info.fromDate, props.LeakfinderInfo.analyze_info.toDate, selectedRanks, props.LeakfinderInfo.db_info, saveName)}
                                                                    >
                                                                        Запустить
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "99.5%", textAlign: "center",}}
            >
                {
                    <div style={{display: "grid", gridTemplateColumns: "1fr 25% 25% 40% 1fr", marginBottom: "1%"}}>
                        <div></div>
                        {
                            props.LeakfinderInfo.radarDatasetPreflop ? <div style={{margin: "20px 0 10px 40px"}}>
                                <Radar data={{
                                    labels: ['RFI', 'Fvs3bet', 'vs 1R', 'vs 1R+C', 'def BB', 'BvB'],
                                    datasets: props.LeakfinderInfo.radarDatasetPreflop,
                                }} options={options}/>

                            </div> : <div></div>
                        }

                        {
                            props.LeakfinderInfo.radarDatasetPostflop ? <div style={{margin: "20px 0 10px 40px"}}>
                                <Radar data={{
                                    labels: ['raiser oop', 'caller oop', 'raiser ip', 'caller ip', '3bet pot', 'BvB'],
                                    datasets: props.LeakfinderInfo.radarDatasetPostflop,
                                }} options={options}/>
                            </div> : <div></div>
                        }

                        {
                            props.LeakfinderInfo.player_info || props.LeakfinderInfo.currentSaves[0] ?
                                props.LeakfinderInfo.player_info ?
                                    <div style={{margin: "5% 0 0 20px"}}>
                                        <Line options={line_chart_options_not_full_screen} data={line_chart_data}/>
                                        <Popup key={"leak-show-line-chart-2"}
                                               modal
                                               contentStyle={{
                                                   width: "100%",
                                                   height: "100%",
                                                   margin: "0",
                                                   padding: 0,
                                                   backgroundColor: "#1e1e25"
                                               }}
                                               trigger={
                                                   <div style={{
                                                       color: 'rgb(154,154,154)',
                                                       margin: "1% 10%",
                                                       fontSize: "13px",
                                                       cursor: "pointer"
                                                   }}>Развернуть на весь экран
                                                   </div>
                                               }
                                               onOpen={() => setIsLineChartFullscreen(true)}
                                        >
                                            {(close) => (
                                                <div style={{backgroundColor: "#1e1e25"}}>

                                                    <div style={{textAlign: "right"}}>
                                                        <span style={{
                                                            color: 'white', backgroundColor: "gray", padding: "5px",
                                                            fontSize: "15px",
                                                            cursor: "pointer"
                                                        }} onClick={() => setRandColor(randColor + 1)}>Обновить цвета на другие случайные</span>
                                                        <span style={{
                                                            color: 'white',
                                                            backgroundColor: "rgb(206,77,77)",
                                                            padding: "5px",
                                                            fontSize: "15px",
                                                            cursor: "pointer"
                                                        }}
                                                              onClick={() => handleCloseLineChartFullscreen(close)}>Закрыть</span>
                                                    </div>
                                                    <div style={{margin: '2% 7% 0 5%'}}>
                                                        <Line options={line_chart_options_full_screen}
                                                              data={line_chart_data}/>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>

                                    </div>
                                    :
                                    <div style={{margin: "0 0 5% 0"}}>
                                        <Line options={line_chart_options_not_full_screen} data={line_chart_data}/>
                                        <Popup key={"leak-show-line-chart-2"}
                                               modal
                                               contentStyle={{
                                                   width: "100%",
                                                   height: "100%",
                                                   margin: "0",
                                                   padding: 0,
                                                   backgroundColor: "#1e1e25"
                                               }}
                                               trigger={
                                                   <div style={{
                                                       color: 'rgb(154,154,154)',
                                                       margin: "1% 10%",
                                                       fontSize: "13px",
                                                       cursor: "pointer"
                                                   }}>Развернуть на весь экран
                                                   </div>
                                               }
                                               onOpen={() => setIsLineChartFullscreen(true)}
                                        >
                                            {(close) => (
                                                <div style={{backgroundColor: "#1e1e25"}}>

                                                    <div style={{textAlign: "right"}}>
                                                        <span style={{
                                                            color: 'white', backgroundColor: "gray", padding: "5px",
                                                            fontSize: "15px",
                                                            cursor: "pointer"
                                                        }} onClick={() => setRandColor(randColor + 1)}>Обновить цвета на другие случайные</span>
                                                        <span style={{
                                                            color: 'white',
                                                            backgroundColor: "rgb(206,77,77)",
                                                            padding: "5px",
                                                            fontSize: "15px",
                                                            cursor: "pointer"
                                                        }}
                                                              onClick={() => handleCloseLineChartFullscreen(close)}>Закрыть</span>
                                                    </div>
                                                    <div style={{margin: '2% 7% 0 5%'}}>
                                                        <Line options={line_chart_options_full_screen}
                                                              data={line_chart_data}/>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                    </div>
                                : null
                        }

                        <div></div>
                    </div>
                }
            </div>


            {
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 250px 250px 250px 1fr",
                    padding: "3px 0",
                    backgroundColor: "#22222a",
                    borderRadius: "10px",
                    position: "sticky",
                    top: "0",
                    zIndex: "100",
                    marginTop: "3px"
                }}>
                    <div></div>
                    <div style={{padding: "0 10px 0 0"}}>
                        <RankSelector options={props.LeakfinderInfo.schemas}
                                      handleSelectRank={handleSelectRank}/>
                    </div>
                    <div
                        className={`default-button transition cursor-pointer ${selectedFlop == 1 ? "green-back" : "default-back"}`}
                        onClick={() => handleSetSelectedFlop(1)}>
                        PRE-FLOP
                    </div>
                    <div
                        className={`default-button transition cursor-pointer ${selectedFlop == 2 ? "green-back" : "default-back"}`}
                        onClick={() => handleSetSelectedFlop(2)}>
                        POST-FLOP
                    </div>
                    <div></div>
                </div>
            }

            <div style={{textAlign: "center"}}>
                <span style={{color: "white", cursor: "pointer"}}
                      onClick={() => handleExportData(export_data, export_data_headers, export_room_names)}>Выгрузить данные
                </span>
            </div>

            {
                props.LeakfinderInfo.player_info || props.LeakfinderInfo.currentSaves[0] ?
                    <Table selectedRanks={selectedRanks} selectedFlop={selectedFlop} {...props} vv={v} bb={b} vv_2={v_2} bb_2={b_2} lngt_1={lngt} lngt_2={lngt_2}/>
                    : null
            }

        </div>
    )
}
const Table = (props) => {

    let schema = {};
    let pre_flop_total_percent_etalon = [0, 100];
    let post_flop_total_percent_etalon = [0, 100];

    if (props.selectedRanks && props.selectedRanks[0]) {
        schema = {...schema, ...props.selectedRanks[0].schema_pre_flop, ...props.selectedRanks[0].schema_post_flop};
        if (props.selectedRanks[0].pre_flop_total_percent_etalon) pre_flop_total_percent_etalon = props.selectedRanks[0].pre_flop_total_percent_etalon;
        if (props.selectedRanks[0].post_flop_total_percent_etalon) post_flop_total_percent_etalon = props.selectedRanks[0].post_flop_total_percent_etalon;
    } else if (props.LeakfinderInfo.player_info) {
        schema = {...schema, ...props.LeakfinderInfo.player_info, ...props.LeakfinderInfo.sub_player_info}
        if (props.LeakfinderInfo.pre_flop_total_percent_etalon) pre_flop_total_percent_etalon = props.LeakfinderInfo.pre_flop_total_percent_etalon;
        if (props.LeakfinderInfo.post_flop_total_percent_etalon) post_flop_total_percent_etalon = props.LeakfinderInfo.post_flop_total_percent_etalon;
    } else if (props.LeakfinderInfo.currentSaves && props.LeakfinderInfo.currentSaves[0]) {
        schema = {...schema, ...props.LeakfinderInfo.currentSaves[0].data.player_info, ...props.LeakfinderInfo.currentSaves[0].data.sub_player_info}
        if (props.LeakfinderInfo.currentSaves[0].data.pre_flop_total_percent_etalon) pre_flop_total_percent_etalon = props.LeakfinderInfo.currentSaves[0].data.pre_flop_total_percent_etalon;
        if (props.LeakfinderInfo.currentSaves[0].data.post_flop_total_percent_etalon) post_flop_total_percent_etalon = props.LeakfinderInfo.currentSaves[0].data.post_flop_total_percent_etalon;
    } else {
        schema = {...schema, ...defaultSchema, ...defaultSchemaPostFlop}
    }

    const getColoredPercentEtalon = (keyName, value, standards, hard_percent, value_2, lngt_1, lngt_2) => {
        let different;
        let color = '#ff3305';

        if (standards[0].length <= 0) standards[0] = 0;
        if (standards[1].length <= 0) standards[1] = 0;

        if (+value > standards[0]) {
            different = +value - standards[1]
        } else {
            different = +value - standards[0]
        }

        let etalon = standards.reduce(function (a, c) {
            return Math.abs(a - +value) < Math.abs(c - +value) ? a : c;
        })
        let mod_dif = Math.abs(different / etalon) * 100;

        if (+value > standards[0] && +value < standards[1]) {
            color = '#0aff23';
        } else if (mod_dif <= 5) {
            color = '#0aff23';
        } else if (mod_dif <= 10) {
            color = '#4cb526';
        } else if (mod_dif <= 15) {
            color = '#def016';
        } else if (mod_dif <= 20) {
            color = '#ff9b05';
        } else {
            color = '#ff3305';
        }

// ([Тотал Префлоп] * [Кол-во стат префлопа] + [Тотал Постфлоп] * [Кол-во стат постфлопа]) / ([Кол-во стат префлопа] + [кол-во стат постфлопа])

        let total_flops = (value * lngt_1 + value_2 * lngt_2) / (lngt_1 + lngt_2);

        return keyName == "General" ? <div style={{marginTop: "-42px", marginBottom: "24px", color, fontSize: "12px"}}>{value.toFixed(1)}% <span style={{color: "white", fontSize: "10px"}}>{hard_percent.toFixed(1)}% <span style={{color: "#5fb5ff"}}>{total_flops.toFixed(1)}%</span></span></div>
            : <div style={{marginTop: "-20px", marginBottom: "2px", color, fontSize: "12px"}}>{value.toFixed(1)}% <span style={{color: "white", fontSize: "10px"}}>{hard_percent.toFixed(1)}% <span style={{color: "#5fb5ff"}}>{total_flops.toFixed(1)}%</span></span></div>
    }

    return <div>
        <Header currentSaves={props.LeakfinderInfo.currentSaves} selectedFlop={props.selectedFlop}
                LeakfinderSpliceSave={props.LeakfinderSpliceSave}
                player_info={props.LeakfinderInfo.player_info} sub_player_info={props.LeakfinderInfo.sub_player_info}/>
        {
            (function () {
                return Object.keys(props.selectedFlop == 1 ? defaultSchema : defaultSchemaPostFlop).map((keyName, i) => { // идём по категориям схемы
                    // i = индекс категории
                    if (!schema[keyName]) return;
                    let stats = (props.selectedFlop == 1 ? schema[keyName] : schema[keyName]).map((j, i) => { // идём по объектам категории схемы
                        if (!j.is_visible) return undefined;

                        let columns = [];

                        let getPercent = (v, s) => {
                            let value = +(+v).toFixed(2);
                            let standards = [0, 0];
                            let different = 0;

                            if (typeof s == "object") {
                                standards[0] = +s[0];
                                standards[1] = +s[1];
                            } else if (s.includes("&")) {
                                standards = [...s.split("&")]
                            } else {
                                standards[0] = +s;
                                standards[1] = +s;
                            }

                            //standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
                            if (+standards[0].length <= 0) standards[0] = 0;
                            if (+standards[1].length <= 0) standards[1] = 0;

                            // Вычисляем разницу между верхней и нижней границей эталона
                            let etalon_gape = +standards[1] - +standards[0];

                            // для каждого значения etalon_gape определяем коэффициент для дальнейших расчетов
                            let keff = 0;
                            if (etalon_gape > 0 && etalon_gape <= 3) {
                                keff = 13;
                            } else if (etalon_gape > 3 && etalon_gape <= 5) {
                                keff = 8;
                            } else if (etalon_gape > 5 && etalon_gape <= 10) {
                                keff = 5;
                            } else if (etalon_gape > 10) {
                                keff = 3;
                            }

                            // Далее каждый процент расхождения умножаем на этот коэфф и тем самым получаем %попадания
                            let diff = 0;
                            if (+value < +standards[0]) {
                                diff = +standards[0] - value;
                            }
                            else if (+value > +standards[1]) {
                                diff = +value - +standards[1];
                            }

                            //  попадание = 100 - расхождение*КОЭФФ;
                            //      ЕСЛИ попадание < 0 то попадание = 0;
                            let hit = 100 - diff * keff;
                            if (hit < 0) hit = 0;

                            // console.log(`========================================`)
                            // console.log(`etalon_gape = ${etalon_gape}`)
                            // console.log(`keff = ${keff}`)
                            // console.log(`diff = ${diff.toFixed(1)}`)
                            // console.log(`hit = ${hit.toFixed(1)}`)


                            return +hit
                        }

                        if (props.LeakfinderInfo.player_info) {
                            if (props.selectedFlop == 1) {
                                if (props.LeakfinderInfo.player_info[keyName] && props.LeakfinderInfo.player_info[keyName][0]) {
                                    let data = props.LeakfinderInfo.player_info[keyName].find(e => e.name == j.name);
                                    if (data) columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(getPercent(0, "0"))
                                }

                            } else {
                                if (props.LeakfinderInfo.sub_player_info[keyName] && props.LeakfinderInfo.sub_player_info[keyName][0]) {
                                    let data = props.LeakfinderInfo.sub_player_info[keyName].find(e => e.name == j.name);
                                    if (data) columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(getPercent(0, "0"))
                                }


                            }
                        }
                        if (props.LeakfinderInfo.currentSaves[0]) {
                            if (props.selectedFlop == 1) {
                                props.LeakfinderInfo.currentSaves.forEach(e => {
                                    if (e.data.player_info[keyName] && e.data.player_info[keyName][0]) {
                                        let data = e.data.player_info[keyName].find(e => e.name == j.name);
                                        if (data) columns.push(getPercent(data.value, schema[keyName][i].standards));
                                    } else {
                                        columns.push(getPercent(0, "0"))
                                    }

                                })
                            } else {
                                props.LeakfinderInfo.currentSaves.forEach(e => {
                                    if (e.data.sub_player_info[keyName] && e.data.sub_player_info[keyName][0]) {
                                        let data = e.data.sub_player_info[keyName].find(e => e.name == j.name);
                                        if (data) columns.push(getPercent(data.value, schema[keyName][i].standards))
                                    } else {
                                        columns.push(getPercent(0, "0"))
                                    }

                                })
                            }
                        }
                        return columns
                    })

                    stats = stats.filter(e => e != undefined)

                    let ustats = [];

                    if (stats && stats[0]) {
                        for (let i = 0; i < stats[0].length; i++) {
                            let endValue = 0;
                            let countOfValues = 0;
                            stats.forEach(e => {
                                countOfValues++;
                                endValue += e[i];
                            })
                            ustats.push(((endValue / countOfValues)).toFixed(1))
                        }
                    }

                    let isNeedHideCategory = false;

                    if (ustats.length == 0 && !coloredCategories.includes(keyName) &&
                        keyName != "TOTAL PREFLOP" && keyName != "TOTAL POSTFLOP") {
                        isNeedHideCategory = true;
                    }

                    return isNeedHideCategory ? null : <div key={`${keyName} ${i} ${randomInteger(1, 9999999999)}`}>
                        <div style={{
                            textAlign: "left",
                            color: "#0073d7",
                            fontWeight: "bolder",
                            borderTop: "1px #0073d7 solid",
                            display: "grid",
                            gridTemplateColumns: `${props.selectedFlop == 1 ? "300px" : "400px"} 9% ${ustats[0] ? "9%".repeat(ustats.length) : ""}`
                        }}>
                            <div>
                                <span
                                    style={{color: coloredCategories.includes(keyName) ? "#eed245" : keyName == "TOTAL PREFLOP" || keyName == "TOTAL POSTFLOP" ? "#e86028" : "#0073d7"}}>{keyName}</span>
                            </div>
                            <div style={{color: "white", fontWeight: "initial"}}>
                                {keyName == "TOTAL PREFLOP" ? `${pre_flop_total_percent_etalon[0]}-${pre_flop_total_percent_etalon[1]}` : ""}
                                {keyName == "TOTAL POSTFLOP" ? `${post_flop_total_percent_etalon[0]}-${post_flop_total_percent_etalon[1]}` : ""}
                            </div>

                            {
                                ustats.map((r, j) => {
                                    return <div
                                        style={{
                                            color: "white",
                                            fontWeight: "normal"
                                        }}>
                                        {keyName == "General stat" ?
                                                getColoredPercentEtalon(keyName, props.bb[j], pre_flop_total_percent_etalon, props.vv[j], props.bb_2[j], props.lngt_1, props.lngt_2)
                                            : ""}
                                        {keyName == "General" ?
                                                getColoredPercentEtalon(keyName, props.bb[j],post_flop_total_percent_etalon, props.vv[j], props.bb_2[j], props.lngt_1, props.lngt_2)
                                            : ""}
                                        {r <= 0 ? 0 : r}%</div>
                                })
                            }
                        </div>
                        {(props.selectedFlop == 1 ? defaultSchema[keyName] : defaultSchemaPostFlop[keyName]).map((e, j) => {

                            let standards = "-";
                            let index = schema[keyName].findIndex(iii => iii.name == e.name);
                            if (index != -1) standards = schema[keyName][index].standards;


                            return <Row name={e.name} color={e.color} value={e.value} standards={standards}
                                        different={e.different} i={j} formula={e.formula}
                                        schema={schema}
                                        keyName={keyName}
                                        currentSaves={props.LeakfinderInfo.currentSaves}
                                        selectedFlop={props.selectedFlop}
                                        player_info={props.LeakfinderInfo.player_info}
                                        sub_player_info={props.LeakfinderInfo.sub_player_info}

                            />
                        })}
                    </div>
                })
            }())
        }
    </div>
}
const Header = (props) => {
    // <Header currentSaves={props.LeakfinderInfo.currentSaves} selectedFlop={selectedFlop}
    //         player_info={props.LeakfinderInfo.player_info} sub_player_info={props.LeakfinderInfo.sub_player_info}/>

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: `${props.selectedFlop == 1 ? "300px" : "400px"} 9% ${props.player_info ? " 9% " : ""} ${props.currentSaves[0] ? " 9% ".repeat(props.currentSaves.length) : ""}`,
            color: "#0073d7",
            fontWeight: "bolder", marginTop: "40px",
            textAlign: "left"
        }}>
            <span>Название</span>
            {/*<span>Значение</span>*/}
            <span>Эталон</span>
            {
                props.player_info ? <span>Анализ</span> : null
            }
            {
                props.currentSaves[0] ?
                    props.currentSaves.map((e, g) => <span title={e.topic}>Save {g + 1} <span
                        className={"cursor-pointer"} onClick={() => props.LeakfinderSpliceSave(g)}>❌</span></span>)
                    : null
            }
            {/*<span>Разница</span>*/}
            {/*<span>Выборка</span>*/}
        </div>
    )
}
const Row = (props) => {
    let columns = [];

    let isExist = true;
    let standards = props.standards;
    let standardsArr = [0, 0];

    let data = props.schema[props.keyName].find(e => e.name == props.name);
    if (!data || !data.is_visible) isExist = false;
    let evStandards = data.evStandards;

    if (data && typeof data.standards == "object") {
        standards = data.standards[1] ? `${data.standards[0]}-${data.standards[1]}` : `${data.standards[0]}`
        standardsArr = data.standards[1] ? [data.standards[0], data.standards[1]] : [data.standards[0], data.standards[0]]
    } else if (props.standards.includes("&")) {
        let s = props.standards.split("&");
        standards = `${s[0]}-${s[1]}`;
        standardsArr = [s[0], s[1]];
    }

    const getColor = (value, standards) => {
        let step;
        let color = '#ff3305';

        //standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
        if (standards[0].length <= 0) standards[0] = 0;
        if (standards[1].length <= 0) standards[1] = 0;

        //ищем шаг в 5% от мин.значения эталона
        step = standards[0] * 0.05;

        //получаем значение в etalon из standards которое ближе к показателю value
        let etalon = standards.reduce(function (a, c) {
            return Math.abs(a - value) < Math.abs(c - value) ? a : c;
        })
        let mod_dif = Math.abs(value - etalon);

        if (value >= standards[0] && value <= standards[1]) {
            color = '#7FFF00'; //зеленый
        } else if (mod_dif <= step) {
            color = '#FFFF00'; //желтый
        } else {
            color = '#FF4500'; //красный
        }
        return color;
    }

    let hands = [
        {name: "AA", value: 0},
        {name: "AKs", value: 0},
        {name: "AQs", value: 0},
        {name: "AJs", value: 0},
        {name: "ATs", value: 0},
        {name: "A9s", value: 0},
        {name: "A8s", value: 0},
        {name: "A7s", value: 0},
        {name: "A6s", value: 0},
        {name: "A5s", value: 0},
        {name: "A4s", value: 0},
        {name: "A3s", value: 0},
        {name: "A2s", value: 0},
        {name: "AKo", value: 0},
        {name: "KK", value: 0},
        {name: "KQs", value: 0},
        {name: "KJs", value: 0},
        {name: "KTs", value: 0},
        {name: "K9s", value: 0},
        {name: "K8s", value: 0},
        {name: "K7s", value: 0},
        {name: "K6s", value: 0},
        {name: "K5s", value: 0},
        {name: "K4s", value: 0},
        {name: "K3s", value: 0},
        {name: "K2s", value: 0},
        {name: "AQo", value: 0},
        {name: "KQo", value: 0},
        {name: "QQ", value: 0},
        {name: "QJs", value: 0},
        {name: "QTs", value: 0},
        {name: "Q9s", value: 0},
        {name: "Q8s", value: 0},
        {name: "Q7s", value: 0},
        {name: "Q6s", value: 0},
        {name: "Q5s", value: 0},
        {name: "Q4s", value: 0},
        {name: "Q3s", value: 0},
        {name: "Q2s", value: 0},
        {name: "AJo", value: 0},
        {name: "KJo", value: 0},
        {name: "QJo", value: 0},
        {name: "JJ", value: 0},
        {name: "JTs", value: 0},
        {name: "J9s", value: 0},
        {name: "J8s", value: 0},
        {name: "J7s", value: 0},
        {name: "J6s", value: 0},
        {name: "J5s", value: 0},
        {name: "J4s", value: 0},
        {name: "J3s", value: 0},
        {name: "J2s", value: 0},
        {name: "ATo", value: 0},
        {name: "KTo", value: 0},
        {name: "QTo", value: 0},
        {name: "JTo", value: 0},
        {name: "TT", value: 0},
        {name: "T9s", value: 0},
        {name: "T8s", value: 0},
        {name: "T7s", value: 0},
        {name: "T6s", value: 0},
        {name: "T5s", value: 0},
        {name: "T4s", value: 0},
        {name: "T3s", value: 0},
        {name: "T2s", value: 0},
        {name: "A9o", value: 0},
        {name: "K9o", value: 0},
        {name: "Q9o", value: 0},
        {name: "J9o", value: 0},
        {name: "T9o", value: 0},
        {name: "99", value: 0},
        {name: "98s", value: 0},
        {name: "97s", value: 0},
        {name: "96s", value: 0},
        {name: "95s", value: 0},
        {name: "94s", value: 0},
        {name: "93s", value: 0},
        {name: "92s", value: 0},
        {name: "A8o", value: 0},
        {name: "K8o", value: 0},
        {name: "Q8o", value: 0},
        {name: "J8o", value: 0},
        {name: "T8o", value: 0},
        {name: "98o", value: 0},
        {name: "88", value: 0},
        {name: "87s", value: 0},
        {name: "86s", value: 0},
        {name: "85s", value: 0},
        {name: "84s", value: 0},
        {name: "83s", value: 0},
        {name: "82s", value: 0},
        {name: "A7o", value: 0},
        {name: "K7o", value: 0},
        {name: "Q7o", value: 0},
        {name: "J7o", value: 0},
        {name: "T7o", value: 0},
        {name: "97o", value: 0},
        {name: "87o", value: 0},
        {name: "77", value: 0},
        {name: "76s", value: 0},
        {name: "75s", value: 0},
        {name: "74s", value: 0},
        {name: "73s", value: 0},
        {name: "72s", value: 0},
        {name: "A6o", value: 0},
        {name: "K6o", value: 0},
        {name: "Q6o", value: 0},
        {name: "J6o", value: 0},
        {name: "T6o", value: 0},
        {name: "96o", value: 0},
        {name: "86o", value: 0},
        {name: "76o", value: 0},
        {name: "66", value: 0},
        {name: "65s", value: 0},
        {name: "64s", value: 0},
        {name: "63s", value: 0},
        {name: "62s", value: 0},
        {name: "A5o", value: 0},
        {name: "K5o", value: 0},
        {name: "Q5o", value: 0},
        {name: "J5o", value: 0},
        {name: "T5o", value: 0},
        {name: "95o", value: 0},
        {name: "85o", value: 0},
        {name: "75o", value: 0},
        {name: "65o", value: 0},
        {name: "55", value: 0},
        {name: "54s", value: 0},
        {name: "53s", value: 0},
        {name: "52s", value: 0},
        {name: "A4o", value: 0},
        {name: "K4o", value: 0},
        {name: "Q4o", value: 0},
        {name: "J4o", value: 0},
        {name: "T4o", value: 0},
        {name: "94o", value: 0},
        {name: "84o", value: 0},
        {name: "74o", value: 0},
        {name: "64o", value: 0},
        {name: "54o", value: 0},
        {name: "44", value: 0},
        {name: "43s", value: 0},
        {name: "42s", value: 0},
        {name: "A3o", value: 0},
        {name: "K3o", value: 0},
        {name: "Q3o", value: 0},
        {name: "J3o", value: 0},
        {name: "T3o", value: 0},
        {name: "93o", value: 0},
        {name: "83o", value: 0},
        {name: "73o", value: 0},
        {name: "63o", value: 0},
        {name: "53o", value: 0},
        {name: "43o", value: 0},
        {name: "33", value: 0},
        {name: "32s", value: 0},
        {name: "A2o", value: 0},
        {name: "K2o", value: 0},
        {name: "Q2o", value: 0},
        {name: "J2o", value: 0},
        {name: "T2o", value: 0},
        {name: "92o", value: 0},
        {name: "82o", value: 0},
        {name: "72o", value: 0},
        {name: "62o", value: 0},
        {name: "52o", value: 0},
        {name: "42o", value: 0},
        {name: "32o", value: 0},
        {name: "22", value: 0}
    ]

    let getMatrix = (arr) => {
        if (!arr || !arr[0]) return null;

        let matrix = JSON.parse(JSON.stringify(hands));

        arr.forEach(hand => {
            let indexOfHandInMatrix = matrix.findIndex(me => me.name == hand.hole_cards);
            if (indexOfHandInMatrix != -1) {
                matrix[indexOfHandInMatrix].value = hand.count;
            }
        })

        return matrix;
    }

    if (props.player_info) {
        if (props.selectedFlop == 1) {
            let data = props.player_info[props.keyName].find(e => e.name == props.name);
            if (data) {
                columns.push({
                    value: data.value,
                    color: data.color,
                    formula: data.formula,
                    matrix_check: getMatrix(data.matrix_check),
                    matrix_raise: getMatrix(data.matrix_raise),
                    matrix_3bet: getMatrix(data.matrix_3bet),
                    matrix_fold: getMatrix(data.matrix_fold),
                    matrix_open: getMatrix(data.matrix_open),
                    matrix_vpip: getMatrix(data.matrix_vpip),
                    matrix_call: getMatrix(data.matrix_call),
                    matrix_4bet: getMatrix(data.matrix_4bet), ev: data.ev,

                    matrix_limp: getMatrix(data.matrix_limp),
                    matrix_open_raise: getMatrix(data.matrix_open_raise),
                    matrix_isolate: getMatrix(data.matrix_isolate),
                    matrix_squeeze: getMatrix(data.matrix_squeeze),
                })
            } else {
                columns.push({
                    value: "----",
                    color: "#111111",
                    formula: "",
                    matrix_check: null,
                    matrix_raise: null,
                    matrix_3bet: null,
                    matrix_fold: null,
                    matrix_open: null,
                    matrix_vpip: null,
                    matrix_call: null,
                    matrix_4bet: null,
                    ev: null,

                    matrix_limp: null,
                    matrix_open_raise: null,
                    matrix_isolate: null,
                    matrix_squeeze: null,
                })
            }
        } else {
            let data = props.sub_player_info[props.keyName].find(e => e.name == props.name);
            if (data) {
                columns.push({
                    value: data.value,
                    color: data.color,
                    formula: data.formula,
                    matrix_check: getMatrix(data.matrix_check),
                    matrix_raise: getMatrix(data.matrix_raise),
                    matrix_3bet: getMatrix(data.matrix_3bet),
                    matrix_fold: getMatrix(data.matrix_fold),
                    matrix_open: getMatrix(data.matrix_open),
                    matrix_vpip: getMatrix(data.matrix_vpip),
                    matrix_call: getMatrix(data.matrix_call),
                    matrix_4bet: getMatrix(data.matrix_4bet), ev: data.ev,

                    matrix_limp: getMatrix(data.matrix_limp),
                    matrix_open_raise: getMatrix(data.matrix_open_raise),
                    matrix_isolate: getMatrix(data.matrix_isolate),
                    matrix_squeeze: getMatrix(data.matrix_squeeze),
                })
            } else {
                columns.push({
                    value: "----",
                    color: "#111111",
                    formula: "",
                    matrix_check: null,
                    matrix_raise: null,
                    matrix_3bet: null,
                    matrix_fold: null,
                    matrix_open: null,
                    matrix_vpip: null,
                    matrix_call: null,
                    matrix_4bet: null,
                    ev: null,

                    matrix_limp: null,
                    matrix_open_raise: null,
                    matrix_isolate: null,
                    matrix_squeeze: null,
                })
            }
        }
    }
    if (props.currentSaves[0]) {
        if (props.selectedFlop == 1) {
            props.currentSaves.forEach(e => {
                if (e.data.player_info[props.keyName] && e.data.player_info[props.keyName][0]) {
                    let data = e.data.player_info[props.keyName].find(e => e.name == props.name);
                    if (data) {
                        columns.push({
                            value: data.value,
                            color: data.color,
                            formula: data.formula,
                            matrix_check: getMatrix(data.matrix_check),
                            matrix_raise: getMatrix(data.matrix_raise),
                            matrix_3bet: getMatrix(data.matrix_3bet),
                            matrix_fold: getMatrix(data.matrix_fold),
                            matrix_open: getMatrix(data.matrix_open),
                            matrix_vpip: getMatrix(data.matrix_vpip),
                            matrix_call: getMatrix(data.matrix_call),
                            matrix_4bet: getMatrix(data.matrix_4bet), ev: data.ev,

                            matrix_limp: getMatrix(data.matrix_limp),
                            matrix_open_raise: getMatrix(data.matrix_open_raise),
                            matrix_isolate: getMatrix(data.matrix_isolate),
                            matrix_squeeze: getMatrix(data.matrix_squeeze),
                        })
                    } else {
                        columns.push({
                            value: "----",
                            color: "#111111",
                            formula: "",
                            matrix_check: null,
                            matrix_raise: null,
                            matrix_3bet: null,
                            matrix_fold: null,
                            matrix_open: null, matrix_vpip: null, matrix_call: null, matrix_4bet: null, ev: null,

                            matrix_limp: null,
                            matrix_open_raise: null,
                            matrix_isolate: null,
                            matrix_squeeze: null,
                        })
                    }
                } else {
                    columns.push({
                        value: "----",
                        color: "#111111",
                        formula: "",
                        matrix_check: null,
                        matrix_raise: null,
                        matrix_3bet: null,
                        matrix_fold: null,
                        matrix_open: null,
                        matrix_vpip: null,
                        matrix_call: null,
                        matrix_4bet: null,
                        ev: null,

                        matrix_limp: null,
                        matrix_open_raise: null,
                        matrix_isolate: null,
                        matrix_squeeze: null,
                    })
                }
            })
        } else {
            props.currentSaves.forEach(e => {
                if (e.data.sub_player_info[props.keyName] && e.data.sub_player_info[props.keyName][0]) {
                    let data = e.data.sub_player_info[props.keyName].find(e => e.name == props.name);
                    if (data) {
                        columns.push({
                            value: data.value,
                            color: data.color,
                            formula: data.formula,
                            matrix_check: getMatrix(data.matrix_check),
                            matrix_raise: getMatrix(data.matrix_raise),
                            matrix_3bet: getMatrix(data.matrix_3bet),
                            matrix_fold: getMatrix(data.matrix_fold),
                            matrix_open: getMatrix(data.matrix_open),
                            matrix_vpip: getMatrix(data.matrix_vpip),
                            matrix_call: getMatrix(data.matrix_call),
                            matrix_4bet: getMatrix(data.matrix_4bet), ev: data.ev,

                            matrix_limp: getMatrix(data.matrix_limp),
                            matrix_open_raise: getMatrix(data.matrix_open_raise),
                            matrix_isolate: getMatrix(data.matrix_isolate),
                            matrix_squeeze: getMatrix(data.matrix_squeeze),
                        })
                    } else {
                        columns.push({
                            value: "----",
                            color: "#111111",
                            formula: "",
                            matrix_check: null,
                            matrix_raise: null,
                            matrix_3bet: null,
                            matrix_fold: null,
                            matrix_open: null, matrix_vpip: null, matrix_call: null, matrix_4bet: null, ev: null,

                            matrix_limp: null,
                            matrix_open_raise: null,
                            matrix_isolate: null,
                            matrix_squeeze: null,
                        })
                    }
                } else {
                    columns.push({
                        value: "----",
                        color: "#111111",
                        formula: "",
                        matrix_check: null,
                        matrix_raise: null,
                        matrix_3bet: null,
                        matrix_fold: null,
                        matrix_open: null,
                        matrix_vpip: null,
                        matrix_call: null,
                        matrix_4bet: null,

                        matrix_limp: null,
                        matrix_open_raise: null,
                        matrix_isolate: null,
                        matrix_squeeze: null,
                        ev: null
                    })
                }

            })
        }
    }

    return (
        (columns[0] && columns[0].value == "----") || !isExist ? null :
            <div style={{
                display: "grid",
                gridTemplateColumns: `${props.selectedFlop == 1 ? "300px" : "400px"} 9% ${columns[0] ? "9%".repeat(columns.length) : ""}`,
                backgroundColor: (+props.i) % 2 == 0 ? "#1d1d25" : "initial", textAlign: "left"
            }}>
                <span>{props.name}</span>
                <span>{standards}</span>
                {
                    columns.map((e, g) => {
                            let matrix_list = [
                                {name: "matrix_check", display_name: "CHECK", color: "blue"},
                                {name: "matrix_call", display_name: "CALL", color: "blue"},
                                {name: "matrix_limp", display_name: "LIMP", color: "blue"},
                                {name: "matrix_open_raise", display_name: "OPEN RAISE", color: "green"},
                                {name: "matrix_isolate", display_name: "ISOLATE", color: "green"},
                                {name: "matrix_3bet", display_name: "3BET", color: "green"},
                                {name: "matrix_squeeze", display_name: "SQUEEZE", color: "green"},
                                {name: "matrix_4bet", display_name: "4BET", color: "green"},
                                {name: "matrix_vpip", display_name: "VPIP", color: "green"},
                                {name: "matrix_fold", display_name: "FOLD", color: "red"},
                                // { name: "matrix_open",  display_name: "OPEN",  color: "green" },
                                // { name: "matrix_raise", display_name: "RAISE", color: "green" },
                            ];

                            return e.matrix_check || e.matrix_call || e.matrix_limp || e.matrix_open_raise ||
                            e.matrix_isolate || e.matrix_3bet || e.matrix_squeeze || e.matrix_4bet
                            || e.matrix_vpip || e.matrix_fold ?
                                <Popup key={`lk-leakfinder matrix ${g + 1} ${props.name} ${randomInteger(1, 9999999999)}`}
                                       trigger={
                                           <TT title={e.formula}>
                                <span key={`${e.value} a ${g} ${randomInteger(1, 9999999999)}`}
                                      style={{color: getColor(e.value, standardsArr), cursor: "pointer"}}>
                                    {`${e.value == "----" ? "" : e.value ? `${e.value.toFixed(2)}` : 0}`}
                                    <span style={{
                                        fontSize: "11px",
                                        color: getColor(e.ev, evStandards)
                                    }}>{e.ev ? ` ${e.ev.toFixed(2)}` : ""}</span>
                                </span>
                                           </TT>
                                       }
                                       modal
                                >
                                    {() => (
                                        <div>
                                            <div id="get-advice" style={{
                                                backgroundColor: "rgba(21,21,33,0.8)",
                                                padding: "10px 50px 30px 50px",
                                                textAlign: "left",
                                                color: "white",
                                                width: "1076px"
                                            }}>
                                                <form className="form">
                                                    <div style={{
                                                        textAlign: "center",
                                                        fontSize: "16px",
                                                        fontWeight: "bolder"
                                                    }}>{props.name}</div>


                                                    <div className="formgroup"
                                                         style={{
                                                             display: 'flex',
                                                             flexWrap: 'wrap',
                                                             maxWidth: '1076px'
                                                         }}
                                                    >
                                                        {
                                                            matrix_list.map(matrix => {
                                                                return e[matrix.name] && e[matrix.name][0] ?
                                                                    <div style={{
                                                                        margin: "10px 0 10px 0",
                                                                        flex: '1 0 calc(50% - 10px)'
                                                                    }}>
                                                                        <div style={{
                                                                            fontWeight: "bolder",
                                                                            fontSize: "14px",
                                                                            textAlign: "center"
                                                                        }}>
                                                                            {matrix.display_name}
                                                                        </div>
                                                                        <div style={{
                                                                            height: "100%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center"
                                                                        }}>
                                                                            <Matrix data={e[matrix.name]}
                                                                                    color={matrix.color}/>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            })
                                                        }
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </Popup> :

                                <TT title={e.formula}>
                                <span key={`${e.value} a ${g} ${randomInteger(1, 9999999999)}`}
                                      style={{color: getColor(e.value, standardsArr), cursor: "pointer"}}
                                >
                                    {`${e.value == "----" ? "" : e.value ? `${e.value.toFixed(2)} ${e.ev ? `(${e.ev.toFixed(2)}) ` : ""}` : 0}`}
                                </span>
                                </TT>
                        }
                    )
                }
            </div>
    )
}
