import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import UsersState from "./usersState";
import {SalaryAdminGetUsersState} from "../../../../redux/reducers/salaryReducer";

class SalaryUsersStateContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Finances | Отчёт о сотрудниках");
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        return (
            <UsersState {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    SalaryInfo: state.salaryReducer
})


export default compose(
    connect(mapStateToProps, {
        SalaryAdminGetUsersState
    }),
    withRouter
)(SalaryUsersStateContainer)