import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import AuditorTasks from "./auditorTasks";
import {
    AdminAddSecondSupportToTask,
    AdminChangeFollowStatus,
    AdminDeleteTask,
    AdminSortTasks,
    GetSupportsList
} from "../../../../redux/reducers/auditorReducer";

class AuditorTasksContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Список тасков")
        this.props.GetSupportsList();
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <AuditorTasks {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer,
    AuditorInfo: state.auditorReducer
})

export default compose(
    connect(mapStateToProps, {
        AdminChangeFollowStatus, AdminDeleteTask, AdminSortTasks, GetSupportsList, AdminAddSecondSupportToTask
    }),
    withRouter
)(AuditorTasksContainer)