import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";

export default function SupportsEditor(props) {
    return (
        <div className={"supports-editor-page"}>
            {
                props.SupportsInfo.accessesList.map(access => <AccessBlock key={access.name}
                                                                           {...props}
                                                                           access={access}/>)
            }
        </div>
    );
}
const AccessBlock = (props) => {
    let lkSeniorSupports = [];
    let lkHeads = [];

    props.AdminInfo.usersList.forEach(accessA => {
        let isUserFound = false;
        props.access.lkSeniorSupports.forEach(accessB => {
            if (accessA.id == accessB.id) isUserFound = true
        })
        isUserFound ? lkSeniorSupports.push({name: accessA.username, id: accessA.id, isAccessed: true}) :
            lkSeniorSupports.push({
                name: accessA.username, id: accessA.id,
                isAccessed: false
            })
    })
    props.AdminInfo.usersList.forEach(accessA => {
        let isUserFound = false;
        props.access.lkHeads.forEach(accessB => {
            if (accessA.id == accessB.id) isUserFound = true
        })
        isUserFound ? lkHeads.push({name: accessA.username, id: accessA.id, isAccessed: true}) :
            lkHeads.push({
                name: accessA.username, id: accessA.id,
                isAccessed: false
            })
    })

    const EditAccessByInput = (e) => {
        props.EditSupportAccess({
            editType: e.target.accessKey,
            newValue: e.target.value,
            access_name: props.access.name
        })
    }

    const EditAccessByButton = (editType, subEditType, name, id, access_name) => {
        props.EditSupportAccess({
            editType, subEditType, name, id, access_name
        })
    }

    return (
        <div className={"access-block"}>
            <div className={"access-name"}>{props.access.name}</div>
            <div className={"editor"}>
                <div className={"block"}>
                    <div className={"name"}>Discord SID</div>
                    <input onBlur={EditAccessByInput} accessKey={"discordServer"}
                           defaultValue={props.access.discordServer}/>
                </div>
                <div className={"block"}>
                    <div className={"name"}>Discord CID</div>
                    <input onBlur={EditAccessByInput} accessKey={"discordChannel"}
                           defaultValue={props.access.discordChannel}/>
                </div>
                <div className={"block"}>
                    <div className={"name"}>Telegram SID</div>
                    <input onBlur={EditAccessByInput} accessKey={"telegramServer"}
                           defaultValue={props.access.telegramServer}/>
                </div>
                <div className={"block"}>
                    <div className={"name"}>Telegram Senior Support SID</div>
                    <input onBlur={EditAccessByInput} accessKey={"telegramSeniorSupport"}
                           defaultValue={props.access.telegramSeniorSupport}/>
                </div>
                <div className={"block"}>
                    <div className={"name"}>Telegram Head SID</div>
                    <input onBlur={EditAccessByInput} accessKey={"telegramHead"}
                           defaultValue={props.access.telegramHead}/>
                </div>
                <div className={"block"}>
                    <div className={"name"}>LK Senior Supports</div>
                    <Popup key={"lk-seniors-supports-editor"}
                           trigger={
                               <button>Выбрать</button>}
                           modal
                           nested
                    >
                        {() => (
                            <div>
                                <div id="get-advice" className="user-page-popup">
                                    <form className="form">
                                        <div className="formgroup">
                                            {lkSeniorSupports.map((user, i) => {
                                                    return user.isAccessed ?
                                                        <div key={user.name} onClick={(e) => {
                                                            EditAccessByButton("lkSeniorSupports", "remove", user.name, user.id, props.access.name)
                                                        }}
                                                             className={"role-page-access-block role-page-access-block-accessed"}>{user.name}</div>
                                                        : <div key={user.name} accessKey={user.id}
                                                               onClick={(e) => {
                                                                   EditAccessByButton("lkSeniorSupports", "add", user.name, user.id, props.access.name)
                                                               }}
                                                               className={"role-page-access-block role-page-access-block-denied"}>{user.name}</div>
                                                }
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Popup>
                </div>
                <div className={"block"}>
                    <div className={"name"}>LK Heads</div>
                    <Popup key={"lk-heads-editor"}
                           trigger={
                               <button>Выбрать</button>}
                           modal
                           nested
                    >
                        {() => (
                            <div>
                                <div id="get-advice" className="user-page-popup">
                                    <form className="form">
                                        <div className="formgroup">
                                            {lkHeads.map((user, i) => {
                                                    return user.isAccessed ?
                                                        <div key={user.name} onClick={(e) => {
                                                            EditAccessByButton("lkHeads", "remove", user.name, user.id, props.access.name)
                                                        }}
                                                             className={"role-page-access-block role-page-access-block-accessed"}>{user.name}</div>
                                                        : <div key={user.name} accessKey={user.id}
                                                               onClick={(e) => {
                                                                   EditAccessByButton("lkHeads", "add", user.name, user.id, props.access.name)
                                                               }}
                                                               className={"role-page-access-block role-page-access-block-denied"}>{user.name}</div>
                                                }
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Popup>
                </div>
            </div>
        </div>
    )
}