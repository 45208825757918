import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as Copy} from '../../../../assests/copy.svg'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const CopyBox = ({title, value}) => {
    const [isCopy, setIsCopy] = useState(false)

    useEffect(() => {
        if (isCopy) {
            setTimeout(() => {
                setIsCopy(false)
            }, 1000)
        }
    }, [isCopy])
    return (
        <div className={s.ref_link}>
            <p>{title}</p>
            <div className={s.ref_box}>
                {isCopy && <div className={s.copy_ok}>Скопировано</div>}
                <span>{value}</span>
                <CopyToClipboard text={value} onCopy={() => setIsCopy(true)}>
                    <Copy className={s.copy_icon}/>
                </CopyToClipboard>
            </div>
        </div>
    );
};

export default CopyBox;
