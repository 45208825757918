import React from 'react';
import s from './styles.module.css';
const RefWithdrawStatus = ({ value }) => {
    let customStyles = {};
    let status = "Неизвестно";

    if (value == "new") {
        customStyles.color = "#f8b700";
        status = "На рассмотрении"
    } else if (value == "complete") {
        customStyles.color = "#1085f2";
        status = "Успешный вывод"
    }

    return <p className={s.color_text} style={customStyles}>{status}</p>;
};

export default RefWithdrawStatus;