import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import Auth from "./auth";
import queryString from 'query-string';
import Cookies from 'js-cookie'
import {GetAuth, GetProfile, Logout} from "../../../redux/reducers/authReducer";
import $ from "jquery";

class AuthContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {

        var sidebar_left = $('.sidebar-left');
        var sidebar_right = $('.sidebar-right');

        var sub_menu = $('.sub-menu');
        var container = $('.container');


        $(window).resize(function () {
            var ww = $(window).width();
            if (ww <= 991) {
                sidebar_left.removeClass('hide');
                sidebar_right.removeClass('hide');
            }
        });

        $('.size-btn').click(function () {
            if ($(this).hasClass('in')) {
                //$(this).removeClass('in');

                sidebar_right.removeClass('hide');
                $('.sidebar-right .sidebar-head__btn.desc').removeClass('active');
                container.css("margin-right", 300);

                changeSizeBtnStatus();

            } else if ($(this).not('in')) {
                //$(this).addClass('in');

                sidebar_right.addClass('hide');
                $('.sidebar-right .sidebar-head__btn.desc').addClass('active');
                container.css("margin-right", 0);

                $('.side-menu__btn').removeClass('active');
                sub_menu.removeClass('show');
                container.css("margin-left", 50);

                changeSizeBtnStatus();
            }
        });

        function changeSizeBtnStatus() {

            if (sidebar_right.hasClass('hide') && sub_menu.not('show')) {
                $('.size-btn').addClass('in');
            } else if (sub_menu.hasClass('show')) {
                $('.size-btn').removeClass('in');
            } else {
                $('.size-btn').removeClass('in');
            }
        }

        $('.sidebar-right .sidebar-head__btn.desc').click(function () {
            var btn = $(this);

            sidebar_right.toggleClass('hide');

            if (btn.hasClass('active')) {
                container.css("margin-right", 300);
            } else {
                container.css("margin-right", 0);
            }

            btn.toggleClass('active');
            changeSizeBtnStatus();
        });

        $('.side-menu__btn').click(function () {
            var btn = $(this);

            if (btn.hasClass('active')) {
                btn.removeClass('active');
                btn.closest('li').find('.sub-menu').removeClass('show');

                container.css("margin-left", 50);
            } else {
                $('.side-menu__btn').removeClass('active');
                $('.sub-menu').removeClass('show');
                btn.addClass('active');
                btn.closest('li').find('.sub-menu').addClass('show');

                container.css("margin-left", 300);
            }
        });

        $('.sub-menu__down').click(function () {
            $(this).closest('li').find('.sub-menu__list').slideToggle(300);
            $(this).toggleClass('active');
        });

        $('.sub-menu__title').click(function () {
            $('.sub-menu').removeClass('show');
            $('.side-menu__btn').removeClass('active')
            $('.container').removeClass('menu-open');

            container.css("margin-left", 50);
        });

        //Мобила
        $('.sidebar-left .sidebar-head__btn.mob').click(function () {

            if (sidebar_left.hasClass('show')) {
                hideLeftSidebar();
            } else {
                showLeftSidebar();
            }
        });

        $('.sidebar-right .sidebar-head__btn.mob').click(function () {

            if (sidebar_right.hasClass('show')) {
                hideRightSidebar();
            } else {
                showRightSidebar();
            }
        });

        function showLeftSidebar() {
            $('.sidebar-left .sidebar-head__btn').addClass('active');
            $('.sidebar-left').addClass('show');
            hideRightSidebar();
            showOverlay();
        }

        function hideLeftSidebar() {
            $('.sidebar-left .sidebar-head__btn').removeClass('active');
            $('.sidebar-left').removeClass('show');
            hideOverlay();
        }

        function showRightSidebar() {
            $('.sidebar-right .sidebar-head__btn').addClass('active');
            $('.sidebar-right').addClass('show');
            hideLeftSidebar();
            showOverlay();
        }

        function hideRightSidebar() {
            $('.sidebar-right .sidebar-head__btn').removeClass('active');
            $('.sidebar-right').removeClass('show');
            hideOverlay();
        }

        //Оверлей
        function showOverlay() {
            $('html').css('overflow', 'hidden');
            $("body").append("<div class='overlay'></div>");
            $("body").css('padding-right', checkScrollWidth);
            $("header").css('padding-right', checkScrollWidth);
        };

        function hideOverlay() {
            $('html').css('overflow', 'auto');
            $('.overlay').remove();
            $("body").css('padding-right', '0');
            $("header").css('padding-right', '0');
        };

        //Закрытие сайдбаров при клике вне
        $('body').on('click', '.overlay', function () {
            hideLeftSidebar();
            hideRightSidebar();
        });

        //Ширина скроллбара(что бы не прыгало при открытии меню)
        function checkScrollWidth() {
            let div = document.createElement('div');
            div.className = "sw";
            div.style.overflowY = 'scroll';
            div.style.width = '50px';
            div.style.height = '50px';

            document.body.append(div);
            var scrollWidth = div.offsetWidth - div.clientWidth;
            $('.sw').remove();

            return scrollWidth;
        }

        let params = queryString.parse(this.props.location.search);
        let cookieId = Cookies.get('id');
        let cookieToken = Cookies.get('token');
        let cookieUsername = Cookies.get('username');
        let cookieAvatar = Cookies.get('avatar');
        let cookieEmail = Cookies.get('email');

        if (params.code) {
            this.props.GetAuth(params.code);
        } else if (cookieId && cookieToken && cookieUsername && cookieAvatar && cookieEmail) {
            this.props.GetProfile(cookieId, cookieEmail, cookieToken)
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <Auth {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer
})

export default compose(
    connect(mapStateToProps, {
        GetAuth, GetProfile, Logout
    }),
    withRouter
)(AuthContainer)