import Cookies from "js-cookie";
import {ChangeMainLoading} from "./mainReducer";
import {REQUISITES_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";

const SET_REQUISITES = 'SET_REQUISITES';

let initialState = {
    requisites: []
};

const requisitesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REQUISITES: {
            return {
                ...state,
                requisites: JSON.parse(JSON.stringify(action.data))
            }
        }
        default:
            return state;
    }
};

const SetRequisites = (data) => ({type: SET_REQUISITES, data});

export const CreateRequisites = (description) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        REQUISITES_API.createRequisites({token: Cookies.get("token"), description})
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Сохранение реквизитов", data.message))
                    return
                }
                dispatch(SetRequisites(data))
                dispatch(AddNotification("success", "Сохранение реквизитов", "Сохранено"))
            })
    }
}
export const GetRequisites = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        REQUISITES_API.getRequisites()
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение реквизитов", data.message))
                    return
                }
                dispatch(SetRequisites(data))
            })
    }
}

export const RemoveRequisites = (id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        REQUISITES_API.removeRequisites(id)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление реквизитов", data.message))
                    return
                }
                dispatch(SetRequisites(data))
            })
    }
}

export default requisitesReducer;