import React, {useState, useRef} from "react";
import {NavLink} from "react-router-dom";
import DefaultAvatarIcon from '../../../images/default_avatar.png'
import MainLoading from "../../common/mainLoading";
import UserSearchContainer from "./userSearchContainer";

export default function UsersList(props) {
    return (
        props.isLoading ? <MainLoading/>
            :
        <div className="users-list">
            <UserSearchContainer/>
            { props.AdminInfo.usersSearchList.map(user => <UserBlock user={user}/>)}
        </div>
    );
}

const UserBlock = (props) => {
    return (
        <NavLink to={props.user.id} className={"user-block"}>
            <div className={"user-avatar"}>
                <img src={
                    props.user.avatar ?
                        `https://cdn.discordapp.com/avatars/${props.user.id}/${props.user.avatar}`
                        : DefaultAvatarIcon
                } alt={"avatar"}/>
            </div>
            <div className={"user-info"}>
                <div className={"username"}>
                    {props.user.username} | {props.user.email}
                </div>
                <div className={"last-activity"}>
                    Был онлайн: {props.user.online}
                </div>
            </div>
        </NavLink>
    )
}