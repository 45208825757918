import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import s from './auditorTasks.module.css';
import sortImg from '../../../images/sort.png'
import {AdminAddSecondSupportToTask} from "../../../../redux/reducers/auditorReducer";

export default function AuditorTasks(props) {
    return (
        props.AuditorInfo.adminTasksList[0] ?
            <div className={"auditor-tasks-list"}>
                <div className={"c-header"}>
                    <div style={{cursor: "pointer"}} className={"name"}
                         onClick={() => props.AdminSortTasks("nickname")}>
                        <img style={{width: "14px", marginRight: "5px"}} src={sortImg} alt={"sort"}/>Ник игрока
                    </div>
                    <div style={{cursor: "pointer"}} className={"name"} onClick={() => props.AdminSortTasks("status")}>
                        <img style={{width: "14px", marginRight: "5px"}} src={sortImg} alt={"sort"}/>Статус
                    </div>
                    <div style={{cursor: "pointer"}} className={"name"}
                         onClick={() => props.AdminSortTasks("setCount")}>
                        <img style={{width: "14px", marginRight: "5px"}} src={sortImg} alt={"sort"}/>Сет
                    </div>
                    <div className={"name"}>
                        Чекбоксы
                    </div>
                    <div className={"name"}>
                        Саппорт
                    </div>
                    <div className={"name"}>
                        Аудитор 1
                    </div>
                    <div className={"name"}>
                        Аудитор 2
                    </div>
                </div>
                <div className={"content"}>
                    {
                        props.AuditorInfo.adminTasksList.map((task, i) => <TaskItem key={"admTasksList" + `i: ${i}`}
                                                                                    task={task}
                                                                                    AdminAddSecondSupportToTask={props.AdminAddSecondSupportToTask}
                                                                                    supportsList={props.AuditorInfo.supportsList}
                                                                                    AdminChangeFollowStatus={props.AdminChangeFollowStatus}
                                                                                    AdminDeleteTask={props.AdminDeleteTask}/>)
                    }
                </div>
            </div>
            : <div style={{color: "white"}}>Ничего не найдено по заданным параметрам</div>
    );
}

const TaskItem = (props) => {
    let AdminChangeFollowStatus = (index) => {
        props.AdminChangeFollowStatus(props.task.nickname, props.task.period, props.task.setCount, index)
    }
    let AdminDeleteTask = (deletionType) => {
        props.AdminDeleteTask(props.task.nickname, props.task.period, props.task.setCount, deletionType)
    }

    let statusColor;
    if (props.task.status == "active") statusColor = "yellow";
    if (props.task.status == "edit") statusColor = "yellow";
    if (props.task.status == "edited") statusColor = "green";
    if (props.task.status == "audit_1") statusColor = "yellow";
    if (props.task.status == "support_2") statusColor = "yellow";
    if (props.task.status == "audit_2") statusColor = "red";
    if (props.task.status == "failed") statusColor = "red";
    if (props.task.status == "correct") statusColor = "green";

    return (
        <Popup key={`taskEditAdmin ${props.task.nickname}${props.task.setCount}${props.task.period}${props.task.direction}`}
               trigger={
                   <div className={"auditor-tasks-list-popup"}>
                       <div className={"name"}>
                           {props.task.nickname}
                       </div>
                       <div className={"name"} style={{color: statusColor}}>
                           {props.task.status}
                       </div>
                       <div className={"name"}>
                           {props.task.setCount}
                       </div>
                       <div className={"name"}>
                           <input style={{margin: "0 5px"}} checked={props.task.checkboxes.is_NN_loaded}
                                  type={"checkbox"}
                                  title={"Забрал НН и загрузил на диск"}/>
                           <input style={{margin: "0 5px"}} checked={props.task.checkboxes.is_balances_done}
                                  type={"checkbox"}
                                  title={"Проверил балансы и записал в проверку"}/>
                           <input style={{margin: "0 5px"}} checked={props.task.checkboxes.is_audit_calculate}
                                  type={"checkbox"}
                                  title={"Полностью проверил и рассчитал данные по аудиту, внес авансы и несогласованную игру, оставил комментарий"}/>
                           <input style={{margin: "0 5px"}} checked={props.task.checkboxes.is_debts_done}
                                  type={"checkbox"}
                                  title={"Проверил и исправил данные в формуляре (количество рук/профит/рб/лб)"}/>
                           <input style={{margin: "0 5px"}} checked={props.task.checkboxes.is_files_loaded}
                                  type={"checkbox"}
                                  title={"Забрал аудит и загрузил на диск"}/>
                       </div>
                       <div className={"name"}>
                           {props.task.owners[0].name}
                       </div>
                       <div className={"name"}>
                           {props.task.owners[1] ? props.task.owners[1].name : "Нет"}
                       </div>
                       <div className={"name"}>
                           {props.task.owners[2] ? props.task.owners[2].name : "Нет"}
                       </div>
                   </div>
               }
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" style={{width: "1200px"}}>
                        <form className="form" style={{color: "white"}}>
                            <div className="formgroup">
                                <div className={s.contentItem}>
                                    <div className={s.contentItemHeader}>
                                        <div className={s.contentItemHeaderInfo}>
                                            <div className={s.contentHeaderItem}>
                                                Ник игрока: <a className={statusColor}>{props.task.nickname}</a>
                                            </div>
                                            <div>
                                                Статус проверки: <a className={statusColor}>{props.task.status}</a>
                                            </div>
                                            <div className={s.contentHeaderItem}>
                                                Дата сбора аудита: <a
                                                className={s.green}>{props.task.owners[0].audit_change_date}</a>
                                            </div>
                                        </div>
                                        <div className={s.contentItemHeaderDates}>
                                            <div className={s.contentHeaderItem}>
                                                Дата расчёта: <a
                                                className={s.green}>{props.task.owners[0].calculation_date}</a>
                                            </div>
                                            <div className={s.contentHeaderItem}>
                                                Дата расчёта аудитора: {props.task.owners[1] ?
                                                <a className={s.green}>{props.task.owners[1].calculation_date}</a>
                                                : "Не назначен"
                                            }
                                            </div>
                                            <div className={s.contentHeaderItem}>
                                                Дата расчёта аудитора 2: {props.task.owners[2] ?
                                                <a className={s.green}>{props.task.owners[2].calculation_date}</a>
                                                : "Не назначен"
                                            }
                                            </div>
                                        </div>
                                        <div className={s.contentItemHeaderDates}>
                                            <div className={s.contentHeaderItem}>
                                                Дата расчёта саппорта 2: {props.task.owners[3] ?
                                                <a className={s.green}>{props.task.owners[3].calculation_date}</a>
                                                : "Не назначен"
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={s.contentItemInfo}>
                                        {props.task.rooms[0] ?
                                            props.task.rooms.map(room => <RoomItem room={room}/>)
                                            : null
                                        }
                                    </div>
                                    <div className={s.buttons}>
                                        <div onClick={(e) => AdminChangeFollowStatus(0)}>Отправить на редактирование
                                            саппорту {props.task.owners[0] ? props.task.owners[0].name : "---"}</div>
                                        {props.task.owners[1] ?
                                            <div onClick={(e) => AdminChangeFollowStatus(1)}>Отправить на редактирование
                                                аудитору {props.task.owners[1].name}</div> : null}
                                        {props.task.owners[2] ?
                                            <div onClick={(e) => AdminChangeFollowStatus(2)}>Отправить на редактирование
                                                аудитору {props.task.owners[2].name}</div> : null}
                                        {props.task.owners[2] ?
                                            <Popup key={Math.random(1)}
                                                   trigger={
                                                       <div>Назначить 4-го проверяющего</div>
                                                   }
                                                   modal
                                                   nested
                                            >
                                                {() => (
                                                    <div>
                                                        <div id="get-advice" className="user-page-popup">
                                                            <form className="form">
                                                                {props.supportsList.map((support, i) => <div onClick={() => props.AdminAddSecondSupportToTask(support.id, props.task.nickname, props.task.period, props.task.setCount, props.task.direction)}
                                                                    key={`4editor audit ${support.username}`}
                                                                    className={"role-page-access-block default-back"}>{support.username}</div>)}
                                                            </form>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                            : null
                                        }

                                        <div onClick={() => AdminDeleteTask("0")}>Скрыть таск у саппорта</div>
                                        <div onClick={() => AdminDeleteTask("1")}>Полное удаление таска</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}

const RoomItem = (props) => {
    return (
        <div className={s.roomInfo}>
            <div className={s.roomName}>
                <b>{props.room.name}</b>
            </div>
            <div className={s.checkerAnswers}>
                <div className={s.checkerItem}>
                    Проверил
                </div>
                <div className={s.checkerItem}>
                    Турниров
                </div>
                <div className={s.checkerItem}>
                    Профит
                </div>
                <div className={s.checkerItem}>
                    Бонусы
                </div>
                <div className={s.checkerItem}>
                    Коины/Мили
                </div>
                <div className={s.checkerItem}>
                    Стартовый баланс
                </div>
                <div className={s.checkerItem}>
                    Конечный баланс
                </div>
                <div className={s.checkerItem}>
                    Аванс/Лич.Долг
                </div>
                <div className={s.checkerItem}>
                    Комментарий
                </div>
            </div>
            {props.room.answers.map(answer => <CheckerStats answer={answer}/>)}
        </div>
    )
}
const CheckerStats = (props) => {
    return (
        <div className={s.checkerStats}>
            <div className={s.checkerAnswers}>
                <div className={s.checkerItem}>
                    {props.answer.ownerName}
                </div>
                <div className={s.checkerItem}>
                    {props.answer.total_tournaments}
                </div>
                <div className={s.checkerItem}>
                    {props.answer.profit}
                </div>
                <div className={s.checkerItem}>
                    {props.answer.bonuses}
                </div>
                <div className={s.checkerItem}>
                    {props.answer.coins_miles}
                </div>
                <div className={s.checkerItem}>
                    {props.answer.start_balance}
                </div>
                <div className={s.checkerItem}>
                    {props.answer.current_balance}
                </div>
                <div className={s.checkerItem}>
                    {props.answer.personal_duty}
                </div>
                <div className={s.checkerItem}>
                    {props.answer.description}
                </div>
            </div>
        </div>
    )
}