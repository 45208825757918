import React from 'react';
import s from './styles.module.css'
import img_logo_success from '../../../assests/success_popup.png'
import img_logo_error from '../../../assests/error_popup.png'
import CloseArrowPopup from "../../../common/closeArrowPopup";

const PopupSuccessAndError = ({onClose, type = 'error'}) => {
    return (
        <div className={s.success_box}>
            <CloseArrowPopup title={''} onClose={onClose}/>
            <img className={s.img} src={type === 'error' ? img_logo_error : img_logo_success} alt="logo"/>

            <h3>
                {type === 'success' && 'Заявка на вывод средств создана успешно'}
                {type === 'error' && 'Что-то сломалось! Попробуйте еще раз'}
            </h3>

            <p>
                {type === 'success' && 'Деньги поступят на указанный счет в течение 24 часов'}
                {type === 'error' && 'Заявка на вывод средств не была создана :('}
            </p>

            <div className={s.btn} onClick={onClose} style={{
                background: type === 'success' ? '#FAB800' : '#1089F9',
                color: type === 'success' ? '#000' : '#fff',
            }}>
                {type === 'success' && ' Спасибо'}
                {type === 'error' && ' Попробовать еще раз'}
            </div>
        </div>
    );
};

export default PopupSuccessAndError;
