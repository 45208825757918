import {DEV_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";

const DEV_SET_LOGS_SYSTEM_NAMES = 'DEV_SET_LOGS_SYSTEM_NAMES';
const DEV_SET_LOGS = 'DEV_SET_LOGS';

let initialState = {
    logs_system_names: [],
    logs: [],
};

const devReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEV_SET_LOGS_SYSTEM_NAMES: {
            return {
                ...state,
                logs_system_names: JSON.parse(JSON.stringify(action.data))
            }
        }
        case DEV_SET_LOGS: {
            return {
                ...state,
                logs: JSON.parse(JSON.stringify(action.data))
            }
        }
        default:
            return state;
    }
};

const SetLogsSystemNames = (data) => ({type: DEV_SET_LOGS_SYSTEM_NAMES, data})
const SetLogs = (data) => ({type: DEV_SET_LOGS, data});

export const GetSystemNames = () => {
    return (dispatch) => {
        DEV_API.errorLogs_GetSystemNames()
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "DEV", data.message))
                    return
                }
                dispatch(SetLogsSystemNames(data))
            })
    }
}

export const GetSystem = (system) => {
    return (dispatch) => {
        DEV_API.errorLogs_GetSystem(system)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "DEV", data.message))
                    return
                }
                dispatch(SetLogs(data))
            })
    }
}

export const EditLog = (system) => {
    return (dispatch) => {
        DEV_API.errorLogs_GetSystem(system)
            .then(data => {
                if (data && data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "DEV", data.message))
                    return
                }
                dispatch(SetLogs(data))
            })
    }
}

export default devReducer;