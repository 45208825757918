import React, {useEffect, useMemo, useRef, useState} from 'react';
import Select from "react-dropdown-select";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import moment from "moment/moment";

const PlayerSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите игрока"}
            labelField={"student_name"}
            valueField={"student_name"}
            sortBy={"student_name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelectPlayer(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "1"}}
        />
    )
}

const GroupSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать группу"}
            labelField={"group_name"}
            valueField={"group_name"}
            sortBy={"group_name"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelectFilterGroup(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "1"}}
        />
    )
}

const TypeSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать тип"}
            labelField={"type"}
            valueField={"type"}
            sortBy={"type"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectFilterType(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "0"}}
        />
    )
}

const StatusSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать статусы"}
            labelField={"status"}
            valueField={"status"}
            sortBy={"status"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectFilterStatus(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "0"}}
        />
    )
}

const DifficultSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать сложность"}
            labelField={"subType"}
            valueField={"subType"}
            sortBy={"subType"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={[]}
            onChange={(value) => {
                props.handleSelectFilterDifficult(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "0"}}
        />
    )
}

const PlayersStat = (props) => {
    const [selectedPlayer, setSelectedPlayer] = useState([]);
    const [fromTimestamp, setFromTimestamp] = useState(+new Date());
    const [toTimestamp, setToTimestamp] = useState(+new Date());

    const handleSelectFilterType = (e) => {
        props.PlayerStatsSetFilterType(e)
    }
    const handleSelectFilterGroup = (e) => {
        props.PlayerStatsSetFilterGroup(e)
    }
    const handleSelectFilterDifficult = (e) => {
        props.PlayerStatsSetFilterDifficult(e)
    }
    const handleSelectFilterStatus = (type, e) => {
        props.PlayerStatsSetFilterStatus(type, e)
    }

    const handleSelectPlayer = (e) => {
        setSelectedPlayer(JSON.parse(JSON.stringify(e)));
        props.GetPlayerStats(fromTimestamp, toTimestamp, e[0]?.student_id)
    }

    const handleSelectFromTimestamp = (e) => {
        if (new Date(e) == "Invalid Date") return;
        setFromTimestamp(+new Date(e));
        props.GetPlayerStats(+new Date(e), toTimestamp, selectedPlayer[0]?.student_id)
    }
    const handleSelectToTimestamp = (e) => {
        if (new Date(e) == "Invalid Date") return;
        setToTimestamp(+new Date(e));
        props.GetPlayerStats(fromTimestamp, +new Date(e), selectedPlayer[0]?.student_id)
    }


    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            },

        },
    });

    let trainings_count = 0;

    let reactions_true = 0;
    let reactions_count = 0;

    let visits_true = 0;
    let visits_count = 0;


    props.TrainingInfo.playerStats.forEach(training => {
        trainings_count += 1;

        if (training.students_list && training.students_list[0]) {
            training.students_list.forEach(student => {
                if (student.is_reacted) reactions_true += 1;
                if (student.visit_percent && student.visit_percent >= 1) visits_true += 1;
                reactions_count += 1;
                visits_count += 1;
            })
        }
    })


    let percent_reactions = (reactions_true / reactions_count * 100).toFixed(2);
    let percent_visits = (visits_true / visits_count * 100).toFixed(2);
    let avg_visits = (visits_count / trainings_count).toFixed(2);

    let playersList = [];

    for (let i = 0; i < props.TrainingInfo.playerStats.length; i++) {
        if (props.TrainingInfo.playerStats[i].type == "personal") {
            let playerIndex = playersList.findIndex(e => e.id == props.TrainingInfo.playerStats[i].student_id);
            if (playerIndex == -1) {
                playersList.push({
                    id: props.TrainingInfo.playerStats[i].student_id,
                    name: props.TrainingInfo.playerStats[i].student_name,
                    len: 0,
                    total: 0
                })
            }
        } else {
            if (props.TrainingInfo.playerStats[i].students_list && props.TrainingInfo.playerStats[i].students_list[0]) {
                for (let j = 0; j < props.TrainingInfo.playerStats[i].students_list.length; j++) {
                    let playerIndex = playersList.findIndex(e => e.id == props.TrainingInfo.playerStats[i].students_list[j].student_id);
                    if (playerIndex == -1) {
                        playersList.push({
                            id: props.TrainingInfo.playerStats[i].students_list[j].student_id,
                            name: props.TrainingInfo.playerStats[i].students_list[j].student_name,
                            len: 0,
                            total: 0
                        })
                    }
                }
            }
        }
    }

    // console.log(playersList)

    let trainingRows = [];

    for (let i = 0; i < props.TrainingInfo.playerStats.length; i++) {
        let trainingRow = {
            name: props.TrainingInfo.playerStats[i].topic ? props.TrainingInfo.playerStats[i].topic : "Не указано",
            from_timestamp: props.TrainingInfo.playerStats[i].from_timestamp ? props.TrainingInfo.playerStats[i].from_timestamp : "Не указано",
            coach_name: props.TrainingInfo.playerStats[i].coach_name ? props.TrainingInfo.playerStats[i].coach_name : "Не указано",
            type: props.TrainingInfo.playerStats[i].type ? props.TrainingInfo.playerStats[i].type : "Не указано",
            subType: props.TrainingInfo.playerStats[i].subType ? props.TrainingInfo.playerStats[i].subType : "Не указано",
            group_name: props.TrainingInfo.playerStats[i].group_name && props.TrainingInfo.playerStats[i].type == "group" ? props.TrainingInfo.playerStats[i].group_name : "-",
            players: []
        };
        playersList.forEach((player, pi) => {
            if (props.TrainingInfo.playerStats[i].type == "personal") {
                if (props.TrainingInfo.playerStats[i].student_id == player.id) {
                    if (props.TrainingInfo.playerStats[i].students_list && props.TrainingInfo.playerStats[i].students_list[0]
                        && !isNaN(props.TrainingInfo.playerStats[i].students_list[0].visit_percent)) {
                        trainingRow.players.push({
                            name: player.name,
                            percent: props.TrainingInfo.playerStats[i].students_list[0].visit_percent
                        })
                        if (!isNaN(+props.TrainingInfo.playerStats[i].students_list[0].visit_percent)) {
                            playersList[pi].total += +props.TrainingInfo.playerStats[i].students_list[0].visit_percent;
                            playersList[pi].len += 1;
                        }
                    } else {
                        trainingRow.players.push({name: player.name, percent: " "})
                    }
                } else {
                    trainingRow.players.push({name: player.name, percent: " "})
                }
            } else {
                if (props.TrainingInfo.playerStats[i].students_list && props.TrainingInfo.playerStats[i].students_list[0]) {
                    let playerIndex = props.TrainingInfo.playerStats[i].students_list.findIndex(e => e.student_id == player.id);
                    if (playerIndex != -1) {
                        trainingRow.players.push({
                            name: player.name,
                            percent: props.TrainingInfo.playerStats[i].students_list[playerIndex].visit_percent
                        })
                        if (!isNaN(+props.TrainingInfo.playerStats[i].students_list[playerIndex].visit_percent)) {
                            playersList[pi].total += +props.TrainingInfo.playerStats[i].students_list[playerIndex].visit_percent;
                            playersList[pi].len += 1;
                        }
                    } else {
                        trainingRow.players.push({name: player.name, percent: " "})
                    }
                } else {
                    trainingRow.players.push({name: player.name, percent: " "})
                }
            }
        })
        trainingRows.push(trainingRow)
    }

    // console.log(trainingRows)

    let csvData = ";;;;;;;";

    playersList.forEach(player => {
        csvData += `${(player.total / player.len).toFixed(2)};`
    })

    csvData += "\n";

    csvData += "Дата;Тип;Группа;Тема;Сложность;Тренер;;"

    playersList.forEach(player => {
        csvData += `${player.name};`;
    })

    csvData += "\n";

    trainingRows.forEach((e, i) => {
        let total = 0;
        let length = 0;

        e.players.forEach(player => {
            if (!isNaN(+player.percent)) {
                total += +player.percent;
                length += 1;
            }
        })

        let row = `${!isNaN(e.from_timestamp) ? `${moment(+e.from_timestamp).format("DD.MM.YYYY HH:mm")}` : e.from_timestamp};` +
            `${e.type};${e.group_name};${e.name};${e.subType};${e.coach_name};${(total / length).toFixed(2)};`;

        e.players.map((player, i) => {
            row += `${player.percent};`
        })

        row += "\n";
        csvData += row;
    })

    const handleExportData = (data) => {
        props.ExportPlayerStatsToGoogleTable(data);
    }

    return (
        <div>
            <div style={{textAlign: "left", margin: "10px 0px 0 50px"}}>
                <a
                    style={{
                        color: "#3260da"
                    }}
                    href="https://docs.google.com/spreadsheets/d/1afuAubUt7cFt9Og3dNkVS6iEqQO9AkZqzf5hR4kV5C8/edit#gid=654264470" target="_blank">Открыть таблицу с экспортированными данными</a>
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                padding: "20px 50px 10px 50px",
                color: "white"
            }}>
                <input type="date" onBlur={(e) => handleSelectFromTimestamp(e.target.value)}/>
                <input type="date" onBlur={(e) => handleSelectToTimestamp(e.target.value)}/>
                <PlayerSelector options={props.TrainingInfo.playersList}
                                handleSelectPlayer={handleSelectPlayer}/>
                <GroupSelector selected={props.TrainingInfo.playerStatsSelectedGroupFilter}
                               options={props.TrainingInfo.playerStatsGroupFilter}
                               handleSelectFilterGroup={handleSelectFilterGroup}/>
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                padding: "10px 50px 10px 50px",
                color: "white"
            }}>
                <TypeSelector selected={props.TrainingInfo.playerStatsSelectedTypeFilter}
                              options={props.TrainingInfo.playerStatsTypeFilter}
                              handleSelectFilterType={handleSelectFilterType}/>
                <StatusSelector selected={props.TrainingInfo.playerStatsSelectedStatusFilter}
                                options={props.TrainingInfo.playerStatsStatusFilter}
                                handleSelectFilterStatus={handleSelectFilterStatus}/>
                <DifficultSelector selected={props.TrainingInfo.playerStatsSelectedDifficultFilter}
                                   options={props.TrainingInfo.playerStatsDifficultFilter}
                                   handleSelectFilterDifficult={handleSelectFilterDifficult}/>
                {
                    props.TrainingInfo.playerStats && props.TrainingInfo.playerStats[0] &&
                    <div onClick={() => handleExportData(csvData)}
                         className={"button default-back default-button cursor-pointer"}
                         style={{marginLeft: "15px"}}>
                        Export to Google Table
                    </div>
                }
            </div>

            {/*{*/}
            {/*    props.TrainingInfo.playerStats[0] &&*/}
            {/*    <div style={{*/}
            {/*        color: "white",*/}
            {/*        display: "grid",*/}
            {/*        gridTemplateColumns: "1fr 1fr 1fr", margin: "30px 0 20px 0"*/}
            {/*    }}>*/}
            {/*        <div>Тренировок всего: {trainings_count}</div>*/}
            {/*        <div>Процент посещений: {percent_visits}</div>*/}
            {/*        <div>В среднем посещений: {avg_visits}</div>*/}
            {/*    </div>*/}
            {/*}*/}

            <div style={{
                width: 1500 + +`${playersList.length * 100}`
            }}>
                {
                    props.TrainingInfo.playerStats[0] &&
                    <div style={{
                        textAlign: "left",
                        color: "white",
                        display: "flex",
                        margin: "30px 0 0 0",
                        width: "100%",
                    }}>
                        <div style={{width: "1500px"}}></div>
                        {
                            playersList.map(player => <div style={{width: "90px", padding: "0 10px", textAlign: "center"}}>{(player.total / player.len).toFixed(2)}%</div>)
                        }
                    </div>
                }
            </div>

            <div style={{
                width: 1500 + +`${playersList.length * 100}`,
            }}>
                {
                    props.TrainingInfo.playerStats[0] &&
                        <div style={{
                            textAlign: "left",
                            color: "white",
                            display: "flex",
                            margin: "0 0 10px 0",
                            width: "100%",
                            position: "sticky",
                            top: "0",
                            zIndex: "100",
                        }}>
                            <div style={{width: "200px"}}>Дата</div>
                            <div style={{width: "200px"}}>Тип</div>
                            <div style={{width: "200px"}}>Группа</div>
                            <div style={{width: "400px"}}>Тема</div>
                            <div style={{width: "200px"}}>Сложность</div>
                            <div style={{width: "200px"}}>Тренер</div>
                            <div style={{width: "100px"}}></div>
                            {
                                playersList.map(player => <div title={player.name} style={{width: "90px", padding: "0 10px", textAlign: "center"}}>{player.name.replaceAll(' ', '').length >= 7 ? `${player.name.replaceAll(' ', '').slice(0,7)}...` : player.name.replaceAll(' ', '')}</div>)
                            }
                        </div>
                }

                {trainingRows.map((e, i) => {
                    return <TrainingRowPopup training={e} i={i}/>
                })}
            </div>
        </div>
    );
};

const TrainingRowPopup = (props) => {
    // let visits_true = 0;
    // let reactions_true = 0;
    //
    // if (props.training.students_list && props.training.students_list[0]) {
    //     props.training.students_list.forEach(student => {
    //         if (student.is_reacted) reactions_true += 1;
    //         if (student.visit_percent && student.visit_percent >= 1) visits_true += 1;
    //     })
    // }
    //
    // let status = "Активно";
    // if (props.training.isClosed) status = "Завершена";
    // if (props.training.isClosed && props.training.to_timestamp <= 5) status = "Отменена";

    let total = 0;
    let length = 0;

    props.training.players.forEach(player => {
        if (!isNaN(+player.percent)) {
            total += +player.percent;
            length += 1;
        }
    })

    return (
                <div style={{
                    textAlign: "left",
                    color: "white",
                    display: "flex",
                    width: "100%",
                    backgroundColor: props.i % 2 == 0 ? `rgb(70,71,94)` : null,
                    padding: `0 0`
                }}>
                    <div style={{width: "200px"}}>{!isNaN(props.training.from_timestamp) ? `${moment(+props.training.from_timestamp).format("DD.MM.YYYY HH:mm")}` : props.training.from_timestamp}</div>
                    <div style={{width: "200px"}}>{props.training.type}</div>
                    <div style={{width: "200px"}}>{props.training.group_name}</div>
                    <div style={{width: "400px"}}>{props.training.name}</div>
                    <div style={{width: "200px"}}>{props.training.subType}</div>
                    <div style={{width: "200px"}}>{props.training.coach_name}</div>
                    <div style={{width: "100px"}}>{(total / length).toFixed(2)}%</div>
                    {
                        props.training.players.map((player, i) => {
                            let color = "white";

                            if (!isNaN(+player.percent)) {
                                if (player.percent == 0) color = "#ec4040"
                                if (player.percent == 25) color = "#b340ec"
                                if (player.percent == 50) color = "#e1b759"
                                if (player.percent == 75) color = "#2fc9b0"
                                if (player.percent == 100) color = "#00ce55"
                            }

                            return <div title={`${!isNaN(props.training.from_timestamp) ? `${moment(+props.training.from_timestamp).format("DD.MM.YYYY HH:mm")}` : props.training.from_timestamp} | ${props.training.name}`} key={`${player.name} ${player.percent} ${props.i} ${i}`}
                                 style={{width: "90px", padding: "0 10px", color, textAlign: "center", backgroundColor: i % 2 == 0 ? "rgba(30,35,47,0.41)" : null}}>{player.percent}</div>
                        })
                    }
                </div>
    )
}

export default PlayersStat;

