import React, {useState, useRef} from "react";
import MainLoading from "../../common/mainLoading";

export default function CustomProfileInfo(props) {
    return !props.isLoading ?
        <div className={"cpi-page"}>
            <div className={"columns-names"}>
                <div/>
                <div/>
                <div className={"column-name"}>
                    en_name
                </div>
                <div className={"column-name"}>
                    ru_name
                </div>
                <div className={"column-name"}>
                    isVisible
                </div>
            </div>
            <div className={"cpi-list"}>
                {
                    props.AdminInfo.templateRows.map((template, i) => <Template key={`${i}` + template.en_name}
                                                                                template={template}
                                                                                AdminTemplatesEditRow={props.AdminTemplatesEditRow}/>)
                }
            </div>
        </div>
        : <MainLoading/>
}

const Template = (props) => {
    let ru_name = React.createRef();

    return (
        <div className={"auditor"}>
            <div className={"default-back"} style={{marginRight: "5px", cursor: "pointer"}}
                 onClick={() => props.AdminTemplatesEditRow(props.template._id, "position", "bot", null)}>
                &#129045;&#129045;&#129045;
            </div>
            <div className={"default-back"} style={{marginLeft: "5px", cursor: "pointer"}}
                 onClick={() => props.AdminTemplatesEditRow(props.template._id, "position", "top", null)}>
                &#129047;&#129047;&#129047;
            </div>
            <div className={"name"}>
                {props.template.en_name}
            </div>
            <div className={"column-data"}>
                <input ref={ru_name}
                       onBlur={(e) => props.AdminTemplatesEditRow(props.template._id, "ru_name", null, e.target.value)}
                       defaultValue={props.template.ru_name}/>
            </div>
            <div className={"column-data"}>
                <input type={"checkbox"}
                       checked={props.template.isVisible}
                       onClick={(e) => props.AdminTemplatesEditRow(props.template._id, "isVisible", null, e.target.value)}/>
            </div>
        </div>
    )
}