import React, {useState, useRef} from "react";
import MainLoading from "../common/mainLoading";
import EditIcon from "../../images/learn_images/edit.png"
import UpArrowIcon from "../../images/learn_images/up-arrow.png"
import DownArrowIcon from "../../images/learn_images/down-arrow.png"
import RemoveIcon from "../../images/learn_images/remove.png"
import CreateIcon from "../../images/learn_images/plus.png"
import Error_404 from "../common/error_404/Error_404";
import Popup from "reactjs-popup";


export default function Learn(props) {
    let Title = React.createRef();
    let Description = React.createRef();
    let CategoryCreation = React.createRef();

    let EditTitle = () => {
        props.EditLearn({name: props.learnName, editType: "title", newValue: Title.current.value})
    }
    let EditDescription = () => {
        props.EditLearn({name: props.learnName, editType: "description", newValue: Description.current.value})
    }
    let createCategory = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "category",
            editSubType: "create",
            categoryName: CategoryCreation.current.value
        })
    }

    return (
        props.isLoading ? <MainLoading/>
            :
            props.LearnInfo.data ?
                <div className={"learn-page"}>
                    <div className={"info"}>
                        <div className={"learn-page-header"}>
                        <span className={"title"}>
                            {
                                props.AuthInfo.isWikiCreator ?
                                    <textarea onBlur={EditTitle} ref={Title} defaultValue={props.LearnInfo.data.title}/>
                                    : props.LearnInfo.data.title
                            }
                        </span>
                            <span className={"last-update"}>
                            Последнее обновление: {props.LearnInfo.data.lastUpdate}
                        </span>
                        </div>
                        <div className={"description"}>
                            {
                                props.AuthInfo.isWikiCreator ?
                                    <textarea onBlur={EditDescription} ref={Description}
                                              defaultValue={props.LearnInfo.data.description}/>
                                    : props.LearnInfo.data.description
                            }
                        </div>
                    </div>

                    <div className={"categories"}>
                        {
                            props.LearnInfo.data.categories.map((category, i) => <Category key={category.name + "c"}
                                                                                           learnName={props.learnName}
                                                                                           EditLearn={props.EditLearn}
                                                                                           category={category}
                                                                                           categoryIndex={i}
                                                                                           isAccessed={props.AuthInfo.isWikiCreator}/>)

                        }
                        {
                            props.AuthInfo.isWikiCreator ?
                                <div className={"category"}>
                                    <Popup key={props.LearnInfo.data.categories.length}
                                           trigger={<button
                                               className={"transition green-back wiki-search-category"}>Создать
                                               категорию</button>}
                                           modal
                                           nested
                                    >
                                        {() => (
                                            <div>
                                                <div id="get-advice" className="main-popup">
                                                    <form className="form">
                                                        <div className="formgroup">
                                                            <input type="text" ref={CategoryCreation}
                                                                   placeholder="Название категории"/>
                                                        </div>
                                                    </form>
                                                    <button onClick={createCategory} className={"send-btn"}>Создать
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                </div>
                                : null
                        }
                    </div>
                </div> : <Error_404/>
    );
}

let Category = (props) => {
    let CategoryName = React.createRef();
    let TopicCreationName = React.createRef();
    let TopicCreationUrl = React.createRef();

    let removeCategory = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "category",
            editSubType: "remove",
            categoryName: props.category.name
        })
    }
    let downCategory = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "category",
            editSubType: "changePosition",
            categoryName: props.category.name,
            newValue: +props.categoryIndex + 1
        })
    }
    let upCategory = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "category",
            editSubType: "changePosition",
            categoryName: props.category.name,
            newValue: +props.categoryIndex - 1
        })
    }
    let ChangeCategoryName = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "category",
            categoryName: props.category.name,
            newValue: CategoryName.current.value
        })
    }
    let CreateTopic = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "topic",
            editSubType: "create",
            categoryName: props.category.name,
            topicName: TopicCreationName.current.value,
            newValue2: TopicCreationUrl.current.value
        })
    }

    return (
        <div className={"category"}>
            <div className={"name"}>
                {props.category.name}
                {
                    props.isAccessed ?
                        <span>
                            <Popup key={props.category.topics.length}
                                   trigger={<img src={CreateIcon} alt={"edit-icon"}/>}
                                   modal
                                   nested
                            >
                                        {() => (
                                            <div>
                                                <div id="get-advice" className="main-popup">
                                                    <form className="form">
                                                        <div className="formgroup">
                                                            <input type="text" ref={TopicCreationName}
                                                                   placeholder="Название"/>
                                                        </div>
                                                        <div className="formgroup">
                                                            <input type="text" ref={TopicCreationUrl}
                                                                   placeholder="Ссылка"/>
                                                        </div>
                                                    </form>
                                                    <button onClick={CreateTopic} className={"send-btn"}>Создать
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                        <img onClick={upCategory} src={UpArrowIcon} alt={"edit-icon"}/>
                        <img onClick={downCategory} src={DownArrowIcon} alt={"edit-icon"}/>
                            <Popup key={props.category.name}
                                   trigger={<img src={EditIcon} alt={"edit-icon"}/>}
                                   modal
                                   nested
                            >
                                        {() => (
                                            <div>
                                                <div id="get-advice" className="main-popup">
                                                    <form className="form">
                                                        <div className="formgroup">
                                                            <input type="text" ref={CategoryName}
                                                                   defaultValue={props.category.name}/>
                                                        </div>
                                                    </form>
                                                    <button onClick={ChangeCategoryName} className={"send-btn"}>Изменить
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                        <img onClick={removeCategory} src={RemoveIcon} alt={"edit-icon"}/>
                    </span>
                        : null
                }
            </div>
            <div className={"topics"}>
                {
                    props.category.topics.map((topic, i) => <Topic key={topic.name + "t"}
                                                                   EditLearn={props.EditLearn}
                                                                   learnName={props.learnName}
                                                                   categoryName={props.category.name}
                                                                   topic={topic} categoryIndex={props.categoryIndex}
                                                                   topicIndex={i} isAccessed={props.isAccessed}/>)
                }
            </div>
        </div>
    )
}

let Topic = (props) => {
    let TopicName = React.createRef();
    let TopicUrl = React.createRef();

    let removeTopic = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "topic",
            editSubType: "remove",
            categoryName: props.categoryName,
            topicName: props.topic.name
        })
    }
    let downTopic = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "topic",
            editSubType: "changePosition",
            categoryName: props.categoryName,
            topicName: props.topic.name,
            newValue: +props.topicIndex + 1
        })
    }
    let upTopic = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "topic",
            editSubType: "changePosition",
            categoryName: props.categoryName,
            topicName: props.topic.name,
            newValue: +props.topicIndex - 1
        })
    }
    let ChangeTopicInfo = () => {
        props.EditLearn({
            name: props.learnName,
            editType: "topic",
            categoryName: props.categoryName,
            topicName: props.topic.name,
            newValue: TopicName.current.value,
            newValue2: TopicUrl.current.value
        })
    }

    return (
        <div className={"topic"}>
            <span>{props.categoryIndex + 1}.{props.topicIndex + 1}</span> <a target="_blank" rel="noopener noreferrer" href={props.topic.url}>{props.topic.name}</a>
            {
                props.isAccessed ?
                    <span>
                        <img onClick={upTopic} src={UpArrowIcon} alt={"edit-icon"}/>
                        <img onClick={downTopic} src={DownArrowIcon} alt={"edit-icon"}/>
                        <Popup key={props.topic.name + props.topic.url}
                               trigger={<img src={EditIcon} alt={"edit-icon"}/>}
                               modal
                               nested
                        >
                                        {() => (
                                            <div>
                                                <div id="get-advice" className="main-popup">
                                                    <form className="form">
                                                        <div className="formgroup">
                                                            <input type="text" ref={TopicName} defaultValue={props.topic.name}
                                                                   placeholder="Название"/>
                                                        </div>
                                                        <div className="formgroup">
                                                            <input type="text" ref={TopicUrl} defaultValue={props.topic.url}
                                                                   placeholder="Ссылка"/>
                                                        </div>
                                                    </form>
                                                    <button onClick={ChangeTopicInfo} className={"send-btn"}>Изменить
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                        <img onClick={removeTopic} src={RemoveIcon} alt={"edit-icon"}/>
                    </span>
                    : null
            }
        </div>
    )
}
