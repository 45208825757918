import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import helper from '../../assests/helper_money.png'
import moneyArrow from '../../assests/money_arrow.png'
import {styled, Tooltip, tooltipClasses} from "@mui/material";

const HtmlTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#2B2D3F',
        maxWidth: 400,
        padding: '25px 35px 23px 30px',
        fontSize: theme.typography.pxToRem(12),
        borderRadius: '10px'
    },
}));

let steps_help = [
    {firstValue: '$ 1000', secondValue: '$ 50', order: 1},
    {firstValue: '$ 2000', secondValue: '$ 75', order: 2},
    {firstValue: '$ 5000', secondValue: '$ 125', order: 3},
    {firstValue: '$ 10000', secondValue: '$ 250', order: 4},
    {firstValue: '$ 16000', secondValue: '$ 500', order: 4},
]

const Money = ({data}) => {

    return (
        <div className={s.money_container}>
            <div className={s.money_box}>
                <div className={s.money_order}>{data.current_level}</div>
                <p className={classNames(s.money_text, s.money_text_second)}>$ {+(data.current_level_max - data.for_next_step).toFixed(2)}&nbsp;/&nbsp;</p>
                <p className={classNames(s.money_text)}> $ {+data.current_level_max.toFixed(2)}</p>
                <HtmlTooltip placement="bottom" arrow title={
                    <React.Fragment>
                        {<div className={s.money_dop_box}>
                            <div className={s.money_dop_title}>
                                <p>Профит рекрута</p>
                                <p>Ваша прибыль</p>
                            </div>
                            <div className={s.money_dop_items}>
                                {steps_help.map((el, i) => {
                                    return (
                                        <div className={s.money_dop_item}>
                                            <div
                                                className={classNames(s.money_order, s.money_order_small)}>{el.order}</div>
                                            <p className={s.money_dop_white}>{el.firstValue}</p>
                                            <img className={s.money_arrow} src={moneyArrow} alt="arrow"/>
                                            <p className={classNames(s.money_dop_white, s.money_dop_blue)}>{el.secondValue}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </React.Fragment>
                }>
                    <img className={s.money_helper} src={helper} alt="?"/>
                </HtmlTooltip>
            </div>
        </div>
    );
};

export default Money;