import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import {
    ChangeServersFilter,
    ChangeStatusesFilter,
    ChangeTargetFilter,
    ChangeUsersListFilter,
    GetTTList
} from "../../../redux/reducers/supportReducer";
import Sidebar from "./sidebar";
import {
    AdminCreatePlayer,
    AdminStartPeriod,
    SetSupportTasksListMonth,
    SetSupportTasksListYear,
    SupportGetTasksList,
    SupportSaveTask,
    SearchAuditPlayersByDirection,
    SetAdminTasksListDirection,
    AdminGetAllTasksByPeriod, SetSupportTasksListSet
} from "../../../redux/reducers/auditorReducer";
import fsChangerReducer, {
    SupportChangeTypesFilter,
    SupportChangeStatusesFilter,
    SupportGetTicketsList, AdminCreateBank
} from "../../../redux/reducers/fsChangerReducer";
import {ConnectorCreate} from "../../../redux/reducers/trainingReducer";
import {SupportGetResultsList, SupportUpdateSet} from "../../../redux/reducers/formularReducer";
import {SupportFixData} from "../../../redux/reducers/referralReducer";
import {
    LeakfinderEditDbInfo, LeakfinderGetSaves,
    LeakfinderSaveAnalyze,
    LeakfinderSetCurrentSave, LeakfinderSaveComment, LeakfinderDeleteSave
} from "../../../redux/reducers/leakfinderReducer";
import {SalarySupportChangeStatusesFilter, SalarySupportChangeTypesFilter, SalarySupportChangeDirectionsFilter} from "../../../redux/reducers/salaryReducer";

class SidebarContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {

    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        return (
            <Sidebar {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    SupportInfo: state.supportReducer,
    AuditorInfo: state.auditorReducer,
    FsChangerInfo: state.fsChangerReducer,
    TrainingInfo: state.trainingReducer,
    LeakfinderInfo: state.leakfinderReducer,
    SalaryInfo: state.salaryReducer
})


export default compose(
    connect(mapStateToProps, {
        GetTTList, ChangeStatusesFilter, ChangeServersFilter, AdminCreatePlayer, AdminStartPeriod, SupportGetTasksList, ChangeTargetFilter,
        SetSupportTasksListMonth, SetSupportTasksListYear, SetSupportTasksListSet, SetAdminTasksListDirection, SupportSaveTask, SearchAuditPlayersByDirection, AdminGetAllTasksByPeriod,
        SupportChangeTypesFilter, AdminCreateBank,
        SupportChangeStatusesFilter, LeakfinderSetCurrentSave, LeakfinderSaveAnalyze, LeakfinderGetSaves,
        SupportGetTicketsList, ConnectorCreate, ChangeUsersListFilter, SupportGetResultsList, SupportUpdateSet, SupportFixData,
        LeakfinderEditDbInfo, SalarySupportChangeStatusesFilter, SalarySupportChangeDirectionsFilter, SalarySupportChangeTypesFilter, LeakfinderSaveComment, LeakfinderDeleteSave
    }),
    withRouter
)(SidebarContainer)