import React from 'react';
import s from './styles.module.css'
import logo from '../../assests/logo_mobile.png'
import {ReactComponent as Notepad} from "../../assests/notepad_mobile.svg";
import {ReactComponent as Gold} from "../../assests/gold.svg";
import {ReactComponent as Burger} from "../../assests/burger.svg";
import user from '../../assests/user_mobile.png'
import Cookies from "js-cookie";

const MobileHeader = ({setShowRightMenu, setType}) => {
    return (
        <div className={s.mobile_header}>
            <div>
                <img src={logo} alt=""/>
            </div>
            <div className={s.right_menu}>
                <Notepad onClick={() => {
                    setType('text')
                    setShowRightMenu()
                }}/>
                <Gold/>
                <img style={{width: "30px", borderRadius: "100px"}} className={s.img} src={`https://cdn.discordapp.com/avatars/${Cookies.get("id")}/${Cookies.get("avatar")}`} alt="" onClick={() => {
                    setType('user')
                    setShowRightMenu()
                }}/>
                <Burger onClick={() => {
                    setType('navigate')
                    setShowRightMenu()
                }}/>
            </div>

        </div>
    );
};

export default MobileHeader;