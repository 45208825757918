import React from 'react';
import s from './styles.module.css';
import {useDispatch} from "react-redux";
import {UserRemoveRequest} from "../../../redux/reducers/trainingReducer";

const TrainingRemoveField = ({data}) => {
        const dispatch = useDispatch();

        return data.status == "На рассмотрении" ?
            <button className={s.number}
                    onClick={() => dispatch(UserRemoveRequest(data.owner_id, data.timestamp_day, data.timestamp_time, data.direction))} style={{
                cursor: "pointer",
                backgroundColor: "rgba(255,255,255,0)",
                color: "rgb(178,18,18)",
                border: "none"
            }}>❌ Отменить</button> : ""
    }


export default TrainingRemoveField;