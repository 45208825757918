import React, {useState} from 'react';
import Popup from "reactjs-popup";
import Select from "react-dropdown-select";
import {COA_Transfers_GetByDate} from "../../../../redux/reducers/coaReducer";

const UserDrSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите пользователя по DR"}
            labelField={"nick"}
            valueField={"nick"}
            sortBy={"nick"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#232531",
                border: "none",
                outline: "none",
                borderRadius: "300px",
                zIndex: "104",
                color: "white",
                padding: "5px 10px 5px 10px"
            }}
        />
    )
}
const UserCrSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите пользователя по CR"}
            labelField={"nick"}
            valueField={"nick"}
            sortBy={"nick"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#232531",
                border: "none",
                outline: "none",
                borderRadius: "300px",
                zIndex: "104",
                color: "white",
                padding: "5px 10px 5px 10px"
            }}
        />
    )
}
const CurrencySelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#232531",
                border: "none",
                outline: "none",
                borderRadius: "300px",
                zIndex: "100",
                color: "white",
                padding: "5px 10px 5px 10px"
            }}
        />
    )
}
const DebitSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать дебит счёт"}
            labelField={"public_name"}
            valueField={"public_name"}
            sortBy={"public_name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#232531",
                border: "none",
                outline: "none",
                borderRadius: "300px",
                zIndex: "96",
                color: "white",
                padding: "5px 10px 5px 10px"
            }}
        />
    )
}
const CreditSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать кредит счёт"}
            labelField={"public_name"}
            valueField={"public_name"}
            sortBy={"public_name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#232531",
                border: "none",
                outline: "none",
                borderRadius: "300px",
                zIndex: "96",
                color: "white",
                padding: "5px 10px 5px 10px"
            }}
        />
    )
}

const COATransfers = (props) => {
    const [selectedSearchDateType, setSelectedSearchDateType] = useState("date_fin");
    const [selectedSearchDateFrom, setSelectedSearchDateFrom] = useState(null);
    const [selectedSearchDateTo, setSelectedSearchDateTo] = useState(null);

    const [creatorFormDateTransfer, setCreatorFormDateTransfer] = useState(null);
    const [creatorFormDateFin, setCreatorFormDateFin] = useState(null);
    const [creatorFormUserDr, setCreatorFormUserDr] = useState([]);
    const [creatorFormUserCr, setCreatorFormUserCr] = useState([]);
    const [creatorFormCurrency, setCreatorFormCurrency] = useState([]);
    const [creatorFormDebit, setCreatorFormDebit] = useState([]);
    const [creatorFormCredit, setCreatorFormCredit] = useState([]);
    const [creatorFormDescription, setCreatorFormDescription] = useState("");
    const [creatorFormSum, setCreatorFormSum] = useState(null);
    const [creatorFormSumUsd, setCreatorFormSumUsd] = useState(null);

    const handleGetByDate = () => {
        props.COA_Transfers_GetByDate(selectedSearchDateFrom, selectedSearchDateTo, selectedSearchDateType)
    }
    const handleSelectFormUserDr = (e) => {
        if (e[0]) {
            setCreatorFormUserDr(e)
        } else {
            setCreatorFormUserDr([])
        }
    }
    const handleSelectFormUserCr = (e) => {
        if (e[0]) {
            setCreatorFormUserCr(e)
        } else {
            setCreatorFormUserCr([])
        }
    }
    const handleSelectFormCurrency = (e) => {
        if (e[0]) {
            setCreatorFormCurrency(e)
        } else {
            setCreatorFormCurrency([])
        }
    }
    const handleSelectFormDebit = (e) => {
        if (e[0]) {
            setCreatorFormDebit(e)
        } else {
            setCreatorFormDebit([])
        }
    }
    const handleSelectFormCredit = (e) => {
        if (e[0]) {
            setCreatorFormCredit(e)
        } else {
            setCreatorFormCredit([])
        }
    }
    const handleCreateRow = () => {
        props.COA_Transfers_CreateRow({
                date_transfer: new Date(creatorFormDateTransfer),
                date_fin: new Date(creatorFormDateFin),
                description: creatorFormDescription,
                transfer_sum: creatorFormSum,
                transfer_currency: creatorFormCurrency[0].name,
                debit_public_id: creatorFormDebit[0].public_id,
                debit_name: creatorFormDebit[0].name,
                debit_user_name: creatorFormUserDr[0].nick,
                debit_user_id: creatorFormUserDr[0].user_id,
                credit_public_id: creatorFormCredit[0].public_id,
                credit_name: creatorFormCredit[0].name,
                credit_user_name: creatorFormUserCr[0].nick,
                credit_user_id: creatorFormUserCr[0].user_id,
                debit_id: creatorFormDebit[0].id,
                credit_id: creatorFormCredit[0].id
        })
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
    };

    return (
        <div>
            <div style={{color: "#b26061", marginTop: "5px", fontSize: "14px"}}>Следите внимательно за датами! Если что-то не совпадает - сообщите об этом</div>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 300px", padding: "0 20px", color: "white", marginTop: "20px"}}>
                <div style={{display: "grid", gridTemplateColumns: "130px 100px 5px 50px 40px 120px 40px 120px 5px 100px"}}>
                    <div style={{marginTop: "10px"}}>Поиск по дате:</div>
                    <div className={`default-button button ${selectedSearchDateType == "date_transfer" ? "green-back" : "default-back"}`} onClick={() => setSelectedSearchDateType("date_transfer")}>Transfer</div>
                    <div></div>
                    <div className={`default-button button ${selectedSearchDateType == "date_fin" ? "green-back" : "default-back"}`} onClick={() => setSelectedSearchDateType("date_fin")}>Fin</div>
                    <div style={{marginTop: "10px"}}>От:</div>
                    <input type="date" style={{height: "35px", marginTop: "5px"}} value={selectedSearchDateFrom} onChange={(e) => setSelectedSearchDateFrom(e.target.value)}/>
                    <div style={{marginTop: "10px"}}>До:</div>
                    <input type="date" style={{height: "35px", marginTop: "5px"}} value={selectedSearchDateTo} onChange={(e) => setSelectedSearchDateTo(e.target.value)}/>
                    <div></div>
                    <div className={"button default-button green-back"} onClick={handleGetByDate}>Поиск</div>
                </div>
                <div></div>
                <Popup key={`coa_tran_creator ${props.COAInfo.rows_transfers.length}`}
                       trigger={
                           <div className={"default-button button default-back"}>
                               Добавить новую строку
                           </div>}
                       modal
                       nested
                >
                    {() => (
                        <div>
                            <div id="get-advice" className="main-popup" style={{color: "white", width: "600px"}}>
                                <div style={{ marginTop: "-30px", textAlign: "center"}}>
                                    <div style={{fontSize: "13px", margin: "5px"}}>Дата фин. отчётности <span style={{color: "red"}}>*</span></div>
                                    <input type="date" value={creatorFormDateFin} onChange={(e) => setCreatorFormDateFin(e.target.value)}/>
                                </div>
                                <div style={{textAlign: "center"}}>
                                    <div style={{fontSize: "13px",margin: "5px"}}>Дата факт. транзакции <span style={{color: "red"}}>*</span></div>
                                    <input type="date" value={creatorFormDateTransfer} onChange={(e) => setCreatorFormDateTransfer(e.target.value)} />
                                </div>
                                <div>
                                    <div style={{fontSize: "13px",margin: "5px"}}>Описание транзакции <span style={{color: "red"}}>*</span></div>
                                    <input type="text" placeholder={"Введите описание"} style={{
                                        backgroundColor: "#232531",
                                        width: "100%",
                                        border: "none",
                                        borderRadius: "300px",
                                        padding: "7px 10px 7px 10px",
                                        outline: "none",
                                        color: "white"
                                    }} value={creatorFormDescription} onChange={(e) => setCreatorFormDescription(e.target.value)}/>
                                </div>
                                <div style={{display: "grid", gridTemplateColumns: "1fr 5px 1fr",  marginTop: "10px"}}>
                                    <div>
                                        <div style={{fontSize: "13px",margin: "5px"}}>User по Dr <span style={{color: "red"}}>*</span></div>
                                        <UserDrSelector options={props.COAInfo.transfers_users} selected={creatorFormUserDr} handleSelect={handleSelectFormUserDr}/>
                                    </div>
                                    <div></div>
                                    <div>
                                        <div style={{fontSize: "13px",margin: "5px"}}>User по Cr <span style={{color: "red"}}>*</span></div>
                                        <UserCrSelector options={props.COAInfo.transfers_users} selected={creatorFormUserCr} handleSelect={handleSelectFormUserCr}/>
                                    </div>
                                </div>
                                <div style={{display: "grid", gridTemplateColumns: "1fr 5px 100px 5px 1fr", marginTop: "10px"}}>
                                    <div>
                                        <div style={{fontSize: "13px",margin: "5px"}}>Сумма перевода <span style={{color: "red"}}>*</span></div>
                                        <input type="number" placeholder={"Введите сумму"} style={{
                                            backgroundColor: "#232531",
                                            width: "100%",
                                            border: "none",
                                            borderRadius: "300px",
                                            padding: "7px 10px 7px 10px",
                                            outline: "none",
                                            color: "white"
                                        }} value={creatorFormSum} onChange={(e) => setCreatorFormSum(e.target.value)}/>
                                    </div>
                                    <div></div>
                                    <div>
                                        <div style={{fontSize: "13px",margin: "5px"}}>Валюта <span style={{color: "red"}}>*</span></div>
                                        <CurrencySelector options={props.COAInfo.transfers_currencies} selected={creatorFormCurrency} handleSelect={handleSelectFormCurrency}/>
                                    </div>
                                    <div></div>
                                    <div>
                                        <div style={{fontSize: "13px",margin: "5px"}}>Сумма перевода в USD</div>
                                        <input type="number" placeholder={"Необязательно"} style={{
                                            backgroundColor: "#232531",
                                            width: "100%",
                                            border: "none",
                                            borderRadius: "300px",
                                            padding: "7px 10px 7px 10px",
                                            outline: "none",
                                            color: "white"
                                        }} value={creatorFormSumUsd} onChange={(e) => setCreatorFormSumUsd(e.target.value)}/>
                                    </div>
                                </div>

                                <div style={{display: "grid", gridTemplateColumns: "1fr 5px 1fr", marginTop: "10px"}}>
                                    <div>
                                        <div style={{fontSize: "13px",margin: "5px"}}>Счёт по Dr <span style={{color: "red"}}>*</span></div>
                                        <DebitSelector options={props.COAInfo.transfers_debits} selected={creatorFormDebit} handleSelect={handleSelectFormDebit}/>
                                    </div>
                                    <div></div>
                                    <div>
                                        <div style={{fontSize: "13px",margin: "5px"}}>Счёт по Cr <span style={{color: "red"}}>*</span></div>
                                        <CreditSelector options={props.COAInfo.transfers_credits} selected={creatorFormCredit} handleSelect={handleSelectFormCredit}/>
                                    </div>
                                </div>

                                {
                                    creatorFormDateTransfer && creatorFormDateFin && creatorFormUserDr[0] &&
                                    creatorFormUserCr[0] && creatorFormCurrency[0] && creatorFormDebit[0] &&
                                    creatorFormCredit[0] && creatorFormDescription && creatorFormSum &&
                                    <div className={"default-button button green-back"} style={{margin: "30px 0 -20px 0"}} onClick={handleCreateRow}>
                                        Добавить
                                    </div>
                                }

                            </div>
                        </div>
                    )}
                </Popup>
            </div>
            {
                props.COAInfo.rows_transfers[0] &&
                <div style={{color: "white", margin: "40px 20px 0 20px", textAlign: "left", fontSize: "14px"}}>
                    <div style={{display: "grid", gridTemplateColumns: "80px 80px 200px 100px 100px 90px 70px 90px 80px 1fr 80px 1fr 70px"}}>
                        <div>Tran Date</div>
                        <div>Fin Date</div>
                        <div>Description</div>
                        <div>User Dr</div>
                        <div>User Cr</div>
                        <div>Tran Amount</div>
                        <div>Tran Curr</div>
                        <div>Rep Amount</div>
                        <div>Dr</div>
                        <div>Dr name</div>
                        <div>Cr</div>
                        <div>Cr name</div>
                        <div>Curr rate</div>
                    </div>
                    {
                        props.COAInfo.rows_transfers.map((e, i) => {
                            return <div style={{display: "grid", gridTemplateColumns: "80px 80px 200px 100px 100px 90px 70px 90px 80px 1fr 80px 1fr 70px", backgroundColor: i % 2 == 0 ? "#4b5069" : "initial",}}>
                                <div>{formatDate(new Date(e.date_transfer))}</div>
                                <div>{formatDate(new Date(e.date_fin))}</div>
                                <div title={e.description}>{e.description.length > 20 ? `${e.description.substring(0, 20)}...` : e.description}</div>
                                <div title={e.debit_user_name}>{e.debit_user_name.length > 10 ? `${e.debit_user_name.substring(0, 10)}...` : e.debit_user_name}</div>
                                <div title={e.credit_user_name}>{e.credit_user_name.length > 10 ? `${e.credit_user_name.substring(0, 10)}...` : e.credit_user_name}</div>
                                <div>{e.transfer_sum}</div>
                                <div>{e.transfer_currency}</div>
                                <div>{(+e.transfer_sum_usd).toFixed(4)}</div>
                                <div>{e.debit_public_id}</div>
                                <div>{e.debit_name}</div>
                                <div>{e.credit_public_id}</div>
                                <div>{e.credit_name}</div>
                                <div>{(+e.exchange_rate).toFixed(4)}</div>
                            </div>
                        })
                    }
                </div>
            }

        </div>
    );
};

export default COATransfers;