import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const TextArea = ({value, onChange, placeholder, name, className}) => {
    return (
        <div className={classNames(s.textarea, className)}>
            <textarea rows={5} name={name} value={value} onChange={onChange} placeholder={placeholder}/>
        </div>
    );
};

export default TextArea;