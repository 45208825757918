import React, {useState, useRef} from "react";
import {FindUserByNameInArr} from "../../../../redux/reducers/adminReducer";

export default function AuditorEditor(props) {
    return (
            <div className={"auditor-editor-page"}>
                <div className={"columns-names"}>
                    <div className={"column-name"}>
                        Ник игрока
                    </div>
                    <div className={"column-name"}>
                        Направление
                    </div>
                    <div className={"column-name"}>
                        Статус игрока
                    </div>
                    <div className={"column-name"}>
                        Кол-во сетов
                    </div>
                    <div className={"column-name"}>
                        Румы игрока
                    </div>
                    <div className={"column-name"}>
                        Ответственный
                    </div>
                </div>
                <div className={"players-list"}>
                    {
                        props.AuditorInfo.searchPlayersList[0] ?
                            props.AuditorInfo.searchPlayersList.map((player, i) => <Player key={i + player.playerName}
                                                                                           player={player}
                                                                                           AdminEditPlayer={props.AdminEditPlayer}
                                                                                           AdminDeletePlayer={props.AdminDeletePlayer}
                                                                                           usersList={props.AdminInfo.usersList}
                                                                                           FindUserByNameInArr={props.FindUserByNameInArr}/>)
                            :
                            props.AuditorInfo.playersList.map((player, i) => <Player key={i + player.playerName}
                                                                                     player={player}
                                                                                     AdminEditPlayer={props.AdminEditPlayer}
                                                                                     AdminDeletePlayer={props.AdminDeletePlayer}
                                                                                     usersList={props.AdminInfo.usersList}
                                                                                     FindUserByNameInArr={props.FindUserByNameInArr}/>)
                    }
                </div>
            </div>
    );
}

const Player = (props) => {
    let editPlayerStatus = React.createRef();
    let editPlayerRooms = React.createRef();
    let editPlayerOwner = React.createRef();
    let editPlayerDirection = React.createRef();
    let editPlayerSets = React.createRef();
    const [eOwner, setEOwner] = useState(props.player.ownerName);

    const EditStatus = () => {
        props.AdminEditPlayer({
            type: "status",
            playerName: props.player.playerName,
            newValue: editPlayerStatus.current.value
        })
    }
    const EditDirection = () => {
        props.AdminEditPlayer({
            type: "direction",
            playerName: props.player.playerName,
            newValue: editPlayerDirection.current.value
        })
    }
    const EditSets = () => {
        props.AdminEditPlayer({
            type: "setsCount",
            playerName: props.player.playerName,
            newValue: editPlayerSets.current.value
        })
    }
    const EditRooms = () => {
        props.AdminEditPlayer({
            type: "rooms",
            playerName: props.player.playerName,
            newValue: editPlayerRooms.current.value
        })
    }
    const FindUserByNameInArr = () => {
        setEOwner(editPlayerOwner.current.value)
        props.FindUserByNameInArr(editPlayerOwner.current.value)
    }
    const Delete = () => {
        props.AdminDeletePlayer(props.player.playerName)
    }
    return (
        <div className={"player"}>
            <div className={"name"}>
                {props.player.playerName}
            </div>
            <div className={"column-data"}>
                <input ref={editPlayerDirection} onBlur={EditDirection} defaultValue={props.player.playerDirection}/>
            </div>
            <div className={"column-data"}>
                <input ref={editPlayerStatus} onBlur={EditStatus} defaultValue={props.player.playerStatus}/>
            </div>
            <div className={"column-data"}>
                <input ref={editPlayerSets} onBlur={EditSets} defaultValue={props.player.setsCount}/>
            </div>
            <div className={"column-data"}>
                <input ref={editPlayerRooms} onBlur={EditRooms} defaultValue={props.player.playerRooms}/>
            </div>
            <div className={"dropdown"}>
                <input onChange={FindUserByNameInArr} ref={editPlayerOwner} value={eOwner}/>
                <div className="dropdown-content">
                    {
                        props.usersList.map((user, i) => user.isSpinSupport || user.isCashSupport || user.isMTTSupport ||
                        user.isMTSNGSupport ?
                            <Support key={i + user.username} support={user} AdminEditPlayer={props.AdminEditPlayer}
                                     playerName={props.player.playerName} setEOwner={setEOwner}/>
                            : null)
                    }
                </div>
            </div>
            <div onClick={Delete} className={"deletePlayer"}>
                удалить игрока
            </div>
        </div>
    )
}

const Support = (props) => {
    const EditOwner = () => {
        props.setEOwner(props.support.username)
        props.AdminEditPlayer({
            type: "owner",
            playerName: props.playerName,
            newValue: props.support.id,
            newValue2: props.support.username
        })
    }
    return (
        <div onClick={EditOwner}>{props.support.username}</div>
    )
}