import React, {useState} from 'react';
import classNames from "classnames";
import s from "../styles.module.css";
import {formatDate} from "../../../utils/calendar_utils";
import PopoverAllEvents from "../../popover_show_all_events";
import CalendarSelectedData from "../calendar_selected_data";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import moment from "moment";

const CalendarWeek = ({
                          lastSelectedDate,
                          removeChooseDate,
                          index,
                          _date,
                          setLastSelectedDate,
                          test,
                          setPressLKMData,
                          pressLKMData
                      }) => {
    const date = _date.getDate()
    const dateName = _date.getDay()
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <PopoverAllEvents removeChooseDate={removeChooseDate} dateName={dateName}
                              test={test} day={+date} open={showModal}
                              handleClose={() => setShowModal(false)}/>
            <div
                className={classNames(
                    s.test3,
                    (index === 5 || index === 6) && s.week_day,
                    lastSelectedDate?.find((f) => formatDate(f) === formatDate(_date)) && s.select
                )}

                onMouseUp={() => {
                    setPressLKMData([])
                }}
                onMouseDown={(e) => {

                    setPressLKMData([_date, ...pressLKMData])
                    const checkerSimilarDate = lastSelectedDate.find((f) => formatDate(f) === formatDate(_date))

                    if (checkerSimilarDate) {
                        setLastSelectedDate(lastSelectedDate.filter(f => formatDate(f) !== formatDate(_date)))
                    } else {
                        setLastSelectedDate([...lastSelectedDate, _date])
                    }
                }}

                onMouseEnter={(e) => {
                    if (e.buttons === 1) {
                        const checkerSimilarDate = lastSelectedDate.find((f, i) => formatDate(f) === formatDate(_date))
                        const isNanDate = new Date(new Date(checkerSimilarDate).getTime() + 86400000)


                        const start = moment(pressLKMData.length > 0 && pressLKMData[0]);
                        const end = moment(_date);
                        const diff = end.diff(start, "days")
                        const mapingDate = []
                        const whatMore = start > end ? end : start

                        for (let i = 0; i < Math.abs(diff); i++) {
                            mapingDate.push(new Date(whatMore.add(1, 'd').format()))
                        }
                        let uniqueArray = [...pressLKMData, ...mapingDate]
                            .map(function (date) {
                                return date.getTime()
                            })
                            .filter(function (date, i, array) {
                                return array.indexOf(date) === i;
                            })
                            .map(function (time) {
                                return new Date(time);
                            });
                        setPressLKMData(uniqueArray)
                        // if (!isNaN(isNanDate)) {
                        //     setLastSelectedDate(lastSelectedDate.filter(f => formatDate(f) !== formatDate(isNanDate)))
                        // } else {
                        setLastSelectedDate([...lastSelectedDate,
                            ...mapingDate.slice(0, -1),
                            _date])
                        // }
                    }

                }}
            >
                <div
                    className={classNames(
                        s.day,
                        (index === 5 || index === 6) && s.week_day,
                        lastSelectedDate?.find((f) => formatDate(f) === formatDate(_date)) && s.select
                    )}
                >
                    <div
                        className={classNames(s.title_day, test.some(s => formatDate(s.date) === formatDate(_date)) && s.have_event_day_box)}>
                        <p className={classNames(s.day_in_box, test.some(s => formatDate(s.date) === formatDate(_date)) && s.have_event_day)}>
                            {date}
                        </p>
                        {test?.length > 0 && <div className={s.more} onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setShowModal(true)
                        }}>
                            <RemoveRedEyeIcon/>
                            {test?.length > 3 &&
                                <p>{`+ ${test.length - 3}`}</p>}
                        </div>}
                    </div>
                    <div
                        className={classNames(s.in_choosing,
                            test && s.have_in_choosing
                        )}>
                        {test.slice(0, 3).map((el) => <CalendarSelectedData
                            key={el.id} {...el}/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CalendarWeek;
