
import React from 'react';
import s from './styles.module.css'
import {ReactComponent as Faq} from "../../../assests/faq.svg";
import {ReactComponent as Video} from "../../../assests/video.svg";
import {ReactComponent as Support} from "../../../assests/support.svg";
import {ReactComponent as LongArrow} from "../../../assests/longArrow.svg";
import classNames from "classnames";

const headerItems = [
    // {title: 'FAQ', svg: <Faq/>},
    // {title: 'Видеоинструкция', svg: <Video/>},
    // {title: 'Обратиться в Саппорт', svg: <Support/>},
]

const headerBottomItems = [
    {order: 1, title: 'Выберите вариант получения', svg: <LongArrow/>},
    {order: 2, title: 'Выберите вариант ввода', svg: <LongArrow/>},
    {order: 3, title: 'Заполните реквизиты и нажмите Вывести деньги', svg: <LongArrow/>},
    {order: 4, title: 'Ожидайте получения средств'},
]

const HeaderExchanger = () => {
    return (
        <div className={s.box}>
            <div className={s.top}>
                <div className={s.top_left}>
                    <h2>Вывод средств</h2>
                    <p>Быстрый и надежный вывод средств на различные платежные системы.</p>
                </div>
                <div className={s.top_right}>
                    {headerItems.map((el, index) => <div className={classNames(s.header_item)}>
                        {el.svg}
                        <p>{el.title}</p>
                    </div>)}
                </div>
            </div>

            <div className={s.bottom}>
                {headerBottomItems.map((el, index) => <div className={s.header_bottom_item}>
                    <div className={s.order}>{el.order}</div>
                    <p>{el.title}</p>
                    {el.svg}
                </div>)}
            </div>
        </div>
    );
};

export default HeaderExchanger;