import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import s from './styles.module.css'
import {data} from "../../utils/calendar_utils";
import {Button} from "@mui/material";
import PopoverRemove from "../popover_remove";

const PopoverAllEvents = ({open, handleClose, day, test, dateName, removeChooseDate}) => {
    const [remove, setRemove] = useState(false)
    const [id, setId] = useState(null)
    return (
        <>
            <PopoverRemove id={id} remove={remove} setRemove={setRemove} removeChooseDate={(id) => {
                if (test.length === 1) {
                    handleClose()
                }
                removeChooseDate(id)
            }}
                           setId={setId}/>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        maxWidth: '480px',
                        width: '100%',
                        padding: '20px',
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                    <div className={s.header}>
                        <p>{day}</p>
                        <p>{data.weekDayNamesFull[dateName - 1]}</p>
                    </div>

                    <div className={s.content}>
                        {test.map((el, i) => {
                                return <div key={el.id} className={s.all_element}>
                                    <div>
                                    <span style={{
                                        fontSize: '14px',
                                        marginTop: '0px',
                                        height: '10px',
                                        marginRight: '10px'
                                    }}>{i + 1}</span>
                                        <span style={{
                                            fontSize: '14px',
                                            marginTop: '0px',
                                            height: '10px',
                                        }}>{el.name}</span>
                                        <span style={{
                                            margin: '0 10px',
                                            fontSize: '14px',
                                            marginTop: '0px',
                                            height: '10px',
                                        }}>{el.time}</span>
                                        <span style={{
                                            fontSize: '14px',
                                            marginTop: '0px',
                                            height: '10px',
                                        }}>{el.title}</span>
                                    </div>
                                    <div className={s.btns}>
                                        <Button variant="contained" color="error" onClick={() => {
                                            setId(el.id)
                                            setRemove(true)
                                        }}
                                                sx={{
                                                    fontSize: '9px',
                                                    padding: '5px 10px',
                                                    marginRight: '10px'
                                                }}>Удалить</Button>
                                    </div>
                                </div>
                            }
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default PopoverAllEvents;
