import React, {useState, useRef} from "react";
import ChangerTicketsContainer from "./ChangerTickets/changerTicketsContainer";

export default function MainPage(props) {
    return (
        <div className={"main-page"}>
            <iframe style={{width: "100%", height: "50vh", marginBottom: "20px" }} src="https://www.youtube.com/embed/j_IgUk0vVkw" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            <ChangerTicketsContainer/>
        </div>
    );
}
