import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const Input = ({value, onChange, title, placeholder, name, className, type}) => {
    return (
        <div className={classNames(s.input, className)}>
            <input type={type ? type : "text"} name={name} value={value} onChange={onChange} placeholder={placeholder}/>
            <span>{title}</span>
        </div>
    );
};

export default Input;