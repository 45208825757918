import React, {useState, useRef} from "react";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import ImgField from "../../../../v_2/common/fields/imgField";
import ColorText from "../../../../v_2/common/fields/colorText";
import NumberField from "../../../../v_2/common/fields/numberField";
import NumberCeilField from "../../../../v_2/common/fields/numberCeilField";
import NumberToFixed1Field from "../../../../v_2/common/fields/numberToFixed1Field";
import NumberToFixed2Field from "../../../../v_2/common/fields/numberToFixed2Field";
import TimestampToDateField from "../../../../v_2/common/fields/timestampToDateField";
import TimestampToPeriodField from "../../../../v_2/common/fields/timestampToPeriodField";
import TextField from "../../../../v_2/common/fields/textField";
import AcceptButtonField from "../../../../v_2/common/fields/acceptButtonField";
import DeclineButtonField from "../../../../v_2/common/fields/declineButtonField";
import s from "../../../../v_2/pages/FinResults/4-content/styles.module.css";

export default function Referral(props) {
    const [searchCashInsInput, setSearchCashInsInput] = useState(null);

    return (
        <div className={"admin-referral"}>
            <div className={"user-cash-ins"} style={{margin: "40px"}}>
                <div style={{display: "grid", gridTemplateColumns: "3fr 1fr"}}>
                    <input onChange={(e) => setSearchCashInsInput(e.target.value)} value={searchCashInsInput}
                           type="text"
                           placeholder={"Введите ID дискорда пользователя"}
                           style={{height: "50px", paddingLeft: "30px"}}/>
                    <div onClick={() => props.SupportGetUserCashInfo(searchCashInsInput)}
                         className={"green-back"} style={{
                        cursor: "pointer",
                        height: "50px",
                        color: "white",
                        textAlign: "center",
                        padding: "12px"
                    }}>
                        Поиск
                    </div>
                </div>
                {
                    props.ReferralInfo.support_user_cash_ins[0] ?
                        <div className={"data-table"}>
                            <div style={{textAlign: "left", color: "white", margin: "18px 0 18px 5px", fontSize: "20px"}}>
                                Найденный пользователь: <span style={{fontWeight: "bolder"}}>{props.ReferralInfo.support_user_cash_ins[0].target_cash}</span>
                            </div>
                            <div style={{textAlign: "left", color: "white", margin: "18px 0 24px 5px", fontSize: "20px"}}>
                                Текущая доступная сумма к выводу: <span style={{fontWeight: "bolder"}}>{props.ReferralInfo.support_user_available_sum}</span>
                            </div>

                            <div className='ag-theme-alpine-dark'
                                 style={{height: 400, width: '100%', position: 'relative', marginTop: "20px"}}>
                                <AgGridReact
                                    style={{height: 400, width: '100%'}}
                                    rowData={props.ReferralInfo.support_user_cash_outs}
                                    enableBrowserTooltips={true}
                                    tooltipShowDelay={0}
                                    tooltipHideDelay={2000}
                                    rowHeight={70}
                                    defaultColDef={{
                                        editable: true,
                                        sortable: true,
                                        flex: 1,
                                        minWidth: 100,
                                        filter: false,
                                        floatingFilter: false,
                                        resizable: true,
                                    }}
                                    frameworkComponents={{
                                        imgField: ImgField,
                                        colorText: ColorText,
                                        numberField: NumberField,
                                        numberCeilField: NumberCeilField,
                                        numberToFixed1Field: NumberToFixed1Field,
                                        numberToFixed2Field: NumberToFixed2Field,
                                        timestampToDateField: TimestampToDateField,
                                        timestampToPeriodField: TimestampToPeriodField,
                                        textField: TextField,
                                        acceptButtonField: AcceptButtonField,
                                        declineButtonField: DeclineButtonField
                                    }}
                                >
                                    <AgGridColumn
                                        headerClass={s.header_styles}
                                        headerName={'Дата вывода'}
                                        field={'init_date'}
                                        editable={false}
                                        sortable={true}
                                        filter={false}
                                        cellRenderer='textField'
                                    />
                                    <AgGridColumn
                                        headerClass={s.header_styles}
                                        headerName={'Вывод'}
                                        field='sum'
                                        editable={false}
                                        sortable={true}
                                        filter={false}
                                        cellRenderer='textField'
                                    />
                                    <AgGridColumn
                                        headerClass={s.header_styles}
                                        headerName={'Валюта'}
                                        field={"currency"}
                                        editable={false}
                                        sortable={true}
                                        filter={false}
                                        cellRenderer='textField'
                                    />
                                </AgGridReact>
                            </div>

                            <div className='ag-theme-alpine-dark'
                                 style={{height: 400, width: '100%', position: 'relative', marginTop: "20px"}}>
                                <AgGridReact
                                    style={{height: 400, width: '100%'}}
                                    rowData={props.ReferralInfo.support_user_cash_ins}
                                    enableBrowserTooltips={true}
                                    tooltipShowDelay={0}
                                    tooltipHideDelay={2000}
                                    rowHeight={70}
                                    defaultColDef={{
                                        editable: true,
                                        sortable: true,
                                        flex: 1,
                                        minWidth: 100,
                                        filter: false,
                                        floatingFilter: false,
                                        resizable: true,
                                    }}
                                    frameworkComponents={{
                                        imgField: ImgField,
                                        colorText: ColorText,
                                        numberField: NumberField,
                                        numberCeilField: NumberCeilField,
                                        numberToFixed1Field: NumberToFixed1Field,
                                        numberToFixed2Field: NumberToFixed2Field,
                                        timestampToDateField: TimestampToDateField,
                                        timestampToPeriodField: TimestampToPeriodField,
                                        textField: TextField,
                                        acceptButtonField: AcceptButtonField,
                                        declineButtonField: DeclineButtonField
                                    }}
                                >
                                    <AgGridColumn
                                        headerClass={s.header_styles}
                                        headerName={'Дата начисления'}
                                        field={'init_date'}
                                        editable={false}
                                        sortable={true}
                                        filter={false}
                                        cellRenderer='textField'
                                    />
                                    <AgGridColumn
                                        headerClass={s.header_styles}
                                        headerName={'Начислено за (ник)'}
                                        field='user_cash'
                                        editable={false}
                                        sortable={true}
                                        filter={false}
                                        cellRenderer='textField'
                                    />
                                    <AgGridColumn
                                        headerClass={s.header_styles}
                                        headerName={'Начислено за (ID)'}
                                        field='user_cash_id'
                                        editable={false}
                                        sortable={true}
                                        filter={false}
                                        cellRenderer='textField'
                                    />
                                    <AgGridColumn
                                        headerClass={s.header_styles}
                                        headerName={'Начислено'}
                                        field='sum'
                                        editable={false}
                                        sortable={true}
                                        filter={false}
                                        cellRenderer='textField'
                                    />
                                    <AgGridColumn
                                        headerClass={s.header_styles}
                                        headerName={'Валюта'}
                                        field={"currency"}
                                        editable={false}
                                        sortable={true}
                                        filter={false}
                                        cellRenderer='textField'
                                    />
                                </AgGridReact>
                            </div>

                        </div>
                        : null
                }
            </div>
        </div>
    );
}
