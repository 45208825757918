import React, {useState, useRef} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

export default function Transfers(props) {
    let player_name = React.createRef();
    let account = React.createRef();
    let target = React.createRef();
    let method = React.createRef();
    let chip_sum = React.createRef();
    let chip_tax = React.createRef();
    let usd_sum = React.createRef();
    let usd_tax = React.createRef();

    // id player_name account timestamp target method chip_sum chip_tax usd_sum usd_tax status
    return (
        <div className={"training-connectors-list-page"}>
            <div style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 20px"}}
                 className={"columns-names"}>
                <div className={"column-name"}>
                    request_id
                </div>
                <div className={"column-name"}>
                    player_name
                </div>
                <div className={"column-name"}>
                    account
                </div>
                <div className={"column-name"}>
                    date
                </div>
                <div className={"column-name"}>
                    target
                </div>
                <div className={"column-name"}>
                    method
                </div>
                <div className={"column-name"}>
                    chip_sum
                </div>
                <div className={"column-name"}>
                    chip_tax
                </div>
                <div className={"column-name"}>
                    usd_sum
                </div>
                <div className={"column-name"}>
                    usd_tax
                </div>
                <div className={"column-name"}>
                    status
                </div>
            </div>
            <div className={"training-connectors-list"}>
                <div id="scrollableDiv" style={{height: "85vh", overflow: "auto"}}>
                    <div key={`creator transferrow`}
                         style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"}}
                         className={"connector"}>
                        <div className={"name"}>
                            ---
                        </div>
                        <input ref={player_name}/>
                        <input ref={account}/>
                        <div className={"name"}>
                            {moment(+new Date).format("DD.MM.YYYY")}
                        </div>
                        <input ref={target}/>
                        <input ref={method}/>
                        <input ref={chip_sum}/>
                        <input ref={chip_tax}/>
                        <input ref={usd_sum}/>
                        <input ref={usd_tax}/>
                        <div>
                            {
                                !props.isLoading && <div
                                    style={{padding: "5px 0", borderRadius: "2px", fontSize: "13px", cursor: "pointer"}}
                                    onClick={() => props.SupportUpdateTransfer({
                                        type: "add",
                                        player_name: player_name.current.value,
                                        target: target.current.value,
                                        method: method.current.value,
                                        account: account.current.value,
                                        usd_sum: usd_sum.current.value,
                                        chip_sum: chip_sum.current.value,
                                        usd_tax: usd_tax.current.value,
                                        chip_tax: chip_tax.current.value
                                    })}
                                    className={"transition green-back"}>
                                    Создать
                                </div>
                            }
                        </div>
                    </div>

                    <InfiniteScroll
                        dataLength={props.FormularInfo.supportTransfersRows.length}
                        next={props.fetchMoreData}
                        hasMore={props.FormularInfo.supportTransfersRows.length <= props.FormularInfo.supportTransfersRowsCount}
                        scrollableTarget="scrollableDiv"
                    >

                        {
                            props.FormularInfo.supportTransfersRows.map((row, i) => {
                                // id player_name account timestamp target method chip_sum chip_tax usd_sum usd_tax status
                                return <div key={`${i} transferrow`}
                                            style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"}}
                                            className={"connector"}>
                                    <input className={"name"} value={row.id}/>
                                    <input className={"name"} value={row.player_name}/>
                                    <input className={"name"} value={row.account}/>
                                    <div className={"name"}>
                                        {moment(+row.timestamp).format("DD.MM.YYYY")}
                                    </div>
                                    <input className={"name"} value={row.target}/>
                                    <input className={"name"} value={row.method}/>
                                    <input className={"name"} value={row.chip_sum}/>
                                    <input className={"name"} value={row.chip_tax}/>
                                    <input className={"name"} value={row.usd_sum}/>
                                    <input className={"name"} value={row.usd_tax}/>
                                    <div className={"name"}>
                                        {row.status == "1" ? "Новый" : row.status == "2" ? "Игрок ОК" : row.status == "3" ? "Сервер ОК" : row.status == "4" ? "Оспаривание" : "Выплачено"}
                                    </div>
                                </div>
                            })
                        }
                    </InfiniteScroll>
                </div>

            </div>
        </div>
    );
}
