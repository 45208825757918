import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import ChangerBanks from "./changerBanks";
import {AdminDeleteBank, AdminEditBank, AdminGetBanksList, AdminChangeServerConverter} from "../../../../redux/reducers/fsChangerReducer";

class ChangerBanksContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Список банков для обмена")
        this.props.AdminGetBanksList()
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <ChangerBanks {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer,
    FsChangerInfo: state.fsChangerReducer
})

export default compose(
    connect(mapStateToProps, {
        AdminGetBanksList, AdminDeleteBank, AdminEditBank, AdminChangeServerConverter
    }),
    withRouter
)(ChangerBanksContainer)