import React, {useState, useRef} from "react"
import MainLoading from "../../common/mainLoading";
import s from "./styles.module.css"

export default function WikiViewer(props) {
    return (
            <div><div  className={s.page} dangerouslySetInnerHTML={{__html: props.WikiInfo.currentPage.content}}/></div>

    );
}
