import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import s from "./styles.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {NavLink, useLocation} from "react-router-dom";
import {NavLinkProvider, ShowToolTip} from "../index";
import ThirdAccordion from "../third_accordion";


const SecondAccordion = ({
                             item,
                             title,
                             isAccordion,
                             svgComponent,
                             path,
                             setSelected,
                             selected,
                             hiddenLeft,
                             data,
                             setLenghtTherd,
                             lenghtTird,
                             setSelectSecond,
                             setSelectFirst,
                             setSelectThird,
                             selectSecond,
                             selectThird,

                         }) => {
    const location = useLocation()

    const [showSubData, setShowSubData] = useState(false)

    const handlerAccordion = (e) => {
        // e.stopPropagation()
        setShowSubData(!showSubData)
    }


    useEffect(() => {
        setSelected(location.pathname)
    }, [location.pathname])

    return (
        <NavLinkProvider path={path} isAccordion={item?.isAccordion}>
            <ShowToolTip hiddenLeft={hiddenLeft} title={title}>
                <div
                    className={classNames(
                        s.item,
                        selected === path && s.active,
                        hiddenLeft && s.hiddenLeft,
                        (((selectSecond) && (selectSecond === item?.selectSecond2)) ? s.active : ''),
                        // (((selectThird) && (selectThird === item?.selectSecond2)) ? s.active : ''),
                    )}
                    onClick={(e) => {
                        // setSelected(path)
                        handlerAccordion(e)
                        setLenghtTherd(item?.data?.length)
                        setSelectSecond(item?.selectSecond2 ? item?.selectSecond2 : null)
                        setSelectFirst(item?.selectFirstParent ? item?.selectFirstParent : null)
                    }}>
                    {svgComponent}
                    {isAccordion &&
                        <div className={classNames(s.arrow, showSubData && s.rotateArrow,hiddenLeft && s.arrowHidden)}><ExpandMoreIcon/></div>}
                    {!hiddenLeft && <p>{title}</p>}
                </div>
            </ShowToolTip>

            <div className={s.subData}
                 style={{height: showSubData && `${item?.data?.length * 49}px`}}
            >
                {data?.map((item, index) => <ThirdAccordion
                    setSelectSecond={setSelectSecond}
                    setSelectFirst={setSelectFirst}
                    setSelectThird={setSelectThird}
                    key={index + item.path}
                    hiddenLeft={hiddenLeft} item={item} {...item}
                    setSelected={setSelected} selected={selected}/>)}
            </div>
        </NavLinkProvider>
    );
};

export default SecondAccordion;