
import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const GetCurrency = (props) => {
    const UserSetCurrentBank = () => {
        props.setSelectGiveMoney(props.item._id)
        props.UserSetCurrentBank(props.item)
    }
    return (
        <div className={classNames(s.item, props.select && s.active)} onClick={UserSetCurrentBank}>
            <div className={s.first}>
                <img src={props.item.png_logo} alt='...'/>
                <p>{props.item.public_name}</p>
            </div>
            <span>{props.item.public_type}</span>
        </div>
    );
};

export default GetCurrency;