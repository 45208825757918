import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";
import {
    FIN_RESULTS_API,
    FORMULAR_ROWS_API,
    FS_CHANGER_API,
    PROFILE_CUSTOM_INFO_API,
    TRANSFERS_API
} from "../../api/util";
import Cookies from "js-cookie";

const FORMULAR_SUPPORT_SET_CLUBS = 'FORMULAR_SUPPORT_SET_CLUBS';
const FORMULAR_SUPPORT_SET_PLAYER_INFO = 'FORMULAR_SUPPORT_SET_PLAYER_INFO';
const FORMULAR_SUPPORT_SET_NEW_CLUB = 'FORMULAR_SUPPORT_SET_NEW_CLUB';
const FORMULAR_SUPPORT_SET_NEW_PLAYER_ROW = 'FORMULAR_SUPPORT_SET_NEW_PLAYER_ROW';
const FORMULAR_SUPPORT_SET_RESULTS_LIST = 'FORMULAR_SUPPORT_SET_RESULTS_LIST';
const FORMULAR_SUPPORT_SET_TRANSFERS_LIST = 'FORMULAR_SUPPORT_SET_TRANSFERS_LIST';
const FORMULAR_SUPPORT_DELETE_PLAYER_ROW = 'FORMULAR_SUPPORT_DELETE_PLAYER_ROW';
const FORMULAR_SUPPORT_SET_NEW_TRANSFER = 'FORMULAR_SUPPORT_SET_NEW_TRANSFER';
const FORMULAR_SUPPORT_UPDATE_FIN_RESULT_ROW = 'FORMULAR_SUPPORT_UPDATE_FIN_RESULT_ROW';
const FORMULAR_SUPPORT_UPDATE_FIN_RESULT_ROWS_SET = 'FORMULAR_SUPPORT_UPDATE_FIN_RESULT_ROWS_SET';
const FORMULAR_SUPPORT_SPIN_SET_CONTRACTS_ROWS = 'FORMULAR_SUPPORT_SPIN_SET_CONTRACTS_ROWS';
const FORMULAR_SUPPORT_MTT_SET_CONTRACTS_ROWS = 'FORMULAR_SUPPORT_MTT_SET_CONTRACTS_ROWS';
const FORMULAR_SUPPORT_SPIN_EDIT_PROFILE = 'FORMULAR_SUPPORT_SPIN_EDIT_PROFILE';

let initialState = {
    clubsList: [],
    playerInfo: [],

    supportResultsRows: [],
    supportResultsRowsCount: null,
    supportResultsRowsCurrentCount: null,
    supportResultsRowsCurrentFilters: {},

    supportTransfersRows: [],
    supportTransfersRowsCount: null,
    supportTransfersRowsCurrentCount: null,

    spinContractRows: [],
    mttContractRows: []
};

const formularReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORMULAR_SUPPORT_SPIN_EDIT_PROFILE: {
            return {
                ...state
            }
        }
        case FORMULAR_SUPPORT_MTT_SET_CONTRACTS_ROWS: {
            return {
                ...state,
                mttContractRows: JSON.parse(JSON.stringify(action.data))
            }
        }
        case FORMULAR_SUPPORT_SPIN_SET_CONTRACTS_ROWS: {
            return {
                ...state,
                spinContractRows: JSON.parse(JSON.stringify(action.data))
            }
        }
        case FORMULAR_SUPPORT_UPDATE_FIN_RESULT_ROWS_SET: {
            let newList = JSON.parse(JSON.stringify(state.supportResultsRows));
            action.data.forEach(e => {
                const FinResRowIndex = newList.findIndex(ee => ee._id == e._id);
                if (FinResRowIndex !== -1) {
                    state.supportResultsRows[FinResRowIndex].setCount = e.setCount;
                    newList[FinResRowIndex].setCount = e.setCount;
                }
            })

            return {
                ...state,
                supportResultsRows: JSON.parse(JSON.stringify(newList))
            }
        }
        case FORMULAR_SUPPORT_UPDATE_FIN_RESULT_ROW: {
            const rowIndex = state.supportResultsRows.findIndex(e => e._id == action.row._id)
            if (rowIndex != -1) state.supportResultsRows[rowIndex] = JSON.parse(JSON.stringify(action.row))
            return state;
        }
        case FORMULAR_SUPPORT_SET_NEW_TRANSFER: {
            return {
                ...state,
                supportTransfersRows: [action.transfer, ...state.supportTransfersRows],
                supportTransfersRowsCount: state.supportTransfersRowsCount ? state.supportTransfersRowsCount += 1 : 1,
                supportTransfersRowsCurrentCount: state.supportTransfersRowsCurrentCount ? state.supportTransfersRowsCurrentCount += 1 : 1
            }
        }
        case FORMULAR_SUPPORT_DELETE_PLAYER_ROW: {
            let rows = JSON.parse(JSON.stringify(state.playerInfo));
            let rowIndex = rows.findIndex(e => e._id == action._id);
            if (rowIndex != -1) {
                rows.splice(rowIndex, 1);
                return {
                    ...state,
                    playerInfo: JSON.parse(JSON.stringify(rows))
                }
            } else {
                return state;
            }
        }
        case FORMULAR_SUPPORT_SET_RESULTS_LIST: {
            if (action.changeType == "set") {
                return {
                    ...state,
                    supportResultsRows: [...action.list],
                    supportResultsRowsCount: action.count,
                    supportResultsRowsCurrentCount: 50,
                    supportResultsRowsCurrentFilters: JSON.parse(JSON.stringify(action.filters))
                }
            } else if (action.changeType == "new") {
                return {
                    ...state,
                    supportResultsRows: [...action.list],
                    supportResultsRowsCount: action.count,
                    supportResultsRowsCurrentCount: action.list.length
                }
            } else {
                return {
                    ...state,
                    supportResultsRows: [...state.supportResultsRows, ...action.list],
                    supportResultsRowsCount: action.count,
                    supportResultsRowsCurrentCount: state.supportResultsRowsCurrentCount + 50
                }
            }
        }
        case FORMULAR_SUPPORT_SET_TRANSFERS_LIST: {
            if (action.changeType == "set") {
                return {
                    ...state,
                    supportTransfersRows: [...action.list],
                    supportTransfersRowsCount: action.count,
                    supportTransfersRowsCurrentCount: 50
                }
            } else if (action.changeType == "new") {
                return {
                    ...state,
                    supportTransfersRows: [...action.list],
                    supportTransfersRowsCount: action.count,
                    supportTransfersRowsCurrentCount: action.list.length
                }
            } else {
                return {
                    ...state,
                    supportTransfersRows: [...state.supportTransfersRows, ...action.list],
                    supportTransfersRowsCount: action.count,
                    supportTransfersRowsCurrentCount: state.supportTransfersRowsCurrentCount + 50
                }
            }
        }
        case FORMULAR_SUPPORT_SET_CLUBS: {
            return {
                ...state,
                clubsList: JSON.parse(JSON.stringify(action.clubs))
            }
        }
        case FORMULAR_SUPPORT_SET_PLAYER_INFO: {
            return {
                ...state,
                playerInfo: JSON.parse(JSON.stringify(action.info))
            }
        }
        case FORMULAR_SUPPORT_SET_NEW_CLUB: {
            return {
                ...state,
                clubsList: [JSON.parse(JSON.stringify(action.row)), ...state.clubsList]
            }
        }
        case FORMULAR_SUPPORT_SET_NEW_PLAYER_ROW: {
            return {
                ...state,
                playerInfo: [JSON.parse(JSON.stringify(action.row)), ...state.playerInfo]
            }
        }
        default:
            return state;
    }
};

const SupportSetClubs = (clubs) => ({type: FORMULAR_SUPPORT_SET_CLUBS, clubs})
const SupportSetPlayerInfo = (info) => ({type: FORMULAR_SUPPORT_SET_PLAYER_INFO, info})
const SupportSpinSetContractsRows = (data) => ({type: FORMULAR_SUPPORT_SPIN_SET_CONTRACTS_ROWS, data});
const SupportSpinEditProfile = () => ({type: FORMULAR_SUPPORT_SPIN_EDIT_PROFILE});

export const FormularSupportEditUserSPIN = (id, direction, mainType, type, subType, newValue) => {
    return (dispatch) => {
        let body = {
            id, type, newValue, direction, mainType, subType,
            token: Cookies.get('token')
        }
        PROFILE_CUSTOM_INFO_API.supportEditUserSPIN(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение данных о пользователе SPIN профайл`, data.message))
                    return
                }
                dispatch(SupportSpinEditProfile(data.profile, data.templates))
            })
    }
}

export const SupportGetSpinContracts = () => {
    return (dispatch) => {
        FORMULAR_ROWS_API.supportGetSpinContracts()
            .then(data => {
                if (data.resultCode && data.resultCode != 1)
                    return dispatch(AddNotification("error", "Получение списка контрактов", data.message))
                dispatch(SupportSpinSetContractsRows(data))
            })
    }
}

const SupportMTTSetContractsRows = (data) => ({type: FORMULAR_SUPPORT_MTT_SET_CONTRACTS_ROWS, data});
export const SupportGetMTTContracts = () => {
    return (dispatch) => {
        FORMULAR_ROWS_API.supportGetMTTContracts()
            .then(data => {
                if (data.resultCode && data.resultCode != 1)
                    return dispatch(AddNotification("error", "Получение списка контрактов", data.message))
                dispatch(SupportMTTSetContractsRows(data))
            })
    }
}
export const SupportGetClubs = () => {
    return (dispatch) => {
        FORMULAR_ROWS_API.supportGetClubs()
            .then(data => {
                if (data.resultCode && data.resultCode != 1)
                    return dispatch(AddNotification("error", "Получение списка клубов", data.message))
                dispatch(SupportSetClubs(data))
            })
    }
}

export const SupportGetPlayerInfo = (player_id, club_name, from_timestamp, to_timestamp) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        FORMULAR_ROWS_API.supportGetPlayerInfo(player_id, club_name, from_timestamp, to_timestamp)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1)
                    return dispatch(AddNotification("error", "Получение информации о пользователе", data.message))
                dispatch(SupportSetPlayerInfo(data))
            })
    }
}

const SupportSetNewClub = (row) => ({type: FORMULAR_SUPPORT_SET_NEW_CLUB, row})
const SupportSetNewPlayerRow = (row) => ({type: FORMULAR_SUPPORT_SET_NEW_PLAYER_ROW, row})
const SupportDeletePlayerRow = (_id) => ({type: FORMULAR_SUPPORT_DELETE_PLAYER_ROW, _id})
export const SupportUpdateClub = (b) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            ...b,
            token: Cookies.get("token")
        }
        FORMULAR_ROWS_API.supportUpdateClub(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1)
                    return dispatch(AddNotification("error", "Добавление / изменение клуба", data.message))
                if (b.type == "create") dispatch(SupportSetNewClub(data))
            })
    }
}

export const SupportUpdatePlayer = (b) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            ...b,
            token: Cookies.get("token")
        }
        FORMULAR_ROWS_API.supportUpdatePlayer(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1)
                    return dispatch(AddNotification("error", "Добавление / изменение строки игрока", data.message))
                if (b.type == "create") dispatch(SupportSetNewPlayerRow(data));
                if (b.type == "delete") dispatch(SupportDeletePlayerRow(b._id))
            })
    }
}

export const PushIdsData = (data) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            data,
            token: Cookies.get("token")
        }
        FIN_RESULTS_API.pushIdsData(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1)
                    return dispatch(AddNotification("error", "Ошибка", data.message))

                return dispatch(AddNotification("success", "Добавление / изменение ID's", "Успешно"))
            })
    }
}

export const PushResultsData = (data) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            data,
            token: Cookies.get("token")
        }
        FIN_RESULTS_API.pushResultsData(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(ChangeMainLoading(false))
                    return dispatch(AddNotification("error", "Ошибка", data.message))
                }

                window.location.href = '/support/formular/finresults/list';
            })
    }
}

const SupportSetResultsList = (list, changeType, count, filters) => ({
    type: FORMULAR_SUPPORT_SET_RESULTS_LIST,
    list,
    changeType,
    count, filters
})

const SupportSetTransfersList = (list, changeType, count) => ({
    type: FORMULAR_SUPPORT_SET_TRANSFERS_LIST,
    list,
    changeType,
    count
})
const SupportUpdateRow = (row) => ({
    type: FORMULAR_SUPPORT_UPDATE_FIN_RESULT_ROW, row
})
export const SupportChangeRow = (_id, key, newValue) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            _id, key, newValue: +newValue,
            token: Cookies.get('token')
        }
        FIN_RESULTS_API.supportChangeRow(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение данных`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportUpdateRow(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

const SupportUpdateRowsSet = (data) => ({type: FORMULAR_SUPPORT_UPDATE_FIN_RESULT_ROWS_SET, data})
export const SupportUpdateSet = (nickname) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get('token'),
            nickname
        }
        FIN_RESULTS_API.supportUpdateSet(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Проставление сетов`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(AddNotification("success", `Сет успешно проставлен (номер сета: ${data[0].setCount})`))
                dispatch(SupportUpdateRowsSet(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const SupportGetResultsList = (changeType, skipElements, limitElements, filters = {}) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            skipElements,
            limitElements,
            token: Cookies.get('token'),
            ...filters
        }
        FIN_RESULTS_API.supportGetResultsList(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка результатов`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                if (changeType == 'set') {
                    dispatch(SupportSetResultsList([], changeType, null, filters))
                    dispatch(SupportSetResultsList(data.docs ? data.docs : [], changeType, data.count ? data.count : null, filters))
                } else {
                    dispatch(SupportSetResultsList(data.docs ? data.docs : [], changeType, data.count ? data.count : null, filters))
                }
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const SupportGetTransfersList = (changeType, skipElements, limitElements) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        TRANSFERS_API.supportGetTransfersList(skipElements, limitElements)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка трансферов`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                if (changeType == 'set') {
                    dispatch(SupportSetTransfersList([], changeType, null))
                    dispatch(SupportSetTransfersList(data.docs ? data.docs : [], changeType, data.count ? data.count : null))
                } else {
                    dispatch(SupportSetTransfersList(data.docs ? data.docs : [], changeType, data.count ? data.count : null))
                }
                dispatch(ChangeMainLoading(false))
            })
    }
}

const SupportSetNewTransfer = (transfer) => ({
    type: FORMULAR_SUPPORT_SET_NEW_TRANSFER,
    transfer
})
export const SupportUpdateTransfer = (b) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        const body = {
            ...b,
            token: Cookies.get("token")
        }
        TRANSFERS_API.supportUpdateTransfer(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Добавление трансфера`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportSetNewTransfer(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export default formularReducer;