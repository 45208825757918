import React from 'react';
import s from './styles.module.css'
import goldBorder from '../../assests/gold_border.png'
import blueBorder from '../../assests/blue_border.png'
import {ReactComponent as GoldBorderBottom} from '../../assests/gold_border_bottom.svg'
import {ReactComponent as BlueBorderBottom} from '../../assests/blue_border_bottom.svg'
import classNames from "classnames";

const Speedometr = ({title, max = 100, current = 0, type, className}) => {
    const convert = ((current / max) * 100).toFixed(0)

    const getNumberDeg = (leftNum, rightNum, percent) => {
        return leftNum + ((rightNum - leftNum) / 100) * percent
    }


    return (
        <div className={classNames(s.speedometr, className)}>
            <img src={type === 'gold' ? goldBorder : blueBorder} className={s.line}/>
            {type === 'gold' && <GoldBorderBottom className={s.line2}/>}
            {type === 'blue' && <BlueBorderBottom className={s.line2}/>}

            <div className={s.arrow} style={{
                left: `${convert}%`,
                transform: `rotate(${getNumberDeg(-40, 40, convert)}deg)`,
                top: (convert <= 30 && '0px') || (convert >= 70 && '0px')
            }}/>

            <p>{title}</p>

        </div>
    );
};

export default Speedometr;