import React from 'react';
import s from "../../../right_side_bar/styles.module.css";
import UserMain from "../../../../common/user_main";
import SpedometrMain from "../../../../common/spedometr_main";
import PlayersMain from "../../../../common/players_main";
import {whatShowRightPanel} from "../../../../helpers/whatShowRightPanel";
import {useLocation} from "react-router-dom";
import Cookies from "js-cookie";
const UserMobileMenu = (props) => {
    const dataEmail = Cookies.get("email");
    const location = useLocation()

    return (
        <div>
            <UserMain email={dataEmail} username={Cookies.get("username")} avatar={props.MainInfo.mainPageData.avatar} AuthInfo={props.AuthInfo}/>


            {/*<SpedometrMain min={props.MainInfo.mainPageData.sums.period_hands} max={30000} maxS={30000} currentS={props.MainInfo.mainPageData.sums.period_hands}*/}
            {/*               titleS={props.MainInfo.mainPageData.sums.period_hands} typeS={'blue'} classNameS={s.blue} title={'Сыграно раздач'} title_help={"В данном счетчике указана рекомендуемая дистанция для игры в Cash. \n" +*/}
            {/*    "Подсчет турниров ведется за календарный месяц."}/>*/}

            {/*<SpedometrMain min={props.MainInfo.mainPageData.sums.period_profit ? props.MainInfo.mainPageData.sums.period_profit : 0} max={'$10 000'} maxS={10000} currentS={props.MainInfo.mainPageData.sums.period_profit ? props.MainInfo.mainPageData.sums.period_profit : 0}*/}
            {/*               titleS={'$10 000'} typeS={'gold'} classNameS={s.blue} title={'Профит'} title_help={"Подсчет профита ведется за календарный месяц.\n" +*/}
            {/*    "Мы добавим возможность устанавливать себе цели для каждого игрока в последующих обновлениях."}/>*/}

            {/*<PlayersMain formular_player_share_percent={props.MainInfo.mainPageData.formular_player_share_percent}/>*/}
            {whatShowRightPanel(location.pathname, false, {...props})}
        </div>
    );
};

export default UserMobileMenu;