import React, {useMemo} from 'react';
import s from './styles.module.css'
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import ColorText from "../../../common/fields/colorText";
import CustomHeader from "../../../common/fields/customHeader";
import ImgField from "../../../common/fields/imgField";
import NumberField from "../../../common/fields/numberField";
import Progress from "../../../common/fields/progress";
import Money from "../../../common/fields/money";
import StatusText from "../../../common/fields/status";
import NumberCeilField from "../../../common/fields/numberCeilField";
import NumberToFixed1Field from "../../../common/fields/numberToFixed1Field";
import NumberToFixed2Field from "../../../common/fields/numberToFixed2Field";
import TimestampToDateField from "../../../common/fields/timestampToDateField";
import TimestampToTimeField from "../../../common/fields/timestampToTimeField";
import TimestampToPeriodField from "../../../common/fields/timestampToPeriodField";
import TextField from "../../../common/fields/textField";
import AcceptButtonField from "../../../common/fields/acceptButtonField";
import DeclineButtonField from "../../../common/fields/declineButtonField";
import RefWithdrawStatus from "../../../common/fields/refWithdrawStatus";

const row = [
    {date: '08.09.2022', name: 'Jane Cooper', accrual: '+75$'},
    {date: '30.08.2022', name: 'Jane Cooper', accrual: '+75$'},
    {date: '07.09.2022', name: 'Jane Cooper', accrual: '+75$'},
    {date: '02.09.2022', name: 'Jane Cooper', accrual: '+75$'},
]
const row2 = [
    {date: '08.09.2022', sum: '$ 621,00', comision: '$1,01',remains:'$0'},
    {date: '30.08.2022', sum: '$ 1233', comision: '$1,01',remains:'$1,01'},
    {date: '07.09.2022', sum: '$ 3641,00', comision: '$1,01',remains:'$0'},
    {date: '02.09.2022', sum: '$ 75', comision: '$1,01',remains:'$0'},
]
const LastTables = (props) => {
    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    return (
        <div className={s.last_tables_box}>
            <div className={s.left}>
                <h3>Последние начисления:</h3>
                <div>
                    <div className='ag-theme-alpine-dark'
                         style={{height: 424, width: '100%', position: 'relative', marginTop: '33px'}}>
                        <AgGridReact
                            style={{height: 424, width: '100%'}}
                            rowData={props.SalaryInfo.full_info.cash_ins}
                            enableBrowserTooltips={true}
                            tooltipShowDelay={0}
                            tooltipHideDelay={20}
                            rowHeight={70}
                            components={components}
                            suppressMenu={true}
                            defaultColDef={{
                                editable: true,
                                sortable: true,
                                flex: 1,
                                minWidth: 100,
                                filter: false,
                                floatingFilter: false,
                                resizable: true,
                                menuTabs: false,
                                suppressMenu: true,
                                headerComponentParams: {
                                    menuIcon: 'fa-bars',
                                },
                            }}
                            frameworkComponents={{
                                colorText: ColorText,
                                timestampToDateField: TimestampToDateField,
                                timestampToTimeField: TimestampToTimeField,
                            }}


                        >
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Тип'}
                                field='salary_type'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='colorText'
                                suppressMenu={true}
                                // tooltip by ag-grid
                                tooltipField='PR'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={`Дата`}
                                field='init_timestamp'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRendererFramework='timestampToDateField'
                                cellRendererParams={{
                                    color: '#fff'
                                }}

                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Период'}
                                field='period'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='colorText'
                                suppressMenu={true}
                                // tooltip by ag-grid
                                tooltipField='PR'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Отдел'}
                                field='user_division'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='colorText'
                                suppressMenu={true}
                                // tooltip by ag-grid
                                tooltipField='PR'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Описание'}
                                field='description'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='colorText'
                                suppressMenu={true}
                                // tooltip by ag-grid
                                tooltipField='PR'
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Начислено'}
                                field='sum'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='colorText'
                                cellRendererParams={{
                                    color: '#1089F9',
                                    isCurrency: true
                                }}
                            />
                        </AgGridReact>
                    </div>
                </div>
            </div>
            <div className={s.right}>
                <h3>Список выплат:</h3>
                <div>
                    <div className='ag-theme-alpine-dark'
                         style={{height: 424, width: '100%', position: 'relative', marginTop: '33px'}}>
                        <AgGridReact
                            style={{height: 424, width: '100%'}}
                            rowData={props.SalaryInfo.full_info.cash_outs}
                            enableBrowserTooltips={true}
                            tooltipShowDelay={0}
                            tooltipHideDelay={20}
                            rowHeight={70}
                            components={components}
                            suppressMenu={true}
                            defaultColDef={{
                                editable: true,
                                sortable: true,
                                flex: 1,
                                minWidth: 100,
                                filter: false,
                                floatingFilter: false,
                                resizable: true,
                                menuTabs: false,
                                suppressMenu: true,
                                headerComponentParams: {
                                    menuIcon: 'fa-bars',
                                },
                            }}
                            frameworkComponents={{
                                colorText: ColorText,
                                timestampToDateField: TimestampToDateField,
                                timestampToTimeField: TimestampToTimeField,
                                refWithdrawStatus: RefWithdrawStatus
                            }}


                        >
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={`Дата`}
                                field='init_timestamp'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRendererFramework='timestampToDateField'
                                cellRendererParams={{
                                    color: '#fff'
                                }}

                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Сумма'}
                                field='sum'
                                editable={false}
                                sortable={true}
                                filter={false}
                                cellRenderer='colorText'
                                suppressMenu={true}
                                tooltipField='PR'
                                cellRendererParams={{
                                    color: '#1089F9',
                                    isCurrency: true
                                }}
                            />
                            <AgGridColumn
                                headerClass={s.header_styles}
                                headerName={'Статус'}
                                field='status'
                                editable={true}
                                sortable={true}
                                filter={false}
                                cellRenderer='refWithdrawStatus'
                            />
                        </AgGridReact>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LastTables;
