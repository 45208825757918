import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import SimpleBar from "simplebar-react";
import 'simplebar/dist/simplebar.min.css';
import {UserChangeTicketStatus} from "../../../../../redux/reducers/fsChangerReducer";

export default function ChangerTickets(props) {
    const isHasRequests = props.FsChangerInfo.userTicketsList[0] && props.FsChangerInfo.userTicketsList.some(e => e.type == "request");
    const isHasQuestions = props.FsChangerInfo.userTicketsList[0] && props.FsChangerInfo.userTicketsList.some(e => e.type == "question");
    if (!isHasRequests && !isHasQuestions) return null;

    return (
        <div className="exchange-questions">
            <div className="exchange-info">
                {
                    isHasRequests ?
                        <div className="exchange-info__col">
                            <div className="exchange-info__title">Запросы</div>
                            <SimpleBar className="exchange-info__scroll scrollbar">
                                {
                                    props.FsChangerInfo.userTicketsList.map(e => {
                                        if (e.type != "request") return null;
                                        return <Ticket key={e.timestamp_creation}
                                                       ticket={e} UserChangeTicketStatus={props.UserChangeTicketStatus}/>
                                    })
                                }
                            </SimpleBar>
                        </div>
                        : null
                }
                {
                    isHasQuestions ?
                        <div className="exchange-info__col">
                            <div className="exchange-info__title">Вопросы</div>
                            <SimpleBar className="exchange-info__scroll scrollbar">
                                {
                                    props.FsChangerInfo.userTicketsList.map(e => {
                                        if (e.type != "question") return null;
                                        return <Ticket key={e.timestamp_creation}
                                                       ticket={e}/>
                                    })
                                }
                            </SimpleBar>
                        </div>
                        : null
                }

            </div>
        </div>
    );
}

const Ticket = (props) => {
    return (
        <Popup key={"user_fs_changer_ticket"}
               trigger={
                   <div className="exchange-info__item">
                       <div className="exchange-info__head">
                           <div className="exchange-info__date"><span
                               style={{fontWeight: "bolder"}}>Создано:</span> {props.ticket.date_creation}</div>
                           <div className="exchange-info__status active">{
                               props.ticket.status == "new" ?
                                   <span style={{color: "green"}}> Отправлено</span>
                                   : props.ticket.status == "active" ? <span style={{color: "orange"}}> В работе</span>
                                       : props.ticket.status == "closed" ?
                                           <span style={{color: "tomato"}}> Завершено</span>
                                           : props.ticket.status == "accepting" ?
                                               <span style={{color: "yellow"}}> Нажмите для подтверждения</span>
                                               : props.ticket.status == "canceled" ?
                                                   <span style={{color: "red"}}> Отменено</span> : null
                           }</div>
                       </div>
                       <div className="exchange-e-info__body">
                           <p>{props.ticket.message.substring(0, 100) + "..."}</p>
                       </div>
                   </div>
               }
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="main-popup">
                        <form className="form">
                            <div className="formgroup">
                                <span style={{color: "white", whiteSpace: "pre-line"}}>
                                    {props.ticket.message}
                                </span>
                            </div>

                        </form>
                        { props.ticket.status == "accepting" && props.ticket.type == "request" ? <div>
                                <br/>
                                <div className={"default-button green-back transition"}
                                onClick={() => props.UserChangeTicketStatus(props.ticket.timestamp_creation, "closed")}>Подтвердить получение средств</div>
                            </div>
                            : null}
                    </div>
                </div>
            )}
        </Popup>
    )
}