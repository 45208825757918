
import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";

const GiveCurrency = ({icon, name, currencyName, select, setSelectGiveMoney, id, UserSetFormData}) => {
    const handleClick = () => {
        setSelectGiveMoney(id)
        UserSetFormData({ currentTarget: {value: currencyName}}, "currency")
    }
    return (
        <div className={classNames(s.item, select && s.active)} onClick={() => handleClick()}>
            <div className={s.first}>
                <img src={icon} alt='валюта'/>
                <p>{name}</p>
            </div>
            <span>{currencyName}</span>
        </div>
    );
};

export default GiveCurrency;