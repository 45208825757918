import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import Leaderboard from "./leaderboard";
import {
    ChangeDuelStatus,
    ExportToGoogleTable,
    GetDuels,
    GetInfo,
    GetSave,
    GetSaves, SetBlock
} from "../../../../redux/reducers/leaderboardReducer";


class LeaderboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }


    componentDidMount() {
        this.props.changeCurrentPage("Support: Лидерборд");
        this.props.GetSaves();
        this.props.GetDuels();
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <Leaderboard {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    SupportInfo: state.supportReducer,
    LeaderboardInfo: state.leaderboardReducer
})


export default compose(
    connect(mapStateToProps, {
        GetSaves, GetSave, GetInfo, GetDuels, ChangeDuelStatus, ExportToGoogleTable, SetBlock
    }),
    withRouter
)(LeaderboardContainer)