import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import CustomPage from "./customPage";
import {ChangeCurrentDocumentPage} from "../../../../redux/reducers/authReducer";

class CustomPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage(this.props.match.params.docName)
        this.props.ChangeCurrentDocumentPage(this.props.match.params.docName, this.props.AuthInfo.pageAccesses)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.docName != this.props.match.params.docName) {
            this.props.changeCurrentPage(this.props.match.params.docName)
            this.props.ChangeCurrentDocumentPage(this.props.match.params.docName, this.props.AuthInfo.pageAccesses)
        }
    }

    render() {
        return (
            <CustomPage {...this.props} docType={this.props.match.params.docType}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.authReducer
})

export default compose(
    connect(mapStateToProps, {
        ChangeCurrentDocumentPage
    }),
    withRouter
)(CustomPageContainer)