import React, {useState, useRef} from "react";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Popup from "reactjs-popup";
import moment from "moment";
import Select from "react-dropdown-select";

const BlockSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать блок"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            create
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedBlock}
            onChange={(value) => {
                props.handleSelectBlock(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "101"}}
        />
    )
}

export default function Leaderboard(props) {
    const [startDate, setStartDate] = useState(+new Date());
    const [endDate, setEndDate] = useState(+new Date());

    let blocks = null;
    if (props.LeaderboardInfo.blocks) {
        blocks = props.LeaderboardInfo.blocks.map(e => {return {name: e}});
    }
    const handleSelectBlock = (e) => {
        if (e && e[0] && e[0].name) {
            props.SetBlock(e)
        }
    }

    const handleStartDateChange = (date) => {
        setStartDate(+date);
    };
    const handleEndDateChange = (date) => {
        setEndDate(+date);
    };

    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            },

        },
    });

    const handleGetInfo = (type) => {
        props.GetInfo(startDate, endDate, type)
    }

    let generatedCSV = "Leakfinder;;;Shark;;;\nNickname;Ev Total;Block;;Nickname;Tournaments Total;ROI Total;Block;\n"

    const dataLength = props.LeaderboardInfo.currentSaveLeakfinder ? props.LeaderboardInfo.currentSaveLeakfinder.length :
        props.LeaderboardInfo.currentSaveShark ? props.LeaderboardInfo.currentSaveShark.length : 0;

    for (let i = 0; i < dataLength; i++) {
        if (props.LeaderboardInfo.currentSaveLeakfinder && props.LeaderboardInfo.currentSaveLeakfinder[i]) {
            generatedCSV += `${props.LeaderboardInfo.currentSaveLeakfinder[i].username};${props.LeaderboardInfo.currentSaveLeakfinder[i].evTotal};${props.LeaderboardInfo.currentSaveLeakfinder[i].block};;`
        } else {
            generatedCSV += `;;;;`
        }
        if (props.LeaderboardInfo.currentSaveShark && props.LeaderboardInfo.currentSaveShark[i]) {
            generatedCSV += `${props.LeaderboardInfo.currentSaveShark[i].username};${props.LeaderboardInfo.currentSaveShark[i].sharkTournaments};${props.LeaderboardInfo.currentSaveShark[i].roiTotal};${props.LeaderboardInfo.currentSaveShark[i].block};`
        } else {
            generatedCSV += `;;;;`
        }
        generatedCSV += `\n`;
    }

    const handleExportData = () => {
        let link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(generatedCSV));
        link.setAttribute('download', "export.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleExportDataToGoogleTables = () => {
        props.ExportToGoogleTable(generatedCSV);
    }

    return <div style={{display: "grid", gridTemplateColumns: "70% 29.9%", color: "white", textAlign: "center"}}>
        <div>
            <div style={{filter: "brightness(0) invert(1)", margin: "10px"}}>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            format="dd.MM.yyyy HH:mm"
                            margin="normal"
                            label="От"
                            value={startDate}
                            onChange={handleStartDateChange}
                        />
                    </MuiPickersUtilsProvider>
                    &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            format="dd.MM.yyyy HH:mm"
                            margin="normal"
                            label="До"
                            value={endDate}
                            onChange={handleEndDateChange}
                        />
                    </MuiPickersUtilsProvider>

                </ThemeProvider>
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: `10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px ${blocks ? "1fr 10px" : ""}`,
                marginTop: "30px"
            }}>
                <div></div>
                <div className={"default-button green-back transition cursor-pointer"}
                     onClick={() => handleGetInfo("leakfinder")}>
                    Загрузить данные по ликфайндеру
                </div>
                <div></div>
                <div className={"default-button green-back transition cursor-pointer"}
                     onClick={() => handleGetInfo("shark")}>
                    Загрузить данные по шарку
                </div>
                <div></div>
                <Popup key={`savesleakleaderboard`}
                       trigger={
                           <div className={"default-button default-back transition cursor-pointer"}>
                               Сохранения по ликфайндеру
                           </div>
                       }
                       modal
                       nested
                >
                    {() => (
                        <div>
                            <div id="get-advice" className="main-popup" style={{width: "500px"}}>
                                <form className="form" style={{color: "white", textAlign: "center"}}>
                                    <div className="formgroup">
                                        {
                                            props.LeaderboardInfo.saves.map(save => {
                                                if (save.type == "leakfinder") {
                                                    return <div key={`${save._id} leak`} style={{
                                                        display: "grid",
                                                        gridTemplateColumns: "1fr 30px 1fr",
                                                        margin: "2px 0"
                                                    }}
                                                                className={"cursor-pointer default-button"}
                                                                onClick={() => props.GetSave(save._id)}>
                                                        <div>{moment(+save.timestamp_from).format("DD.MM.YYYY HH:mm")}</div>
                                                        <div>{">>>"}</div>
                                                        <div>{moment(+save.timestamp_to).format("DD.MM.YYYY HH:mm")}</div>
                                                    </div>
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </Popup>
                <div></div>
                <Popup key={`savessharkleaderboard`}
                       trigger={
                           <div className={"default-button default-back transition cursor-pointer"}>
                               Сохранения по шарку
                           </div>
                       }
                       modal
                       nested
                >
                    {() => (
                        <div>
                            <div id="get-advice" className="main-popup" style={{width: "500px"}}>
                                <form className="form" style={{color: "white", textAlign: "center"}}>
                                    <div className="formgroup">
                                        {
                                            props.LeaderboardInfo.saves.map(save => {
                                                if (save.type == "shark") {
                                                    return <div key={`${save._id} shark`} style={{
                                                        display: "grid",
                                                        gridTemplateColumns: "1fr 30px 1fr",
                                                        margin: "2px 0"
                                                    }}
                                                                className={"cursor-pointer default-button"}
                                                                onClick={() => props.GetSave(save._id)}>
                                                        <div>{moment(+save.timestamp_from).format("DD.MM.YYYY HH:mm")}</div>
                                                        <div>{">>>"}</div>
                                                        <div>{moment(+save.timestamp_to).format("DD.MM.YYYY HH:mm")}</div>
                                                    </div>
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </Popup>
                <div></div>
                {
                    blocks ?
                        <BlockSelector options={blocks} selectedBlock={props.LeaderboardInfo.selected_block}
                                       handleSelectBlock={handleSelectBlock}/>
                        : null
                }
                <div></div>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "1fr 50px 1fr", margin: "30px 0"}}>
                {
                    props.LeaderboardInfo.currentSaveLeakfinder ?
                        <div>
                            <div>Leakfinder - <span style={{color: "#65a5e5", cursor: "pointer"}}
                                                    onClick={() => handleExportData()}> export csv file </span><span> | </span>
                                <span style={{color: "#65a5e5", cursor: "pointer"}}
                                      onClick={handleExportDataToGoogleTables}> export to Google Tables </span></div>
                            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: "5px"}}>
                                <div>
                                    Nickname
                                </div>
                                <div>
                                    Ev Total
                                </div>
                            </div>
                            {
                                props.LeaderboardInfo.currentSaveLeakfinder.map((result, i) => <div
                                    key={`${result.username} ${i} leak ${props.LeaderboardInfo.selected_block[0] ? props.LeaderboardInfo.selected_block[0].name : "def dev"}`}
                                    style={{display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: "5px"}}
                                    className={i % 2 == 0 ? "default-back" : ""}>
                                    <div>
                                        {result.username}
                                    </div>
                                    <div>
                                        {result.evTotal}
                                    </div>
                                </div>)
                            }
                        </div>
                        : <div></div>
                }

                <div></div>

                {
                    props.LeaderboardInfo.currentSaveShark ?
                        <div>
                            <div>Shark - <span style={{color: "#65a5e5", cursor: "pointer"}}
                                                    onClick={() => handleExportData()}> export csv file </span><span> | </span>
                                <span style={{color: "#65a5e5", cursor: "pointer"}}
                                      onClick={handleExportDataToGoogleTables}> export to Google Tables </span></div>
                            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginTop: "5px"}}>
                                <div>
                                    Nickname
                                </div>
                                <div>
                                    Tournaments Total
                                </div>
                                <div>
                                    ROI Total
                                </div>
                            </div>
                            {
                                props.LeaderboardInfo.currentSaveShark.map((result, i) => <div
                                    key={`${result.username} ${i} shark ${props.LeaderboardInfo.selected_block[0] ? props.LeaderboardInfo.selected_block[0].name : "def dev"}`}
                                    style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginTop: "5px"}}
                                    className={i % 2 == 0 ? "default-back" : ""}>
                                    <div>
                                        {result.username}
                                    </div>
                                    <div>
                                        {result.sharkTournaments}
                                    </div>
                                    <div>
                                        {result.roiTotal}
                                    </div>
                                </div>)
                            }
                        </div>
                        : <div></div>
                }
            </div>
        </div>
        <div style={{margin: "25px 10px 25px 10px"}}>
            <div>Дуэли</div>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", marginTop: "15px"}}>
                <div>
                    Date
                </div>
                <div>
                    Inviter
                </div>
                <div>
                    Enemy
                </div>
                <div>
                    Status
                </div>
            </div>
            <div>
                {
                    props.LeaderboardInfo.duels.map((duel, i) => {
                        return <Popup key={`duel ${duel._id}`}
                                      trigger={
                                          <div
                                              className={i % 2 == 0 ? "default-back" : ""}
                                              style={{
                                                  display: "grid",
                                                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                                                  marginTop: "5px",
                                                  cursor: "pointer"
                                              }}>
                                              <div>{moment(+duel.timestamp_creation).format("DD.MM.YYYY HH:mm")}</div>
                                              <div>
                                                  {duel.inviter_username}
                                              </div>
                                              <div>
                                                  {duel.enemy_username}
                                              </div>
                                              <div
                                                  style={{color: duel.status == "winner" ? "#ccb95c" : duel.status == "new" ? "#7dc581" : duel.status == "active" ? "#69ace3" : "#6c6c6c"}}>
                                                  {duel.status}
                                              </div>
                                          </div>
                                      }
                                      modal
                                      nested
                        >
                            {() => (
                                <div>
                                    <div id="get-advice" className="main-popup" style={{width: "500px"}}>
                                        <form className="form" style={{color: "white", textAlign: "left"}}>
                                            <div className="formgroup">
                                                <div>
                                                    Дата создания
                                                    дуэли: {moment(+duel.timestamp_creation).format("DD.MM.YYYY HH:mm")}
                                                </div>
                                                <div>
                                                    Winner: {duel.winner_username} - {duel.winner_id}
                                                </div>
                                                <div>
                                                    Inviter: {duel.inviter_username} - {duel.inviter_id}
                                                </div>
                                                <div>
                                                    Enemy: {duel.enemy_username} - {duel.enemy_id}
                                                </div>
                                                <div>
                                                    Ставка: {duel.coins}
                                                </div>
                                                <div>
                                                    Сообщение: {duel.message}
                                                </div>
                                                {
                                                    duel.status == "active" && <div style={{marginTop: "20px"}}>
                                                        <div className={"cursor-pointer"} style={{color: "#79c072"}}
                                                             onClick={() => props.ChangeDuelStatus(duel._id, duel.inviter_id, duel.inviter_username)}>
                                                            Выбрать победителем {duel.inviter_username}
                                                        </div>
                                                        <div className={"cursor-pointer"}
                                                             style={{marginTop: "5px", color: "#79c072"}}
                                                             onClick={() => props.ChangeDuelStatus(duel._id, duel.enemy_id, duel.enemy_username)}>
                                                            Выбрать победителем {duel.enemy_username}
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    duel.status != "deleted" && duel.status != "canceled" && duel.status != "winner" &&
                                                    <div className={"cursor-pointer"}
                                                         style={{marginTop: "15px", color: "#c7827d"}}
                                                         onClick={() => props.ChangeDuelStatus(duel._id, "deleted", "deleted")}>
                                                        Удалить дуэль
                                                    </div>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    })
                }
            </div>
        </div>
    </div>
}