const CLEAR_NOTIFICATIONS_LIST = 'CLEAR_NOTIFICATIONS_LIST';
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
const CHANGE_TIMESTAMP = 'CHANGE_TIMESTAMP';

let notificationsInterval = null;

let initialState = {
    notificationsList: [],
    pseudoTimer: 0,
    timestamp: Math.random(1)
};

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_TIMESTAMP: {
            return {
                ...state,
                timestamp: Math.random(1)
            }
        }
        case CLEAR_NOTIFICATIONS_LIST:
            let currentPseudoTimer = state.pseudoTimer + 1;

            if (currentPseudoTimer > 2) {
                let newNotificationsList = [...state.notificationsList]
                newNotificationsList.pop()

                return {
                    ...state,
                    notificationsList: [...newNotificationsList],
                    pseudoTimer: 0,
                    timestamp: Math.random(1)
                };
            } else {
                return {
                    ...state,
                    pseudoTimer: currentPseudoTimer,
                    timestamp: Math.random(1)
                };
            }
        case ADD_NOTIFICATION:
            return {
                ...state,
                pseudoTimer: 0,
                notificationsList: [{
                    type: action.nType,
                    title: action.nTitle,
                    description: action.nDescription
                }, ...state.notificationsList]
            }
        case DELETE_NOTIFICATION:
            let newNotificationsList = [...state.notificationsList]
            newNotificationsList.splice(action.index, 1)

            return {
                ...state,
                notificationsList: [...newNotificationsList]
            }
        default:
            return state;
    }
};

const ClearNotificationsList = () => ({type: CLEAR_NOTIFICATIONS_LIST});
export const AddNotification = (nType, nTitle, nDescription) => ({type: ADD_NOTIFICATION, nType, nTitle, nDescription})
export const DeleteNotification = (index) => ({type: DELETE_NOTIFICATION, index})
const ChangeTimestamp = () => ({type: CHANGE_TIMESTAMP})

export const StartNotifications = () => {
    return (dispatch) => {
        if (notificationsInterval == null) {
            setInterval(() => {
                dispatch(ChangeTimestamp())
                dispatch(ClearNotificationsList());
            }, 2000)
        }
    }
}

export default notificationsReducer;