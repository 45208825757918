import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import moment from "moment";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import Cookies from "js-cookie";
import {EditRow, EditUsers} from "../../../../redux/reducers/schedulerReducer";
import Select from "react-dropdown-select";

const directions = {
    "SPINS": "444471730732269569",
    "CASH": "743480972816678922",
    "MTT": "928562934412607500",
}

const notifyTypes = [
    "ONCE", "EVERY_DAY", "EVERY_WEEK_DAY", "EVERY_X_DAYS", "EVERY_MONTH"
]
const weekDays = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"]

const UserSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать"}
            labelField={"username"}
            valueField={"username"}
            sortBy={"username"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            multi
            options={props.options}
            values={props.selected && props.selected[0] ? props.selected : []}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}

export default function SidebarScheduler(props) {
    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2",
                color: "white"
            },

        },
    });

    const [newUserId, setNewUserId] = useState("");
    const [newUserNick, setNewUserNick] = useState("");
    const [newUserDirection, setNewUserDirection] = useState(null);

    const [topic, setTopic] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [timestampFirstNotify, setTimestampFirstNotify] = useState(+new Date());
    const [notifyType, setNotifyType] = useState(null);
    const [notifyHelper, setNotifyHelper] = useState(null);
    const [sendTask, setSendTask] = useState(false);

    const handleSelectedUsers = (e) => {
        setSelectedUsers(e && e[0] ? e : []);
    }

    const handleSetSelectedUsers = (id, username, discord_server_id, selected) => {
        if (!selected) return setSelectedUsers([...selectedUsers, {id, username, discord_server_id}])
        const updatedUsers = [...selectedUsers];
        updatedUsers.splice(selectedUsers.findIndex(e => e.id == id), 1)
        setSelectedUsers([...updatedUsers])
    }

    const handleCreateTask = () => {
        props.EditRow("create", {
            notify_type: notifyType,
            users: [...selectedUsers],
            notify_helper: notifyHelper,
            topic,
            timestamp_first_notify: timestampFirstNotify,
            send_task: sendTask
        })
    }

    const handleEditUsers = (type) => {
        props.EditUsers(`${newUserDirection}: ${newUserNick}`, newUserId, directions[newUserDirection], type)
    }

    return (
        <>
            <div className={"default-button blue-back"} onClick={() => props.GetList(Cookies.get("id"))}>
                Мои задачи
            </div>
            <div className={"default-button default-back"} onClick={() => props.GetList("all")}>
                Все задачи
            </div>

            <br/>
            <br/>
            <Popup key={`${props.SchedulerInfo.list.length} taskCreator`}
                   trigger={
                       <div className={"default-button green-back"}>
                           Добавить новую задачу
                       </div>
                   }
                   modal
                   nested
            >
                {() => (
                    <div>
                        <div id="get-advice" className="main-popup-scheduler" style={{color: "white"}}>
                            <form className="form">
                                <div className="formgroup" style={{marginLeft: "-10px"}}>
                                    <div>
                                        <div style={{padding: "7px 2px", fontSize: "16px", fontWeight: "bolder"}}>
                                            Название задачи
                                        </div>
                                        <input className={"input"} onChange={(e) => setTopic(e.target.value)} style={{width: "530px"}}/>
                                    </div>

                                    <div>
                                        <div
                                            style={{padding: "14px 2px 0 2px", fontSize: "16px", fontWeight: "bolder"}}>
                                            Ответственные
                                        </div>
                                        <UserSelector options={props.SchedulerInfo.users}
                                                      selected={selectedUsers}
                                                      handleSelect={handleSelectedUsers}/>
                                    </div>

                                    <div style={{marginTop: "26px", filter: "brightness(0) invert(1)"}}>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePicker
                                                    format="dd.MM.yyyy HH:mm"
                                                    label="Дата первого срабатывания уведомления"
                                                    value={timestampFirstNotify}
                                                    onChange={(e) => setTimestampFirstNotify(+e)}
                                                    className={"mui-picker-custom-font"}
                                                    style={{width: "100%", color: "white", padding: "5px"}}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>
                                    </div>

                                    <div>
                                        <div
                                            style={{padding: "14px 2px 0 2px", fontSize: "16px", fontWeight: "bolder"}}>
                                            Тип периодичности
                                        </div>
                                        {
                                            notifyType ?
                                                <div style={{padding: "0 2px 3px 2px", fontSize: "14px"}}>
                                                    <div>{notifyType}</div>
                                                </div> : null
                                        }
                                        <Popup key={`${notifyType} notifyTypePicker`}
                                               trigger={
                                                   <div className={"default-button default-back"}
                                                        style={{width: "530px"}}>
                                                       Выбрать
                                                   </div>
                                               }
                                               modal
                                               nested
                                        >
                                            {() => (
                                                <div>
                                                    <div id="get-advice" className="main-popup">
                                                        <form className="form" style={{color: "white"}}>
                                                            <div className="formgroup">
                                                                {
                                                                    notifyTypes.map((e) => {
                                                                        return <div style={{width: "300px"}}
                                                                                    onClick={() => setNotifyType(e)}
                                                                                    key={`${e} notifyTypePick`}
                                                                                    className={"default-back default-button"}>
                                                                            {e}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                    </div>

                                    {
                                        notifyType == "EVERY_WEEK_DAY" ?
                                            <div>
                                                <div style={{
                                                    padding: "14px 2px 0 2px",
                                                    fontSize: "16px",
                                                    fontWeight: "bolder"
                                                }}>
                                                    В какой день недели слать оповещение
                                                </div>
                                                {
                                                    +notifyHelper >= 1 ?
                                                        <div style={{padding: "0 2px 3px 2px", fontSize: "14px"}}>
                                                            <div>{weekDays[notifyHelper - 1]}</div>
                                                        </div> : null
                                                }
                                                <Popup key={`${notifyHelper} notifyHelperPicker`}
                                                       trigger={
                                                           <div className={"default-button default-back"}
                                                                style={{width: "530px"}}>
                                                               Выбрать
                                                           </div>
                                                       }
                                                       modal
                                                       nested
                                                >
                                                    {() => (
                                                        <div>
                                                            <div id="get-advice" className="main-popup">
                                                                <form className="form" style={{color: "white"}}>
                                                                    <div className="formgroup">
                                                                        {
                                                                            weekDays.map((e, i) => {
                                                                                return <div style={{width: "300px"}}
                                                                                            onClick={() => setNotifyHelper(i + 1)}
                                                                                            key={`${e} notifyHelperPick`}
                                                                                            className={"default-back default-button"}>
                                                                                    {e}
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                            : null
                                    }

                                    {
                                        notifyType == "EVERY_X_DAYS" ?
                                            <div>
                                                <div style={{
                                                    padding: "7px 2px",
                                                    fontSize: "16px",
                                                    fontWeight: "bolder"
                                                }}>
                                                    Каждые сколько дней слать оповещение
                                                </div>
                                                <input className={"input"} type={"number"} onChange={(e) => setNotifyHelper(e.target.value)}
                                                       style={{width: "530px"}}/>
                                            </div>
                                            : null
                                    }

                                    <div style={{padding: "7px 0", fontSize: "16px", fontWeight: "bolder", marginTop: "20px"}}>

                                        <input className={"input"} style={{margin: "0", padding: "0", width: "20px"}} type={"checkbox"} checked={sendTask} onClick={() => setSendTask(!sendTask)}/>
                                        <span onClick={() => setSendTask(!sendTask)}>Создавать таск вместо отправки сообщения</span>
                                    </div>

                                    {
                                        topic && notifyType ?
                                            <div className={"default-button green-back"}
                                                 onClick={handleCreateTask}
                                                 style={{width: "530px", marginTop: "20px", padding: "20px 0"}}>
                                                Создать
                                            </div>
                                            : null
                                    }

                                </div>

                            </form>

                        </div>
                    </div>
                )}
            </Popup>

            <Popup key={`new user creator`}
                   trigger={
                       <div className={"default-button orange-back"}>
                           База ответственных
                       </div>
                   }
                   modal
                   nested
            >
                {() => (
                    <div>
                        <div id="get-advice" className="main-popup" style={{color: "white"}}>
                            <form className="form">
                                <div className="formgroup" style={{marginLeft: "-10px"}}>
                                    <div>
                                        <div style={{padding: "7px 2px", fontSize: "16px", fontWeight: "bolder"}}>
                                            Discord ID пользователя
                                        </div>
                                        <input type={"number"} value={newUserId} onChange={(e) => setNewUserId(e.target.value)} style={{width: "530px"}}/>
                                    </div>
                                    <div>
                                        <div style={{padding: "7px 2px", fontSize: "16px", fontWeight: "bolder"}}>
                                            Никнейм для пользователя
                                        </div>
                                        <input type={"text"} value={newUserNick} onChange={(e) => setNewUserNick(e.target.value)} style={{width: "530px"}}/>
                                    </div>

                                    <div>
                                        <div style={{padding: "7px 2px", fontSize: "16px", fontWeight: "bolder"}}>
                                            Направление
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "100px 100px 100px 1fr"}}>
                                            <div
                                                onClick={() => setNewUserDirection("SPINS")}
                                                className={`default-button ${newUserDirection == "SPINS" ? "green-back" : "default-back"}`} style={{borderRadius: 0}}>SPINS</div>
                                            <div
                                                onClick={() => setNewUserDirection("CASH")}
                                                className={`default-button ${newUserDirection == "CASH" ? "green-back" : "default-back"}`} style={{borderRadius: 0}}>CASH</div>
                                            <div
                                                onClick={() => setNewUserDirection("MTT")}
                                                className={`default-button ${newUserDirection == "MTT" ? "green-back" : "default-back"}`} style={{borderRadius: 0}}>MTT</div>
                                            <div></div>
                                        </div>
                                    </div>

                                    {
                                        newUserDirection && newUserNick?
                                            <div className={"default-button red-back"}
                                                 onClick={() => handleEditUsers("remove")}
                                                 style={{width: "530px", marginTop: "20px", padding: "9px 0"}}>
                                                Удалить
                                            </div>
                                            : null
                                    }

                                    {
                                        newUserId && newUserDirection && newUserNick ?
                                            <div className={"default-button green-back"}
                                                 onClick={() => handleEditUsers("add")}
                                                 style={{width: "530px", marginTop: "20px", padding: "9px 0"}}>
                                                Добавить
                                            </div>
                                            : null
                                    }

                                </div>

                            </form>

                        </div>
                    </div>
                )}
            </Popup>

            <br/>
            <br/>
        </>
    )
}