import React from 'react';
import {NavLink, Route, Switch} from "react-router-dom";
import AuthContainer from "../Auth/AuthContainer";
import WikiListContainer from "../Wiki/wikiListContainer";
import WikiCreatorContainer from "../Wiki/wikiCreatorContainer";
import WikiPageContainer from "../Wiki/wikiPageContainer";
import Error_404_Container from "../common/error_404/error_404_container";
import AdminIcon from "../../images/admin_icon.png";
import UsersListContainer from "../Admin/Users/usersListContainer";
import UserPageContainer from "../Admin/Users/userPageContainer";
import RolesListContainer from "../Admin/Roles/rolesListContainer";
import PagesListContainer from "../Admin/Pages/pagesListContainer";
import CustomPageContainer from "../WorkSpace/customPages/customPageContainer";
import CategoriesListContainer from "../Admin/Categories/categoriesListContainer";
import GeneratedMenuAccessesContainer from "./generatedMenuAccessesContainer";
import CloseImg from "../../images/close.svg"
import DiskFilesContainer from "../Disk/diskFilesContainer";
import DiskIcon from "../../images/disk.png"
import RefIcon from "../../images/stick-man.png"
import SupportPanelIcon from "../../images/technical-support.png";
import ExchangeIcon from "../../images/exchange.png";
import WikiSearchContainer from "../Wiki/wikiSearchContainer";
import LearnContainer from "../Learn/learnContainer";
import SupportsEditorContainer from "../Admin/SupportsEditor/supportsEditorContainer";
import TasksTicketsContainer from "../Tasks_Tickets/tasksTicketsContainer";
import SidebarContainer from "../Sidebar/sidebarContainer";
import AuditorEditorContainer from "../Admin/AuditorEditor/auditorEditorContainer";
import AuditorsListContainer from "../Admin/AuditorsList/auditorsListContainer";
import AuditListContainer from "../Support/Audit/AuditListContainer";
import AuditPlayerContainer from "../Support/Audit/AuditPlayerContainer";
import AuditorTasksContainer from "../Admin/AuditorTasks/auditorTasksContainer";
import ChangerTicketsContainer from "../Support/ChangerTickets/ticketsContainer";
import ChangerBanksContainer from "../Admin/ChangerBanks/changerBanksContainer";
import MainPageContainer from "./MainPage/mainPageContainer";
import CustomProfileInfoContainer from "../Admin/CustomProfileInfo/customProfileInfoContainer";
import UsersContainer from "../Support/Users/usersContainer";
import UserContainer from "../Support/User/userContainer";
import ConnectorsContainer from "../Admin/Training/connectorsContainer";
import CreatorContainer from "../Coach/Trainings/creatorContainer";
import ListContainer from "../Coach/Trainings/listContainer";
import PersonalSlotsContainer from "../Coach/Trainings/personalSlotsContainer";
import RequestContainer from "../User/PersonalTraining/requestContainer";
import CoachImg from "../../images/mortarboard.png";
import CoachImg2 from "../../images/coach.png";
import Cookies from "js-cookie";
import ClubsContainer from "../Support/Formular/clubsContainer";
import PlayerContainer from "../Support/Formular/playerContainer";
import FinResultsContainer from "../Support/Formular/finResultsContainer";
import FinResultsIdsContainer from "../Support/Formular/finResultsIdsContainer";
import FinResultsRowsContainer from "../Support/Formular/finResultsRowsContainer";
import TransfersContainer from "../Support/Formular/transfersContainer";
import BugTicketsContainer from "../Tasks_Tickets/bugTicketsContainer";
import Popup from "reactjs-popup";
import SchedulerContainer from "../Support/Scheduler/schedulerContainer";
import QuestionsFormContainer from "../Admin/QuestionsForm/questionsFormContainer";
import ReferralContainer from "../Admin/Referral/referralContainer";
import LeakfinderContainer from "../Coach/Leakfinder/leakfinderContainer";
import LeakfinderSchemasContainer from "../Coach/Leakfinder/leakfinderSchemasContainer";
import StartStackTableContainer from "../Coach/Leakfinder/startStackTableContainer";
import SalaryUsersListContainer from "../Salary/Admin/usersListContainer";
import SalaryRowsListContainer from "../Salary/Admin/rowsListContainer";
import ErrorLogsContainer from "../_Dev/errorLogsContainer";
import RefSupportUsersContainer from "../Ref/Support/usersContainer"
import SalaryTicketsContainer from "../Salary/Admin/salaryTicketsContainer";
import CoachDiscordSenderContainer from "../Coach/coachDiscordSenderContainer";
import IndivSlotsContainer from "../Coach/Trainings/indivSlotsContainer";
import Leakfinder_PBI from "../Coach/Leakfinder/leakfinder_pbi";
import LeaderboardContainer from "../Support/Leaderboard/leaderboardContainer";
import FullInfosContainer from "../Coach/Trainings/fullInfosContainer";
import config from "../../config.json";
import CoachesStatContainer from "../Support/CoachesStat/coachesStatContainer";
import ScheduleCalendarContainer from "../Support/ScheduleCalendar/scheduleCalendarContainer";
import ContractsContainer from "../Support/Spin/Contacts/contractsContainer";
import LeakfinderSpinContainer from "../Coach/LeakfinderSpin/leakfinderSpinContainer";
import LeakfinderSchemasSpinContainer from "../Coach/LeakfinderSpin/leakfinderSchemasSpinContainer";
import SpinPoolDataContainer from "../Support/SpinPoolData/spinPoolDataContainer";
import LeakfinderDetailsContainer from "../Coach/Leakfinder/leakfinderDetailsContainer";
import InfosModel from "../Admin/Shark/InfosModel/InfosModel";
import PlayersStatContainer from "../Support/PlayersStat/playersStatContainer";
import TournamentsMttContainer from "../Support/Tournaments_MTT/tournamentsMttContainer";
import SalaryUsersStateContainer from "../Salary/Admin/usersStateContainer";
import COAPageContainer from "../Finance/COA/coaPageContainer";
import COATransfersContainer from "../Finance/COA_Transfers/coaTransfersContainer";
import CoachNotificationsContainer from "../Coach/Notifications/coachNotificationsContainer";
import WelcomeMessageEditorContainer from "../Admin/WelcomeMessageEditor/welcomeMessageEditorContainer";
import ContractsContainerMTT from "../Support/MTT/Contracts/contractsContainerMTT";

const Main = (props) => {

    let Notes = React.createRef();

    let isSupportAuditor = false;
    let isSupport = false;
    if (props.AuthInfo.isMTSNGSupport || props.AuthInfo.isMTTSupport || props.AuthInfo.isCashSupport || props.AuthInfo.isSpinSupport || props.AuthInfo.isAuditor) isSupportAuditor = true;
    if (props.AuthInfo.isMTSNGSupport || props.AuthInfo.isMTTSupport || props.AuthInfo.isCashSupport || props.AuthInfo.isSpinSupport) isSupport = true;


    const changeNotes = (e) => {
        if (Notes.current.value.length >= 1) {
            props.ChangeNotes(props.AuthInfo.id, props.AuthInfo.accessToken, Notes.current.value)
        }
    }
    const DeleteNotification = (e) => {
        props.DeleteNotification(e.target.accessKey)
    }

    const returnToMainAcc = () => {
        Cookies.set('WEB_SITE_USER_VERSION', "1", {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('token', Cookies.get("saved_token"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('id', Cookies.get("saved_id"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('avatar', Cookies.get("saved_avatar"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('username', Cookies.get("saved_username"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('email', Cookies.get("saved_email"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_token', "", {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_id', "", {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_avatar', "", {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_username', "", {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_email', "", {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });

        window.location.href = "/"
    }

    return (
        <div>
            {
                props.AuthInfo.accessToken ?
                    <div>
                        <div className="notification-wrap">
                            {props.NotificationsInfo.notificationsList.map((notification, i) => {
                                let nType = '';
                                if (notification.type == "error") nType = "warning-block"
                                if (notification.type == "success") nType = "success-block"

                                return (
                                    <div key={notification.title + " " + i} className={"notification" + " " + nType}>
                                        <div className="notification-head">
                                            <div className="notification-head__title">{notification.title}</div>
                                            <div className="notification-close">
                                                <img accessKey={i} onClick={DeleteNotification} src={CloseImg}
                                                     alt="close"/>
                                            </div>
                                        </div>
                                        <p>{notification.description}</p>
                                    </div>
                                )
                            })}
                        </div>


                        <header className="header">
                            <div className="container">
                                <div className="header__title">{props.MainInfo.currentPage}</div>
                            </div>
                        </header>
                        <div className="sidebar-left">
                            <div className="sidebar-head">
                                <a href={"/"}>
                                    <img src="https://lk.firestorm.team/img/logo-small.png" alt="logo"
                                         className="sidebar-head__logo desc"/>
                                </a>
                                <a href={"/"}>
                                    <img src="https://lk.firestorm.team/img/logo-big.png" alt="logo"
                                         className="sidebar-head__logo mob"/>
                                </a>
                                {/* <div class="sidebar-head__btn desc">
            <img src="img/arrow-l-left.svg" alt="close">
         </div> */}
                                <div className="sidebar-head__btn mob">
                                    <img src="https://lk.firestorm.team/img/arrow-l-right.svg" alt="close"/>
                                </div>
                            </div>
                            <ul className="side-menu">
                                <li>
              <span className="side-menu__btn">
                <img src="https://lk.firestorm.team/img/icon1.svg" alt=""/>
              </span>
                                    <ul className="sub-menu">
                                        <li className="sub-menu__title">
                                            <img src="https://lk.firestorm.team/img/icon1.svg" alt="icon"
                                                 className="icon"/>
                                            <span className>Главное меню</span>
                                        </li>

                                        {
                                            props.AuthInfo.isLeaderboardAdmin ?
                                                <li>
                                                    <span className="sub-menu__down">
                                                        <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                                                        Лидерборд
                                                    </span>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/leaderboard"}>Общие данные</NavLink>
                                                        </li>
                                                        <li><NavLink to={"/coach/training/playersstats"}>Статистика игроков</NavLink></li>
                                                    </ul>
                                                </li>
                                                : null
                                        }

                                        {
                                            isSupportAuditor || props.AuthInfo.isFsChangerSupport || props.AuthInfo.isFsChangerAdmin || props.AuthInfo.isPokerhubSupport ?
                                                <li>
                                                    <span className="sub-menu__down">
                                                        <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                                                        Таски и тикеты
                                                    </span>
                                                    <ul className="sub-menu__list">
                                                        {isSupport || props.AuthInfo.isPokerhubSupport  ? <li><NavLink to={"/support/tasks"}>Таски</NavLink>
                                                        </li> : null}
                                                        {isSupport || props.AuthInfo.isPokerhubSupport ?
                                                            <li><NavLink to={"/support/tickets"}>Тикеты</NavLink>
                                                            </li> : null}
                                                        {isSupport ?
                                                            <li><NavLink to={"/support/tourney_request"}>Запросы на турниры</NavLink>
                                                            </li> : null}
                                                        {isSupport ?
                                                            <li><NavLink to={"/support/tg_tickets"}>TG тикеты</NavLink>
                                                            </li> : null}
                                                        {
                                                            props.AuthInfo.isAdmin &&
                                                            <li><NavLink to={"/dev/bugs"}>Баги</NavLink>
                                                            </li>
                                                        }
                                                        {isSupportAuditor ?
                                                            <li><NavLink to={"/support/audit/list"}>Аудит</NavLink>
                                                            </li> : null}
                                                        {props.AuthInfo.isFsChangerSupport ? <li><NavLink
                                                            to={"/support/changer/tickets"}>Обмены</NavLink>
                                                        </li> : null}
                                                        {props.AuthInfo.isFsChangerAdmin ?
                                                            <li><NavLink to={"/support/changer/banks"}>Банки
                                                                обменов</NavLink></li> : null}
                                                        {
                                                            props.AuthInfo.isAuditAdmin ?
                                                                <li><NavLink to={"/admin/referral"}>Рефка
                                                                </NavLink></li> : null
                                                        }
                                                    </ul>
                                                </li> : null
                                        }

                                        {
                                            props.AuthInfo.isFinancesAdmin ? <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    З/п сотрудников
                  </span>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/salary/admin/state"}>Отчёт</NavLink></li>
                                                        <li><NavLink to={"/salary/admin/users"}>Пользователи</NavLink></li>
                                                        <li><NavLink to={"/salary/admin/rows"}>Начисления</NavLink></li>
                                                        <li><NavLink to={"/salary/admin/tickets"}>Запросы</NavLink></li>
                                                    </ul>
                                                </li>
                                                : null
                                        }
                                        {
                                            props.AuthInfo.isFinancesAdmin ? <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    COA
                  </span>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/finance/coa/all"}>All Rows</NavLink></li>
                                                        <li><NavLink to={"/finance/coa/transfers"}>Transfers</NavLink></li>
                                                    </ul>
                                                </li>
                                                : null
                                        }

                                        {/*                      <li>*/}
                                        {/*<span className="sub-menu__down">*/}
                                        {/*  <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>*/}
                                        {/*  База знаний*/}
                                        {/*</span>*/}
                                        {/*                          <ul className="sub-menu__list">*/}
                                        {/*                              <li><NavLink to={"/learn"}>FAQ</NavLink></li>*/}
                                        {/*                              <li><NavLink to={"/wiki/list"}>Статьи</NavLink></li>*/}
                                        {/*                          </ul>*/}
                                        {/*                      </li>*/}


                                    </ul>

                                </li>

                                {/*<li><NavLink to={"/training/personal/request"} className="side-menu__link">*/}
                                {/*    <img src={CoachImg} alt=""/>*/}

                                {/*    <div className={"side-menu__link-text"}>*/}
                                {/*        Запись на тренировку*/}
                                {/*    </div>*/}
                                {/*</NavLink>*/}
                                {/*</li>*/}

                                {
                                    props.AuthInfo.isCoach || isSupport ?
                                        <li>
              <span className="side-menu__btn">
                <img src={CoachImg2} alt=""/>
              </span>
                                            <ul className="sub-menu">
                                                <li className="sub-menu__title">
                                                    <img src={CoachImg2} alt="icon"
                                                         className="icon"/>
                                                    <span className>Тренировки</span>
                                                </li>


                                                <li>
                                                      <span className="sub-menu__down">
                                                        <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                                                        Leakfinder
                                                      </span>
                                                    <ul className="sub-menu__list">
                                                        {/*<li><NavLink to={"/coach/leakfinder/startstack"}>Start Stack*/}
                                                        {/*    Table</NavLink></li>*/}
                                                        <li><NavLink to={"/coach/leakfinder/details"}>Интерактивный
                                                            отчет</NavLink>
                                                        </li>
                                                        <li><NavLink to={"/coach/leakfinder"}>MTT: Analyze</NavLink>
                                                        </li>
                                                        <li><NavLink to={"/coach/leakfinder/ranks"}>MTT: Analyze
                                                            Ranks</NavLink></li>
                                                        <li><NavLink to={"/coach/leakfinder_spin"}>SPIN: Analyze</NavLink>
                                                        </li>
                                                        <li><NavLink to={"/coach/leakfinder_spin/ranks"}>SPIN: Analyze
                                                            Ranks</NavLink></li>
                                                    </ul>
                                                </li>

                                                {
                                                    props.AuthInfo.isCoach && <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Панель тренера
                  </span>
                                                        <ul className="sub-menu__list">
                                                            <li><NavLink to={"/coach/training/create"}>Создать
                                                                новую</NavLink></li>
                                                            <li><NavLink to={"/coach/training/list"}>Список тренировок</NavLink>
                                                            </li>
                                                            <li><NavLink to={"/coach/training/personal/slots"}>Свободные
                                                                даты</NavLink></li>
                                                            <li><NavLink to={"/coach/notifications"}>Напоминания о собраниях</NavLink></li>
                                                            {/*<li><NavLink to={"/coach/training/indiv/slots"}>Свободные*/}
                                                            {/*    даты tet</NavLink></li>*/}
                                                            {/*<li><NavLink to={"/coach/training/discord/messages/send"}>Рассылка сообщений в дискорд</NavLink></li>*/}
                                                        </ul>
                                                    </li>

                                                }

                                                {
                                                    isSupport && <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Панель саппорта
                  </span>
                                                        <ul className="sub-menu__list">
                                                            <li><NavLink to={"/coach/training/create"}>Запланировать
                                                                тренировку</NavLink></li>
                                                            <li><NavLink to={"/coach/training/coachstats"}>Статистика тренеров</NavLink></li>
                                                            <li><NavLink to={"/coach/training/playersstats"}>Статистика игроков</NavLink></li>
                                                            <li><NavLink to={"/coach/training/list"}>Список тренировок</NavLink></li>
                                                            <li><NavLink to={"/coach/training/details"}>Доп.инфо о
                                                                тренировках</NavLink></li>
                                                            <li><NavLink to={"/support/users/list"}>Список
                                                                пользователей</NavLink></li>
                                                            <li><NavLink to={"/coach/training/discord/messages/send"}>Рассылка
                                                                сообщений в дискорд</NavLink></li>
                                                        </ul>
                                                    </li>
                                                }

                                            </ul>

                                        </li>
                                        : null
                                }

                                {props.AuthInfo.isDisk &&
                                    <li><NavLink to={"/disk"} className="side-menu__link">
                                        <img src={DiskIcon} alt=""/>

                                        <div className={"side-menu__link-text"}>
                                            Диск
                                        </div>
                                    </NavLink>
                                    </li>
                                }


                                {props.AuthInfo.isRefSupport &&
                                    <li><NavLink to={"/ref/users"} className="side-menu__link">
                                        <img src={RefIcon} alt=""/>

                                        <div className={"side-menu__link-text"}>
                                            Пользователи (реферал.)
                                        </div>
                                    </NavLink>
                                    </li>
                                }

                                {props.AuthInfo.isAdmin || props.AuthInfo.isAuditAdmin ?
                                    <li>
              <span className="side-menu__btn">
                <img src={AdminIcon} alt=""/>
              </span>
                                        <ul className="sub-menu">
                                            <li className="sub-menu__title">
                                                <img src={AdminIcon} alt="icon"
                                                     className="icon"/>
                                                <span className>Admin Panel</span>
                                            </li>
                                            {
                                                props.AuthInfo.isAdmin ?
                                                    <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Конструктор
                  </span>
                                                        <ul className="sub-menu__list">
                                                            <li><NavLink to={"/admin/roles"}>Список ролей</NavLink></li>
                                                            <li><NavLink to={"/admin/categories"}>Список
                                                                категорий</NavLink>
                                                            </li>
                                                            <li><NavLink to={"/admin/pages"}>Список страниц</NavLink>
                                                            </li>
                                                            <li><NavLink to={"/admin/question/form"}>Опросник</NavLink>
                                                            </li>
                                                            <li><NavLink to={"/admin/bot/welcomemessage"}>Welcome Message</NavLink>
                                                            </li>

                                                        </ul>
                                                    </li>
                                                    : null
                                            }

                                            {
                                                props.AuthInfo.isAdmin ?
                                                    <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Уведомления
                  </span>
                                                        <ul className="sub-menu__list">
                                                            <li><NavLink
                                                                to={"/admin/training/connectors"}>Тренировки</NavLink>
                                                            </li>
                                                            <li><NavLink to={"/admin/supports/editor"}>Редактор
                                                                саппортов</NavLink>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : null
                                            }


                                            <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Аудит
                  </span>
                                                <ul className="sub-menu__list">
                                                    <li><NavLink to={"/admin/auditor/editor"}>Список игроков</NavLink>
                                                    </li>
                                                    <li><NavLink to={"/admin/auditor/auditors"}>Список
                                                        аудиторов</NavLink>
                                                    </li>
                                                    <li><NavLink to={"/admin/auditor/tasks"}>Список тасок</NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                            {
                                                props.AuthInfo.isAdmin ?
                                                    <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Пользователи
                  </span>
                                                        <ul className="sub-menu__list">
                                                            <li><NavLink to={"/admin/users/list"}>Найти всех</NavLink>
                                                            </li>
                                                            <li><NavLink to={"/admin/pci/templates"}>Шаблонизатор
                                                                полей</NavLink>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : null
                                            }

                                            {
                                                props.AuthInfo.isAdmin ?
                                                    <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Shark
                  </span>
                                                        <ul className="sub-menu__list">
                                                            <li><NavLink to={"/admin/shark/infos_model"}>Infos Model</NavLink>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : null
                                            }

                                            {
                                                props.AuthInfo.isDeveloper ?
                                                    <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    DEV
                  </span>
                                                        <ul className="sub-menu__list">
                                                            <li><NavLink to={"/dev/logs/errors"}>Errors</NavLink></li>
                                                            <li><NavLink to={"/undefined"}>Warnings</NavLink></li>
                                                        </ul>
                                                    </li>
                                                    : null
                                            }
                                        </ul>
                                    </li>
                                    : null}


                                {
                                    props.AuthInfo.isAdmin || isSupport ?
                                        <li>
              <span className="side-menu__btn">
                <img src={SupportPanelIcon} alt=""/>
              </span>
                                            <ul className="sub-menu">
                                                <li className="sub-menu__title">
                                                    <img src={SupportPanelIcon} alt="icon"
                                                         className="icon"/>
                                                    <span className>Support Panel</span>
                                                </li>

                                                <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Пользователи
                  </span>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/users/list"}>Найти всех</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    MTT
                  </span>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/mtt/players/tournaments"}>Турниры от игроков</NavLink>
                                                        </li>
                                                        {/*<li><NavLink to={"/support/mtt/contracts"}>Контракты</NavLink>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </li>

                                                <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    SPIN
                  </span>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/spin/contracts"}>Контракты</NavLink>
                                                        </li>
                                                        <li><NavLink to={"/support/spin/pool/data"}>Pool Data</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Прочее
                  </span>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/scheduler"}>Планировщик</NavLink>
                                                        </li>
                                                        <li><NavLink to={"/support/schedule"}>График работы</NavLink>
                                                        </li>
                                                    </ul>

                                                </li>

                                                <li>
                  <span className="sub-menu__down">
                    <img src="https://lk.firestorm.team/img/icon2.svg" alt="icon"/>
                    Формуляры
                  </span>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/formular/player"}>Данные по
                                                            игроку</NavLink>
                                                        </li>
                                                    </ul>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink
                                                            to={"/support/formular/transfers/list"}>Трансферы</NavLink>
                                                        </li>
                                                    </ul>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/formular/finresults"}>Фин. результаты
                                                            (add)</NavLink>
                                                        </li>
                                                    </ul>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/formular/finresults/list"}>Фин.
                                                            результаты (read)</NavLink>
                                                        </li>
                                                    </ul>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/formular/finresultsids"}>Фин.
                                                            результаты (IDS)</NavLink>
                                                        </li>
                                                    </ul>
                                                    <ul className="sub-menu__list">
                                                        <li><NavLink to={"/support/formular/clubs"}>Клубы</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>

                                        </li>
                                        : null
                                }

                                {
                                    props.AuthInfo.pageAccesses[0] ?
                                        <GeneratedMenuAccessesContainer AuthInfo={props.AuthInfo}
                                                                        CategoriesInfo={props.CategoriesInfo}/>
                                        : null
                                }
                            </ul>
                            <div className="size-btn"/>
                        </div>
                        <div className="sidebar-right">
                            <div className="sidebar-head">
                                <div className="sidebar-head__btn desc">
                                    <img src="https://lk.firestorm.team/img/arrow-l-right.svg" alt="close"/>
                                </div>
                                <div className="sidebar-head__btn mob">
                                    <img src="https://lk.firestorm.team/img/arrow-l-left.svg" alt="close"/>
                                </div>
                                <AuthContainer/>
                            </div>
                            <div className="sidebar-task">
                                <SidebarContainer isLoading={props.MainInfo.isLoading}/>
                                {
                                    Cookies.get("saved_token") &&
                                    <div onClick={returnToMainAcc} style={{marginTop: "0px"}}
                                         className={"default-button red-back"}>
                                        Вернуться на свой аккаунт
                                    </div>
                                }
                                <div className="task-note">
                                    <textarea rows={15}
                                              placeholder="Здесь вы можете оставлять свои заметки. Для сохранения - нажмите в любое место (уберите фокус с этого поля)"
                                              title={"Здесь вы можете оставлять свои заметки. Для сохранения - нажмите в любое место (уберите фокус с этого поля)"}
                                              defaultValue={props.AuthInfo.notes}
                                              onBlur={changeNotes} ref={Notes}/>
                                </div>
                            </div>
                        </div>
                        <div className="page">
                            <div className="container">
                                <Switch>
                                    <Route path="/support/audit/player/:username/:period/:setc"
                                           render={() => <AuditPlayerContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/users/user/:id/:direction' render={() => <UserContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/formular/finresults' render={() => <FinResultsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/formular/finresults/list'
                                           render={() => <FinResultsRowsContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/formular/transfers/list'
                                           render={() => <TransfersContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/scheduler' render={() => <SchedulerContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/schedule' render={() => <ScheduleCalendarContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/formular/finresultsids'
                                           render={() => <FinResultsIdsContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/spin/pool/data' render={() => <SpinPoolDataContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/spin/contracts' render={() => <ContractsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/mtt/contracts' render={() => <ContractsContainerMTT
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/formular/clubs' render={() => <ClubsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/ref/users' render={() => <RefSupportUsersContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/leaderboard' render={() => <LeaderboardContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/formular/player' render={() => <PlayerContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/training/details' render={() => <FullInfosContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/training/coachstats' render={() => <CoachesStatContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/training/playersstats' render={() => <PlayersStatContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/training/list' render={() => <ListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/salary/admin/state' render={() => <SalaryUsersStateContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/salary/admin/tickets' render={() => <SalaryTicketsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/salary/admin/users' render={() => <SalaryUsersListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/admin/shark/infos_model' render={() => <InfosModel
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/training/discord/messages/send'
                                           render={() => <CoachDiscordSenderContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/salary/admin/rows' render={() => <SalaryRowsListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/finance/coa/all' render={() => <COAPageContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/finance/coa/transfers' render={() => <COATransfersContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/notifications' render={() => <CoachNotificationsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/dev/logs/errors' render={() => <ErrorLogsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/training/personal/slots'
                                           render={() => <PersonalSlotsContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/training/indiv/slots'
                                           render={() => <IndivSlotsContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/admin/bot/welcomemessage'
                                           render={() => <WelcomeMessageEditorContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    {/*<Route exact path='/training/personal/request' render={() => <RequestContainer*/}
                                    {/*    isLoading={props.MainInfo.isLoading}*/}
                                    {/*    changeCurrentPage={props.ChangeCurrentPage}/>}/>*/}
                                    <Route exact path='/coach/training/create' render={() => <CreatorContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    {/*changeCurrentPage={props.ChangeCurrentPage}/>}/>*/}
                                    <Route exact path='/admin/training/connectors' render={() => <ConnectorsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/admin/question/form' render={() => <QuestionsFormContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/leakfinder' render={() => <LeakfinderContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>


                                    <Route exact path='/support/mtt/players/tournaments' render={() => <TournamentsMttContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/leakfinder_spin' render={() => <LeakfinderSpinContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/leakfinder_spin/ranks'
                                           render={() => <LeakfinderSchemasSpinContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>


                                    <Route exact path='/coach/leakfinder/details' render={() => <LeakfinderDetailsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/leakfinder/ranks'
                                           render={() => <LeakfinderSchemasContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/coach/leakfinder/startstack'
                                           render={() => <StartStackTableContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/admin/referral' render={() => <ReferralContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/users/list' render={() => <UsersContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/admin/pci/templates' render={() => <CustomProfileInfoContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/audit/list' render={() => <AuditListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/support/changer/banks' render={() => <ChangerBanksContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}
                                    />}/>
                                    <Route exact path='/support/changer/tickets' render={() => <ChangerTicketsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}
                                    />}/>
                                    <Route exact path='/support/tasks' render={() => <TasksTicketsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}
                                        type={"task"}/>}/>
                                    <Route exact path='/support/tickets' render={() => <TasksTicketsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}
                                        type={"ticket"}/>}/>
                                    <Route exact path='/support/tourney_request' render={() => <TasksTicketsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}
                                        type={"tourney_request"}/>}/>
                                    <Route exact path='/support/tg_tickets' render={() => <TasksTicketsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}
                                        type={"tg_ticket"}/>}/>
                                    <Route exact path='/dev/bugs' render={() => <BugTicketsContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}
                                        type={"bug"}/>}/>
                                    <Route exact path='/disk/:supportTask' render={() => <DiskFilesContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/learn/:learnName' render={() => <LearnContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/learn' render={() => <LearnContainer
                                        learnName={"default"}
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/admin/auditor/auditors' render={() => <AuditorsListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/admin/auditor/tasks' render={() => <AuditorTasksContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/admin/auditor/editor' render={() => <AuditorEditorContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/disk' render={() => <DiskFilesContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/wiki/list' render={() => <WikiListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/admin/supports/editor' render={() => <SupportsEditorContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/admin/users/list' render={() => <UsersListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/admin/users/:id' render={() => <UserPageContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/workspace/:docType/:docName' render={() => <CustomPageContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/admin/roles' render={() => <RolesListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/admin/pages' render={() => <PagesListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/admin/categories' render={() => <CategoriesListContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/wiki/search'
                                           render={() => <WikiSearchContainer
                                               isLoading={props.MainInfo.isLoading}
                                               changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route exact path='/wiki/creator' render={() => <WikiCreatorContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/wiki/:id' render={() => <WikiPageContainer
                                        isLoading={props.MainInfo.isLoading}
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='/' render={() => <MainPageContainer
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                    <Route path='*' render={() => <Error_404_Container
                                        changeCurrentPage={props.ChangeCurrentPage}/>}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={"authPage"}>
                        <AuthContainer/>

                    </div>
            }

            {/*{*/}
            {/*    Cookies.get("UPDATES_READ") != "1" && props.AuthInfo.username ?*/}
            {/*        <Popup key={"checkbox_rules_fs_changer"}*/}
            {/*               defaultOpen={true}*/}
            {/*               modal*/}
            {/*               nested*/}
            {/*               onClose={() => Cookies.set('UPDATES_READ', "1", {expires: 360})}*/}
            {/*        >*/}
            {/*            {(close) => (*/}
            {/*                <div>*/}
            {/*                    <div id="get-advice" className="main-popup">*/}
            {/*                        <form className="form">*/}
            {/*                            <div className="formgroup">*/}
            {/*                                <div style={{*/}
            {/*                                    width: "5%", marginTop: "-30px",*/}
            {/*                                    float: "right", cursor: "pointer"*/}
            {/*                                }} onClick={() => {*/}
            {/*                                    Cookies.set('UPDATES_READ', "1", {expires: 360})*/}
            {/*                                    close()*/}
            {/*                                }}>*/}
            {/*                                    <img*/}
            {/*                                        src="https://icon-library.com/images/close-icon-png-transparent/close-icon-png-transparent-14.jpg"*/}
            {/*                                        alt="img"/>*/}
            {/*                                </div>*/}
            {/*                                <span style={{color: "white"}}>*/}
            {/*                                    <span style={{fontWeight: "bolder", fontSize: "18"}}>*/}
            {/*                                        Последние обновления*/}
            {/*                                    </span>*/}
            {/*                                    <br/>*/}
            {/*                                    <br/>*/}
            {/*                                    <ol>*/}
            {/*                                        <li>*/}
            {/*                                            Запись на тренировки удалены из этой версии кабинета, теперь они находятся в новой версии (перейти в новую версию можно по кнопке справа сверху)*/}
            {/*                                        </li>*/}
            {/*                                        <li>*/}
            {/*                                             На следующих страницах (в новой версии ЛК): Главная; Фин.результаты; Трансферы; Тренировки обновлены таблицы, теперь можно сортировать всё, что угодно + менять размер столбцов по ситуации + возможность выделять текст и копировать его*/}
            {/*                                        </li>*/}
            {/*                                        <li>*/}
            {/*                                             На следующих страницах (в новой версии ЛК): Главная; Фин.результаты; Трансферы; Тренировки обновлены/исправлены фильтры, теперь они работают как надо + стали удобнее*/}
            {/*                                        </li>*/}
            {/*                                        <li>*/}
            {/*                                            В обменнике добавлена анимация отправки запроса, в случае успеха отправки вы увидите галочку*/}
            {/*                                        </li>*/}
            {/*                                        <li>*/}
            {/*                                            Исправлена ошибка, что у некоторых была ошибка в обменнике "Вы превысили лимит запросов, максимум 5"*/}
            {/*                                        </li>*/}
            {/*                                        <li>*/}
            {/*                                            Добавлены уведомления по фин.результатам (если у вас появились новые результаты - в вашу личную конференцию об этом скажет бот)*/}
            {/*                                        </li>*/}
            {/*                                    </ol>*/}
            {/*                                    /!*{*!/*/}
            {/*                                    /!*    isSupport || props.AuthInfo.isAdmin ?*!/*/}
            {/*                                    /!*        <div style={{marginTop: "40px"}}>*!/*/}
            {/*                                    /!*            <span style={{fontWeight: "bolder", fontSize: "18"}}>*!/*/}
            {/*                                    /!*                Support функционал*!/*/}
            {/*                                    /!*            </span>*!/*/}
            {/*                                    /!*            <br/>*!/*/}
            {/*                                    /!*            <br/>*!/*/}
            {/*                                    /!*            <ol>*!/*/}
            {/*                                    /!*                <li>*!/*/}
            {/*                                    /!*                    Фикс 4-х проверяющих*!/*/}
            {/*                                    /!*                </li>*!/*/}
            {/*                                    /!*            </ol>*!/*/}
            {/*                                    /!*        </div>*!/*/}
            {/*                                    /!*        : null*!/*/}
            {/*                                    /!*}*!/*/}
            {/*                                </span>*/}
            {/*                            </div>*/}
            {/*                        </form>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*        </Popup>*/}
            {/*        : null*/}
            {/*}*/}

        </div>
    )
}

export default Main;