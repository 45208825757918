import React, {useState} from 'react';
import s from './styles.module.css'
import {Button, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery} from "@mui/material";
import classNames from "classnames";

const ActionRight = ({lastSelectedDate, addDate, closeModal, supportsList}) => {
    const isBreakPoint = useMediaQuery('(max-width:1200px)');

    const hours = Array.from(({length: 24}), (v, k) => k + 1)
    const minutes = Array.from(({length: 60}), (v, k) => k + 1)

    // const [users, setUsers] = useState([
    //     {name: 'Дима', id: 1},
    //     {name: 'Виктор', id: 2},
    //     {name: 'Сергей', id: 3},
    // ])

    const [value, setValue] = useState('');
    const [title, setTitle] = useState('');
    const [time, setTime] = useState({
        start: {
            hours: '',
            minutes: ''
        },
        end: {
            hours: '',
            minutes: ''
        },
    })
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const blockInvalidChar = e => ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault()

    return (

        <div className={classNames(s.action_right, isBreakPoint && s.mobile_action_right)}>

            <FormControl fullWidth sx={{
                color: '#fff',
                '& .Mui-focused': {
                    color: '#fff !important'
                },
            }}>
                <InputLabel id="demo-simple-select-standard-label" sx={{
                    color: '#fff',
                    '& .Mui-focused': {
                        color: '#fff !important'
                    },
                }}>Имя</InputLabel>
                <Select
                    sx={{
                        color: '#fff',
                        borderColor: '#fff',
                        outline: 'none',
                        backgroundColor: '#27282A',
                        border: 'none',
                        '& .MuiSvgIcon-root': {
                            color: '#fff'
                        },

                        '& .MuiOutlinedInput-notchedOutline ': {
                            borderColor: '#fff !important',
                            color: '#fff !important'
                        }
                    }}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={value}
                    onChange={handleChange}
                    label="Имя"
                >
                    {supportsList.map((el) => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}

                </Select>
            </FormControl>

            <TextField value={title} onChange={(e) => setTitle(e.target.value)} sx={{
                marginTop: '20px',
                backgroundColor: '#27282A',
                color: '#fff !important',
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#fff !important',
                },
                '& .MuiOutlinedInput-notchedOutline span': {
                    color: '#fff !important',
                },
                '& .MuiFormLabel-root': {
                    color: '#fff !important',
                },
                '& .MuiInputBase-root': {
                    color: '#fff !important',
                }
            }} fullWidth id="outlined-basic" label="Задача" variant="outlined"/>

            <h3 className={s.title}>Начало</h3>
            <div className={s.time_picker}>
                <FormControl fullWidth sx={{
                    marginRight: '10px'
                }}>
                    <TextField
                        sx={{
                            backgroundColor: '#27282A',
                            color: '#fff !important',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#fff !important',
                            },
                            '& .MuiOutlinedInput-notchedOutline span': {
                                color: '#fff !important',
                            },
                            '& .MuiFormLabel-root': {
                                color: '#fff !important',
                            },
                            '& .MuiInputBase-root': {
                                color: '#fff !important',
                            }
                        }}
                        type={"number"}
                        autoComplete={'none'}
                        value={time.start.hours}
                        InputProps={{inputProps: {min: 0, max: 23}}}
                        onKeyDown={blockInvalidChar}
                        onChange={(e) => {
                            if (+e.target.value > 23) {
                                setTime({...time, start: {...time.start, hours: 23}})
                            } else if (+e.target.value < 0) {
                                setTime({...time, start: {...time.start, hours: 0}})
                            } else {
                                setTime({...time, start: {...time.start, hours: e.target.value}})
                            }
                        }}
                        label="Часы"
                    >
                    </TextField>
                </FormControl>
                <FormControl fullWidth sx={{
                    marginLeft: '10px'
                }}>
                    <TextField
                        sx={{
                            backgroundColor: '#27282A',
                            color: '#fff !important',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#fff !important',
                            },
                            '& .MuiOutlinedInput-notchedOutline span': {
                                color: '#fff !important',
                            },
                            '& .MuiFormLabel-root': {
                                color: '#fff !important',
                            },
                            '& .MuiInputBase-root': {
                                color: '#fff !important',
                            }
                        }}
                        type={"number"}
                        autoComplete={'none'}
                        value={time.start.minutes}
                        InputProps={{inputProps: {min: 0, max: 59}}}
                        onKeyDown={blockInvalidChar}
                        onChange={(e) => {
                            if (+e.target.value > 59) {
                                setTime({...time, start: {...time.start, minutes: 59}})
                            } else if (+e.target.value < 0) {
                                setTime({...time, start: {...time.start, minutes: 0}})
                            } else {
                                setTime({...time, start: {...time.start, minutes: e.target.value}})
                            }
                        }}
                        label="Минуты"
                    >
                        {minutes.map(el => <MenuItem value={el} key={el}>{el}</MenuItem>)}
                    </TextField>
                </FormControl>
            </div>

            <h3 className={s.title}>Конец</h3>
            <div className={s.time_picker}>
                <FormControl fullWidth sx={{
                    marginRight: '10px'
                }}>
                    <TextField
                        sx={{
                            backgroundColor: '#27282A',
                            color: '#fff !important',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#fff !important',
                            },
                            '& .MuiOutlinedInput-notchedOutline span': {
                                color: '#fff !important',
                            },
                            '& .MuiFormLabel-root': {
                                color: '#fff !important',
                            },
                            '& .MuiInputBase-root': {
                                color: '#fff !important',
                            }
                        }}
                        type={"number"}
                        autoComplete={'none'}
                        InputProps={{inputProps: {min: 0, max: 23}}}
                        value={time.end.hours}
                        onKeyDown={blockInvalidChar}
                        onChange={(e) => {
                            if (+e.target.value > 23) {
                                setTime({...time, end: {...time.end, hours: 23}})
                            } else if (+e.target.value < 0) {
                                setTime({...time, end: {...time.end, hours: 0}})
                            } else {
                                setTime({...time, end: {...time.end, hours: e.target.value}})
                            }
                        }}
                        label="Часы"
                    >
                        {hours.map(el => <MenuItem value={el} key={el}>{el}</MenuItem>)}
                    </TextField>
                </FormControl>
                <FormControl fullWidth sx={{
                    marginLeft: '10px'
                }}>
                    <TextField
                        sx={{
                            backgroundColor: '#27282A',
                            color: '#fff !important',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#fff !important',
                            },
                            '& .MuiOutlinedInput-notchedOutline span': {
                                color: '#fff !important',
                            },
                            '& .MuiFormLabel-root': {
                                color: '#fff !important',
                            },
                            '& .MuiInputBase-root': {
                                color: '#fff !important',
                            }
                        }}
                        type={"number"}
                        autoComplete={'none'}
                        InputProps={{inputProps: {min: 0, max: 59}}}
                        value={time.end.minutes}
                        onKeyDown={blockInvalidChar}
                        onChange={(e) => {
                            if (+e.target.value > 59) {
                                setTime({...time, end: {...time.end, minutes: 59}})
                            } else if (+e.target.value < 0) {
                                setTime({...time, end: {...time.end, minutes: 0}})
                            } else {
                                setTime({...time, end: {...time.end, minutes: e.target.value}})
                            }
                        }}
                        label="Минуты"
                    >
                        {minutes.map(el => <MenuItem value={el} key={el}>{el}</MenuItem>)}
                    </TextField>
                </FormControl>
            </div>

            <Button variant="contained" color={"warning"}
                    onClick={() => {
                        addDate(
                            supportsList.find((f) => f.id === value),
                            title,
                            `${time.start.hours === ''
                                ? '00' : time.start.hours}:${time.start.minutes === ''
                                ? '00' : time.start.minutes}-${time.end.hours === ''
                                ? '00' : time.end.hours}:${time.end.minutes === ''
                                ? '00' : time.end.minutes}`)
                        isBreakPoint && closeModal(false)
                    }}
                    fullWidth
                    disabled={lastSelectedDate?.length === 0 || lastSelectedDate === null} sx={{
                marginTop: '20px'
            }}>
                Сохранить
            </Button>
            {isBreakPoint && <Button variant="contained" color={"error"}
                                     onClick={() => closeModal(false)}
                                     fullWidth
                                     sx={{
                                         marginTop: '20px',
                                         color: '#fff !important',
                                         '.Mui-disabled': {
                                             color: '#fff !important'
                                         }
                                     }}>
                Выход
            </Button>}
        </div>
    );
};

export default ActionRight;
