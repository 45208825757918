import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as DateSvg} from "../../assests/date.svg";
import {DateRange} from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import {useWindowSize} from "../../hooks/useWindowSize";
import moment from "moment-timezone";

const month = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']

const CustomDatePicker = ({state, setState, GetData, filters, system = null, setIsNeedUpdateRows}) => {
    const [showDate, setShowDate] = useState(false)
    const {width, height} = useWindowSize()

    const handleSetShowDate = (bool) => {
        setShowDate(bool)
        if (!bool) {
            setIsNeedUpdateRows(true);
            // if (system = "mainPage") {
            //     // console.log(filters)
            //     // GetData(filters)
            // } else {
            //     GetData(filters)
            // }
        }
    }
    const isMobile = width <= 1024

    const date = new Date(state[0].startDate)
    const date2 = new Date(state[0].endDate)

    const firstFullDate = `${date.getDate()} ${month[date.getMonth()]}`
    const secondFullDate = `${date2.getDate()} ${month[date2.getMonth()]}`

    return (
        <div className={s.date_container}>
            <div className={s.select_box} style={{
                background: showDate && '#252736',
                border: showDate && '1px solid #2F80ED',
                borderRadius: showDate && '10px',
            }} onClick={() => handleSetShowDate(!showDate)}>
                <span className={s.span}>{firstFullDate} - {secondFullDate}</span>
                <DateSvg className={s.date}/>
            </div>

            <div className={s.date_box} style={{
                height: (isMobile && showDate ? 700 : 0) || (showDate ? 330 : 0),
                zIndex: 1000
                // height: showDate ? 830 : 0
            }}>
                <DateRange
                    className={s.date_range}
                    editableDateInputs={true}
                    onChange={item => {
                        const date11 = moment(item.selection.endDate).tz("Europe/Moscow").format()
                        const date2222 = moment(item.selection.startDate).tz("Europe/Moscow").format()
                        const date1 = new Date(item.selection.startDate)
                        const date2 = new Date(item.selection.endDate)
                        setState([{
                            ...item.selection,
                            endDate: new Date(date11),
                            startDate: new Date(date2222),
                            reqStartDate: `${date1.getFullYear()}/${date1.getMonth() + 1}/${date1.getDate()}`,
                            reqEndDate: `${date2.getFullYear()}/${date2.getMonth() + 1}/${date2.getDate()}`
                        }])
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    months={2}
                    direction={isMobile ? 'vertical' : "horizontal"}
                    locale={locales['ru']}
                    rangeColors={['#2196F3']}
                    showMonthAndYearPickers={false}
                    onRangeFocusChange={(e) => {
                        if (e[1] === 0) {
                            handleSetShowDate(false)
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default CustomDatePicker;