import React, {useState, useRef} from "react";
import {NavLink} from "react-router-dom";
import WikiSearchContainer from "./wikiSearchContainer";
import MainLoading from "../common/mainLoading";

export default function WikiList(props) {
    return (
        props.isLoading ? <MainLoading/>
            :
            <div className="wiki-list">
                <WikiSearchContainer/>

                {props.WikiInfo.searchPages[0] ?
                    props.WikiInfo.searchPages.map((page, i) =>
                        <NavLink key={page.name + "wiki-page" + i} to={`/wiki/${page.id}`} className={"block"}>
                            <div className={"name"}>
                                {`${page.name}`}<a>{page.category ? " [" + page.category + "]" : null}</a>
                            </div>
                            <div className={"info"}>
                                <div className={"author"}>
                                    Автор: {`${page.creator}`}
                                </div>
                                <div className={"last_update"}>
                                    Последнее обновление: {`${page.lastUpdate} (${page.lastEditor})`}
                                </div>
                            </div>
                        </NavLink>)
                    :
                    <div className={"error"}>
                        Статьи не найдены
                    </div>
                }
            </div>
    );
}
