import React from 'react';
import s from './styles.module.css'
import {ReactComponent as Close} from "../../assests/exit.svg";
import logo from '../../assests/mobile_logo.png'
import NavigateMenu from "./items/navigate";
import UserMobileMenu from "./items/user";
import TextMobileMenu from "./items/text";
import MobileMenuContainer from "./items/user/userMobileMenuContainer";

const MenuMobileContainer = (props) => {
    return (
        <div className={s.menu_box} style={{width: props.showRightMenu ? '100%' : 0}}>
            <div className={s.menu_box_header}>
                <img src={logo} alt="logo"/>
                <Close onClick={props.onClose} className={s.close}/>
            </div>

            <div>
                {props.type === 'navigate' &&
                    <NavigateMenu onClose={props.onClose}
                                  showRightMenu={props.showRightMenu} setSelected={props.setSelected}
                                  selected={props.selected}
                                  setSelectThird={props.setSelectThird}
                                  selectThird={props.selectThird}
                                  setSelectFirst={props.setSelectFirst}
                                  selectFirst={props.selectFirst}
                                  setSelectSecond={props.setSelectSecond}
                                  selectSecond={props.selectSecond}
                                  setHiddenLeft={props.setHiddenLeft} hiddenLeft={props.hiddenLeft}
                                  setHiddenRight={props.setHiddenRight}
                                  hiddenRight={props.hiddenRight}
                                  AuthInfo={props.AuthInfo}
                    />}
                {props.type === 'user' && <MobileMenuContainer/>}
                {props.type === 'text' && <TextMobileMenu {...props}/>}
            </div>
        </div>
    );
};

export default MenuMobileContainer;