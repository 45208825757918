import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import Learn from "./learn";
import {EditLearn, GetLearn} from "../../../redux/reducers/learnReducer";

class LearnContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.GetLearn("default")
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.props.GetLearn("default")
    }


    render() {
        return (
            <Learn learnName={"default"} {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    LearnInfo: state.learnReducer
})


export default compose(
    connect(mapStateToProps, {
        GetLearn, EditLearn
    }),
    withRouter
)(LearnContainer)