import React, {useState} from 'react';
import moment from "moment/moment";

const ContractsContent = ({user, nameStyle, inputStyle, handleEditContracts}) => {
    const [isContractSetHistoryOpened, setIsContractSetHistoryOpened] = useState(false);

    function formatDate(date) {
        return moment(new Date(date)).format('DD.MM.YYYY')
    }
    function formatDateStandart(date) {
        return moment(new Date(date)).format('YYYY-MM-DD')
    }

    const dropdownStyle = {
        padding: "2px 0", width: "100vh",
        fontSize: "14px", color: "#4689d0",
        border: "none", cursor: "pointer"
    }
    const textStyle = {
        padding: "2px 0", width: "100vh",
        fontSize: "14px", color: "white",
        border: "none"
    }

    return (
        <div style={{margin: '2% 7% 0 5%'}}>
            {
                !user.mtt_profile ?
                    <div style={{padding: "20px"}}>Игрок еще не инициализирован. Пожалуйста, подождите ~10-30 минут до
                        завершения
                        процесса.</div> :
                    <div style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexFlow: "wrap"
                    }}>
                        <div>
                            <div style={nameStyle}>День закрытия сета (ввод дня месяца)</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditContracts("day_set_close", null, null, null, e.target.value)}
                                   defaultValue={user?.mtt_contracts?.day_set_close} type="number"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>История изменений дня закрытия сета</div>
                            {
                                isContractSetHistoryOpened ?
                                    <div style={dropdownStyle}>
                                        <div onClick={() => setIsContractSetHistoryOpened(false)}>
                                            Нажмите для скрытия
                                        </div>
                                        <div>
                                            {
                                                user?.mtt_contracts?.contracts_set_history.map(e => {
                                                    return <div style={{color: "gray"}}>
                                                        Изменено {formatDate(e.date_change)} | Изменил {e.who} | Было {e.old} | Стало {e.new}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div style={dropdownStyle} onClick={() => setIsContractSetHistoryOpened(true)}>
                                        Нажмите для просмотра
                                    </div>
                            }
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Дата отсчета отыгранной дистанции по контракту</div>
                            <input style={inputStyle}
                                   onBlur={(e) => handleEditContracts("date_start_contract_distance", null, null, null, e.target.value)}
                                   defaultValue={formatDateStandart(user?.mtt_contracts?.date_start_contract_distance)} type="date"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Аннулирование индивидуальной тренировки в определенный день месяца</div>
                            <input style={inputStyle}
                                   onBlur={(e) => handleEditContracts("day_remove_training_quota", null, null, null, e.target.value)}
                                   defaultValue={user?.mtt_contracts?.day_remove_training_quota} type="number"/>
                        </div>

                        <hr style={{width: "100vh", marginTop: "70px"}}/>

                        <div style={{marginTop: "70px"}}>
                            <div style={nameStyle}>Дата подписания контракта</div>
                            <input style={inputStyle}
                                   onBlur={(e) => handleEditContracts("date_start_contract", null, null, null, e.target.value)}
                                   defaultValue={formatDateStandart(user?.mtt_contracts?.date_start_contract)} type="date"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Тип длительности контракта</div>
                            <div style={textStyle}>
                                { user.mtt_contracts.contract_duration_type ? user.mtt_contracts.contract_duration_type : "None" }
                            </div>
                            <div style={dropdownStyle} onClick={(e) => handleEditContracts("day_remove_training_quota", null, null, null, user.mtt_contracts.contract_duration_type ? user.mtt_contracts.contract_duration_type == "date" ? "tourney" : "date" : "date")}>
                                Нажмите, чтобы изменить тип
                            </div>
                        </div>

                        {
                            user.mtt_contracts.contract_duration_type && user.mtt_contracts.contract_duration_type == 'date' &&
                            <div style={{marginTop: "20px"}}>
                                <div style={nameStyle}>Длительность контракта (дата)</div>
                                <input style={inputStyle}
                                       onBlur={(e) => handleEditContracts("date_end_contract", null, null, null, e.target.value)}
                                       defaultValue={formatDateStandart(user?.mtt_contracts?.date_end_contract)} type="date"/>
                            </div>
                        }

                        {
                            user.mtt_contracts.contract_duration_type && user.mtt_contracts.contract_duration_type == 'tourney' &&
                            <div style={{marginTop: "20px"}}>
                                <div style={nameStyle}>Длительность контракта (количество турниров)</div>
                                <input style={inputStyle}
                                       onBlur={(e) => handleEditContracts("tourney_end_contract", null, null, null, e.target.value)}
                                       defaultValue={user?.mtt_contracts?.tourney_end_contract} type="number"/>
                            </div>
                        }
                    </div>
            }
        </div>
    );
};

export default ContractsContent;