import React, {useState, useRef} from "react"

const AuditPlayer = (props) => {
    let lastEdit = '';
    if (props.AuditorInfo.currentPlayer && props.AuditorInfo.currentPlayer.nickname) {
        lastEdit = props.AuditorInfo.currentPlayer.lastEdit
    }

    function Save(e, type, subType = null, fieldName = null, subFieldName = null) {
        if (e.target.value == e.target.defaultValue) return null;
        props.SupportSaveTask({
            type,
            subType,
            fieldName,
            subFieldName,
            newValue: e.target.value,
            nickname: props.AuditorInfo.currentPlayer.nickname,
            period: props.AuditorInfo.currentPlayer.period,
            setCount: props.AuditorInfo.currentPlayer.setCount
        })
    }

    return (
        // props.isLoading ? <MainLoading/>
        //     :
        props.AuditorInfo.currentPlayer && props.AuditorInfo.currentPlayer.nickname ?
            <div className={"audit-player-page"}>
                <div className={"rooms"}>
                    {props.AuditorInfo.currentPlayer.rooms.map((room, i) => <Room
                        follow_status={props.AuditorInfo.currentPlayer.follow_status}
                        previousRooms={props.AuditorInfo.currentPlayer.previousRooms}
                        nickname={props.AuditorInfo.currentPlayer.nickname}
                        period={props.AuditorInfo.currentPlayer.period}
                        key={i + room.name}
                        room={room} lastEdit={lastEdit}
                        SupportSaveTask={props.SupportSaveTask}
                        setCount={props.AuditorInfo.currentPlayer.setCount}/>)}
                </div>
                <div className={"debts-wallets"}>
                    <div className={"block"}>
                        <div className={"name"}>
                            Долги (авансы) за месяц
                        </div>
                        <div className={"wallets-inputs"}>
                            <div>
                                <div className={"little-input"}>
                                    <div className={"input-name"}>
                                        USD
                                    </div>
                                    <input
                                        defaultValue={props.AuditorInfo.currentPlayer.debts[0].usd_amount}
                                        onBlur={(e) => Save(e, "debts", "", "usd_amount", "")}/>
                                </div>
                                <div className={"little-input"}>
                                    <div className={"input-name"}>
                                        EUR
                                    </div>
                                    <input
                                        defaultValue={props.AuditorInfo.currentPlayer.debts[0].eur_amount}
                                        onBlur={(e) => Save(e, "debts", "", "eur_amount", "")}/>
                                </div>
                            </div>
                            <div>
                                <div className={"big-textarea"}>
                                    <div className={"input-name"}>
                                        Комментарий
                                    </div>
                                    <textarea
                                        defaultValue={props.AuditorInfo.currentPlayer.debts[0].description}
                                        rows={5} style={{resize: "none", width: "50%"}}
                                        onBlur={(e) => Save(e, "debts", "", "description", "")}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={"debts-wallets"}>
                    <div className={"block"}>
                        <div className={"name"}>
                            Кошельки
                        </div>
                        <div className={"wallets-inputs"}>
                            <div>
                                <div className={"little-input"}>
                                    <div className={"input-name"}>
                                        Skrill USD
                                    </div>
                                    <input
                                        defaultValue={props.AuditorInfo.currentPlayer.wallets[0].skrill_usd}
                                        onBlur={(e) => Save(e, "wallets", "", "skrill_usd", "")}/>
                                </div>
                                <div className={"little-input"}>
                                    <div className={"input-name"}>
                                        Skrill EUR
                                    </div>
                                    <input
                                        defaultValue={props.AuditorInfo.currentPlayer.wallets[0].skrill_eur}
                                        onBlur={(e) => Save(e, "wallets", "", "skrill_eur", "")}/>
                                </div>
                                <div className={"little-input"}>
                                    <div className={"input-name"}>
                                        Наличные USD
                                    </div>
                                    <input
                                        defaultValue={props.AuditorInfo.currentPlayer.wallets[0].cash_usd}
                                        onBlur={(e) => Save(e, "wallets", "", "cash_usd", "")}/>
                                </div>
                            </div>
                            <div>
                                <div className={"little-input"}>
                                    <div className={"input-name"}>
                                        Крипта USDT
                                    </div>
                                    <input
                                        defaultValue={props.AuditorInfo.currentPlayer.wallets[0].crypto_usdt}
                                        onBlur={(e) => Save(e, "wallets", "", "crypto_usdt", "")}/>
                                </div>
                                <div className={"little-input"}>
                                    <div className={"input-name"}>
                                        Крипта Other
                                    </div>
                                    <input
                                        defaultValue={props.AuditorInfo.currentPlayer.wallets[0].crypto_other}
                                        onBlur={(e) => Save(e, "wallets", "", "crypto_other", "")}/>
                                </div>
                                <div className={"little-input"}>
                                    <div className={"input-name"}>
                                        Other
                                    </div>
                                    <input
                                        defaultValue={props.AuditorInfo.currentPlayer.wallets[0].other}
                                        onBlur={(e) => Save(e, "wallets", "", "other", "")}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className={"other"}>
                    <div className={"block"}>
                        <div className={"name"}>
                            Остальное
                        </div>
                        <div className={"wallets-inputs"}>
                            <div className={"big-textarea"}>
                                <div className={"input-name"}>
                                    Общий комментарий
                                </div>
                                <textarea
                                    defaultValue={props.AuditorInfo.currentPlayer.description} rows={10}
                                    style={{resize: "none"}}
                                    onBlur={(e) => Save(e, "support", "", "description", "")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <div style={{color: "white"}}>Игрок не найден</div>
    )
}

const Room = (props) => {
    let previousBalance = "N/A";

    props.previousRooms.forEach(room => {
        if (room.nickname == props.nickname) {
            room.rooms.forEach(r => {
                if (r.name == props.room.name) previousBalance = r.current_balance;
            })
        }
    })

    function Save(e, type, subType = null, fieldName = null, subFieldName = null) {
        if (subType == "remove") {
            props.SupportSaveTask({
                type,
                subType,
                fieldName: props.room.name,
                nickname: props.nickname,
                period: props.period,
                setCount: props.setCount
            })
        } else if (subType == "edit") {
            if (e.target.value == e.target.defaultValue) return null;
            props.SupportSaveTask({
                type,
                subType,
                fieldName: props.room.name,
                subFieldName,
                newValue: e.target.value,
                nickname: props.nickname,
                period: props.period,
                setCount: props.setCount
            })
        }
    }

    return (
        <div className={"room"}>
            <div className={"description"}>
                                <span className={"name"}>
                                    Рум: {props.room.name} (валюта: {props.room.currency})
                                </span>
                <span className={"previous-balance"}>
                                    - Баланс в предыдущем периоде: {previousBalance}
                                </span>
                {
                    props.follow_status ?
                        <span onClick={(e) => Save(e, "room", "remove")}
                              className={"delete-room"}>
                                    x
                                </span>
                        : null
                }

            </div>

            <div className={"room-inputs"}>
                <div className={"column"}>
                    <div className={"block"}>
                        <div className={"little-input"}>
                            <div className={"input-name"}>
                                Количество турниров
                            </div>
                            {!props.follow_status ?
                                <div>{props.room.total_tournaments ? props.room.total_tournaments : "Не было заполнено"}</div>
                                :
                                <input
                                    defaultValue={props.room.total_tournaments}
                                    onBlur={(e) => Save(e, "room", "edit", "", "total_tournaments")}/>

                            }
                        </div>
                        <div className={"little-input"}>
                            <div className={"input-name"}>
                                Профит
                            </div>
                            {!props.follow_status ?
                                <div>{props.room.profit ? props.room.profit : "Не было заполнено"}</div>
                                :
                                <input
                                    defaultValue={props.room.profit}
                                    onBlur={(e) => Save(e, "room", "edit", "", "profit")}/>

                            }
                        </div>
                        <div className={"little-input"}>
                            <div className={"input-name"}>
                                Бонусы
                            </div>
                            {!props.follow_status ?
                                <div>{props.room.bonuses ? props.room.bonuses : "Не было заполнено"}</div>
                                :
                                <input
                                    defaultValue={props.room.bonuses}
                                    onBlur={(e) => Save(e, "room", "edit", "", "bonuses")}/>

                            }
                        </div>
                    </div>
                </div>
                <div className={"column"}>
                    <div className={"block"}>
                        <div className={"little-input"}>
                            <div className={"input-name"}>
                                Коины/Мили
                            </div>
                            {!props.follow_status ?
                                <div>{props.room.coins_miles ? props.room.coins_miles : "Не было заполнено"}</div>
                                :
                                <input
                                    defaultValue={props.room.coins_miles}
                                    onBlur={(e) => Save(e, "room", "edit", "", "coins_miles")}/>

                            }
                        </div>
                        <div className={"little-input"}>
                            <div className={"input-name"}>
                                Стартовый баланс
                            </div>
                            {!props.follow_status ?
                                <div>{props.room.start_balance ? props.room.start_balance : "Не было заполнено"}</div>
                                :
                                <input
                                    defaultValue={props.room.start_balance}
                                    onBlur={(e) => Save(e, "room", "edit", "", "start_balance")}/>

                            }
                        </div>
                        <div className={"little-input"}>
                            <div className={"input-name"}>
                                Конечный баланс
                            </div>
                            {!props.follow_status ?
                                <div>{props.room.current_balance ? props.room.current_balance : "Не было заполнено"}</div>
                                :
                                <input
                                    defaultValue={props.room.current_balance}
                                    onBlur={(e) => Save(e, "room", "edit", "", "current_balance")}/>

                            }
                        </div>
                    </div>
                </div>

                <div className={"column"}>
                    <div className={"block"}>
                        <div className={"little-input"}>
                            <div className={"input-name"}>
                                Аванс/Личный долг
                            </div>
                            {!props.follow_status ?
                                <div>{props.room.personal_duty ? props.room.personal_duty : "Не было заполнено"}</div>
                                :
                                <input
                                    defaultValue={props.room.personal_duty}
                                    onBlur={(e) => Save(e, "room", "edit", "", "personal_duty")}/>

                            }
                        </div>

                        <div className={"little-input"}>
                            <div className={"input-name"}>
                                Стартовый Т-деньги
                            </div>
                            {!props.follow_status ?
                                <div>{props.room.start_T_money ? props.room.start_T_money : "Не было заполнено"}</div>
                                :
                                <input
                                    defaultValue={props.room.start_T_money}
                                    onBlur={(e) => Save(e, "room", "edit", "", "start_T_money")}/>

                            }
                        </div>
                        <div className={"little-input"}>
                            <div className={"input-name"}>
                                Конечный Т-деньги
                            </div>
                            {!props.follow_status ?
                                <div>{props.room.current_T_money ? props.room.current_T_money : "Не было заполнено"}</div>
                                :
                                <input
                                    defaultValue={props.room.current_T_money}
                                    onBlur={(e) => Save(e, "room", "edit", "", "current_T_money")}/>

                            }
                        </div>
                    </div>
                </div>

            </div>

            <div className={"big-input"}>
                <div className={"input-name"}>
                    Комментарий
                </div>
                {!props.follow_status ?
                    <div>{props.room.description ? props.room.description : "Не было заполнено"}</div>
                    :
                    <input
                        defaultValue={props.room.description}
                        onBlur={(e) => Save(e, "room", "edit", "", "description")}/>

                }
            </div>
        </div>
    )
}

export default AuditPlayer;