import React, {useState, useRef} from "react";

export default function MainLoading(props) {
    return (
        <div>
            <div className="main-loading">
                <img src="https://lk.firestorm.team/img/loading2.svg" alt={"loading-svg"}/>
                {props.uploadFilesCount  ? <div className={"upload-file-loading"}>Осталось загрузить файлов: {props.uploadFilesCount}</div> : null}
            </div>
        </div>
    );
}
