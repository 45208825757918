import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import SendQuestionToSupport from "./sendQuestionToSupport";
import {UserSendQuestion} from "../../../redux/reducers/mainReducer";

class SendQuestionToSupportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }


    componentDidMount() {

    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <SendQuestionToSupport {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({

})


export default compose(
    connect(mapStateToProps, {
        UserSendQuestion
    }),
    withRouter
)(SendQuestionToSupportContainer)