import React, {useState} from "react";

import {createTheme} from "@material-ui/core/styles";
import Select from 'react-dropdown-select';
import moment from "moment";
import Popup from "reactjs-popup";
import {Radar, Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    Title,
} from 'chart.js'
import {Tooltip as TT} from "@mui/material";


const defaultSchema = {
    "TOTAL PREFLOP": [],
    "HU Preflop": [
        {
            "func": "Preflop_HU_SB_VPIP",
            "name": "Preflop HU SB VPIP",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_SB_Limp",
            "name": "Preflop HU SB Limp",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_SB_Limp_Fold_vs_ISO_NAI",
            "name": "Preflop HU SB Limp Fold vs ISO NAI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_SB_Limp_Fold_vs_ISO_AI",
            "name": "Preflop HU SB Limp Fold vs ISO AI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_SB_Limp_Call_vs_ISO_NAI",
            "name": "Preflop HU SB Limp Call vs ISO NAI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_SB_Limp_Call_vs_ISO_AI",
            "name": "Preflop HU SB Limp Call vs ISO AI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },

        {
            "func": "Preflop_HU_SB_Limp_Raise_AI_vs_ISO_NAI",
            "name": "Preflop HU SB Limp Raise AI vs ISO NAI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_SB_OR_2bb",
            "name": "Preflop HU SB OR 2bb",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_SB_Call_vs_3Bet_NAI",
            "name": "Preflop HU SB Call vs 3Bet NAI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_SB_Call_vs_3Bet_AI",
            "name": "Preflop HU SB Call vs 3Bet AI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_SB_OS",
            "name": "Preflop HU SB OS",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_BB_VPIP",
            "name": "Preflop HU BB VPIP",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_BB_vs_SB_Limp_Check",
            "name": "Preflop HU BB vs SB Limp Check",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_BB_vs_SB_Limp_ISO_NAI",
            "name": "Preflop HU BB vs SB Limp ISO NAI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_BB_vs_SB_Limp_ISO_Fold",
            "name": "Preflop HU BB vs SB Limp ISO Fold",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_BB_vs_SB_Limp_ISO_AI",
            "name": "Preflop HU BB vs SB Limp ISO AI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_BB_vs_SB_OR_2bb_Fold",
            "name": "Preflop HU BB vs SB OR 2bb Fold",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_BB_vs_SB_OR_2bb_Call",
            "name": "Preflop HU BB vs SB OR 2bb Call",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_BB_vs_SB_OR_2bb_3Bet_AI",
            "name": "Preflop HU BB vs SB OR 2bb 3Bet AI",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Preflop_HU_BB_vs_SB_OS_Call",
            "name": "Preflop HU BB vs SB OS Call",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ]
}
const defaultSchemaPostFlop = {
    "TOTAL POSTFLOP": [],
    "---": [],
    "Postflop Total Attack IP": [
        {
            "func": "Postflop_Attack_IP_Bet_Flop",
            "name": "Postflop Attack IP Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_Bet_Turn",
            "name": "Postflop Attack IP Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_Bet_River",
            "name": "Postflop Attack IP Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_Delay",
            "name": "Postflop Attack IP Delay",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_Delay_and_Bet_River",
            "name": "Postflop Attack IP Delay and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_Delay_River",
            "name": "Postflop Attack IP Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_B_X_B",
            "name": "Postflop Attack IP B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_Fold_vs_Donk_Flop",
            "name": "Postflop Attack IP Fold vs Donk Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_Raise_vs_Donk_Flop",
            "name": "Postflop Attack IP Raise vs Donk Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_Fold_vs_Probe_Turn",
            "name": "Postflop Attack IP Fold vs Probe Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_Call_vs_Probe_Turn_and_Fold_River",
            "name": "Postflop Attack IP Call vs Probe Turn and Fold River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_B_X_F",
            "name": "Postflop Attack IP B-X-F",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop Total Attack OOP": [
        {
            "func": "Postflop_Attack_OOP_Bet_Flop",
            "name": "Postflop Attack OOP Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_Bet_Turn",
            "name": "Postflop Attack OOP Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_Bet_River",
            "name": "Postflop Attack OOP Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_Delay",
            "name": "Postflop Attack OOP Delay",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_Delay_and_Bet_River",
            "name": "Postflop Attack OOP Delay and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_Delay_River",
            "name": "Postflop Attack OOP Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_B_X_B",
            "name": "Postflop Attack OOP B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_Fold_vs_FloatBet_Flop",
            "name": "Postflop Attack OOP Fold vs FloatBet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_Fold_vs_FloatBet_Turn",
            "name": "Postflop Attack OOP Fold vs FloatBet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_Fold_vs_FloatBet_River",
            "name": "Postflop Attack OOP Fold vs FloatBet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_Fold_vs_Probe_Turn",
            "name": "Postflop Attack OOP Fold vs Probe Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop Total Defence IP": [
        {
            "func": "Postflop_Defence_IP_Fold_vs_Bet_Flop",
            "name": "Postflop Defence IP Fold vs Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Fold_vs_Bet_Turn",
            "name": "Postflop Defence IP Fold vs Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Fold_vs_Bet_River",
            "name": "Postflop Defence IP Fold vs Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Fold_vs_Delay_Turn",
            "name": "Postflop Defence IP Fold vs Delay Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Call_vs_Delay_and_Fold_River",
            "name": "Postflop Defence IP Call vs Delay and Fold River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Fold_vs_Delay_River",
            "name": "Postflop Defence IP Fold vs Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Fold_vs_B_X_B",
            "name": "Postflop Defence IP Fold vs B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Float_Bet_Flop",
            "name": "Postflop Defence IP Float Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Float_Bet_Turn",
            "name": "Postflop Defence IP Float Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Float_Bet_River",
            "name": "Postflop Defence IP Float Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Float_Bet_Flop_and_Turn",
            "name": "Postflop Defence IP Float Bet Flop and Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_Float_Call_Turn_and_Bet_River",
            "name": "Postflop Defence IP Float Call Turn and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_X_B",
            "name": "Postflop Defence IP X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_X_B_B",
            "name": "Postflop Defence IP X-B-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_X_X_B",
            "name": "Postflop Defence IP X-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop Total Defence OOP": [
        {
            "func": "Postflop_Defence_OOP_Fold_vs_Bet_Flop",
            "name": "Postflop Defence OOP Fold vs Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Fold_vs_Bet_Turn",
            "name": "Postflop Defence OOP Fold vs Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Fold_vs_Bet_River",
            "name": "Postflop Defence OOP Fold vs Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_XR_Flop",
            "name": "Postflop Defence OOP XR Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_XR_Flop_and_Bet_Turn",
            "name": "Postflop_Defence_OOP_XR_Flop_and_Bet_Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Fold_vs_Delay_Turn",
            "name": "Postflop Defence OOP Fold vs Delay Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Call_Turn_and_Fold_vs_Delay_River",
            "name": "Postflop Defence OOP Call Turn and Fold vs Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Fold_vs_Delay_River",
            "name": "Postflop Defence OOP Fold vs Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Probe_Turn",
            "name": "Postflop Defence OOP Probe Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Probe_Turn_and_Bet_River",
            "name": "Postflop Defence OOP Probe Turn and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Call_Flop_and_Probe_River",
            "name": "Postflop Defence OOP Call Flop and Probe River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Donk_Flop",
            "name": "Postflop Defence OOP Donk Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_Donk_Flop_and_Bet_Turn",
            "name": "Postflop Defence OOP Donk Flop and Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop 3Max Attack IP": [
        {
            "func": "Postflop_Attack_IP_3Max_Bet_Flop",
            "name": "Postflop Attack IP 3Max Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_Bet_Turn",
            "name": "Postflop Attack IP 3Max Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_Bet_River",
            "name": "Postflop Attack IP 3Max Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_Delay",
            "name": "Postflop Attack IP 3Max Delay",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_Delay_and_Bet_River",
            "name": "Postflop Attack IP 3Max Delay and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_Delay_River",
            "name": "Postflop Attack IP 3Max Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_B_X_B",
            "name": "Postflop Attack IP 3Max B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_Fold_vs_Donk_Flop",
            "name": "Postflop Attack IP 3Max Fold vs Donk Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_Raise_vs_Donk_Flop",
            "name": "Postflop Attack IP 3Max Raise vs Donk Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_Fold_vs_Probe_Turn",
            "name": "Postflop Attack IP 3Max Fold vs Probe Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_Call_vs_Probe_Turn_and_Fold_River",
            "name": "Postflop Attack IP 3Max Call vs Probe Turn and Fold River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_3Max_B_X_F",
            "name": "Postflop Attack IP 3Max B-X-F",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop 3Max Attack OOP": [
        {
            "func": "Postflop_Attack_OOP_3Max_Bet_Flop",
            "name": "Postflop Attack OOP 3Max Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_Bet_Turn",
            "name": "Postflop Attack OOP 3Max Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_Bet_River",
            "name": "Postflop Attack OOP 3Max Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_Delay",
            "name": "Postflop Attack OOP 3Max Delay",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_Delay_and_Bet_River",
            "name": "Postflop Attack OOP 3Max Delay and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_Delay_River",
            "name": "Postflop Attack OOP 3Max Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_B_X_B",
            "name": "Postflop Attack OOP 3Max B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_Fold_vs_FloatBet_Flop",
            "name": "Postflop Attack OOP 3Max Fold vs FloatBet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_Fold_vs_FloatBet_Turn",
            "name": "Postflop Attack OOP 3Max Fold vs FloatBet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_Fold_vs_FloatBet_River",
            "name": "Postflop Attack OOP 3Max Fold vs FloatBet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_3Max_Fold_vs_Probe_Turn",
            "name": "Postflop Attack OOP 3Max Fold vs Probe Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop 3Max Defence IP": [
        {
            "func": "Postflop_Defence_IP_3Max_Fold_vs_Bet_Flop",
            "name": "Postflop Defence IP 3Max Fold vs Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Fold_vs_Bet_Turn",
            "name": "Postflop Defence IP 3Max Fold vs Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Fold_vs_Bet_River",
            "name": "Postflop Defence IP 3Max Fold vs Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Fold_vs_Delay_Turn",
            "name": "Postflop Defence IP 3Max Fold vs Delay Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Call_vs_Delay_and_Fold_River",
            "name": "Postflop Defence IP 3Max Call vs Delay and Fold River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Fold_vs_Delay_River",
            "name": "Postflop Defence IP 3Max Fold vs Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Fold_vs_B_X_B",
            "name": "Postflop Defence IP 3Max Fold vs B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Float_Bet_Flop",
            "name": "Postflop Defence IP 3Max Float Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Float_Bet_Turn",
            "name": "Postflop Defence IP 3Max Float Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Float_Bet_River",
            "name": "Postflop Defence IP 3Max Float Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Float_Bet_Flop_and_Turn",
            "name": "Postflop Defence IP 3Max Float Bet Flop and Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_Float_Call_Turn_and_Bet_River",
            "name": "Postflop Defence IP 3Max Float Call Turn and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_X_B",
            "name": "Postflop Defence IP 3Max X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_X_B_B",
            "name": "Postflop Defence IP 3Max X-B-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_3Max_X_X_B",
            "name": "Postflop Defence IP 3Max X-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop 3Max Defence OOP": [
        {
            "func": "Postflop_Defence_OOP_3Max_Fold_vs_Bet_Flop",
            "name": "Postflop Defence OOP 3Max Fold vs Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Fold_vs_Bet_Turn",
            "name": "Postflop Defence OOP 3Max Fold vs Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Fold_vs_Bet_River",
            "name": "Postflop Defence OOP 3Max Fold vs Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_XR_Flop",
            "name": "Postflop Defence OOP 3Max XR Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_XR_Flop_and_Bet_Turn",
            "name": "Postflop Defence OOP 3Max XR Flop and Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Fold_vs_Delay_Turn",
            "name": "Postflop Defence OOP 3Max Fold vs Delay Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Call_Turn_and_Fold_vs_Delay_River",
            "name": "Postflop Defence OOP 3Max Call Turn and Fold vs Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Fold_vs_Delay_River",
            "name": "Postflop Defence OOP 3Max Fold vs Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Probe_Turn",
            "name": "Postflop Defence OOP 3Max Probe Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Probe_Turn_and_Bet_River",
            "name": "Postflop Defence OOP 3Max Probe Turn and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Call_Flop_and_Probe_River",
            "name": "Postflop Defence OOP 3Max Call Flop and Probe River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Donk_Flop",
            "name": "Postflop Defence OOP 3Max Donk Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_3Max_Donk_Flop_and_Bet_Turn",
            "name": "Postflop Defence OOP 3Max Donk Flop and Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop HU Attack IP": [
        {
            "func": "Postflop_Attack_IP_HU_Bet_Flop",
            "name": "Postflop Attack IP HU Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_Bet_Turn",
            "name": "Postflop Attack IP HU Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_Bet_River",
            "name": "Postflop Attack IP HU Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_Delay",
            "name": "Postflop Attack IP HU Delay",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_Delay_and_Bet_River",
            "name": "Postflop Attack IP HU Delay and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_Delay_River",
            "name": "Postflop Attack IP HU Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_B_X_B",
            "name": "Postflop Attack IP HU B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_Fold_vs_Donk_Flop",
            "name": "Postflop Attack IP HU Fold vs Donk Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_Raise_vs_Donk_Flop",
            "name": "Postflop Attack IP HU Raise vs Donk Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_Fold_vs_Probe_Turn",
            "name": "Postflop Attack IP HU Fold vs Probe Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_Call_vs_Probe_Turn_and_Fold_River",
            "name": "Postflop Attack IP HU Call vs Probe Turn and Fold River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_IP_HU_B_X_F",
            "name": "Postflop Attack IP HU B-X-F",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop HU Attack OOP": [
        {
            "func": "Postflop_Attack_OOP_HU_Bet_Flop",
            "name": "Postflop Attack OOP HU Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_Bet_Turn",
            "name": "Postflop Attack OOP HU Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_Bet_River",
            "name": "Postflop Attack OOP HU Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_Delay",
            "name": "Postflop Attack OOP HU Delay",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_Delay_and_Bet_River",
            "name": "Postflop Attack OOP HU Delay and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_Delay_River",
            "name": "Postflop Attack OOP HU Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_B_X_B",
            "name": "Postflop Attack OOP HU B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_Fold_vs_FloatBet_Flop",
            "name": "Postflop Attack OOP HU Fold vs FloatBet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_Fold_vs_FloatBet_Turn",
            "name": "Postflop Attack OOP HU Fold vs FloatBet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_Fold_vs_FloatBet_River",
            "name": "Postflop Attack OOP HU Fold vs FloatBet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Attack_OOP_HU_Fold_vs_Probe_Turn",
            "name": "Postflop Attack OOP HU Fold vs Probe Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop HU Defence IP": [
        {
            "func": "Postflop_Defence_IP_HU_Fold_vs_Bet_Flop",
            "name": "Postflop Defence IP HU Fold vs Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Fold_vs_Bet_Turn",
            "name": "Postflop Defence IP HU Fold vs Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Fold_vs_Bet_River",
            "name": "Postflop Defence IP HU Fold vs Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Fold_vs_Delay_Turn",
            "name": "Postflop Defence IP HU Fold vs Delay Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Call_vs_Delay_and_Fold_River",
            "name": "Postflop Defence IP HU Call vs Delay and Fold River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Fold_vs_Delay_River",
            "name": "Postflop Defence IP HU Fold vs Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Fold_vs_B_X_B",
            "name": "Postflop Defence IP HU Fold vs B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Float_Bet_Flop",
            "name": "Postflop Defence IP HU Float Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Float_Bet_Turn",
            "name": "Postflop Defence IP HU Float Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Float_Bet_River",
            "name": "Postflop Defence IP HU Float Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Float_Bet_Flop_and_Turn",
            "name": "Postflop Defence IP HU Float Bet Flop and Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_Float_Call_Turn_and_Bet_River",
            "name": "Postflop_Defence_IP_HU_Float_Call_Turn_and_Bet_River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_X_B",
            "name": "Postflop Defence IP HU X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_X_B_B",
            "name": "Postflop Defence IP HU X-B-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_IP_HU_X_X_B",
            "name": "Postflop Defence IP HU X-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ],
    "Postflop HU Defence OOP": [
        {
            "func": "Postflop_Defence_OOP_HU_Fold_vs_Bet_Flop",
            "name": "Postflop Defence OOP HU Fold vs Bet Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Fold_vs_Bet_Turn",
            "name": "Postflop Defence OOP HU Fold vs Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Fold_vs_Bet_River",
            "name": "Postflop Defence OOP HU Fold vs Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_XR_Flop",
            "name": "Postflop Defence OOP HU XR Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_XR_Flop_and_Bet_Turn",
            "name": "Postflop Defence OOP HU XR Flop and Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Fold_vs_Delay_Turn",
            "name": "Postflop Defence OOP HU Fold vs Delay Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Call_Turn_and_Fold_vs_Delay_River",
            "name": "Postflop Defence OOP HU Call Turn and Fold vs Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Fold_vs_Delay_River",
            "name": "Postflop Defence OOP HU Fold vs Delay River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Probe_Turn",
            "name": "Postflop Defence OOP HU Probe Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Probe_Turn_and_Bet_River",
            "name": "Postflop Defence OOP HU Probe Turn and Bet River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Call_Flop_and_Probe_River",
            "name": "Postflop Defence OOP HU Call Flop and Probe River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Donk_Flop",
            "name": "Postflop Defence OOP HU Donk Flop",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Donk_Flop_and_Bet_Turn",
            "name": "Postflop Defence OOP HU Donk Flop and Bet Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Donk_Turn",
            "name": "Postflop Defence OOP HU Donk Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Donk_River",
            "name": "Postflop Defence OOP HU Donk River",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Fold_vs_B_X_B",
            "name": "Postflop Defence OOP HU Fold vs B-X-B",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Check_Raise_Turn",
            "name": "Postflop Defence OOP HU Check Raise Turn",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        },
        {
            "func": "Postflop_Defence_OOP_HU_Check_Raise_and_Bet_Bet",
            "name": "Postflop Defence OOP HU Check Raise and Bet Bet",
            "value": 0,
            "title": "help",
            "standards": [
                "0",
                "0"
            ],
            "evStandards": [
                "0",
                "0"
            ],
            "is_visible": true,
            "formula": "None"
        }
    ]
}


ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale,
);

function randomInteger(min, max) {
    let rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
}

const RankSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите ранг "}
            labelField={"name"}
            valueField={"name"}
            options={props.options}
            values={[]}
            onChange={(value) => {
                if (value && value[0]) props.handleSelectRank(value)
            }
            }
            style={{
                backgroundColor: "#4c516a",
                margin: "5px 12px",
                fontSize: "19px",
                border: "none",
                paddingLeft: "11px",
                zIndex: "102"
            }}
        />
    )
}

function Matrix({data, color}) {
    let colors_g = [{v: 0, c: "#014800"}, {v: 0.1, c: "#167101"}, {v: 0.3, c: "#03bc04"},
        {v: 0.75, c: "#07f515"}, {v: 2, c: "#8fea07"}, {v: 5, c: "#d8f506"}];
    let colors_b = [{v: 0, c: "#022e50"}, {v: 0.1, c: "#033684"}, {v: 0.3, c: "#0653b2"},
        {v: 0.75, c: "#0490f1"}, {v: 2, c: "#07cefc"}, {v: 5, c: "#00f3e9"}];
    let colors_r = [{v: 0, c: "#4b000c"}, {v: 0.1, c: "#710303"}, {v: 0.3, c: "#bc0505"},
        {v: 0.75, c: "#f80606"}, {v: 2, c: "#ef0202"}, {v: 5, c: "#f60788"}];

    let selectedColors;

    if (color == "blue") {
        selectedColors = colors_b;
    } else if (color == "red") {
        selectedColors = colors_r;
    } else {
        selectedColors = colors_g;
    }

    let totalHands = 0;

    let lengthWithoutZero = 0;

    data.forEach(e => {
        if (e.value > 0) lengthWithoutZero += 1;
        totalHands += +e.value;
    })

    let avgHands = totalHands / lengthWithoutZero;

    return (
        <div className="lf-matrix" style={{borderRadius: "20px"}}>
            {data.map((item, index) => {
                    let cof = item.value ? +item.value / avgHands.toFixed(3) : -1;

                    let backgroundColor = "#303030";

                    selectedColors.forEach(color => {
                        if (cof >= color.v) backgroundColor = color.c;
                    })

                    return <div key={`${randomInteger(1, 9999999999)}`} className="lf-matrix-item"
                                style={{backgroundColor}}>
                        <div className="lf-matrix-name">{item.name}</div>
                        <div className="lf-matrix-value">{item.value}</div>
                    </div>
                }
            )}
        </div>
    );
}

let coloredCategories = []

export default function Leakfinder(props) {
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [saveName, setSaveName] = useState(null);

    const [selectedRoomNames, setSelectedRoomNames] = useState([]);
    const [selectedRanks, setSelectedRanks] = useState([]);
    const [selectedFlop, setSelectedFlop] = useState(1);
    const [isLineChartFullscreen, setIsLineChartFullscreen] = useState(false);
    const [randColor, setRandColor] = useState(1);
    const handleSetSelectedFlop = (num) => {
        setSelectedFlop(num)
    }

    const handleGetSaves = () => {
        props.LeakfinderGetSaves(props?.AuthInfo?.verificator?.room_names && props.AuthInfo.verificator.room_names[0] ? props.AuthInfo.verificator.room_names.map(e => ({name: e})) : [])
    }

    let options = {
        scales: {
            r: {
                angleLines: {
                    display: false
                },
                suggestedMin: 1,
                suggestedMax: 10,
                grid: {
                    color: 'rgba(234,234,234,0.18)',
                },
                pointLabels: {
                    color: 'rgb(0,178,255)',
                    font: {
                        size: 10
                    }
                },
                ticks: {
                    backdropColor: 'rgba(234,234,234,0)',
                    color: "orange"
                }
            }
        },
        plugins: {
            legend: {
                display: true,
            }
        }
    };
    const line_chart_options_not_full_screen = {
        elements: {
            point: {
                radius: isLineChartFullscreen ? 2 : 1
            }
        },
        responsive: true,
        plugins: {
            title: {
                display: true,
            },
            legend: {
                display: false,
            }
        },
        scales: {
            xAxis: {
                type: 'linear'
            }
        }
    };
    const line_chart_options_full_screen = {
        elements: {
            point: {
                radius: isLineChartFullscreen ? 2 : 1
            }
        },
        responsive: true,
        plugins: {
            title: {
                display: true,
            },
            legend: {
                display: true,
            }
        },
        scales: {
            xAxis: {
                type: 'linear'
            }
        }
    };

    const createLineChartData = (label, arrayOfObjects, keyInObject, isHidden = false) => {
        let color = "#3171ee";

        if (keyInObject == "amt_won") color = "#3ad036";
        if (keyInObject == "amt_expected_won") color = "#f1a045";
        if (keyInObject == "blue_line") color = "#3171ee";
        if (keyInObject == "red_line") color = "#ff4646";

        let obj = {
            label,
            borderColor: color,
            backgroundColor: color,
            borderWidth: 1,
            data: {},
            // hidden: isHidden,
        };

        arrayOfObjects.forEach((e, i) => {
            obj.data[`${i * e.step}`] = e[keyInObject];
        })

        return obj;
    }
    // const line_chart_chip_won = createLineChartData("BB Won", props.LeakfinderInfo.line_chart_data, "amt_won");
    // const line_chart_ev_chip_won = createLineChartData("EV BB Won", props.LeakfinderInfo.line_chart_data, "amt_expected_won");
    // const line_chart_blue_line = createLineChartData("BB Won with showdown", props.LeakfinderInfo.line_chart_data, "blue_line");
    // const line_chart_red_line = createLineChartData("BB Won without showdown", props.LeakfinderInfo.line_chart_data, "red_line");
    let line_chart_data_from_saves = [];
    if (props.LeakfinderInfo.currentSaves[0]) {
        props.LeakfinderInfo.currentSaves.forEach((save, i) => {
            if (save.line_chart?.data) {
                const chip_won = createLineChartData(`BB Won Save ${i + 1}`, save.line_chart.data, "amt_won", true);
                const ev_chip_won = createLineChartData(`EV BB Won Save ${i + 1}`, save.line_chart.data, "amt_expected_won", true);
                const blue_line = createLineChartData(`BB Won with showdown Save ${i + 1}`, save.line_chart.data, "blue_line", true);
                const red_line = createLineChartData(`BB Won without showdown Save ${i + 1}`, save.line_chart.data, "red_line", true);
                line_chart_data_from_saves.push({...chip_won})
                line_chart_data_from_saves.push({...ev_chip_won})
                line_chart_data_from_saves.push({...blue_line})
                line_chart_data_from_saves.push({...red_line})
            }
        })
    }
    let line_chart_data_datasets = [
        // line_chart_chip_won, line_chart_ev_chip_won, line_chart_blue_line, line_chart_red_line,
        ...line_chart_data_from_saves
    ];
    const line_chart_data = {
        datasets: line_chart_data_datasets,
    };
    const handleSelectRank = (e) => {
        setSelectedRanks(JSON.parse(JSON.stringify(e)));
    }

    let schema = {};

    if (selectedRanks && selectedRanks[0]) {
        schema = {...schema, ...selectedRanks[0].schema_pre_flop, ...selectedRanks[0].schema_post_flop};
    } else if (props.LeakfinderInfo.player_info) {
        schema = {...schema, ...props.LeakfinderInfo.player_info, ...props.LeakfinderInfo.sub_player_info};
    } else if (props.LeakfinderInfo.currentSaves && props.LeakfinderInfo.currentSaves[0]) {
        schema = {...schema, ...props.LeakfinderInfo.currentSaves[0].data.player_info, ...props.LeakfinderInfo.currentSaves[0].data.sub_player_info}
    } else {
        schema = {...schema, ...defaultSchema, ...defaultSchemaPostFlop}
    }

    let export_data = {...defaultSchema, ...defaultSchemaPostFlop};

    Object.keys(export_data).map((keyName) => {
        export_data[keyName].forEach(element => {
            element.standards = "-";
            element.values = [];
            element.headers = [];
        })
    });

    let export_data_headers = ["Standarts"];
    let export_room_names = [...props.LeakfinderInfo.analyze_info.roomNames];

    if (props.LeakfinderInfo.player_info) {
        export_data_headers.push("Analyze");
        Object.keys(export_data).map((keyName) => {
            export_data[keyName].forEach(e => {
                let toCheck = {...props.LeakfinderInfo.player_info, ...props.LeakfinderInfo.sub_player_info};
                if (toCheck[keyName]) {
                    const indexOfFunc = toCheck[keyName].findIndex(k => k.func == e.func);
                    if (indexOfFunc == -1) {
                        e.values.push("-")
                    } else {
                        e.values.push(`${toCheck[keyName][indexOfFunc].value}`.includes(".") ? `${toCheck[keyName][indexOfFunc].value}`.replace('.', ',') : `${toCheck[keyName][indexOfFunc].value}`);
                        e.standards = toCheck[keyName][indexOfFunc].standards;
                    }
                } else {
                    e.values.push("-")
                }
            })
        })
    }
    if (props.LeakfinderInfo.currentSaves && props.LeakfinderInfo.currentSaves[0]) {
        props.LeakfinderInfo.currentSaves.forEach((save, saveIndex) => {
            export_data_headers.push(save.topic);
            export_room_names = [...export_room_names, ...save.room_names];

            Object.keys(export_data).map((keyName) => {
                export_data[keyName].forEach(e => {
                    let toCheck = {...save.data.player_info, ...save.data.sub_player_info};
                    if (toCheck[keyName]) {
                        const indexOfFunc = toCheck[keyName].findIndex(k => k.func == e.func);
                        if (indexOfFunc == -1) {
                            e.values.push("-")
                        } else {
                            e.values.push(`${toCheck[keyName][indexOfFunc].value}`.includes(".") ? `${toCheck[keyName][indexOfFunc].value}`.replace('.', ',') : `${toCheck[keyName][indexOfFunc].value}`);
                            if (saveIndex == 0 && !export_data_headers.includes("Analyze")) e.standards = toCheck[keyName][indexOfFunc].standards;
                        }
                    } else {
                        e.values.push("-")
                    }
                })
            })
        })
    }

    let s = [];
    let u = [];
    let v = [];
    let b = [];
    let page_percents_total = [];

    Object.keys(selectedFlop == 1 ? defaultSchema : defaultSchemaPostFlop).map((keyName, i) => { // идём по категориям схемы
        // i = индекс категории
        if (!schema[keyName]) return;
        let stats = (selectedFlop == 1 ? schema[keyName] : schema[keyName]).map((j, i) => { // идём по объектам категории схемы
            if (!j.is_visible) return undefined;

            // У нас в выбранной схеме is_visible = true
            // В сохранении is_visible = false, либо просто нету данных

            let columns = []; // Это строка одной функции из категории, 1 значение = 1 столбец
            let getPercent = (v, s) => {
                let value = +(+v).toFixed(2);
                let standards = [0, 0];
                let different = 0;

                if (typeof s == "object") {
                    standards[0] = +s[0];
                    standards[1] = +s[1];
                } else if (s.includes("&")) {
                    standards = [...s.split("&")]
                } else {
                    standards[0] = +s;
                    standards[1] = +s;
                }

//standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
                if (+standards[0].length <= 0) standards[0] = 0;
                if (+standards[1].length <= 0) standards[1] = 0;

                // Вычисляем разницу между верхней и нижней границей эталона
                let etalon_gape = +standards[1] - +standards[0];

                // для каждого значения etalon_gape определяем коэффициент для дальнейших расчетов
                let keff = 0;
                if (etalon_gape > 0 && etalon_gape <= 3) {
                    keff = 13;
                } else if (etalon_gape > 3 && etalon_gape <= 5) {
                    keff = 8;
                } else if (etalon_gape > 5 && etalon_gape <= 10) {
                    keff = 5;
                } else if (etalon_gape > 10) {
                    keff = 3;
                }

                // Далее каждый процент расхождения умножаем на этот коэфф и тем самым получаем %попадания
                let diff = 0;
                if (+value < +standards[0]) {
                    diff = +standards[0] - +value;
                } else if (+value > +standards[1]) {
                    diff = +value - +standards[1];
                }

                //  попадание = 100 - расхождение*КОЭФФ;
                //      ЕСЛИ попадание < 0 то попадание = 0;
                let hit = 100 - diff * keff;
                if (hit < 0) hit = 0;

                // console.log(`========================================`)
                // console.log(`etalon_gape = ${etalon_gape}`)
                // console.log(`keff = ${keff}`)
                // console.log(`diff = ${diff.toFixed(1)}`)
                // console.log(`hit = ${hit.toFixed(1)}`)

                return +hit
            }

            if (props.LeakfinderInfo.player_info) {
                if (selectedFlop == 1) {
                    if (props.LeakfinderInfo.player_info[keyName] && props.LeakfinderInfo.player_info[keyName][0]) {
                        let data = props.LeakfinderInfo.player_info[keyName].find(e => e.name == j.name);
                        if (data && data.is_visible != j.is_visible) {
                            columns.push(undefined);
                        } else {
                            if (data) {
                                columns.push(getPercent(data.value, schema[keyName][i].standards))
                            } else {
                                columns.push(undefined);
                            }
                        }
                    } else {
                        columns.push(undefined)
                    }
                } else {
                    if (props.LeakfinderInfo.sub_player_info[keyName] && props.LeakfinderInfo.sub_player_info[keyName][0]) {
                        let data = props.LeakfinderInfo.sub_player_info[keyName].find(e => e.name == j.name);
                        if (data && data.is_visible != j.is_visible) {
                            columns.push(undefined);
                        } else {
                            if (data) {
                                columns.push(getPercent(data.value, schema[keyName][i].standards))
                            } else {
                                columns.push(undefined);
                            }
                        }
                    } else {
                        columns.push(undefined)
                    }
                }
            }
            if (props.LeakfinderInfo.currentSaves[0]) {
                if (selectedFlop == 1) {
                    props.LeakfinderInfo.currentSaves.forEach(e => {
                        if (e.data.player_info[keyName] && e.data.player_info[keyName][0]) {
                            let data = e.data.player_info[keyName].find(e => e.name == j.name);
                            if (data && data.is_visible != j.is_visible) {
                                columns.push(undefined);
                            } else {
                                if (data) {
                                    columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(undefined);
                                }
                            }
                        } else {
                            columns.push(undefined)
                        }
                    })
                } else {
                    props.LeakfinderInfo.currentSaves.forEach(e => {
                        if (e.data.sub_player_info[keyName] && e.data.sub_player_info[keyName][0]) {
                            let data = e.data.sub_player_info[keyName].find(e => e.name == j.name);
                            if (data && data.is_visible != j.is_visible) {
                                columns.push(undefined);
                            } else {
                                if (data) {
                                    columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(undefined);
                                }
                            }
                        } else {
                            columns.push(undefined)
                        }
                    })
                }
            }
            return columns
        })


        stats = stats.map(columns => {
            if (columns && columns.includes(undefined)) {
                return undefined;
            } else {
                return columns
            }
        })
        stats = stats.filter(e => e != undefined) // stats - это набор массивов (строк функций категорий, где 1 значение = 1 столбец)
        // здесь мы понимаем, что категории нету и её нужно вырезать

        s.push(stats);


        let ustats = [];

        let page_percents_category_total = [];

        if (stats && stats[0]) {
            for (let i = 0; i < stats[0].length; i++) {
                let endValue = 0;
                let countOfValues = 0;
                stats.forEach(e => {
                    countOfValues++;
                    endValue += e[i];
                })
                ustats.push(((endValue / countOfValues)));
                page_percents_category_total.push(endValue);
            }
        }

        u = [...u, ...ustats];
        page_percents_total = [...page_percents_total, ...page_percents_category_total];
    })

    let lngt = 0;

    s.forEach(r => {
        lngt += r.length;
    })

    u.forEach((r, i) => {
        let vl = 0;
        s.forEach(e => {
            e.forEach(p => {
                if (+p[i] == 100) {
                    vl += 1;
                }
            })
        })

        let vvv = ((vl / lngt) * 100);
        v.push(vvv <= 0 ? 0 : vvv) // TODO: получение процента по всем категориям (попадание/не попадание)
    })

    let categories_count = s.filter(e => e[0]);
    let columns_count = page_percents_total.length / categories_count.length;

    const saves_categories = [];
    for (let i = 0; i < columns_count; i++) {
        saves_categories.push(page_percents_total.filter((value, index) => index % columns_count === i));
    }

    saves_categories.forEach(sc => {
        b.push((sc.reduce((a, b) => +a + +b) / lngt))
    });


    let s_2 = [];
    let u_2 = [];
    let v_2 = [];
    let b_2 = [];
    let page_percents_total_2 = [];

    Object.keys(selectedFlop == 1 ? defaultSchemaPostFlop : defaultSchema).map((keyName, i) => { // идём по категориям схемы
        // i = индекс категории
        if (!schema[keyName]) return;
        let stats = (selectedFlop == 1 ? schema[keyName] : schema[keyName]).map((j, i) => { // идём по объектам категории схемы
            if (!j.is_visible) return undefined;

            // У нас в выбранной схеме is_visible = true
            // В сохранении is_visible = false, либо просто нету данных

            let columns = []; // Это строка одной функции из категории, 1 значение = 1 столбец
            let getPercent = (v, s) => {
                let value = +(+v).toFixed(2);
                let standards = [0, 0];
                let different = 0;

                if (typeof s == "object") {
                    standards[0] = +s[0];
                    standards[1] = +s[1];
                } else if (s.includes("&")) {
                    standards = [...s.split("&")]
                } else {
                    standards[0] = +s;
                    standards[1] = +s;
                }

//standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
                if (+standards[0].length <= 0) standards[0] = 0;
                if (+standards[1].length <= 0) standards[1] = 0;

                // Вычисляем разницу между верхней и нижней границей эталона
                let etalon_gape = +standards[1] - +standards[0];

                // для каждого значения etalon_gape определяем коэффициент для дальнейших расчетов
                let keff = 0;
                if (etalon_gape > 0 && etalon_gape <= 3) {
                    keff = 13;
                } else if (etalon_gape > 3 && etalon_gape <= 5) {
                    keff = 8;
                } else if (etalon_gape > 5 && etalon_gape <= 10) {
                    keff = 5;
                } else if (etalon_gape > 10) {
                    keff = 3;
                }

                // Далее каждый процент расхождения умножаем на этот коэфф и тем самым получаем %попадания
                let diff = 0;
                if (+value < +standards[0]) {
                    diff = +standards[0] - +value;
                } else if (+value > +standards[1]) {
                    diff = +value - +standards[1];
                }

                //  попадание = 100 - расхождение*КОЭФФ;
                //      ЕСЛИ попадание < 0 то попадание = 0;
                let hit = 100 - diff * keff;
                if (hit < 0) hit = 0;

                // console.log(`========================================`)
                // console.log(`etalon_gape = ${etalon_gape}`)
                // console.log(`keff = ${keff}`)
                // console.log(`diff = ${diff.toFixed(1)}`)
                // console.log(`hit = ${hit.toFixed(1)}`)

                return +hit
            }

            if (props.LeakfinderInfo.player_info) {
                if (selectedFlop == 1) {
                    if (props.LeakfinderInfo.sub_player_info[keyName] && props.LeakfinderInfo.sub_player_info[keyName][0]) {
                        let data = props.LeakfinderInfo.sub_player_info[keyName].find(e => e.name == j.name);
                        if (data && data.is_visible != j.is_visible) {
                            columns.push(undefined);
                        } else {
                            if (data) {
                                columns.push(getPercent(data.value, schema[keyName][i].standards))
                            } else {
                                columns.push(undefined);
                            }
                        }
                    } else {
                        columns.push(undefined)
                    }
                } else {
                    if (props.LeakfinderInfo.player_info[keyName] && props.LeakfinderInfo.player_info[keyName][0]) {
                        let data = props.LeakfinderInfo.player_info[keyName].find(e => e.name == j.name);
                        if (data && data.is_visible != j.is_visible) {
                            columns.push(undefined);
                        } else {
                            if (data) {
                                columns.push(getPercent(data.value, schema[keyName][i].standards))
                            } else {
                                columns.push(undefined);
                            }
                        }
                    } else {
                        columns.push(undefined)
                    }
                }
            }


            if (props.LeakfinderInfo.currentSaves[0]) {
                if (selectedFlop == 1) {
                    props.LeakfinderInfo.currentSaves.forEach(e => {
                        if (e.data.sub_player_info[keyName] && e.data.sub_player_info[keyName][0]) {
                            let data = e.data.sub_player_info[keyName].find(e => e.name == j.name);
                            if (data && data.is_visible != j.is_visible) {
                                columns.push(undefined);
                            } else {
                                if (data) {
                                    columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(undefined);
                                }
                            }
                        } else {
                            columns.push(undefined)
                        }
                    })
                } else {
                    props.LeakfinderInfo.currentSaves.forEach(e => {
                        if (e.data.player_info[keyName] && e.data.player_info[keyName][0]) {
                            let data = e.data.player_info[keyName].find(e => e.name == j.name);
                            if (data && data.is_visible != j.is_visible) {
                                columns.push(undefined);
                            } else {
                                if (data) {
                                    columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(undefined);
                                }
                            }
                        } else {
                            columns.push(undefined)
                        }
                    })
                }
            }
            return columns
        })


        stats = stats.map(columns => {
            if (columns && columns.includes(undefined)) {
                return undefined;
            } else {
                return columns
            }
        })
        stats = stats.filter(e => e != undefined) // stats - это набор массивов (строк функций категорий, где 1 значение = 1 столбец)
        // здесь мы понимаем, что категории нету и её нужно вырезать

        s_2.push(stats);


        let ustats = [];

        let page_percents_category_total = [];

        if (stats && stats[0]) {
            for (let i = 0; i < stats[0].length; i++) {
                let endValue = 0;
                let countOfValues = 0;
                stats.forEach(e => {
                    countOfValues++;
                    endValue += e[i];
                })
                ustats.push(((endValue / countOfValues)));
                page_percents_category_total.push(endValue);
            }
        }

        u_2 = [...u_2, ...ustats];
        page_percents_total_2 = [...page_percents_total_2, ...page_percents_category_total];
    })

    let lngt_2 = 0;

    s_2.forEach(r => {
        lngt_2 += r.length;
    })

    u_2.forEach((r, i) => {
        let vl = 0;
        s_2.forEach(e => {
            e.forEach(p => {
                if (+p[i] == 100) {
                    vl += 1;
                }
            })
        })

        let vvv = ((vl / lngt) * 100);
        v_2.push(vvv <= 0 ? 0 : vvv) // TODO: получение процента по всем категориям (попадание/не попадание)
    })

    let categories_count_2 = s_2.filter(e => e[0]);
    let columns_count_2 = page_percents_total_2.length / categories_count_2.length;

    const saves_categories_2 = [];
    for (let i = 0; i < columns_count_2; i++) {
        saves_categories_2.push(page_percents_total_2.filter((value, index) => index % columns_count_2 === i));
    }

    saves_categories_2.forEach(sc => {
        b_2.push((sc.reduce((a, b) => +a + +b) / lngt_2))
    });


    const handleCloseLineChartFullscreen = (close) => {
        setIsLineChartFullscreen(false);
        close()
    }

    return (
        props.AuthInfo.isPlayerLeakfinderAccess ? <div style={{color: "white"}}>
            <div style={{display: "grid", gridTemplateColumns: "84px 300px"}}>
                <div></div>
                <span className={"default-button transition cursor-pointer"} style={{
                    cursor: "pointer",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "100px",
                    backgroundColor: "#2c2c44"
                }} onClick={() => handleGetSaves()}>
                            Обновить список сохранений
                        </span>
            </div>


            {
                // props.isLoading ? <div style={{
                //         color: "white",
                //         padding: "50px 50px 10px 50px",
                //         textAlign: "left",
                //         display: "grid",
                //         gridTemplateColumns: "60px 1fr"
                //     }}>
                //         <img src={OvalLoading} alt="loading" style={{width: "90px"}}/>
                //         <div style={{padding: "5px 0 0 20px"}}>
                //
                //             Идёт подсчёт данных
                //             <br/>
                //             Примерное время ожидания ~1-2 мин
                //         </div>
                //     </div> :
                <div>
                    <div style={{
                        textAlign: "center",
                        padding: "50px 50px 10px 50px"
                    }}>
                        <div>
                            <div>
                                <div id="get-advice" style={{
                                    padding: "10px 35px",
                                    textAlign: "left",
                                    color: "white",
                                    margin: "-15px 20px 0 0"
                                }}>
                                    <form className="form">
                                        <div className="formgroup">
                                            {props.LeakfinderInfo.savesList[0] ?
                                                <div style={{
                                                    fontSize: "14px",
                                                    lineHeight: "1.1em"
                                                }}>
                                                    <div style={{
                                                        display: "grid",
                                                        gridTemplateColumns: "30% 20% 20% 20% 10%",
                                                        color: "#0073d7",
                                                        fontWeight: "bolder", padding: "2px 8px", margin: "3px 0"
                                                    }}>
                                                        <span>Название</span>
                                                        <span>Дата сохранения</span>
                                                        <span>От</span>
                                                        <span>До</span>
                                                        <span>Комментарий</span>
                                                    </div>
                                                    {props.LeakfinderInfo.savesList.map((save, i) => {
                                                        return <div key={save._id}>
                                                            <div style={{
                                                                display: "grid",
                                                                gridTemplateColumns: "30% 20% 20% 20% 10%",
                                                                backgroundColor: i % 2 == 0 ? "#2c2c45" : "initial",
                                                                padding: "2px 8px",
                                                                margin: "3px 0",
                                                                borderRadius: "100px"
                                                            }}>
                                                                <span style={{
                                                                    cursor: "pointer",
                                                                    textDecoration: "underline",
                                                                    textDecorationColor: "#0073d7"
                                                                }}
                                                                      onClick={() => props.LeakfinderSetCurrentSave(save)}>{save.topic}</span>
                                                                <span>{moment(+save.timestamp_creation).format("DD.MM.YYYY HH:mm")}</span>
                                                                <span>{moment(+save.date_from).format("DD.MM.YYYY HH:mm")}</span>
                                                                <span>{moment(+save.date_to).format("DD.MM.YYYY HH:mm")}</span>
                                                                <Popup key={"analyzeOne"}
                                                                       trigger={
                                                                           <span style={{cursor: "pointer"}}>{save.comment ? <span style={{color: "#50c037"}}>Посмотреть</span> : "Нет"}</span>
                                                                       }
                                                                >
                                                                    {() => (
                                                                        <div>
                                                                            <div
                                                                                 style={{color: "white", textAlign: "left", width: "600px", padding: "20px 60px", backgroundColor: "#414164", borderRadius: "10px"}}>
                                                                                <form className="form">
                                                                                    <div style={{fontWeight: "bolder"}}>
                                                                                        Комментарий для сохранения {save.topic}
                                                                                    </div>
                                                                                    <div>
                                                                                        { save.comment ? save.comment : "Отсутствует" }
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Popup>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                                : <div>Сохранения связанные с вами не найдены. Если вы зашли на страницу
                                                    по прямой ссылке, то попробуйте нажать на "Обновить список
                                                    сохранений"!</div>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            }

            {
                props.LeakfinderInfo.currentSaves && props.LeakfinderInfo.currentSaves[0] && <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 250px 250px 250px 1fr",
                    padding: "3px 0",
                    borderRadius: "10px",
                    position: "sticky",
                    top: "0",
                    zIndex: "100",
                    marginTop: "3px"
                }}>
                    <div></div>
                    <div style={{padding: "0 10px 0 0"}}>
                        <RankSelector options={props.LeakfinderInfo.schemas}
                                      handleSelectRank={handleSelectRank}/>
                    </div>
                    <div
                        className={`default-button transition cursor-pointer ${selectedFlop == 1 ? "green-back" : "default-back"}`}
                        onClick={() => handleSetSelectedFlop(1)}>
                        PRE-FLOP
                    </div>
                    <div
                        className={`default-button transition cursor-pointer ${selectedFlop == 2 ? "green-back" : "default-back"}`}
                        onClick={() => handleSetSelectedFlop(2)}>
                        POST-FLOP
                    </div>
                    <div></div>
                </div>
            }

            {
                props.LeakfinderInfo.player_info || props.LeakfinderInfo.currentSaves[0] ?
                    <Table selectedRanks={selectedRanks} selectedFlop={selectedFlop} {...props} vv={v} bb={b} vv_2={v_2}
                           bb_2={b_2} lngt_1={lngt} lngt_2={lngt_2}/>
                    : null
            }

        </div> : <div></div>
    )
}

const Table = (props) => {

    let schema = {};
    let pre_flop_total_percent_etalon = [0, 100];
    let post_flop_total_percent_etalon = [0, 100];

    if (props.selectedRanks && props.selectedRanks[0]) {
        schema = {...schema, ...props.selectedRanks[0].schema_pre_flop, ...props.selectedRanks[0].schema_post_flop};
        if (props.selectedRanks[0].pre_flop_total_percent_etalon) pre_flop_total_percent_etalon = props.selectedRanks[0].pre_flop_total_percent_etalon;
        if (props.selectedRanks[0].post_flop_total_percent_etalon) post_flop_total_percent_etalon = props.selectedRanks[0].post_flop_total_percent_etalon;
    } else if (props.LeakfinderInfo.player_info) {
        schema = {...schema, ...props.LeakfinderInfo.player_info, ...props.LeakfinderInfo.sub_player_info}
        if (props.LeakfinderInfo.pre_flop_total_percent_etalon) pre_flop_total_percent_etalon = props.LeakfinderInfo.pre_flop_total_percent_etalon;
        if (props.LeakfinderInfo.post_flop_total_percent_etalon) post_flop_total_percent_etalon = props.LeakfinderInfo.post_flop_total_percent_etalon;
    } else if (props.LeakfinderInfo.currentSaves && props.LeakfinderInfo.currentSaves[0]) {
        schema = {...schema, ...props.LeakfinderInfo.currentSaves[0].data.player_info, ...props.LeakfinderInfo.currentSaves[0].data.sub_player_info}
        if (props.LeakfinderInfo.currentSaves[0].data.pre_flop_total_percent_etalon) pre_flop_total_percent_etalon = props.LeakfinderInfo.currentSaves[0].data.pre_flop_total_percent_etalon;
        if (props.LeakfinderInfo.currentSaves[0].data.post_flop_total_percent_etalon) post_flop_total_percent_etalon = props.LeakfinderInfo.currentSaves[0].data.post_flop_total_percent_etalon;
    } else {
        schema = {...schema, ...defaultSchema, ...defaultSchemaPostFlop}
    }

    const getColoredPercentEtalon = (keyName, value, standards, hard_percent, value_2, lngt_1, lngt_2) => {
        let different;
        let color = '#ff3305';

        if (standards[0].length <= 0) standards[0] = 0;
        if (standards[1].length <= 0) standards[1] = 0;

        if (+value > standards[0]) {
            different = +value - standards[1]
        } else {
            different = +value - standards[0]
        }

        let etalon = standards.reduce(function (a, c) {
            return Math.abs(a - +value) < Math.abs(c - +value) ? a : c;
        })
        let mod_dif = Math.abs(different / etalon) * 100;

        if (+value > standards[0] && +value < standards[1]) {
            color = '#0aff23';
        } else if (mod_dif <= 5) {
            color = '#0aff23';
        } else if (mod_dif <= 10) {
            color = '#4cb526';
        } else if (mod_dif <= 15) {
            color = '#def016';
        } else if (mod_dif <= 20) {
            color = '#ff9b05';
        } else {
            color = '#ff3305';
        }

// ([Тотал Префлоп] * [Кол-во стат префлопа] + [Тотал Постфлоп] * [Кол-во стат постфлопа]) / ([Кол-во стат префлопа] + [кол-во стат постфлопа])

        let total_flops = (value * lngt_1 + value_2 * lngt_2) / (lngt_1 + lngt_2);

        // return keyName == "General" ?
        //     <div style={{marginTop: "-42px", marginBottom: "24px", color, fontSize: "12px"}}>{value.toFixed(1)}% <span
        //         style={{color: "white", fontSize: "10px"}}>{hard_percent.toFixed(1)}% <span
        //         style={{color: "#5fb5ff"}}>{total_flops.toFixed(1)}%</span></span></div>
        //     : <div style={{marginTop: "-20px", marginBottom: "2px", color, fontSize: "12px"}}>{value.toFixed(1)}% <span
        //         style={{color: "white", fontSize: "10px"}}>{hard_percent.toFixed(1)}% <span
        //         style={{color: "#5fb5ff"}}>{total_flops.toFixed(1)}%</span></span></div>
        return keyName == "Postflop Total Attack IP" ?
            <div style={{marginTop: "-20px", marginBottom: "2px", color, fontSize: "12px"}}>{value.toFixed(1)}% <span
                style={{color: "white", fontSize: "10px"}}>{hard_percent.toFixed(1)}% <span
                style={{color: "#5fb5ff"}}>{total_flops.toFixed(1)}%</span></span></div>
            : <div style={{marginTop: "-20px", marginBottom: "2px", color, fontSize: "12px"}}>{value.toFixed(1)}% <span
                style={{color: "white", fontSize: "10px"}}>{hard_percent.toFixed(1)}% <span
                style={{color: "#5fb5ff"}}>{total_flops.toFixed(1)}%</span></span></div>
    }

    return <div>
        <Header currentSaves={props.LeakfinderInfo.currentSaves} selectedFlop={props.selectedFlop}
                LeakfinderSpliceSave={props.LeakfinderSpliceSave}
                player_info={props.LeakfinderInfo.player_info} sub_player_info={props.LeakfinderInfo.sub_player_info}/>
        {
            (function () {
                return Object.keys(props.selectedFlop == 1 ? defaultSchema : defaultSchemaPostFlop).map((keyName, i) => { // идём по категориям схемы
                    // i = индекс категории
                    if (!schema[keyName]) return;
                    let stats = (props.selectedFlop == 1 ? schema[keyName] : schema[keyName]).map((j, i) => { // идём по объектам категории схемы
                        if (!j.is_visible) return undefined;

                        let columns = [];

                        let getPercent = (v, s) => {
                            let value = +(+v).toFixed(2);
                            let standards = [0, 0];
                            let different = 0;

                            if (typeof s == "object") {
                                standards[0] = +s[0];
                                standards[1] = +s[1];
                            } else if (s.includes("&")) {
                                standards = [...s.split("&")]
                            } else {
                                standards[0] = +s;
                                standards[1] = +s;
                            }

                            //standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
                            if (+standards[0].length <= 0) standards[0] = 0;
                            if (+standards[1].length <= 0) standards[1] = 0;

                            // Вычисляем разницу между верхней и нижней границей эталона
                            let etalon_gape = +standards[1] - +standards[0];

                            // для каждого значения etalon_gape определяем коэффициент для дальнейших расчетов
                            let keff = 0;
                            if (etalon_gape > 0 && etalon_gape <= 3) {
                                keff = 13;
                            } else if (etalon_gape > 3 && etalon_gape <= 5) {
                                keff = 8;
                            } else if (etalon_gape > 5 && etalon_gape <= 10) {
                                keff = 5;
                            } else if (etalon_gape > 10) {
                                keff = 3;
                            }

                            // Далее каждый процент расхождения умножаем на этот коэфф и тем самым получаем %попадания
                            let diff = 0;
                            if (+value < +standards[0]) {
                                diff = +standards[0] - value;
                            } else if (+value > +standards[1]) {
                                diff = +value - +standards[1];
                            }

                            //  попадание = 100 - расхождение*КОЭФФ;
                            //      ЕСЛИ попадание < 0 то попадание = 0;
                            let hit = 100 - diff * keff;
                            if (hit < 0) hit = 0;

                            // console.log(`========================================`)
                            // console.log(`etalon_gape = ${etalon_gape}`)
                            // console.log(`keff = ${keff}`)
                            // console.log(`diff = ${diff.toFixed(1)}`)
                            // console.log(`hit = ${hit.toFixed(1)}`)


                            return +hit
                        }

                        if (props.LeakfinderInfo.player_info) {
                            if (props.selectedFlop == 1) {
                                if (props.LeakfinderInfo.player_info[keyName] && props.LeakfinderInfo.player_info[keyName][0]) {
                                    let data = props.LeakfinderInfo.player_info[keyName].find(e => e.name == j.name);
                                    if (data) columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(getPercent(0, "0"))
                                }

                            } else {
                                if (props.LeakfinderInfo.sub_player_info[keyName] && props.LeakfinderInfo.sub_player_info[keyName][0]) {
                                    let data = props.LeakfinderInfo.sub_player_info[keyName].find(e => e.name == j.name);
                                    if (data) columns.push(getPercent(data.value, schema[keyName][i].standards))
                                } else {
                                    columns.push(getPercent(0, "0"))
                                }


                            }
                        }
                        if (props.LeakfinderInfo.currentSaves[0]) {
                            if (props.selectedFlop == 1) {
                                props.LeakfinderInfo.currentSaves.forEach(e => {
                                    if (e.data.player_info[keyName] && e.data.player_info[keyName][0]) {
                                        let data = e.data.player_info[keyName].find(e => e.name == j.name);
                                        if (data) columns.push(getPercent(data.value, schema[keyName][i].standards));
                                    } else {
                                        columns.push(getPercent(0, "0"))
                                    }

                                })
                            } else {
                                props.LeakfinderInfo.currentSaves.forEach(e => {
                                    if (e.data.sub_player_info[keyName] && e.data.sub_player_info[keyName][0]) {
                                        let data = e.data.sub_player_info[keyName].find(e => e.name == j.name);
                                        if (data) columns.push(getPercent(data.value, schema[keyName][i].standards))
                                    } else {
                                        columns.push(getPercent(0, "0"))
                                    }

                                })
                            }
                        }
                        return columns
                    })

                    stats = stats.filter(e => e != undefined)

                    let ustats = [];

                    if (stats && stats[0]) {
                        for (let i = 0; i < stats[0].length; i++) {
                            let endValue = 0;
                            let countOfValues = 0;
                            stats.forEach(e => {
                                countOfValues++;
                                endValue += e[i];
                            })
                            ustats.push(((endValue / countOfValues)).toFixed(1))
                        }
                    }

                    let isNeedHideCategory = false;

                    if (ustats.length == 0 && !coloredCategories.includes(keyName) &&
                        keyName != "TOTAL PREFLOP" && keyName != "TOTAL POSTFLOP") {
                        isNeedHideCategory = true;
                    }

                    return isNeedHideCategory ? null : <div key={`${keyName} ${i} ${randomInteger(1, 9999999999)}`}>
                        <div style={{
                            textAlign: "left",
                            color: "#0073d7",
                            fontWeight: "bolder",
                            borderTop: "1px #0073d7 solid",
                            display: "grid",
                            gridTemplateColumns: `${props.selectedFlop == 1 ? "300px" : "400px"} 9% ${ustats[0] ? "9%".repeat(ustats.length) : ""}`
                        }}>
                            <div>
                                <span
                                    style={{color: coloredCategories.includes(keyName) ? "#eed245" : keyName == "TOTAL PREFLOP" || keyName == "TOTAL POSTFLOP" ? "#e86028" : "#0073d7"}}>{keyName}</span>
                            </div>
                            <div style={{color: "white", fontWeight: "initial"}}>
                                {keyName == "TOTAL PREFLOP" ? `${pre_flop_total_percent_etalon[0]}-${pre_flop_total_percent_etalon[1]}` : ""}
                                {keyName == "TOTAL POSTFLOP" ? `${post_flop_total_percent_etalon[0]}-${post_flop_total_percent_etalon[1]}` : ""}
                            </div>

                            {
                                ustats.map((r, j) => {
                                    return <div
                                        style={{
                                            color: "white",
                                            fontWeight: "normal"
                                        }}>
                                        {keyName == "HU Preflop" ?
                                            getColoredPercentEtalon(keyName, props.bb[j], pre_flop_total_percent_etalon, props.vv[j], props.bb_2[j], props.lngt_1, props.lngt_2)
                                            : ""}
                                        {keyName == "Postflop Total Attack IP" ?
                                            getColoredPercentEtalon(keyName, props.bb[j], post_flop_total_percent_etalon, props.vv[j], props.bb_2[j], props.lngt_1, props.lngt_2)
                                            : ""}
                                        {r <= 0 ? 0 : r}%</div>
                                })
                            }
                        </div>
                        {(props.selectedFlop == 1 ? defaultSchema[keyName] : defaultSchemaPostFlop[keyName]).map((e, j) => {

                            let standards = "-";
                            let index = schema[keyName].findIndex(iii => iii.name == e.name);
                            if (index != -1) standards = schema[keyName][index].standards;


                            return <Row name={e.name} color={e.color} value={e.value} standards={standards}
                                        different={e.different} i={j} formula={e.formula}
                                        schema={schema}
                                        keyName={keyName}
                                        currentSaves={props.LeakfinderInfo.currentSaves}
                                        selectedFlop={props.selectedFlop}
                                        player_info={props.LeakfinderInfo.player_info}
                                        sub_player_info={props.LeakfinderInfo.sub_player_info}

                            />
                        })}
                    </div>
                })
            }())
        }
    </div>
}

const Header = (props) => {
    // <Header currentSaves={props.LeakfinderInfo.currentSaves} selectedFlop={selectedFlop}
    //         player_info={props.LeakfinderInfo.player_info} sub_player_info={props.LeakfinderInfo.sub_player_info}/>

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: `${props.selectedFlop == 1 ? "300px" : "400px"} 9% ${props.player_info ? " 9% " : ""} ${props.currentSaves[0] ? " 9% ".repeat(props.currentSaves.length) : ""}`,
            color: "#0073d7",
            fontWeight: "bolder", marginTop: "40px",
            textAlign: "left"
        }}>
            <span>Название</span>
            {/*<span>Значение</span>*/}
            <span>Эталон</span>
            {
                props.player_info ? <span>Анализ</span> : null
            }
            {
                props.currentSaves[0] ?
                    props.currentSaves.map((e, g) => <span title={e.topic}>Save {g + 1} <span
                        className={"cursor-pointer"} onClick={() => props.LeakfinderSpliceSave(g)}>❌</span></span>)
                    : null
            }
            {/*<span>Разница</span>*/}
            {/*<span>Выборка</span>*/}
        </div>
    )
}
const Row = (props) => {
    let columns = [];

    let isExist = true;
    let standards = props.standards;
    let standardsArr = [0, 0];

    let data = props.schema[props.keyName].find(e => e.name == props.name);
    if (!data || !data.is_visible) isExist = false;
    let evStandards = data.evStandards;

    if (data && typeof data.standards == "object") {
        standards = data.standards[1] ? `${data.standards[0]}-${data.standards[1]}` : `${data.standards[0]}`
        standardsArr = data.standards[1] ? [data.standards[0], data.standards[1]] : [data.standards[0], data.standards[0]]
    } else if (props.standards.includes("&")) {
        let s = props.standards.split("&");
        standards = `${s[0]}-${s[1]}`;
        standardsArr = [s[0], s[1]];
    }

    const getColor = (value, standards) => {
        let step;
        let color = '#ff3305';

        //standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
        if (standards[0].length <= 0) standards[0] = 0;
        if (standards[1].length <= 0) standards[1] = 0;

        //ищем шаг в 5% от мин.значения эталона
        step = standards[0] * 0.05;

        //получаем значение в etalon из standards которое ближе к показателю value
        let etalon = standards.reduce(function (a, c) {
            return Math.abs(a - value) < Math.abs(c - value) ? a : c;
        })
        let mod_dif = Math.abs(value - etalon);

        if (value >= standards[0] && value <= standards[1]) {
            color = '#7FFF00'; //зеленый
        } else if (mod_dif <= step) {
            color = '#FFFF00'; //желтый
        } else {
            color = '#FF4500'; //красный
        }
        return color;
    }

    let hands = [
        {name: "AA", value: 0},
        {name: "AKs", value: 0},
        {name: "AQs", value: 0},
        {name: "AJs", value: 0},
        {name: "ATs", value: 0},
        {name: "A9s", value: 0},
        {name: "A8s", value: 0},
        {name: "A7s", value: 0},
        {name: "A6s", value: 0},
        {name: "A5s", value: 0},
        {name: "A4s", value: 0},
        {name: "A3s", value: 0},
        {name: "A2s", value: 0},
        {name: "AKo", value: 0},
        {name: "KK", value: 0},
        {name: "KQs", value: 0},
        {name: "KJs", value: 0},
        {name: "KTs", value: 0},
        {name: "K9s", value: 0},
        {name: "K8s", value: 0},
        {name: "K7s", value: 0},
        {name: "K6s", value: 0},
        {name: "K5s", value: 0},
        {name: "K4s", value: 0},
        {name: "K3s", value: 0},
        {name: "K2s", value: 0},
        {name: "AQo", value: 0},
        {name: "KQo", value: 0},
        {name: "QQ", value: 0},
        {name: "QJs", value: 0},
        {name: "QTs", value: 0},
        {name: "Q9s", value: 0},
        {name: "Q8s", value: 0},
        {name: "Q7s", value: 0},
        {name: "Q6s", value: 0},
        {name: "Q5s", value: 0},
        {name: "Q4s", value: 0},
        {name: "Q3s", value: 0},
        {name: "Q2s", value: 0},
        {name: "AJo", value: 0},
        {name: "KJo", value: 0},
        {name: "QJo", value: 0},
        {name: "JJ", value: 0},
        {name: "JTs", value: 0},
        {name: "J9s", value: 0},
        {name: "J8s", value: 0},
        {name: "J7s", value: 0},
        {name: "J6s", value: 0},
        {name: "J5s", value: 0},
        {name: "J4s", value: 0},
        {name: "J3s", value: 0},
        {name: "J2s", value: 0},
        {name: "ATo", value: 0},
        {name: "KTo", value: 0},
        {name: "QTo", value: 0},
        {name: "JTo", value: 0},
        {name: "TT", value: 0},
        {name: "T9s", value: 0},
        {name: "T8s", value: 0},
        {name: "T7s", value: 0},
        {name: "T6s", value: 0},
        {name: "T5s", value: 0},
        {name: "T4s", value: 0},
        {name: "T3s", value: 0},
        {name: "T2s", value: 0},
        {name: "A9o", value: 0},
        {name: "K9o", value: 0},
        {name: "Q9o", value: 0},
        {name: "J9o", value: 0},
        {name: "T9o", value: 0},
        {name: "99", value: 0},
        {name: "98s", value: 0},
        {name: "97s", value: 0},
        {name: "96s", value: 0},
        {name: "95s", value: 0},
        {name: "94s", value: 0},
        {name: "93s", value: 0},
        {name: "92s", value: 0},
        {name: "A8o", value: 0},
        {name: "K8o", value: 0},
        {name: "Q8o", value: 0},
        {name: "J8o", value: 0},
        {name: "T8o", value: 0},
        {name: "98o", value: 0},
        {name: "88", value: 0},
        {name: "87s", value: 0},
        {name: "86s", value: 0},
        {name: "85s", value: 0},
        {name: "84s", value: 0},
        {name: "83s", value: 0},
        {name: "82s", value: 0},
        {name: "A7o", value: 0},
        {name: "K7o", value: 0},
        {name: "Q7o", value: 0},
        {name: "J7o", value: 0},
        {name: "T7o", value: 0},
        {name: "97o", value: 0},
        {name: "87o", value: 0},
        {name: "77", value: 0},
        {name: "76s", value: 0},
        {name: "75s", value: 0},
        {name: "74s", value: 0},
        {name: "73s", value: 0},
        {name: "72s", value: 0},
        {name: "A6o", value: 0},
        {name: "K6o", value: 0},
        {name: "Q6o", value: 0},
        {name: "J6o", value: 0},
        {name: "T6o", value: 0},
        {name: "96o", value: 0},
        {name: "86o", value: 0},
        {name: "76o", value: 0},
        {name: "66", value: 0},
        {name: "65s", value: 0},
        {name: "64s", value: 0},
        {name: "63s", value: 0},
        {name: "62s", value: 0},
        {name: "A5o", value: 0},
        {name: "K5o", value: 0},
        {name: "Q5o", value: 0},
        {name: "J5o", value: 0},
        {name: "T5o", value: 0},
        {name: "95o", value: 0},
        {name: "85o", value: 0},
        {name: "75o", value: 0},
        {name: "65o", value: 0},
        {name: "55", value: 0},
        {name: "54s", value: 0},
        {name: "53s", value: 0},
        {name: "52s", value: 0},
        {name: "A4o", value: 0},
        {name: "K4o", value: 0},
        {name: "Q4o", value: 0},
        {name: "J4o", value: 0},
        {name: "T4o", value: 0},
        {name: "94o", value: 0},
        {name: "84o", value: 0},
        {name: "74o", value: 0},
        {name: "64o", value: 0},
        {name: "54o", value: 0},
        {name: "44", value: 0},
        {name: "43s", value: 0},
        {name: "42s", value: 0},
        {name: "A3o", value: 0},
        {name: "K3o", value: 0},
        {name: "Q3o", value: 0},
        {name: "J3o", value: 0},
        {name: "T3o", value: 0},
        {name: "93o", value: 0},
        {name: "83o", value: 0},
        {name: "73o", value: 0},
        {name: "63o", value: 0},
        {name: "53o", value: 0},
        {name: "43o", value: 0},
        {name: "33", value: 0},
        {name: "32s", value: 0},
        {name: "A2o", value: 0},
        {name: "K2o", value: 0},
        {name: "Q2o", value: 0},
        {name: "J2o", value: 0},
        {name: "T2o", value: 0},
        {name: "92o", value: 0},
        {name: "82o", value: 0},
        {name: "72o", value: 0},
        {name: "62o", value: 0},
        {name: "52o", value: 0},
        {name: "42o", value: 0},
        {name: "32o", value: 0},
        {name: "22", value: 0}
    ]

    let getMatrix = (arr) => {
        if (!arr || !arr[0]) return null;

        let matrix = JSON.parse(JSON.stringify(hands));

        arr.forEach(hand => {
            let indexOfHandInMatrix = matrix.findIndex(me => me.name == hand.hole_cards);
            if (indexOfHandInMatrix != -1) {
                matrix[indexOfHandInMatrix].value = hand.count;
            }
        })

        return matrix;
    }

    if (props.player_info) {
        if (props.selectedFlop == 1) {
            let data = props.player_info[props.keyName].find(e => e.name == props.name);
            if (data) {
                columns.push({
                    value: data.value,
                    color: data.color,
                    formula: data.formula,
                    matrix_check: getMatrix(data.matrix_check),
                    matrix_raise: getMatrix(data.matrix_raise),
                    matrix_3bet: getMatrix(data.matrix_3bet),
                    matrix_fold: getMatrix(data.matrix_fold),
                    matrix_open: getMatrix(data.matrix_open),
                    matrix_vpip: getMatrix(data.matrix_vpip),
                    matrix_call: getMatrix(data.matrix_call),
                    matrix_4bet: getMatrix(data.matrix_4bet), ev: data.ev,

                    matrix_limp: getMatrix(data.matrix_limp),
                    matrix_open_raise: getMatrix(data.matrix_open_raise),
                    matrix_isolate: getMatrix(data.matrix_isolate),
                    matrix_squeeze: getMatrix(data.matrix_squeeze),
                })
            } else {
                columns.push({
                    value: "----",
                    color: "#111111",
                    formula: "",
                    matrix_check: null,
                    matrix_raise: null,
                    matrix_3bet: null,
                    matrix_fold: null,
                    matrix_open: null,
                    matrix_vpip: null,
                    matrix_call: null,
                    matrix_4bet: null,
                    ev: null,

                    matrix_limp: null,
                    matrix_open_raise: null,
                    matrix_isolate: null,
                    matrix_squeeze: null,
                })
            }
        } else {
            let data = props.sub_player_info[props.keyName].find(e => e.name == props.name);
            if (data) {
                columns.push({
                    value: data.value,
                    color: data.color,
                    formula: data.formula,
                    matrix_check: getMatrix(data.matrix_check),
                    matrix_raise: getMatrix(data.matrix_raise),
                    matrix_3bet: getMatrix(data.matrix_3bet),
                    matrix_fold: getMatrix(data.matrix_fold),
                    matrix_open: getMatrix(data.matrix_open),
                    matrix_vpip: getMatrix(data.matrix_vpip),
                    matrix_call: getMatrix(data.matrix_call),
                    matrix_4bet: getMatrix(data.matrix_4bet), ev: data.ev,

                    matrix_limp: getMatrix(data.matrix_limp),
                    matrix_open_raise: getMatrix(data.matrix_open_raise),
                    matrix_isolate: getMatrix(data.matrix_isolate),
                    matrix_squeeze: getMatrix(data.matrix_squeeze),
                })
            } else {
                columns.push({
                    value: "----",
                    color: "#111111",
                    formula: "",
                    matrix_check: null,
                    matrix_raise: null,
                    matrix_3bet: null,
                    matrix_fold: null,
                    matrix_open: null,
                    matrix_vpip: null,
                    matrix_call: null,
                    matrix_4bet: null,
                    ev: null,

                    matrix_limp: null,
                    matrix_open_raise: null,
                    matrix_isolate: null,
                    matrix_squeeze: null,
                })
            }
        }
    }
    if (props.currentSaves[0]) {
        if (props.selectedFlop == 1) {
            props.currentSaves.forEach(e => {
                if (e.data.player_info[props.keyName] && e.data.player_info[props.keyName][0]) {
                    let data = e.data.player_info[props.keyName].find(e => e.name == props.name);
                    if (data) {
                        columns.push({
                            value: data.value,
                            color: data.color,
                            formula: data.formula,
                            matrix_check: getMatrix(data.matrix_check),
                            matrix_raise: getMatrix(data.matrix_raise),
                            matrix_3bet: getMatrix(data.matrix_3bet),
                            matrix_fold: getMatrix(data.matrix_fold),
                            matrix_open: getMatrix(data.matrix_open),
                            matrix_vpip: getMatrix(data.matrix_vpip),
                            matrix_call: getMatrix(data.matrix_call),
                            matrix_4bet: getMatrix(data.matrix_4bet), ev: data.ev,

                            matrix_limp: getMatrix(data.matrix_limp),
                            matrix_open_raise: getMatrix(data.matrix_open_raise),
                            matrix_isolate: getMatrix(data.matrix_isolate),
                            matrix_squeeze: getMatrix(data.matrix_squeeze),
                        })
                    } else {
                        columns.push({
                            value: "----",
                            color: "#111111",
                            formula: "",
                            matrix_check: null,
                            matrix_raise: null,
                            matrix_3bet: null,
                            matrix_fold: null,
                            matrix_open: null, matrix_vpip: null, matrix_call: null, matrix_4bet: null, ev: null,

                            matrix_limp: null,
                            matrix_open_raise: null,
                            matrix_isolate: null,
                            matrix_squeeze: null,
                        })
                    }
                } else {
                    columns.push({
                        value: "----",
                        color: "#111111",
                        formula: "",
                        matrix_check: null,
                        matrix_raise: null,
                        matrix_3bet: null,
                        matrix_fold: null,
                        matrix_open: null,
                        matrix_vpip: null,
                        matrix_call: null,
                        matrix_4bet: null,
                        ev: null,

                        matrix_limp: null,
                        matrix_open_raise: null,
                        matrix_isolate: null,
                        matrix_squeeze: null,
                    })
                }
            })
        } else {
            props.currentSaves.forEach(e => {
                if (e.data.sub_player_info[props.keyName] && e.data.sub_player_info[props.keyName][0]) {
                    let data = e.data.sub_player_info[props.keyName].find(e => e.name == props.name);
                    if (data) {
                        columns.push({
                            value: data.value,
                            color: data.color,
                            formula: data.formula,
                            matrix_check: getMatrix(data.matrix_check),
                            matrix_raise: getMatrix(data.matrix_raise),
                            matrix_3bet: getMatrix(data.matrix_3bet),
                            matrix_fold: getMatrix(data.matrix_fold),
                            matrix_open: getMatrix(data.matrix_open),
                            matrix_vpip: getMatrix(data.matrix_vpip),
                            matrix_call: getMatrix(data.matrix_call),
                            matrix_4bet: getMatrix(data.matrix_4bet), ev: data.ev,

                            matrix_limp: getMatrix(data.matrix_limp),
                            matrix_open_raise: getMatrix(data.matrix_open_raise),
                            matrix_isolate: getMatrix(data.matrix_isolate),
                            matrix_squeeze: getMatrix(data.matrix_squeeze),
                        })
                    } else {
                        columns.push({
                            value: "----",
                            color: "#111111",
                            formula: "",
                            matrix_check: null,
                            matrix_raise: null,
                            matrix_3bet: null,
                            matrix_fold: null,
                            matrix_open: null, matrix_vpip: null, matrix_call: null, matrix_4bet: null, ev: null,

                            matrix_limp: null,
                            matrix_open_raise: null,
                            matrix_isolate: null,
                            matrix_squeeze: null,
                        })
                    }
                } else {
                    columns.push({
                        value: "----",
                        color: "#111111",
                        formula: "",
                        matrix_check: null,
                        matrix_raise: null,
                        matrix_3bet: null,
                        matrix_fold: null,
                        matrix_open: null,
                        matrix_vpip: null,
                        matrix_call: null,
                        matrix_4bet: null,

                        matrix_limp: null,
                        matrix_open_raise: null,
                        matrix_isolate: null,
                        matrix_squeeze: null,
                        ev: null
                    })
                }

            })
        }
    }

    return (
        (columns[0] && columns[0].value == "----") || !isExist ? null :
            <div style={{
                display: "grid",
                gridTemplateColumns: `${props.selectedFlop == 1 ? "300px" : "400px"} 9% ${columns[0] ? "9%".repeat(columns.length) : ""}`,
                backgroundColor: (+props.i) % 2 == 0 ? "#2c2c46" : "initial", textAlign: "left"
            }}>
                <span>{props.name}</span>
                <span>{standards}</span>
                {
                    columns.map((e, g) => {
                            let matrix_list = [
                                {name: "matrix_check", display_name: "CHECK", color: "blue"},
                                {name: "matrix_call", display_name: "CALL", color: "blue"},
                                {name: "matrix_limp", display_name: "LIMP", color: "blue"},
                                {name: "matrix_open_raise", display_name: "OPEN RAISE", color: "green"},
                                {name: "matrix_isolate", display_name: "ISOLATE", color: "green"},
                                {name: "matrix_3bet", display_name: "3BET", color: "green"},
                                {name: "matrix_squeeze", display_name: "SQUEEZE", color: "green"},
                                {name: "matrix_4bet", display_name: "4BET", color: "green"},
                                {name: "matrix_vpip", display_name: "VPIP", color: "green"},
                                {name: "matrix_fold", display_name: "FOLD", color: "red"},
                                // { name: "matrix_open",  display_name: "OPEN",  color: "green" },
                                // { name: "matrix_raise", display_name: "RAISE", color: "green" },
                            ];

                            return e.matrix_check || e.matrix_call || e.matrix_limp || e.matrix_open_raise ||
                            e.matrix_isolate || e.matrix_3bet || e.matrix_squeeze || e.matrix_4bet
                            || e.matrix_vpip || e.matrix_fold ?
                                <Popup key={`lk-leakfinder matrix ${g + 1} ${props.name} ${randomInteger(1, 9999999999)}`}
                                       trigger={
                                           <TT title={e.formula}>
                                <span key={`${e.value} a ${g} ${randomInteger(1, 9999999999)}`}
                                      style={{color: getColor(e.value, standardsArr), cursor: "pointer"}}>
                                    {`${e.value == "----" ? "" : e.value ? `${e.value.toFixed(2)}` : 0}`}
                                    <span style={{
                                        fontSize: "11px",
                                        color: getColor(e.ev, evStandards)
                                    }}>{e.ev ? ` ${e.ev.toFixed(2)}` : ""}</span>
                                </span>
                                           </TT>
                                       }
                                       modal
                                >
                                    {() => (
                                        <div>
                                            <div id="get-advice" style={{
                                                backgroundColor: "rgba(21,21,33,0.8)",
                                                padding: "10px 50px 30px 50px",
                                                textAlign: "left",
                                                color: "white",
                                                width: "1076px"
                                            }}>
                                                <form className="form">
                                                    <div style={{
                                                        textAlign: "center",
                                                        fontSize: "16px",
                                                        fontWeight: "bolder"
                                                    }}>{props.name}</div>


                                                    <div className="formgroup"
                                                         style={{
                                                             display: 'flex',
                                                             flexWrap: 'wrap',
                                                             maxWidth: '1076px'
                                                         }}
                                                    >
                                                        {
                                                            matrix_list.map(matrix => {
                                                                return e[matrix.name] && e[matrix.name][0] ?
                                                                    <div style={{
                                                                        margin: "10px 0 10px 0",
                                                                        flex: '1 0 calc(50% - 10px)'
                                                                    }}>
                                                                        <div style={{
                                                                            fontWeight: "bolder",
                                                                            fontSize: "14px",
                                                                            textAlign: "center"
                                                                        }}>
                                                                            {matrix.display_name}
                                                                        </div>
                                                                        <div style={{
                                                                            height: "100%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center"
                                                                        }}>
                                                                            <Matrix data={e[matrix.name]}
                                                                                    color={matrix.color}/>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                            })
                                                        }
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </Popup> :

                                <TT title={e.formula}>
                                <span key={`${e.value} a ${g} ${randomInteger(1, 9999999999)}`}
                                      style={{color: getColor(e.value, standardsArr), cursor: "pointer"}}
                                >
                                    {`${e.value == "----" ? "" : e.value ? `${e.value.toFixed(2)} ${e.ev ? `(${e.ev.toFixed(2)}) ` : ""}` : 0}`}
                                </span>
                                </TT>
                        }
                    )
                }
            </div>
    )
}
