import React, {useState} from 'react';
import Select from "react-dropdown-select";
import moment from "moment";

const PeriodSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите период"}
            labelField={"name"}
            valueField={"name"}
            sortBy={"name"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selectedPeriod}
            onChange={(value) => {
                props.handleSelectPeriod(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const UserSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать пользователя"}
            labelField={"nickname"}
            valueField={"nickname"}
            sortBy={"nickname"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}
const DirectionSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать направление"}
            labelField={"direction"}
            valueField={"direction"}
            sortBy={"direction"}
            multi
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                props.handleSelect(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 12px", fontSize: "19px", border: "none", zIndex: "102"}}
        />
    )
}

const UsersState = (props) => {
        const [selectedPeriod, setSelectedPeriod] = useState([]);
        const [openedUsers, setOpenedUsers] = useState([]);
        const [selectedUsers, setSelectedUsers] = useState([]);
        const [selectedDirections, setSelectedDirections] = useState([]);

        const handleSelectUser = (e) => {
            if (e[0]) {
                setSelectedUsers(e)
            } else {
                setSelectedUsers([])
            }
        }
        const handleSelectDirection = (e) => {
            if (e[0]) {
                setSelectedDirections(e)
            } else {
                setSelectedDirections([])
            }
        }

        const handleSetOpenedUsers = (user_id) => {
            let updatedList = JSON.parse(JSON.stringify(openedUsers));

            if (openedUsers.includes(user_id)) {
                const ind = openedUsers.findIndex(e => e == user_id);
                updatedList.splice(ind, 1);
            } else {
                updatedList.push(user_id);
            }

            setOpenedUsers(updatedList);
        }

        const handleSelectPeriod = (e) => {
            if (e && e[0]) {
                setSelectedPeriod(JSON.parse(JSON.stringify(e)));
                props.SalaryAdminGetUsersState(e[0].name);
            } else {
                setSelectedPeriod(JSON.parse(JSON.stringify([])));
                props.SalaryAdminGetUsersState(null);
            }
        }

        let data = [];

        data = JSON.parse(JSON.stringify(props.SalaryInfo.usersState));

        if (selectedUsers[0] || selectedDirections[0]) {
            data = data.filter(e => selectedUsers.some(ee => ee.nickname == e.nickname) || selectedDirections.some(ee => ee.direction == e.direction))
        }

        let csvData = "Сотрудник|Отдел(ы)|Дата|Тип|CASH Sum|ASIA Sum|SPIN Sum|MTT Sum|Всего|\n";
        if (data && data[0]) {
            data.forEach(row => {
                if (row.state && row.state.cash_ins && row.state.cash_ins[0]) {
                    row.state.cash_ins.forEach(cash => {
                        csvData += `${row.nickname}|${row.division}|${moment(+cash.init_timestamp).format("DD.MM.YYYY")}|${cash.salary_type}|${cash.cash_sum}|${cash.asia_sum}|${cash.spin_sum}|${cash.mtt_sum}|${cash.sum}|\n`
                    })
                }
            })
        }

        let csvData2 = "Сотрудник|Отдел(ы)|Начислено всего|Выведено всего|Остаток|Не погашено|\n";
        if (data && data[0]) {
            data.forEach(row => {
                csvData2 += `${row.nickname}|${row.division}|${row.state && row.state.all_in_sum ? row.state.all_in_sum.toFixed(2) : 0}|`;
                csvData2 += `${row.state && row.state.all_out_sum ? row.state.all_out_sum.toFixed(2) : 0}|${row.state && row.state.salary ? row.state.salary.toFixed(2) : 0}|`;
                csvData2 += `${row.state && row.state.prepayment ? row.state.prepayment.toFixed(2) : 0}|\n`;
            })
        }

        const handleExportCSV = () => {
            let link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvData));
            link.setAttribute('download', "Отчёт о сотрудниках details");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        const handleExportCSV2 = () => {
            let link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvData2));
            link.setAttribute('download', "Отчёт о сотрудниках totals");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        return (
            <div style={{color: "white", padding: "20px 10px 0 20px"}}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 10px 2fr 10px 2fr 30px 150px 10px 150px"}}>
                    <div>
                        <PeriodSelector options={props.SalaryInfo.usersStatePeriods}
                                        selectedPeriod={selectedPeriod}
                                        handleSelectPeriod={handleSelectPeriod}/>
                    </div>
                    <div></div>
                    {
                        data[0] ?
                            <div>
                                <UserSelector options={props.SalaryInfo.usersStateUsers}
                                              selected={selectedUsers}
                                              handleSelect={handleSelectUser}/>
                            </div> : <div></div>
                    }
                    <div></div>
                    {
                        data[0] ?
                            <div>
                                <DirectionSelector options={props.SalaryInfo.usersStateDirections}
                                                   selected={selectedDirections}
                                                   handleSelect={handleSelectDirection}/>
                            </div> : <div></div>
                    }
                    <div></div>
                    {
                        data && data[0] ? <div className={"default-button default-back"} onClick={handleExportCSV}>
                            Export Details
                        </div> : <div></div>
                    }
                    <div></div>
                    {
                        data && data[0] ? <div className={"default-button default-back"} onClick={handleExportCSV2}>
                            Export Totals
                        </div> : <div></div>
                    }
                </div>
                <div style={{
                    width: '99.8%',
                    margin: "20px 0 0 5px", color: "white", fontSize: "11px"
                }}>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                        margin: "15px 0", padding: "3px 0",
                        textAlign: "center"
                    }}>
                        <div></div>
                        <div>Сотрудник</div>
                        <div>Отдел(ы)</div>
                        <div>Начислено всего</div>
                        <div>Выведено всего</div>
                        <div>Остаток</div>
                        <div>Не погашено</div>
                    </div>
                    {
                        data.map((row, i) => {
                            return <div key={`${i} salaryRow ${row._id}`} style={{
                                border: openedUsers.includes(row.id) ? "1px solid #52b78a" : null,
                                margin: "7px 0",
                            }}>
                                <div style={{
                                    backgroundColor: i % 2 == 0 ? '#4b5764' : `#363848`,
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                                    padding: "6px 0",
                                    textAlign: "center"
                                }}>
                                    <div onClick={() => handleSetOpenedUsers(row.id)}
                                         style={{
                                             fontWeight: "bolder",
                                             fontSize: "16px",
                                             color: openedUsers.includes(row.id) ? "#c45d5d" : "#52b78a",
                                             marginTop: "-5px",
                                             cursor: "pointer"
                                         }}
                                    >{openedUsers.includes(row.id) ? "-" : "+"}</div>
                                    <div>{row.nickname}</div>
                                    <div>{row.division}</div>
                                    <div>{row.state && row.state.all_in_sum ? row.state.all_in_sum.toFixed(2) : 0}</div>
                                    <div>{row.state && row.state.all_out_sum ? row.state.all_out_sum.toFixed(2) : 0}</div>
                                    <div>{row.state && row.state.salary ? row.state.salary.toFixed(2) : 0}</div>
                                    <div>{row.state && row.state.prepayment ? row.state.prepayment.toFixed(2) : 0}</div>
                                </div>
                                {
                                    row.state && row.state.cash_ins && row.state.cash_ins[0] && openedUsers.includes(row.id) ?
                                        <div style={{
                                            display: "grid",
                                            gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                                            padding: "6px 0"
                                        }}>
                                            <div></div>
                                            <div>Дата</div>
                                            <div>Тип</div>
                                            <div>CASH Sum</div>
                                            <div>ASIA Sum</div>
                                            <div>SPIN Sum</div>
                                            <div>MTT Sum</div>
                                            <div>Всего</div>
                                        </div>
                                        : null
                                }
                                {
                                    row.state && row.state.cash_ins && row.state.cash_ins[0] && openedUsers.includes(row.id) ?
                                        row.state.cash_ins.map((cash, j) => {
                                            return <div style={{
                                                display: "grid",
                                                gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                                                margin: "0", padding: "6px 0"
                                            }}>
                                                <div></div>
                                                <div>{moment(+cash.init_timestamp).format("DD.MM.YYYY")}</div>
                                                <div>{cash.salary_type}</div>
                                                <div>{cash.cash_sum}</div>
                                                <div>{cash.asia_sum}</div>
                                                <div>{cash.spin_sum}</div>
                                                <div>{cash.mtt_sum}</div>
                                                <div>{cash.sum}</div>
                                            </div>
                                        })
                                        : null
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        );
    }
;

export default UsersState;