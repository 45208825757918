import React, {useState, useRef} from "react"
import {NavLink} from "react-router-dom";
import MainLoading from "../../common/mainLoading";
import sortImg from "../../../images/sort.png";
import {AuditorSortTasks} from "../../../../redux/reducers/auditorReducer";

export default function AuditList(props) {
    return (
        props.isLoading ? <MainLoading/>
            :
            props.AuditorInfo.tasksList[0] ?
                <div className={"support-list-players"}>
                    <div className={"columns-header"}>
                        <div className={"columns-names"}>
                            <div style={{cursor: "pointer"}} onClick={() => props.AuditorSortTasks("nickname")}>
                                <img style={{width: "14px", marginRight: "5px"}} src={sortImg} alt={"sort"}/>Никнейм
                            </div>
                            <div>
                                Румы играет
                            </div>
                            <div style={{cursor: "pointer"}} onClick={() => props.AuditorSortTasks("setCount")}>
                                <img style={{width: "14px", marginRight: "5px"}} src={sortImg} alt={"sort"}/>Сет
                            </div>
                            <div>
                                Чекбоксы
                            </div>
                            <div style={{cursor: "pointer"}} onClick={() => props.AuditorSortTasks("status")}>
                                <img style={{width: "14px", marginRight: "5px"}} src={sortImg} alt={"sort"}/>Статус
                            </div>
                        </div>
                        <div/>
                    </div>
                    {props.AuditorInfo.tasksList.map((task, i) => <PlayerRow AuthInfo={props.AuthInfo} player={task}
                                                                        key={task.nickname + task.period + `i: ${i}`}/>)}
                </div>
                : <div style={{color: "white"}}>Игроки не найдены, либо вы не указали период</div>
    );
}

const PlayerRow = (props) => {
    if (props.player.status == "hidden") return null;

    let status = 'Необработан';
    if (props.player.follow_status) {
        if (props.player.status == "audit_1" || props.player.status == "audit" || props.player.status == "audit_2" || props.player.status == "support_2") status = "Аудит загружен"
    }
    if (props.player.status == "correct" || props.player.status == "failed") status = "Закрыто"
    if (!props.player.follow_status) status = "Закрыто"

    function openDiskPage(path) {
        window.open(`https://lk.firestorm.team/disk/${path}`, 'name', 'width=800,height=600');
        // window.open(`http://localhost:3002/disk/${path}`, 'name', 'width=800,height=600');
    }

    return (
        <div className={"support-list-player"}>
            <NavLink className={"block"}
                     to={`/support/audit/player/${props.player.nickname}/${props.player.period}/${props.player.setCount}`}>
                <div className={"nickname item"}>
                    {props.player.nickname}
                </div>
                <div className={"item"}>
                    {props.player.roomsPlaying}
                </div>
                <div className={"item"}>
                    {props.player.setCount}
                </div>

                {
                    props.AuthInfo.isAuditor ?
                        <div className={"item"}>
                            Нет
                        </div>
                        :
                        <div className={"item"}>
                            <input checked={props.player.checkboxes.is_NN_loaded} type={"checkbox"}
                                   title={"Забрал НН и загрузил на диск"}/>
                            <input checked={props.player.checkboxes.is_balances_done} type={"checkbox"}
                                   title={"Проверил балансы и записал в проверку"}/>
                            <input checked={props.player.checkboxes.is_audit_calculate} type={"checkbox"}
                                   title={"Полностью проверил и рассчитал данные по аудиту, внес авансы и несогласованную игру, оставил комментарий"}/>
                            <input checked={props.player.checkboxes.is_debts_done} type={"checkbox"}
                                   title={"Проверил и исправил данные в формуляре (количество рук/профит/рб/лб)"}/>
                            <input checked={props.player.checkboxes.is_files_loaded} type={"checkbox"}
                                   title={"Забрал аудит и загрузил на диск"}/>
                        </div>
                }

                {props.player.status == "active" ?
                    <div className={"status default-status"}>
                        {status}
                    </div>
                    :
                    props.player.status == "correct" || props.player.status == "failed" ?
                        <div className={"status closed-status"}>
                            {status}
                        </div>
                        :
                        <div className={"status audit-status"}>
                            {status}
                        </div>
                }

            </NavLink>

            <div className={"upload-buttons"}>
                <button className={"upload-button"}
                        onClick={() => openDiskPage(`AUDIT-${props.player.nickname}-${props.player.period}`)}>Audit
                </button>
                <button className={"upload-button"}
                        onClick={() => openDiskPage(`HH-${props.player.nickname}-${props.player.period}`)}>HH
                </button>
                <button className={"upload-button"}
                        onClick={() => openDiskPage(`SCREENS-${props.player.nickname}-${props.player.period}`)}>Screens
                </button>
            </div>
        </div>
    )
}