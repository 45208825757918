import React, {useState, useRef} from "react";
import {PushIdsData} from "../../../../redux/reducers/formularReducer";

export default function FinResultsIds(props) {

    const [nicknames, setNicknames] = useState(null);
    const [ids, setIds] = useState(null);
    const [isGenerated, setIsGenerate] = useState(false);

    const handleGenerate = () => {
        if (!ids || !nicknames) return;
        let n = nicknames.split("\n");
        let i = ids.split("\n");
        if (n.length != i.length) return;

        setNicknames(n);
        setIds(i);
        setIsGenerate(true);
    }

    const handleChangeInput = (type, index, value) => {
        if (type == "nickname" && !value && !ids[index]) {
            ids.splice(index, 1);
            nicknames.splice(index, 1);
            setNicknames(JSON.parse(JSON.stringify(nicknames)));
            setIds(JSON.parse(JSON.stringify(ids)));
            return;
        }
        if (type == "id" && !value && !nicknames[index]) {
            ids.splice(index, 1);
            nicknames.splice(index, 1);
            setNicknames(JSON.parse(JSON.stringify(nicknames)));
            setIds(JSON.parse(JSON.stringify(ids)));
            return;
        }
        if (type == "nickname") nicknames[index] = value;
        if (type == "id") ids[index] = value;
    }
    const handleSendIds = () => {
        let data = [];

        nicknames.forEach(e => data.push({ nickname: e }))
        ids.forEach((e, i) => data[i].id = e)

        props.PushIdsData(data)
    }

    return (
        <>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 160px", color: "white", marginBottom: "20px"}}>
                <div>
                    Никнеймы
                </div>
                <div>
                    IDs
                </div>
                <></>
            </div>

            {
                isGenerated ?
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 160px"}}>
                        <div>
                            {
                                nicknames.map((nickname, i) => {
                                    return <div style={{marginBottom: "10px", marginRight: "10px"}}
                                                key={`${nickname} ${i}`}>
                                        <input onBlur={(e) => handleChangeInput("nickname", i, e.target.value)}
                                               style={{width: "100%", backgroundColor: "lightyellow", border: "none", textAlign: "center"}}
                                               defaultValue={nickname}/>
                                    </div>
                                })
                            }
                        </div>
                        <div>
                            {
                                ids.map((id, i) => {
                                    return <div style={{marginBottom: "10px", marginRight: "10px"}} key={`${id} ${i}`}>
                                        <input onBlur={(e) => handleChangeInput("id", i, e.target.value)}
                                               style={{width: "100%", backgroundColor: "lightyellow", border: "none", textAlign: "center"}}
                                               defaultValue={id}/>
                                    </div>
                                })
                            }
                        </div>
                        {
                            !props.isLoading && <button style={{height: "50px"}} onClick={handleSendIds}>Сохранить в базу</button>
                        }
                    </div>
                    :
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 160px"}}>
                        <textarea style={{backgroundColor: "whitesmoke", textAlign: "center"}} onChange={(e) => setNicknames(e.target.value)}
                                  type="text" rows={10}/>
                        <textarea style={{backgroundColor: "whitesmoke", textAlign: "center"}} onChange={(e) => setIds(e.target.value)}
                                  type="text"/>
                        {
                            !props.isLoading && <button style={{height: "50px"}} onClick={handleGenerate}>Предсоздать</button>
                        }

                    </div>
            }
        </>
    );
}
