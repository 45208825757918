import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import UsersList from "./usersList";
import salaryReducer, {
    SalaryAddUser,
    SalaryGetProfiles,
    SalaryGetUsers,
    SalaryRemoveUser, SupportChangeUsersDirectionsFilter
} from "../../../../redux/reducers/salaryReducer";

class SalaryUsersListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Finances | Справочник сотрудников")
        this.props.SalaryGetProfiles();
        this.props.SalaryGetUsers();
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        return (
            <UsersList {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    SalaryInfo: state.salaryReducer
})


export default compose(
    connect(mapStateToProps, {
        SalaryGetProfiles, SalaryGetUsers, SalaryAddUser, SalaryRemoveUser, SupportChangeUsersDirectionsFilter
    }),
    withRouter
)(SalaryUsersListContainer)