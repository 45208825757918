import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";

import AuditorsListSidebar from "./auditorsListSidebar";
import {FindUserByNameInArr, GetUsersList} from "../../../../redux/reducers/adminReducer";
import {AdminCreateAuditor} from "../../../../redux/reducers/auditorReducer";

class AuditorsListSidebarContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.GetUsersList(this.props.AuthInfo.id, this.props.AuthInfo.email, this.props.AuthInfo.accessToken)
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        return (
            <AuditorsListSidebar {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    AuditorInfo: state.auditorReducer,
    AdminInfo: state.adminReducer
})

export default compose(
    connect(mapStateToProps, {
        GetUsersList, AdminCreateAuditor, FindUserByNameInArr
    }),
    withRouter
)(AuditorsListSidebarContainer)