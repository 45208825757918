import {LEAKFINDER_API, LEAKFINDER_SPIN_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import Cookies from "js-cookie";

let LEAKFINDER_SPIN_SET_ANALYZE_PLAYER = 'LEAKFINDER_SPIN_SET_ANALYZE_PLAYER';
let LEAKFINDER_SPIN_SET_PLAYERS = 'LEAKFINDER_SPIN_SET_PLAYERS';
let LEAKFINDER_SPIN_SET_SAVES_LIST = 'LEAKFINDER_SPIN_SET_SAVES_LIST';
let LEAKFINDER_SPIN_SET_CURRENT_SAVE = 'LEAKFINDER_SPIN_SET_CURRENT_SAVE';
let LEAKFINDER_SPIN_SET_SCHEMAS = 'LEAKFINDER_SPIN_SET_SCHEMAS';
let LEAKFINDER_SPIN_PUSH_NEW_SCHEMA = 'LEAKFINDER_SPIN_PUSH_NEW_SCHEMA'
let LEAKFINDER_SPIN_EDIT_SCHEMA = 'LEAKFINDER_SPIN_EDIT_SCHEMA'
let LEAKFINDER_SPIN_DELETE_SCHEMA = 'LEAKFINDER_SPIN_DELETE_SCHEMA';
let LEAKFINDER_SPIN_PRE_EDIT_SCHEMA = 'LEAKFINDER_SPIN_PRE_EDIT_SCHEMA'
let LEAKFINDER_SPIN_SET_START_STACK_SAVE = 'LEAKFINDER_SPIN_SET_START_STACK_SAVE';
let LEAKFINDER_SPIN_SET_START_STACK_TABLE_SAVES = 'LEAKFINDER_SPIN_SET_START_STACK_TABLE_SAVES'
let LEAKFINDER_SPIN_EDIT_DB_INFO = 'LEAKFINDER_SPIN_EDIT_DB_INFO';
let LEAKFINDER_SPIN_DELETE_SAVE_FROM_SAVES = 'LEAKFINDER_SPIN_DELETE_SAVE_FROM_SAVES';
let LEAKFINDER_SPIN_SPLICE_SAVE = 'LEAKFINDER_SPIN_SPLICE_SAVE'
let LEAKFINDER_SPIN_EDIT_ANALYZE_INFO = 'LEAKFINDER_SPIN_EDIT_ANALYZE_INFO';
let LEAKFINDER_SPIN_ANALYZE_SET_DIRECTION = 'LEAKFINDER_SPIN_ANALYZE_SET_DIRECTION';
let LEAKFINDER_SPIN_ANALYZE_SET_SELECTED_PLAYERS = 'LEAKFINDER_SPIN_ANALYZE_SET_SELECTED_PLAYERS';
let LEAKFINDER_SPIN_DELETE_SAVE = 'LEAKFINDER_SPIN_DELETE_SAVE';
let LEAKFINDER_SPIN_EDIT_SAVE_COMMENT = 'LEAKFINDER_SPIN_EDIT_SAVE_COMMENT';

let getPeriods = () => {
    let years = 2;
    let startYear = 2023;
    let months = 12;

    let periods = [];

    for (let i = 0; i < years; i++) {
        for (let j = 1; j < months + 1; j++) {
            let month = j <= 9 ? `0${j}` : j;
            if (j == 1) periods.push({name: `${startYear + i}`});
            periods.push({name: `${month}.${startYear + i}`});
            if (j == 3) periods.push({name: `1QT.${startYear + i}`});
            if (j == 6) {
                periods.push({name: `2QT.${startYear + i}`});
                periods.push({name: `1H.${startYear + i}`});
            }
            if (j == 9) periods.push({name: `3QT.${startYear + i}`});
            if (j == 12) {
                periods.push({name: `4QT.${startYear + i}`});
                periods.push({name: `2H.${startYear + i}`});
            }
        }
    }

    return periods;
}

let initialState = {
    player_info: null,
    sub_player_info: null,

    players: [],
    current_players: [],
    selected_players: [],
    groups: [],
    directions: [],

    savesList: [],
    currentSaves: [],
    schemas: [],
    startStackTableCurrentSave: null,
    startStackTableSaves: [],
    radarData: [0, 0, 0, 0, 0, 0, 0, 0],
    radarData2: [0, 0, 0, 0, 0],
    radarData3: [0, 0, 0, 0, 0, 0, 0, 0],
    radarData4: [0, 0, 0, 0, 0],

    radarDatasetPreflop: null,
    radarDatasetPostflop: null,

    left_table_data: [],
    line_chart_data: [],
    db_info: {
        user: "readonly",
        name: "",
        pass: "TEBYA_EBAT_NE_DOLJNO",
        buy_in_less: "",
        order_by: "",
        order_by_level: "",
        rooms: [],
        is_hero_need: true
    },
    analyze_info: {
        roomNames: [],
        fromDate: +new Date(),
        toDate: +new Date()
    },

};

function randomInteger(min, max) {
    let rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
}

const GetRadarDatasets = (savesList) => {
    let preflop = [];
    let postflop = [];

    if (savesList && savesList[0]) {
        savesList.forEach((save, i) => {
            let randColor_rgba = `rgba(${randomInteger(1, 255)}, ${randomInteger(1, 255)}, ${randomInteger(1, 255)},0.25)`;
            let randColor_rgb = `rgb(${randomInteger(1, 255)}, ${randomInteger(1, 255)}, ${randomInteger(1, 255)})`;

            // TODO: Поменять колеса силы
            preflop.push({
                label: `Save ${i + 1}`,
                data: [
                    // save.data.player_info["RFI (wAI)"] && save.data.player_info["Foldvs3bet (wAI)"] && save.data.player_info["RFI (wAI)"][0] && save.data.player_info["Foldvs3bet (wAI)"][0] ? (save.data.player_info["RFI (wAI)"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["Foldvs3bet (wAI)"].map(e => e.score).reduce((a, b) => a + b)) / (save.data.player_info["RFI (wAI)"].length + save.data.player_info["Foldvs3bet (wAI)"].length) : 0,
                    // save.data.player_info["vs1R (wAI) CC"] && save.data.player_info["vs1R (wAI) 3bet (wAI)"] && save.data.player_info["vs1R (wAI) 3bet (AI)>8bb"] && save.data.player_info["vs1R (wAI) CC"][0] && save.data.player_info["vs1R (wAI) 3bet (wAI)"][0] && save.data.player_info["vs1R (wAI) 3bet (AI)>8bb"][0] ? (save.data.player_info["vs1R (wAI) CC"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["vs1R (wAI) 3bet (wAI)"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["vs1R (wAI) 3bet (AI)>8bb"].map(e => e.score).reduce((a, b) => a + b)) / (save.data.player_info["vs1R (wAI) CC"].length + save.data.player_info["vs1R (wAI) 3bet (wAI)"].length + save.data.player_info["vs1R (wAI) 3bet (AI)>8bb"].length) : 0,
                    //
                    // save.data.player_info["vs1R(wAI)+C(s) CC"] && save.data.player_info["vs1R(wAI)+C(s) squezze (wAI)"] && save.data.player_info["vs1R(wAI)+C(s) squezze (AI)>8bb"] && save.data.player_info["vs1R(wAI)+C(s) CC"][0] && save.data.player_info["vs1R(wAI)+C(s) squezze (wAI)"][0] && save.data.player_info["vs1R(wAI)+C(s) squezze (AI)>8bb"][0] ? (save.data.player_info["vs1R(wAI)+C(s) CC"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["vs1R(wAI)+C(s) squezze (wAI)"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["vs1R(wAI)+C(s) squezze (AI)>8bb"].map(e => e.score).reduce((a, b) => a + b)) / (save.data.player_info["vs1R(wAI)+C(s) CC"].length + save.data.player_info["vs1R(wAI)+C(s) squezze (wAI)"].length + save.data.player_info["vs1R(wAI)+C(s) squezze (AI)>8bb"].length) : 0,
                    //
                    // save.data.player_info["VPIP vs 1R Open<2,4bb StackSize<28bb"] && save.data.player_info["VPIP vs 1R Open<2,4bb StackSize<28bb"][0] ? save.data.player_info["VPIP vs 1R Open<2,4bb StackSize<28bb"].map(e => e.score).reduce((a, b) => a + b) / save.data.player_info["VPIP vs 1R Open<2,4bb StackSize<28bb"].length : 0,
                    //
                    // save.data.player_info["VPIP vs 1R Open<2,4bb StackSize>28bb"] && save.data.player_info["VPIP vs 1R Open<2,4bb StackSize>28bb"][0] ? save.data.player_info["VPIP vs 1R Open<2,4bb StackSize>28bb"].map(e => e.score).reduce((a, b) => a + b) / save.data.player_info["VPIP vs 1R Open<2,4bb StackSize>28bb"].length : 0,
                    // save.data.player_info["BB Fold vs 1R Open<2,4bb"] && save.data.player_info["BvB SB"] && save.data.player_info["BvB BB"] && save.data.player_info["BB Fold vs 1R Open<2,4bb"][0] && save.data.player_info["BvB SB"][0] && save.data.player_info["BvB BB"][0] ? (save.data.player_info["BB Fold vs 1R Open<2,4bb"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["BvB SB"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["BvB BB"].map(e => e.score).reduce((a, b) => a + b)) / (save.data.player_info["BB Fold vs 1R Open<2,4bb"].length + save.data.player_info["BvB SB"].length + save.data.player_info["BvB BB"].length) : 0,
                    save.data.player_info["Open Raise"] ? save.data.player_info["Open Raise"].map(e => e.score).reduce((a, b) => a + b) / save.data.player_info["Open Raise"].length : 0,
                    save.data.player_info["Fold vs 3bet"] ? save.data.player_info["Fold vs 3bet"].map(e => e.score).reduce((a, b) => a + b) / save.data.player_info["Fold vs 3bet"].length : 0,

                    save.data.player_info["Call"] && save.data.player_info["3bet non all-in"] && save.data.player_info["3bet all-in"] && save.data.player_info["VPIP vs Open Raise<2,4bb, Stack<28bb"] && save.data.player_info["VPIP vs Open Raise<2,4bb, Stack>28bb"]
                    && save.data.player_info["Call"][0] && save.data.player_info["3bet non all-in"][0] && save.data.player_info["3bet all-in"][0] && save.data.player_info["VPIP vs Open Raise<2,4bb, Stack<28bb"][0] && save.data.player_info["VPIP vs Open Raise<2,4bb, Stack>28bb"][0]
                        ? (save.data.player_info["Call"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["3bet non all-in"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["3bet all-in"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["VPIP vs Open Raise<2,4bb, Stack<28bb"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["VPIP vs Open Raise<2,4bb, Stack>28bb"].map(e => e.score).reduce((a, b) => a + b))
                        / (save.data.player_info["Call"].length + save.data.player_info["3bet non all-in"].length + save.data.player_info["3bet all-in"].length + save.data.player_info["VPIP vs Open Raise<2,4bb, Stack<28bb"].length + save.data.player_info["VPIP vs Open Raise<2,4bb, Stack>28bb"].length) : 0,

                    save.data.player_info["Call_"] && save.data.player_info["Squeeze non all-in"] && save.data.player_info["Squeeze all-in"]
                    && save.data.player_info["Call_"][0] && save.data.player_info["Squeeze non all-in"][0] && save.data.player_info["Squeeze all-in"][0]
                        ? (save.data.player_info["Call_"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["Squeeze non all-in"].map(e => e.score).reduce((a, b) => a + b) + save.data.player_info["Squeeze all-in"].map(e => e.score).reduce((a, b) => a + b))
                        / (save.data.player_info["Call_"].length + save.data.player_info["Squeeze non all-in"].length + save.data.player_info["Squeeze all-in"].length) : 0,

                    save.data.player_info["Fold vs Open Raise<2,4bb"] ? save.data.player_info["Fold vs Open Raise<2,4bb"].map(e => e.score).reduce((a, b) => a + b) / save.data.player_info["Fold vs Open Raise<2,4bb"].length : 0,
                    save.data.player_info["SB vs BB_"] ? save.data.player_info["SB vs BB_"].map(e => e.score).reduce((a, b) => a + b) / save.data.player_info["SB vs BB_"].length : 0,
                ],
                backgroundColor: randColor_rgba,
                borderColor: randColor_rgb,
                borderWidth: 2,
                color: "white",
                order: 10,
            });
            postflop.push({
                label: `Save ${i + 1}`,
                data: [
                    save.data.sub_player_info["RAISER OOP"] ? save.data.sub_player_info["RAISER OOP"].map(e => e.score).reduce((a, b) => a + b) / save.data.sub_player_info["RAISER OOP"].length : 0,
                    save.data.sub_player_info["CALLER OOP"] ? save.data.sub_player_info["CALLER OOP"].map(e => e.score).reduce((a, b) => a + b) / save.data.sub_player_info["CALLER OOP"].length : 0,
                    save.data.sub_player_info["RAISER IP"] ? save.data.sub_player_info["RAISER IP"].map(e => e.score).reduce((a, b) => a + b) / save.data.sub_player_info["RAISER IP"].length : 0,
                    save.data.sub_player_info["CALLER IP"] ? save.data.sub_player_info["CALLER IP"].map(e => e.score).reduce((a, b) => a + b) / save.data.sub_player_info["CALLER IP"].length : 0,

                    save.data.sub_player_info["Raiser OOP"] && save.data.sub_player_info["Caller OOP"] && save.data.sub_player_info["Raiser IP"] && save.data.sub_player_info["Caller IP"]
                    && save.data.sub_player_info["Raiser OOP"][0] && save.data.sub_player_info["Caller OOP"][0] && save.data.sub_player_info["Raiser IP"][0] && save.data.sub_player_info["Caller IP"][0]
                        ? (save.data.sub_player_info["Raiser OOP"].map(e => e.score).reduce((a, b) => a + b) + save.data.sub_player_info["Caller OOP"].map(e => e.score).reduce((a, b) => a + b) + save.data.sub_player_info["Raiser IP"].map(e => e.score).reduce((a, b) => a + b) + save.data.sub_player_info["Caller IP"].map(e => e.score).reduce((a, b) => a + b))
                        / (save.data.sub_player_info["Raiser OOP"].length + save.data.sub_player_info["Caller OOP"].length + save.data.sub_player_info["Raiser IP"].length + save.data.sub_player_info["Caller IP"].length) : 0,

                    save.data.sub_player_info["Raise Pot"] && save.data.sub_player_info["Isolate Pot"] && save.data.sub_player_info["Limp Pot"]
                    && save.data.sub_player_info["Raise Pot"][0] && save.data.sub_player_info["Isolate Pot"][0] && save.data.sub_player_info["Limp Pot"][0]
                        ? (save.data.sub_player_info["Raise Pot"].map(e => e.score).reduce((a, b) => a + b) + save.data.sub_player_info["Isolate Pot"].map(e => e.score).reduce((a, b) => a + b) + save.data.sub_player_info["Limp Pot"].map(e => e.score).reduce((a, b) => a + b))
                        / (save.data.sub_player_info["Raise Pot"].length + save.data.sub_player_info["Isolate Pot"].length + save.data.sub_player_info["Limp Pot"].length) : 0,
                ],
                backgroundColor: randColor_rgba,
                borderColor: randColor_rgb,
                borderWidth: 2,
                color: "white",
                order: 10,
            });
        })
        return {preflop, postflop}
    } else {
        return null
    }
}

const leakfinderSpinReducer = (state = initialState, action) => {
    switch (action.type) {
        case LEAKFINDER_SPIN_EDIT_SAVE_COMMENT: {
            const newSavesList = JSON.parse(JSON.stringify(state.savesList));

            const is = newSavesList.findIndex(e => e._id == action._id);
            if (is != -1) {
                newSavesList[is].comment = action.comment;
                return {
                    ...state,
                    savesList: JSON.parse(JSON.stringify(newSavesList))
                }
            } else {
                return state;
            }
        }
        case LEAKFINDER_SPIN_DELETE_SAVE: {
            return {
                ...state,
                savesList: state.savesList.filter(e => e._id != action._id)
            }
        }
        case LEAKFINDER_SPIN_EDIT_ANALYZE_INFO: {
            let newAnalyzeInfo = JSON.parse(JSON.stringify(state.analyze_info));
            newAnalyzeInfo[action.key] = action.value;
            return {
                ...state,
                analyze_info: newAnalyzeInfo
            }
        }
        case LEAKFINDER_SPIN_EDIT_DB_INFO: {
            let newDbInfo = JSON.parse(JSON.stringify(state.db_info));
            newDbInfo[action.key] = action.value;
            return {
                ...state,
                db_info: newDbInfo
            }
        }
        case LEAKFINDER_SPIN_SET_START_STACK_SAVE: {
            return {
                ...state,
                startStackTableCurrentSave: JSON.parse(JSON.stringify(action.data))
            }
        }
        case LEAKFINDER_SPIN_DELETE_SAVE_FROM_SAVES: {
            let newSavesList = JSON.parse(JSON.stringify(state.startStackTableSaves))
            let saveI = newSavesList.findIndex(e => e._id == action._id)
            if (saveI != -1) {
                newSavesList.splice(saveI, 1)
                return {
                    ...state,
                    startStackTableSaves: JSON.parse(JSON.stringify(newSavesList))
                }
            } else {
                return state
            }
        }
        case LEAKFINDER_SPIN_SET_START_STACK_TABLE_SAVES: {
            return {
                ...state,
                startStackTableSaves: [...action.data]
            }
        }
        case LEAKFINDER_SPIN_PRE_EDIT_SCHEMA: {
            let newArr = JSON.parse(JSON.stringify(state.schemas));

            if (action.key == "pre_flop_total_percent_etalon_min") {
                newArr[action.selectedSchemaIndex].pre_flop_total_percent_etalon[0] = action.value ? action.value : 0;
            }
            if (action.key == "pre_flop_total_percent_etalon_max") {
                newArr[action.selectedSchemaIndex].pre_flop_total_percent_etalon[1] = action.value ? action.value : 0;
            }
            if (action.key == "post_flop_total_percent_etalon_min") {
                newArr[action.selectedSchemaIndex].post_flop_total_percent_etalon[0] = action.value ? action.value : 0;
            }
            if (action.key == "post_flop_total_percent_etalon_max") {
                newArr[action.selectedSchemaIndex].post_flop_total_percent_etalon[1] = action.value ? action.value : 0;
            }

            if (action.key == "standards_min") {
                if (newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName]) {
                    newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName][action.index].standards[0] = action.value;
                } else {
                    newArr[action.selectedSchemaIndex].schema_post_flop[action.categoryName][action.index].standards[0] = action.value;
                }
            }

            if (action.key == "standards_max") {
                if (newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName]) {
                    newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName][action.index].standards[1] = action.value;
                } else {
                    newArr[action.selectedSchemaIndex].schema_post_flop[action.categoryName][action.index].standards[1] = action.value;
                }
            }

            if (action.key == "standards_ev_min") {
                if (newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName]) {
                    newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName][action.index].evStandards[0] = action.value;
                } else {
                    newArr[action.selectedSchemaIndex].schema_post_flop[action.categoryName][action.index].evStandards[0] = action.value;
                }
            }

            if (action.key == "standards_ev_max") {
                if (newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName]) {
                    newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName][action.index].evStandards[1] = action.value;
                } else {
                    newArr[action.selectedSchemaIndex].schema_post_flop[action.categoryName][action.index].evStandards[1] = action.value;
                }
            }

            if (action.key == "is_visible") {
                if (newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName]) {
                    newArr[action.selectedSchemaIndex].schema_pre_flop[action.categoryName][action.index].is_visible = action.value;
                } else {
                    newArr[action.selectedSchemaIndex].schema_post_flop[action.categoryName][action.index].is_visible = action.value;
                }
            }

            return {
                ...state,
                schemas: JSON.parse(JSON.stringify(newArr))
            }
        }
        case LEAKFINDER_SPIN_EDIT_SCHEMA: {
            let newSchemasList = JSON.parse(JSON.stringify(state.schemas));
            let schemaIndex = newSchemasList.findIndex(e => e._id == action._id)
            if (schemaIndex != -1) {
                newSchemasList[schemaIndex].schema_pre_flop = JSON.parse(JSON.stringify(action.schema_pre_flop))
                newSchemasList[schemaIndex].schema_post_flop = JSON.parse(JSON.stringify(action.schema_post_flop))
                return {
                    ...state,
                    schemas: newSchemasList
                }
            } else {
                return state
            }
        }
        case LEAKFINDER_SPIN_DELETE_SCHEMA: {
            let newSchemasList = JSON.parse(JSON.stringify(state.schemas));
            let schemaIndex = newSchemasList.findIndex(e => e._id == action._id)
            if (schemaIndex != -1) {
                newSchemasList.splice(schemaIndex, 1);
                return {
                    ...state,
                    schemas: newSchemasList
                }
            } else {
                return state
            }
        }
        case LEAKFINDER_SPIN_PUSH_NEW_SCHEMA: {
            return {
                ...state,
                schemas: [...state.schemas, action.data]
            }
        }
        case LEAKFINDER_SPIN_SET_SCHEMAS: {
            return {
                ...state,
                schemas: JSON.parse(JSON.stringify(action.data))
            }
        }
        // case LEAKFINDER_SPIN_SET_ANALYZE_PLAYER: {
        //     let d = action.data.split("devDELETEthisLINE");
        //     let f = JSON.parse(d[1])
        //     let data = f.responseData;
        //     let data2 = f.responseData2;
        //     let line_chart_data = f.line_chart_data;
        //     return {
        //         ...state,
        //         player_info: JSON.parse(JSON.stringify(data)),
        //         sub_player_info: JSON.parse(JSON.stringify(data2)),
        //         radarData: [
        //             // action.data.responseData["Total Category"].map(e => e.score).reduce((a, b) => a + b) / action.data.responseData["Total Category"].length,
        //             data["RFI (wAI)"] && data["Foldvs3bet (wAI)"] && data["RFI (wAI)"][0] && data["Foldvs3bet (wAI)"][0] ? (data["RFI (wAI)"].map(e => e.score).reduce((a, b) => a + b) + data["Foldvs3bet (wAI)"].map(e => e.score).reduce((a, b) => a + b)) / (data["RFI (wAI)"].length + data["Foldvs3bet (wAI)"].length) : 0,
        //             data["vs1R (wAI) CC"] && data["vs1R (wAI) 3bet (wAI)"] && data["vs1R (wAI) 3bet (AI)>8bb"] && data["vs1R (wAI) CC"][0] && data["vs1R (wAI) 3bet (wAI)"][0] && data["vs1R (wAI) 3bet (AI)>8bb"][0] ? (data["vs1R (wAI) CC"].map(e => e.score).reduce((a, b) => a + b) + data["vs1R (wAI) 3bet (wAI)"].map(e => e.score).reduce((a, b) => a + b) + data["vs1R (wAI) 3bet (AI)>8bb"].map(e => e.score).reduce((a, b) => a + b)) / (data["vs1R (wAI) CC"].length + data["vs1R (wAI) 3bet (wAI)"].length + data["vs1R (wAI) 3bet (AI)>8bb"].length) : 0,
        //
        //             data["vs1R(wAI)+C(s) CC"] && data["vs1R(wAI)+C(s) squezze (wAI)"] && data["vs1R(wAI)+C(s) squezze (AI)>8bb"] && data["vs1R(wAI)+C(s) CC"][0] && data["vs1R(wAI)+C(s) squezze (wAI)"][0] && data["vs1R(wAI)+C(s) squezze (AI)>8bb"][0] ? (data["vs1R(wAI)+C(s) CC"].map(e => e.score).reduce((a, b) => a + b) + data["vs1R(wAI)+C(s) squezze (wAI)"].map(e => e.score).reduce((a, b) => a + b) + data["vs1R(wAI)+C(s) squezze (AI)>8bb"].map(e => e.score).reduce((a, b) => a + b)) / (data["vs1R(wAI)+C(s) CC"].length + data["vs1R(wAI)+C(s) squezze (wAI)"].length + data["vs1R(wAI)+C(s) squezze (AI)>8bb"].length) : 0,
        //
        //             data["VPIP vs 1R Open<2,4bb StackSize<28bb"] && data["VPIP vs 1R Open<2,4bb StackSize<28bb"][0] ? data["VPIP vs 1R Open<2,4bb StackSize<28bb"].map(e => e.score).reduce((a, b) => a + b) / data["VPIP vs 1R Open<2,4bb StackSize<28bb"].length : 0,
        //
        //             data["VPIP vs 1R Open<2,4bb StackSize>28bb"] && data["VPIP vs 1R Open<2,4bb StackSize>28bb"][0] ? data["VPIP vs 1R Open<2,4bb StackSize>28bb"].map(e => e.score).reduce((a, b) => a + b) / data["VPIP vs 1R Open<2,4bb StackSize>28bb"].length : 0,
        //             data["BB Fold vs 1R Open<2,4bb"] && data["BvB SB"] && data["BvB BB"] && data["BB Fold vs 1R Open<2,4bb"][0] && data["BvB SB"][0] && data["BvB BB"][0] ? (data["BB Fold vs 1R Open<2,4bb"].map(e => e.score).reduce((a, b) => a + b) + data["BvB SB"].map(e => e.score).reduce((a, b) => a + b) + data["BvB BB"].map(e => e.score).reduce((a, b) => a + b)) / (data["BB Fold vs 1R Open<2,4bb"].length + data["BvB SB"].length + data["BvB BB"].length) : 0,
        //         ],
        //         radarData2: [
        //             data2["TOTAL POSTFLOP"] ? data2["TOTAL POSTFLOP"].map(e => e.score).reduce((a, b) => a + b) / data2["TOTAL POSTFLOP"].length : 0,
        //             data2["RAISER OOP"] ? data2["RAISER OOP"].map(e => e.score).reduce((a, b) => a + b) / data2["RAISER OOP"].length : 0,
        //             data2["CALLER OOP"] ? data2["CALLER OOP"].map(e => e.score).reduce((a, b) => a + b) / data2["CALLER OOP"].length : 0,
        //             data2["RAISER IP"] ? data2["RAISER IP"].map(e => e.score).reduce((a, b) => a + b) / data2["RAISER IP"].length : 0,
        //             data2["CALLER IP"] ? data2["CALLER IP"].map(e => e.score).reduce((a, b) => a + b) / data2["CALLER IP"].length : 0
        //         ],
        //         // left_table_data: [...action.data.left_table_data],
        //         line_chart_data: line_chart_data && line_chart_data[0] ? [...line_chart_data] : [],
        //     }
        // }
        case LEAKFINDER_SPIN_SET_PLAYERS: {
            let players = action.data.sort((a, b) => a.nick.localeCompare(b.nick));
            const h = [...new Set(players.map(item => item.direction))];
            const g = [...new Set(players.map(item => item.leakfinder_db_group))];
            const directions = [];
            const groups = [];
            h.forEach(e => directions.push({direction: e}));
            g.forEach(e => e && groups.push({leakfinder_db_group: e}));

            return {
                ...state,
                players: JSON.parse(JSON.stringify(players)),
                current_players: JSON.parse(JSON.stringify(players)),
                directions, groups
            }
        }
        case LEAKFINDER_SPIN_ANALYZE_SET_SELECTED_PLAYERS: {
            return {
                ...state,
                selected_players: JSON.parse(JSON.stringify(action.players))
            }
        }
        case LEAKFINDER_SPIN_ANALYZE_SET_DIRECTION: {
            if (action.direction == 'devdel') {
                return {
                    ...state,
                    current_players: JSON.parse(JSON.stringify(state.players))
                }
            }
            const newPlayers = [];
            state.players.forEach(e => {
                if (e.direction == action.direction) {
                    newPlayers.push(e)
                }
            })
            return {
                ...state,
                current_players: JSON.parse(JSON.stringify(newPlayers))
            }
        }
        case LEAKFINDER_SPIN_SET_SAVES_LIST: {
            return {
                ...state,
                savesList: JSON.parse(JSON.stringify(action.data))
            }
        }
        case LEAKFINDER_SPIN_SPLICE_SAVE: {
            let newArr = JSON.parse(JSON.stringify(state.currentSaves));
            newArr.splice(action.index, 1);
            let radarDatasets = GetRadarDatasets(newArr)
            return {
                ...state,
                currentSaves: JSON.parse(JSON.stringify(newArr)),
                radarDatasetPreflop: radarDatasets ? radarDatasets.preflop : null,
                radarDatasetPostflop: radarDatasets ? radarDatasets.postflop : null,
            }
        }
        case LEAKFINDER_SPIN_SET_CURRENT_SAVE: {
            if (!action.data) {
                return {
                    ...state,
                    currentSaves: [],
                    radarDatasetPreflop: null,
                    radarDatasetPostflop: null,
                }
            }
            let radarDatasets = GetRadarDatasets([...state.currentSaves, JSON.parse(JSON.stringify(action.data))])
            return {
                ...state,
                currentSaves: [...state.currentSaves, JSON.parse(JSON.stringify(action.data))],
                radarDatasetPreflop: radarDatasets ? radarDatasets.preflop : null,
                radarDatasetPostflop: radarDatasets ? radarDatasets.postflop : null,
            }
        }
        default:
            return state;
    }
};

const LeakfinderSetAnalyzePlayer = (data) => ({type: LEAKFINDER_SPIN_SET_ANALYZE_PLAYER, data});
const LeakfinderSetPlayers = (data) => ({type: LEAKFINDER_SPIN_SET_PLAYERS, data});
const LeakfinderSetCurrentSaveAC = (data) => ({type: LEAKFINDER_SPIN_SET_CURRENT_SAVE, data});
const LeakfinderSetDbInfo = (key, value) => ({type: LEAKFINDER_SPIN_EDIT_DB_INFO, key, value});
const LeakfinderSetAnalyzeInfo = (key, value) => ({type: LEAKFINDER_SPIN_EDIT_ANALYZE_INFO, key, value});
const LeakfinderAnalyzeSetDirectionAC = (direction) => ({type: LEAKFINDER_SPIN_ANALYZE_SET_DIRECTION, direction});
const LeakfinderAnalyzeSetSelectedPlayersAC = (players) => ({type: LEAKFINDER_SPIN_ANALYZE_SET_SELECTED_PLAYERS, players});
const LeakfinderDeleteSaveAC = (_id) => ({type: LEAKFINDER_SPIN_DELETE_SAVE, _id});

const LeakfinderSpliceSaveAC = (index) => ({type: LEAKFINDER_SPIN_SPLICE_SAVE, index});

export const LeakfinderAnalyzeSetSelectedPlayers = (players) => {
    return (dispatch) => {
        dispatch(LeakfinderAnalyzeSetSelectedPlayersAC(players))
    }
}

export const LeakfinderAnalyzeSetDirection = (direction) => {
    return (dispatch) => {
        dispatch(LeakfinderAnalyzeSetDirectionAC(direction))
    }
}

export const LeakfinderSpliceSave = (index) => {
    return (dispatch) => {
        dispatch(LeakfinderSpliceSaveAC(index))
    }
}
export const LeakfinderEditDbInfo = (key, value) => {
    return (dispatch) => {
        dispatch(LeakfinderSetDbInfo(key, value))
    }
}
export const LeakfinderEditAnalyzeInfo = (key, value) => {
    return (dispatch) => {
        dispatch(LeakfinderSetAnalyzeInfo(key, value))
    }
}

export const LeakfinderSetCurrentSave = (data) => {
    return (dispatch) => {
        dispatch(LeakfinderSetCurrentSaveAC(data))
    }
}

export const LeakfinderDeleteSave = (_id) => {
    return dispatch => {
        LEAKFINDER_SPIN_API.deleteSave(_id)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Удаление сохранения`, data.message))
                    return
                }
                dispatch(LeakfinderDeleteSaveAC(_id))
                dispatch(AddNotification("success", `Удаление сохранения`, "Успешно"))
            })
    }
}

export const LeakfinderSaveComment = (comment, players, date_from, date_to) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"), comment, players, date_from, date_to
        }
        LEAKFINDER_SPIN_API.saveComment(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Отправка комментария`, data.message))
                    return
                }
                dispatch(AddNotification("success", `Отправка комментария`, "Успешно"))
            })
    }
}

export const LeakfinderAnalyzePlayer = (selectedRoomNames, fromDate, toDate, selectedRanks, db_info, saveName,  type = "admin") => {
    return (dispatch) => {
        LEAKFINDER_SPIN_API.analyzePlayer(selectedRoomNames, fromDate, toDate, selectedRanks, db_info, saveName, type)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Анализ игрока`, data.message))
                    return
                }
                dispatch(AddNotification("success", `Анализ игрока`, "Процесс анализа запущен. Как он закончится - он появится в сохранениях. Если у вас включены уведомления, вы получите его, когда появится сохранение"))
            })
    }
}
export const LeakfinderRemoveSavesByName = (saveName) => {
    return dispatch => {
        LEAKFINDER_SPIN_API.removeSavesByName(saveName)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Удаление сохранений по названию`, data.message))
                    return
                }
                dispatch(AddNotification("success", `Удаление сохранений по названию`, "Процесс запущен"))
            })
    }
}
export const LeakfinderAnalyzeAll = (fromDate, toDate, selectedRanks, db_info, saveName) => {
    return (dispatch) => {
        LEAKFINDER_SPIN_API.analyzeAll(fromDate, toDate, selectedRanks, db_info, saveName)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Анализ всех игроков`, data.message))
                    return
                }
                dispatch(AddNotification("success", `Анализ всех игроков`, "Анализ запущен. В время обработки какие-либо анализы недоступны."))
            })
    }
}

export const LeakfinderGetPlayers = () => {
    return (dispatch) => {
        LEAKFINDER_SPIN_API.getPlayers()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение никнеймов игроков`, data.message))
                    return
                }
                dispatch(LeakfinderSetPlayers(data))
            })
    }
}

export const LeakfinderSaveAnalyze = (topic, data, room_names, date_from, date_to, line_chart_data) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"), topic, data, room_names, date_from, date_to, line_chart_data
        }
        LEAKFINDER_SPIN_API.saveAnalyze(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Сохранение`, data.message))
                    return
                }
                dispatch(AddNotification("success", `Сохранение`, "Успешно"))
            })
    }
}

const SetSavesList = (data) => ({type: LEAKFINDER_SPIN_SET_SAVES_LIST, data})
export const LeakfinderGetSaves = (room_names) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"), room_names
        }
        LEAKFINDER_SPIN_API.getSaves(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(SetSavesList([]))
                    dispatch(AddNotification("error", `Получение списка сохранений`, data.message))
                    return
                }
                dispatch(SetSavesList(data))
            })
    }
}

const SetSchemas = (data) => ({type: LEAKFINDER_SPIN_SET_SCHEMAS, data})
export const LeakfinderGetSchemas = () => {
    return (dispatch) => {
        LEAKFINDER_SPIN_API.getSchemas()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка рангов`, data.message))
                    return
                }
                dispatch(SetSchemas(data))
            })
    }
}

const PushNewSchema = (data) => ({type: LEAKFINDER_SPIN_PUSH_NEW_SCHEMA, data})
export const LeakfinderCreateSchema = (name) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"),
            name
        }
        LEAKFINDER_SPIN_API.schemaCreate(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Создание ранга`, data.message))
                    return
                }
                dispatch(PushNewSchema(data))
                dispatch(AddNotification("success", `Создание ранга`, "Успешно"))
            })
    }
}

const EditSchema = (_id, schema_pre_flop, schema_post_flop) => ({type: LEAKFINDER_SPIN_EDIT_SCHEMA, schema_pre_flop, schema_post_flop, _id})
export const LeakfinderEditSchema = (_id, schema_pre_flop, schema_post_flop, pre_flop_total_percent_etalon, post_flop_total_percent_etalon) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"),
            _id, schema_pre_flop, schema_post_flop, pre_flop_total_percent_etalon, post_flop_total_percent_etalon
        }
        LEAKFINDER_SPIN_API.schemaEdit(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Сохранение ранга`, data.message))
                    return
                }
                dispatch(EditSchema(_id, schema_pre_flop, schema_post_flop))
                dispatch(AddNotification("success", `Сохранение ранга`, "Успешно"))
            })
    }
}

const DeleteSchema = (_id) => ({type: LEAKFINDER_SPIN_DELETE_SCHEMA, _id})
export const LeakfinderDeleteSchema = (_id) => {
    return (dispatch) => {
        let body = {
            token: Cookies.get("token"),
            _id
        }
        LEAKFINDER_SPIN_API.schemaDelete(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Удаление ранга`, data.message))
                    return
                }
                dispatch(DeleteSchema(_id))
                dispatch(AddNotification("success", `Удаление ранга`, "Успешно"))
            })
    }
}

const PreEditSchema = (categoryName, index, key, value, selectedSchemaIndex) => ({
    type: LEAKFINDER_SPIN_PRE_EDIT_SCHEMA,
    categoryName, index, key, value, selectedSchemaIndex })
export const LeakfinderPreEditSchema = (categoryName, index, key, value, selectedSchemaIndex) => {
    return (dispatch) => {
        dispatch(PreEditSchema(categoryName, index, key, value, selectedSchemaIndex))
    }
}

const EditSaveComment = (_id, comment) => ({type: LEAKFINDER_SPIN_EDIT_SAVE_COMMENT, _id, comment});
export const SaveCommentToAnalyze = (_id, comment) => {
    return dispatch => {
        if (!comment || comment.length <= 2) return;
        dispatch(EditSaveComment(_id, comment))
        let body = {
            token: Cookies.get("token"), _id, comment, direction: "SPIN"
        }
        LEAKFINDER_API.saveCommentToAnalyze(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Добавление комментария`, data.message))
                    return
                }
            })
    }
}

export const LeakfinderDownloadCSV = async (filename, arrayOfObjects, isSave, fromDate = null, toDate = null, save = null, room_names = null) => {
    let generatedCSV = 'FROM;TO;Roomnames\n';
    if (isSave) {
        generatedCSV += `${new Date(save.date_from)};${new Date(save.date_to)};${JSON.stringify(save.room_names.map(e => e.name))}\n`
    } else {
        generatedCSV += `${new Date(fromDate)};${new Date(toDate)};${JSON.stringify(room_names.map(e => e.name))}\n`
    }
    generatedCSV += 'Название;Значение;Эталон;Разница;Выборка\n'
    for (const [key, value] of Object.entries(arrayOfObjects)) {
        generatedCSV += `${key}\n`
        value.forEach(e => generatedCSV += `${e.name};${e.value};${e.standards};${e.different};${e.formula}\n`)
    }
    let link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(generatedCSV));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default leakfinderSpinReducer;