import React, {useState, useRef} from "react";
import MainLoading from "../../common/mainLoading";

export default function AuditorsListSidebar(props) {
    let createAuditorDirection = React.createRef();
    let editAuditorName = React.createRef();

    const [createAuditorName, setCreateAuditorName] = useState(null);
    const [createAuditorId, setCreateAuditorId] = useState(null);

    let createAuditor = () => {
        props.AdminCreateAuditor({
            id: createAuditorId,
            name: createAuditorName,
            direction: createAuditorDirection.current.value
        })
    }

    const FindUserByNameInArr = () => {
        setCreateAuditorName(editAuditorName.current.value)
        props.FindUserByNameInArr(editAuditorName.current.value)
    }

    return (
        props.isLoading ? <MainLoading/> :
            <div className={"sidebar"}>
                <div className={"item"}>
                    <div className={"name"}>
                        Новый аудитор
                    </div>
                    <div className={"info"}>
                        <div className={"dropdown"}>
                            <input placeholder={"Никнейм"} className={"small-input"} onChange={FindUserByNameInArr} ref={editAuditorName} value={createAuditorName}/>
                            <div className="dropdown-content">
                                {
                                    props.AdminInfo.usersList.map((user, i) => user.isAuditor ?
                                        <Auditor key={i + user.username} auditor={user} setCreateAuditorId={setCreateAuditorId}
                                                 setCreateAuditorName={setCreateAuditorName}/>
                                        : null)
                                }
                            </div>
                        </div>
                        <input ref={createAuditorDirection} className={"small-input"} placeholder={"Направление"}/>
                    </div>
                    <button onClick={createAuditor} className={"small-button green-back transition"}>Добавить в список
                    </button>
                </div>
            </div>
    )
}

const Auditor = (props) => {
    const EditAuditor = () => {
        props.setCreateAuditorName(props.auditor.username)
        props.setCreateAuditorId(props.auditor.id)
    }
    return (
        <div onClick={EditAuditor}>{props.auditor.username}</div>
    )
}