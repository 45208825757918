import React from 'react';
import s from './styles.module.css'

import profitImg from '../../../../assests/day1.png'
import handsImg from '../../../../assests/day2.png'
import rakeImg from '../../../../assests/day3.png'
import tbetImg from '../../../../assests/day4.png'
import cbetImg from '../../../../assests/day5.png'

const Item = (props) => {
    // ROI roiTotal = ((sum_prize / buyin_with_multientries) - 1) * 100;
    // Лимит (так же как в кеше считать, только брать за количество и турниры и ребаи)

    return (
        props.direction && props.direction == "MTT" ?
            <>
                <div className={s.item}>

                </div>
                <div className={s.item} title={"Количество турниров за выбранный период"}>
                    <img src={handsImg} alt=""/>
                    <div className={s.text_box}>
                        <h4>{props.selected_count}</h4>
                        <p>Турниров</p>
                    </div>
                </div>
                <div className={s.item} title={"Profit"}>
                    <img src={profitImg} alt=""/>
                    <div className={s.text_box}>
                        <h4>${props.selected_prize ? (props.selected_prize).toFixed(2) : 0}</h4>
                        <p>Profit</p>
                    </div>
                </div>
                <div className={s.item} title={"ROI"}>
                    <img src={profitImg} alt=""/>
                    <div className={s.text_box}>
                        <h4>{props.selected_p && props.selected_bi ? (((props.selected_p / props.selected_bi) - 1) * 100).toFixed(2) : 0}</h4>
                        <p>ROI</p>
                    </div>
                </div>
                <div className={s.item} title={"Лимит"}>
                    <img src={tbetImg} alt=""/>
                    <div className={s.text_box}>
                        <h4>{props.selected_bi && props.selected_count ? (props.selected_bi / props.selected_count).toFixed(2) : 0}</h4>
                        <p>Лимит</p>
                    </div>
                </div>
                <div className={s.item} title={"ITM%"}>
                    <img src={tbetImg} alt=""/>
                    <div className={s.text_box}>
                        <h4>{props.selected_count && props.selected_in_prizes ? ((props.selected_in_prizes / props.selected_count) * 100).toFixed(2) : 0}</h4>
                        <p>ITM%</p>
                    </div>
                </div>
                <div className={s.item}>

                </div>
            </>
            :
        <>
            <div className={s.item} title={"Количество сыгранных раздач за выбранный период"}>
                <img src={handsImg} alt=""/>
                <div className={s.text_box}>
                    <h4>{props.selected_hands}</h4>
                    <p>Количество рук</p>
                </div>
            </div>
            <div className={s.item} title={"Ориентировочный Профит в пересчете на USD за выбранный период на основе внесенных данных"}>
                <img src={profitImg} alt=""/>
                <div className={s.text_box}>
                    <h4>${props.selected_profit ? Math.ceil(props.selected_profit) : 0}</h4>
                    <p>Профит</p>
                </div>
            </div>
            <div className={s.item} title={"Ориентировочная сумма Рейкбека за выбранный период на основе внесенных данных"}>
                <img src={tbetImg} alt=""/>
                <div className={s.text_box}>
                    <h4>{props.period_rakeback ? props.period_rakeback.toFixed(2) : 0}</h4>
                    <p>Рейкбек</p>
                </div>
            </div>
            <div className={s.item} title={"bb/100 Винрейт в покере = (выигрыш в блайндах / количество раздач) * 100"}>
                <img src={rakeImg} alt=""/>
                <div className={s.text_box}>
                    <h4>{props.selected_profit && props.selected_hands && props.selected_limit ? (((props.selected_profit / (props.selected_limit / 100)) / props.selected_hands) * 100).toFixed(2) : 0}</h4>
                    <p>Winrate</p>
                </div>
            </div>

            <div className={s.item} title={"Средневзвешенный лимит за выбранный период на основе внесенных данных"}>
                <img src={cbetImg} alt=""/>
                <div className={s.text_box}>
                    <h4>{props.selected_limit ? props.selected_limit.toFixed(1) : 0}</h4>
                    <p>Лимит</p>
                </div>
            </div>
        </>
    );
};

export default Item;