import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import DefaultAvatarIcon from '../../../images/default_avatar.png'
import MainLoading from "../../common/mainLoading";
import Cookies from "js-cookie";
import config from "../../../config.json";
import {EditProfile} from "../../../../redux/reducers/adminReducer";

export default function UserPage(props) {
    let GiveRole = (e) => {
        props.GiveRole(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.AdminInfo.currentUser.id, props.AdminInfo.currentUser.username, e.target.accessKey)
    }

    let SetAccess = (type) => {
        props.SetAccess(props.AdminInfo.currentUser.id, type, props.AuthInfo.id, props.AuthInfo.accessToken,
            props.AuthInfo.username, props.AuthInfo.email);
    }

    const handleAuthUser = () => {
        Cookies.set('WEB_SITE_USER_VERSION', "2", {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_token', Cookies.get("token"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_id', Cookies.get("id"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_avatar', Cookies.get("avatar"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_username', Cookies.get("username"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('saved_email', Cookies.get("email"), {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('token', props.AdminInfo.currentUser.accessToken, {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('id', props.AdminInfo.currentUser.id, {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('avatar', props.AdminInfo.currentUser.avatar, {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('username', props.AdminInfo.currentUser.username, {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        Cookies.set('email', props.AdminInfo.currentUser.email, {
            domain: config.domain,
            path: '/',
            expires: 365 // срок годности в днях
        });
        window.location.href = "/"
    }
    const copyTextToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
            console.error('Ошибка:', err);
        }
    };
    return (
        props.isLoading ? <MainLoading/>
            :
            props.AdminInfo.currentUser ?
                <div className="user-page">
                    <div onClick={handleAuthUser}
                         style={{margin: "15px 0 30px 0"}}
                         className={"default-button green-back"}>
                        Авторизоваться за этого пользователя
                    </div>
                    <div className={"info"}>
                        <div className={"avatar"}>
                            <img
                                src={
                                    props.AdminInfo.currentUser.avatar ?
                                        `https://cdn.discordapp.com/avatars/${props.AdminInfo.currentUser.id}/${props.AdminInfo.currentUser.avatar}`
                                        : DefaultAvatarIcon}
                                alt={"avatar"}/>
                        </div>
                        <div className={"description"}>
                            Никнейм: <span className={"light-gray"}>{props.AdminInfo.currentUser.username}</span>
                            <br/>Почта: <span className={"light-gray"}>{props.AdminInfo.currentUser.email}</span>
                            <br/>Был онлайн: <span className={"light-gray"}>{props.AdminInfo.currentUser.online}</span>
                            <br/><span style={{fontWeight: "bolder", color: "orangered", cursor: "pointer"}}
                                       onClick={() => props.DEV_TEST_SendNotification()}>DEV TEST SEND NOTIFICATION</span>
                        </div>
                    </div>
                    {/*<div className={"utility"}>*/}
                    {/*    <div className={"column"}>*/}
                    {/*        <div className={"block-name"}>*/}
                    {/*            Изменить почту*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <input placeholder={"Функция не работает"}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={"accesses"}>
                        <div className={"block-name"}>
                            Права доступа
                        </div>
                        <div style={{
                            padding: "10px 10px",
                            margin: "10px 20px 0 0",
                            backgroundColor: "#2c2c36",
                            borderRadius: "10px"
                        }}>
                            <div className={"block-name"}>
                                Основные
                            </div>
                            <div className={"checkboxes"}>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("admin")}
                                               checked={props.AdminInfo.currentUser.isAdmin}/>Админ
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("coach")}
                                               checked={props.AdminInfo.currentUser.isCoach}/>Тренер
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("disk")}
                                               checked={props.AdminInfo.currentUser.isDisk}/>Доступ к диску
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("wikiCreator")}
                                               checked={props.AdminInfo.currentUser.isWikiCreator}/>Редактор вики
                                    </div>
                                </div>

                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("leaderboardAdmin")}
                                               checked={props.AdminInfo.currentUser.isLeaderboardAdmin}/>Лидерборд админ
                                    </div>
                                </div>

                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("leakfinderAdmin")}
                                               checked={props.AdminInfo.currentUser.isLeakfinderAdmin}/>Ликфайндер админ
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={{
                            padding: "10px 10px",
                            margin: "10px 20px 0 0",
                            backgroundColor: "#2c2c36",
                            borderRadius: "10px"
                        }}>
                            <div className={"block-name"}>
                                Обменник
                            </div>
                            <div className={"checkboxes"}>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("fsChangerAdmin")}
                                               checked={props.AdminInfo.currentUser.isFsChangerAdmin}/>Админ
                                    </div>
                                </div>

                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("fsChangerSupport")}
                                               checked={props.AdminInfo.currentUser.isFsChangerSupport}/>Саппорт
                                    </div>
                                </div>

                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("fsChangerUser")}
                                               checked={props.AdminInfo.currentUser.isFsChangerUser}/>Доступ к обменнику
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={{
                            padding: "10px 10px",
                            margin: "10px 20px 0 0",
                            backgroundColor: "#2c2c36",
                            borderRadius: "10px"
                        }}>
                            <div className={"block-name"}>
                                Аудит
                            </div>
                            <div className={"checkboxes"}>

                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("auditAdmin")}
                                               checked={props.AdminInfo.currentUser.isAuditAdmin}/>Админ
                                    </div>
                                </div>

                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("auditor")}
                                               checked={props.AdminInfo.currentUser.isAuditor}/>Аудитор
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={{
                            padding: "10px 10px",
                            margin: "10px 20px 0 0",
                            backgroundColor: "#2c2c36",
                            borderRadius: "10px"
                        }}>
                            <div className={"block-name"}>
                                Саппорт
                            </div>
                            <div className={"checkboxes"}>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("MTSNGSupport")}
                                               checked={props.AdminInfo.currentUser.isMTSNGSupport}/>MTSNG
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("MTTSupport")}
                                               checked={props.AdminInfo.currentUser.isMTTSupport}/>MTT
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("CashSupport")}
                                               checked={props.AdminInfo.currentUser.isCashSupport}/>CASH
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("SpinSupport")}
                                               checked={props.AdminInfo.currentUser.isSpinSupport}/>SPIN
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("POKERHUBSupport")}
                                               checked={props.AdminInfo.currentUser.isPokerhubSupport}/>POKERHUB
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            padding: "10px 10px",
                            margin: "10px 20px 0 0",
                            backgroundColor: "#2c2c36",
                            borderRadius: "10px"
                        }}>
                            <div className={"block-name"}>
                                Финансисты
                            </div>
                            <div className={"checkboxes"}>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("financesAdmin")}
                                               checked={props.AdminInfo.currentUser.isFinancesAdmin}/>Админ
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            padding: "10px 10px",
                            margin: "10px 20px 0 0",
                            backgroundColor: "#2c2c36",
                            borderRadius: "10px"
                        }}>
                            <div className={"block-name"}>
                                Рефка
                            </div>
                            <div className={"checkboxes"}>
                                <div className={"item"}>
                                    <div className={"name"}>
                                        <input type={"checkbox"} onClick={() => SetAccess("refSupport")}
                                               checked={props.AdminInfo.currentUser.isRefSupport}/>Саппорт
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"accesses"}>
                        <div className={"block-name"}>
                            Дополнительно к правам
                        </div>
                        <div>
                            <div style={{backgroundColor: "#2c2c36", padding: "10px", margin: "10px 20px 0 0", borderRadius: "10px"}}>
                                <div style={{margin: "0 0 10px 0"}}>
                                    Ранг тренера
                                </div>
                                <input type="text" style={
                                    {
                                        backgroundColor: "#202026",
                                        border: "none",
                                        borderRadius: "5px",
                                        outline: "none",
                                        padding: "5px", color: "white"
                                    }
                                }
                                       onBlur={(e) => props.EditProfile(props.AdminInfo.currentUser.id, "coach_rank", e.target.value)}
                                       placeholder={"Введите ранг..."}
                                       defaultValue={props.AdminInfo.currentUser.coach_rank}/>
                            </div>

                        </div>
                    </div>

                    <div className={"accesses"}>
                        <div className={"block-name"}>
                            Leakfinder Staff Desktop
                        </div>
                        <div>
                            <div style={{backgroundColor: "#2c2c36", padding: "10px", margin: "10px 20px 0 0", borderRadius: "10px"}}>
                                <div style={{margin: "0 0 10px 0"}}>
                                    Ключ доступа (<span style={{cursor: "pointer", color: "#7bc0ed"}} onClick={() => copyTextToClipboard(props.AdminInfo.currentUser.leakfinder_desktop_key ? props.AdminInfo.currentUser.leakfinder_desktop_key : "")}>Копировать</span>)
                                </div>
                                <input type="password" style={
                                    {
                                        backgroundColor: "#202026",
                                        border: "none",
                                        borderRadius: "5px",
                                        outline: "none",
                                        padding: "5px", color: "white"
                                    }
                                }
                                       onBlur={(e) => props.EditProfile(props.AdminInfo.currentUser.id, "leakfinder_desktop_key", e.target.value)}
                                       placeholder={"..."}
                                       defaultValue={props.AdminInfo.currentUser.leakfinder_desktop_key}/>
                            </div>

                        </div>
                    </div>

                    <div className={"roles"}>
                        <div className={"block-name"}>
                            Роли
                        </div>
                        <div className={"list"}>
                            {props.AdminInfo.currentUser.roles.map((role, i) => <RoleBlock key={role.name + " " + i}
                                                                                           role={role}
                                                                                           DeleteRole={props.DeleteRole}
                                                                                           AuthInfo={props.AuthInfo}
                                                                                           AdminInfo={props.AdminInfo}/>)}
                            {props.RolesInfo.rolesList[0] ?
                                <Popup key={props.AdminInfo.currentUser.roles.length}
                                       trigger={<a className={"add-role"}>Добавить роль</a>}
                                       modal
                                       nested
                                >
                                    {() => (
                                        <div>
                                            <div id="get-advice" className="user-page-popup">
                                                <form className="form">
                                                    <div className="formgroup">
                                                        {props.RolesInfo.rolesList.map((role, y) => <div
                                                            key={role.name + "1" + y} accessKey={role.name}
                                                            onClick={GiveRole}
                                                            className={"available-role"}>{role.name}</div>)}
                                                    </div>
                                                    {props.AdminInfo.addRoleError ? <div
                                                        className={"error"}>{props.AdminInfo.addRoleError}</div> : null}
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                : null}
                        </div>
                    </div>
                    <div className={"logs"}>

                    </div>
                </div>
                : null
    );
}

const RoleBlock = (props) => {
    let DeleteRole = (e) => {
        props.DeleteRole(props.AuthInfo.id, props.AuthInfo.email, props.AuthInfo.username, props.AuthInfo.accessToken,
            props.AdminInfo.currentUser.id, e.target.accessKey)
    }
    return (
        <div className={"item"}>
            <div className={"name"}>
                {props.role.name}
            </div>
            <div className={"initiator"}>
                <span className={"light-gray"}> Роль выдал: {props.role.initiator}</span>
            </div>
            <div className={"delete-role"}>
                <button onClick={DeleteRole} accessKey={props.role.name}>Снять роль</button>
            </div>
        </div>
    )
}