import React, {useState} from 'react';
import s from './styles.module.css'
import Row from "./row";
import ActionTable from "../action";

function getDiff (value, etalon_min, etalon_max) {

    //standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
    if (etalon_min <= 0) etalon_min = 0;
    if (etalon_max.length <= 0) etalon_max = 0;

    // Вычисляем разницу между верхней и нижней границей эталона
    let etalon_gape = etalon_max - etalon_min;

    // для каждого значения etalon_gape определяем коэффициент для дальнейших расчетов
    let keff = 0;
    if (etalon_gape > 0 && etalon_gape <= 3) {
        keff = 13;
    } else if (etalon_gape > 3 && etalon_gape <= 5) {
        keff = 8;
    } else if (etalon_gape > 5 && etalon_gape <= 10) {
        keff = 5;
    } else if (etalon_gape > 10) {
        keff = 3;
    }

    // Далее каждый процент расхождения умножаем на этот коэфф и тем самым получаем %попадания
    let diff = 0;
    if (value < etalon_min) {
        diff = etalon_min - value;
    }
    else if (value > etalon_max) {
        diff = value - etalon_max;
    }

    //  попадание = 100 - расхождение*КОЭФФ;
    //      ЕСЛИ попадание < 0 то попадание = 0;
    let hit = 100 - diff * keff;
    if (hit < 0) hit = 0;

    // console.log(`========================================`)
    // console.log(`etalon_gape = ${etalon_gape}`)
    // console.log(`keff = ${keff}`)
    // console.log(`diff = ${diff.toFixed(1)}`)
    // console.log(`hit = ${hit.toFixed(1)}`)

    return +hit



}

// function getDiff(value, etalon_min, etalon_max) {
//     if (value >= etalon_min && value <= etalon_max) {
//         return 0; //  - это число, которое показывает насколько мы отклоняемся от стратегии. Т.к. в этом ИФе value1 внутри мин/макс эталона, то расхождение со стратой равно 0
//     } else {
//         if (value > etalon_max) {
//             return (etalon_max - value ); //если значение value больше максимального предела эталона, то разницу считаем именно от верхнего предела
//         } else {
//             return value - etalon_min; //иначе считаем от нижнего предела эталона
//         }
//     }
// }


const Table2 = ({periods, schema}) => {
    const [typeSaves, setTypeSaves] = useState('player_info')

    let schema_type = typeSaves == "player_info" ? "schema_pre_flop" : "schema_post_flop";

    const period_1 = periods[0]
    const period_2 = periods[1]

    const schema_keys = Object.keys(schema[schema_type])
    const schema_data = schema[schema_type]

    const newData = {}

    const players = period_1.map((name) => name.username)

    for (let i = 0; i < players.length; i++) {
        const data = {}
        const findUserData1 = period_1.find(f => f.username === players[i])
        const findUserData2 = period_2.find(f => f.username === players[i])

        for (let j = 0; j < schema_keys.length; j++) {
            const key = schema_keys[j]

            const currentKey1 = (findUserData1?.data && findUserData1.data[typeSaves] && findUserData1.data[typeSaves][key]) ? findUserData1.data[typeSaves][key] : []
            const currentKey2 = (findUserData2?.data && findUserData2.data[typeSaves] && findUserData2.data[typeSaves][key]) ? findUserData2.data[typeSaves][key] : [];

            const hz = currentKey1.map((item) => {
                    const findByStandarts = schema_data[key].find(f => f.name === item.name)?.standards
                    const findByName = currentKey2.find(f => f.name === item.name)

                    return {
                        ...item,
                        standards: findByStandarts,
                        tooltip_value: `${item?.value} -> ${findByName?.value}`,
                        value: !findByStandarts ?
                            null
                            :
                            +(+getDiff(findByName?.value ? findByName.value : 0, +findByStandarts[0], +findByStandarts[1]) - +getDiff(item?.value ? item.value : 0, +findByStandarts[0], +findByStandarts[1])).toFixed(2)
                    }
                }
            )

            data[key] = hz
        }
        let totalCount = {count: 0, length: 0};

        for (let y = 0; y < Object.keys(data).length; y++) {
            const reduce = data[Object.keys(data)[y]].reduce((acc = 0, inc) => {
                return acc = acc + inc.value
            }, 0)

            const reduce_length = data[Object.keys(data)[y]].reduce((acc = 0, inc) => {
                if (inc.value !== 0 && inc.value !== null) {
                    return acc = acc + 1
                } else {
                    return acc
                }
            }, 0)
            totalCount = {
                count: totalCount.count + reduce,
                length: totalCount.length + reduce_length
            }
        }
        newData[players[i]] = {...newData[players[i]], data, total: totalCount.count, length: totalCount.length}
    }

    const saves_data = newData[Object.keys(newData)[0]].data

    const getAvgCategiry = (key) => {
        let avg = []
        for (let i = 0; i < schema_data[key].length; i++) {
            let length = players.length

            const avg1 = players.reduce((acc = 0, inc) => {
                const find_data_by_userName = newData[inc].data[key]
                const find_current_name = find_data_by_userName.find((f) => f.name === schema_data[key][i].name)

                if (find_current_name?.value === 0) {
                    length = length - 1
                }

                return acc = acc + (find_current_name?.value || 0)
            }, 0)
            const avg_complete = avg1 / length
            avg = [...avg, avg_complete]
        }
        const filtered_zero = avg.filter(f => !isNaN(f))
        const result = filtered_zero.reduce((acc, inc) => acc = +acc + +inc, 0)
        return (result / filtered_zero.length).toFixed(2)
    }

    return (
        <>
            <ActionTable changeTypeSave={(type) => setTypeSaves(type)}/>
            <div className={s.content}>
                <div style={{overflow: 'auto'}}>
                    <div className={s.header}>
                        <Row min={200} max={200}></Row>
                        <Row min={150} max={150}></Row>
                        <Row min={100} max={100} type={"avg"}>Среднее</Row>
                        <Row max={'100%'}>Игрок</Row>
                    </div>

                    {Object.keys(newData).map((key, i) => {
                        const avg = Object.keys(newData).reduce((acc = 0, inc) => {

                            if (newData[inc].total === 0) {
                                return acc
                            } else {
                                return acc = acc + (newData[inc].total / newData[inc].length)
                            }


                        }, 0)


                        const length = Object.keys(newData).filter(f => newData[f].total !== 0)

                        return <div style={{display: i === 0 ? 'flex' : 'none'}} key={i}>
                            <div>
                                <br/>
                                <Row min={200} max={200} isCategory={true} align={'left'}>{null}</Row>
                                <br/>

                            </div>

                            <div>
                                <br/>
                                <Row min={150} max={150} isCategory={true} align={'left'}>{isNaN(avg / length.length) ? 0 : (avg / length.length).toFixed(2)}</Row>
                                <br/>

                            </div>

                            <div>
                                <br/>
                                <Row min={100} max={100} isCategory={true} align={'left'} type={"avg"}>{null}</Row>
                                <br/>
                                <div>
                                    <Row
                                        align={'left'} type={"avg"}>{(avg / Object.keys(newData).length).toFixed(2)}</Row>
                                </div>
                            </div>

                            <div>
                                <br/>
                                <div className={s.players}>
                                    {players.map((name, index) => {
                                            return (
                                                <div className={s.test} key={`players${index}`}>
                                                    <Row min={100} max={100} isCategory={true}
                                                         align={'center'}>{name}</Row>
                                                    <br/>
                                                    <Row
                                                        align={'left'}>{isNaN(newData[name].total / newData[name].length) ? 0 : (newData[name].total / newData[name].length).toFixed(2)}</Row>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>

                                <br/>
                            </div>
                        </div>
                    })}
                </div>
                <br/>
                <div style={{overflow: 'auto'}}>
                    <div className={s.header}>
                        <Row min={200} max={200}>Стат</Row>
                        <Row min={150} max={150}>Эталон</Row>
                        <Row min={100} max={100} type={"avg"}>Среднее</Row>
                        <Row max={'100%'}>Игрок</Row>
                    </div>

                    {schema_keys?.map((key, i) => {
                        if (!saves_data[key]) return;

                        return <div key={`main_content${i}`} style={{
                            display: (!saves_data[key]) && 'none'
                        }}>
                            {schema_data[key]?.length === 0 ? null :
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <br/>
                                        <Row min={200} max={200} isCategory={true} align={'left'}>{key}</Row>
                                        <br/>
                                        <div>
                                            {!saves_data[key] ? null : saves_data[key].map((sub_stat, index) => {
                                                return <Row
                                                    hidden={!Boolean(schema_data[key].find(f => f.name === sub_stat.name))}
                                                    key={`sub_state${index}`} align={'left'}>{sub_stat.name}</Row>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <br/>
                                        <Row min={150} max={150} isCategory={true} align={'left'}>{null}</Row>
                                        <br/>
                                        <div>
                                            {saves_data[key]?.map((schema_Value, index) => {
                                                const schema_values = schema_Value?.standards
                                                if (!schema_values) return;

                                                return <Row
                                                    key={`schema_Value${index}`}
                                                    align={'center'}>
                                                    {`${schema_values[0]} - ${schema_values[1]}`}
                                                </Row>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <br/>
                                        <Row min={100} max={100} isCategory={true} align={'center'} type={"avg"}>
                                            {isNaN(getAvgCategiry(key)) ? 0 : getAvgCategiry(key)}
                                        </Row>
                                        <br/>
                                        <div>
                                            {!saves_data[key] ? null : saves_data[key].map((sub_stat, index) => {
                                                let length = players.length;
                                                const avg = players.reduce((acc = 0, inc) => {
                                                    const find_data_by_userName = newData[inc].data[key]
                                                    const find_current_name = find_data_by_userName.find((f) => f.name === sub_stat.name)
                                                    if (find_current_name?.value === 0) {
                                                        length = length - 1
                                                    }
                                                    // console.log(find_current_name)
                                                    return acc = acc + (find_current_name?.value || 0)
                                                }, 0)
                                                const avg_complete = avg / length;
                                                return <Row className={`${key.split(' ').join('_')}_avg`} key={`avg${index}`} type={"avg"}
                                                            hidden={!Boolean(schema_data[key].find(f => f.name === sub_stat.name))}
                                                            align={'center'}>{isNaN(avg_complete) ? 0 : avg_complete.toFixed(2)}</Row>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <br/>
                                        <div className={s.players}>
                                            {players.map((name, index) => {
                                                    const find_data_by_userName = newData[name].data[key]

                                                    return (
                                                        <div className={s.test} key={`players${index}`}>
                                                            <Row min={100} max={100} isCategory={true}
                                                                 align={'center'}>{name}</Row>
                                                            <br/>
                                                            {find_data_by_userName?.map((value, index) => {
                                                                return <Row
                                                                    tooltip={value?.tooltip_value}
                                                                    hidden={!Boolean(schema_data[key].find(f => f.name === value.name))}
                                                                    key={`players_value_${index}`} min={100} max={100}
                                                                    align={'center'}>{value.value}</Row>
                                                            })}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>

                                        <br/>
                                    </div>
                                </div>
                            }
                        </div>
                    })}
                </div>
            </div>
        </>
    );
};

export default Table2;
