import React, {useState, useRef} from "react";
import {NavLink} from "react-router-dom";
import ProfileMTTContainer from "../User_Profile_MTT/profileContainer";

export default function Users(props) {
    let countOfVisible = 0;
    if (props.SupportInfo.usersCustomInfoTemplates[0])
        countOfVisible = props.SupportInfo.usersCustomInfoTemplates.filter((v) => (v.isVisible === true)).length;


    return (
        <div className="support-users-list">
            <div className={"support-users-list-header"}
                 style={{gridTemplateColumns: `1fr 1fr 1fr ${"1fr ".repeat(countOfVisible)}`}}>
                <div className={"item"}>
                    Никнейм
                </div>
                <div className={"item"}>
                    Направление
                </div>
                <div className={"item"}>
                    Email
                </div>
                {
                    props.SupportInfo.usersCustomInfoTemplates.map((template, i) => {
                        if (template.isVisible) return <div key={template.en_name + `${i}`}>{template.ru_name}</div>
                    })
                }
            </div>

            {
                props.SupportInfo.usersListFilter.map((user, i) => <UserRow key={`${user.id} ${i}`}
                                                                            user={user} countOfVisible={countOfVisible}
                                                                            templates={props.SupportInfo.usersCustomInfoTemplates}/>)
            }
        </div>
    )
}

const UserRow = (props) => {
    let generatedInfos = [];
    props.templates.forEach(t => {
        props.user.custom_info.forEach(info => {
            if (t.en_name == info.en_name && t.isVisible) generatedInfos.push(info.data)
        })
    })

    return (
            <NavLink className={"support-users-list-items"}
                     to={`/support/users/user/${props.user.id}/${props.user.direction}`}
                     style={{gridTemplateColumns: `1fr 1fr 1fr ${"1fr ".repeat(props.countOfVisible)}`}}>
                <div className={"item"}>
                    {props.user.username}
                </div>
                <div className={"item"}>
                    {props.user.direction}
                </div>
                <div className={"item"}>
                    {props.user.email}
                </div>
                {
                    generatedInfos.map(info => {
                        return <div className={"item"}>
                            {info}
                        </div>
                    })
                }
            </NavLink>
    )
}