import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import { withRouter } from "react-router-dom";
import Main from "./routers";
import Cookies from "js-cookie";
import {GetProfile} from "../../redux/reducers/authReducer";

class RoutersContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        let cookieId = Cookies.get('id');
        let cookieToken = Cookies.get('token');
        let cookieUsername = Cookies.get('username');
        let cookieAvatar = Cookies.get('avatar');
        let cookieEmail = Cookies.get('email');

        if (cookieId && cookieToken && cookieUsername && cookieAvatar && cookieEmail) {
            this.props.GetProfile(cookieId, cookieEmail, cookieToken)
        }
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <Main {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    AuthInfo: state.mainReducer
})

export default compose(
    connect(mapStateToProps, {
        GetProfile
    }),
    withRouter
)(RoutersContainer)