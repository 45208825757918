import React, {useState, useRef} from "react";

export default function ChangerBanks(props) {
    let editEurInDollar = React.createRef();
    let initedEurInDollar;
    let initedEurInLastUpdate;
    if (props.FsChangerInfo.adminBanksList[0]) {
        props.FsChangerInfo.adminBanksList.forEach(e => {
            if (e.public_name == "fs_eur_to_dollar") {
                initedEurInDollar = e.fs_sell_min;
                initedEurInLastUpdate = e.exchange_rates_updated;
            }
        })
    }
    const EditEurInDollar = () => {
        props.AdminChangeServerConverter("fs_eur_to_dollar", editEurInDollar.current.value)
    }

    return (
        <div className={"changer-banks-page"}>
            <div className={"changer-banks-converters"}>
                <div>
                    Заданный курс евро к доллару: <input type="number" ref={editEurInDollar} onBlur={EditEurInDollar}
                                                         defaultValue={initedEurInDollar}/>
                    <span style={{marginLeft: "13px"}}>(актуальность курса: {initedEurInLastUpdate ? initedEurInLastUpdate : "не обновлялось"})</span>
                </div>
            </div>
            <div className={"columns-names"}>
                <div className={"column-name"}>
                    Название
                </div>
                <div className={"column-name"}>
                    Тип
                </div>
                <div className={"column-name"}>
                    usd
                </div>
                <div className={"column-name"}>
                    Актуальность курса
                </div>
                <div className={"column-name"}>
                    usd_p
                </div>
                <div className={"column-name"}>
                    fs_usd_p
                </div>
                <div className={"column-name"}>
                    .png logo URL
                </div>
                <div className={"column-name"}>
                    Подсказка
                </div>
            </div>
            <div className={"changer-banks-list"}>
                {
                    props.FsChangerInfo.adminBanksList.map((bank, i) => {
                        if (bank.public_name == "fs_eur_to_dollar") return null;
                        return <Bank key={i + bank.public_name + bank.public_type}
                                     bank={bank} AdminDeleteBank={props.AdminDeleteBank}
                                     AdminEditBank={props.AdminEditBank}/>
                    })
                }
            </div>
        </div>
    );
}

const Bank = (props) => {
    let editPublicSell = React.createRef();
    let editPublicPercent = React.createRef();
    let editFsSellMin = React.createRef();
    let editFsSellMax = React.createRef();
    let editFsSellPercent = React.createRef();
    let editFsCardHelp = React.createRef();
    let editFsPngLogo = React.createRef();

    const EditPublicSell = () => {
        props.AdminEditBank({
            public_type: props.bank.public_type,
            public_name: props.bank.public_name,
            type: "public_sell",
            newValue: editPublicSell.current.value
        })
    }
    const EditPublicPercent = () => {
        props.AdminEditBank({
            public_type: props.bank.public_type,
            public_name: props.bank.public_name,
            type: "public_percent",
            newValue: editPublicPercent.current.value
        })
    }
    const EditFsSellMin = () => {
        props.AdminEditBank({
            public_type: props.bank.public_type,
            public_name: props.bank.public_name,
            type: "fs_sell_min",
            newValue: editFsSellMin.current.value
        })
    }
    const EditFsSellMax = () => {
        props.AdminEditBank({
            public_type: props.bank.public_type,
            public_name: props.bank.public_name,
            type: "fs_sell_max",
            newValue: editFsSellMax.current.value
        })
    }
    const EditFsSellPercent = () => {
        props.AdminEditBank({
            public_type: props.bank.public_type,
            public_name: props.bank.public_name,
            type: "fs_percent",
            newValue: editFsSellPercent.current.value
        })
    }
    const EditFsPngLogo = () => {
        props.AdminEditBank({
            public_type: props.bank.public_type,
            public_name: props.bank.public_name,
            type: "png_logo",
            newValue: editFsPngLogo.current.value
        })
    }
    const EditFsCardHelp = () => {
        props.AdminEditBank({
            public_type: props.bank.public_type,
            public_name: props.bank.public_name,
            type: "card_help",
            newValue: editFsCardHelp.current.value
        })
    }
    const Delete = () => {
        props.AdminDeleteBank(props.bank.public_type, props.bank.public_name)
    }

    return (
        <div className={"bank"}>
            <div className={"name"}>
                {props.bank.public_name}
            </div>
            <div className={"name"}>
                {props.bank.public_type}
            </div>
            <div className={"column-data"}>
                <input type="number" ref={editPublicSell} onBlur={EditPublicSell}
                       defaultValue={props.bank.public_sell}/>
            </div>
            <div className={"name"}>
                {props.bank.exchange_rates_updated ?
                    props.bank.exchange_rates_updated : "Не обновлялось"}
            </div>
            <div className={"column-data"}>
                <input type="number" ref={editPublicPercent} onBlur={EditPublicPercent}
                       defaultValue={props.bank.public_percent}/>
            </div>
            <div className={"column-data"}>
                <input type="number" ref={editFsSellPercent} onBlur={EditFsSellPercent}
                       defaultValue={props.bank.fs_percent}/>
            </div>
            <div className={"column-data"}>
                <textarea rows={1} ref={editFsPngLogo} onBlur={EditFsPngLogo} defaultValue={props.bank.png_logo}/>
            </div>
            <div className={"column-data"}>
                <textarea rows={1} ref={editFsCardHelp} onBlur={EditFsCardHelp} defaultValue={props.bank.card_help}/>
            </div>
            <div onClick={Delete} className={"deleteBank"}>
                удалить банк
            </div>
        </div>
    )
}