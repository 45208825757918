import React, {useState} from 'react';
import Select from "react-dropdown-select";
import {EditWelcomeMessageInfo, RemoveWelcomeMessageInfo} from "../../../../redux/reducers/supportReducer";

const WelcomeMessageEditor = (props) => {
    const [selectedButton, setSelectedButton] = useState(1)
    return (
        <div style={{color: "white"}}>
            <div style={{display: "grid", gridTemplateColumns: "1fr 200px 10px 200px 1fr"}}>
                <div></div>
                <div onClick={() => setSelectedButton(1)}
                     className={`button default-button ${selectedButton == 1 ? "green-back" : "default-back"}`}>Существующие
                    правила
                </div>
                <div></div>
                <div onClick={() => setSelectedButton(2)}
                     className={`button default-button ${selectedButton == 2 ? "green-back" : "default-back"}`}>Новое
                    правило
                </div>
                <div></div>
            </div>

            {
                selectedButton == 1 &&
                <div>
                    <Editor {...props}/>
                </div>
            }

            {
                selectedButton == 2 &&
                <div>
                    <Creator {...props}/>
                </div>
            }
        </div>
    );
};

const Editor = (props) => {
    const [selectedWelcome, setSelectedWelcome] = useState(null);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");

    const handleSetSelectedWelcome = (welcome) => {
        setSelectedWelcome(welcome);
        setMessage1(welcome.message_1);
        setMessage2(welcome.message_2);
    }

    const handleEditRule = () => {
        props.EditWelcomeMessageInfo(message1, message2, selectedWelcome.role_id, selectedWelcome.guild_id)
    }

    const handleRemoveRule = () => {
        setSelectedWelcome(null);
        props.RemoveWelcomeMessageInfo(selectedWelcome.guild_id, selectedWelcome.role_id)
    }

    return (
        <div style={{color: "white", marginTop: "8px"}}>
            {
                props.SupportInfo.welcome_config && props.SupportInfo.welcome_config[0] &&
                <div style={{backgroundColor: "#1d1d25", padding: "8px 10px", borderRadius: "10px"}}>


                    <div style={{display: "grid", gridTemplateColumns: "1fr 300px 10px 300px 10px 300px 1fr"}}>
                        <div></div>
                        <div>Server</div>
                        <div></div>
                        <div>Role</div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    {
                        props.SupportInfo.welcome_config.map(welcome => {
                            return <div
                                key={`${welcome.guild_name}${welcome.role_name}`}
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 300px 10px 300px 10px 300px 1fr",
                                    marginTop: "2px"
                                }}>
                                <div></div>
                                <div>{welcome.guild_name}</div>
                                <div></div>
                                <div>{welcome.role_name}</div>
                                <div></div>
                                <div style={{color: "#0b95d1", textDecoration: "underline white", cursor: "pointer"}}
                                     onClick={() => handleSetSelectedWelcome(welcome)}>Изменить
                                </div>
                                <div></div>
                            </div>
                        })
                    }
                </div>
            }

            {
                selectedWelcome &&
                <div key={`${selectedWelcome.role_id}${selectedWelcome.guild_id}`} style={{backgroundColor: "#232531", padding: "8px 10px", borderRadius: "10px", marginTop: "8px"}}>
                    <div style={{textAlign: "center", fontWeight: "bolder"}}>
                        Редактирование <span style={{color: "#0b95d1"}}>{selectedWelcome.guild_name}</span> | <span
                        style={{color: "#0b95d1"}}>{selectedWelcome.role_name}</span> | Task Channel: <span
                        style={{color: "#0b95d1"}}>{selectedWelcome.task_channel_id}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{color: "#d75656", textDecoration: "underline white", cursor: "pointer"}}
                              onClick={handleRemoveRule}>Удалить правило</span>
                    </div>

                    <div style={{marginTop: "20px", textAlign: "left"}}>
                        <div>Первое сообщение (максимум символов: 1800)</div>
                        <textarea
                            defaultValue={message1} onBlur={(e) => setMessage1(e.target.value)}
                            style={{
                                width: "100%", backgroundColor: "#1d1d25", marginTop: "10px",
                                color: "white", outline: "none", border: "none", borderRadius: "5px"
                            }} cols="30" rows="18" maxLength="1800"/>
                    </div>
                    <div style={{marginTop: "8px", textAlign: "left"}}>
                        <div>Второе сообщение (максимум символов: 1800)</div>
                        <textarea
                            defaultValue={message2} onBlur={(e) => setMessage2(e.target.value)}
                            style={{
                                width: "100%", backgroundColor: "#1d1d25", marginTop: "10px",
                                color: "white", outline: "none", border: "none", borderRadius: "5px"
                            }} cols="30" rows="18" maxLength="1800"/>
                    </div>

                    <div style={{display: "grid", gridTemplateColumns: "1fr 300px 1fr"}}>
                        <div></div>
                        <div onClick={handleEditRule}
                             className={"button default-button green-back"}
                             style={{
                                 marginTop: "10px",
                                 width: "300px",
                                 textAlign: "center"
                             }}
                        >
                            Сохранить
                        </div>
                        <div></div>
                    </div>
                </div>
            }
        </div>
    )
}

const GuildSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите сервер "}
            labelField={"name"}
            valueField={"name"}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                if (value && value[0]) props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#4c516a",
                margin: "8px 0",
                fontSize: "19px",
                border: "none",
                paddingLeft: "11px",
                zIndex: "110"
            }}
        />
    )
}
const RoleSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите роль "}
            labelField={"name"}
            valueField={"name"}
            options={props.options}
            values={props.selected}
            onChange={(value) => {
                if (value && value[0]) props.handleSelect(value)
            }
            }
            style={{
                backgroundColor: "#4c516a",
                margin: "8px 0",
                fontSize: "19px",
                border: "none",
                paddingLeft: "11px",
                zIndex: "109"
            }}
        />
    )
}
const Creator = (props) => {
    const [selectedRole, setSelectedRole] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedGuild, setSelectedGuild] = useState([]);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const [taskChannelId, setTaskChannelId] = useState("");

    const handleSelectGuild = (e) => {
        if (e && e[0]) {
            setSelectedGuild(e);
            setRoles(e[0].roles)
        } else {
            setSelectedGuild([]);
            setSelectedRole([]);
        }
    }
    const handleSelectRole = (e) => {
        if (e && e[0]) {
            setSelectedRole(e);
        } else {
            setSelectedRole([]);
        }
    }

    const handleCreateRule = () => {
        props.AddWelcomeMessageInfo(selectedGuild[0] ? selectedGuild[0] : null, selectedRole[0] ? selectedRole[0] : null, message1, message2, taskChannelId)
    }

    return (
        <div style={{marginBottom: "50px"}}>
            <GuildSelector options={props.SupportInfo.welcome_guilds}
                           handleSelect={handleSelectGuild} selected={selectedGuild}/>
            <RoleSelector options={roles}
                          handleSelect={handleSelectRole} selected={selectedRole}/>

            <div style={{color: "white", textAlign: "left"}}>
                <div>
                    <div>Первое сообщение (максимум символов: 1800)</div>
                    <textarea
                        defaultValue={message1} onBlur={(e) => setMessage1(e.target.value)}
                        style={{
                            width: "100%", backgroundColor: "#1d1d25", marginTop: "10px",
                            color: "white", outline: "none", border: "none", borderRadius: "5px"
                        }} cols="30" rows="18" maxLength="1800"/>
                </div>
                <div style={{marginTop: "8px"}}>
                    <div>Второе сообщение (максимум символов: 1800)</div>
                    <textarea
                        defaultValue={message2} onBlur={(e) => setMessage2(e.target.value)}
                        style={{
                            width: "100%", backgroundColor: "#1d1d25", marginTop: "10px",
                            color: "white", outline: "none", border: "none", borderRadius: "5px"
                        }} cols="30" rows="18" maxLength="1800"/>
                </div>
                <div style={{marginTop: "8px"}}>
                    <div>ID канала для саппортов (в случае ошибок будет отправляться таск в этот канал)</div>
                    <input
                        defaultValue={taskChannelId} onBlur={(e) => setTaskChannelId(e.target.value)}
                        style={{
                            width: "250px", backgroundColor: "#1d1d25", marginTop: "10px", padding: "5px 0",
                            color: "white", outline: "none", border: "none", borderRadius: "5px"
                        }}/>
                </div>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "1fr 300px 1fr"}}>
                <div></div>
                <div onClick={handleCreateRule}
                     className={"button default-button green-back"}
                     style={{
                         marginTop: "10px",
                         width: "300px",
                         textAlign: "center"
                     }}
                >
                    Создать
                </div>
                <div></div>
            </div>

        </div>
    )
}

export default WelcomeMessageEditor;