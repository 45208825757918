import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";
import {C_SUPPORTS_API, FORMULAR_ROWS_API, PROFILE_CUSTOM_INFO_API, SUPPORTS_API, TRAINING_API} from "../../api/util";
import Cookies from "js-cookie";
import usersList from "../../v_1/components/Salary/Admin/usersList";

const SET_SUPPORTS_ACCESSES_LIST = 'SET_SUPPORTS_ACCESSES_LIST';
const CHANGE_SUPPORT_ACCESS = 'CHANGE_SUPPORT_ACCESS';
const SET_TT_LIST = 'SET_TT_LIST';
const CHANGE_STATUSES_FILTER = 'CHANGE_STATUSES_FILTER';
const CHANGE_SERVERS_FILTER = 'CHANGE_SERVERS_FILTER';
const TT_SUPPORT_CHANGE_TARGETS_FILTER = 'TT_SUPPORT_CHANGE_TARGETS_FILTER';
const UPDATE_TT_STATUS = 'UPDATE_TT_STATUS';
const SUPPORT_SET_USERS_LIST = 'SUPPORT_SET_USERS_LIST';
const SUPPORT_SET_CURRENT_USER = 'SUPPORT_SET_CURRENT_USER';
const SUPPORT_CHANGE_USERS_LIST_FILTER = 'SUPPORT_CHANGE_USERS_LIST_FILTER';
const SUPPORT_SET_COACHES_LIST = 'SUPPORT_SET_COACHES_LIST';
const C_SUPPORT_SET_SPIN_POOL_DATA = 'C_SUPPORT_SET_SPIN_POOL_DATA';
const SUPPORT_MTT_TOURNAMENTS_GROUP_FILTER_SET = 'SUPPORT_MTT_TOURNAMENTS_GROUP_FILTER_SET';
const SUPPORT_MTT_TOURNAMENTS_LIST_SET = 'SUPPORT_MTT_TOURNAMENTS_LIST_SET';
const SUPPORT_REMOVE_MTT_TOURNAMENT_BY_ID = 'SUPPORT_REMOVE_MTT_TOURNAMENT_BY_ID';
const SUPPORT_SET_CURRENT_USER_LOGS = 'SUPPORT_SET_CURRENT_USER_LOGS';
const SUPPORT_SET_WELCOME_MESSAGE_INFO = 'SUPPORT_SET_WELCOME_MESSAGE_INFO';
const SUPPORT_UPDATE_WELCOME_MESSAGE_INFO = 'SUPPORT_UPDATE_WELCOME_MESSAGE_INFO';
const FORMULAR_SUPPORT_MTT_SET_CONTRACTS_ROWS = 'FORMULAR_SUPPORT_MTT_SET_CONTRACTS_ROWS';

let initialState = {
    accessesList: [],
    currentType: null,
    TTList: [], // tasks or tickets list
    TTCount: null,
    TTCurrentCount: null,
    servers: [],
    statuses: [],
    targets: [],

    usersList: [],
    usersListFilter: [],
    usersCustomInfoTemplates: [],
    currentUser: null,
    currentUserLogs: [],
    coachesList: [],
    spinPoolData: [],


    mttTournamentsList: [],
    mttTournamentsListSave: [],
    mttTournamentsGroupFilter: [],

    mttTournamentsSelectedGroupFilter: [],
    coachesWithPlayers: [],

    welcome_config: [],
    welcome_guilds: []
};

const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPORT_UPDATE_WELCOME_MESSAGE_INFO: {
            return {
                ...state,
                welcome_config: JSON.parse(JSON.stringify(action.welcome_config))
            }
        }
        case SUPPORT_SET_WELCOME_MESSAGE_INFO: {
            return {
                ...state,
                welcome_config: action.data.welcome_config,
                welcome_guilds: action.data.guilds
            }
        }
        case SUPPORT_SET_CURRENT_USER_LOGS: {
            return {
                ...state,
                currentUserLogs: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SUPPORT_REMOVE_MTT_TOURNAMENT_BY_ID: {
            let new_mttTournamentsList = JSON.parse(JSON.stringify(state.mttTournamentsList));
            let new_mttTournamentsListSave = JSON.parse(JSON.stringify(state.mttTournamentsListSave));

            let index1 = new_mttTournamentsList.findIndex(e => e._id == action._id);
            let index2 = new_mttTournamentsListSave.findIndex(e => e._id == action._id);

            if (index1 != -1) {
                new_mttTournamentsList.splice(index1, 1);
            }
            if (index2 != -1) {
                new_mttTournamentsListSave.splice(index2, 1);
            }

            return {
                ...state,
                mttTournamentsList: new_mttTournamentsList,
                mttTournamentsListSave: new_mttTournamentsListSave
            }
        }
        case SUPPORT_MTT_TOURNAMENTS_LIST_SET: {
            let r1 = JSON.parse(JSON.stringify(action.data));

            let GroupFilter;

            if (r1 && r1[0]) {
                const uniqueData1 = r1.reduce((accumulator, current) => {
                    if (!accumulator[current.coach_group]) {
                        accumulator[current.coach_group] = current;
                    }
                    return accumulator;
                }, {});
                GroupFilter = Object.values(uniqueData1).filter(e => e.coach_group?.length >= 2);
            }

            return {
                ...state,
                mttTournamentsList: JSON.parse(JSON.stringify(action.data)),
                mttTournamentsListSave: JSON.parse(JSON.stringify(action.data)),

                mttTournamentsGroupFilter: GroupFilter ? GroupFilter : [],
                mttTournamentsSelectedGroupFilter: [],
            }
        }
        case SUPPORT_MTT_TOURNAMENTS_GROUP_FILTER_SET: {
            let data = JSON.parse(JSON.stringify(state.mttTournamentsListSave));

            let currentFilters = action.data;

            let filteredData;

            if (state.mttTournamentsListSave && state.mttTournamentsListSave[0]) {
                filteredData = data.filter(item => {
                    const hasGroupName = currentFilters.length === 0 || currentFilters.some(group => group.coach_group === item.coach_group);
                    return hasGroupName;
                });
            }

            return {
                ...state,
                mttTournamentsList: filteredData ? JSON.parse(JSON.stringify(filteredData)) : JSON.parse(JSON.stringify(state.mttTournamentsListSave)),

                mttTournamentsSelectedGroupFilter: action.data,
            }
        }
        case C_SUPPORT_SET_SPIN_POOL_DATA: {
            return {
                ...state,
                spinPoolData: JSON.parse(JSON.stringify(action.data))
            }
        }
        case SUPPORT_SET_COACHES_LIST: {
            return {
                ...state,
                coachesList: JSON.parse(JSON.stringify(action.list))
            }
        }
        case SUPPORT_CHANGE_USERS_LIST_FILTER: {
            if (action.subType == "direction") {
                if (action.value == 'Все') {
                    return {
                        ...state,
                        usersListFilter: [...state.usersList]
                    }
                } else {
                    let newUsersList = JSON.parse(JSON.stringify(state.usersList));
                    newUsersList = newUsersList.filter(e => e.direction == action.value);
                    return {
                        ...state,
                        usersListFilter: [...newUsersList]
                    }
                }
            }
        }
        case SUPPORT_SET_USERS_LIST: {
            return {
                ...state,
                usersList: JSON.parse(JSON.stringify(action.profiles)),
                usersListFilter: JSON.parse(JSON.stringify(action.profiles)),
                coachesWithPlayers: JSON.parse(JSON.stringify(action.coachesWithPlayers)),
                usersCustomInfoTemplates: JSON.parse(JSON.stringify(action.templates.reverse()))
            }
        }
        case SUPPORT_SET_CURRENT_USER: {
            let updatedUsers = undefined;
            if (action.isContract) {
                let i = state.usersList.findIndex(e => e.id == action.profile.id);
                if (i != -1) {
                    updatedUsers = JSON.parse(JSON.stringify(state.usersList));
                    updatedUsers[i] = JSON.parse(JSON.stringify(action.profile))
                }
            }
            return {
                ...state,
                usersList: updatedUsers ? updatedUsers : state.usersList,
                currentUser: JSON.parse(JSON.stringify(action.profile)),
                usersCustomInfoTemplates: JSON.parse(JSON.stringify(action.templates.reverse()))
            }
        }
        case UPDATE_TT_STATUS: {
            let newTTList = [...state.TTList];
            if (action.body.status == "new") {
                return {
                    ...state,
                    TTList: [action.body, ...state.TTList],
                    TTCount: +newTTList.length + 1,
                    TTCurrentCount: +state.TTCurrentCount + 1
                }
            } else {
                let elementToChangeIndex = newTTList.findIndex(e => e.timestamp_creation == action.body.timestamp_creation);
                if (elementToChangeIndex == -1) {
                    return state;
                } else {
                    newTTList[elementToChangeIndex] = {...action.body};
                    if (!state.statuses.includes(action.body.status)) {
                        newTTList.splice(elementToChangeIndex, 1)
                    }
                    return {
                        ...state,
                        TTList: [...newTTList]
                    }
                }
            }
        }
        case TT_SUPPORT_CHANGE_TARGETS_FILTER: {
            if (state.targets.includes(action.target)) {
                let targetIndex = state.targets.findIndex(e => e == action.target)
                let newTargets = [...state.targets]
                newTargets.splice(targetIndex, 1);
                return {
                    ...state,
                    targets: [...newTargets]
                }
            } else {
                return {
                    ...state,
                    targets: [...state.targets, action.target]
                }
            }
        }
        case CHANGE_STATUSES_FILTER: {
            if (state.statuses.includes(action.status)) {
                let statusIndex = state.statuses.findIndex(e => e == action.status)
                let newStatuses = [...state.statuses]
                newStatuses.splice(statusIndex, 1);
                return {
                    ...state,
                    statuses: [...newStatuses]
                }
            } else {
                return {
                    ...state,
                    statuses: [...state.statuses, action.status]
                }
            }
        }
        case CHANGE_SERVERS_FILTER: {
            if (state.servers.includes(action.server)) {
                let serverIndex = state.servers.findIndex(e => e == action.server)
                let newServers = [...state.servers]
                newServers.splice(serverIndex, 1);
                return {
                    ...state,
                    servers: [...newServers]
                }
            } else {
                return {
                    ...state,
                    servers: [...state.servers, action.server]
                }
            }
        }
        case SET_TT_LIST: {
            if (action.changeType == "set") {
                return {
                    ...state,
                    TTList: [...action.list],
                    TTCount: action.count,
                    TTCurrentCount: 50
                }
            } else if (action.changeType == "new") {
                return {
                    ...state,
                    TTList: [...action.list],
                    TTCount: action.count,
                    TTCurrentCount: action.list.length
                }
            } else {
                return {
                    ...state,
                    TTList: [...state.TTList, ...action.list],
                    TTCount: action.count,
                    TTCurrentCount: state.TTCurrentCount + 50
                }
            }
        }
        case SET_SUPPORTS_ACCESSES_LIST:
            return {
                ...state,
                accessesList: [...action.list]
            }
        case CHANGE_SUPPORT_ACCESS: {
            let accessIndex = state.accessesList.findIndex(e => e.name === action.access.name)
            let newAccessesList = [...state.accessesList];
            newAccessesList[accessIndex] = {...action.access};
            return {
                ...state,
                accessesList: [...newAccessesList]
            }
        }
        default:
            return state;
    }
};

const SetSupportsAccessesList = (list) => ({type: SET_SUPPORTS_ACCESSES_LIST, list});
const ChangeSupportAccess = (access) => ({type: CHANGE_SUPPORT_ACCESS, access})
const SetTTList = (list, changeType, count) => ({type: SET_TT_LIST, list, changeType, count})
const ChangeStatusesFilterAC = (status) => ({type: CHANGE_STATUSES_FILTER, status})
const ChangeServersFilterAC = (server) => ({type: CHANGE_SERVERS_FILTER, server})
const ChangeTargetFilterAC = (target) => ({type: TT_SUPPORT_CHANGE_TARGETS_FILTER, target })
export const UpdateTTStatus = (body) => ({type: UPDATE_TT_STATUS, body })

export const ChangeUsersListFilter = (subType, value) => ({type: SUPPORT_CHANGE_USERS_LIST_FILTER, subType, value})


const SupportMttTournamentsGroupFilterSetAC = (data) => ({type: SUPPORT_MTT_TOURNAMENTS_GROUP_FILTER_SET, data})
export const SupportMttTournamentsGroupFilterSet = (data) => {
    return dispatch => {
        dispatch(SupportMttTournamentsGroupFilterSetAC(data))
    }
}

const SupportRemoveMttTournamentByIdAC = (_id) => ({type: SUPPORT_REMOVE_MTT_TOURNAMENT_BY_ID, _id});
export const SupportRemoveMttTournamentById = (_id) => {
    return dispatch => {
        C_SUPPORTS_API.removeMttTournamentById(_id)
            .then(data => {
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Удаление турнира", data.message))
                    return;
                }
                dispatch(SupportRemoveMttTournamentByIdAC(_id))
            })
    }
}

const SupportMttTournamentsListSetAC = (data) => ({type: SUPPORT_MTT_TOURNAMENTS_LIST_SET, data})
export const SupportMttTournamentsGet = (date_from, date_to) => {
    return dispatch => {
        dispatch(ChangeMainLoading(true))
        C_SUPPORTS_API.getMttTournamentsByPeriod(date_from, date_to)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Получение турниров", data.message))
                    return;
                }
                dispatch(SupportMttTournamentsListSetAC(data))
            })
    }
}

export const SupportMttTournamentsExportToGoogleTable = (export_data) => {
    return dispatch => {
        let body = {
            token: Cookies.get("token"), export_data
        }
        dispatch(ChangeMainLoading(true))
        C_SUPPORTS_API.MttTournamentsExportToGoogleTable(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data && data.resultCode) {
                    dispatch(AddNotification("error", "Export to google table", data.message))
                    return;
                }
            })
    }
}

const C_SetSpinPoolData = (data) => ({type: C_SUPPORT_SET_SPIN_POOL_DATA, data});
export const C_SupportGetSpinPoolDate = (period) => {
    return dispatch => {
        C_SUPPORTS_API.getSpinPoolDataByPeriod(period)
            .then(data=> {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение данных`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(C_SetSpinPoolData(data))
            })
    }
}

const SupportSetCoachesList = (list) => ({type: SUPPORT_SET_COACHES_LIST, list})
export const SupportGetCoachesList = () => {
    return (dispatch) => {
        PROFILE_CUSTOM_INFO_API.supportGetCoachesList()
            .then(data=> {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка тренеров`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportSetCoachesList(data))
            })
    }
}
const SupportSetUsersList = (profiles, templates, coachesWithPlayers) => ({type: SUPPORT_SET_USERS_LIST, profiles, templates, coachesWithPlayers});
export const SupportGetUsersList = () => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        PROFILE_CUSTOM_INFO_API.supportGetUsersList()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка пользователей`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportSetUsersList(data.profiles, data.templates, data.coachesWithPlayers))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SupportUpdateUserCoach = (type, coach_id, coach_name, user_direction, user_id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            type, coach_id, coach_name, user_direction, user_id
        }
        PROFILE_CUSTOM_INFO_API.supportUpdateUserCoach(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение данных о пользователе`, data.message))
                    return
                }
                dispatch(SupportSetCurrentUser(data.profile, data.templates))
            })
    }
}

const SupportSetCurrentUserLogs = (data) => ({type: SUPPORT_SET_CURRENT_USER_LOGS, data})
export const SupportGetUserLogs = (id) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        PROFILE_CUSTOM_INFO_API.supportGetEditLogs(id)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение логов`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportSetCurrentUserLogs([]))
                dispatch(SupportSetCurrentUserLogs(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}


const SupportSetCurrentUser = (profile, templates, isContract) => ({type: SUPPORT_SET_CURRENT_USER, profile, templates, isContract})
export const DispatchSupportSetCurrentUser = (profile, templates, isContract) => {
    return dispatch => {
        dispatch(SupportSetCurrentUser(profile, templates, isContract))
    }
}

export const SupportGetUser = (id, direction) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        PROFILE_CUSTOM_INFO_API.supportGetUser(id, direction)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение данных о пользователе`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportSetCurrentUser(null, []))
                dispatch(SupportSetCurrentUser(data.profile, data.templates))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SupportEditUser = (id, direction, type, newValue) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            id, type, newValue, direction,
            token: Cookies.get('token')
        }
        PROFILE_CUSTOM_INFO_API.supportEditUser(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение данных о пользователе`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SupportSetCurrentUser(data.profile, data.templates))
                dispatch(ChangeMainLoading(false))
            })
    }
}

export const SupportEditUserMTT = (id, direction, mainType, type, subType, newValue, isContract = false) => {
    return (dispatch) => {
        let body = {
            id, type, newValue, direction, mainType, subType,
            token: Cookies.get('token')
        }
        PROFILE_CUSTOM_INFO_API.supportEditUserMTT(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение данных о пользователе MTT профайл`, data.message))
                    return
                }
                dispatch(SupportSetCurrentUser(data.profile, data.templates, isContract))
            })
    }
}

export const SupportEditUserSPIN = (id, direction, mainType, type, subType, newValue) => {
    return (dispatch) => {
        let body = {
            id, type, newValue, direction, mainType, subType,
            token: Cookies.get('token')
        }
        PROFILE_CUSTOM_INFO_API.supportEditUserSPIN(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Изменение данных о пользователе SPIN профайл`, data.message))
                    return
                }
                dispatch(SupportSetCurrentUser(data.profile, data.templates))
            })
    }
}

export const ChangeTargetFilter = (target) => {
    return (dispatch) => {
        dispatch(ChangeTargetFilterAC(target));
    }
}
export const ChangeStatusesFilter = (status) => {
    return (dispatch) => {
        dispatch(ChangeStatusesFilterAC(status));
    }
}
export const ChangeServersFilter = (server) => {
    return (dispatch) => {
        dispatch(ChangeServersFilterAC(server));
    }
}
export const CloseTT = (ticket) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            ticket,
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token')
        }
        SUPPORTS_API.closeTT(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Отмена таска/тикета`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const GetTTList = (changeType, servers, statuses, targets, type, skipElements, limitElements) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let params = {
            params: {
                filters: {
                    servers, statuses, targets
                },
                info: {
                    id: Cookies.get('id'),
                    email: Cookies.get('email')
                },
                access_token: Cookies.get('token'),
                type: type,
                skipElements: skipElements,
                limitElements: limitElements
            }
        }
        SUPPORTS_API.getTTList(params)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", `Получение списка ${type}`, data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                if (changeType == 'set') {
                    dispatch(SetTTList([], changeType, null))
                    dispatch(SetTTList(data.tasks ? data.tasks : [], changeType, data.count ? data.count : null))
                } else {
                    dispatch(SetTTList(data.tasks ? data.tasks : [], changeType, data.count ? data.count : null))
                }
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const EditSupportAccess = (access_info) => {
    return (dispatch) => {
        if (access_info.newValue && isNaN(access_info.newValue)) {
            dispatch(AddNotification("error", "Редактор саппортов", `Поле ${access_info.access_name} должно быть в циферном формате`))
            return
        }
        dispatch(ChangeMainLoading(true))
        let body = {
            access_info,
            info: {
                id: Cookies.get('id'),
                email: Cookies.get('email')
            },
            access_token: Cookies.get('token')
        }
        SUPPORTS_API.edit(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Редактор саппортов", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(ChangeSupportAccess(data))
                dispatch(ChangeMainLoading(false))
                dispatch(AddNotification("success", `Редактор саппортов`, `Значение ${body.access_info.editType} для ${body.access_info.access_name} изменено`))
            })
    }
}
export const GetSupportsAccessesList = () => {
    return (dispatch) => {
        // dispatch(GetTTList())
        dispatch(ChangeMainLoading(true))
        SUPPORTS_API.getAccessesList()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Редактор саппортов", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SetSupportsAccessesList(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

const SetWelcomeMessageInfo = (data) => ({type: SUPPORT_SET_WELCOME_MESSAGE_INFO, data});

export const GetWelcomeMessageInfo = () => {
    return dispatch => {
        dispatch(ChangeMainLoading(true))
        C_SUPPORTS_API.getWelcomeMessageInfo()
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Редактор welcome message", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(SetWelcomeMessageInfo(data))
                dispatch(ChangeMainLoading(false))
            })
    }
}

const UpdateWelcomeMessageInfo = (welcome_config) => ({type: SUPPORT_UPDATE_WELCOME_MESSAGE_INFO, welcome_config});
export const AddWelcomeMessageInfo = (selectedGuild, selectedRole, message1, message2, taskChannelId) => {
    return dispatch => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            selectedGuild, selectedRole, message1, message2, taskChannelId
        };
        C_SUPPORTS_API.addWelcomeMessageInfo(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Добавление welcome message", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(UpdateWelcomeMessageInfo(data))
                dispatch(AddNotification("success", "Добавление welcome message", "Успешно"))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const EditWelcomeMessageInfo = (message_1, message_2, role_id, guild_id) => {
    return dispatch => {
        dispatch(ChangeMainLoading(true))
        let body = {
            token: Cookies.get("token"),
            message_1, message_2, role_id, guild_id
        };
        C_SUPPORTS_API.editWelcomeMessageInfo(body)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Редактирование welcome message", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(UpdateWelcomeMessageInfo(data))
                dispatch(AddNotification("success", "Редактирование welcome message", "Успешно"))
                dispatch(ChangeMainLoading(false))
            })
    }
}
export const RemoveWelcomeMessageInfo = (guildId, roleId) => {
    return dispatch => {
        dispatch(ChangeMainLoading(true))
        C_SUPPORTS_API.removeWelcomeMessageInfo(guildId, roleId)
            .then(data => {
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Удаление welcome message", data.message))
                    dispatch(ChangeMainLoading(false))
                    return
                }
                dispatch(UpdateWelcomeMessageInfo(data))
                dispatch(AddNotification("success", "Удаление welcome message", "Успешно"))
                dispatch(ChangeMainLoading(false))
            })
    }
}


const SupportMTTSetContractsRows = (data) => ({type: FORMULAR_SUPPORT_MTT_SET_CONTRACTS_ROWS, data});
export const SupportGetMTTContracts = () => {
    return (dispatch) => {
        FORMULAR_ROWS_API.supportGetMTTContracts()
            .then(data => {
                if (data.resultCode && data.resultCode != 1)
                    return dispatch(AddNotification("error", "Получение списка контрактов", data.message))
                dispatch(SupportSetUsersList(data, [], []))
            })
    }
}

export default supportReducer;

