import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import ContractsContent from "./contractsContent";

export default function UserProfileMTT(props) {
    const [selectedSubPage, setSelectedSubPage] = useState(props.isContract ? "contracts" : "main");

    let content = null;

    const user = props.SupportInfo.currentUser;

    const handleEditContracts = (arg_1, arg_2, arg_3, arg_4, newValue) => {
        console.log(arg_1, arg_2, arg_3, arg_4, newValue)
    }

    const handleEditProfile = (type, newValue) => {
        props.SupportEditUserMTT(user.id, user.direction, "main", type, null, newValue, props.isContract);
    }

    const handleEditRaps = (type, subType, newValue) => {
        // id, direction, mainType, type, subType, newValue
        props.SupportEditUserMTT(user.id, user.direction, "raps", type, subType, newValue, props.isContract);
    }

    const inputStyle = {
        padding: "2px 10px", width: "100vh",
        backgroundColor: "#313444", fontSize: "14px", color: "#dedede",
        border: "none"
    }

    const inputRapsStyle = {
        padding: "2px 10px",
        margin: "5px 10px 5px 0",
        backgroundColor: "#313444", fontSize: "14px", color: "#dedede",
        border: "none"
    }

    const nameStyle = {margin: "0 10px 5px 0"}

    if (selectedSubPage == "main") {
        content = <div style={{margin: '2% 7% 0 5%'}}>
            {
                !user.mtt_profile ?
                    <div style={{padding: "20px"}}>Игрок еще не инициализирован. Пожалуйста, подождите ~10-30 минут до
                        завершения
                        процесса.</div> :
                    <div style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexFlow: "wrap"
                    }}>
                        <div>
                            <div style={nameStyle}>Дата подачи заявки игроком в команду</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("date_join_request", e.target.value)}
                                   defaultValue={user?.mtt_profile?.date_join_request} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Дата вступления игрока в проект</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("date_join_mtt", e.target.value)}
                                   defaultValue={user?.mtt_profile?.date_join_mtt} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Ссылка на лид amoCRM</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("url_lead_amo_crm", e.target.value)}
                                   defaultValue={user?.mtt_profile?.url_lead_amo_crm} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Ссылка на конференцию переговоров</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("url_channel_conversation", e.target.value)}
                                   defaultValue={user?.mtt_profile?.url_channel_conversation} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Кто принимал игрока в команду, уровень 1</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("who_seller_lvl_1", e.target.value)}
                                   defaultValue={user?.mtt_profile?.who_seller_lvl_1} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Кто проводил продажу, уровень 2</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("who_seller_lvl_2", e.target.value)}
                                   defaultValue={user?.mtt_profile?.who_seller_lvl_2} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>ФИО</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("full_real_name", e.target.value)}
                                   defaultValue={user?.mtt_profile?.full_real_name} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Дата Рождения</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("birthday", e.target.value)}
                                   defaultValue={user?.mtt_profile?.birthday} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Семейное положение</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("marital_status", e.target.value)}
                                   defaultValue={user?.mtt_profile?.marital_status} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Есть дети</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("childs", e.target.value)}
                                   defaultValue={user?.mtt_profile?.childs} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Образование</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("education", e.target.value)}
                                   defaultValue={user?.mtt_profile?.education} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Наличие работы</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("work", e.target.value)}
                                   defaultValue={user?.mtt_profile?.work} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Профессия</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("profession", e.target.value)}
                                   defaultValue={user?.mtt_profile?.profession} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Страна проживания</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("country", e.target.value)}
                                   defaultValue={user?.mtt_profile?.country} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Город проживания</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("city", e.target.value)}
                                   defaultValue={user?.mtt_profile?.city} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Номер телефона игрока</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("phone_number_player", e.target.value)}
                                   defaultValue={user?.mtt_profile?.phone_number_player} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Номер телефона родственников, друзей</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("phone_number_guarantors", e.target.value)}
                                   defaultValue={user?.mtt_profile?.phone_number_guarantors} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Skype</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("skype", e.target.value)}
                                   defaultValue={user?.mtt_profile?.skype} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Telegram</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("telegram", e.target.value)}
                                   defaultValue={user?.mtt_profile?.telegram} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Discord</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("discord", e.target.value)}
                                   defaultValue={user?.mtt_profile?.discord} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>WhatsApp</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("whats_app", e.target.value)}
                                   defaultValue={user?.mtt_profile?.whats_app} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Viber</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("viber", e.target.value)}
                                   defaultValue={user?.mtt_profile?.viber} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Ссылка на GT</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("url_gt", e.target.value)}
                                   defaultValue={user?.mtt_profile?.url_gt} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Рабочая почта игрока Gmail.com</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("fs_mail", e.target.value)}
                                   defaultValue={user?.mtt_profile?.fs_mail} type="text"/>
                        </div>


                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>ID игрока в дискорде</div>
                            <div style={inputStyle}><span style={{color: "#e06666"}}>READ ONLY:</span> {user.id}</div>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>ID личной конференции игрока</div>
                            <div style={inputStyle}><span style={{color: "#e06666"}}>READ ONLY:</span> {user.channel_id}
                            </div>
                        </div>

                        {/*сюда надо инфу подтягивать */}


                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Итог по разбору базы на этапе приема игрока</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("result_first_stage_handhistory", e.target.value)}
                                   defaultValue={user?.mtt_profile?.result_first_stage_handhistory} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Стартовый аби</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("start_abi", e.target.value)}
                                   defaultValue={user?.mtt_profile?.start_abi} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Стартовый ранг</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("start_rank", e.target.value)}
                                   defaultValue={user?.mtt_profile?.start_rank} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Блок (куда определили игрока)</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("start_block", e.target.value)}
                                   defaultValue={user?.mtt_profile?.start_block} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Дополнительные рекомендации</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("additional_recommendation", e.target.value)}
                                   defaultValue={user?.mtt_profile?.additional_recommendation} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Дополнительная информация</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("additional_info", e.target.value)}
                                   defaultValue={user?.mtt_profile?.additional_info} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Ссылка на контракт игрока</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("url_contract", e.target.value)}
                                   defaultValue={user?.mtt_profile?.url_contract} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Дата подписания контракта</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("date_signature", e.target.value)}
                                   defaultValue={user?.mtt_profile?.date_signature} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Длительность контракта (месяцев)</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("contract_duration_months", e.target.value)}
                                   defaultValue={user?.mtt_profile?.contract_duration_months} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Длительность контракта (дистанция)</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("contract_duration_distance", e.target.value)}
                                   defaultValue={user?.mtt_profile?.contract_duration_distance} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Минимальная норма в месяц</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("min_rate_per_month", e.target.value)}
                                   defaultValue={user?.mtt_profile?.min_rate_per_month} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Продление</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("renewal", e.target.value)}
                                   defaultValue={user?.mtt_profile?.renewal} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>В каких фондах состоял</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("previous_funds", e.target.value)}
                                   defaultValue={user?.mtt_profile?.previous_funds} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Причина ухода с прошлых фондов</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("leave_reason_previous_funds", e.target.value)}
                                   defaultValue={user?.mtt_profile?.leave_reason_previous_funds} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Причина ухода из FS</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("leave_reason_fs", e.target.value)}
                                   defaultValue={user?.mtt_profile?.leave_reason_fs} type="text"/>
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <div style={nameStyle}>Есть/был арбитраж</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("was_arbitration", e.target.value)}
                                   defaultValue={user?.mtt_profile?.was_arbitration} type="text"/>
                        </div>

                        <div style={{marginTop: "20px", marginBottom: "40px"}}>
                            <div style={nameStyle}>С какого источника узнал про команду (реферал)</div>
                            <input style={inputStyle}
                                   onChange={(e) => handleEditProfile("referral", e.target.value)}
                                   defaultValue={user?.mtt_profile?.referral} type="text"/>
                        </div>
                    </div>
            }
        </div>;
    } else if (selectedSubPage == "raps") {
        content = <div style={{margin: '2% 7% 0 5%'}}>
            {
                !user.mtt_profile ?
                    <div style={{padding: "20px"}}>Игрок еще не инициализирован. Пожалуйста, подождите ~10-30
                        минут до завершения
                        процесса.</div> :
                    <div style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexFlow: "wrap",
                        width: "100%"
                    }}>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                            width: "100%",
                            textAlign: "left", marginBottom: "20px"
                        }}>
                            <div style={nameStyle}>Румы и платежные системы</div>
                            <div style={nameStyle}>Эл.почта</div>
                            <div style={nameStyle}>ID</div>
                            <div style={nameStyle}>Логин</div>
                            <div style={nameStyle}>Ник</div>
                            <div style={nameStyle}>Дополнительно</div>
                        </div>
                        {
                            user.mtt_rooms_and_payment_systems.map((el, i) => <div
                                    key={el.name}
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                                        width: "100%",
                                        textAlign: "left", marginBottom: "5px", borderBottom: "1px solid #888888"
                                    }}>
                                    <div style={{
                                        padding: "2px 10px",
                                        margin: "5px 10px 5px 0",
                                    }}>{el.name}</div>
                                    <input onChange={(e) => handleEditRaps("email", el.name, e.target.value)}
                                           style={inputRapsStyle} type="text" defaultValue={el.email}/>
                                    <input onChange={(e) => handleEditRaps("id", el.name, e.target.value)}
                                           style={inputRapsStyle} type="text" defaultValue={el.id}/>
                                    <input onChange={(e) => handleEditRaps("login", el.name, e.target.value)}
                                           style={inputRapsStyle} type="text" defaultValue={el.login}/>
                                    <input onChange={(e) => handleEditRaps("nickname", el.name, e.target.value)}
                                           style={inputRapsStyle} type="text" defaultValue={el.nickname}/>
                                    <input onChange={(e) => handleEditRaps("additional_info", el.name, e.target.value)}
                                           style={inputRapsStyle} type="text" defaultValue={el.additional_info}/>
                                </div>
                            )
                        }
                    </div>
            }
        </div>
    } else if (selectedSubPage == "contracts") {
        content = <ContractsContent user={user} handleEditContracts={handleEditContracts} nameStyle={nameStyle} inputStyle={inputStyle}/>
    }

    return <Popup key={`mtt_user_profile`}
                  trigger={
                      <div className={"btn default-back transition"}
                           style={{padding: "5px"}}>Профайл</div>
                  }
                  modal
                  contentStyle={{
                      width: "100%",
                      height: "100%",
                      margin: "0",
                      padding: 0,
                      backgroundColor: "#1e1e25"
                  }}
                  nested
    >
        {(close) => (
            <div style={{backgroundColor: "#1e1e25"}}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1px 1fr"}}>
                    <div style={{textAlign: "left", margin: "15px 5px 0 5px"}}>
                        {
                            <span
                                style={{
                                    color: 'white',
                                    backgroundColor: "rgb(54,140,211)",
                                    padding: "7px 30px 10px 30px",
                                    fontSize: "15px",
                                    cursor: "pointer"
                                }}
                                onClick={() => setSelectedSubPage("main")}>
                            Main Info
                        </span>
                        }

                        {
                            <span
                                style={{
                                    color: 'white',
                                    backgroundColor: "rgb(54,140,211)",
                                    padding: "7px 30px 10px 30px",
                                    fontSize: "15px",
                                    cursor: "pointer", marginLeft: "5px"
                                }}
                                onClick={() => setSelectedSubPage("raps")}>
                            Rooms and payment systems
                        </span>
                        }
                        <span
                            style={{
                                color: 'white',
                                backgroundColor: "rgb(54,140,211)",
                                padding: "7px 30px 10px 30px",
                                fontSize: "15px",
                                cursor: "pointer", marginLeft: "5px"
                            }}
                            onClick={() => setSelectedSubPage("contracts")}>
                            Contracts
                        </span>
                    </div>
                    <div></div>
                    <div style={{textAlign: "right", margin: "15px 5px 0 0"}}>
                    <span style={{
                        color: 'white',
                        backgroundColor: "rgb(206,77,77)",
                        padding: "7px 30px 10px 30px",

                        fontSize: "15px",
                        cursor: "pointer"
                    }}
                          onClick={close}>Закрыть</span>
                    </div>
                </div>


                {
                    content
                }
            </div>
        )}
    </Popup>
}