import React, {useState, useRef} from "react";
import MainLoading from "../common/mainLoading";
import AuditorsListSidebarContainer from "./AuditorsList/auditorsListSidebarContainer";
import {NavLink} from "react-router-dom";
import Popup from "reactjs-popup";
import ProfileCustomInfoSidebarContainer from "./ProfileCustomInfo/ProfileCustomInfoSidebarContainer";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
} from '@material-ui/pickers';
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import SidebarSchedulerContainer from "./Scheduler/sidebarSchedulerContainer";
import CoachTrainingsListSidebarContainer from "./coachTrainingsList.js/coachTrainingsListSidebarContainer";
import Select from "react-dropdown-select";
import moment from "moment";
import EditIcon from "../../images/edit_pencil.png";

const StartStackAnalyzeRooms = [
    {
        "id": 100,
        "abbr": "PS",
        "name": "PokerStars"
    },
    {
        "id": 200,
        "abbr": "PTY",
        "name": "Party"
    },
    {
        "id": 300,
        "abbr": "FTP",
        "name": "Full Tilt"
    },
    {
        "id": 400,
        "abbr": "IPN",
        "name": "iPoker"
    },
    {
        "id": 500,
        "abbr": "EVP",
        "name": "Everest"
    },
    {
        "id": 600,
        "abbr": "PR",
        "name": "OnGame"
    },
    {
        "id": 900,
        "abbr": "PCF",
        "name": "Pacific Poker"
    },
    {
        "id": 3300,
        "abbr": "9ST",
        "name": "9Stacks"
    },
    {
        "id": 1700,
        "abbr": "AP",
        "name": "Absolute Poker (pre-Cereus)"
    },
    {
        "id": 5400,
        "abbr": "ADA",
        "name": "Adda52"
    },
    {
        "id": 4500,
        "abbr": "AI",
        "name": "Allin"
    },
    {
        "id": 2600,
        "abbr": "BAR",
        "name": "Barriere"
    },
    {
        "id": 1400,
        "abbr": "BFR",
        "name": "Betfair"
    },
    {
        "id": 7500,
        "abbr": "BTO",
        "name": "BetOnline"
    },
    {
        "id": 4300,
        "abbr": "BGO",
        "name": "Bingo"
    },
    {
        "id": 1300,
        "abbr": "BDG",
        "name": "Bodog"
    },
    {
        "id": 800,
        "abbr": "ABS",
        "name": "Cereus Network"
    },
    {
        "id": 3400,
        "abbr": "CHI",
        "name": "Chico"
    },
    {
        "id": 6400,
        "abbr": "COP",
        "name": "COP"
    },
    {
        "id": 1500,
        "abbr": "CRL",
        "name": "Cryptologic Network"
    },
    {
        "id": 2500,
        "abbr": "ENT",
        "name": "Enet Network"
    },
    {
        "id": 2300,
        "abbr": "EL",
        "name": "Everleaf"
    },
    {
        "id": 4200,
        "abbr": "FP",
        "name": "FishPoker"
    },
    {
        "id": 3200,
        "abbr": "GGP",
        "name": "GG Poker"
    },
    {
        "id": 700,
        "abbr": "BMN",
        "name": "Boss Media"
    },
    {
        "id": 4600,
        "abbr": "IDN",
        "name": "IDN"
    },
    {
        "id": 2700,
        "abbr": "IGN",
        "name": "Ignition"
    },
    {
        "id": 1000,
        "abbr": "B2B",
        "name": "Entraction"
    },
    {
        "id": 7100,
        "abbr": "LPN",
        "name": "LatAmPokerNetwork"
    },
    {
        "id": 2100,
        "abbr": "MRG",
        "name": "Merge"
    },
    {
        "id": 1100,
        "abbr": "PNL",
        "name": "MicroGaming"
    },
    {
        "id": 2800,
        "abbr": "MG",
        "name": "People's Poker"
    },
    {
        "id": 2000,
        "abbr": "PKR",
        "name": "PKR"
    },
    {
        "id": 6100,
        "abbr": "PKB",
        "name": "PokerBaazi"
    },
    {
        "id": 4700,
        "abbr": "PBR",
        "name": "PokerBros"
    },
    {
        "id": 4100,
        "abbr": "PCM",
        "name": "PokerCommunity"
    },
    {
        "id": 6900,
        "abbr": "PHP",
        "name": "PokerHighPoker"
    },
    {
        "id": 3700,
        "abbr": "PKA",
        "name": "PokerKingAsia"
    },
    {
        "id": 2900,
        "abbr": "PM",
        "name": "PokerMaster"
    },
    {
        "id": 6800,
        "abbr": "PSP",
        "name": "PokerSaintPoker"
    },
    {
        "id": 4000,
        "abbr": "PTM",
        "name": "PokerTime"
    },
    {
        "id": 6500,
        "abbr": "PKI",
        "name": "Pokio"
    },
    {
        "id": 3500,
        "abbr": "PPP",
        "name": "PPPoker"
    },
    {
        "id": 7000,
        "abbr": "RAP",
        "name": "RAPoker"
    },
    {
        "id": 4400,
        "abbr": "RLP",
        "name": "RealPoker"
    },
    {
        "id": 3600,
        "abbr": "RD",
        "name": "RedDragon"
    },
    {
        "id": 1200,
        "abbr": "CAK",
        "name": "Cake"
    },
    {
        "id": 3100,
        "abbr": "RIO",
        "name": "RunItOnce"
    },
    {
        "id": 7200,
        "abbr": "SUP",
        "name": "SupremaPoker"
    },
    {
        "id": 7400,
        "abbr": "SVS",
        "name": "SvenskaSpel"
    },
    {
        "id": 6700,
        "abbr": "SWC",
        "name": "SWCPoker"
    },
    {
        "id": 1900,
        "abbr": "TT",
        "name": "Tribeca Tables Network"
    },
    {
        "id": 1600,
        "abbr": "UB",
        "name": "Ultimate Bet (pre-Cereus)"
    },
    {
        "id": 3900,
        "abbr": "UP",
        "name": "UPoker"
    },
    {
        "id": 3800,
        "abbr": "WEP",
        "name": "WePoker"
    },
    {
        "id": 7300,
        "abbr": "WPP",
        "name": "WePokerPlus"
    },
    {
        "id": 2200,
        "abbr": "WX",
        "name": "Winamax"
    },
    {
        "id": 2400,
        "abbr": "YN",
        "name": "Yatahay Network"
    },
    {
        "id": 6600,
        "abbr": "XPK",
        "name": "XPoker"
    },
    {
        "id": 3000,
        "abbr": "STD",
        "name": "Standardized"
    }
]

const StartStackAnalyzeRoomSelector = (props) => {
    return (
        <Select
            placeholder={"Названия румов"}
            labelField={"name"}
            valueField={"id"}
            multi
            options={props.options}
            values={props.db_info.rooms && props.db_info.rooms[0] ? props.db_info.rooms : []}
            onChange={(value) => {
                props.LeakfinderEditDbInfo("rooms", value)
            }
            }
            style={{marginTop: "5px", backgroundColor: "#313443", border: "none", borderRadius: "2px", color: "white", textAlign: "center"}}
        />
    )
}

const StartStackAnalyzeOrderBySelector = (props) => {
    return (
        <Select
            placeholder={"Сортировка"}
            labelField={"name"}
            valueField={"name"}
            options={[{name: "ASC"}, {name: "DESC"}]}
            values={props.db_info.order_by ? [{name: props.db_info.order_by }] : []}
            onChange={(value) => {
                props.LeakfinderEditDbInfo("order_by", value[0].name)
            }
            }
            style={{backgroundColor: "#313443", border: "none", borderRadius: "2px", color: "white", textAlign: "center"}}
        />
    )
}

const StartStackAnalyzeOrderByLevelSelector = (props) => {
    return (
        <Select
            placeholder={"Поля сортировки"}
            labelField={"name"}
            valueField={"action"}
            options={[{name: "По дате и ID", action: "default"}, {name: "По дате", action: "date"}, {name: "По ID", action: "id"}]}
            values={props.db_info.order_by_level ? [{name: props.db_info.order_by_level }] : []}
            onChange={(value) => {
                props.LeakfinderEditDbInfo("order_by_level", value[0].action)
            }
            }
            style={{backgroundColor: "#313443", border: "none", borderRadius: "2px", color: "white", textAlign: "center", marginTop: "5px"}}
        />
    )
}

const SalaryTicketsDirectionSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать"}
            labelField={"direction"}
            valueField={"direction"}
            sortBy={"direction"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            multi
            options={props.options}
            values={props.selectedDirection}
            onChange={(value) => {
                props.handleSelectDirection(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 15px 0 0", fontSize: "19px", border: "none", zIndex: "101"}}
        />
    )
}

export default function Sidebar(props) {
    function openDiskPage(e) {
        window.open(`https://lk.firestorm.team/disk/${e.target.accessKey}`, 'name', 'width=800,height=600');
    }

    const [salaryTicketsDirection, setSalaryTicketsDirection] = useState([]);
    const [directionFilter, setDirectionFilter] = useState("Все");
    const [auditPlayerCloseLevel, setAuditPlayerCloseLevel] = useState(1);

    const [nickname, setNickname] = useState("");
    const [clubName, setClubName] = useState("");
    const [accId, setAccId] = useState("");
    const [fromTimestamp, setFromTimestamp] = useState(+new Date());
    const [toTimestamp, setToTimestamp] = useState(+new Date());
    const [isNeedSearchFinResultsRows, setIsNeedSearchFinResultsRows] = useState(false);
    const [isNeedConfirmFinResultsNewSet, setIsNeedConfirmFinResultsNewSet] = useState(false);

    const [topic, setTopic] = useState(null);
    const [comment, setComment]  = useState(null);

    const handleAuditPlayerClose = (level) => {
        setAuditPlayerCloseLevel(level);
        setTimeout(() => {
            setAuditPlayerCloseLevel(1)
        }, 5000)
    }

    const handleSave = (close) => {
        props.LeakfinderSaveAnalyze(topic, {
            player_info: props.LeakfinderInfo.player_info,
            sub_player_info: props.LeakfinderInfo.sub_player_info
        }, props.LeakfinderInfo.analyze_info.roomNames, props.LeakfinderInfo.analyze_info.fromDate, props.LeakfinderInfo.analyze_info.toDate, props.LeakfinderInfo.line_chart_data);
        close();
    }

    const handleComment = (close) => {
        props.LeakfinderSaveComment(comment, props.LeakfinderInfo.selected_players, props.LeakfinderInfo.analyze_info.fromDate, props.LeakfinderInfo.analyze_info.toDate);
        close();
    }
    const defaultMaterialTheme = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            },

        },
    });

    // if (props.MainInfo.currentPage == "Coach: Список тренировок") {
    //     return <CoachTrainingsListSidebarContainer {...props}/>
    // }

    let groups_coaches_players = [];

    if (props.SupportInfo.usersListFilter && props.SupportInfo.usersListFilter[0]) {
        props.SupportInfo.usersListFilter.forEach(e => {
            if (e && e.coach_group) {
                let ind = groups_coaches_players.findIndex(ee => ee.group == e.coach_group);
                if (ind == -1) groups_coaches_players.push({group: e.coach_group, coaches: []})
            }
        })

        props.SupportInfo.usersListFilter.forEach(e => {
            if (e && e.coach_group) {
                let ind = groups_coaches_players.findIndex(ee => ee.group == e.coach_group);
                if (ind != -1) {
                    // groups_coaches_players[ind].players.push(e.username);
                    if (e.coaches && e.coaches[0]) {
                        e.coaches.forEach(c => {
                            let indOfCoach = groups_coaches_players[ind].coaches.findIndex(cc => cc.name == c.name);
                            if (indOfCoach == -1) {
                                groups_coaches_players[ind].coaches.push({name: c.name, players: [e.username]})
                            } else {
                                groups_coaches_players[ind].coaches[indOfCoach].players.push(e.username)
                            }
                        })
                    }
                }
            }
        })
    }

    if (props.MainInfo.currentPage == "Support: Список пользователей") {

        const SetDirectionFilter = (direction) => {
            setDirectionFilter(direction);
            props.ChangeUsersListFilter("direction", direction)
        }

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    <div className={"item"}>
                        <div className={"name"}>
                            Фильтр по направлению
                        </div>
                        <div className={"info"}>
                            <div className={"dropdown"}>
                                <input value={directionFilter} className={"small-input"}/>
                                <div className="dropdown-content">
                                    <div onClick={() => SetDirectionFilter("Все")}>Все</div>
                                    <div onClick={() => SetDirectionFilter("MTT")}>MTT</div>
                                    <div onClick={() => SetDirectionFilter("SPIN")}>SPIN</div>
                                    <div onClick={() => SetDirectionFilter("CASH")}>Cash</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"item"}>
                        <div className={"name"}>
                            Игрок - Тренеры
                        </div>
                        <Popup key={`check players coaches`}
                               trigger={
                                   <div className={"info default-button default-back"} style={{padding: "5px 0px"}}>
                                       Посмотреть
                                   </div>
                               }
                               modal
                               nested
                        >
                            {() => (
                                <div style={{color: "white"}}>
                                    <div id="get-advice" className="main-popup">
                                        <form className="form">
                                            {
                                                props.SupportInfo.usersListFilter.map((user, i) => {
                                                    return user.coaches && user.coaches[0] ? <div key={user.username + `${i}`} style={{border: "#2b5177 1px solid", margin: "15px 0", color: "#468ad0", borderRadius: "7px"}}>
                                                        <div style={{padding: "2px"}}>{user.username} - {user.coaches.map(coach => <span style={{color: "white"}}>{coach.name}; </span>)}</div>
                                                    </div> : null
                                                })
                                            }
                                        </form>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>


                    <div className={"item"}>
                        <div className={"name"}>
                            Тренер - Игроки
                        </div>
                        <Popup key={`check players coaches`}
                               trigger={
                                   <div className={"info default-button default-back"} style={{padding: "5px 0px"}}>
                                       Посмотреть
                                   </div>
                               }
                               modal
                               nested
                        >
                            {() => (
                                <div style={{color: "white"}}>
                                    <div id="get-advice" className="main-popup">
                                        <form className="form">
                                            {
                                                props.SupportInfo.coachesWithPlayers.map((user, i) => {
                                                    return user.players && user.players[0] ? <div key={user.name + `${i}`} style={{border: "#2b5177 1px solid", margin: "15px 0", color: "#468ad0", borderRadius: "7px"}}>
                                                        <div style={{padding: "2px"}}>{user.name} - {user.players.map(player => <span style={{color: "white"}}>{player}; </span>)}</div>
                                                    </div> : null
                                                })
                                            }
                                        </form>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>


                    <div className={"item"}>
                        <div className={"name"}>
                            Блок - Тренеры - Игроки
                        </div>
                        <Popup key={`check players coaches`}
                               trigger={
                                   <div className={"info default-button default-back"} style={{padding: "5px 0px"}}>
                                       Посмотреть
                                   </div>
                               }
                               modal
                               nested
                        >
                            {() => (
                                <div style={{color: "white"}}>
                                    <div id="get-advice" className="main-popup">
                                        <form className="form">
                                            {
                                                groups_coaches_players.map((e, i) => {
                                                    return e.group ? <div key={e.group + `${i}`} style={{border: "#2b5177 1px solid", margin: "15px 0", color: "#468ad0", borderRadius: "7px"}}>
                                                        <div style={{padding: "2px"}}>{e.group}</div>
                                                        <div>{e.coaches.map(coach => <div style={{color: "orange"}}>{coach.name} - {coach.players.map(player => <span style={{color: "white"}}>{player}; </span>)}</div>)}</div>

                                                    </div> : null
                                                })
                                            }
                                        </form>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>
        )
    }
    if (props.MainInfo.currentPage == "Фин. результаты (список)") {
        const handleSearchNickname = (e) => {
            setNickname(e.target.value)
            setIsNeedSearchFinResultsRows(true)
        }
        const handleSearchClubName = (e) => {
            setClubName(e.target.value)
            setIsNeedSearchFinResultsRows(true)
        }
        const handleSearchAccId = (e) => {
            setAccId(e.target.value)
            setIsNeedSearchFinResultsRows(true)
        }
        const handleSearchPeriod = (type, timestamp) => {
            if (type == "from") setFromTimestamp(+timestamp)
            if (type == "to") setToTimestamp(+timestamp)
            setIsNeedSearchFinResultsRows(true)
        }
        const handleConfirm = () => {
            setIsNeedConfirmFinResultsNewSet(true);
            setTimeout(() => {
                setIsNeedConfirmFinResultsNewSet(false)
            }, 5000)
        }
        if (isNeedSearchFinResultsRows) {
            const filters = {};
            if (nickname) filters.nickname = nickname;
            if (accId) filters.account_id = accId;
            if (clubName) filters.club_name = clubName;
            if (fromTimestamp) filters.from_timestamp = fromTimestamp;
            if (toTimestamp) filters.to_timestamp = toTimestamp;

            setIsNeedSearchFinResultsRows(false)
            props.SupportGetResultsList("set", 0, 50, filters)
        }
        return (
            <div className={"sidebar"}>
                <div style={{filter: "brightness(0) invert(1)", margin: "0 0 40px 0", textAlign: "center"}}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                format="dd.MM.yyyy HH:mm"
                                margin="normal"
                                label="От"
                                value={fromTimestamp}
                                onChange={(e) => handleSearchPeriod("from", e)}
                            />
                        </MuiPickersUtilsProvider>
                        <br/>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                format="dd.MM.yyyy HH:mm"
                                margin="normal"
                                label="До"
                                value={toTimestamp}
                                onChange={(e) => handleSearchPeriod("to", e)}
                            />
                        </MuiPickersUtilsProvider>

                    </ThemeProvider>
                </div>

                <div className={"item"} style={{marginBottom: "10px"}}>
                    <div className={"name"} style={{marginBottom: "-15px"}}>
                        Никнейм
                    </div>
                    <div className={"info"}>
                        <input value={nickname} className={"small-input"}
                               placeholder={"Можно не полностью"} onChange={handleSearchNickname}/>
                    </div>
                </div>
                <div className={"item"} style={{marginBottom: "10px"}}>
                    <div className={"name"} style={{marginBottom: "-15px"}}>
                        Аккаунт ID
                    </div>
                    <div className={"info"}>
                        <input value={accId} className={"small-input"}
                               placeholder={"Можно не полностью"} onChange={handleSearchAccId}/>
                    </div>
                </div>
                <div className={"item"} style={{marginBottom: "30px"}}>
                    <div className={"name"} style={{marginBottom: "-15px"}}>
                        Клуб
                    </div>
                    <div className={"info"}>
                        <input value={clubName} className={"small-input"}
                               placeholder={"Можно не полностью"} onChange={handleSearchClubName}/>
                    </div>
                </div>

                {
                    !isNeedConfirmFinResultsNewSet ?
                        <div className={"transition default-button green-back"} style={{marginBottom: "60px"}}
                             onClick={handleConfirm}>
                            Назначить сет по выбранным фильтрам (автоматически)
                        </div>
                        :
                        props.isLoading ? "" :
                            <div className={"transition default-button red-back"} style={{marginBottom: "60px"}}
                                 onClick={() => props.SupportUpdateSet(nickname)}>
                                Вы уверены в своём действии?
                            </div>
                }
            </div>
        )
    }
    if (props.MainInfo.currentPage == "SUPPORT | Планировщик") {
        return <SidebarSchedulerContainer/>
    }
    if (props.MainInfo.currentPage == "Редактор уведомлений по тренировкам") {
        let direction = React.createRef();
        let group_name = React.createRef();
        let channels = React.createRef();

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    <div className={"item"}>
                        <div className={"name"}>
                            Новая связь
                        </div>
                        <div className={"info"}>
                            <input ref={direction} className={"small-input"}
                                   placeholder={"Направление"}/>
                            <input ref={group_name} className={"small-input"} placeholder={"Название группы"}/>
                            <input ref={channels} className={"small-input"}
                                   placeholder={"ID каналов (через пробел, если несколько)"}/>
                        </div>
                        <button
                            onClick={(e) => props.ConnectorCreate(direction.current.value, group_name.current.value, channels.current.value)}
                            className={"small-button green-back transition"}>Создать
                        </button>
                    </div>
                </div>
        )
    }
    if (props.MainInfo.currentPage == "Редактор шаблонов доп. полей пользователя") return <ProfileCustomInfoSidebarContainer/>
    if (props.MainInfo.currentPage == "Список аудиторов") return <AuditorsListSidebarContainer/>
    if (props.MainInfo.currentPage.includes("Аудит игрока") && props.AuditorInfo.currentPlayer && props.AuditorInfo.currentPlayer.nickname) {
        if (props.AuditorInfo.currentPlayer.lastEdit == "remove_task") return window.location.href = '/support/audit/list'

        function Save(e, type = null, subType = null, fieldName = null, subFieldName = null) {
            if (type == "support" || type == "close") {
                props.SupportSaveTask({
                    type: fieldName == "close" ? "close" : type,
                    subType,
                    fieldName,
                    subFieldName,
                    newValue: fieldName == "remove_task" || fieldName == "close" ? null : e.target.checked,
                    nickname: props.AuditorInfo.currentPlayer.nickname,
                    period: props.AuditorInfo.currentPlayer.period,
                    setCount: props.AuditorInfo.currentPlayer.setCount
                }, type == "close" ? props.AuditorInfo.currentPlayer : null)
            } else if (type == "room") {
                props.SupportSaveTask({
                    type,
                    subType,
                    fieldName: e.target.innerText,
                    subFieldName,
                    newValue: e.target.innerText,
                    nickname: props.AuditorInfo.currentPlayer.nickname,
                    period: props.AuditorInfo.currentPlayer.period,
                    setCount: props.AuditorInfo.currentPlayer.setCount
                })
            }
        }

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    <div className={"item"}>
                        <div className={"name"}>
                            Информация
                        </div>
                        <div className={"info"}>
                            <div><span
                                style={{fontWeight: "bolder"}}>Статус (pool):</span> {props.AuditorInfo.currentPlayer.poolStatus}
                            </div>
                            <div><span
                                style={{fontWeight: "bolder"}}>Румы играет:</span> {props.AuditorInfo.currentPlayer.roomsPlaying}
                            </div>
                        </div>
                    </div>

                    {props.AuditorInfo.currentPlayer.editor_level == 1 ?
                        <div className={"item"}>
                            <div className={"info"}>
                                <div>
                                    <input checked={props.AuditorInfo.currentPlayer.checkboxes.is_NN_loaded}
                                           onClick={(e) => Save(e, "support", "", "is_NN_loaded")}
                                           className={"checkbox"}
                                           type={"checkbox"}/>
                                    <span>HH загружены</span>
                                </div>
                                <div>
                                    <input checked={props.AuditorInfo.currentPlayer.checkboxes.is_balances_done}
                                           onClick={(e) => Save(e, "support", "", "is_balances_done")}
                                           className={"checkbox"} type={"checkbox"}/>
                                    <span>Балансы записаны</span>
                                </div>
                                <div>
                                    <input checked={props.AuditorInfo.currentPlayer.checkboxes.is_audit_calculate}
                                           onClick={(e) => Save(e, "support", "", "is_audit_calculate")}
                                           className={"checkbox"} type={"checkbox"}/>
                                    <span>Аудит рассчитан</span>
                                </div>
                                <div>
                                    <input checked={props.AuditorInfo.currentPlayer.checkboxes.is_debts_done}
                                           onClick={(e) => Save(e, "support", "", "is_debts_done")}
                                           className={"checkbox"} type={"checkbox"}/>
                                    <span>Долги/Авансы внесены</span>
                                </div>
                                <div>
                                    <input checked={props.AuditorInfo.currentPlayer.checkboxes.is_files_loaded}
                                           onClick={(e) => Save(e, "support", "", "is_files_loaded")}
                                           className={"checkbox"} type={"checkbox"}/>
                                    <span>Забрал аудит и загрузил на диск</span>
                                </div>
                            </div>
                        </div>
                        : null
                    }


                    <div className={"item"}>
                        <div className={"info"}>
                            {
                                props.AuditorInfo.currentPlayer.follow_status ?
                                    <div className={"dropdown"}>
                                        <div className={"default-back big-input transition"}>Добавить рум</div>
                                        <div className="dropdown-content">
                                            <div onClick={(e) => Save(e, "room", "add")}>PokerStars com</div>
                                            <div onClick={(e) => Save(e, "room", "add")}>PokerStars ES</div>
                                            <div onClick={(e) => Save(e, "room", "add")}>Pacific (888)</div>
                                            <div onClick={(e) => Save(e, "room", "add")}>PartyPoker</div>
                                            <div onClick={(e) => Save(e, "room", "add")}>IPoker (RedStar Poker)</div>
                                            <div onClick={(e) => Save(e, "room", "add")}>Winnings (Pokerking)</div>
                                            <div onClick={(e) => Save(e, "room", "add")}>Microgaming</div>
                                            <div onClick={(e) => Save(e, "room", "add")}>Winamax</div>
                                            <div onClick={(e) => Save(e, "room", "add")}>GGnetwork</div>
                                            <div onClick={(e) => Save(e, "room", "add")}>Tigergaming</div>
                                        </div>
                                    </div>
                                    : null
                            }

                            <NavLink to={"/support/audit/list"}>
                                <div className={"blue-back big-input transition"}>Назад</div>
                            </NavLink>

                            {props.AuditorInfo.currentPlayer.follow_status ?
                                auditPlayerCloseLevel == 1 ?
                                    <button onClick={() => handleAuditPlayerClose(2)}
                                            className={"green-back big-input transition"}>Завершить</button>
                                    : <button onClick={(e) => Save(e, "close", "", "close")}
                                              className={"green-back big-input transition"}>Нажмите еще раз для
                                        завершения</button>
                                : null
                            }
                        </div>
                    </div>
                    <div className={"item"}>
                        <div className={"info"}>
                            <div className={"three-inputs"}>
                                <button
                                   onClick={openDiskPage}
                                   accessKey={`AUDIT-${props.AuditorInfo.currentPlayer.nickname}-${props.AuditorInfo.currentPlayer.period}`}
                                   className={"default-back big-input transition"}>Аудит</button>
                                <div/>
                                <button
                                   onClick={openDiskPage}
                                   accessKey={`HH-${props.AuditorInfo.currentPlayer.nickname}-${props.AuditorInfo.currentPlayer.period}`}
                                   className={"default-back big-input transition"}>НН</button>
                                <div/>
                                <button
                                   onClick={openDiskPage}
                                   accessKey={`SCREENS-${props.AuditorInfo.currentPlayer.nickname}-${props.AuditorInfo.currentPlayer.period}`}
                                   className={"default-back big-input transition"}>Скриншоты</button>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
    if (props.MainInfo.currentPage == "Список тасков") {
        const SetMonth = (e) => {
            if (e.target.localName == "div") {
                props.SetSupportTasksListMonth(e.target.innerText, props.AuditorInfo.supportTasksListYear,
                    props.AuditorInfo.adminTasksListDirection, "adminTasks", props.AuditorInfo.adminTasksListSet)
            } else {
                props.SetSupportTasksListMonth(e.target.value, props.AuditorInfo.supportTasksListYear,
                    props.AuditorInfo.adminTasksListDirection, "adminTasks", props.AuditorInfo.adminTasksListSet)
            }
        }
        const SetYear = (e) => {
            if (e.target.localName == "div") {
                props.SetSupportTasksListYear(props.AuditorInfo.supportTasksListMonth,
                    e.target.innerText, props.AuditorInfo.adminTasksListDirection, "adminTasks",
                    props.AuditorInfo.adminTasksListSet)
            } else {
                props.SetSupportTasksListYear(props.AuditorInfo.supportTasksListMonth, e.target.value,
                    props.AuditorInfo.adminTasksListDirection, "adminTasks", props.AuditorInfo.adminTasksListSet)
            }
        }
        const SetDirection = (e) => {
            props.SetAdminTasksListDirection(props.AuditorInfo.supportTasksListMonth,
                props.AuditorInfo.supportTasksListYear, e.target.value, props.AuditorInfo.adminTasksListSet)
        }
        const SetSetCount = (e) => {
            props.SetSupportTasksListSet(props.AuditorInfo.supportTasksListMonth,
                props.AuditorInfo.supportTasksListYear, props.AuditorInfo.adminTasksListDirection, e.target.value)
        }

        const DownloadTasks = (downloadType) => {
            props.AdminGetAllTasksByPeriod(props.AuditorInfo.adminTasksListDirection,
                props.AuditorInfo.supportTasksListMonth, props.AuditorInfo.supportTasksListYear, downloadType, props.AuditorInfo.adminTasksListSet)
        }

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    {props.AuditorInfo.adminTasksList && props.AuditorInfo.adminTasksList[0] ?
                        <div className={"item"}>
                            <div className={"name"}>
                                Статистика статусов
                            </div>
                            <div className={"info"}>
                                <div><span
                                    style={{fontWeight: "bolder"}}>Всего:</span> {props.AuditorInfo.adminTasksList.length}
                                </div>
                                <div><span
                                    style={{
                                        fontWeight: "bolder",
                                        color: "#debd18"
                                    }}>Активно:</span> {props.AuditorInfo.adminTasksList.filter(e => e.status == "active" || e.status == "edit" || e.status == "audit_2" || e.status == "audit_1").length}
                                </div>
                                <div><span
                                    style={{
                                        fontWeight: "bolder",
                                        color: "#45793c"
                                    }}>Верных:</span> {props.AuditorInfo.adminTasksList.filter(e => e.status == "edited" || e.status == "correct").length}
                                </div>
                                <div><span
                                    style={{
                                        fontWeight: "bolder",
                                        color: "#832b2b"
                                    }}>Данные не сошлись:</span> {props.AuditorInfo.adminTasksList.filter(e => e.status == "failed").length}
                                </div>

                                <StatusesFullInfo tasks={props.AuditorInfo.adminTasksList}/>
                            </div>
                        </div>
                        : null
                    }
                    <div className={"item"}>
                        <div className={"name"}>
                            Период
                        </div>
                        <div className={"info"}>
                            <div className={"dropdown"}>
                                <input onChange={(e) => SetMonth(e)} placeholder={"Месяц"} className={"small-input"}
                                       value={props.AuditorInfo.supportTasksListMonth}/>
                                <div className="dropdown-content">
                                    <div onClick={(e) => SetMonth(e)}>1</div>
                                    <div onClick={(e) => SetMonth(e)}>2</div>
                                    <div onClick={(e) => SetMonth(e)}>3</div>
                                    <div onClick={(e) => SetMonth(e)}>4</div>
                                    <div onClick={(e) => SetMonth(e)}>5</div>
                                    <div onClick={(e) => SetMonth(e)}>6</div>
                                    <div onClick={(e) => SetMonth(e)}>7</div>
                                    <div onClick={(e) => SetMonth(e)}>8</div>
                                    <div onClick={(e) => SetMonth(e)}>9</div>
                                    <div onClick={(e) => SetMonth(e)}>10</div>
                                    <div onClick={(e) => SetMonth(e)}>11</div>
                                    <div onClick={(e) => SetMonth(e)}>12</div>
                                </div>
                            </div>
                            <div className={"dropdown"}>
                                <input onChange={(e) => SetYear(e)} placeholder={"Год"} className={"small-input"}
                                       value={props.AuditorInfo.supportTasksListYear}/>
                                <div className="dropdown-content">
                                    <div onClick={(e) => SetYear(e)}>2021</div>
                                    <div onClick={(e) => SetYear(e)}>2022</div>
                                </div>
                            </div>
                            <input onBlur={(e) => SetSetCount(e)} placeholder={"Сет"} className={"small-input"}
                                   defaultValue={props.AuditorInfo.adminTasksListSet}/>
                            <input onBlur={(e) => SetDirection(e)} placeholder={"Направление"} className={"small-input"}
                                   defaultValue={props.AuditorInfo.adminTasksListDirection}/>
                        </div>
                    </div>
                    {
                        props.AuditorInfo.adminTasksList[0] ?

                            <div className={"item"}>
                                <div className={"info"}>
                                    {/*<button onClick={() => DownloadTasks(3)}*/}
                                    {/*        className={"small-button green-back transition"}>Выгрузить данные для*/}
                                    {/*    финансов*/}
                                    {/*</button>*/}
                                    <button onClick={() => DownloadTasks(1)}
                                            className={"small-button green-back transition"}>Выгрузить все данные
                                    </button>
                                    <button onClick={() => DownloadTasks(2)}
                                            className={"small-button green-back transition"}>Выгрузить верные данные
                                    </button>
                                </div>
                            </div>
                            : null
                    }
                </div>
        )
    }
    if (props.MainInfo.currentPage == "Список игроков") {
        const SetMonth = (e) => {
            if (e.target.localName == "div") {
                props.SetSupportTasksListMonth(e.target.innerText, props.AuditorInfo.supportTasksListYear, null, null, props.AuditorInfo.adminTasksListSet)
            } else {
                props.SetSupportTasksListMonth(e.target.value, props.AuditorInfo.supportTasksListYear, null, null, props.AuditorInfo.adminTasksListSet)
            }
        }
        const SetYear = (e) => {
            if (e.target.localName == "div") {
                props.SetSupportTasksListYear(props.AuditorInfo.supportTasksListMonth, e.target.innerText, null, null, props.AuditorInfo.adminTasksListSet)
            } else {
                props.SetSupportTasksListYear(props.AuditorInfo.supportTasksListMonth, e.target.value, null, null, props.AuditorInfo.adminTasksListSet)
            }
        }

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    <div className={"item"}>
                        <div className={"name"}>
                            Период
                        </div>
                        <div className={"info"}>
                            <div className={"dropdown"}>
                                <input onChange={(e) => SetMonth(e)} placeholder={"Месяц"} className={"small-input"}
                                       value={props.AuditorInfo.supportTasksListMonth}/>
                                <div className="dropdown-content">
                                    <div onClick={(e) => SetMonth(e)}>1</div>
                                    <div onClick={(e) => SetMonth(e)}>2</div>
                                    <div onClick={(e) => SetMonth(e)}>3</div>
                                    <div onClick={(e) => SetMonth(e)}>4</div>
                                    <div onClick={(e) => SetMonth(e)}>5</div>
                                    <div onClick={(e) => SetMonth(e)}>6</div>
                                    <div onClick={(e) => SetMonth(e)}>7</div>
                                    <div onClick={(e) => SetMonth(e)}>8</div>
                                    <div onClick={(e) => SetMonth(e)}>9</div>
                                    <div onClick={(e) => SetMonth(e)}>10</div>
                                    <div onClick={(e) => SetMonth(e)}>11</div>
                                    <div onClick={(e) => SetMonth(e)}>12</div>
                                </div>
                            </div>
                            <div className={"dropdown"}>
                                <input onChange={(e) => SetYear(e)} placeholder={"Год"} className={"small-input"}
                                       value={props.AuditorInfo.supportTasksListYear}/>
                                <div className="dropdown-content">
                                    <div onClick={(e) => SetYear(e)}>2021</div>
                                    <div onClick={(e) => SetYear(e)}>2022</div>
                                    <div onClick={(e) => SetYear(e)}>2023</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
    if (props.MainInfo.currentPage == "Leakfinder | Анализ игрока") {
        return (
            <div className={"sidebar"}>
                <div className={"item"}>
                    <div className={"name"}>
                        База данных
                    </div>
                    <div className={"info"}>
                        {/*<input className={"small-input"} value={props.LeakfinderInfo.db_info.user}*/}
                        {/*       onChange={(e) => props.LeakfinderEditDbInfo("user", e.target.value)}*/}
                        {/*       placeholder={"User"}/>*/}
                        {/*<input className={"small-input"} value={props.LeakfinderInfo.db_info.name}*/}
                        {/*       onChange={(e) => props.LeakfinderEditDbInfo("name", e.target.value)}*/}
                        {/*       placeholder={"DB Name"}/>*/}
                        {/*<input className={"small-input"} value={props.LeakfinderInfo.db_info.pass}*/}
                        {/*       onChange={(e) => props.LeakfinderEditDbInfo("pass", e.target.value)}*/}
                        {/*       placeholder={"Password"}/>*/}
                        {/*<div style={{textAlign: "center", margin: "3px 0 10px 0", padding: "3px", borderRadius: "5px"}} className={`${props.LeakfinderInfo.db_info.is_hero_need ? "green-back " : "red-back "} cursor-pointer`}*/}
                        {/*    onClick={(e) => props.LeakfinderEditDbInfo("is_hero_need", !props.LeakfinderInfo.db_info.is_hero_need)}>*/}
                        {/*    {*/}
                        {/*        props.LeakfinderInfo.db_info.is_hero_need ? `"Hero" учитывается` : `Нажмите, чтобы учитывать "Hero"`*/}
                        {/*    }*/}
                        {/*</div>*/}

                    </div>
                    {/*<div style={{fontSize: "12px", textAlign: "center", color: "#707070", marginTop: "10px"}}>Подсказка:*/}
                    {/*    Если не заполнено хотя бы одно поле, то анализ будет по основной базе*/}
                    {/*</div>*/}
                </div>

                {
                    props.LeakfinderInfo.selected_players && props.LeakfinderInfo.selected_players[0] ?
                        <Popup key={"saveAnalyze"}
                               trigger={
                                   <div>
                                       <div className={"default-button transition cursor-pointer orange-back"} style={{
                                           textAlign: "center",
                                           padding: "6px",

                                       }}
                                       >
                                           Коммент. для выбранных игроков
                                       </div>
                                   </div>
                               }
                               modal
                               nested
                        >
                            {(close) => (
                                <div>
                                    <div id="get-advice" className="main-popup">
                                        <form className="form">
                                            <div className="formgroup">
                                                <textarea onChange={(e) => setComment(e.target.value)} rows={10}
                                                       placeholder="Напишите здесь свои комментарии / замечания / ДЗ"/>
                                            </div>
                                        </form>
                                        <button onClick={() => handleComment(close)} className={"send-btn"}>Отправить
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    : null
                }

                {
                    props.LeakfinderInfo.player_info ?
                        <Popup key={"saveAnalyze"}
                               trigger={
                                   <div style={{marginBottom: "40px"}}>
                                       <div className={"default-button transition cursor-pointer green-back"} style={{
                                           textAlign: "center",
                                           padding: "6px",

                                       }}
                                       >
                                           Сохранить текущий анализ
                                       </div>
                                   </div>
                               }
                               modal
                               nested
                        >
                            {(close) => (
                                <div>
                                    <div id="get-advice" className="main-popup">
                                        <form className="form">
                                            <div className="formgroup">
                                                <input onChange={(e) => setTopic(e.target.value)} type="text"
                                                       placeholder="Название сохранения"/>
                                            </div>
                                        </form>
                                        <button onClick={() => handleSave(close)} className={"send-btn"}>Сохранить
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Popup>
                        : null
                }

            </div>
        )
    }
    if (props.MainInfo.currentPage == "Leakfinder | Анализ стартового стека") {
        return (
            <div className={"sidebar"}>
                <div className={"item"}>
                    <div className={"name"}>
                        База данных
                    </div>
                    <div className={"info"}>
                        <input className={"small-input"} value={props.LeakfinderInfo.db_info.user}
                               onChange={(e) => props.LeakfinderEditDbInfo("user", e.target.value)}
                               placeholder={"User"}/>
                        <input className={"small-input"} value={props.LeakfinderInfo.db_info.name}
                               onChange={(e) => props.LeakfinderEditDbInfo("name", e.target.value)}
                               placeholder={"DB Name"}/>
                        <input className={"small-input"} value={props.LeakfinderInfo.db_info.pass}
                               onChange={(e) => props.LeakfinderEditDbInfo("pass", e.target.value)}
                               placeholder={"Password"}/>
                    </div>
                    <div style={{fontSize: "12px", textAlign: "center", color: "#707070", marginTop: "10px"}}>Подсказка:
                        Если не заполнено хотя бы одно поле, то анализ будет по основной базе
                    </div>
                </div>


                <div className={"item"}>
                    <div className={"name"}>
                        Настройки запроса
                    </div>
                    <div className={"info"}>
                        <input className={"small-input"} value={props.LeakfinderInfo.db_info.buy_in_less}
                               onChange={(e) => props.LeakfinderEditDbInfo("buy_in_less", e.target.value)}
                               placeholder={"Минимальный стартовый стек"} type={"number"} style={{textAlign: "left", padding: "10px 10px"}}/>
                        <StartStackAnalyzeOrderBySelector
                            LeakfinderEditDbInfo={props.LeakfinderEditDbInfo} db_info={props.LeakfinderInfo.db_info}
                        />
                        <StartStackAnalyzeOrderByLevelSelector
                            LeakfinderEditDbInfo={props.LeakfinderEditDbInfo} db_info={props.LeakfinderInfo.db_info}
                        />
                        <StartStackAnalyzeRoomSelector options={StartStackAnalyzeRooms}
                            LeakfinderEditDbInfo={props.LeakfinderEditDbInfo} db_info={props.LeakfinderInfo.db_info}
                        />
                    </div>
                    <div style={{fontSize: "12px", textAlign: "center", color: "#707070", marginTop: "10px"}}>Подсказка:
                        Если поле НЕ заполнено, то будет использовано значение по умолчанию (Размер - 50; Сортировка - ASC; Поля - По дате и ID; Румы - Все)
                    </div>
                </div>
            </div>
        )
    }
    if (props.MainInfo.currentPage == "Список банков для обмена") {
        let createBankPublicType = React.createRef();
        let createBankPublicName = React.createRef();
        let createBankPrivateName = React.createRef();
        let createBankPrivateRegion = React.createRef();

        let createBank = () => {
            props.AdminCreateBank({
                public_type: createBankPublicType.current.value,
                public_name: createBankPublicName.current.value,
                private_name: createBankPrivateName.current.value,
                private_region: createBankPrivateRegion.current.value
            })
        }

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    <div className={"item"}>
                        <div className={"name"}>
                            Новый банк
                        </div>
                        <div className={"info"}>
                            <input ref={createBankPublicType} className={"small-input"}
                                   placeholder={"Тип (USD / EUR / UAH / CRYPTO)"}/>
                            <input ref={createBankPublicName} className={"small-input"} placeholder={"Название"}/>
                            <input ref={createBankPrivateName} className={"small-input"}
                                   placeholder={"Private Name"}/>
                            <input ref={createBankPrivateRegion} className={"small-input"}
                                   placeholder={"Private Region"}/>
                        </div>
                        <Popup key={"createBankHelp"}
                               trigger={
                                   <button className={"small-button default-back transition"}>Помощь
                                   </button>}
                               modal
                               nested
                        >
                            {() => (
                                <div>
                                    <div id="get-advice" className="main-popup">
                                        <form className="form">
                                            <div className="formgroup">
                                                <span style={{color: "white"}}>
                                                    Private Bank и Private Region требуется для того, чтобы в ближайшем обновлении
                                                    для вашего удобства подгружались текущие курсы создаваемых банков
                                                    <br/>
                                                    <span style={{color: "tomato"}}>Если у создаваемого банка тип CRYPTO, то поля Private Bank и Private Region заполнять не обязательно!</span>
                                                    <br/>
                                                    <br/>
                                                    <span style={{color: "green"}}>Private Region</span> - здесь нужно решить, с какого сайта будут браться актуальные курсы. Ввести нужно либо UA, либо RU.
                                                    <br/>
                                                    На выбор есть два сайта:<br/>
                                                    <span style={{color: "green"}}>RU</span> <a
                                                    style={{color: "lightblue"}}
                                                    href={"https://mainfin.ru/bank/otkritie/currency"} target="_blank">Перейти по ссылке</a><br/>
                                                    <span style={{color: "green"}}>UA</span> <a
                                                    style={{color: "lightblue"}}
                                                    href={"https://minfin.com.ua/company/privatbank/"} target="_blank">Перейти по ссылке</a><br/>
                                                    <span style={{color: "tomato"}}>Если не нужно будет по банку ничего подгружать или нет этого банка на сайтах выше, то впишите просто "-" в поле Private Region</span>
                                                    <br/>
                                                    <br/>
                                                    <span style={{color: "green"}}>Private Name</span> - здесь нужно ввести название банка из адресной строки сайта, который вы выбрали.
                                                    <br/>Например: <span style={{color: "green"}}>privatbank</span> (взяли это название отсюда: https://minfin.com.ua/company/<span
                                                    style={{color: "green"}}>privatbank</span>/)
                                                    <br/>
                                                    <span style={{color: "tomato"}}>Если не нужно будет по банку ничего подгружать или нет этого банка на сайтах выше, то впишите просто "-" в поле Private Name</span>

                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </Popup>
                        <button onClick={createBank} className={"small-button green-back transition"}>Добавить в
                            список
                        </button>
                    </div>
                </div>
        )

    }
    if (props.MainInfo.currentPage == "Список игроков (аудит)") {
        let createPlayerName = React.createRef();
        let createPlayerStatus = React.createRef();
        let createPlayerRooms = React.createRef();
        let createPlayerDirection = React.createRef();
        let createPlayerSets = React.createRef();

        let createPeriodMonth = React.createRef();
        let createPeriodYear = React.createRef();
        let createPeriodDirection = React.createRef();

        let createPlayer = () => {
            props.AdminCreatePlayer({
                playerName: createPlayerName.current.value,
                playerRooms: createPlayerRooms.current.value,
                playerStatus: createPlayerStatus.current.value,
                playerDirection: createPlayerDirection.current.value,
                setsCount: createPlayerSets.current.value
            })
        }

        let startPeriod = () => {
            props.AdminStartPeriod({
                month: createPeriodMonth.current.value,
                year: createPeriodYear.current.value,
                direction: createPeriodDirection.current.value,
            })
        }

        let handleKeyPress = (event) => {
            props.SearchAuditPlayersByDirection(event.target.value)
        }

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    <div className={"item"}>
                        <div className={"name"}>
                            Поиск
                        </div>
                        <div className={"info"}>
                            <input onKeyPress={handleKeyPress} className={"small-input"} placeholder={"Направление"}/>
                        </div>
                    </div>
                    <div className={"item"}>
                        <div className={"name"}>
                            Новый игрок
                        </div>
                        <div className={"info"}>
                            <input ref={createPlayerName} className={"small-input"} placeholder={"Никнейм"}/>
                            <input ref={createPlayerDirection} className={"small-input"} placeholder={"Направление"}/>
                            <input ref={createPlayerStatus} className={"small-input"}
                                   placeholder={"Статус (не обязательно)"}/>
                            <input ref={createPlayerSets} className={"small-input"}
                                   placeholder={"Кол-во сетов (по умолчанию: 1)"}/>
                            <input ref={createPlayerRooms} className={"small-input"}
                                   placeholder={"Румы (не обязательно)"}/>
                        </div>
                        <button onClick={createPlayer} className={"small-button green-back transition"}>Добавить в
                            список
                        </button>
                    </div>
                    <div className={"item"}>
                        <div className={"name"}>
                            Старт периода
                        </div>
                        <div className={"info"}>
                            <input ref={createPeriodMonth} className={"small-input"} placeholder={"Месяц (формат: 1)"}/>
                            <input ref={createPeriodYear} className={"small-input"} placeholder={"Год (формат: 2022)"}/>
                            <input ref={createPeriodDirection} className={"small-input"} placeholder={"Направление"}/>
                        </div>
                        <button onClick={startPeriod} className={"small-button green-back transition"}>Вызвать функцию
                        </button>
                    </div>
                </div>
        )
    }
    if (props.MainInfo.currentPage == "Запросы и вопросы по обменам") {
        const SupportChangeStatusesFilter = (e) => {
            props.SupportChangeStatusesFilter(e.target.accessKey)
        }
        const SupportChangeTypesFilter = (e) => {
            props.SupportChangeTypesFilter(e.target.accessKey)
        }

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    <div className={"item"}>
                        <div className={"name"}>
                            Фильтры статусов
                        </div>
                        <div className={"info"}>
                            <div>
                                <input className={"checkbox"} accessKey={"new"} onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.FsChangerInfo.statuses.includes("new")}/>
                                <span accessKey={"new"} onClick={SupportChangeStatusesFilter}>Новые</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"active"}
                                       onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.FsChangerInfo.statuses.includes("active")}/>
                                <span accessKey={"active"} onClick={SupportChangeStatusesFilter}>В работе</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"accepting"}
                                       onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.FsChangerInfo.statuses.includes("accepting")}/>
                                <span accessKey={"accepting"} onClick={SupportChangeStatusesFilter}>Отправлено</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"closed"}
                                       onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.FsChangerInfo.statuses.includes("closed")}/>
                                <span accessKey={"closed"} onClick={SupportChangeStatusesFilter}>Выполненные</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"canceled"}
                                       onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.FsChangerInfo.statuses.includes("canceled")}/>
                                <span accessKey={"canceled"} onClick={SupportChangeStatusesFilter}>Отмененные</span>
                            </div>
                        </div>
                    </div>

                    <div className={"item"}>
                        <div className={"name"}>
                            Фильтры типов
                        </div>
                        <div className={"info"}>
                            <div>
                                <input className={"checkbox"} accessKey={"request"}
                                       onChange={SupportChangeTypesFilter} type={"checkbox"}
                                       checked={props.FsChangerInfo.types.includes("request")}/>
                                <span accessKey={"request"}
                                      onClick={SupportChangeTypesFilter}>Запросы</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"question"}
                                       onChange={SupportChangeTypesFilter} type={"checkbox"}
                                       checked={props.FsChangerInfo.types.includes("question")}/>
                                <span accessKey={"question"}
                                      onClick={SupportChangeTypesFilter}>Вопросы</span>
                            </div>
                        </div>
                    </div>
                </div>
        )

    }
    if (props.MainInfo.currentPage == "Finances | Запросы на вывод") {
        const SupportChangeStatusesFilter = (e) => {
            props.SalarySupportChangeStatusesFilter(e.target.accessKey)
        }
        const SupportChangeTypesFilter = (e) => {
            props.SalarySupportChangeTypesFilter(e.target.accessKey)
        }

        const handleSelectDirection = (e) => {
            props.SalarySupportChangeDirectionsFilter(e && e[0] ? e : [])
        }

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    <div className={"item"}>
                        <div className={"name"}>
                            Фильтры статусов
                        </div>
                        <div className={"info"}>
                            <div>
                                <input className={"checkbox"} accessKey={"new"} onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.SalaryInfo.statuses.includes("new")}/>
                                <span accessKey={"new"} onClick={SupportChangeStatusesFilter}>Новые</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"active"}
                                       onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.SalaryInfo.statuses.includes("active")}/>
                                <span accessKey={"active"} onClick={SupportChangeStatusesFilter}>В работе</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"accepting"}
                                       onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.SalaryInfo.statuses.includes("accepting")}/>
                                <span accessKey={"accepting"} onClick={SupportChangeStatusesFilter}>Отправлено</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"closed"}
                                       onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.SalaryInfo.statuses.includes("closed")}/>
                                <span accessKey={"closed"} onClick={SupportChangeStatusesFilter}>Выполненные</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"canceled"}
                                       onChange={SupportChangeStatusesFilter}
                                       type={"checkbox"}
                                       checked={props.SalaryInfo.statuses.includes("canceled")}/>
                                <span accessKey={"canceled"} onClick={SupportChangeStatusesFilter}>Отмененные</span>
                            </div>
                        </div>
                    </div>

                    <div className={"item"}>
                        <div className={"name"}>
                            Фильтры типов
                        </div>
                        <div className={"info"}>
                            <div>
                                <input className={"checkbox"} accessKey={"request"}
                                       onChange={SupportChangeTypesFilter} type={"checkbox"}
                                       checked={props.SalaryInfo.types.includes("request")}/>
                                <span accessKey={"request"}
                                      onClick={SupportChangeTypesFilter}>Запросы</span>
                            </div>
                        </div>
                    </div>

                    <div className={"item"}>
                        <div className={"name"}>
                            Направление
                        </div>
                        <div className={"info"}>
                            <SalaryTicketsDirectionSelector options={props.SalaryInfo.supportTicketsDirections}
                                                            selectedDirection={props.SalaryInfo.supportSelectedTicketsDirections}
                                                            handleSelectDirection={handleSelectDirection}/>
                        </div>
                    </div>
                </div>
        )

    }

    if (props.MainInfo.currentPage == "ADMIN | Рефка") {
        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>

                    <div className={"item"}>
                        <div className={"default-button orange-back"} onClick={props.SupportFixData}>
                            Зафиксировать данные
                        </div>
                    </div>

                </div>
        )
    }
    if (props.MainInfo.currentPage == "Баги") {
        const ChangeStatusFilter = (e) => {
            props.ChangeStatusesFilter(e.target.accessKey)
        }
        const ChangeServerFilter = (e) => {
            props.ChangeServersFilter(e.target.accessKey)
        }

        return (
            props.isLoading ? <MainLoading/> :
                <div className={"sidebar"}>
                    <div className={"item"}>
                        <div className={"name"}>
                            Фильтры статусов
                        </div>
                        <div className={"info"}>
                            <div>
                                <input className={"checkbox"} accessKey={"new"} onChange={ChangeStatusFilter}
                                       type={"checkbox"}
                                       checked={props.SupportInfo.statuses.includes("new")}/>
                                <span accessKey={"new"} onClick={ChangeStatusFilter}>Новые</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"active"} onChange={ChangeStatusFilter}
                                       type={"checkbox"}
                                       checked={props.SupportInfo.statuses.includes("active")}/>
                                <span accessKey={"active"} onClick={ChangeStatusFilter}>В работе</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"closed"} onChange={ChangeStatusFilter}
                                       type={"checkbox"}
                                       checked={props.SupportInfo.statuses.includes("closed")}/>
                                <span accessKey={"closed"} onClick={ChangeStatusFilter}>Выполненные</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"canceled"} onChange={ChangeStatusFilter}
                                       type={"checkbox"}
                                       checked={props.SupportInfo.statuses.includes("canceled")}/>
                                <span accessKey={"canceled"} onClick={ChangeStatusFilter}>Отмененные</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"deleted"} onChange={ChangeStatusFilter}
                                       type={"checkbox"}
                                       checked={props.SupportInfo.statuses.includes("deleted")}/>
                                <span accessKey={"deleted"} onClick={ChangeStatusFilter}>Удаленные</span>
                            </div>
                        </div>
                    </div>

                    <div className={"item"}>
                        <div className={"name"}>
                            Фильтры серверов
                        </div>
                        <div className={"info"}>
                            <div>
                                <input className={"checkbox"} accessKey={"851060311988895774"}
                                       onChange={ChangeServerFilter} type={"checkbox"}
                                       checked={props.SupportInfo.servers.includes("851060311988895774")}/>
                                <span accessKey={"851060311988895774"}
                                      onClick={ChangeServerFilter}>Кухня</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"444471730732269569"}
                                       onChange={ChangeServerFilter} type={"checkbox"}
                                       checked={props.SupportInfo.servers.includes("444471730732269569")}/>
                                <span accessKey={"444471730732269569"}
                                      onClick={ChangeServerFilter}>FireStorm Spins</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"743480972816678922"}
                                       onChange={ChangeServerFilter} type={"checkbox"}
                                       checked={props.SupportInfo.servers.includes("743480972816678922")}/>
                                <span accessKey={"743480972816678922"}
                                      onClick={ChangeServerFilter}>FireStorm CASH</span>
                            </div>
                            <div>
                                <input className={"checkbox"} accessKey={"928562934412607500"}
                                       onChange={ChangeServerFilter} type={"checkbox"}
                                       checked={props.SupportInfo.servers.includes("928562934412607500")}/>
                                <span accessKey={"928562934412607500"} onClick={ChangeServerFilter}>FireStorm MTT</span>
                            </div>
                        </div>
                    </div>

                </div>
        )
    }
    if (props.MainInfo.currentPage == "Тикеты" || props.MainInfo.currentPage == "Таски" || props.MainInfo.currentPage == "Запросы на турниры" || props.MainInfo.currentPage == "TG тикеты") {

        const ChangeStatusFilter = (e) => {
            props.ChangeStatusesFilter(e.target.accessKey)
        }
        const ChangeServerFilter = (e) => {
            props.ChangeServersFilter(e.target.accessKey)
        }
        const ChangeTargetFilter = (e) => {
            props.ChangeTargetFilter(e.target.accessKey)
        }

        return (
            <div className={"sidebar"}>
                <div className={"item"}>
                    <div className={"name"}>
                        Фильтры статусов
                    </div>
                    <div className={"info"}>
                        <div>
                            <input className={"checkbox"} accessKey={"new"} onChange={ChangeStatusFilter}
                                   type={"checkbox"}
                                   checked={props.SupportInfo.statuses.includes("new")}/>
                            <span accessKey={"new"} onClick={ChangeStatusFilter}>Новые</span>
                        </div>
                        <div>
                            <input className={"checkbox"} accessKey={"active"} onChange={ChangeStatusFilter}
                                   type={"checkbox"}
                                   checked={props.SupportInfo.statuses.includes("active")}/>
                            <span accessKey={"active"} onClick={ChangeStatusFilter}>В работе</span>
                        </div>
                        <div>
                            <input className={"checkbox"} accessKey={"closed"} onChange={ChangeStatusFilter}
                                   type={"checkbox"}
                                   checked={props.SupportInfo.statuses.includes("closed")}/>
                            <span accessKey={"closed"} onClick={ChangeStatusFilter}>Выполненные</span>
                        </div>
                        <div>
                            <input className={"checkbox"} accessKey={"canceled"} onChange={ChangeStatusFilter}
                                   type={"checkbox"}
                                   checked={props.SupportInfo.statuses.includes("canceled")}/>
                            <span accessKey={"canceled"} onClick={ChangeStatusFilter}>Отмененные</span>
                        </div>
                        <div>
                            <input className={"checkbox"} accessKey={"deleted"} onChange={ChangeStatusFilter}
                                   type={"checkbox"}
                                   checked={props.SupportInfo.statuses.includes("deleted")}/>
                            <span accessKey={"deleted"} onClick={ChangeStatusFilter}>Удаленные</span>
                        </div>
                    </div>
                </div>

                <div className={"item"}>
                    <div className={"name"}>
                        Фильтры серверов
                    </div>
                    {
                        props.AuthInfo.isPokerhubSupport ?
                            <div className={"info"}>
                                <div>
                                    <input className={"checkbox"} accessKey={"1258028075426250854"}
                                           onChange={ChangeServerFilter} type={"checkbox"}
                                           checked={props.SupportInfo.servers.includes("1258028075426250854")}/>
                                    <span accessKey={"1258028075426250854"}
                                          onClick={ChangeServerFilter}>Pokerhub</span>
                                </div>
                            </div>
                            :
                            <div className={"info"}>
                                <div>
                                    <input className={"checkbox"} accessKey={"851060311988895774"}
                                           onChange={ChangeServerFilter} type={"checkbox"}
                                           checked={props.SupportInfo.servers.includes("851060311988895774")}/>
                                    <span accessKey={"851060311988895774"}
                                          onClick={ChangeServerFilter}>Кухня</span>
                                </div>
                                <div>
                                    <input className={"checkbox"} accessKey={"444471730732269569"}
                                           onChange={ChangeServerFilter} type={"checkbox"}
                                           checked={props.SupportInfo.servers.includes("444471730732269569")}/>
                                    <span accessKey={"444471730732269569"}
                                          onClick={ChangeServerFilter}>FireStorm Spins</span>
                                </div>
                                <div>
                                    <input className={"checkbox"} accessKey={"743480972816678922"}
                                           onChange={ChangeServerFilter} type={"checkbox"}
                                           checked={props.SupportInfo.servers.includes("743480972816678922")}/>
                                    <span accessKey={"743480972816678922"}
                                          onClick={ChangeServerFilter}>FireStorm CASH</span>
                                </div>
                                <div>
                                    <input className={"checkbox"} accessKey={"928562934412607500"}
                                           onChange={ChangeServerFilter} type={"checkbox"}
                                           checked={props.SupportInfo.servers.includes("928562934412607500")}/>
                                    <span accessKey={"928562934412607500"} onClick={ChangeServerFilter}>FireStorm MTT</span>
                                </div>
                                <div>
                                    <input className={"checkbox"} accessKey={"1258028075426250854"}
                                           onChange={ChangeServerFilter} type={"checkbox"}
                                           checked={props.SupportInfo.servers.includes("1258028075426250854")}/>
                                    <span accessKey={"1258028075426250854"}
                                          onClick={ChangeServerFilter}>Pokerhub</span>
                                </div>
                            </div>
                    }

                </div>
                <div className={"item"}>
                    <div className={"name"}>
                        Дополнительные фильтры
                    </div>
                    <div className={"info"}>
                        <Popup key={"tasks-tickets-filters-popup"}
                               trigger={
                                   <button className={"small-button default-back transition"}>Выбрать
                                   </button>}
                               modal
                               nested
                        >
                            {() => (
                                <div style={{color: "white"}} id="get-advice"
                                     className="tasks-tickets-filters-popup">
                                    <div style={{
                                        marginTop: "-20px",
                                        marginBottom: "20px",
                                        fontWeight: "bolder",
                                        fontSize: "17px",
                                        textAlign: "center"
                                    }}>
                                        Фильтр по отмеченным в таске/тикете
                                    </div>
                                    <div style={{
                                        display: "grid",
                                        gridTemplateColumns: "250px 250px 250px 250px",
                                        textAlign: "center"
                                    }}>
                                        <div>
                                            <div style={{fontWeight: "bolder", fontSize: "16px"}}>
                                                Spins
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"635876770071183362"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("635876770071183362")}/>
                                                <span accessKey={"635876770071183362"}
                                                      onClick={ChangeTargetFilter}> Tomoto</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"492373719604396033"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("492373719604396033")}/>
                                                <span accessKey={"492373719604396033"}
                                                      onClick={ChangeTargetFilter}> SergeBLR</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"283307693383483392"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("283307693383483392")}/>
                                                <span accessKey={"283307693383483392"}
                                                      onClick={ChangeTargetFilter}> Elegant</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"271733027040788490"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("271733027040788490")}/>
                                                <span accessKey={"271733027040788490"}
                                                      onClick={ChangeTargetFilter}> Egor</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"635864993429979141"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("635864993429979141")}/>
                                                <span accessKey={"635864993429979141"}
                                                      onClick={ChangeTargetFilter}> Anclave</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"422353664900988940"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("422353664900988940")}/>
                                                <span accessKey={"422353664900988940"}
                                                      onClick={ChangeTargetFilter}> Academic</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"330694943825723392"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("330694943825723392")}/>
                                                <span accessKey={"330694943825723392"}
                                                      onClick={ChangeTargetFilter}> Happy</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"462700557279166465"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("462700557279166465")}/>
                                                <span accessKey={"462700557279166465"}
                                                      onClick={ChangeTargetFilter}> Foge</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"354979120771235841"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("354979120771235841")}/>
                                                <span accessKey={"354979120771235841"}
                                                      onClick={ChangeTargetFilter}> Zoras</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"271275238602309643"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("271275238602309643")}/>
                                                <span accessKey={"271275238602309643"}
                                                      onClick={ChangeTargetFilter}> Phoenix</span>
                                            </div>
                                            <div style={{marginLeft: "40px", marginTop: "10px", textAlign: "left"}}>
                                                <input className={"checkbox"} accessKey={"537324949052850176"}
                                                       onChange={ChangeTargetFilter} type={"checkbox"}
                                                       checked={props.SupportInfo.targets.includes("537324949052850176")}/>
                                                <span accessKey={"537324949052850176"}
                                                      onClick={ChangeTargetFilter}> Tokovenko Yura</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{fontWeight: "bolder", fontSize: "16px"}}>
                                                Cash
                                            </div>
                                            <div style={{marginTop: "10px", textAlign: "left"}}>

                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"161503821573521409"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("161503821573521409")}/>
                                                    <span accessKey={"161503821573521409"}
                                                          onClick={ChangeTargetFilter}> Bzik</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"688790371781705911"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("688790371781705911")}/>
                                                    <span accessKey={"688790371781705911"}
                                                          onClick={ChangeTargetFilter}> Ghoastim</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"445209420461506571"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("445209420461506571")}/>
                                                    <span accessKey={"445209420461506571"}
                                                          onClick={ChangeTargetFilter}> Vitalii</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"283307693383483392"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("283307693383483392")}/>
                                                    <span accessKey={"283307693383483392"}
                                                          onClick={ChangeTargetFilter}> Elegant</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"271733027040788490"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("271733027040788490")}/>
                                                    <span accessKey={"271733027040788490"}
                                                          onClick={ChangeTargetFilter}> Egor</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"635864993429979141"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("635864993429979141")}/>
                                                    <span accessKey={"635864993429979141"}
                                                          onClick={ChangeTargetFilter}> Anclave</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"422353664900988940"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("422353664900988940")}/>
                                                    <span accessKey={"422353664900988940"}
                                                          onClick={ChangeTargetFilter}> Academic</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"330694943825723392"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("330694943825723392")}/>
                                                    <span accessKey={"330694943825723392"}
                                                          onClick={ChangeTargetFilter}> Happy</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"635876770071183362"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("635876770071183362")}/>
                                                    <span accessKey={"635876770071183362"}
                                                          onClick={ChangeTargetFilter}> Tomoto</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"537324949052850176"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("537324949052850176")}/>
                                                    <span accessKey={"537324949052850176"}
                                                          onClick={ChangeTargetFilter}> Tokovenko Yura</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{fontWeight: "bolder", fontSize: "16px"}}>
                                                MTT
                                            </div>
                                            <div style={{marginTop: "10px", textAlign: "left"}}>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"445209420461506571"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("445209420461506571")}/>
                                                    <span accessKey={"445209420461506571"}
                                                          onClick={ChangeTargetFilter}> Vitalii</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"354751742925340685"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("354751742925340685")}/>
                                                    <span accessKey={"354751742925340685"}
                                                          onClick={ChangeTargetFilter}> Masherchik</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"913042737765437461"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("913042737765437461")}/>
                                                    <span accessKey={"913042737765437461"}
                                                          onClick={ChangeTargetFilter}> Vlad</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"507657314640330755"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("507657314640330755")}/>
                                                    <span accessKey={"507657314640330755"}
                                                          onClick={ChangeTargetFilter}> Mao</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"811607897024233484"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("811607897024233484")}/>
                                                    <span accessKey={"811607897024233484"}
                                                          onClick={ChangeTargetFilter}> Lilia</span>
                                                </div>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"161503821573521409"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("161503821573521409")}/>
                                                    <span accessKey={"161503821573521409"}
                                                          onClick={ChangeTargetFilter}> Bzik</span>
                                                </div>

                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"1012271000165031996"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("1012271000165031996")}/>
                                                    <span accessKey={"1012271000165031996"}
                                                          onClick={ChangeTargetFilter}> Artem</span>
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            <div style={{fontWeight: "bolder", fontSize: "16px"}}>
                                                Другое
                                            </div>
                                            <div style={{marginTop: "10px", textAlign: "left"}}>
                                                <div style={{
                                                    marginLeft: "40px",
                                                    marginTop: "10px",
                                                    textAlign: "left"
                                                }}>
                                                    <input className={"checkbox"} accessKey={"everyone"}
                                                           onChange={ChangeTargetFilter} type={"checkbox"}
                                                           checked={props.SupportInfo.targets.includes("everyone")}/>
                                                    <span accessKey={"everyone"}
                                                          onClick={ChangeTargetFilter}> Для всех</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>

            </div>
        )
    } else {
        return (
            <div>

            </div>
        )
    }
}

const StatusesFullInfo = (props) => {
    let helpersStatuses = [];

    props.tasks.forEach(task => {
        let si;

        si = helpersStatuses.findIndex(e => e.supportName == task.owners[0].name);
        si == -1 ? updateHelpersStatuses("push", task.owners[0], si) : updateHelpersStatuses("change", task.owners[0], si)

        if (task.owners[1]) {
            let ai1 = helpersStatuses.findIndex(e => e.supportName == task.owners[1].name);
            ai1 == -1 ? updateHelpersStatuses("push", task.owners[1], ai1) : updateHelpersStatuses("change", task.owners[1], ai1)
        }
        if (task.owners[2]) {
            let ai2 = helpersStatuses.findIndex(e => e.supportName == task.owners[2].name);
            ai2 == -1 ? updateHelpersStatuses("push", task.owners[2], ai2) : updateHelpersStatuses("change", task.owners[2], ai2)
        }

        function updateHelpersStatuses(type, owner, i) {
            if (type == "push") {
                let left = owner.is_follow ? 1 : 0;
                helpersStatuses.push({
                    supportName: owner.name,
                    count: 1,
                    left
                })
            } else if (type == "change") {
                helpersStatuses[i].count += 1;
                if (owner.is_follow) helpersStatuses[i].left += 1
            }

        }
    })

    return (
        <Popup key={"AdminTasksStatusesFullInfo"}
               trigger={
                   <button className={"small-button red-back transition"}>Полная информация
                   </button>}
               modal
               nested
        >
            {() => (
                <div>
                    <div id="get-advice" className="main-popup">
                        <form className="form">
                            <div className={"item"}>
                                <div style={{color: "white"}} className={"info"}>
                                    {helpersStatuses.map(e => <div key={"AdminTasksStatusesFullInfo" + e.supportName}>
                                        <span style={{fontWeight: "bolder"}}>{e.supportName} - </span>
                                        <span style={{color: "#debd18"}}>{e.left} </span>
                                        <span>/ {e.count} </span>
                                    </div>)}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}