import React from 'react';
import s from './styles.module.css'
import closeArrow from "../../assests/arrow_small.png";
import classNames from "classnames";

const CloseArrowPopup = ({onClose, title,className}) => {
    return (
        <div className={classNames(s.close_arrow_box,className)} onClick={onClose}>
            <img className={s.close_arrow} src={closeArrow} alt="close"/>
            <span>{title}</span>
        </div>
    );
};

export default CloseArrowPopup;