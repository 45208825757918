import React, {useState, useRef} from "react";

export default function AuditorsList(props) {
    return (
        <div className={"auditors-list-page"}>
            <div className={"columns-names"}>
                <div className={"column-name"}>
                    Ник аудитора
                </div>
                <div className={"column-name"}>
                    Направление
                </div>
            </div>
            <div className={"auditors-list"}>
                {
                    props.AuditorInfo.auditorsList.map((auditor, i) => <Auditor key={i + auditor.name}
                                                                                auditor={auditor}
                                                                                AdminEditAuditor={props.AdminEditAuditor}
                                                                                AdminDeleteAuditor={props.AdminDeleteAuditor}/>)
                }
            </div>
        </div>
    );
}

const Auditor = (props) => {
    let editAuditorDirection = React.createRef();

    const EditDirection = () => {
        props.AdminEditAuditor({
            type: "direction",
            id: props.auditor.id,
            newValue: editAuditorDirection.current.value
        })
    }
    const Delete = () => {
        props.AdminDeleteAuditor(props.auditor.id)
    }
    return (
        <div className={"auditor"}>
            <div className={"name"}>
                {props.auditor.name}
            </div>
            <div className={"column-data"}>
                <input ref={editAuditorDirection} onBlur={EditDirection} defaultValue={props.auditor.direction}/>
            </div>
            <div onClick={Delete} className={"deleteAuditor"}>
                x
            </div>
        </div>
    )
}