import React, {useState} from 'react';
import Select from 'react-dropdown-select';
import Popup from "reactjs-popup";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import NumberToFixed1Field from "../../../../v_2/common/fields/numberToFixed1Field";
import NumberToFixed2Field from "../../../../v_2/common/fields/numberToFixed2Field";
import TextField from "../../../../v_2/common/fields/textField";
import s from "../../../../v_2/pages/FinResults/4-content/styles.module.css";
import SalaryDeleteButton from "../../../../v_2/common/fields/salaryDeleteButton";
import {SupportChangeUsersDirectionsFilter} from "../../../../redux/reducers/salaryReducer";

const UserSelector = (props) => {
    return (
        <Select
            placeholder={"Выберите пользователя"}
            labelField={"username"}
            valueField={"username"}
            options={props.options}
            values={[]}
            onChange={(value) => {
                if (value && value[0]) {
                    props.handleChangeUser(value[0].username, value[0].id)
                }
            }
            }
            style={{backgroundColor: "initial", border: "none", color: "white"}}
        />
    )
}

const SalaryUsersDirectionSelector = (props) => {
    return (
        <Select
            placeholder={"Выбрать направления"}
            labelField={"direction"}
            valueField={"direction"}
            sortBy={"direction"}
            onCreateNew={(item) => {
                // props.handleSelectPlayer(item)
            }}
            multi
            options={props.options}
            values={props.selectedDirection}
            onChange={(value) => {
                props.handleSelectDirection(value)
            }
            }
            style={{backgroundColor: "initial", margin: "7px 15px 0 0", fontSize: "19px", border: "none", zIndex: "101"}}
        />
    )
}

const UsersList = (props) => {
    const [division, setDivision] = useState(null);
    const [direction, setDirection] = useState(null);
    const [userNick, setUserNick] = useState(null);
    const [userId, setUserId] = useState(null);

    const handleChangeUser = (nick, id) => {
        setUserNick(nick)
        setUserId(id)
    }

    const handleSave = () => {
        props.SalaryAddUser(userNick, userId, division, direction)
    }

    const handleSelectDirection = (e) => {
        props.SupportChangeUsersDirectionsFilter(e && e[0] ? e : [])
    }


    let data = props.SalaryInfo.users.map(e => {
        if (props.SalaryInfo.supportSelectedUsersDirections.length == 0) return e;
        if (props.SalaryInfo.supportSelectedUsersDirections.some(ee => ee.direction == e.direction)) return e;
        return null;
    });
    data = data.filter(e => e != null);


    return (
        <div>
            <div>
                <Popup key={"userSalaryCreator"}
                       trigger={
                           <div className={"default-button green-back transition"}>Добавить пользователя</div>}
                       modal
                       nested
                >
                    {(close) => (
                        <div>
                            <div id="get-advice"
                                 style={{
                                     backgroundColor: "#1e1e25",
                                     padding: "40px 100px",
                                     textAlign: "center",
                                     color: "white"
                                 }}>
                                <form className="form">
                                    <div className="formgroup">
                                        <div style={{margin: "0 0 20px 0"}}>
                                            <UserSelector options={props.SalaryInfo.profiles}
                                                          handleChangeUser={handleChangeUser}/>
                                        </div>
                                        <div style={{margin: "20px 0"}}>
                                            <input className={"ag-input-field"} type="text" style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#32323f",
                                                borderRadius: "30px"
                                            }}
                                                   onChange={(e) => setDivision(e.target.value)}
                                                   placeholder="Название отдела"/>
                                        </div>
                                        <div style={{margin: "20px 0"}}>
                                            <input className={"ag-input-field"} type="text" style={{
                                                padding: "10px 20px",
                                                backgroundColor: "#32323f",
                                                borderRadius: "30px"
                                            }}
                                                   onChange={(e) => setDirection(e.target.value)}
                                                   placeholder="Название направления"/>
                                        </div>
                                    </div>
                                </form>
                                <button style={{marginTop: "20px", borderRadius: "30px"}} className={"send-btn default-button green-back"}
                                        onClick={handleSave}>Добавить
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>
            <div style={{margin: "30px 0 0px 30px"}}>
                <SalaryUsersDirectionSelector options={props.SalaryInfo.supportUsersDirections}
                                                selectedDirection={props.SalaryInfo.supportSelectedUsersDirections}
                                                handleSelectDirection={handleSelectDirection}/>
            </div>
            <div className='ag-theme-alpine-dark'
                 style={{
                     width: '100%',
                     height: 620,
                     position: 'relative',
                     margin: "40px 0 0 5px",
                     padding: "0 10px 0 0"
                 }}>
                <AgGridReact
                    style={{height: 400, width: '100%', fontSize: "10px"}}
                    rowData={data}
                    enableBrowserTooltips={true}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    rowHeight={70}
                    defaultColDef={{
                        editable: true,
                        sortable: true,
                        flex: 1,
                        minWidth: 20,
                        filter: false,
                        floatingFilter: false,
                        resizable: true,
                        fontSize: "11px"
                    }}
                    frameworkComponents={{
                        numberToFixed1Field: NumberToFixed1Field,
                        numberToFixed2Field: NumberToFixed2Field,
                        textField: TextField,
                        deleteButton: SalaryDeleteButton
                    }}
                >
                    <AgGridColumn
                        headerClass={s.header_styles_mini}
                        headerName={'Сотрудник (ник)'}
                        field={'nickname'}
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles_mini}
                        headerName={'Сотрудник (id)'}
                        field={'id'}
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles_mini}
                        headerName={'Отдел'}
                        field={'division'}
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles_mini}
                        headerName={'Направление'}
                        field={'direction'}
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='textField'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles_mini}
                        headerName={'Доступно к выводу'}
                        field={'salary'}
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberToFixed2Field'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles_mini}
                        headerName={'Непогашенных авансов'}
                        field={'prepayment'}
                        editable={false}
                        sortable={true}
                        filter={false}
                        cellRenderer='numberToFixed2Field'
                    />
                    <AgGridColumn
                        headerClass={s.header_styles}
                        headerName={''}
                        field={'blablabla'}
                        editable={false}
                        sortable={false}
                        filter={false}
                        cellRenderer='deleteButton'
                    />
                </AgGridReact>
            </div>
        </div>
    );
};

export default UsersList;