import React, {useState} from 'react';
import s from './styles.module.css'
import Row from "./row";
import ActionTable from "../action";
function getColor (value, standards) {
    let step;
    let color = '#ff3305';

    //standards[0] - минимальное значение эталона, standards[1] - максимальное значение эталона. Если мин/макс значение не задано - приравниваем к 0
    if (standards[0].length <= 0) standards[0] = 0;
    if (standards[1].length <= 0) standards[1] = 0;

    //ищем шаг в 5% от мин.значения эталона
    step = standards[0] * 0.05;

    //получаем значение в etalon из standards которое ближе к показателю value
    let etalon = standards.reduce(function (a, c) {
        return Math.abs(a - value) < Math.abs(c - value) ? a : c;
    })
    let mod_dif = Math.abs(value - etalon);

    if (value >= standards[0] && value <= standards[1]) {
        color = '#7FFF00'; //зеленый
    } else if (mod_dif <= step) {
        color = '#FFFF00'; //желтый
    } else {
        color = '#FF4500'; //красный
    }
    return color;
}

const Table = ({saves, schema}) => {
    const [typeSaves, setTypeSaves] = useState('player_info')

    let schema_type = typeSaves == "player_info" ? "schema_pre_flop" : "schema_post_flop";

    const saves_data = saves[0]?.data[typeSaves]
    const schema_data = {}
    const schema_keys = Object.keys(schema[schema_type])
    const players = saves.map((name) => name.username)

    for (let i = 0; i < schema_keys.length; i++) {
        schema_data[schema_keys[i]] = schema[schema_type][schema_keys[i]].filter(f => f['is_visible'])
    }

    return (
        <>
            <ActionTable changeTypeSave={(type) => setTypeSaves(type)}/>

            <div className={s.content}>
                <div style={{overflow: 'auto'}}>
                    <div className={s.header}>
                        <Row min={200} max={200}>Стат</Row>
                        <Row min={150} max={150}>Эталон</Row>
                        <Row min={100} max={100} type={"avg"}>Среднее</Row>
                        <Row max={'100%'}>Игрок</Row>
                    </div>

                    {schema_keys?.map((key, i) => {
                        return <div key={`main_content${i}`} style={{
                            display: (!saves_data[key]) && 'none'
                        }}>
                            {schema_data[key]?.length === 0 ? null :
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <br/>
                                        <Row min={200} max={200} isCategory={true} align={'left'}>{key}</Row>
                                        <br/>
                                        <div>
                                            {!saves_data[key] ? null : saves_data[key].map((sub_stat, index) => {
                                                return <Row
                                                    hidden={!Boolean(schema_data[key].find(f => f.name === sub_stat.name))}
                                                    key={`sub_state${index}`} align={'left'}>{sub_stat.name}</Row>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <br/>
                                        <Row min={150} max={150} isCategory={true} align={'left'}>{null}</Row>
                                        <br/>
                                        <div>
                                            {schema_data[key].map((schema_Value, index) => {
                                                const schema_values = schema_Value.standards

                                                return <Row
                                                    key={`schema_Value${index}`}
                                                    align={'center'}>
                                                    {`${schema_values[0]} - ${schema_values[1]}`}
                                                </Row>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <br/>
                                        <Row min={100} max={100} isCategory={true} align={'left'} type={"avg"}>{null}</Row>
                                        <br/>
                                        <div>
                                            {!saves_data[key] ? null : saves_data[key].map((sub_stat, index) => {
                                                const avg = players.reduce((acc = 0, inc) => {
                                                    const find_data_by_userName = saves.find(f => f.username === inc)?.data[typeSaves][key]
                                                    const find_current_name = find_data_by_userName.find((f) => f.name === sub_stat.name)

                                                    return acc = acc + find_current_name.value
                                                }, 0)


                                                const avg_complete = avg / players.length
                                                const schema_standart = schema_data[key].find(f => f.name === sub_stat.name)

                                                return <Row key={`avg${index}`}  type={"avg"}
                                                            style={{color: getColor(avg_complete, (schema_standart?.standards || [0, 0]))}}
                                                            hidden={!Boolean(schema_data[key].find(f => f.name === sub_stat.name))}
                                                            align={'center'}>{avg_complete.toFixed(2)}</Row>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <br/>
                                        <div className={s.players}>
                                            {players.map((name, index) => {
                                                    const find_data_by_userName = saves.find(f => f.username === name)?.data[typeSaves][key]

                                                    return (
                                                        <div className={s.test} key={`players${index}`}>
                                                            <Row min={100} max={100} isCategory={true}
                                                                 align={'center'}>{name}</Row>
                                                            <br/>
                                                            {find_data_by_userName?.map((value, index) => {
                                                                const schema_standart = schema_data[key].find(f => f.name === value.name)

                                                                return <Row
                                                                    style={{color: getColor(value.value, (schema_standart?.standards || [0, 0]))}}
                                                                    hidden={!Boolean(schema_data[key].find(f => f.name === value.name))}
                                                                    key={`players_value_${index}`} min={100} max={100}
                                                                    align={'center'}>{value.value}</Row>
                                                            })}
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>

                                        <br/>
                                    </div>
                                </div>
                            }
                        </div>
                    })}
                </div>
            </div>
        </>
    );
};

export default Table;
