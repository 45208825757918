import React from 'react';
import classNames from "classnames";
import {ReactComponent as Main} from "../../components/left_side_bar/icons/1.svg";
import s from './styles.module.css'

const TrainerButton = ({hiddenLeft, onClick}) => {
    const goto = () => {
        window.location.href = 'https://pokerhub.pro/';
    }

    return (
        <div onClick={goto} className={classNames(s.old_version, hiddenLeft && s.hiddenLeft)}>
            <Main/>
            {!hiddenLeft && <p>Тренажер</p>}
        </div>
    );
};

export default TrainerButton;