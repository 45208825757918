import React, {useState, useRef} from "react";

export default function Clubs(props) {

    let name = React.createRef();
    let union = React.createRef();
    let rake_percent = React.createRef();
    let currency = React.createRef();
    let chip = React.createRef();
    let logo = React.createRef();
    let chip_exchange = React.createRef();

    return (
        <div className={"training-connectors-list-page"}>
            <div style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px"}} className={"columns-names"}>
                <div className={"column-name"}>
                    Название клуба
                </div>
                <div className={"column-name"}>
                    Союз
                </div>
                <div className={"column-name"}>
                    Рейкбек %
                </div>
                <div className={"column-name"}>
                    Валюта
                </div>
                <div className={"column-name"}>
                    Размер фишки
                </div>
                <div className={"column-name"}>
                    Курс фишки к USD
                </div>
                <div className={"column-name"}>
                    Ссылка на лого (png)
                </div>
                <div/>
            </div>
            <div className={"training-connectors-list"}>

                <div style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px"}} className={"connector"}>
                    <div className={"column-data"}>
                        <input
                            ref={name}
                        />
                    </div>
                    <div className={"column-data"}>
                        <input
                            ref={union}
                        />
                    </div>
                    <div className={"column-data"}>
                        <input
                            type={"number"}
                            ref={rake_percent}
                        />
                    </div>
                    <div className={"column-data"}>
                        <input
                            ref={currency}
                        />
                    </div>
                    <div className={"column-data"}>
                        <input
                            type={"number"}
                            ref={chip}
                        />
                    </div>
                    <div className={"column-data"}>
                        <input
                            type={"number"}
                            ref={chip_exchange}
                        />
                    </div>
                    <div className={"column-data"}>
                        <input
                            ref={logo}
                        />
                    </div>
                    {
                        !props.isLoading && <div
                            onClick={() => props.SupportUpdateClub({
                                type: "create",
                                name: name.current.value,
                                rake_percent: rake_percent.current.value,
                                currency: currency.current.value,
                                chip: chip.current.value,
                                chip_exchange: chip_exchange.current.value,
                                logo: logo.current.value,
                                union: union.current.value
                            })}
                            className={"createConnector"}>
                            Создать
                        </div>
                    }

                </div>


                {
                    props.FormularInfo.clubsList.map((club, i) => <Club
                        key={`${i} ${club._id}`}
                        club={club}
                        SupportUpdateClub={props.SupportUpdateClub}/>)
                }
            </div>
        </div>
    );
}

const Club = (props) => {
    return (
        <div style={{gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 80px"}} className={"connector"}>
            <div className={"name"}>
                {props.club.name}
            </div>
            <div className={"column-data"}>
                <input
                    onBlur={(e) => props.SupportUpdateClub({
                        type: "update",
                        name: props.club.name,
                        row: "union",
                        newValue: e.target.value
                    })}
                    defaultValue={props.club.union}/>
            </div>
            <div className={"column-data"}>
                <input
                    type={"number"}
                    onBlur={(e) => props.SupportUpdateClub({
                        type: "update",
                        name: props.club.name,
                        row: "rake_percent",
                        newValue: e.target.value
                    })}
                    defaultValue={props.club.rake_percent}/>
            </div>
            <div className={"column-data"}>
                <input
                    onBlur={(e) => props.SupportUpdateClub({
                        type: "update",
                        name: props.club.name,
                        row: "currency",
                        newValue: e.target.value
                    })}
                    defaultValue={props.club.currency}/>
            </div>
            <div className={"column-data"}>
                <input
                    type={"number"}
                    onBlur={(e) => props.SupportUpdateClub({
                        type: "update",
                        name: props.club.name,
                        row: "chip",
                        newValue: e.target.value
                    })}
                    defaultValue={props.club.chip}/>
            </div>

            <div className={"column-data"}>
                <input
                    type={"number"}
                    onBlur={(e) => props.SupportUpdateClub({
                        type: "update",
                        name: props.club.name,
                        row: "chip_exchange",
                        newValue: e.target.value
                    })}
                    defaultValue={props.club.chip_exchange}/>
            </div>

            <div className={"column-data"}>
                <input
                    onBlur={(e) => props.SupportUpdateClub({
                        type: "update",
                        name: props.club.name,
                        row: "logo",
                        newValue: e.target.value
                    })}
                    defaultValue={props.club.logo}/>
            </div>
            <div/>
        </div>
    )
}