import Cookies from "js-cookie";
import {CATEGORIES_API} from "../../api/util";
import {AddNotification} from "./notificationsReducer";
import {ChangeMainLoading} from "./mainReducer";

const ADD_CATEGORY_TO_LIST = 'ADD_CATEGORY_TO_LIST';
const SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST';
const SET_CATEGORY_CREATION_ERROR = 'SET_CATEGORY_CREATION_ERROR';
const EDIT_CATEGORY_IN_LIST = 'EDIT_CATEGORY_IN_LIST';

let initialState = {
    categoriesList: [],
    creationError: null
};

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORY_CREATION_ERROR:
            return {
                ...state,
                creationError: action.message
            }
        case ADD_CATEGORY_TO_LIST:
            return {
                ...state,
                categoriesList: [{name: action.categoryName, iconUrl: action.categoryIconUrl}, ...state.categoriesList]
            }
        case SET_CATEGORIES_LIST:
            return {
                ...state,
                categoriesList: [...action.categories]
            }
        case EDIT_CATEGORY_IN_LIST: {
            let isCategoryFound = false;
            let categoryIndex = 0;
            state.categoriesList.forEach((category, i) => {
                if (category.name == action.oldName) {
                    isCategoryFound = true;
                    categoryIndex = i;
                }
            })
            if (isCategoryFound) {
                let newCategoriesList = [...state.categoriesList];
                if (action.editType == 'name') newCategoriesList[categoryIndex].name = action.newValue;
                if (action.editType == 'icon') newCategoriesList[categoryIndex].iconUrl = action.newValue;
                if (action.editType == 'priority') newCategoriesList[categoryIndex].priority = action.newValue;
                return {
                    ...state,
                    categoriesList: [...newCategoriesList]
                }
            } else {
                return state;
            }
        }
        default:
            return state;
    }
};

const EditCategoryInList = (editType, oldName, newValue) => ({
    type: EDIT_CATEGORY_IN_LIST, editType, oldName, newValue
})
const AddCategoryToList = (categoryName, categoryIconUrl) => ({
    type: ADD_CATEGORY_TO_LIST,
    categoryName, categoryIconUrl
})
const SetCategoriesList = (categories) => ({
    type: SET_CATEGORIES_LIST, categories
})
const SetCreationError = (message) => ({
    type: SET_CATEGORY_CREATION_ERROR, message
})

export const EditCategory = (type, name, newValue) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        let body = {
            categoryInfo: {
                name,
                type,
                newValue
            }
        }

        if (type == "name" && newValue.length > 16) {
            dispatch(AddNotification("error", "Изменение категории", "Максимум 16 символов для названия!"))
            dispatch(ChangeMainLoading(false))
            return
        }

        CATEGORIES_API.editCategory(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Изменение категории", data.message))
                    return
                }
                dispatch(EditCategoryInList(type, name, newValue))
                dispatch(AddNotification("success", "Изменение категории", "Категория изменена"))
            })
    }
}

export const GetCategoriesList = (id, email, access_token) => {
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        CATEGORIES_API.get(id, email, access_token)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Получение списка категорий", data.message))
                    return
                }
                dispatch(SetCategoriesList(data))
            })
    }
}
export const CreateCategory = (access_token, id, email, username, categoryName, categoryIconUrl) => {
    let body = {
        category_info: {
            name: categoryName,
            iconUrl: categoryIconUrl
        },
        info: {
            id, email, username
        },
        access_token
    }
    return (dispatch) => {
        dispatch(ChangeMainLoading(true))
        if (categoryName.length > 16) {
            dispatch(AddNotification("error", "Создание категории", "Максимум 16 символов для названия!"))
            dispatch(ChangeMainLoading(false))
            return
        }
        if (!categoryIconUrl || !categoryName) {
            dispatch(AddNotification("error", "Создание категории", "Все поля должны быть заполнены!"))
            dispatch(ChangeMainLoading(false))
            return
        }
        CATEGORIES_API.create(body)
            .then(data => {
                dispatch(ChangeMainLoading(false))
                if (data.resultCode && data.resultCode != 1) {
                    dispatch(AddNotification("error", "Создание категории", data.message))
                    return
                }
                dispatch(AddCategoryToList(categoryName, categoryIconUrl))
            })
    }
}

export default categoriesReducer;