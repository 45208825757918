import React from 'react';
import MainContainer from "./components/Main/mainContainer";
import './App.css';
import { subscribeUser } from './subscription';

const App = () => {
    return (
        <div>
            <button className={"hidden"} onClick={subscribeUser}/>
            <MainContainer/>
        </div>
    );
}

export default App;
