import React, {useState} from 'react';
import s from './styles.module.css'
import SubItem from "./sub_item";
import ActionTable from "../action";

const Table4 = ({usernames, data, period_type, year}) => {
    const [typeSaves, setTypeSaves] = useState('player_info')
    let schema_type = typeSaves == "player_info" ? "schema_pre_flop" : "schema_post_flop";

    const shema_main = data[schema_type]
    const shema_main_keys = Object.keys(data[schema_type])

    const userNames = usernames.map((name) => name.nick);

    let periods = [];
    let years = ["2022", "2023", "2024", "2025", "2026"];
    let months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    if (period_type == "Все года") {
        periods = years;
    }
    if (period_type == "Месяцы") {
        months.forEach(month => {
            periods.push(`${month}.${year}`)
        })
    }
    if (period_type == "Кварталы") {
        periods.push(`1QT.${year}`);
        periods.push(`2QT.${year}`);
        periods.push(`3QT.${year}`);
        periods.push(`4QT.${year}`);
    }
    if (period_type == "Полугодия") {
        periods.push(`1H.${year}`);
        periods.push(`2H.${year}`);
    }

    return (
        <div style={{marginLeft: "40px"}}>
            <ActionTable changeTypeSave={(type) => setTypeSaves(type)}/>
            <div className={s.table}>
                <div className={s.header_table}>
                    <div>Стат</div>
                    <div>Эталон</div>
                    <div>Среднее</div>
                    <div>Период</div>
                </div>

                <div className={s.content_table}>
                    {shema_main_keys.map((key, index) => {
                        let ignoreListCategories = ["TOTAL PREFLOP", "TOTAL POSTFLOP"]
                        let ignoreListPeriods = ["Unopened", "vs 1 Raiser", "vs 1 Raiser + Call (s)",
                        "BB Defense", "SB vs BB_", "Single Raise Pot", "3bet pot", "SB vs BB"]

                        return !ignoreListCategories.includes(key) ? <div className={s.content_table_content} key={`${index} key`}>
                            <div className={s.content_table_content_top}>
                                <div className={s.table_box} style={{fontWeight: '700', fontSize: '22px'}}>{key}</div>
                                <div className={s.table_box}></div>
                                <div className={s.table_box}></div>
                                <div style={{display: "grid", gridTemplateColumns: `repeat(${periods.length}, 100px)`, gap: "10px"}}>
                                    { !ignoreListPeriods.includes(key) ? periods.map((per, ind) => <div key={`${ind} per1`} className={s.perido}
                                                                    style={{fontWeight: '700'}}>{per}</div>) : null}
                                </div>
                            </div>

                            <div className={s.content_table_content_center}>
                                {shema_main[key].map((subtitle, ind) => {


                                    return <SubItem key={`${ind} subtitle`} userNames={userNames} periods={periods}
                                                    subtitle={subtitle} ind={ind}/>
                                })}

                            </div>
                        </div> : null
                    })}
                </div>
            </div>
        </div>

    );
};

export default Table4;
