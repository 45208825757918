import React, {useRef, useState} from 'react';
import {NavLink, Route, Switch} from "react-router-dom";
import MainLoading from "../common/mainLoading";

const GeneratedMenuAccesses = (props) => {
    function uniq(a) {
        return a.sort().filter(function (item, pos, ary) {
            return !pos || item != ary[pos - 1];
        });
    }

    let categories = props.AuthInfo.pageAccesses.map(page => page.categoryName);
    categories = uniq(categories);

    categories = categories.map(category => {
        let categoryBody = {
            name: category,
            subCategories: [],
            pages: []
        }
        props.AuthInfo.pageAccesses.forEach(page => {
            if (page.categoryName == category) {
                if (!categoryBody.subCategories.some(e => e.subCategoryName == page.subCategoryName)) {
                    categoryBody.subCategories.push({
                        subCategoryName: page.subCategoryName,
                        subCategoryPriority: page.subCategoryPriority
                    })
                }
                categoryBody.pages.push(page)
            }
        })

        categoryBody.subCategories.sort((a, b) => a.subCategoryPriority - b.subCategoryPriority)
        categoryBody.pages.sort((a, b) => a.priority - b.priority)

        return categoryBody;
    })

    return (
        props.isLoading ? <MainLoading/>
            :
            props.AuthInfo.accessToken ?
                props.AuthInfo.pageAccesses[0] ?
                    categories.map(category => {
                        let categoryIcon = null;
                        let categoryColor = "#FFFFFF";

                        props.CategoriesInfo.categoriesList.forEach(c => {
                            if (c.name == category.name) categoryIcon = c.iconUrl
                        })

                        return <li key={category.name + "menuGeneratorCategory"}>
                  <span className="side-menu__btn">
                    <img src={categoryIcon} alt=""/>
                  </span>
                            <ul className="sub-menu">
                                <li className="sub-menu__title">
                                    <img src={categoryIcon} alt="icon"
                                         className="icon"/>
                                    <span className>{category.name}</span>
                                </li>

                                {
                                    category.subCategories.map((subCategory, i) => {
                                        let subCategoryIconUrl = "https://lk.firestorm.team/img/icon2.svg";
                                        let subCategoryColor = "#FFFFFF";

                                        props.AuthInfo.pageAccesses.forEach(page => {
                                            if (page.subCategoryName == subCategory.subCategoryName && page.subCategoryIconUrl) {
                                                subCategoryIconUrl = page.subCategoryIconUrl
                                                subCategoryColor = page.subCategoryNameColor
                                            }
                                        })
                                        return (
                                            <li key={subCategory.subCategoryName + "menuGeneratorSubCategory" + i}>
                                            <span style={{color: subCategoryColor}} className="sub-menu__down">
                                                <img src={subCategoryIconUrl} alt="icon"/>
                                                {subCategory.subCategoryName}
                                            </span>
                                                {
                                                    category.pages.map((page, y) => {
                                                        if (page.subCategoryName == subCategory.subCategoryName && page.categoryName == category.name) {
                                                            return (
                                                                <ul key={page.name + "menuGeneratorPageName" + y}
                                                                    className="sub-menu__list">
                                                                    <MenuItem type={page.type} name={page.name}
                                                                              color={page.color}/>
                                                                </ul>
                                                            )
                                                        }
                                                    })
                                                }
                                            </li>
                                        )
                                    })
                                }


                            </ul>
                        </li>
                    })
                    : null : null
    )
}

const MenuItem = (props) => {
    return (
        <li><NavLink style={{color: props.color}} to={`/workspace/${props.type}/${props.name}`}>{props.name}</NavLink>
        </li>
    )
}

export default GeneratedMenuAccesses;