import React from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import Tickets from "./salaryTickets";
import {
    AdminDeleteTicket,
    SalarySupportChangeStatusesFilter, SalarySupportChangeTypesFilter, SupportChangeTicketStatus,
    SupportEditTicket,
    SupportGetTicketsList
} from "../../../../redux/reducers/salaryReducer";

class SalaryTicketsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount() {
        this.props.changeCurrentPage("Finances | Запросы на вывод");
        this.props.SupportGetTicketsList("set", ["question", "request"], ["new", "active"], ['SPIN', 'MTT', 'CASH'], 0, 50)
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps !== this.props || nextState !== this.state; // не изменяет компоненту, если пропсы не поменялись ради такой строчки можно использовать PureComponent вместо Component
    } // сравнивание объектов кста невозможно, сверху бред

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.SalaryInfo.types.length != prevProps.SalaryInfo.types.length ||
            this.props.SalaryInfo.statuses.length != prevProps.SalaryInfo.statuses.length) {
            this.props.SupportGetTicketsList("set", this.props.SalaryInfo.types, this.props.SalaryInfo.statuses, this.props.SalaryInfo.directions, 0, 50)
        }
    }

    fetchMoreData = () => {
        this.props.SupportGetTicketsList("update", this.props.SalaryInfo.types, this.props.SalaryInfo.statuses, this.props.SalaryInfo.directions, this.props.SalaryInfo.supportTicketsList.length, 50)
    };

    render() {
        return (
            <Tickets fetchMoreData={this.fetchMoreData} {...this.props}/>
        )
    }
}

let mapStateToProps = (state) => ({
    MainInfo: state.mainReducer,
    AuthInfo: state.authReducer,
    SalaryInfo: state.salaryReducer
})


export default compose(
    connect(mapStateToProps, {
        SupportGetTicketsList, SalarySupportChangeStatusesFilter, SalarySupportChangeTypesFilter, SupportChangeTicketStatus,
        SupportEditTicket, AdminDeleteTicket
    }),
    withRouter
)(SalaryTicketsContainer)