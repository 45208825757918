import React, {useState} from 'react';
import folderIcon from '../../images/disk_images/folder.png'
import documentIcon from '../../images/disk_images/document.png'
import imageIcon from '../../images/disk_images/image.png'
import removeIcon from '../../images/disk_images/remove.png'
import backIcon from '../../images/disk_images/left-arrow.png'
import newFolderIcon from '../../images/disk_images/newfolder.png'
import Popup from "reactjs-popup";
import uploadFileIcon from '../../images/disk_images/upload-file.png'
import downloadFileIcon from '../../images/disk_images/down-arrow.png'
import previewFileIcon from '../../images/disk_images/loupe.png';
import homeIcon from '../../images/disk_images/home.png';

import MainLoading from "../common/mainLoading";

const DiskFiles = (props) => {
    let createFolderName = React.createRef();

    const [dragEnter, setDragEnter] = useState(false);
    const [isSortByStartName, setSortByStartName] = useState(false);

    const sortTable = (e) => {
        if (e.target.accessKey == "name") {
            props.sortDiskTable("name", !isSortByStartName)
            setSortByStartName(!isSortByStartName)
        }
    }

    let filesList = props.DiskInfo.filesList.map(file => <File key={file._id} {...file} GetFiles={props.GetFiles}
                                                               CurrentDir={props.DiskInfo.currentDir}
                                                               PushToStack={props.PushToStack}
                                                               changeCurrentPage={props.changeCurrentPage}
                                                               DiskInfo={props.DiskInfo}
                                                               DownloadFile={props.DownloadFile}
                                                               DeleteFile={props.DeleteFile}
                                                               PreviewImage={props.PreviewImage}
                                                               DownloadDir={props.DownloadDir}/>)

    let homeClickHandler = () => {
        props.DiskInfo.dirStack = []
        props.DiskInfo.dirNamesStack = []

        props.GetFiles(null);
    }
    let backClickHandler = () => {
        const backDirId = props.DiskInfo.dirStack.pop()
        props.DiskInfo.dirNamesStack.pop()

        props.GetFiles(backDirId);
    }
    let createFolderHandler = () => {
        props.CreateDir(createFolderName.current.value, props.DiskInfo.currentDir)
        createFolderName.current.value = null;
    }
    let uploadFileHandler = (e) => {
        const files = [...e.target.files]
        files.forEach(file => props.UploadFile(file, props.DiskInfo.currentDir))
    }
    let dragEnterHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragEnter(true)
    }
    let dragLeaveHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragEnter(false)
    }
    let dropHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()

        let files = [...e.dataTransfer.files];
        files.forEach(file => props.UploadFile(file, props.DiskInfo.currentDir))


        setDragEnter(false)
    }

    return (
        props.isLoading || props.DiskInfo.uploadFilesCount != 0 ? <MainLoading uploadFilesCount={props.DiskInfo.uploadFilesCount}/>
            :
            !dragEnter ?
                <div onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler} onDragOver={dragEnterHandler}>
                    <div className={"files-header"}>
                        <div className={"buttons"}>
                            <div onClick={homeClickHandler} className={"button"}>
                                <img src={homeIcon} alt={"home-folder-button"}/>
                                <span>Главная</span>
                            </div>

                            {props.DiskInfo.currentDir && <div className={"button"} onClick={backClickHandler}>
                                <img src={backIcon} alt={"back-button"}/>
                                <span>Назад</span>
                            </div>}


                            <Popup key={props.DiskInfo.filesList.length}
                                   trigger={<div className={"button"}>
                                       <img src={newFolderIcon} alt={"new-folder-button"}/>
                                       <span>Создать папку</span>
                                   </div>}
                                   modal
                                   nested
                            >
                                {() => (
                                    <div>
                                        <div id="get-advice" className="main-popup">
                                            <form className="form">
                                                <div className="formgroup">
                                                    <input ref={createFolderName} type="text"
                                                           placeholder="Название папки"/>
                                                </div>
                                            </form>
                                            <button onClick={createFolderHandler} className={"send-btn"}>Создать
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Popup>

                            <label htmlFor={"upload-file-input"} className={"upload-file button"}>
                                <img src={uploadFileIcon} alt={"upload-file-icon"}/>
                                <span>Загрузить файл</span>
                                <input multiple={true} onChange={uploadFileHandler} type={"file"}
                                       id={"upload-file-input"}
                                       className={"upload-file-input"}/>
                            </label>
                        </div>
                    </div>
                    <div className={"files-settings"}>

                    </div>
                    <div className={"files-columns"}>
                        <div/>
                        <div className={"sort"} onClick={sortTable} accessKey={"name"}><span>Название</span></div>
                        <div>Размер</div>
                        <div>Дата создания</div>
                        <div>Владелец</div>
                        <div></div>
                    </div>
                    <div className={"files-list"}>
                        {filesList}
                    </div>
                </div>
                : <div onDrop={dropHandler} onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler}
                       onDragOver={dragEnterHandler} className={"file-drop-area"}>
                    Перенесите сюда файлы для загрузки...
                </div>
    )
}

const File = (file) => {
    let fileType = documentIcon;
    let imageTypes = ['raw', 'jpeg', 'jpg', 'tiff', 'psd', 'bmp', 'gif', 'png', 'jp2']

    if (file.type === "dir") fileType = folderIcon;
    if (imageTypes.includes(file.type.toLowerCase())) fileType = imageIcon;

    let GetFiles = () => {
        file.PushToStack(file.CurrentDir, file.name)
        file.GetFiles(file._id);
    }

    let downloadFile = (e) => {
        e.stopPropagation()
        file.DownloadFile(file)
    }
    let downloadDir = (e) => {
        e.stopPropagation()
        file.DownloadDir(file)
    }

    let previewImage = (e) => {
        e.stopPropagation()
        file.PreviewImage(file)
    }

    let deleteFile = (e) => {
        e.stopPropagation()
        file.DeleteFile(file);
    }

    let d = new Date(file.date)
    let datestring = ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
        d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);

    return (
        <div onClick={file.type === 'dir' ? GetFiles : downloadFile} className={"file-block"}>
            <img src={fileType} alt={"file-icon"}/>
            <div className={"file-column file-name"}>
                <span>{file.name}</span>
            </div>
            <div className={"file-column"}>
                <span>{file.type === 'dir' ? null : fileSize(file.size)}</span>
            </div>
            <div className={"file-column"}>
                <span>{datestring}</span>
            </div>
            <div className={"file-column"}>
                <span>{file.owner ? file.owner : "Неизвестно"}</span>
            </div>
            <div className={"file-buttons"}>
                {imageTypes.includes(file.type.toLowerCase()) ?
                    <img onClick={previewImage} className={"preview"} src={previewFileIcon} alt={"preview-file-icon"}/>
                    : null
                }
                {file.type === 'dir' ?
                <img onClick={downloadDir} style={{marginRight: "13px"}} className={"preview"} src={downloadFileIcon} alt={"download-dir-btn"}/> : null }
                <img onClick={deleteFile} className={"remove"} src={removeIcon} alt={"remove-icon"}/>
            </div>
        </div>
    )
}

function fileSize(size) {
    let i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export default DiskFiles;