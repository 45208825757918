import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useDispatch} from "react-redux";
import {AddNotification} from "../../../../../redux/reducers/notificationsReducer";
import moment from "moment";

const InfosModel = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState("Пусто");
    const [status2, setStatus2] = useState("Пусто");

    useEffect(() => {
        axios.get(`https://frame.firestorm.team/sharkscope/status/infos_model/fsSD(asd$VKADv_i86IVs&BVEICSGAdfhgf4e3_$0sU_Ch1En`)
            .then(r => r.data)
            .then(data => {
                setStatus(`Последний запуск скрипта - ${moment(+data.timestamp).format("DD.MM.YYYY HH:mm")}`)
            })
        axios.get(`https://frame.firestorm.team/sharkscope/status/schedules_learn/fsSD(asd$VKADv_i86IVs&BVEICSGAdfhgf4e3_$0sU_Ch1En`)
            .then(r => r.data)
            .then(data => {
                setStatus2(`Последний запуск скрипта - ${moment(+data.timestamp).format("DD.MM.YYYY HH:mm")}`)
            })
    }, []);

    const handleUpdateModel = () => {
        axios.get(`https://frame.firestorm.team/sharkscope/start/infos_model/fsSD(asd$VKADv_i86IVs&BVEICSGAdfhgf4e3_$0sU_Ch1En`)
            .then(r => r.data)
            .then(data => {
                if (data?.resultCode && data.resultCode == 2) {
                    dispatch(AddNotification("error", "Запуск нейромашины", data.message));
                } else {
                    dispatch(AddNotification("success", "Запуск нейромашины", "Успешно запущен процесс уничтожения человечества"))
                }
            })
    }
    const handleUpdateModel2 = () => {
        axios.get(`https://frame.firestorm.team/sharkscope/start/schedules_learn/fsSD(asd$VKADv_i86IVs&BVEICSGAdfhgf4e3_$0sU_Ch1En`)
            .then(r => r.data)
            .then(data => {
                if (data?.resultCode && data.resultCode == 2) {
                    dispatch(AddNotification("error", "Запуск нейромашины", data.message));
                } else {
                    dispatch(AddNotification("success", "Запуск нейромашины", "Успешно запущен процесс уничтожения человечества"))
                }
            })
    }

    return (
        <div>
            <div style={{color: "white", marginTop: "15px"}}>
                <div style={{marginBottom: "10px"}}>Infos Model</div>
                <div style={{marginBottom: "10px"}}>{status}</div>
                <div className={"button default-button default-back"} onClick={handleUpdateModel}>
                    Запустить скрипт
                </div>
            </div>
            <div style={{color: "white", marginTop: "105px"}}>
                <div style={{marginBottom: "10px"}}>Schedules Learn</div>
                <div style={{marginBottom: "10px"}}>{status2}</div>
                <div className={"button default-button default-back"} onClick={handleUpdateModel2}>
                    Запустить скрипт
                </div>
            </div>
        </div>
    );
};

export default InfosModel;