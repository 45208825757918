import React, {useState, useRef, useLayoutEffect} from "react";
import {NavLink} from "react-router-dom";
import WikiSearchContainer from "./wikiSearchContainer";
import MainLoading from "../../common/mainLoading";
import s from "./styles.module.css";

export default function WikiList(props) {
    return (
        props.isLoading ? <MainLoading/>
            :
            <div className={s.wikiListPage}>
                <WikiSearchContainer/>

                <div className={s.wikiList}>
                    {props.WikiInfo.searchPages[0] ?
                        props.WikiInfo.searchPages.map((page, i) =>
                            <NavLink key={page.name + "wiki-page" + i} to={`/wiki/${page.id}`} className={s.block}>
                                <div className={s.name}>
                                    {`${page.name}`}<a>{page.category ? " [" + page.category + "]" : null}</a>
                                </div>
                                <div className={s.info}>
                                    <div className={s.author}>
                                        Автор: {`${page.creator}`}
                                    </div>
                                    <div className={s.last_update}>
                                        Последнее обновление: {`${page.lastUpdate} (${page.lastEditor})`}
                                    </div>
                                </div>
                            </NavLink>)
                        :
                        <div style={{color: "white", textAlign: "center"}}>
                            Статьи не найдены
                        </div>
                    }
                </div>

            </div>
    );
}
